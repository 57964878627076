import { ALLUSERS } from "../types";

const initialState = {
  NAMES: [],
  loading: true,
};

export default function (state = initialState, action) {
   switch (action.type) {
    case ALLUSERS:
      return {
        ...state,
        NAMES: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
