import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { Grid } from '@mui/material'
import { useHistory } from "react-router-dom";

 // typescript style
import { useLocation,  } from "react-router-dom";

export default function PdfViewer(props) {
  // console.log('PDFViewer: ', props)
  const [html, sethtml] = useState(""); // require(`../../src/${props.route.params.source}`)
  // const html = require(`${props.route.params.source}`)

  const location = useLocation();
  const oncall = "";
  const history = useHistory();
  // const PolicyHTML = require(props.route.params.source)
  // return (<div dangerouslySetInnerHTML={{__html: props.route.params.source}} />)

  return (
     <Grid      container
     columnSpacing={1}
     alignContent="center"
     justifyContent="center"> 
        <div id="iframe_container" style={{position: 'fixed',top: '80px', bottom: '2px', left: '40%', right: '0px' }}>
<iframe name="ifr" scrolling="yes" style={{position: 'absolute', height:'100%', width:'100%'}} src={`${location.state.source}`} >
</iframe>
</div>
</Grid>
  );
}
