import { yupResolver } from '@hookform/resolvers/yup'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from 'assets/save.png'
import CancelIcon from 'assets/cancel.png'
import ClearIcon from 'assets/clear.png'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Popper from '@material-ui/core/Popper';
import { Backdrop } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Stack from '@mui/material/Stack'
import { Grid, Typography } from '@mui/material'
import axios from 'axios'
import Modal from '@mui/material/Modal'
import SuiBox from 'components/SuiBox'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import SuiButton from 'components/SuiButton'
import SuiInput from 'components/SuiInput'
import SuiSelect from 'components/SuiSelect'
import SuiTypography from 'components/SuiTypography'
import { createBrowserHistory } from 'history'
import Moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { Controller, get, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import icdsList from '../Data/icdsList'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import Button from '@mui/material/Button'
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DialogTitle from "@material-ui/core/DialogTitle";

import {

  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
const history = createBrowserHistory({ forceRefresh: true })

const fields = [
  'id',
  'patientId',
  'patientNoteId',
  'type',
  'code',
  'description',
  'comments',
  'status',
  'isTemplate',
  'primaryDiagnosis',
  'startDate',
  'endDate',
  'practiceId',
  'inactive',
  'createdDate',
  'updatedDate',
  'createdBy',
  'updatedBy',
  'clientCreatedDate',
  'clientUpdatedDate',
]
const defValues = {
  id: '',
  patientId: 0,
  patientNoteId: 0,
  type: '',
  code: '',
  description: '',
  comments: '',
  status: '',
  primaryDiagnosis: false,
  startDate: '',
  endDate: '',
  practiceId: '',
  isTemplate:true,
  inactive: false,
  createdDate: '',
  updatedDate: '',
  createdBy: '',
  updatedBy: '',
  clientCreatedDate: '',
  clientUpdatedDate: '',
}
function editclick() {
  openNote()
}
function PatientDiagnosis(props) {
  const readOnly = props.readOnly
  const location = useLocation()
  const alert = useAlert()
  // console.log('Diagnosisprops', props)
  const [submitting, setSubmitting] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [close, setClose] = useState(true)
  const [patientDiagnosis, setpatientDiagnosis] = useState([])
  const [openNote, setOpenNote] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [itemId, setItemId] = useState('')
  const [code, setCode] = React.useState('');

  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }
  const styleAddMember = {
    position: 'absolute',
    top: '50%',
    left: '60%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: '#F0FFFF',
    border: '2px solid black',
    boxShadow: 24,
    p: 2,
 
  }
  //   const handleStatusChange = (event) => {
  //     setStatus(event.target.value)
  //   }
  //   const handleTypeChange = (event) => {
  //     setType(event.target.value)
  //   }
  //   const handlePrimaryDiagChange = (event) => {
  //     console.log(event.target)
  //   }
  const clearICDFields = (event) => {
    // id('')
    // setICDDesc('')
    // status(0)
    // setType(0)
    // setStartDate('')
    // setEndDate('')
    // setPrimaryDiag(false)
  }
  const [openDD, setOpenDD] = useState(false);
  const [delId, setDelId] = useState('');

  const handleDeleteById = () => {
    const postData = {
      id: delId,
      inactive: true,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/PatientDiagnosis/AddPatientDiagnosis`, postData, {
        headers,
      })
      .then((response) => {
        setRefresh(!refresh);
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
        console.error('There was an error!', error);
      });

    setOpenDD(false);
  };

  const onSubmit = (data) => {
    setSubmitting(true)

    const postData = data
    postData.patientId = parseInt(props.patientId)
    // postData.startDate = getValues('startDate')
    // postData.endDate = getValues('endDate')
    if (postData.startDate == 'Invalid date') {
      postData.startDate = ''
    }
    if (postData.endDate == 'Invalid date') {
      postData.endDate = ''
    }
    postData.patientNoteId = parseInt(props.patientnoteId)
    postData.id = data.id == '' || data.id.length == 0 ? 0 : data.id
    // if (props.patientId == 0) {
    //   postData.isTemplate = true
    // }
    postData.isTemplate = true
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientDiagnosis/AddPatientDiagnosis`,
        postData,
        {
          headers,
        }
      )
      .then((response) => {
        setRefresh(!refresh)
        setOpenNote(false)
        setSubmitting(false)
        // console.log('response.data: ', response.data)
      })
      .catch((error) => {
        setSubmitting(false)
        console.error('There was an error!', error)
      })
  }
  var Url
  useEffect(() => {
    if (props.patientId != 0) {
      Url = `${
        process.env.REACT_APP_API_URL
      }/PatientDiagnosis/getPatientDiagnosisById?id=
  ${props.patientnoteId !== undefined ? props.patientnoteId : 0}`
    } else {
      Url = `${
        process.env.REACT_APP_API_URL
      }/PatientDiagnosis/getPatientDiagnosisById?isTemplate=${true}`
    }
    // POST request using axios inside useEffect React hook
    let isComponentMounted = true
    const fetchData = async () =>
      axios
        .get(Url, { headers })
        .then((response) => {
          // console.log('response.data: ', response.data)
          setpatientDiagnosis(response.data)
        })
        .catch((error) => {
          console.error('There was an error!', error)
        })
    fetchData()
    return () => {
      isComponentMounted = false
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])
  const [open, setOpen] = React.useState(false)
  const [icdfilteredList, seticdfilteredList] = React.useState([])
  const [isZipCode, setIsZipCode] = React.useState(false)
  const [templates, setTemplates] = React.useState(false)
  const [checkBox, setCheckBox] = useState(false)
  const zipCodeListStyle = {
    cursor: 'pointer',
    '&:hover': {
      background: '#3b3b3b !important',
      color: 'rgba(5, 152, 236, 0.637) !important',
    },
  }
  const today = new Date()
  var startDate = new Date()
  startDate.setFullYear(startDate.getFullYear() - 100)

  var endDate = startDate
  var validationSchema
  if (props.patientId != 0) {
    validationSchema = Yup.object({
      code: Yup.string().required('Please Enter ICD Code'),
      // firstName: Yup.string().required('First Name is required'),
      // ssn: Yup.string().required('Social Security number is required'),
      // accountNumber: Yup.string().required('Account number is required'),
      // dob: Yup.string().required('dob is required'),
      // emailAddress: Yup.string()
      //   .required('Email is required')
      //   .email('Email is invalid'),
      //  insuranceId: Yup.string().required('Insurance is required'),

      // startDate: Yup.date()
      // .required('Start Date is required')
      // .nullable()
      // .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
      // .max(today, "Start Date can't be of future!"),
      // .min(startDate, "You can't be that much !"),
      // endDate: Yup.date()
      //  .required('End Date is required')
      // .nullable()
      // .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
      // .max(today, "End Date can't be of future!")
      // .min(endDate, "End Date can't be less than Start Date!"),
    })
  } else {
    validationSchema = Yup.object({
      code: Yup.string().required('Please Enter ICD Code'),
    })
  }
  const [openDialog, setOpenDialog] = useState(false);
  const [sId, setId] = useState('');

  const handleDelete = () => {
    const postData = {
      id: sId,
      inactive: true,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/PatientDiagnosis/AddPatientDiagnosis`, postData, { headers })
      .then((response) => {
        setRefresh(!refresh);
        setSubmitting(false);
        // setOpenNote(false)
        // console.log('response.data: ', response.data)
        handleCloseDialog();
      })
      .catch((error) => {
        setSubmitting(false);
        console.error('There was an error!', error);
        handleCloseDialog();
      });
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const isTemplate = (data) => {
    setSubmitting(true)
    // const postData = data
    var checkedRows = templates.filter(
      (templates) => templates.inactive == true
    )
    console.log('isChecked:', checkedRows)
    checkedRows.forEach((element) => {
      element.patientNoteId = parseInt(props.patientnoteId)
      element.patientId = parseInt(props.patientId)
      element.inactive = false
      element.id = 0
      element.isTemplate = false
    })

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientDiagnosis/AddPatientDiagnosisList`,
        checkedRows,
        {
          headers,
        }
      )
      .then((response) => {
        setRefresh(!refresh)
        setOpenModal(false)
        setSubmitting(false)
        // console.log('response.data: ', response.data)
      })
      .catch((error) => {
        setSubmitting(false)
        console.error('There was an error!', error)
      })
  }
  const onChangeHandle = async (value) => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    // console.log(value)
    const arr = icdsList.filter((item) => {
      return item.value.toLowerCase().includes(value)
    })
   
    seticdfilteredList(arr)
    setIsZipCode(true)

  }
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    if (props.patientId != 0) {
      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/PatientDiagnosis/getPatientDiagnosisById?isTemplate=${true}`,
          {
            headers,
          }
        )
        .then((response) => {
          // console.log('response.datacode: ', response.data)
      
          

          setTemplates(response.data)
          // setTemplatesid(response.data)
        })
        .catch((error) => {
          console.error('There was an error!', error)
        })
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])
  const handleCloseAddMember = () => {
    setOpenModal(false)
  }
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  })
  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseAddMember}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleAddMember} style={{overflow:'scroll', height:'500px'}}>
          <SuiTypography
            component="label"
            variant="caption"
            fontSize="20px"
            style={{ fontWeight: 'bold' }}
          >
            Templates
          </SuiTypography>
          <CancelOutlinedIcon
            onClick={() => {
              setOpenModal(false)
              setRefresh(!refresh)
            }}
            variant="contained"
            style={{
              float: 'right',
              color: 'red',
            }}
          ></CancelOutlinedIcon>

          <Grid container columnSpacing={1}>
            {templates != null &&
            templates.length > 0 &&
            templates[0].code != '' > 0
              ? templates.map((s, index) => {
                  return (
                    <>
                      <Grid
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{ width: '100%', marginTop: '5px'}}
                        bgcolor={'rgb(161,230,246)'}
                      >
                        <Grid item spacing={0} xs={6} sm={1} md={1} lg={1}>
                          <Checkbox
                            style={{ marginBottom: '4px', marginLeft: '5px' }}
                            onChange={(e) => {
                              s.inactive = e.target.checked
                              // setCheckBox(e.target.checked)
                            }}
                          />
                        </Grid>
                        <Grid item spacing={1} xs={6} sm={1} md={1} lg={1}>
                          <Typography
                            style={{
                              height: '20px',
                              paddingBottom: '5px',
                              fontSize: '14px',
                              marginRight: '10px',
                            }}
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="#2188FE"
                          >
                            ICD:
                          </Typography>
                          <Typography
                            style={{
                              height: '20px',
                              paddingBottom: '5px',
                              fontSize: '14px',
                              marginRight: '10px',
                            }}
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="#333333"
                          >
                            {s.code}
                          </Typography>
                        </Grid>
                        <Grid item spacing={1} xs={6} sm={1} md={1} lg={1}>
                          <Typography
                            style={{
                              height: '20px',
                              paddingBottom: '5px',
                              fontSize: '14px',
                              marginRight: '10px',
                              marginLeft: '10px',
                            }}
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="#2188FE"
                          >
                            Type:
                          </Typography>
                          <Typography
                            style={{
                              height: '20px',
                              paddingBottom: '5px',
                              fontSize: '14px',
                              marginRight: '10px',
                            }}
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="#333333"
                          >
                            {s.type}
                          </Typography>
                        </Grid>
                        <Grid item spacing={1} xs={6} sm={2} md={2} lg={2}>
                          <Typography
                            style={{
                              height: '20px',
                              paddingBottom: '5px',
                              fontSize: '14px',
                              marginRight: '10px',
                              marginLeft: '35px',
                            }}
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="#2188FE"
                          >
                            Status:
                          </Typography>
                          <Typography
                            style={{
                              height: '20px',
                              paddingBottom: '5px',
                              fontSize: '14px',
                              marginRight: '10px',
                            }}
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="#333333"
                          >
                            {s.status}
                          </Typography>
                        </Grid>
                        <Grid item spacing={1} xs={6} sm={7} md={7} lg={7}>
                          <Typography
                            style={{
                              height: '20px',
                              paddingBottom: '5px',
                              fontSize: '14px',
                              marginLeft: '10px',

                              alignContent: 'left',
                              color: '#2188FE',
                              fontWeight: 'bold',
                            }}
                            component="label"
                            variant="caption"
                          >
                            Description:
                          </Typography>
                          <Typography
                            style={{
                              height: '20px',
                              paddingBottom: '5px',
                              fontSize: '14px',
                              marginLeft: '10px',
                              paddingInlineEnd: '10px',
                              fontWeight: 'bold',
                              color: '#333333',
                            }}
                            component="label"
                            variant="caption"
                          >
                            {s.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )
                })
              : 'No Record Found'}
            {templates != null && templates.length > 0 ? (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button
                  onClick={() => {
                    setOpenModal(false)
                    // item.isChecked = false
                    // setError('')
                    // setDate('')
                  }}
                  variant="gradient"
                  disabled={submitting}
                  // color="black"
                  // size="small"
                  style={{
                    marginTop: '5px',
                    float: 'right',
                    marginLeft: '20px',
                    width: '90px',
                    height: '35px',
                    backgroundColor: '#FCBE13',
                    color: 'black',
                    fontSize: '14px',
                  }}
                  // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                >
                  <CancelOutlinedIcon
                    fontSize="medium"
                    style={{ color: 'red', paddingRight: '5px' }}
                  ></CancelOutlinedIcon>
                  Cancel
                </Button>
                <Button
                  onClick={isTemplate}
                  variant="gradient"
                  disabled={submitting}
                  style={{
                    marginTop: '5px',
                    float: 'right',
                    marginLeft: 'auto',
                    // width: '80px',
                    height: '35px',
                    backgroundColor: '#FCBE13',
                    color: 'black',
                    fontSize: '14px',
                  }}
                  // color="warning"
                  // size="small"

                  // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                >
                  <SaveOutlinedIcon
                    fontSize="medium"
                    style={{
                      color: '#1a82ad',
                      paddingRight: '5px',
                      paddingBottom: '2px',
                    }}
                  ></SaveOutlinedIcon>
                  Apply Template
                </Button>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </Box>
      </Modal>
      <Grid
        item
        xs={12}
        sm={4}
        md={6}
        lg={12}
        style={{ width: '100%', height: '40px', paddingTop: '4px' }}
        bgcolor={'rgb(161,230,246)'}
      >
        <SuiTypography
          component="label"
          variant="caption"
          fontSize="20px"
          fontWeight="bold"
        >
          Patient Assessment & Diagnosis
        </SuiTypography>

        <AddIcon
          color="black"
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
            cursor: 'pointer',
          }}
          onClick={(event) => {
            setOpenNote((current) => !current)
            fields.forEach((field) => setValue(field, ''))
          }}
        ></AddIcon>
        {props.patientId != 0 ? (
          <SuiButton
            onClick={() => {
              setOpenModal(true)
              setRefresh(!refresh)
            }}
            variant="gradient"
            color="info"
            disabled={readOnly == true ? true : false}
            size="small"
            style={{
              width: '80px',
              float: 'right',
              // display: 'inline-block',
              marginRight: '5px',
            }}
            // startIcon={
            //   <img
            //     src={AddIcon}
            //     style={{ marginTop: '-3px', marginRight: '-6px' }}
            //   />
            // }
          >
            Templates
          </SuiButton>
        ) : (
          ''
        )}
      </Grid>
      <Grid item xs={12}>
        <SuiBox mb={1}>
          {openNote == true ? (
            <div>
              <Grid
                container
                spacing={1}
                style={{
                  paddingTop: '10px',
                  paddingBottom: '30px',
                  paddingLeft: '50px',
                  paddingRight: '50px',
                  borderRadius: '20px',
                }}
              >
                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <SuiTypography component="label" variant="caption">
                    ICD
                  </SuiTypography>
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <SuiTypography component="label" variant="caption">
                    Description
                  </SuiTypography>
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <SuiTypography component="label" variant="caption">
                    Primary Diagnosis
                  </SuiTypography>
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} style={{marginTop: '-9px'}}>
         
<Autocomplete 
  {...register('code')}
  options={icdsList}
  getOptionLabel={(option) => `${option.value}`}
  onChange={(event, value) => {
    if (value) {
      setValue('code', value.value);
      setValue('description', value.label);
      setCode(value.value);
    }
  }}
  renderInput={(params) => (
    <TextField
style={{border:'1px solid black',borderRadius:'8px'}}

      {...params}
      placeholder={code}
      name="code"
      isClearable
      fullWidth
      margin="dense"
      error={errors.code}
      defaultValue={code} // Set your default value here
    />
  )}
  MenuProps={{
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  }}
  onKeyUp={(ev) => {
    if (ev.target.value !== '' && ev.target.value !== null) {
      onChangeHandle(ev.target.value);
    }
  }}
/>
                  <p style={{ color: 'red', fontSize: '14px' }}>
                    {errors.code?.message}
                  </p>
                  {/* {isZipCode ? (
                    <div
                      className="col-xs-3"
                      style={{
                        padding: '14px 16px',
                        fontSize: '0.875rem',
                        color: '#67748e',
                        borderRadius: '5px',
                        background: 'white',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                        position: 'absolute',
                        zIndex: '99',
                        width: '25%',
                        border: '1px solid #6cb5f9',
                        height: '20%',
                        overflowY: 'auto',
                      }}
                    >
                      {icdfilteredList.map((s, index) => (
                        <p
                          // attribZip={s.zipCode}
                          // attribState={s.state}
                          // attribCity={s.city}
                          // attribIndex={index}
                          style={zipCodeListStyle}
                          onClick={(ev) => {
                            // console.log('evvv', ev)
                            // var sel=ev.target.attributes['attribIndex']
                            // console.log('Selected: ', icdsList[index])
                            setValue('code', icdsList[index].value)
                            setValue('description', icdsList[index].label)
                            setIsZipCode(false)
                          }}
                          onBlur={(ev) => {
                            setIsZipCode(false)
                          }}
                          onChange={(ev) => {
                            setIsZipCode(false)
                          }}
                        >
                          {s.value} - {s.label}
                        </p>
                      ))}
                    </div>
                  ) : (
                    ''
                  )} */}

                  {/* <SuiInput
                    {...register('code')}
                    error={errors.code}
                    placeholder="Please enter ICD"
                    name="code"
                    label="icd"
                    fullWidth
                    margin="dense"
                  />  */}
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <SuiInput
                    type="textarea"
                    {...register('description')}
                    placeholder="Please enter Description"
                    name="description"
                    label="description"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <SuiInput
                    {...register('primaryDiagnosis')}
                    type="checkbox"
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <SuiTypography component="label" variant="caption">
                    Type
                  </SuiTypography>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <SuiTypography component="label" variant="caption">
                    Status
                  </SuiTypography>
                </Grid>

                <Grid item xs={12} sm={3} md={3} lg={3}>
                  {' '}
                  {props.patientId != 0 ? (
                    <>
                      <SuiTypography component="label" variant="caption">
                        Start Date
                      </SuiTypography>
                    </>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  {props.patientId != 0 ? (
                    <>
                      <SuiTypography component="label" variant="caption">
                        End Date
                      </SuiTypography>
                    </>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <Controller
                    name="type"
                    {...register('type')}
                    control={control}
                    render={({ field }) => (
                      <SuiSelect
                        {...field}
                        value={{ label: getValues('type') }}
                        onChange={(value) => setValue('type', value.label)}
                        options={[
                          { value: 'Acute', label: 'Acute' },
                          { value: 'Chronic', label: 'Chronic' },
                        ]}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <Controller
                    name="status"
                    {...register('status')}
                    control={control}
                    render={({ field }) => (
                      <SuiSelect
                        {...field}
                        value={{ label: getValues('status') }}
                        onChange={(value) => setValue('status', value.label)}
                        options={[
                          { value: 'Active', label: 'Active' },
                          { value: 'Resolved', label: 'Resolved' },
                        ]}
                      />
                    )}
                  />
                  {/* <Select {...register('status')} autosize={true}>
                    <MenuItem value={'Active'}> Active</MenuItem>
                    <MenuItem value={'Inactive'}> Inactive</MenuItem>
                  </Select> */}
                </Grid>

                <Grid item xs={12} sm={3} md={3} lg={3}>
                  {props.patientId != 0 ? (
                    <>
                      <SuiInput
                        {...register('startDate')}
                        type="date"
                        margin="dense"

                        // error={errors.startDate}
                      />
                      {/* <p style={{ color: 'red', fontSize: '14px' }}>
                      {errors.startDate?.message}
                    </p> */}
                    </>
                  ) : (
                    null
                  )}
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  {props.patientId != 0 ? (
                    <>
                      <SuiInput
                        {...register('endDate')}
                        type="date"
                        margin="dense"
                      />
                      {/* <p style={{ color: 'red', fontSize: '14px' }}>
                      {errors.endDate?.message}
                    </p> */}
                    </>
                  ) : (
                    null
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <SuiTypography component="label" variant="caption">
                    Comments
                  </SuiTypography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={5}
                    placeholder="Enter comments"
                    style={{ width: '100%' }}
                    {...register('comments')}
                  />
                </Grid>

                <Grid
                  direction="row"
                  spacing={1}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    float: 'right',
                    marginLeft: 'auto',
                  }}
                >
                  <Button
                    onClick={(event) => {
                      setOpenNote(false)
                    }}
                    variant="gradient"
                    style={{
                      marginTop: '10px',
                      marginBottom: '10px',
                      float: 'right',
                      marginRight: '5px',
                      marginLeft: 'auto',
                      width: '80px',
                      height: '35px',
                      backgroundColor: '#FCBE13',
                      color: 'black',
                      fontSize: '14px',
                    }}
                  >
                    <CancelOutlinedIcon
                      fontSize="medium"
                      style={{ color: 'red', paddingRight: '3px' }}
                    ></CancelOutlinedIcon>
                    Cancel
                  </Button>
                  <Button
                    onClick={(event) => {
                      fields.forEach((field) => setValue(field, ''))
                    }}
                    variant="gradient"
                    style={{
                      marginTop: '10px',
                      marginBottom: '10px',
                      float: 'right',
                      marginRight: '5px',
                      marginLeft: 'auto',
                      width: '80px',
                      height: '35px',
                      backgroundColor: '#FCBE13',
                      color: 'black',
                      fontSize: '14px',
                    }}
                  >
                    <CleaningServicesOutlinedIcon
                      fontSize="medium"
                      style={{ color: '#0a9ff0', paddingRight: '5px' }}
                    ></CleaningServicesOutlinedIcon>
                    Clear
                  </Button>
                  {props.patientId == 0 ? (
                    <Button
                      onClick={handleSubmit(onSubmit)}
                      variant="gradient"
                      disabled={readOnly == true ? true : false}
                      style={{
                        marginTop: '10px',
                        marginBottom: '10px',
                        float: 'right',
                        marginRight: '5px',
                        marginLeft: 'auto',
                        // width: '0px',
                        height: '35px',
                        backgroundColor: '#FCBE13',
                        color: 'black',
                        fontSize: '14px',
                      }}
                    >
                      <SaveOutlinedIcon
                        fontSize="medium"
                        style={{ color: '#0a9ff0', paddingRight: '5px' }}
                      ></SaveOutlinedIcon>
                      Save As Template
                    </Button>
                  ) : (
                    <Button
                      onClick={handleSubmit(onSubmit)}
                      variant="gradient"
                      // disabled={readOnly == true ? true : false}
                      disabled = {submitting}
                      
                      style={{
                        marginTop: '10px',
                        marginBottom: '10px',
                        float: 'right',
                        marginRight: '5px',
                        marginLeft: 'auto',
                        width: '80px',
                        height: '35px',
                        backgroundColor: '#FCBE13',
                        color: 'black',
                        fontSize: '14px',
                      }}
                    >
                      <SaveOutlinedIcon
                        fontSize="medium"
                        style={{ color: '#0a9ff0', paddingRight: '5px' }}
                      ></SaveOutlinedIcon>
                      Save
                    </Button>
                  )}
                </Grid>
              </Grid>
            </div>
          ) : (
            ''
          )}
        </SuiBox>
      </Grid>

      {patientDiagnosis != null &&
      patientDiagnosis.length > 0 &&
      props.patientId != 0
        ? patientDiagnosis.map((s, index) => (
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ width: '100%', marginTop: '5px' }}
              // bgcolor={'#white'}
            >
              <Grid item spacing={0} xs={6} sm={2} md={2} lg={2}>
                {close == true ? (
                  <ChevronRightIcon
                    style={{
                      marginRight: '5px',
                      float: 'left',
                      marginTop: '7px',
                      // paddingBottom: "5px",
                    }}
                    onClick={(event) => {
                      setItemId(s.id)
                      setClose(false)
                    }}
                  ></ChevronRightIcon>
                ) : s.id == itemId ? (
                  <KeyboardArrowDownIcon
                    style={{
                      marginRight: '5px',
                      float: 'left',
                      marginTop: '7px',
                      // paddingBottom: "5px",
                    }}
                    onClick={(event) => {
                      setClose(true)
                      setItemId('')
                    }}
                  ></KeyboardArrowDownIcon>
                ) : (
                  <ChevronRightIcon
                    style={{
                      marginRight: '5px',
                      float: 'left',
                      marginTop: '7px',
                      // paddingBottom: "5px",
                    }}
                    onClick={(event) => {
                      setItemId(s.id)
                      setClose(false)
                    }}
                  ></ChevronRightIcon>
                )}
                <Typography
                  style={{
                    height: '20px',
                    paddingBottom: '5px',
                    fontSize: '14px',
                    marginRight: '5px',
                  }}
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="#2188FE"
                >
                  ICD:
                </Typography>
                <Typography
                  style={{
                    height: '20px',
                    paddingBottom: '5px',
                    fontSize: '14px',
                    marginRight: '10px',
                  }}
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="#333333"
                >
                  {s.code}
                </Typography>
                {/* <Typography
                  style={{
                    // height: "20px",
                    paddingBottom: '5px',
                    fontSize: '14px',
                    paddingLeft: '5px',
                    fontWeight: 'bold',
                    color: '#333333',
                  }}
                  component="label"
                  variant="caption"
                >
                  {s.visitDate}
                </Typography> */}
              </Grid>

              <Grid item spacing={1} xs={6} sm={2} md={2} lg={2}>
                <Typography
                  style={{
                    height: '20px',
                    paddingBottom: '5px',
                    fontSize: '14px',
                    // marginLeft:'5px',
                    marginRight: '5px',
                    alignContent: 'left',
                  }}
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="#2188FE"
                >
                  Type:
                </Typography>
                <Typography
                  style={{
                    height: '20px',
                    paddingBottom: '5px',
                    fontSize: '14px',
                    marginRight: '10px',
                  }}
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="#333333"
                >
                  {s.type}
                </Typography>
              </Grid>
              <Grid item spacing={1} xs={6} sm={2} md={2} lg={2}>
                <Typography
                  style={{
                    height: '20px',
                    paddingBottom: '5px',
                    fontSize: '14px',
                    marginRight: '5px',
                    marginLeft: '20px',
                  }}
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="#2188FE"
                >
                  Start Date:
                </Typography>
                <Typography
                  style={{
                    height: '20px',
                    paddingBottom: '5px',
                    fontSize: '14px',
                    marginRight: '10px',
                  }}
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="#333333"
                >
                  {s.startDate}
                </Typography>
              </Grid>
              <Grid item spacing={1} xs={6} sm={2} md={2} lg={2}>
                <Typography
                  style={{
                    height: '20px',
                    paddingBottom: '5px',
                    fontSize: '14px',
                  }}
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="#2188FE"
                >
                  End Date:
                </Typography>
                <Typography
                  style={{
                    height: '20px',
                    paddingBottom: '5px',
                    fontSize: '14px',
                  }}
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="#333333"
                >
                  {s.endDate}
                </Typography>
              </Grid>
              <Grid item spacing={1} xs={6} sm={2} md={2} lg={2}>
                <Typography
                  style={{
                    height: '20px',
                    paddingBottom: '5px',
                    fontSize: '14px',
                    // marginLeft:'5px',
                    marginRight: '5px',
                    alignContent: 'left',
                  }}
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="#2188FE"
                >
                  Status:
                </Typography>
                <Typography
                  style={{
                    height: '20px',
                    paddingBottom: '5px',
                    fontSize: '14px',
                    marginRight: '10px',
                  }}
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="#333333"
                >
                  {s.status}
                </Typography>
              </Grid>
              <Grid item spacing={1} xs={6} sm={1} md={1} lg={1}>
              <>
      <DeleteIcon
        style={{        color:"red",

          marginRight: '5px',
          float: 'right',
          marginTop: '5px',
          cursor: 'pointer',
        }}
        onClick={(event) => {setOpenDD(true)
        setDelId(s.id)
        }}
      ></DeleteIcon>

      <Dialog
        open={openDD}
        onClose={() => setOpenDD(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropComponent={Backdrop}
                  BackdropProps={{
                    invisible: true,
                  }}
                  PaperProps={{
                    style: {background:'#cef5f0',
                    boxShadow:'none',
                    borderRadius:'8px'
                    },
                 }}    
      >
       <div style={{borderRadius:'8px',width:'22rem'}}>
   
   <ErrorOutlineIcon style={{marginLeft:'125px',fontSize:'100px',color:'orange'}}></ErrorOutlineIcon>
       <DialogTitle>{"Do you want to delete the record?"}</DialogTitle>
       <h5 style={{textAlign:'center'}}>This action cannot be undone.</h5>
       <DialogContent>
           <DialogContentText>
           <Grid style={{marginLeft:'90px'}}>
           <Button onClick={handleDeleteById}  style={{backgroundColor:'red',color:'white'}}>YES</Button>
           <Button  onClick={() => setOpenDD(false)} style={{backgroundColor:'blue',color:'white',marginLeft:'1rem'}}>NO</Button>
           </Grid>
           </DialogContentText>
       </DialogContent>
      
       



</div>
      </Dialog>
    </>
                <EditIcon
                  style={{
                    marginRight: '5px',
                    marginLeft: '5px',
                    float: 'right',
                    marginTop: '5px',
                    cursor: 'pointer',
                  }}
                  color="black"
                  onClick={(event) => {
                    console.log('Clicked patientDiagnosis:', s.code);
                    setCode(s.code)
                    setOpenNote(true)
                    setSubmitting(false)
                    var data = patientDiagnosis[index]
                    fields.forEach((field) => setValue(field, data[field]))
                    var dateYMD = Moment(data['startDate']).format('YYYY-MM-DD')
                    setValue('startDate', dateYMD)
                    dateYMD = Moment(data['endDate']).format('YYYY-MM-DD')
                    setValue('endDate', dateYMD)
                    // console.log
                    // console.log('index: ', patientDiagnosis[index])
                  }}
                ></EditIcon>
              </Grid>
              {close == true ? null : s.id == itemId ? (
                <Grid container xs={12} sm={12} md={12} lg={12}>
                  <Grid item spacing={0} xs={6} sm={2} md={2} lg={2}>
                    <Typography
                      style={{
                        height: '20px',
                        paddingBottom: '5px',
                        fontSize: '14px',
                        marginLeft: '20px',
                        fontWeight: 'bold',
                        color: '#333333',
                      }}
                      component="label"
                      variant="caption"
                    >
                      {/* {s.visitdate} */}
                    </Typography>
                  </Grid>
                  <Grid item spacing={1} xs={6} sm={10} md={10} lg={10}>
                    <Typography
                      style={{
                        height: '20px',
                        paddingBottom: '5px',
                        fontSize: '14px',
                        // marginLeft: "8px",

                        alignContent: 'left',
                        color: '#2188FE',
                        fontWeight: 'bold',
                      }}
                      component="label"
                      variant="caption"
                    >
                      Description:
                    </Typography>
                    <Typography
                      style={{
                        height: '20px',
                        paddingBottom: '5px',
                        fontSize: '14px',
                        marginLeft: '10px',
                        paddingInlineEnd: '10px',
                        fontWeight: 'bold',
                        color: '#333333',
                      }}
                      component="label"
                      variant="caption"
                    >
                      {s.description}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          ))
        : null}
      {patientDiagnosis != null &&
      patientDiagnosis.length > 0 &&
      props.patientId == 0
        ? patientDiagnosis.map((s, index) => (
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ width: '100%', marginTop: '5px' }}
              // bgcolor={'rgb(161,230,246)'}
            >
              <Grid item spacing={1} xs={2} >
                <Typography
                  style={{
                    height: '20px',
                    paddingBottom: '5px',
                    fontSize: '14px',
                    marginLeft: '5px',
                    marginRight: '10px',
                  }}
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="#2188FE"
                >
                  ICD:
                </Typography>
                <Typography
                  style={{
                    height: '20px',
                    paddingBottom: '5px',
                    fontSize: '14px',
                    marginRight: '10px',
                  }}
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="#333333"
                >
                  {s.code}
                </Typography>
              </Grid>
              <Grid item spacing={1} xs={2} >
                <Typography
                  style={{
                    height: '20px',
                    paddingBottom: '5px',
                    fontSize: '14px',
                    marginRight: '10px',
                    marginLeft: '10px',
                  }}
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="#2188FE"
                >
                  Type:
                </Typography>
                <Typography
                  style={{
                    height: '20px',
                    paddingBottom: '5px',
                    fontSize: '14px',
                    marginRight: '10px',
                  }}
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="#333333"
                >
                  {s.type}
                </Typography>
              </Grid>
              <Grid item spacing={1} xs={2} >
                <Typography
                  style={{
                    height: '20px',
                    paddingBottom: '5px',
                    fontSize: '14px',
                    marginLeft: '23px',
                    marginRight: '10px',
                  }}
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="#2188FE"
                >
                  Status:
                </Typography>
                <Typography
                  style={{
                    height: '20px',
                    paddingBottom: '5px',
                    fontSize: '14px',
                    marginRight: '10px',
                  }}
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="#333333"
                >
                  {s.status}
                </Typography>
              </Grid>
                <Grid item xs={1}>
                <Typography
                  style={{
                    height: '20px',
                    paddingBottom: '5px',
                    fontSize: '14px',
                    marginRight: '10px',
                  }}
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="#2188FE"
                >
                  Description:
                </Typography>
                </Grid>
                <Grid item xs={3}>

                {/* <Typography
                  style={{
                    height: '20px',
                    fontSize: '14px',
                    marginRight: '10px',
                    paddingInlineEnd: '10px',
                    fontWeight: 'bold',
                  }}
                  component="label"
                  variant="caption"
                >
                  {s.description}
                </Typography> */}
                <p  style={{
                    // height: '30px',
                    fontSize: '14px',
                    marginRight: '10px',
                    fontWeight: 'bold',
                    marginTop:'7px',
                  }}>  {s.description}  </p>
                </Grid>

              <Grid item spacing={1} xs={2} ><>
      <DeleteIcon         color="red"

        style={{
          marginRight: '5px',
          float: 'right',
          marginTop: '5px',
          cursor: 'pointer',
        }}
        onClick={()=>{handleOpenDialog()
        setId(s.id)
        }}
      ></DeleteIcon>

      <EditIcon
        style={{        color:"black",

          marginRight: '5px',
          float: 'right',
          marginTop: '5px',
          cursor: 'pointer',
        }}
        onClick={(event) => {
          setOpenNote(true);
          setSubmitting(false);
          var data = patientDiagnosis[index];
          fields.forEach((field) => setValue(field, data[field]));
        }}
      ></EditIcon>

      <Dialog open={openDialog} onClose={handleCloseDialog}
        BackdropComponent={Backdrop}
        BackdropProps={{
          invisible: true,
        }}
        PaperProps={{
          style: {background:'#cef5f0',
          boxShadow:'none',
          borderRadius:'8px'
          },
       }}
      >
        <div style={{borderRadius:'8px',width:'23rem',background:'white'}}>
   
   <ErrorOutlineIcon style={{marginLeft:'125px',fontSize:'100px',color:'orange'}}></ErrorOutlineIcon>
       <DialogTitle style={{marginLeft:'1rem',fontWeight:'bold'}}>{"Do you want to delete the record?"}</DialogTitle>
       <h5 style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'normal',marginLeft:'-2rem' }}>
This action cannot be undone.
</h5>
       <DialogContent>
           <DialogContentText>
           <Grid style={{marginLeft:'90px'}}>
           <Button  onClick={()=>handleDelete()}  style={{backgroundColor:'red',color:'white'}}>YES</Button>
           <Button   onClick={handleCloseDialog}  style={{backgroundColor:'blue',color:'white',marginLeft:'1rem'}}>NO</Button>
           </Grid>
           </DialogContentText>
       </DialogContent>
      
       



</div>
      </Dialog>
      </>
    </Grid>
            </Grid>
          ))
        : null}
    </>
  )
}
export default PatientDiagnosis
