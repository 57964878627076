// @mui material components
import Card from "@mui/material/Card";
import customstyle from 'assets/customStyle.css'
// Soft UI Dashboard React components
import { useAlert } from "react-alert";
import Container from "@mui/material/Container";
import { useLocation } from 'react-router-dom'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DialogTitle from "@material-ui/core/DialogTitle";
import { Backdrop } from '@mui/material';
import SuiInput from 'components/SuiInput'
import {
    Grid,
    Link,
    Snackbar,
    TextField,
    Select,
    MenuItem,
    InputAdornment,
    FormControl,
    InputLabel,
    IconButton,
    NativeSelect,
} from "@mui/material";
import { Icon } from "@iconify/react";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import SearchIcon from "@mui/icons-material/Search";
import ModeEditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import React, {
    useEffect,
    useReducer,
    useState,
    Fragment,
    useRef,
} from "react";
import Box from "@mui/material/Box";
import ReactSelect from "react-select";
import LoadingButton from "@mui/lab/LoadingButton";
import { Input } from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import { Controller, useForm } from "react-hook-form";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Download from "@mui/icons-material/Download";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PermIdentity from "@mui/icons-material/PermIdentity";
import WorkOutline from "@mui/icons-material/WorkOutline";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { DataGrid } from "@mui/x-data-grid";
import "react-datepicker/dist/react-datepicker.css";
import { Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CleaningServicesOutlinedIcon from "@mui/icons-material/CleaningServicesOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
// import Avatar from 'react-avatar'
import Avatar from "@mui/material/Avatar";
import fileDownload from "js-file-download";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
// import { FileUploader } from "react-drag-drop-files";
import Tooltip from "@mui/material/Tooltip";

import Moment from "moment";
function PatientDocuments(props) {
    // console.log('Documents List _________________________+++=', props)
    const location = useLocation()
    // const patientId = props.patientId || (location.state ? location.state.patientId : null);
    const patientId = props.patientId || (location.state ? location.state.patientId : null);
const providerName=props.providerName
console.log(providerName,'providerName,,,')
    const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
    const psychotherapistPriviledge = `${localStorage.getItem(
        'dtt_isPsychotherapyPriviledges'
    )}`
    const headers = {
        Authorization: auth,
    }


    const fileTypes = ["JPEG", "PNG", "GIF"];
    const alert = useAlert();
    const [tabValue, settabValue] = React.useState(0);
    const [docslist, setdocslist] = useState([]);
    const [orignal, setorignal] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [docError, setdocError] = useState(false);
    const [docError2, setdocError2] = useState(false);
    // const handleClosee = () => setOpen(false)
    const [submitting, setSubmitting] = useState(false);
    const [date, setdate] = useState(Moment().format("YYYY-MM-DD"));
    const [base64, setbase64] = useState("");
    const [id, setid] = useState(0);

    const [opendocs, setopendocs] = useState(false);
    const [urlFile, seturlFile] = useState("");
    const [urldownload, seturldownload] = useState("");
    const [DocName, setDocName] = useState("");
    const [FullDocName, setFullDocName] = useState("");
    const [spinner, setSpinner] = useState(false);
    const [file, setFile] = useState(null);

    const [filename, setfilename] = useState([]);
    const [base64Data, setBase64Data] = useState([]);
    const [totalProgress, setTotalProgress] = React.useState(0);
    const [savedFiles, setSavedFiles] = React.useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const inputRef = useRef(null);
    const defValues = {
        id: 0,
        documentName: "",
        fileUrl: "",
        patientId: 0,
        practiceId: "",
        date: "",
        createdBy: "",
        Deleted: false,
        clientCreatedDate: "",
        updatedBy: "",
        clientUpdatedDate: "",
        createdDate: "",
        updatedDate: "",
        base64: "",
        documentType: "",
    };
    const {
        register,
        control,
        handleSubmit,
        setValue,
        reset,
        getValues,
        clearErrors,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: false,
        shouldUseNativeValidation: false,
        delayError: undefined,
        defaultValues: defValues,
    });

    const handleOpenn = () => {
        console.log("handle open fn call hw aha");
        setOpen(true);
        setSubmitting(false);
        setbase64("");
        setfilename("");
        setSavedFiles("");
        setid("");
        setdate("");

        // Set all fields to null or an empty string
        fields.forEach((field) => {
            setValue(field, "");
        });
    };

    const handleChange = (event, newValue) => {
        settabValue(newValue);
    };

    // console.log('Reading------------------------------------', urlFile)
    const handleDetails = () => { };
    function applyRefresh() {
        setRefresh(!refresh);
        // setOpen(false)
    }
    const handleClose = () => {
        seturlFile(null);
        setopendocs(false);
        setFile(null);
        setOpen(false);
        setSubmitting(false);
        setSelectedType("");
        setfilename("");
        setBase64Data("");

        // setopenAppcreate(false)
        // setRefresh(true)
    };

    // list of documents
    useEffect(() => {
        setSpinner(true);
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/PatientDocument/PatientDocumentList?patientId=${patientId}`,
                { headers }
            )
            .then((response) => {
                setSpinner(false);
                // console.log('docs list', response.data)
                setdocslist(response.data);
                setorignal(response.data);
            })
            .catch((error) => {
                setSpinner(false);
                console.error("There was an error!", error);
            });
    }, [refresh]);
    const gridRowStyle = {
        boxShadow: 2,
        border: 2,
        borderRadius: 1,
        background: "white",
        fontSize: "13.6px !important",
        color: "black !important",
        borderColor: "rgba(5, 152, 236, 0.637) !important",
        "& .MuiDataGrid-cell:hover": {
            color: "rgba(5, 152, 236, 0.637) !important",
            fontSize: "14.6px !important",
        },
        "& .super-app-theme--header": {
            backgroundColor: "#A574FD",
            color: "white",
        },
    };
    const styleofmodal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "60%",
        height: 500,
        bgcolor: "background.paper",

        boxShadow: 24,
        p: 4,
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 500,
        height: 550,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    // document Uplaod

    const fields = [
        "id",
        "documentName",
        "fileUrl",
        "patientId",
        "practiceId",
        "deleted",
        "date",
        "createdBy",
        "clientCreatedDate",
        "updatedBy",
        "clientUpdatedDate",
        "createdDate",
        "updatedDate",
        "base64",
        "documentType",
    ];


    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const handleChangeforfile = async (files) => {
        const newFilenames = [];
        const newBase64Data = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const base64 = await fileToBase64(file);
            newFilenames.push(file.name);
            newBase64Data.push(base64);
        }

        setfilename(newFilenames);
        setBase64Data(newBase64Data);
        setdocError(false);
    };

    const onSubmit = (data) => {
        console.log("data my kya ha onsubmit py", data);

        console.log("id my kya ha", id);

        if (base64Data.length <= 0 && id == "") {
            setdocError(true);
            return;
        }
        if (date.length <= 0 && id == "") {
            setdocError2(true);
            return;
        }

        setdocError(false);
        setSubmitting(true);
        setSavedFiles([]);
        const postData = [];
        let totalLength = 0;
        if (base64Data.length > 0) {
            for (let i = 0; i < base64Data.length; i++) {
                const documentData = {
                    patientId: patientId,
                    date: data.date,
                    documentName: filename[i],
                    base64: base64Data[i],
                    documentType: selectedType,
                    id: data.id ? data.id : 0,
                };
                postData.push(documentData);
                totalLength += base64Data[i].length;
            }
        } else {
            const documentData = {
                patientId: patientId,
                date: data.date,
                documentType: selectedType,
                id: data.id ? data.id : 0,
                fileUrl: data.fileUrl,
                documentName: data.documentName,
                createdBy: data.createdBy,
                createdDate: data.createdDate,
                practiceId: data.practiceId,
            };
            postData.push(documentData);
        }

        const requests = postData.map((documentData, index) => {
            const config = {
                onUploadProgress: function (progressEvent) {
                    const progress = Math.round(
                        (progressEvent.loaded / totalLength) * 1.5
                    );
                    if (progress <= 100) {
                        setTotalProgress(progress);
                    } else {
                        setTotalProgress(100);
                    }
                },
                headers,
            };
            return axios
                .post(
                    `${process.env.REACT_APP_API_URL}/PatientDocument/PatientDocument?patientId=${patientId}`,
                    documentData,
                    config
                )
                .then((response) => {
                    // Update the savedFiles state with the index of the saved file
                    setSavedFiles((savedFiles) => [...savedFiles, index]);

                    return response;
                });
        });

        axios
            .all(requests)
            .then((responses) => {
                setSpinner(false);
                setOpen(false);
                setSubmitting(false);

                // Check if all requests were successful
                const allSuccess = responses.every(
                    (response) => response.status === 200
                );

                if (allSuccess) {
                    alert.success("Document uploaded successfully.", {
                        type: "success",
                        onClose: () => {
                            applyRefresh();
                            setSelectedType("");
                            setSavedFiles("");
                            setfilename("");
                            setBase64Data("");
                        },
                    });
                } else {
                    alert.error("Error uploading one or more documents.", {
                        type: "error",
                    });
                }
            })
            .catch((error) => {
                setSpinner(false);
                console.log(error);
                setSubmitting(false);
                alert.error(`Error ${error.message}`, {
                    type: "error",
                });
            });
    };

    const download = (url, filename) => {
        console.log(url);
        console.log(filename);
        setSpinner(true);
        setSubmitting(true);
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/PatientDocument/DownloadAWSFile?path=${url}`,
                {
                    headers,
                    responseType: "blob",
                }
            )
            .then((res) => {
                setopendocs(false);
                setSpinner(false);
                setSubmitting(false);
                console.log(res.data);
                fileDownload(res.data, filename);
            })
            .catch((err) => {
                setSubmitting(false);
                console.log(err);
            });
    };

    //* Styling

    const [searchText, setSearchText] = useState("");
    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
    };

    // Initialize an array of hover states, one for each row
    const [hoverStates, setHoverStates] = useState(
        Array(docslist.length).fill(false)
    );
    const [hoverStatesSet2, setHoverStatesSet2] = useState(
        Array(docslist.length).fill(false)
    );

    const handleMouseEnter = (index) => {
        // Update the hover state for the specified index to true
        const newHoverStates = [...hoverStates];
        newHoverStates[index] = true;
        setHoverStates(newHoverStates);
    };

    const handleMouseLeave = (index) => {
        // Update the hover state for the specified index to false
        const newHoverStates = [...hoverStates];
        newHoverStates[index] = false;
        setHoverStates(newHoverStates);
    };

    const handleMouseEnterSet2 = (index) => {
        const newHoverStates = [...hoverStatesSet2];
        newHoverStates[index] = true;
        setHoverStatesSet2(newHoverStates);
    };

    const handleMouseLeaveSet2 = (index) => {
        const newHoverStates = [...hoverStatesSet2];
        newHoverStates[index] = false;
        setHoverStatesSet2(newHoverStates);
    };

    const options = [
        { value: "FaceSheet", label: "Face Sheet" },
        { value: "Other", label: "Other" },
    ];
    const [selectedType, setSelectedType] = useState("");

    const handleTypeChange = (selectedOption) => {
        setSelectedType(selectedOption.value);
        console.log("selected value keya hy ", selectedType);
    };
    const [openAlert, setOpenAlert] = useState(false);
  
      const [selectedDocumentId, setSelectedDocumentId] = useState(null);

      const handleClickOpen = (documentId) => {
        setSelectedDocumentId(documentId);
        setOpenAlert(true);
      };
    
      const handleCloseAlert = () => {
        setOpenAlert(false);
        setSelectedDocumentId(null);
      };
    
      const handleDeleteById = () => {
        if (selectedDocumentId) {
          // Your delete logic here
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/PatientDocument/PatientDocument?patientId=${patientId}`,
              { id: selectedDocumentId, deleted: true },
              {
                headers,
              }
            )
            .then((response) => { alert.success('Record Deleted successfully.')
              setRefresh(!refresh);
              handleCloseAlert();
            })
            .catch((error) => {
              console.error("There was an error!", error);
            });
        }
      };
    return (<>
        <>
            <Grid container xs={12} className='pDoc'>
                <Grid item style={{ color: 'blue' }} xs={12}>
                    <Grid contianer xs={12}>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginTop: '20px',
                            }}
                        >
                            <Grid container spacing={0}>
                                <Grid item xs={6.8}>

                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        className='SearchBox'
                                        fullWidth
                                        variant='outlined'
                                        placeholder='Search...'
                                        onChange={(evt) => {
                                            let keyword = evt.target.value;
                                            if (!orignal) {
                                                console.log('Data is null');
                                                return;
                                            }
                                            setdocslist(orignal);

                                            if (keyword === '') {
                                                return;
                                            }

                                            keyword = keyword.toLowerCase();
                                            let filtered = orignal.filter((entry) =>
                                                Object.values(entry).some(
                                                    (val) =>
                                                        typeof val === 'string' &&
                                                        val != null &&
                                                        val != '' &&
                                                        val != 'null' &&
                                                        val.toLowerCase().includes(keyword)
                                                )
                                            );
                                            setdocslist(filtered);
                                        }}
                                        InputProps={{
                                            style: {
                                                border: '0px',
                                                height: '42px',
                                                backgroundColor: '#F6F5FA',
                                                borderRadius: '8px',
                                                width: '100%',
                                            },
                                            endAdornment: (
                                                <InputAdornment position='end' sx={{ marginLeft: 'auto' }}>
                                                    <IconButton>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            width: '100%',
                                            height: '42px',
                                            borderRadius: '8px',

                                            border: '0px',
                                        }}
                                    />

                                </Grid>
                                <Grid item xs={0.2}></Grid>
                                <Grid item xs={1}>
                                    <IconButton
                                        onClick={(event) => {
                                            applyRefresh();
                                        }}
                                        sx={{
                                            backgroundColor: '#FFF5F0',
                                            color: '#f9bf1c',
                                            height: '40px',
                                            borderRadius: '6px',
                                            fontSize: '25px',
                                            padding: '8px',
                                        }}
                                    >

                                        <Icon icon='jam:refresh' />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>



                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                onClick={handleOpenn}
                                sx={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: "2px dashed #704AC2",
                                    padding: "16px 0",
                                    borderRadius: "8px",
                                    backgroundColor: "transparent",
                                }}
                            >
                                <Icon
                                    icon="prime:cloud-upload"
                                    style={{ fontSize: "25px", marginRight: "8px", color: "#704AC2" }}
                                />
                                <Typography variant="subtitle1" style={{ color: "#704AC2" }}>
                                    Upload file
                                </Typography>
                            </Button>
                        </Grid>
                        <>
                            <Grid item xs={12}>
                                {docslist != null && docslist.length > 0
                                    ? docslist.map((row, index) => (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                border: "1px solid #E9E9EE",
                                                padding: "10px 8px",
                                                borderRadius: "8px",
                                                marginBottom: "8px",
                                            }}
                                        >
                                            <Grid item xs={1}>
                                                <Icon icon="emojione-v1:document" fontSize={"30px"} />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Button
                                                    style={{
                                                        color: "#130724",
                                                        fontWeight: "600",
                                                        fontSize: "16px",
                                                    }}
                                                    onClick={() => {
                                                        setopendocs(true);

                                                        var url = row.fileUrl;
                                                        seturldownload(row.fileUrl);
                                                        axios
                                                            .get(
                                                                `${process.env.REACT_APP_API_URL}/PatientDocument/ViewPatientDocument?path=${url}`,
                                                                {
                                                                    headers,
                                                                }
                                                            )
                                                            .then((response) => {
                                                                seturlFile(response.data);
                                                            })
                                                            .catch((error) => {
                                                                console.error("There was an error!", error);
                                                            });
                                                        var arr = row.documentName.split(".");
                                                        console.log("CheckAAAAA", row.fileUrl);
                                                        setDocName(
                                                            arr.length > 0 ? arr[1].toLowerCase() : ""
                                                        );
                                                        setFullDocName(row.documentName);
                                                    }}
                                                >
                                                    {row.documentName}
                                                </Button>

                                                <Typography
                                                    style={{ marginLeft: "8px", fontWeight: "600" }}
                                                >
                                                    {row.documentType}{" "}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={1} sx={{ justifyContent: "flex-end" }}>
                                                <Icon
                                                    icon="basil:edit-outline"
                                                    style={{
                                                        color: hoverStatesSet2[index] ? "blue" : "#9389AF",
                                                        fontSize: "25px",
                                                        cursor: "pointer",
                                                        float: "right",
                                                        marginTop: "25px",
                                                    }}
                                                    onMouseEnter={() => handleMouseEnterSet2(index)}
                                                    onMouseLeave={() => handleMouseLeaveSet2(index)}
                                                    onClick={() => {
                                                        console.log("row id kya ha", row.id);
                                                        let id = row.id;
                                                        setid(row.id);

                                                        axios
                                                            .get(
                                                                `${process.env.REACT_APP_API_URL}/PatientDocument/PatientDocumentById?Id=${id}`,
                                                                {
                                                                    headers,
                                                                }
                                                            )
                                                            .then((response) => {
                                                                setOpen(true);
                                                                let date = response.data.date;
                                                                let formattedDate = Moment(date, "MM/DD/YYYY").format("YYYY-MM-DD");
                                                                setdate(formattedDate);
                                                                setSelectedType(response.data.documentType);
                                                                fields.forEach((field) => {
                                                                    console.log(
                                                                        `Field: ${field}, Data:`,
                                                                        response.data[field]
                                                                    );
                                                                    setValue(field, response.data[field]);
                                                                });
                                                            })
                                                            .catch((error) => {
                                                                console.error("There was an error!", error);
                                                            });
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={1} sx={{ justifyContent: "flex-end" }}>
                                                <Typography style={{ marginBottom: "5px" }}>
                                                    {row.date}{" "}
                                                </Typography>

                                                <>
                                                {props.datamining==='dataMinings'?(
                                                        <DeleteIcon
                                                    
                                                        style={{
                                                            color: hoverStates[index] ? "red" : "#9389AF",
                                                            fontSize: "25px",
                                                            cursor: "pointer",
                                                            float: "right",
                                                            width: '26px',
                                                            height: '23px',
                                                        }}
                                                        onMouseEnter={() => handleMouseEnter(index)}
                                                        onMouseLeave={() => handleMouseLeave(index)}
                                                        onClick={() => {
                                                            confirmAlert({
                                                                message: "Do you want to delete the record.",
                                                                closeOnEscape: true,
                                                                closeOnClickOutside: true,
                                                                overlayClassName: "overlay-custom-class-name",
                                                                buttons: [
                                                                    {
                                                                        label: "Yes",
                                                                        onClick: () => {
                                                                            const postData = {
                                                                                documentName: row.documentName,
                                                                                date: row.date,
                                                                                id: row.id,
                                                                                deleted: true,
                                                                            };
    
                                                                            axios
                                                                                .post(
                                                                                    `${process.env.REACT_APP_API_URL}/PatientDocument/PatientDocument?patientId=${patientId}`,
                                                                                    postData,
                                                                                    {
                                                                                        headers,
                                                                                    }
                                                                                )
                                                                                .then((response) => {
                                                                                    alert.success('Record Deleted successfully.')
                                                                                    setRefresh(!refresh);
                                                                                })
                                                                                .catch((error) => {
                                                                                    console.error(
                                                                                        "There was an error!",
                                                                                        error
                                                                                    );
                                                                                });
                                                                        },
                                                                    },
                                                                    {
                                                                        label: "No",
                                                                    },
                                                                ],
                                                            });
                                                        }}
                                                    />
                                                ):(<> <Button onClick={() => handleClickOpen(row.id)}>
                                                <DeleteIcon
                                                
                                                  color="red"
                                                  style={{  fontSize: "25px",
                                                  cursor: "pointer",
                                                  float: "right",
                                                    marginRight: '5px',
                                                    float: 'right',
                                                    marginTop: '-0.9rem',
                                                    width: '26px',
                                                    height: '23px',
                                                  }}
                                                />
                                              </Button>
                                              <Dialog
                                                open={openAlert}
                                                onClose={handleCloseAlert}
                                                aria-labelledby="responsive-dialog-title"
                                           
                                                  BackdropComponent={Backdrop}
                                                  BackdropProps={{
                                                    invisible: true,
                                                  }}
                                              >

<div style={{borderRadius:'8px'}}>
   
            <ErrorOutlineIcon style={{marginLeft:'125px',fontSize:'100px',color:'orange'}}></ErrorOutlineIcon>
                <DialogTitle>{"Do you want to delete the record?"}</DialogTitle>
                <h5 style={{textAlign:'center'}}>This action cannot be undone.</h5>
                <DialogContent>
                    <DialogContentText>
                    <Grid style={{marginLeft:'90px'}}>
                    <Button  onClick={handleDeleteById} style={{backgroundColor:'red',color:'white'}}>YES</Button>
                    <Button  onClick={handleCloseAlert} style={{backgroundColor:'blue',color:'white',marginLeft:'1rem'}}>NO</Button>
                    </Grid>
                    </DialogContentText>
                </DialogContent>
               
                
      
    
    
    </div>


                                      
                                              </Dialog>


                                                        {/* <DialogContent>
                                                  <DialogContentText style={{ color: 'black' }}>
                                                    Do you want to delete the record?
                                                  </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                  <Button autoFocus onClick={handleCloseAlert} style={{ color: 'black' }}>
                                                    No
                                                  </Button>
                                                  <Button onClick={handleDeleteById} style={{ color: 'black' }} autoFocus>
                                                    Yes
                                                  </Button>
                                                </DialogActions> */}
                                              </>)}
                                            



             
            </>


                                                {/* <Icon
                                                    icon="lucide:trash-2"
                                                    style={{
                                                        color: hoverStates[index] ? "red" : "#9389AF",
                                                        fontSize: "25px",
                                                        cursor: "pointer",
                                                        float: "right",
                                                    }}
                                                    onMouseEnter={() => handleMouseEnter(index)}
                                                    onMouseLeave={() => handleMouseLeave(index)}
                                                    onClick={() => {
                                                        confirmAlert({
                                                            message: "Do you want to delete the record.",
                                                            closeOnEscape: true,
                                                            closeOnClickOutside: true,
                                                            overlayClassName: "overlay-custom-class-name",
                                                            buttons: [
                                                                {
                                                                    label: "Yes",
                                                                    onClick: () => {
                                                                        const postData = {
                                                                            documentName: row.documentName,
                                                                            date: row.date,
                                                                            id: row.id,
                                                                            deleted: true,
                                                                        };

                                                                        axios
                                                                            .post(
                                                                                `${process.env.REACT_APP_API_URL}/PatientDocument/PatientDocument?patientId=${patientId}`,
                                                                                postData,
                                                                                {
                                                                                    headers,
                                                                                }
                                                                            )
                                                                            .then((response) => {

                                                                                setRefresh(!refresh);
                                                                            })
                                                                            .catch((error) => {
                                                                                console.error(
                                                                                    "There was an error!",
                                                                                    error
                                                                                );
                                                                            });
                                                                    },
                                                                },
                                                                {
                                                                    label: "No",
                                                                },
                                                            ],
                                                        });
                                                    }}
                                                /> */}
                                            </Grid>
                                        </Box>
                                    ))
                                    : "No Documents"}
                            </Grid>
                        </>
                        {/* here is the modal of upload file */}
                        <Modal
                            className="glassmorphic-card"
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={open}
                            onRequestClose={handleClose}
                            shouldCloseOnOverlayClick={false}
                        >
                            <Box
                                sx={{
                                    borderRadius: "10px",
                                    backgroundColor: "#fff",
                                    boxShadow: "0 0 24px rgba(209, 52, 81, 0.194)",
                                    maxHeight: "450px",
                                    overflowY: "auto",
                                    marginTop: "10%",
                                    marginBottom: "5%",
                                    width: "450px",
                                    padding: "15px",
                                    marginLeft: '30%'
                                }}
                            >
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography
                                        id="modal-modal-title"
                                        variant="h5"
                                        style={{ marginTop: "10px", fontWeight: "bolder" }}
                                    >
                                        Upload Documents
                                    </Typography>
                                    <IconButton onClick={handleClose} className="cancel-icon-button">
                                        <CancelTwoToneIcon />
                                    </IconButton>
                                </div>
                                <hr></hr>

                                <Grid item style={{ width: 250, marginBottom: 10, marginTop: 10 }}>
                                    {/* <Controller
                                        name="date"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                               {...field}
                                                fullWidth
                                                type="date"
                                                size="small"
                                                required
                                                // value={duedate}
                                                value={date ? date : ""}
                                                onChange={(e) => {
                                                    setValue("date", e.target.value);
                                                    setdate(e.target.value);
                                                }}
                                            />
                                        )}
                                    /> */}

<SuiInput
    type="date"
    placeholder="Enter date"
    value={date?date:Date}
    onChange={(e) => {
        setValue('date', e.target.value)
        setdate(e.target.value)
    }}
    error={errors.date}
/>

          <p style={{ color: 'red', fontSize: '14px' }}>
            {errors.date?.message}
          </p>

                                </Grid>

                                <Grid>
                                    <Button
                                        sx={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            border: "2px dashed #704AC2",
                                            padding: "10px",
                                            borderRadius: "8px",
                                            backgroundColor: "#F0ECF8",
                                            gap: "10px",
                                            height: "40px",
                                        }}
                                        component="label"
                                    >
                                        <Icon icon="emojione-v1:document" fontSize={"20px"} />
                                        Select Files
                                        <input
                                            fullWidth
                                            type="file"
                                            name="file"
                                            onInput={(e) => handleChangeforfile(e.target.files)}
                                            style={{ display: "none" }}
                                            multiple
                                        />
                                    </Button>

                                    <>
                                        {filename.length > 0 && (
                                            <div>
                                                <h3>Uploaded Files:</h3>
                                                <div
                                                    style={{
                                                        height: "100px",
                                                        width: "100%",
                                                        overflow: "scroll",
                                                    }}
                                                >
                                                    <ul>
                                                        {filename.map((name, index) => (
                                                            <>
                                                                <li>
                                                                    <Typography
                                                                        key={index}
                                                                        variant="body1"
                                                                        color="black"
                                                                    >
                                                                        {name} {savedFiles.includes(index) && "✓"}
                                                                    </Typography>
                                                                </li>
                                                            </>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </>

                                    {file && <p>Currently uploading: {file.name}</p>}
                                </Grid>
                                <Grid>
                                    {docError ? (
                                        <Typography style={{ color: "red" }}>
                                            Please select document
                                        </Typography>
                                    ) : null}
                                    {docError2 ? (
                                        <Typography style={{ color: "red" }}>
                                            Please select Date
                                        </Typography>
                                    ) : null}
                                </Grid>
                                <Grid
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        marginTop: "20px",
                                    }}
                                >
                                    {submitting ? (
                                        <Grid>
                                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                <CircularProgress />
                                            </Box>

                                            <Typography variant="bold" component="div" color="#ba000d">
                                                Please wait your data is being saved..
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <Button
                                            fullWidth
                                            onClick={handleSubmit(onSubmit)}
                                            disabled={submitting}
                                            style={{
                                                height: "40px",
                                                borderRadius: "8px",
                                                padding: "5px",
                                                border: "none",
                                                width: "100px",
                                                background:
                                                    "#f9bf1c",
                                                color: "#5B5367",
                                                gap: "10px",
                                            }}
                                        >
                                            <SaveTwoToneIcon /> Save
                                        </Button>
                                    )}

                                    <Button
                                        onClick={handleClose}
                                        disabled={submitting}
                                        style={{
                                            height: "40px",
                                            borderRadius: "8px",
                                            padding: "5px",
                                            border: "none",
                                            width: "100px",
                                            background: "#efeef1",
                                            color: "#5B5367",
                                            gap: "10px",
                                            marginLeft: "20px",
                                        }}
                                    >
                                        <Icon icon="iconoir:cancel" /> Close
                                    </Button>
                                </Grid>
                            </Box>
                        </Modal>

                        {/* View Document */}
                        <Modal
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            onRequestClose={handleClose}
                            shouldCloseOnOverlayClick={false}
                            open={opendocs}
                            className="glassmorphic-card"
                        >
                            <Box
                                style={{
                                    borderRadius: "10px",
                                    backgroundColor: "#fff",
                                    boxShadow: "0 0 24px rgba(209, 52, 81, 0.194)",
                                    // height: '500px',
                                    marginTop: "5%",
                                    marginBottom: "5%",
                                    width: "70%",
                                    marginLeft: '18%'
                                }}
                            >
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "5px",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Grid item xs={10.5}>
                                            <Typography
                                                id="modal-modal-title"
                                                variant="h6"
                                                component="h2"
                                                style={{ marginBottom: 5 }}
                                            >
                                                {FullDocName}
                                            </Typography>
                                        </Grid>
                                        {(DocName && DocName == "jpg") ||
                                            DocName == "png" ||
                                            DocName == "jpeg" ||
                                            DocName == "txt" ? (
                                            <Grid item xs={2}>
                                                {submitting ? (
                                                    <LoadingButton
                                                        loading
                                                        loadingIndicator="Downloading"
                                                        // style={{ position: 'absolute', right: '28px', bottom: '11px' }}
                                                        color="primary"
                                                        variant="contained"
                                                    >
                                                        Fetch data
                                                    </LoadingButton>
                                                ) : (
                                                    <Button
                                                        onClick={() => {
                                                            download(`${urldownload}`, FullDocName);
                                                        }}
                                                        variant="contained"
                                                        disabled={submitting}
                                                        style={{
                                                            height: "40px",
                                                            borderRadius: "8px",
                                                            padding: "5px",
                                                            border: "none",

                                                            background:
                                                                "linear-gradient(180deg, rgb(209, 52, 80) 0%, rgb(209, 52, 80) 0%, rgb(240, 130, 49) 100%)",
                                                            color: "#fff",
                                                            gap: "10px",
                                                        }}
                                                    >
                                                        <Download /> Download
                                                    </Button>
                                                )}
                                            </Grid>
                                        ) : (
                                            ""
                                        )}
                                        <Grid item xs={0.5}>
                                            <IconButton
                                                onClick={handleClose}
                                                className="cancel-icon-button"
                                            >
                                                <CancelTwoToneIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>

                                    {(DocName && DocName == "jpg") ||
                                        DocName == "png" ||
                                        DocName == "jpeg" ? (
                                        <>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    height: "430px",
                                                    alignItems: "center",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {urlFile ? (
                                                    <img
                                                        style={{
                                                            maxWidth: "100%",
                                                            maxHeight: "100%",

                                                            display: "block",
                                                            margin: "0 auto",
                                                        }}
                                                        src={`${urlFile}`}
                                                    ></img>
                                                ) : (
                                                    <CircularProgress />
                                                )}
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    height: "430px",
                                                    alignItems: "center",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {urlFile ? (
                                                    <iframe
                                                        height="430px"
                                                        width="100%"
                                                        src={`${urlFile}`}
                                                    ></iframe>
                                                ) : (
                                                    <CircularProgress />
                                                )}
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Box>
                        </Modal>
                    </Grid>
                </Grid>
            </Grid>
        </>


    </>

    );
}

export default PatientDocuments;
