import Modal from '@mui/material/Modal'
import {
  CircularProgress,
  Grid,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import axios from 'axios'
import React, { useEffect, useReducer, useState, Fragment, useRef } from 'react'
import Addmember from './Addmember'
import CareTeamCard from './CareTeamCard'
import Button from '@mui/material/Button'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import SuiBox from 'components/SuiBox'
import Box from '@mui/material/Box'
import SuiTypography from 'components/SuiTypography'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'

function PatientCareTeam(props) {
  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
const readOnly=props.readOnly
  const headers = {
    Authorization: auth,
  }
  const [addMember, setAddMember] = useState(false)
  const [postData, setPostData] = useState({});
  const handleOpenAddMember = () =>{ setAddMember(true)
  setId('')
  }
  const handleCloseAddMember = () => {setAddMember(false)
     setId('')}
  const [EditModal, setEditModal]=useState(false)
  const [careTeamMembers, setCareTeamMembers] = useState([])
  const [id, setId]=useState('')
  const _patientId = props.patientId
const [refresh, setRefresh]=useState(false)
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/CareTeamMembers/getCareTeamMembers?patientid=${_patientId}`,
        { headers }
      )
      .then((response) => {
        console.log('Respnse: ', response.data)
        setCareTeamMembers(response.data)
         setPostData(response.data)
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }, [refresh])
  const styleAddMember = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: '#F0FFFF',
    border: '2px solid black',
    boxShadow: 24,
    p: 2,
    overflow: 'auto',
    }
    const applyRefresh=()=>
    {
        setRefresh(!refresh)
    }
    const openMemberModal=(memberId)=>
    {
      setId(memberId);
        setAddMember(true);
    }
 
   return (
    <>
      <Modal
        open={addMember}
        onClose={handleCloseAddMember}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleAddMember}>
          {/* <CancelOutlinedIcon
                fontSize="medium"
                style={{  paddingRight: '5px' }}
              ></CancelOutlinedIcon> */}
          <SuiTypography
            component="label"
            variant="caption"
            fontSize="20px"
            style={{ fontWeight: 'bold' }}
          >
            Add Member
          </SuiTypography>
          <CancelOutlinedIcon
            onClick={() => { setAddMember(false)
              setId('')
            }}
            variant="contained"
            style={{
              float: 'right',
              color: 'red',
            }}
          ></CancelOutlinedIcon>

          <Grid container columnSpacing={1}>
            <Addmember
              patientId={_patientId}
              readOnly={readOnly}
              cancelModal={setAddMember}
              refresh={applyRefresh}
              careTeamMemberId={id}
            ></Addmember>
          </Grid>
        </Box>
      </Modal>
         <SuiBox mb={3}>
          <Button
            sx={{ mb: 5, width: 180 }}
            onClick={handleOpenAddMember}
            variant="gradient"
            // disabled={readOnly==true?true:false}
            style={{
              backgroundColor: '#FCBE13',
              color: 'black',
              fontSize: '14px',
            }}
          >
            <AddOutlinedIcon
              fontSize="small"
              style={{
                color: '#1a82ad',
                paddingRight: '4px',
                paddingBottom: '2px',
              }}
            ></AddOutlinedIcon>
            Add Members
          </Button>
    
          <Grid  container style={{justifyContent:'center'}}>
            
            {careTeamMembers != null && careTeamMembers.length > 0
              ? careTeamMembers.map((row, index) => 
              {
              return <CareTeamCard
              photoUrl={row.photoUrl}
              firstName={`${row.lastName}, ${row.firstName}`}
              role={`${row.role}`}
              phoneNo={`${row.phoneNo}`}
              responsiblities={`${row.responsiblities}`}
              postData={row}
              
              refresh={applyRefresh}
              openModal={openMemberModal}
              // careTeamMemberId={setId}
              />
            }
            )
              : 'No Record Found'}
          </Grid>
          </SuiBox>
      </>
  )
}
export default PatientCareTeam
