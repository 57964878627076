/**
=========================================================
* Soft UI Dashboard React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from 'react'

// react-router-dom components
import { Link } from 'react-router-dom'

// @mui material components
import Switch from '@mui/material/Switch'

// Soft UI Dashboard React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiInput from 'components/SuiInput'
import SuiButton from 'components/SuiButton'
import { Grid,Box,InputAdornment , TextField } from '@mui/material'
import { useAlert } from 'react-alert'
// import background from 'assets/backgroundimage.jpeg'
import background from 'assets/background.png'

// import TextField from '@mui/material/TextField';
// Authentication layout components
import CoverLayout from 'layouts/authentication/components/CoverLayout'
import Input from '@mui/material/Input'
// Images
import axios from 'axios'
import {  IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { createBrowserHistory } from 'history'
import { bgcolor } from '@mui/system'
import logo from 'assets/logo.png'
import CircularProgress from '@mui/material/CircularProgress'
import { getUserPractices } from 'redux/Actions/usersActions'
import { getAllProviders } from 'redux/Actions/allProvidersActions'
import { getProvidersNames } from 'redux/Actions/providersActions'
import { getFacilities } from 'redux/Actions/facilitiesActions'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
const history = createBrowserHistory({ forceRefresh: true })

function SignIn() {
  const [rememberMe, setRememberMe] = useState(true)
  const [username, setUserName] = useState()
  const [pwrd, setPwrd] = useState()
  const handleSetRememberMe = () => setRememberMe(!rememberMe)
  const alert = useAlert()

  const [wrongPassword, setwrongPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const [submitting, setSubmitting] = useState(false)
  const keyDown = (e) => {
    if (e.key === 'Enter') {
      console.log('do validate')
      signInClicked(e)
    }
  }
  const [callRedux, setCallRedux] = useState(false)
  const dispatch = useDispatch()

  const signInClicked = (event) => {
    setSubmitting(true)
    setwrongPassword(false)

    event.preventDefault()

    const postData = {
      emailAddress: username,
      password: pwrd,
    }

    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    }

    // console.log('PostData: ', postData)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Users/login`,
        postData,
        axiosConfig
      )
      
      .then((response) => {
        console.log('login--res', response.data.provider_id)
        console.log('login--res', response.data.providerTitle)
        if (response.data) {
          localStorage.setItem('dtt_token', response.data.token)
       
          localStorage.setItem('dtt_user_type', response.data.userType)
          //localStorage.setItem('dtt_user_provider_type', "Behavioral Health Care Manager")
          localStorage.setItem('dtt_user_provider_type', response.data.providerTitle)
          localStorage.setItem('dtt_user_email', response.data.email)
          localStorage.setItem('dtt_user', response.data.email)
          localStorage.setItem('dtt_user_name', response.data.fullName)
          localStorage.setItem('dtt_user_img', response.data.photoUrl)
          localStorage.setItem(
            'dtt_user_provider_id',
            response.data.provider_id
          )
          localStorage.setItem(
            'dtt_isPsychotherapyPriviledges',
            response.data.psychastricPhysician
          )
          localStorage.setItem(
            'dtt_user_default_facility',
            response.data.defaultFacilityId
          )
          localStorage.setItem(
            'dtt_user_default_practice',
            response.data.defaultPracticeId
          )
          localStorage.setItem('dtt_userType', response.data.userType)
          history.replace('/PatientSearch')
          // setCallRedux(true);
        } else {
          setwrongPassword(true)
          /*   alert.success(`Opps!! Wrong EmailAddress or Password`, {
            type: 'error',
          }) */
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
  }
  // useEffect(() => {
  //   if(callRedux)
  //   dispatch(getUserPractices())
  // }, [dispatch,callRedux])

  // useEffect(() => {
  //   if(callRedux)
  //   dispatch(getAllProviders())
  // }, [dispatch,callRedux])

  // useEffect(() => {
  //   if(callRedux)
  //   dispatch(getFacilities())
  // }, [dispatch,callRedux])

  // useEffect(() => {
  //   if(callRedux)
  //   dispatch(getProvidersNames())
  // }, [dispatch,callRedux])

  return (
    <Grid
      item
      xs={12}
      lg={12}
      md={12}
      sm={12}
      container
      style={{overflow:'scroll',
        width: '100%',
        height: '100vh',
        overflow: 'scroll',
        // paddingBottom: '10px',
      }}
    >
      {submitting ? (
        <CircularProgress
          color="info"
          style={{
            width: '50px',
            height: '50px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginLeft: '-25px',
            marginTop: '-25px',
          }}
        />
      ) : null}

      <Grid
        item
        xs={12}
        lg={6}
        md={6}
        sm={12}
        container
        // className="full-height"
        justifyContent="center"
        style={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          width: `100vw`,
          display: 'flex',
          overflow:'scroll',
        }}
      >
        <Grid justifyContent="center" item xs={2} md={2} lg={2}></Grid>
        <Grid
          xs={12}
          lg={6}
          sm={12}
          md={12}
          justifyItems="center"
          alignItems="center"
          style={{overflow:'scroll',
            position: 'absolute',
            bottom: '91px',
            justifyItems: 'center',
            alignItems: 'center',
          }}
        >
          <SuiBox
            component="form"
            justifyItems="center"
            alignItems="center"

            // sx={{ width: 550 }}
          >
            <SuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              color="white"
              fontSize="30px"
              marginLeft="10px"
              style={{ justifyItems: 'center', alignItems: 'center' }}
            >
              "Working Together to Enrich the Lives
              <br />
              &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;of Those
              in Need"
              <br />
            </SuiTypography>
          </SuiBox>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        lg={6}
        md={6}
        sm={12}
        container
        // className="full-height"
        justifyContent="center"
        // style={{
        //   // backgroundImage: `url(${background})`,
        //   backgroundRepeat: 'no-repeat',
        //   backgroundPosition: 'center',
        //   backgroundSize: 'cover',
        //   width: `100vw`,
        //   height: '100vh',
        // }}
        style={{ background: 'linear-gradient(#C0CD49, #3EA8C8)', }}
      >
        <Grid justifyContent="center" item lg={2}></Grid>

        <Grid
          justifyContent="center"
          alignItems="center"
          item
          xs={12}
          md={4}
          lg={4}
          style={{
            height: '100vh',
            // overflow: 'hidden',
          }}
        >
          <SuiBox
            component="form"
            justifyItems="center"
            alignItems="center"
            sx={{ width: 300, marginLeft: '36px' }}
          >
            <SuiBox mt={3} mb={1} alignItems="center">
              <img src={logo} style={{ width: '304px' }} />
            </SuiBox>
            <SuiBox mt={-3}>
              <SuiBox >
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="black"
                  fontSize="20px"
                  alignItems="center"
                  justifyContent="center"
                >
                  Have an Account?
                </SuiTypography>
              </SuiBox>
              <SuiBox ml={0.5}>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="warning"
                >
                  Email
                </SuiTypography>
              </SuiBox>

              <SuiInput
                style={{
                  border: '5px solid rgb(12,70,141)',
                  color: 'rgb(44,117,130)',
                  backgroundColor: 'warning',
                }}
                //  border= '1px solid #e2e2e1'
                // bgColor="info"
                opacity=".2"
                type="email"
                onKeyDown={keyDown}
                placeholder="Email"
                onChange={(e) => setUserName(e.target.value)}
              />
              {wrongPassword ? (
                <SuiTypography
                  variant="text"
                  color="error"
                  fontWeight="regular"
                >
                  &nbsp;&nbsp;Wrong email or password.
                </SuiTypography>
              ) : null}
            </SuiBox>
            <SuiBox mb={2}>
              <SuiBox  ml={0.5}>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  color="warning"
                >
                  Password
                </SuiTypography>
              </SuiBox>
              <Box position="relative" display="inline-flex" width="100%">
      <SuiInput
        style={{ border: '5px solid rgb(12,70,141)', width: '100%' }}
        color="info"
        type={showPassword ? 'text' : 'password'}
        placeholder="Password"
        onKeyDown={keyDown}
        onChange={(e) => setPwrd(e.target.value)}
      />
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => setShowPassword(!showPassword)}
        edge="end"
        style={{ position: 'absolute', right: 25, top: '50%', transform: 'translateY(-50%)' }}
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </Box>
    </SuiBox>
            <SuiBox display="flex" alignItems="center">
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <SuiTypography
                variant="button"
                fontWeight="regular"
                onClick={handleSetRememberMe}
                sx={{ cursor: 'pointer', userSelect: 'none' }}
              >
                &nbsp;&nbsp;Remember me
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={1} mb={1} ml={15} aligncontent="center">
              <SuiButton
                style={{
                  color: 'rgb(44,117,130)',
                  // bgcolor:"rgb(255,189,18)",
                  borderRadius: '30px',
                }}
                disabled={submitting}
                variant="contained"
                // bgColor="rgb(255,189,18)"
                color="warning"
                onClick={signInClicked}
                // fullWidth
                // size="small"
              >
                LOG IN
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </Grid>
        <Grid justifyContent="center" item xs={2} md={2} lg={2}></Grid>
      </Grid>
    </Grid>
  )
}

export default SignIn
