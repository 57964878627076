// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { useAlert } from "react-alert";

import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import input from "@mui/material/Input";
import { Grid, Snackbar, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useReducer, useState, Fragment } from "react";
import { createBrowserHistory } from "history";
import Checkbox from "@mui/material/Checkbox";
import { useLocation } from "react-router-dom";
import ReactSelect from "react-select";
import { Controller, useForm } from "react-hook-form";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const history = createBrowserHistory({ forceRefresh: true });
const fields = [
  "lastname",
  "firstname",
  "dob",
  "state",
  "gender",
  "RELATIONSHIP",
  "phoneno",
  "cellphone",
  "address",
  "city",
  "zipcode",
  "ssn",
];
const defValues = {
  firstname: "",
  lastname: "",
  dob: "",
  gender: "",
  RELATIONSHIP: "",
  phoneno: "",
  cellphone: "",
  address: "",
  city: "",
  state: "",
  zipcode: "",
  ssn: "",
};
function FacilityRegistration() {
  const location = useLocation();
  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const auth = `Bearer  ${localStorage.getItem("dtt_token")}`;
  const headers = {
    Authorization: auth,
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    shortName: Yup.string().required("Short Name is required"),
    type: Yup.string().required("Type is required"),
    npi: Yup.string().required("NPI is required"),
    emailAddress: Yup.string().required("Email is required").email("Email is invalid"),
  });
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  });
  const onSubmit = (data) => {
    setSubmitting(true);
    const postData = data;
    console.warn("Data: ", postData);
    if (location.state.facilityId !== undefined && location.state.facilityId != null)
      postData.Id = location.state.facilityId;
    else postData.Id = 0;
    axios
      .post(`${process.env.REACT_APP_API_URL}/Facilities/addFacility`, postData, { headers })
      .then((response) => {
        setSubmitting(false);
        alert.success("Record saved successfully.", {
          type: "success",
          onClose: () => {
            history.push("/facilities");
          },
        });
      })
      .catch((error) => {
        setSubmitting(false);
        alert.success(`Error ${error.message}`, {
          type: "error",
        });
      });
  };
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    let isComponentMounted = true;
    const fetchData = async () =>
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/Facilities/getFacilityById?id=${
            location.state.facilityId !== undefined ? location.state.facilityId : 0
          }`,
          null,
          {
            headers,
          }
        )
        .then((response) => {
          console.log("response.data: ", response.data);
          if (isComponentMounted) {
            fields.forEach((field) => setValue(field, response.data[field]));
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    fetchData();
    return () => {
      isComponentMounted = false;
    };

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  return (
    <>
      {submitting && <div>Saving Form...</div>}
      <Grid
        container
        spacing={1}
        alignContent="center"
        justifyContent="center"
        style={{ paddingTop: "10px", paddingLeft: "10px", paddingRight: "100px" }}
      >
        <Grid item xs={4} style={{ width: "300px" }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            Last Name
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: "300px" }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            First Name
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: "300px" }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            DOB
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: "300px" }}>
          <SuiInput
            placeholder="Please enter Last Name"
            name="lastname"
            label="lastname"
            fullWidth
            margin="dense"
            {...register("lastname")}
            error={errors.lastname}
          />
          <p style={{ color: "red" }}>{errors.name?.message}</p>
        </Grid>
        <Grid item xs={4} style={{ width: "300px" }}>
          <SuiInput
            placeholder="Please enter First Name"
            name="FirstName"
            label="FirstName"
            fullWidth
            margin="dense"
            {...register("firstname")}
            error={errors.firstname}
          />
        </Grid>
        <Grid item xs={4} style={{ width: "300px" }}>
          <SuiInput
            type="date"
            placeholder="Please enter DOB"
            name="type"
            label="Type"
            fullWidth
            margin="dense"
            {...register("type")}
            error={errors.type}
          />
        </Grid>
        <Grid item xs={4} style={{ width: "300px" }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            Gender
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: "300px" }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            RELATIONSHIP
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: "300px" }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            PHONE NUMBER
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: "300px" }}>
          <Controller
            name="gender"
            {...register("gender")}
            control={control}
            render={({ field }) => (
              <ReactSelect
                isClearable
                {...field}
                options={[
                  { value: "Male", label: "Male" },
                  { value: "Female", label: "Female" },
                  { value: "Other", label: "Other" },
                ]}
              />
            )}
          />
        </Grid>
        <Grid item xs={4} style={{ width: "300px" }}>
          <SuiInput
            placeholder="Please enter RELATIONSHIP"
            name="RELATIONSHIP"
            label="RELATIONSHIP"
            fullWidth
            margin="dense"
            {...register("RELATIONSHIP")}
            error={errors.RELATIONSHIP}
          />
        </Grid>
        <Grid item xs={4} style={{ width: "300px" }}>
          <SuiInput
            placeholder="Please enter PHONE NUMBER"
            name="PHONE NUMBER"
            label="PHONE NUMBER"
            fullWidth
            margin="dense"
            {...register("phoneno")}
            error={errors.phoneno}
          />
        </Grid>
        <Grid item xs={4} style={{ width: "300px" }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            CELLPHONE
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: "300px" }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            ADDRESS
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: "300px" }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            City
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: "300px" }}>
          <SuiInput
            type="input"
            placeholder="Enter cellphone "
            name="cellphone"
            {...register("cellphone")}
          />
        </Grid>
        <Grid item xs={4} style={{ width: "300px" }}>
          <SuiInput
            type="input"
            placeholder="Enter Address"
            name="address"
            {...register("address")}
          />
        </Grid>
        <Grid item xs={4} style={{ width: "300px" }}>
          <SuiInput
            placeholder="Please enter City"
            name="city"
            label="city"
            fullWidth
            margin="dense"
            {...register("city")}
            error={errors.city}
          />
        </Grid>
        <Grid item xs={4} style={{ width: "300px" }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            State
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: "300px" }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            ZIP Code
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: "300px" }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            SSN
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: "300px" }}>
          <SuiInput
            type="input"
            placeholder="Enter State"
            name="zipCode"
            {...register("state")}
            error={errors.state}
          />
        </Grid>
        <Grid item xs={4} style={{ width: "300px" }}>
          <SuiInput
            type="input"
            placeholder="Enter Zip Code"
            name="zipcode"
            {...register("zipcode")}
            error={errors.zipcode}
          />
        </Grid>
        <Grid item xs={4} style={{ width: "300px" }}>
          <SuiInput
            type="input"
            placeholder="Enter SSn"
            name="zipcode"
            {...register("ssn")}
            error={errors.ssn}
          />
        </Grid>

        <SuiButton
          onClick={handleSubmit(onSubmit)}
          variant="gradient"
          disabled={submitting}
          color="dark"
          style={{
            marginTop: "10px",
            float: "right",
            marginLeft: "auto",
          }}
        >
          Save
        </SuiButton>
      </Grid>
    </>
  );
}

export default FacilityRegistration;
