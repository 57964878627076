
import {
    CircularProgress,
    Grid,
    InputAdornment,
    Snackbar,
    TextField,
    Typography,
} from '@mui/material'
import axios from 'axios'
import React, { useEffect, useReducer, useState, Fragment, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { TabPanel, TabContext, TabList } from '@mui/lab'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import { useAlert } from 'react-alert'
import { DataGrid } from '@mui/x-data-grid'
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import input from '@mui/material/Input'
import Moment from 'moment'
import Avatar from '@mui/material/Avatar'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import SuiInput from "components/SuiInput";

const MedicationForm = (props) => {
    const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }
  const validationSchema = Yup.object({
    // name: Yup.string().required("name is required"),
    // severity: Yup.string().required("severity is required"),

  });
  const alert = useAlert()
const patientId=props.patientId

const fields =[
    "id",
    "patientId",
    "practiceId",
    "isActive",
    "name",
    "dose",
    "prescribedBy",
    "purpose",
    "createdDate",
    "updatedDate",
    "startDate",
    "endDate",
    "createdBy",
    "updatedBy"
]
    const defValues = {
        id: 0,
        patientId: 0,
        practiceId: 0,
        isActive: '',
        name:'',
        dose:'',
        prescribedBy:'',
        purpose:'',
        createdDate:'',
        updatedDate:'',
        startDate:'',
        endDate:'',
        createdBy:'',
        updatedBy:''
    }
    const {
        register,
        control,
        handleSubmit,
        setValue,
        reset,
        getValues,
        formState: { errors },
      } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: false,
        shouldUseNativeValidation: false,
        delayError: undefined,
        resolver: yupResolver(validationSchema),
        defaultValues: defValues,
      });
      const [refresh, setRefresh] = React.useState(false)
      const [submitting, setSubmitting] = useState(false)


      const onSubmit = (data) => {   
        setSubmitting(true)
    
        const postData = {
          ...data,
          patientId:props.patientId
        }
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/PatientMedicalNotes/AddMedications`,
            postData,
            { headers }
          )
          .then((response) => {
            setRefresh(!refresh)
            setSubmitting(false)
    
             alert.success('Record saved successfully.', {
              type: 'success',
              // onClose: () => {
              //   setOpenNote(false)
              //   setSubmitting(false)
              // },
            })
          })
          .catch((error) => {
            setSubmitting(false)
            alert.success(`Error ${error.message}`, {
              type: 'error',
            })
          })
      }

      useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/PatientMedicalNotes/getMedicationsByPatientId?patientId=${props.patientId}`, { headers })
            .then(response => { 
                console.log('API Response:', response.data); // Debug log
                fields.forEach((field) => setValue(field, response.data[field]));
                
                // Format endDate
                const endDate = Moment(response.data.endDate).format("YYYY-MM-DD");
                setValue('endDate', endDate);
    
                // Format identifiedDate
                    const startDate = Moment(response.data.startDate).format("YYYY-MM-DD");
                    setValue('startDate', startDate);
              
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, [props.patientId]);
    
    
    


    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                        Name
                    </Typography>
                    <Controller
                        name="name"
                        {...register("name")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // isClearable
                                size="small"
                                placeholder="Enter name "
                                {...field}
                                fullWidth
                                // error={errors.name}
                                />
                              )}
                            />
                            {/* <p style={{ color: 'red', fontSize: '14px' }}>{errors.status?.name}</p> */}
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                     Dose
                    </Typography>
                    <Controller
                        name="dose"
                        {...register("dose")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // isClearable
                                size="small"
                                placeholder="Enter Dose "
                                {...field}
                                fullWidth

                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                    Prescribed By
                    </Typography>
                    <Controller
                        name="prescribedBy"
                        {...register("prescribedBy")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // isClearable
                                size="small"
                                placeholder="Enter prescribedBy "
                                {...field}
                                fullWidth
                                // error={errors.severity}
                                />
                              )}
                            />
                            {/* <p style={{ color: 'red', fontSize: '14px' }}>{errors.status?.severity}</p> */}
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                    Purpose
                    </Typography>
                    <Controller
                        name="purpose"
                        {...register("purpose")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // isClearable
                                size="small"
                                placeholder="Enter purpose "
                                {...field}
                                fullWidth

                            />
                        )}
                    />
                </Grid>
             
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                      Start Date

                    </Typography>
                 
                            <SuiInput
                            type="date"
                            name="startDate"
                            fullWidth
                            margin="dense"
                            {...register("startDate")}
                          />    
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                        End Date
                    </Typography>
                    <SuiInput
                    type="date"
                    name="endDate"
                    fullWidth
                    margin="dense"
                    {...register("endDate")}

                  />    
                </Grid>
             

<Grid container spacing={2}>
    <Grid item xs={12}>
    <Button
              onClick={handleSubmit(onSubmit)}
                      variant="gradient"
                      style={{
                        marginTop: '10px',
                        marginBottom: '10px',
                        float: 'right',
                        marginRight: '5px',
                        marginLeft: 'auto',
                        width: '80px',
                        height: '35px',
                        backgroundColor: '#FCBE13',
                        color: 'black',
                        fontSize: '14px',
                      }}
                    >
                      {/* <SaveIcon
                        fontSize="medium"
                        style={{ color: '#0a9ff0', paddingRight: '5px' }}
                      ></SaveIcon> */}
                      Save
                    </Button>
    </Grid>
</Grid>
            </Grid>

        </>
    )
}

export default MedicationForm
