import PdfDocsCard from './PdfDocsCard'
import { Grid, Typography } from '@mui/material'
import { createBrowserHistory } from 'history'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
// typescript style
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { arSD } from '@mui/material/locale'
import Button from '@mui/material/Button'
// import Pdf from '../Documents/test.pdf'
import { Document, Page } from 'react-pdf'
import Modal from '@mui/material/Modal'
import { confirmAlert } from 'react-confirm-alert'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import DeleteIcon from '@mui/icons-material/Delete'
import SuiTypography from 'components/SuiTypography'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import SuiInput from 'components/SuiInput'
import { DataGrid } from '@mui/x-data-grid'
import SuiBox from 'components/SuiBox'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Avatar from '@mui/material/Avatar'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import { Controller, set, useForm } from 'react-hook-form'
import { useAlert } from 'react-alert'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import CircularProgress from '@mui/material/CircularProgress'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import Checkbox from '@mui/material/Checkbox'
import { CheckBox } from '@mui/icons-material'
import Switch from '@mui/material/Switch'
import Audit_C from './Screening/Audit_C'
import CIDI from './Screening/CIDI'
import CRAFFT from './Screening/Crafft'
import SuiButton from 'components/SuiButton'
import DAST from './Screening/Dast'
import Mdq from './Screening/Mdq'
import PCL from './Screening/PCL'
import YBOCS from './Screening/YBOCS'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import DialogContentText from '@mui/material/DialogContentText';
import { Backdrop } from '@mui/material';
const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    right: 20,
    bottom: 20,
  },
  container: {
    width: 900,
    // height: '100%',
    height: 'fit-content',
    backgroundColor: 'white',
    position: 'absolute',
    display: 'flex',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    // overflow:'auto',
    padding: '10px',
    margin: 'auto',
  },
  form: {
    padding: theme.spacing(2),
  },
  item: {
    marginBottom: theme.spacing(2),
  },
}))
const styleDetails = {
  width: 1200,
  height: '95%',
  bgcolor: '#F0FFFF',
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 30,
  right: 0,
  margin: 'auto',
  marginTop: '10px',
  paddingBottom: '10px',
  padding: '20px',
  overflow: 'auto',
}
export default function ToDos(props) {
  const readOnly = props.readOnly
  const patientName = props.patientName
  const classes = useStyles()
  const [postData, setPostData] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [activeButton, setActiveButton] = useState(false)
  const [images, setImages] = React.useState(null)
  const [refresh, setRefresh] = React.useState(false)
  const [openDetails, setOpenDetails] = React.useState(false)
  const [modalID, setModalID] = React.useState('')
  const alert = useAlert()
  const [submitting, setSubmitting] = useState(false)
  const [openAlert, setOpenAlert] = useState(false);
  const [itemId, setItemId] = useState('')
  // const Input = styled('input')({
  //   display: 'block',
  // })
  const [rowId, setRowId] = useState('')
  console.log(rowId,'rowId')
  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenDetails(false)
  }
  const handleDeleteConfirm = (cell) => {
    console.log(cell,'cellcell')
    {itemId == 1 ? (
       confirmAlert({
      message: 'Do you want to delete the record.',
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: 'overlay-custom-class-name',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const postData = {
              id: cell.row.id,
              deleted: true,
        
          
              // userId: cell.row.userId,
            };
  
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/PatientPortal/addCIDI12`,
                postData,
                {
                  headers,
                }
              )
              .then((response) => { handleClick(patientId, 1);
                setRefresh(!refresh);
                setOpenAlert(false)
                alert.success('Record Deleted successfully')
                // setOpenNote(false)
                // console.log('response.data: ', response.data)
              })
              .catch((error) => {
                console.error('There was an error!', error);
              });
          },
        },
        {
          label: 'No',
        },
      ],
    }))
    :null}
    {itemId == 2 ? (
      
      confirmAlert({
        message: 'Do you want to delete the record.',
        closeOnEscape: true,
        closeOnClickOutside: true,
        overlayClassName: 'overlay-custom-class-name',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              const postData = {
                id: cell.row.id,
                deleted: true,
          
            
                // userId: cell.row.userId,
              };
    
              axios
                .post(
                  `${process.env.REACT_APP_API_URL}/PatientPortal/addAuditc3`,
                  postData,
                  {
                    headers,
                  }
                )
                .then((response) => { 
                  setRefresh(!refresh);
                  alert.success('Record Deleted successfully')
                  handleClick(patientId, 2);
                })
                .catch((error) => {
                  console.error('There was an error!', error);
                });
            },
          },
          {
            label: 'No',
          },
        ],
      })
    )
    :null}
    {itemId == 3 ? (
       confirmAlert({
      message: 'Do you want to delete the record.',
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: 'overlay-custom-class-name',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const postData = {
              id: cell.row.id,
              deleted: true,
        
          
              // userId: cell.row.userId,
            };
  
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/PatientPortal/addCraft9`,
                postData,
                {
                  headers,
                }
              )
              .then((response) => {    handleClick(patientId, 3);
                setRefresh(!refresh);
                alert.success('Record Deleted successfully')
                // setOpenNote(false)
                // console.log('response.data: ', response.data)
              })
              .catch((error) => {
                console.error('There was an error!', error);
              });
          },
        },
        {
          label: 'No',
        },
      ],
    }))
    :null}
    {itemId == 4 ? (
      confirmAlert({
        message: 'Do you want to delete the record.',
        closeOnEscape: true,
        closeOnClickOutside: true,
        overlayClassName: 'overlay-custom-class-name',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              const postData = {
                id: cell.row.id,
                deleted: true,
          
            
                // userId: cell.row.userId,
              };
    
              axios
                .post(
                  `${process.env.REACT_APP_API_URL}/PatientPortal/addDast10`,
                  postData,
                  {
                    headers,
                  }
                )
                .then((response) => {    handleClick(patientId, 4);
                  setRefresh(!refresh);
                  alert.success('Record Deleted successfully')
                  // setOpenNote(false)
                  // console.log('response.data: ', response.data)
                })
                .catch((error) => {
                  console.error('There was an error!', error);
                });
            },
          },
          {
            label: 'No',
          },
        ],
      })
    )
    :null}
    {itemId == 5 ?(
      confirmAlert({
        message: 'Do you want to delete the record.',
        closeOnEscape: true,
        closeOnClickOutside: true,
        overlayClassName: 'overlay-custom-class-name',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              const postData = {
                id: cell.row.id,
                deleted: true,
          
            
                // userId: cell.row.userId,
              };
    
              axios
                .post(
                  `${process.env.REACT_APP_API_URL}/PatientPortal/addMdq17`,
                  postData,
                  {
                    headers,
                  }
                )
                .then((response) => {    handleClick(patientId, 5);
                  setRefresh(!refresh);
                  alert.success('Record Deleted successfully')
                  // setOpenNote(false)
                  // console.log('response.data: ', response.data)
                })
                .catch((error) => {
                  console.error('There was an error!', error);
                });
            },
          },
          {
            label: 'No',
          },
        ],
      })
    )
    :null}
    {itemId == 6 ? (
      confirmAlert({
        message: 'Do you want to delete the record.',
        closeOnEscape: true,
        closeOnClickOutside: true,
        overlayClassName: 'overlay-custom-class-name',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              const postData = {
                id: cell.row.id,
                deleted: true,
          
            
                // userId: cell.row.userId,
              };
    
              axios
                .post(
                  `${process.env.REACT_APP_API_URL}/PatientPortal/addPcl20`,
                  postData,
                  {
                    headers,
                  }
                )
                .then((response) => {    handleClick(patientId, 6);
                  setRefresh(!refresh);
                  alert.success('Record Deleted successfully')
                  // setOpenNote(false)
                  // console.log('response.data: ', response.data)
                })
                .catch((error) => {
                  console.error('There was an error!', error);
                });
            },
          },
          {
            label: 'No',
          },
        ],
      })
    )
    :null}
    {itemId == 7 ?(
      confirmAlert({
        message: 'Do you want to delete the record.',
        closeOnEscape: true,
        closeOnClickOutside: true,
        overlayClassName: 'overlay-custom-class-name',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              const postData = {
                id: cell.row.id,
                deleted: true,
          
            
                // userId: cell.row.userId,
              };
    
              axios
                .post(
                  `${process.env.REACT_APP_API_URL}/PatientPortal/addYbocs90`,
                  postData,
                  {
                    headers,
                  }
                )
                .then((response) => {    handleClick(patientId, 7);
                  setRefresh(!refresh);
                  alert.success('Record Deleted successfully')
                  // setOpenNote(false)
                  // console.log('response.data: ', response.data)
                })
                .catch((error) => {
                  console.error('There was an error!', error);
                });
            },
          },
          {
            label: 'No',
          },
        ],
      })
    ):null}
  };
  const handleClickOpen = (cell) => {

    setOpenAlert(true);
  };
  const handleCloseAlert = (cell) => {

    setOpenAlert(false);
  };
  const handleDeleteConfirm1 = (cell) => {
    console.log(rowId, 'cellcell,,');
    
    // handleDeleteConfirm(cell)
    const postData = {
      id: rowId,
      deleted: true,
      // Add other properties as needed
    };
  
    console.log('Deleting record...', postData);
    if (itemId === 1)
  {
    axios
      .post(`${process.env.REACT_APP_API_URL}/PatientPortal/addCIDI12`, postData, {
        headers,
      })
      .then((response) => {
        setRefresh(!refresh);
        setOpenAlert(false);
     
  
        // Assuming `patientId` is accessible in this scope
        handleClick(patientId, 1);
  
        console.log('Record deleted successfully', response);
        // Add any other logic you need
      })
      .catch((error) => {
        console.error('Error deleting record!', error);
      })
    } else {}
    if (itemId === 2)
    {
      axios
        .post(`${process.env.REACT_APP_API_URL}/PatientPortal/addAuditc3`, postData, {
          headers,
        })
        .then((response) => {
          setRefresh(!refresh);
          setOpenAlert(false);
       
    
          // Assuming `patientId` is accessible in this scope
          handleClick(patientId, 2);
    
          console.log('Record deleted successfully', response);
          // Add any other logic you need
        })
        .catch((error) => {
          console.error('Error deleting record!', error);
        })
      } else {}
      if (itemId === 3)
      {
        axios
          .post(`${process.env.REACT_APP_API_URL}/PatientPortal/addCraft9`, postData, {
            headers,
          })
          .then((response) => {
            setRefresh(!refresh);
            setOpenAlert(false);
         
      
            // Assuming `patientId` is accessible in this scope
            handleClick(patientId, 3);
      
            console.log('Record deleted successfully', response);
            // Add any other logic you need
          })
          .catch((error) => {
            console.error('Error deleting record!', error);
          })
        } else {}
        if (itemId === 4)
        {
          axios
            .post(`${process.env.REACT_APP_API_URL}/PatientPortal/addDast10`, postData, {
              headers,
            })
            .then((response) => {
              setRefresh(!refresh);
              setOpenAlert(false);
           
        
              // Assuming `patientId` is accessible in this scope
              handleClick(patientId, 4);
        
              console.log('Record deleted successfully', response);
              // Add any other logic you need
            })
            .catch((error) => {
              console.error('Error deleting record!', error);
            })
          } else {}
          if (itemId === 5)
          {
            axios
              .post(`${process.env.REACT_APP_API_URL}/PatientPortal/addMdq17`, postData, {
                headers,
              })
              .then((response) => {
                setRefresh(!refresh);
                setOpenAlert(false);
             
          
                // Assuming `patientId` is accessible in this scope
                handleClick(patientId, 5);
          
                console.log('Record deleted successfully', response);
                // Add any other logic you need
              })
              .catch((error) => {
                console.error('Error deleting record!', error);
              })
            } else {}
            if (itemId === 6)
            {
              axios
                .post(`${process.env.REACT_APP_API_URL}/PatientPortal/addPcl20`, postData, {
                  headers,
                })
                .then((response) => {
                  setRefresh(!refresh);
                  setOpenAlert(false);
               
            
                  // Assuming `patientId` is accessible in this scope
                  handleClick(patientId, 6);
            
                  console.log('Record deleted successfully', response);
                  // Add any other logic you need
                })
                .catch((error) => {
                  console.error('Error deleting record!', error);
                })
              } else {}
              if (itemId === 7)
              {
                axios
                  .post(`${process.env.REACT_APP_API_URL}/PatientPortal/addYbocs90`, postData, {
                    headers,
                  })
                  .then((response) => {
                    setRefresh(!refresh);
                    setOpenAlert(false);
                 
              
                    // Assuming `patientId` is accessible in this scope
                    handleClick(patientId, 7);
              
                    console.log('Record deleted successfully', response);
                    // Add any other logic you need
                  })
                  .catch((error) => {
                    console.error('Error deleting record!', error);
                  })
                } else {}
  };
  
  
  const patientId = props.patientId
  const Gad7Columns = [
    {
      field: 'id',
      headerName: 'ID',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      textAlign: 'center',
      width: 130,
      // renderCell: (cellValues) => (
      //   <SuiButton
      //     size="small"
      //     variant="text"
      //     textAlign='left'
      //     color="info"
      //     onClick={(event) => {
      //       setOpenDetails(true)
      //       setModalID(cellValues.row.id)
      //     }}
      //   >{`${cellValues.row.id}`}</SuiButton>
      // ),
    },
    {
      field: 'date',
      headerName: 'Screening Date',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      textAlign: 'center',
      width: 250,
      renderCell: (cellValues) => (
        <SuiButton
          size="small"
          variant="text"
          textAlign="left"
          color="info"
          onClick={(event) => {
            setOpenDetails(true)
            setModalID(cellValues.row.id)
          }}
        >{`${cellValues.row.date}`}</SuiButton>
      ),
    },
    {
      field: 'totalScore',
      headerName: 'Total Score',
      width: 220,
      hide: true, // Initially hiding the field and header
      renderCell: (cellValues) => (
        cellValues.row.totalScore !== null && cellValues.row.totalScore !== 0 ? cellValues.row.totalScore : null
      ),
    },
    {
      field: 'delete',
      headerName: '',
      width: 100,
      renderCell: (cell) => (
        <>
          {/* 1111 */}
          {/* {props.datamining != 'dataMinings' ? (
            <>
            <DeleteIcon
              style={{
                marginRight: '5px',
                float: 'right',
                marginTop: '5px',
              }}
              color="black"
              onClick={() => handleDeleteConfirm(cell)}
            /></>
          ) : ( */}
            <>
              <Button onClick={() => {handleClickOpen()
              setRowId(cell.row.id)
              console.log(cell.row.id,'....')}}>
                <DeleteIcon
                  color="black"
                  style={{
                    marginRight: '5px',
                    float: 'right',
                    marginTop: '5px',
                  }}
                />
              </Button>
              <Dialog
                open={openAlert}
                onClose={handleCloseAlert}
                aria-labelledby="responsive-dialog-title"
                BackdropComponent={Backdrop}
                BackdropProps={{
                  invisible: true,
                }}
                PaperProps={{
                  style: {
                    background: '#cef5f0',
                    boxShadow: 'none',
                    borderRadius: '8px'
                  },
                }}
              >
                <DialogContent>


                <div style={{borderRadius:'8px',width:'22rem',background:'#cef5f0'}}>
   
   <ErrorOutlineIcon style={{marginLeft:'125px',fontSize:'100px',color:'orange'}}></ErrorOutlineIcon>
       <DialogTitle style={{marginLeft:'1rem',fontWeight:'bold'}}>{"Do you want to delete the record?"}</DialogTitle>
       <h5 style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'normal',marginLeft:'-2rem' }}>
This action cannot be undone.
</h5>
       <DialogContent>
           <DialogContentText>
           <Grid style={{marginLeft:'90px'}}>
           <Button  onClick={() => handleDeleteConfirm1(cell)} style={{backgroundColor:'red',color:'white'}}>YES</Button>
           <Button onClick={handleCloseAlert}  style={{backgroundColor:'blue',color:'white',marginLeft:'1rem'}}>NO</Button>
           </Grid>
           </DialogContentText>
       </DialogContent>
    
</div>





                  {/* <DialogContentText style={{ color: 'black' }}>
                    Do you want to delete the record
                  </DialogContentText> */}
                </DialogContent>
                {/* <DialogActions>
                  <Button autoFocus onClick={handleCloseAlert} style={{ color: 'black' }}>
                    No
                  </Button>
                  <Button onClick={() => handleDeleteConfirm1(cell)} style={{ color: 'black' }} autoFocus>
  Yes
</Button>

                </DialogActions> */}
              </Dialog>
            </>
       
        </>
      ),
    },
    
  ]
  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 5,
    background: 'white',
    fontSize: '13.6px !important',
    color: '#8392ab !important',
    borderColor: 'rgba(5, 152, 236, 0.637) !important',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
  }
  const fields = [
    'id',
    'patientId',
    'toDoItemsId',
    'completed',
    'deleted',
    'recurring',
    'isChecked',
    'completedDate',
    'patientTakenItem',
    'createdBy',
    'updatedBy',
    'createdDate',
    'updatedDate',
    'clientCreatedDate',
    'clientUpdatedDate',
  ]
  const defValues = {
    id: 0,
    patientId: '',
    toDoItemsId: '',
    completed: '',
    deleted: false,
    isChecked: true,
    recurring: true,
    completedDate: '',
    patientTakenItem: '',
    createdBy: '',
    updatedBy: '',
    createdDate: '',
    updatedDate: '',
    clientCreatedDate: '',
    clientUpdatedDate: '',
  }
  const validationSchema = Yup.object({
    date: Yup.string().required('Please Set Date!'),
  })
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  })
  const location = useLocation()
  const oncall = ''
  const history = useHistory()
  const setDocument = (ar) => {
    setDoc(ar)
  }

  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }

  const [file, setFile] = useState(false)
  const [toDo, setToDo] = useState([])
  const [note, setNote] = useState(false)

  const [error, setError]=useState('')
  const [cidiRows, setCidiRows] = useState(null)
  const [ID, setID] = useState('')
  const [toDoItemId, setToDoItemId] = useState('')
  const [checkBox, setCheckBox] = useState(false)
  const [date, setDate] = useState('')


  // const handledelete = () = > {
  //   if (ID > 0) {
  //     if (e == 1) {

  //     }

  // }

 
  const handleClick = (id, e) => {

    // if (id > 0) {
     
      if (e == 1) {   
        setSubmitting(true)
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/PatientPortal/getCIDI12?PatientId=${patientId}`,
            {
              headers,
            }
          )
          .then((response) => {
            setRefresh(!refresh)
            setSubmitting(false)

            const label = []
            const dataArr = []
            const CIDIrow = []
            response.data.forEach((element) => {
              label.push(element.date.split('T')[0])
              dataArr.push(element.totalScore)
              CIDIrow.push({
                date: element.date.split('T')[0],
                totalScore: element.totalScore,
                id: element.id,
            
              })
            })   
            setRefresh(!refresh)
            setCidiRows(CIDIrow)
            
          })
          .catch((error) => {            setSubmitting(false)

            console.error('There was an error!', error)
          })
      } else if (e == 2) {
        setSubmitting(true)

        axios
          .get(
            `${process.env.REACT_APP_API_URL}/PatientPortal/getAuditc3?PatientId=${patientId}`,
            {
              headers,
            }
          )
          .then((response) => {
            setSubmitting(false)

            const label = []
            const dataArr = []
            const CIDIrow = []
            response.data.forEach((element) => {
              label.push(element.date.split('T')[0])
              dataArr.push(element.totalScore)
              CIDIrow.push({
                date: element.date.split('T')[0],
                totalScore: element.totalScore,
                id: element.id,
                // percentage: ((element.totalScore / 21) * 100).toFixed(2),
              })
            })
            setCidiRows(CIDIrow)
            setRefresh(!refresh)
            console.log(response.data,'response.data')
            // setItemId(itemId)
          })
          .catch((error) => {            setSubmitting(false)

            console.error('There was an error!', error)
          })
      } else if (e == 3) {
        setSubmitting(true)

        axios
          .get(
            `${process.env.REACT_APP_API_URL}/PatientPortal/getCraft9?PatientId=${patientId}`,
            {
              headers,
            }
          )
          .then((response) => {
            const label = []
            const dataArr = []
            const CIDIrow = []
            response.data.forEach((element) => {
              label.push(element.date.split('T')[0])
              dataArr.push(element.totalScore)
              CIDIrow.push({
                date: element.date.split('T')[0],
                totalScore: element.totalScore,
                id: element.id,
                // percentage: ((element.totalScore / 21) * 100).toFixed(2),
              })
            })
            setRefresh(!refresh)
            setSubmitting(false)

            setCidiRows(CIDIrow)
            // setItemId(itemId)
          })
          .catch((error) => {            setSubmitting(false)

            console.error('There was an error!', error)
          })
      } else if (e == 4) {
        setSubmitting(true)

        axios
          .get(
            `${process.env.REACT_APP_API_URL}/PatientPortal/getDast10?PatientId=${patientId}`,
            {
              headers,
            }
          )
          .then((response) => {
            const label = []
            const dataArr = []
            const CIDIrow = []
            response.data.forEach((element) => {
              label.push(element.date.split('T')[0])
              dataArr.push(element.totalScore)
              CIDIrow.push({
                date: element.date.split('T')[0],
                totalScore: element.totalScore,
                id: element.id,
                // percentage: ((element.totalScore / 21) * 100).toFixed(2),
              })
            })
            setRefresh(!refresh)
            setSubmitting(false)

            setCidiRows(CIDIrow)
            // setItemId(itemId)
          })
          .catch((error) => {            setSubmitting(false)

            console.error('There was an error!', error)
          })
      } else if (e == 5) {
        setSubmitting(true)

          axios.get(
            `${process.env.REACT_APP_API_URL}/PatientPortal/getMdq17?PatientId=${patientId}`,
            {
              headers,
            }
          )
          .then((response) => {
            const label = []

            const dataArr = []
            const CIDIrow = []
            response.data.forEach((element) => {
              label.push(element.date.split('T')[0])
              dataArr.push(element.totalScore)
              CIDIrow.push({
                date: element.date.split('T')[0],
                totalScore: element.totalScore,
                id: element.id,
                // percentage: ((element.totalScore / 21) * 100).toFixed(2),
              })
            })
            setCidiRows(CIDIrow)
            setRefresh(!refresh)
            setSubmitting(false)

            console.log(response.data,'CIDIrow,,')
            // setItemId(itemId)
          })
          .catch((error) => {            setSubmitting(false)

            console.error('There was an error!', error)
          })
      } else if (e == 6) {
        setSubmitting(true)

        axios
          .get(
            `${process.env.REACT_APP_API_URL}/PatientPortal/getPcl20?PatientId=${patientId}`,
            {
              headers,
            }
          )
          .then((response) => {
            setRefresh(!refresh)

            const label = []
            const dataArr = []
            const CIDIrow = []
            response.data.forEach((element) => {
              label.push(element.date.split('T')[0])
              dataArr.push(element.totalScore)
              CIDIrow.push({
                date: element.date.split('T')[0],
                totalScore: element.totalScore,
                id: element.id,
                // percentage: ((element.totalScore / 21) * 100).toFixed(2),
              })
            })
            setCidiRows(CIDIrow)
            setRefresh(!refresh)
            setSubmitting(false)

            // setItemId(itemId)
          })
          .catch((error) => {            setSubmitting(false)

            console.error('There was an error!', error)
          })
      } else if (e == 7) 
      {        setSubmitting(true)

        axios
          .get(
            `${process.env.REACT_APP_API_URL}/PatientPortal/getYbocs90?PatientId=${patientId}`,
            {
              headers,
            }
          )
          .then((response) => {
            setRefresh(!refresh)

            const label = []
            const dataArr = []
            const CIDIrow = []
            response.data.forEach((element) => {
              label.push(element.date.split('T')[0])
              dataArr.push(element.totalScore)
              CIDIrow.push({
                date: element.date.split('T')[0],
                totalScore: element.totalScore,
                id: element.id,
                // percentage: ((element.totalScore / 21) * 100).toFixed(2),
              })
            })
            setRefresh(!refresh)
            setSubmitting(false)

            setCidiRows(CIDIrow)
            // setItemId(itemId)
          })
          .catch((error) => {            setSubmitting(false)

            console.error('There was an error!', error)
          })
      }
  // }
  }

  // useEffect((id,e) => {
  //   handleClick(item.id, item.itemId)
  //   console.log('useEffect')
  // },[refresh])
 

  const AddTodo = () => {
    setError('')
    if(checkBox==true || date.length>0){
    setSubmitting(true)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientToDoList/AddPatientToDoList`,
        {
          id: 0,
          patientId: patientId,
          toDoItemsId: itemId,
          completed: '',
          deleted: false,
          recurring: checkBox,
          completedDate: date,
          patientTakenItem: '',
          createdDate: '',
          updatedDate: '',
          createdBy: '',
          updatedBy: '',
          clientCreatedDate: '',
          clientUpdatedDate: '',
        },
        {
          headers,
        }
      )
      .then((response) => {
        alert.success('Record saved successfully.', {
          type: 'success',
          onClose: () => {
            setRefresh(!refresh)
            setSubmitting(false)
            setNote(false)
            setDate('')
            setCheckBox(false)
            // if (Patientid != null || Patientid.length > 0) {
            //   props.refresh()
            // } else {
            //   history.push('/CareTeam')
            // }
          },
        })
      })
      .catch((error) => {
        setSubmitting(false)
        alert.success(`Error ${error.message}`, {
          type: 'error',
        })
      })
    }
    else {
      setError('Please Mark as Continuous or Select Due Date.')
    }
  }

  useEffect(() => {
    // POST request using axios inside useEffect React hook
setSubmitting(true)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/PatientToDoList/getpatientToDoListById?patientid=${patientId}`,
        {
          headers,
        }
      )
      .then((response) => {
        // console.log('response.data: ', response.data)
        setToDo(response.data)
        setPostData(response.data)
        setSubmitting(false)
      })
      .catch((error) => {        setSubmitting(false)

        console.error('There was an error!', error)
      })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])

  return (
    <>
      <Modal
        open={openDetails}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleDetails}>
          <form className={classes.form} autoComplete="off">
            <CancelOutlinedIcon
              onClick={() => {
                setOpenDetails(false)
                // props.refresh()
              }}
              variant="contained"
              style={{
                float: 'right',
                color: 'red',
              }}
            ></CancelOutlinedIcon>
            {/* <h3 style={{ backgroundColor: 'Primary' }}>Screening</h3> */}
            {itemId == 1 ? (
              <CIDI
              refresh={handleClick}
                id={modalID}
                cancelmodel={setOpenDetails}
                patientName={patientName}
                age={props.age}
                gender={props.gender}
              ></CIDI>
            ) : null}
            {itemId == 2 ? (
              <Audit_C
                id={modalID}
                cancelmodel={setOpenDetails}
                patientName={patientName}
              ></Audit_C>
            ) : null}
            {itemId == 3 ? (
              <CRAFFT
                id={modalID}
                cancelmodel={setOpenDetails}
                patientName={patientName}
              ></CRAFFT>
            ) : null}
             {itemId ==4 ? (
              <DAST
                id={modalID}
                cancelmodel={setOpenDetails}
                patientName={patientName}
              ></DAST>
            ) : null}
                {itemId ==5 ? (
              <Mdq
                id={modalID}
                cancelmodel={setOpenDetails}
                patientName={patientName}
              ></Mdq>
            ) : null}
             {itemId ==6 ? (
              <PCL
                id={modalID}
                cancelmodel={setOpenDetails}
                patientName={patientName}
              ></PCL>
            ) : null}
             {itemId ==7 ? (
              <YBOCS
                id={modalID}
                cancelmodel={setOpenDetails}
                patientName={patientName}
              ></YBOCS>
            ) : null}
          </form>
        </Box>
      </Modal>
      {toDo != null && toDo.length > 0
        ? toDo.map((item, index) => {
            return (
              <>
                <Grid container xs={12} sm={12} md={12} lg={12}>
                  <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
                  <Grid
                    container
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    style={{
                      border: '2px solid black',
                      margin: '10px',
                      padding: '9px',
                      // padding: '10px',
                      borderRadius: '40px',
                      backgroundColor: '#BAE1F1',
                    }}
                  >
                    <Grid item xs={1} style={{ textAlign: 'left' }}>
                      <SuiTypography
                        style={{ marginTop: '1px', padding: '3px' }}
                      >
                        {index + 1}:
                      </SuiTypography>
                      {/* <Checkbox
                        style={{ marginTop: '5px' }}
                        checked={item.id > 0 ? 'checked' : null}
                      /> */}
                    </Grid>
                    <Grid item xs={9} sm={9} md={9} lg={9}>
                      <SuiTypography
                        size="md"
                        onClick={() => {
                          setCidiRows([])
                          setItemId(item.itemId)
                          setID(item.id)
                          handleClick(item.id, item.itemId)
                        }}
                        style={{
                          Gridor: 'black',
                          // height: '90px',
                          // width: '92%',
                          fontSize: 20,
                          margin: '5px',
                          cursor: 'pointer',
                          marginBottom: '0px',
                          fontWeight: 'bold',
                          fontFamily: 'initial',
                          // margin: "5px",
                          paddingBottom: '5px',
                          backgroundGridor: '#FEB313',
                          textAlign: 'left',
                          justifyContent: 'left',
                        }}
                      >
                        {item.itemsName}
                      </SuiTypography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{ textAlign: 'center', paddingTop: '3px' }}
                    >
                      <>
                        <Switch
                          // checked={item.isChecked}
                          color="warning"
                          onChange={(event) => {
                            if (event.target.checked == false) {
                              item.isChecked = false
                              confirmAlert({
                                message: 'Do you want to remove this screening.',
                                closeOnEscape: true,
                                closeOnClickOutside: true,
                                overlayClassName: 'overlay-custom-class-name',
                                buttons: [
                                  {
                                    label: 'Yes',
                                    onClick: () => {
                                      setSubmitting(true)
                                      axios
                                        .post(
                                          `${process.env.REACT_APP_API_URL}/PatientToDoList/AddPatientToDoList`,
                                          {
                                            id: toDo[index].id,
                                            patientId: toDo[index].patientId,
                                            toDoItemsId:
                                              toDo[index].toDoItemsId,
                                            completed: toDo[index].completed,
                                            deleted: true,
                                            recurring: toDo[index].recurring,
                                            completedDate:
                                              toDo[index].completedDate,
                                            patientTakenItem:
                                              toDo[index].patientTakenItem,
                                            createdDate:
                                              toDo[index].createdDate,
                                            updatedDate:
                                              toDo[index].updatedDate,
                                            createdBy: toDo[index].createdBy,
                                            updatedBy: toDo[index].updatedBy,
                                            clientCreatedDate:
                                              toDo[index].clientCreatedDate,
                                            clientUpdatedDate:
                                              toDo[index].clientUpdatedDate,
                                          },
                                          {
                                            headers,
                                          }
                                        )
                                        .then((response) => {
                                          setRefresh(!refresh)
                                          setSubmitting(false)
                                          // setOpenNote(false)
                                          // console.log('response.data: ', response.data)
                                        })
                                        .catch((error) => {
                                          console.error(
                                            'There was an error!',
                                            error
                                          )
                                        })
                                    },
                                  },
                                  {
                                    label: 'No',
                                  },
                                ],
                              })
                            } else {
                              item.isChecked = true
                                setCidiRows([]),
                                setNote(true),
                                setItemId(item.itemId),
                                setToDoItemId(item.toDoItemsId),
                                setCheckBox(false)
                            }
                          }}
                          disabled={readOnly == true ? true : false}
                        />
                      </>
                    </Grid>

                    {note && item.itemId == itemId ? (
                       <Grid container xs={12} sm={12} md={12} lg={12}>
                        {/* <Grid item xs={2} sm={2} md={2} lg={2}></Grid> */}
                        <Grid
                          container
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{
                            // border: '2px solid black',
                            margin: '10px',
                            padding: '10px',
                            // borderRadius: '40px',
                            backgroundColor: '#BAE1F1',
                          }}
                        >
                          <Grid item xs={2.2} sm={2.2} md={2.2} lg={2.2}>
                            <SuiTypography
                              size="md"
                              style={{
                                Gridor: 'black',
                                // height: '90px',
                                // width: '92%',
                                fontSize: 20,
                                fontWeight: 'bold',
                                fontFamily: 'initial',
                                marginTop: '2px',
                                marginBottom: '5px',
                                // margin: "5px",
                                paddingBottom: '5px',
                                backgroundGridor: '#FEB313',
                                textAlign: 'left',
                                justifyContent: 'left',
                              }}
                            >
                              Continuous:
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={1} style={{ width: '300px' ,marginLeft:'13px'}}>
                            <Checkbox
                              onChange={(e) => {
                                setCheckBox(e.target.checked)
                                setDate('')
                                setError('')
                              }}
                            />
                          </Grid>
                          {checkBox ? null : (
                            <>
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                style={{ textAlign: 'right' }}
                              >
                                <SuiTypography
                                  style={{
                                    // height: '90px',
                                    // width: '92%',
                                    fontSize: 20,
                                    fontWeight: 'bold',
                                    marginTop: '2px',
                                    fontFamily: 'initial',
                                    // margin: "5px",
                                    // paddingBottom: '5px',
                                    backgroundGridor: '#FEB313',
                                    textAlign: 'right',
                                    justifyContent: 'left',
                                  }}
                                >
                                  Due Date:
                                </SuiTypography>
                              </Grid>
                              <Grid item xs={3} style={{ width: '300px' }}>
                                <SuiInput
                                  type="date"
                                  name="date"
                                  margin="dense"
                                  value={date}
                                  label="date"
                                  onChange={(e) => {
                                    setDate(e.target.value)
                                  }}
                                  error={errors.date}
                                />
                                
                              </Grid>
                              <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
                            </>
                          )}
                          <p style={{fontSize:'12px',color:'red'}}>{error}</p>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Button
                              onClick={() => {
                                setNote(false)
                                item.isChecked = false
                                setError('')
                                setDate('')
                                setRefresh(!refresh)
                              }}
                              variant="gradient"
                              disabled={submitting}
                              // color="black"
                              // size="small"
                              style={{
                                marginTop: '5px',
                                float: 'right',
                                marginLeft: '20px',
                                width: '90px',
                                height: '35px',
                                backgroundColor: '#FCBE13',
                                color: 'black',
                                fontSize: '14px',
                              }}
                              // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                            >
                              <CancelOutlinedIcon
                                fontSize="medium"
                                style={{ color: 'red', paddingRight: '5px' }}
                              ></CancelOutlinedIcon>
                              Cancel
                            </Button>
                            <Button
                              onClick={AddTodo}
                              variant="gradient"
                              disabled={submitting}
                              style={{
                                marginTop: '5px',
                                float: 'right',
                                marginLeft: 'auto',
                                width: '80px',
                                height: '35px',
                                backgroundColor: '#FCBE13',
                                color: 'black',
                                fontSize: '14px',
                              }}
                              // color="warning"
                              // size="small"

                              // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                            >
                              <SaveOutlinedIcon
                                fontSize="medium"
                                style={{
                                  color: '#1a82ad',
                                  paddingRight: '5px',
                                  paddingBottom: '2px',
                                }}
                              ></SaveOutlinedIcon>
                              Save
                            </Button>
                          </Grid>
                        </Grid>
                        {/* <Grid item xs={2} sm={2} md={2} lg={2}></Grid> */}
                      </Grid>
                    ) : null}
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
                </Grid>
             
                {item.itemId == itemId ?(
                  <Grid container xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
                    <Grid
                      item
                      xs={8}
                      style={{
                        width: '50%',
                        marginLeft: '8px',
                        marginRight: '8px',
                      }}
                    >
                      <SuiBox>
                      {submitting ? (
          <CircularProgress
            style={{
              width: "50px",
              height: "50px",
              position: "absolute",
              left: "50%",
              top: "50%",
              marginLeft: "-25px",
              marginTop: "-25px",
              zIndex: 1000,
              color: "#FCBE13",
            }}
          />
        ) :cidiRows === null || cidiRows.length === 0 ? (
                          'No Record Found.'
                        ) : (
                          <div style={{ height: 400, marginBottom: '30px', margin: 'auto',   width: '55%',
                        }}>
                            <DataGrid
                              rows={cidiRows}
                              columns={Gad7Columns}
                              pageSize={5}
                              rowsPerPageOptions={[5]}
                              sx={gridRowStyle}
                            />
                          </div>
                        )}
                      </SuiBox>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
                  </Grid>
                ) : null}
              </>
            )
          })
        : 'No Record Found'}
           {submitting ? (
          <CircularProgress
            style={{
              width: "50px",
              height: "50px",
              position: "absolute",
              left: "50%",
              top: "90%",
              marginLeft: "-25px",
              marginTop: "-25px",
              zIndex: 1000,
              color: "#FCBE13",
            }}
          />
        ):''}
    </>
  )
}
