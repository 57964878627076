import { Grid } from '@mui/material'
import { createBrowserHistory } from 'history'
import { useHistory } from 'react-router-dom'
import React, { useState } from 'react'
import Button from '@mui/material/Button'
// import Pdf from '../Documents/test.pdf'
import { Document, Page } from 'react-pdf'
import { confirmAlert } from 'react-confirm-alert'
import Pdf from './ProviderResources'
import SuiTypography from 'components/SuiTypography'

function PdfDocsCard(props) {
  // const history = useHistory();
  const history = createBrowserHistory({ forceRefresh: true })
  const [activeButton, setActiveButton] = useState(false)
  return (
    <>
         <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign:'center'}}>
          {props.name ? (
            <>
              <Button
                variant={activeButton?('contained'):('link')}
                size="sm"
                style={{
                  Gridor: 'black',
                  height: '160px',
                  width: '100%',
                  fontSize: 25,
                  margin:'10px',
                  fontWeight: 'bold',
                  fontFamily: 'initial',
                  // margin: "5px",
                  padding: '0px',
                  backgroundGridor: '#FEB313',
                  textAlign: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => {
                  setActiveButton(props.value)
                  props.file
                    ? //
                      //   window.open(props.file)
                      // <Pdf source={props.file} 
                      // ></Pdf>
                      props.Pdf(props.file)
                      // history.push({
                      //   pathname: `/Pdf`,
                      //   state: { source: props.file },
                      // })
                    :    confirmAlert({
                      message: 'Please Contact Administrator! Document Not Found.',
                      closeOnEscape: true,
                      closeOnClickOutside: true,
                      // overlayClassName: 'overlay-custom-class-name'
                      buttons: [
                        {
                          label: 'Ok',
                        },
                      ],
                })
                }}
              >
                {props.name}
              </Button>
            </>
          ) : (
            <SuiTypography>Please Contact Administrator! Document Not Found.</SuiTypography>
          )}
          {/* {props.mainImage ? (
            <img
              variant="link"
              onClick={() => {
                props.file ? (
                  navigation("../MainDocument", {
                    state: {
                      source: props.file,
                      callingFrom: "MeditationDocs",
                    },
                  })
                ) : (
                  <Alert variant="success">
                    Please Contact Administrator! Document Not Found.
                  </Alert>
                );
              }}
              src={props.mainImage}
              width="100%"
              height="250"
            />
          ) : null} */}
        </Grid>
       {/* <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid item xs={0} sm={0} md={0} lg={1}></Grid>
        <Grid item xs={12} sm={12} md={12} lg={10}>
          <Button
            variant="link"
            size="sm"
            style={{
              Gridor: '#32A6D6',
              fontSize: 20,
              fontWeight: 'bold',
              width: '80%',
              // margin: "5px",
              padding: '0px',
              textAlign: 'left',
              justifyContent: 'left',
            }}
            onClick={() => {
              props.file
                ? navigation('../MainDocument', {
                    state: {
                      source: props.file,
                      callingFrom: 'MeditationDocs',
                    },
                  })
                : alert(`Please Contact Administrator! Document Not Found. `)
            }}
          >
            {props.name}
          </Button>
        </Grid>
        <Grid item xs={0} sm={0} md={0} lg={1}></Grid>
      </Grid> 
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid item xs={0} sm={0} md={0} lg={1}></Grid>
        <Grid item xs={12} sm={12} md={12} lg={10}>
          <Button
            variant="outline"
            style={{
              marginBottom: 10,
              Gridor: 'black',
              marginLeft: '3px',
              padding: '0px',
              width: '80%',
              textAlign: 'left',
              justifyContent: 'left',
            }}
            onClick={() => {
              props.file
                ? navigation('../MainDocument', {
                    state: {
                      source: props.file,
                      callingFrom: 'MeditationDocs',
                    },
                  })
                : alert(`Please Contact Administrator! Document Not Found. `)
            }}
          >
            {props.description}
          </Button>
        </Grid>
        <Grid item xs={0} sm={0} md={0} lg={1}></Grid>
      </Grid> */}
    </>
  )
}

export default PdfDocsCard
