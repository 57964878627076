import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import rootReducer from 'redux/reducer'

// const initalState = {};

const middleware = [thunk]
const initalState = localStorage.getItem('reduxState')
  ? JSON.parse(localStorage.getItem('reduxState'))
  : {}
const store = createStore(
  rootReducer,
  initalState,
  composeWithDevTools(applyMiddleware(...middleware))
)
store.subscribe(() => {
  // save a copy to localStorage
  localStorage.setItem('reduxState', JSON.stringify(store.getState()))
})
export default store
