import React from 'react';
import CheckboxTree from './CheckboxTree';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Box from "@mui/material/Box";
import Tab from '@mui/material/Tab'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TabPanel, TabContext, TabList } from '@mui/lab'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTheme } from '@mui/material/styles';
import Roles from './Roles';
import Rights from './Rights';

const treeData = {
  id: 'roles',
  name: 'Roles',
  children: [
    {
      id: 'patients',
      name: 'Patients',
      children: [
        {
          id: 'notes',
          name: 'Notes',
        },
        {
          id: 'summary',
          name: 'Summary',
        },
      ],
    },
    // ... other children
  ],
};
  
function Modules() {
  const theme = useTheme();
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <TabContext value={tabValue}>
      <Box sx={{ borderBottom: 1, borderColor:  theme.palette.background.default }}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab
            sx={{
              backgroundColor: tabValue === 0 ? '#f98d33' : theme.palette.background.default,
            }}
            value={0}
            label="Rights"
          />
          <Tab
            sx={{
              backgroundColor: tabValue === 1 ? '#f98d33' : theme.palette.background.default,
            }}
            value={1}
            label="Roles"
          />
          <Tab
            sx={{
              backgroundColor: tabValue === 2 ? '#f98d33' : theme.palette.background.default,
            }}
            value={2}
            label="Modules"
          />
        </TabList>
      </Box>
      <TabPanel value={0}> <Rights/></TabPanel>
      <TabPanel value={1}><Roles/></TabPanel>
      <TabPanel value={2}>
        <div className="App">
          <DndProvider backend={HTML5Backend}>
            <CheckboxTree nodes={treeData} />
          </DndProvider>
        </div>
      </TabPanel>
    </TabContext>
  );
}

export default Modules;
