// @mui material components
import Card from '@mui/material/Card'

// Soft UI Dashboard React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiInput from 'components/SuiInput'
import SuiButton from 'components/SuiButton'
import SuiSelect from 'components/SuiSelect'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import { useAlert } from 'react-alert'
import SaveIcon from 'assets/save.png'
import CancelIcon from 'assets/cancel.png'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import input from '@mui/material/Input'
import { Grid, Snackbar, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useReducer, useState, Fragment } from 'react'
import { createBrowserHistory } from 'history'
import Checkbox from '@mui/material/Checkbox'
import { useLocation } from 'react-router-dom'
import ReactSelect from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Autocomplete from '@mui/material/Autocomplete'
import InputMask from "react-input-mask";
import Button from '@mui/material/Button';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CircularProgress from '@mui/material/CircularProgress'

const history = createBrowserHistory({ forceRefresh: true })
const fields = [
  'Id',
  'name',
  'shortName',
  'type',
  'phoneNo',
  'fax',
  'emailAddress',
  'city',
  'state',
  'zipCode',
  'deleted',
  'createdBy',
  'createdDate',
  'updatedBy',
  'updatedDate',
  'clientCreatedDate',
  'clientModifiedDate',
  'defaultPOS',
  'practice_id',
  'defaultFacility',
  'npi',
  'fullName',
  'address',
]
const defValues = {
  Id: '',
  address:'',
  fullName: '',
  shortName: '',
  type: '',
  phoneNo: '',
  fax: '',
  emailAddress: '',
  city: '',
  state: '',
  zipCode: '',
  deleted: false,
  createdBy: '',
  practice_id:'',
  createdDate: '',
  updatedBy: '',
  updatedDate: '',
  clientCreatedDate: '',
  clientModifiedDate: '',
  defaultPOS: '',
  defaultFacility: '',
  npi: '',
}
function handleCancel() {
  history.push('/Facilities')
}
function FacilityRegistration() {
  const location = useLocation()
  const alert = useAlert()
  const [id, setId]=useState(location.state.facilityId)
  const [submitting, setSubmitting] = useState(false)
  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }
  const zipCodeListStyle = {
    cursor: 'pointer',
    '&:hover': {
      background: '#3b3b3b !important',
      color: 'rgba(5, 152, 236, 0.637) !important',
    },
  }
  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    // shortName: Yup.string().required('Short Name is required'),
    // type: Yup.string().required('Type is required'),
    // npi: Yup.string().required('NPI is required'),
    emailAddress: Yup.string()
      // .required('Email is required')
      .email('Email is invalid'),
  })
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  })
  const onSubmit = (data) => {
    setSubmitting(true)
    const postData = data
    console.warn('Data: ', postData)
    if (
      location.state.facilityId !== undefined &&
      location.state.facilityId != null
    )
      postData.Id = location.state.facilityId
      
      
    else postData.Id = 0
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Facilities/addFacility`,
        postData,
        { headers }
      )
      .then((response) => {
        setSubmitting(false)
        alert.success('Record saved successfully.', {
          type: 'success',
          onClose: () => {
            history.push('/facilities')
          },
        })
      })
      .catch((error) => {
        setSubmitting(false)
        alert.error(`Error ${error.message}`, {
          type: 'error',
        })
      })
  }
  const handleInputChange = (e) => {
    // Remove non-numeric characters from the input value
    const inputValue = e.target.value.replace(/\D/g, '');

    // Update the value of the input field
    e.target.value = inputValue;
  };
  useEffect(() => {
    if(id>0){
    // POST request using axios inside useEffect React hook
    let isComponentMounted = true
    const fetchData = async () =>
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/Facilities/getFacilityById?id=${
            id !== undefined
              ? id
              : 0
          }`,
          { headers }
          // `${process.env.REACT_APP_API_URL}/Facilities/getFacilityById?id=${
          //   location.state.facilityId !== undefined ? location.state.facilityId : 0
          // }`,
          // null,
          // {
          //   headers,
          // }
        )
        .then((response) => {
          console.log('response.data: ', response.data)
          if (isComponentMounted) {
            fields.forEach((field) => setValue(field, response.data[field]))
          }
          setPhone(response.data.phoneNo)
          setFax(response.data.fax)
        })
        .catch((error) => {
          console.error('There was an error!', error)
        })
    fetchData()
    return () => {
      isComponentMounted = false
    }
  }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [])
  ///Auto Compelet
const [open, setOpen] = React.useState(false);
const [options, setOptions] = React.useState([]);
const loading = open && options.length === 0;
const [zipCodeList, setZipCodeList] = React.useState([])
const [isZipCode, setIsZipCode] = React.useState(false)
const onChangeHandle = async (value) => {
  // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
  // console.log(value)

  axios
    .get(
      `${process.env.REACT_APP_API_URL}/Settings/Searchcitystate?zipcode=${value}`,
      { headers }
    )
    .then((response) => {
      setZipCodeList(response.data)
      setIsZipCode(true)
      setinsuranceNames(response.data)
    })
    .catch((error) => {
      console.error('There was an error!', error)
    })
}


React.useEffect(() => {
  if (!open) {
    setOptions([]);
  }
}, [open]);
const [phone, setPhone] = React.useState();
const [Fax, setFax] = React.useState();
  return (
    <>
       {submitting ? (
        <CircularProgress
          style={{
            width: '50px',
            height: '50px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginLeft: '-25px',
            marginTop: '-25px',
            zIndex: 1000,
            color: '#FCBE13',
          }}
        />
      ) : null}
      <Grid
        container
        spacing={1}
        alignContent="center"
        justifyContent="center"
        style={{
          paddingTop: '10px',
          paddingLeft: '10px',
          paddingRight: '100px',
        }}
      >
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Full Name
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Short Name
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Type
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter Full Name"
            name="fullName"
            label="fullName"
            fullWidth
            margin="dense"
            {...register('name')}
            error={errors.name}
          />
         <p style={{ color: "red", fontSize:"14px" }}>{errors.name?.message}</p>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter ShortName"
            name="shortName"
            label="shortName"
            fullWidth
            margin="dense"
            {...register('shortName')}
            error={errors.shortName}
          />
          <p style={{ color: "red", fontSize:"14px" }}>{errors.shortName?.message}</p>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter Type"
            name="type"
            label="Type"
            fullWidth
            margin="dense"
            {...register('type')}
            error={errors.type}
          />
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Phone No
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Fax
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Email Address
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
        <InputMask
          mask="+1 999-999-9999"
        disabled={false}
        value={phone}
        style={{ height: '30px' }}
        onChange={(e) => {
          setValue("phoneNo",e.target.value)
          setPhone( e.target.value)
        }
        }
      >
        {() => <SuiInput
                id="outlined-basic"
                fullWidth
                margin="dense"
                placeholder="Enter phoneNo"
               />

              }
      </InputMask>
          {/* <SuiInput
            placeholder="Please enter phone No"
            name="phoneNo"
            label="phone no"
            fullWidth
            margin="dense"
            {...register('phoneNo')}
            error={errors.phoneNo}
          /> */}
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
        <InputMask
          mask=" 999-9999999"
        disabled={false}
        value={Fax}
        style={{ height: '30px' }}
        onChange={(e) => {
          setValue("fax",e.target.value)
          setFax( e.target.value)
        }
        }
      >
        {() => <SuiInput
                id="outlined-basic"
                fullWidth
                margin="dense"
                placeholder="Enter Fax"
               />

              }
      </InputMask>
          {/* <SuiInput
            placeholder="Please enter Fax"
            name="fax"
            label="Fax"
            fullWidth
            margin="dense"
            {...register('fax')}
            error={errors.fax}
          /> */}
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter emailAddress"
            name="emailAddress"
            label="EmailAddress"
            fullWidth
            margin="dense"
            {...register('emailAddress')}
            error={errors.emailAddress}
          />
           <p style={{ color: "red", fontSize:"14px" }}>{errors.emailAddress?.message}</p>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Address
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            ZIP Code
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            City
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            type="input"
            placeholder="Enter Address"
            name="state"
            {...register('address')}
          />
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
        <SuiInput                   {...register('ZipCode')}

inputProps={{ maxLength: 5 }}
type="input"
placeholder="Enter ZipCode"
onChange={handleInputChange}
// Add any other props you need, like registering with Formik or displaying errors
/>
      {/* <SuiInput       inputProps={{ maxLength: 5 }}

                  type="ZipCode"
                  placeholder="Enter ZipCode"
                  {...register('zipCode')}
            
                /> */}
          {/* <SuiInput
            type="input"
            placeholder="ZipCode"
            {...register('zipCode')}
            onKeyUp={(ev) => {
              // dont fire API if the user delete or not entered anything
              if (ev.target.value !== '' || ev.target.value !== null) {
                onChangeHandle(ev.target.value)
              }
            }}
          /> */}
          {/* {isZipCode ? (
            <div
              className="col-xs-3"
              style={{
                padding: '14px 16px',
                fontSize: '0.875rem',
                color: '#67748e',
                borderRadius: '5px',
                background: 'white',
                textAlign: 'justify',
                textJustify: 'inter-word',
                position: 'absolute',
                zIndex: '99',
                width: '25%',
                border: '1px solid #6cb5f9',
              }}
            >
              {zipCodeList.map((s, index) => (
                <p
                  // attribZip={s.zipCode}
                  // attribState={s.state}
                  // attribCity={s.city}
                  // attribIndex={index}

                  style={zipCodeListStyle}
                  onClick={(ev) => {
                    console.log('evvv', ev)
                    // var sel=ev.target.attributes['attribIndex']
                    console.log('Selected: ', zipCodeList[index])
                    setValue('zipCode', zipCodeList[index].zipCode)
                    setValue('city', zipCodeList[index].city)
                    setValue('state', zipCodeList[index].state)
                    setZipCodeList([])
                    setIsZipCode(false)
                  }}
                  onBlur={(ev) => {
                    setZipCodeList([])
                    setIsZipCode(false)
                  }}
                  onChange={(ev) => {
                    setZipCodeList([])
                    setIsZipCode(false)
                  }}
                >
                  {s.zipCode} <strong>City:</strong> {s.city} <strong>State:</strong> {s.state}
                </p>
              ))}
            </div>
          ) : (
            ''
          )} */}
        

          {/* <SuiInput
            type="input"
            placeholder="ZipCode"
            {...register('zipCode')}
            onKeyUp={(ev) => {
              // dont fire API if the user delete or not entered anything
              if (ev.target.value !== '' || ev.target.value !== null) {
                onChangeHandle(ev.target.value)
              }
            }}
          />
          {isZipCode ? (
            <div
              className="col-xs-3"
              style={{
                padding: '14px 16px',
                fontSize: '0.875rem',
                color: '#67748e',
                borderRadius: '5px',
                background: 'white',
                textAlign: 'justify',
                textJustify: 'inter-word',
                position: 'absolute',
                zIndex: '99',
                width: '25%',
                border: '1px solid #6cb5f9',
              }}
            >
              {zipCodeList.map((s, index) => (
                <p
                  // attribZip={s.zipCode}
                  // attribState={s.state}
                  // attribCity={s.city}
                  // attribIndex={index}

                  style={zipCodeListStyle}
                  onClick={(ev) => {
                    console.log('evvv', ev)
                    // var sel=ev.target.attributes['attribIndex']
                    console.log('Selected: ', zipCodeList[index])
                    setValue('zipCode', zipCodeList[index].zipCode)
                    setValue('city', zipCodeList[index].city)
                    setValue('state', zipCodeList[index].state)
                    setZipCodeList([])
                    setIsZipCode(false)
                  }}
                  onBlur={(ev) => {
                    setZipCodeList([])
                    setIsZipCode(false)
                  }}
                  onChange={(ev) => {
                    setZipCodeList([])
                    setIsZipCode(false)
                  }}
                >
                  {s.zipCode} <strong>City:</strong> {s.city} <strong>State:</strong> {s.state}
                </p>
              ))}
            </div>
          ) : (
            ''
          )} */}
        </Grid>

        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            type="input"
            // readOnly
            placeholder="Enter city"
            {...register('city')}
          />
        </Grid>
        
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            State
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            NPI
          </SuiTypography>
        </Grid>
        {/* <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Default Facility
          </SuiTypography>
        </Grid> */}
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
          Place of Service
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            type="input"
            // readOnly
            placeholder="Enter state"
            {...register('state')}
          />
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
        <SuiInput
            type="input"
            placeholder="Enter Npi"
            name="npi"
            {...register('npi')}
            error={errors.npi}
          />
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
        <Controller
                name="defaultPOS"
                {...register('defaultPOS')}
                control={control}
                render={({ field }) => (
                  <SuiSelect
                    value={{ label: getValues('defaultPOS') }}
                    onChange={(value) => setValue('defaultPOS', value.label)}
                    options={[
                      { value: '1', label: 'Pharmacy' },
                      { value: '2', label: 'Unassigned' },
                      { value: '3', label: 'School' },
                      { value: '4', label: 'Homeless Shelter' },
                      { value: '5', label: 'Indian Health Service Free-standing Facility' },
                      { value: '6', label: 'Indian Health Service Provider-based Facility' },
                      { value: '7', label: 'Tribal 638 Free-standing Facility' },
                      { value: '8', label: 'Tribal 638 Provider-based Facility' },
                      { value: '9', label: 'Prison/ Correctional Facility' },
                      { value: '10', label: 'Unassigned' },
                      { value: '11', label: 'Office' },
                      { value: '12', label: 'Home' },
                      { value: '13', label: 'Assisted Living Facility' },
                      { value: '14', label: 'Group Home' },
                      { value: '15', label: 'Mobile Unit' },
                      { value: '16', label: 'Temporary Lodging' },
                      { value: '17', label: 'Walk-in Retail Health Clinic' },
                      { value: '18', label: 'Place of Employment-Worksite' },
                      { value: '19', label: 'Off Campus-Outpatient Hospital' },
                      { value: '20', label: 'Urgent Care Facility' },
                      { value: '21', label: 'Inpatient Hospital' },
                      { value: '22', label: 'On Campus-Outpatient Hospital' },
                      { value: '23', label: 'Emergency Room – Hospital' },
                      { value: '24', label: 'Ambulatory Surgical Center' },
                      { value: '25', label: 'Birthing Center' },
                      { value: '26', label: 'Military Treatment Facility' },
                      { value: '27,30', label: 'Unassigned' },
                      { value: '31', label: 'Skilled Nursing Facility' },
                      { value: '32', label: 'Nursing Facility' },
                      { value: '33', label: 'Custodial Care Facility' },
                      { value: '34', label: 'Hospice' },
                      { value: '35,40', label: 'Unassigned' },
                      { value: '41', label: 'Ambulance Land' },
                      { value: '42', label: 'Ambulance – Air or Water' },
                      { value: '43,48', label: 'Unassigned' },
                      { value: '49', label: 'PIndependent Clinic' },
                      { value: '50', label: 'Federally Qualified Health Center' },
                      { value: '51', label: 'Inpatient Psychiatric Facility' },
                      { value: '52', label: 'Psychiatric Facility-Partial Hospitalization' },
                      { value: '53', label: 'Community Mental Health Center' },
                      { value: '54', label: 'Intermediate Care Facility/ Individuals with Intellectual Disabilities' },
                      { value: '55', label: 'Residential Substance Abuse Treatment Facility' },
                      { value: '56', label: 'Psychiatric Residential Treatment Center' },
                      { value: '57', label: 'Non-residential Substance Abuse Treatment Facility' },
                      { value: '58,59', label: 'Unassigned' },
                      { value: '60', label: 'Mass Immunization Center' },
                      { value: '61', label: 'Comprehensive Inpatient Rehabilitation Facility' },
                      { value: '62', label: 'Comprehensive Outpatient Rehabilitation Facility' },
                      { value: '63,64', label: 'Unassigned' },
                      { value: '65', label: 'End-Stage Renal Disease Treatment Facility' },
                      { value: '66,70', label: 'Unassigned' },
                      { value: '71', label: 'Public Health Clinic' },
                      { value: '72', label: 'Rural Health Clinic' },
                      { value: '73,80', label: 'Unassigned' },
                      { value: '81', label: 'Independent Laboratory' },
                      { value: '82,98', label: 'Unassigned' },
                      { value: '99', label: 'Other Place of Service' },

                    ]}
                  />
                )}
              />
        </Grid>
        {/* <Grid item xs={3} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Default Facility
          </SuiTypography>
        </Grid>
        <Grid item xs={1} style={{ width: '300px' }}>
        <SuiInput
         type="checkbox"
            placeholder="Please enter defaultFacility"
            name="defaultFacility"
            label="defaultFacility"
            fullWidth
            margin="dense"
            {...register('defaultFacility')}
            error={errors.defaultFacility}
          />

        </Grid> */}
        <Grid item xs={3} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Deleted
          </SuiTypography>
        </Grid>
        <Grid item xs={1} style={{ width: '300px' }}>
        <SuiInput
              type="checkbox"
               margin="dense"
              {...register('deleted')}
              error={errors.deleted}
            />
        </Grid>
               <Grid item xs={3} style={{ width: '300px' }}/> 

        <Button
          onClick={handleSubmit(onSubmit)}
          variant="gradient"
          disabled={submitting}
          style={{
            marginTop: '50px',
            float: 'right',
            marginLeft: 'auto',
            width:'80px',
            height:'35px',
            backgroundColor: "#FCBE13",
            color:"black",
            fontSize:'14px'
          }}
          // color="warning"
          // size="small"

          // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}

        >
          <SaveOutlinedIcon
          fontSize='medium'
          style={{ color:'#1a82ad', paddingRight:'5px', paddingBottom:'2px'}}
          >
          </SaveOutlinedIcon>
          Save
        </Button>
        <Button
          onClick={handleCancel}
          variant="gradient"
          disabled={submitting}
          // color="black"
          // size="small"
          style={{
            marginTop: '50px',
            float: 'right',
            marginLeft: '20px',
            width:'90px',
            height:"35px",
            backgroundColor: "#FCBE13",
            color:"black",
            fontSize:'14px'

          }}
            // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}

        >
          <CancelOutlinedIcon
          fontSize='medium'
         style={{ color:"red",paddingRight:'5px',}}
          ></CancelOutlinedIcon>
          Cancel
        </Button>

      </Grid>
    </>
  )
}

export default FacilityRegistration
