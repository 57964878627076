import * as React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import SuiBox from 'components/SuiBox'
import SuiButton from 'components/SuiButton'
import SuiTypography from 'components/SuiTypography'
import { useEffect, useState } from 'react'
import axios from 'axios'
import AddIcon from 'assets/add.png'
import { Grid, Snackbar, TextField } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert'
import { createBrowserHistory } from 'history'
import customStyle from '../assets/customStyle.css'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Button from '@mui/material/Button';
// import MUIDataTable from "mui-datatables";
import {  Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Backdrop } from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from "@mui/material/CircularProgress";

const history = createBrowserHistory({ forceRefresh: true })
function Referral() {
const headers = {
  Authorization: `Bearer  ${localStorage.getItem('dtt_token')}`,
}
const openReferralRegistration = (event, id) => {
  history.push({
    pathname: `/ReferralRegistration`,
    state: { referral: id, },
  })
  window.location.reload(false)
}
const [refresh, setRefresh] = useState(false)
const [open, setOpen] = useState(false);

const handleDelete = (cell) => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};

const handleConfirmDelete = (cell) => {
  const postData = {
    id: cell.row.id,
    inactive: true,
    name: cell.row.name,
  };

  axios
    .post(`${process.env.REACT_APP_API_URL}/Referrals/AddReferrals`, postData, {
      headers,
    })
    .then((response) => {
      setRefresh(!refresh);
    })
    .catch((error) => {
      console.error('There was an error!', error);
    });

  setOpen(false);
};
const columns = [
  { field: 'id', headerName: 'ID',headerClassName: 'super-app-theme--header',
  flex: 0.3,},
  {
    field: 'name',
    headerName: 'Name',
    headerClassName: 'super-app-theme--header',
    flex: 0.5,
    renderCell: (cellValues) => (
      <SuiButton
        size="small"
        variant="text"
        color="dark"
        onClick={(event) => {
        openReferralRegistration(event, cellValues.row.id)
        }}
      >{`${cellValues.row.name}`}</SuiButton>
    ),
  },
  // { field: 'shortName', headerName: 'Short Name', width: 130 },
  {
    field: 'providerName',
    headerName: 'Transfer To',
    type: 'text',
    headerClassName: 'super-app-theme--header',
    flex: 0.3,
  },
  // {
  //   field: 'defaultFacility',
  //   headerName: 'Default Facility',
  //   type: 'text',
  //   headerClassName: 'super-app-theme--header',
  //     flex: 0.5,
  // },
  {
    field: 'createdBy',
    headerName: 'Created By',
    type: 'text',
    headerClassName: 'super-app-theme--header',
      flex: 0.5,
  },
  // {
  //   field: 'isActive',
  //   headerName: 'Active',
  //   cellClassName: 'gridCell',
  //   sortable: false,
  //   type: 'boolean',
  //   headerClassName: 'super-app-theme--header',
  //   flex: 0.3,
  // },
  {
    field: 'delete',
    headerName: 'Delete',
    sortable: true,
    headerClassName: 'super-app-theme--header',
    // flex: 0.5,
    minWidth: 50,
    renderCell: (cell) => (
      <>
      <DeleteIcon
        style={{
          marginRight: '5px',
          float: 'right',
          marginTop: '5px',
          color: 'red',
        }}
        onClick={() => handleDelete(cell)}
      ></DeleteIcon>

      <Dialog open={open} onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          invisible: true,
        }}
        PaperProps={{
          style: {background:'#cef5f0',
          boxShadow:'none',
          borderRadius:'8px'
          },
       }}   
      >
      <div style={{borderRadius:'8px',width:'22rem'}}>
   
   <ErrorOutlineIcon style={{marginLeft:'125px',fontSize:'100px',color:'orange'}}></ErrorOutlineIcon>
       <DialogTitle>{"Do you want to delete the record?"}</DialogTitle>
       <h5 style={{textAlign:'center'}}>This action cannot be undone.</h5>
       <DialogContent>
           <DialogContentText>
           <Grid style={{marginLeft:'90px'}}>
           <Button onClick={() => handleConfirmDelete(cell)} style={{backgroundColor:'red',color:'white'}}>YES</Button>
           <Button  onClick={handleClose} style={{backgroundColor:'blue',color:'white',marginLeft:'1rem'}}>NO</Button>
           </Grid>
           </DialogContentText>
       </DialogContent>
      
       



</div>
      </Dialog>
    </>
    ),
  },

]


  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px',
    },
  }

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 5,
    background: 'white',
    fontSize: '13.6px !important',
    color: '#8392ab !important',
    borderColor: 'rgba(5, 152, 236, 0.637) !important',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#FFFFC2',
      borderRadius: 1.8,
    },
  }
  const [rows, setRows] = useState(null)
  const [submitting, setsubmitting] = useState(false)

  useEffect(() => {setsubmitting(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/Referrals/Referrals`, {
        headers,
      })
      .then((response) => {setsubmitting(false)
        const getData = []
        response.data.forEach((element) => {
          if (element.id !== 0) getData.push(element)
        })
        console.warn(getData)
        setRows(getData)
      })
      .catch((error) => {setsubmitting(false)
        console.error('There was an error!', error)
      })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])
  return (
    <>
    <Grid
      container
      spacing={1}
      style={{
        paddingTop: '10px',
        paddingLeft: '10px',
        paddingRight: '50px',
      }}
    >
      <Grid item xs={6} style={{ width: '300px' }}>
          <SuiBox mb={3}>
        <Button
          sx={{ mb: 5, width: 110 }}
          onClick={openReferralRegistration}
          variant="gradient"
              size="small"
              style={{
                width:'80px',
                height:"33px",
              backgroundColor: "#FCBE13",
            color:"black",
            fontSize:'14px',

          }}
          // color="warning"
          // size="small"

          // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}

        >
          <AddOutlinedIcon
          fontSize='medium'
          style={{ color:'#1a82ad', paddingRight:'4px', paddingBottom:'2px'}}
          >
          </AddOutlinedIcon>
          New
          </Button>
          </SuiBox>
        </Grid>
        <Grid item xs={12} style={{ width: '300px' }}>
          <SuiBox mb={3}>
          <div style={{ height: 400, width: '100%' }}>
          {submitting ? (
          <CircularProgress
            style={{
              width: "50px",
              height: "50px",
              position: "absolute",
              left: "50%",
              top: "50%",
              marginLeft: "-25px",
              marginTop: "-25px",
              zIndex: 1000,
              color: "#FCBE13",
            }}
          />
        ) : rows === null || rows.length === 0 ? "No Record Found" : (
              <DataGrid
                rows={rows}
                columns={columns}
                headerHeight={37}
                pageSize={5}
                rowsPerPageOptions={[5]}
                sx={gridRowStyle}
              />
            )}
           </div>
          </SuiBox>
        </Grid>
      </Grid>
    </>
  )
}

export default Referral
