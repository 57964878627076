// @mui material components
import Card from '@mui/material/Card'

// Soft UI Dashboard React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiInput from 'components/SuiInput'
import SuiButton from 'components/SuiButton'
import SaveIcon from 'assets/save.png'
import CancelIcon from 'assets/cancel.png'
import SuiSelect from 'components/SuiSelect'
import Stack from '@mui/material/Stack'
import InputMask from 'react-input-mask'
// import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import { useAlert } from 'react-alert'
import { DataGrid } from '@mui/x-data-grid'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import input from '@mui/material/Input'
import Moment from 'moment'
import Avatar from '@mui/material/Avatar'
import Modal from '@mui/material/Modal'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import PatientCareTeam from './PatientCareTeam'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import {
  CircularProgress,
  Grid,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import axios from 'axios'
import React, { useEffect, useReducer, useState, Fragment, useRef } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { createBrowserHistory } from 'history'
import Checkbox from '@mui/material/Checkbox'
import { useLocation, Link } from 'react-router-dom'
// import ReactSelect from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { TabPanel, TabContext, TabList } from '@mui/lab'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import PermIdentity from '@mui/icons-material/PermIdentity'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import WorkOutline from '@mui/icons-material/WorkOutline'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import FormControl from '@mui/material/FormControl'
import GradientLineChart from 'examples/Charts/LineCharts/GradientLineChart'
import PatientNotes from './PatientNotes'
import CurrencyInput from 'react-currency-input-field'
import { makeStyles } from '@material-ui/core/styles'

// import Autocomplete from '@material-ui/lab/Autocomplete'
// import Autocomplete from '@mui/material/Autocomplete'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Timelog from './Timelog'
import { flexbox } from '@mui/system'
import { styled } from '@mui/material/styles'
import ReactRoundedImage from 'react-rounded-image'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import Button from '@mui/material/Button'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined'
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined'
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined'
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined'
import { parse, isDate } from 'date-fns'
import { typeOf } from 'react-lettered-avatar'

const history = createBrowserHistory({ forceRefresh: true })
const Input = styled('input')({
  display: 'none',
})
var providersRedux = []
var facilitiesRedux = []
function Demographics(props) {
  console.log('props ,,,,111' , props.DOB)

  function handleCancel() {
    if (props.cancelmodel) {
      props.cancelmodel(false)
    } 
    else if(props.handleclose){
      props.handleclose()
    }
      else if(props.handleClose1){
      console.log('handle cancel working' , props.handleClose1)
      props.handleClose1()
    }
    else {
      history.push('/PatientSearch')
    }
  }
  const userProviderId=localStorage.getItem('dtt_user_provider_id')
  const focusedColor = 'blue'
  const useStyles = makeStyles({
    root: {
      // input label when focused
      '& label.i-focused': {
        color: focusedColor,
      },
      // focused color for input with variant='standard'
      '& .MuiInput-underline:after': {
        borderBottomColor: focusedColor,
      },
      // focused color for input with variant='filled'
      '& .MuiFilledInput-underline:after': {
        borderBottomColor: focusedColor,
      },
      // focused color for input with variant='outlined'
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: focusedColor,
        },
      },
    },
    readhide: {
      color: 'blue',
      cursor: 'pointer',
    },
  })
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px',
    },
  }
  const zipCodeListStyle = {
    cursor: 'pointer',
    '&:hover': {
      background: '#3b3b3b !important',
      color: 'rgba(5, 152, 236, 0.637) !important',
    },
  }
  const classes = useStyles()
  const location = useLocation()
  const _patientId = props.patientId
  // const patientId = localStorage.getItem('patientId') 
  console.log(_patientId,'_patientId,...')
  // const readOnly=props.readOnly
  const dtt_userType = localStorage.getItem('dtt_userType')
  const [loadingTime, setloadingTime] = useState(0)
  const alert = useAlert()
  const [insuranceNames, setinsuranceNames] = React.useState([])
  const [openInsurances, setOpenInsurances] = useState(0)
  const [open, setOpen] = React.useState(false)
  const [zipCodeList, setZipCodeList] = React.useState([])
  const [isZipCode, setIsZipCode] = React.useState(false)
  const [zipDownloaded, setzipDownloaded] = useState(false)
  const [tabValueIns, settabValueIns] = React.useState(0)
  const [valueInsName, setValueInsName] = React.useState(null)
  const [valueSecInsName, setValueSecInsName] = React.useState(null)
  const [submitting, setSubmitting] = useState(false)
  // const [open , setopen] = useState('')
  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const psychotherapistPriviledge = `${localStorage.getItem(
    'dtt_isPsychotherapyPriviledges'
  )}`
  const headers = {
    Authorization: auth,
  }
  const [sendWelcomeEmail, setsendWelcomeEmail] = React.useState(null)
console.log(sendWelcomeEmail,'sendWelcomeEmailsendWelcomeEmail')
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedcreatePatientPortal, setIsCheckedcreatePatientPortal] = useState(false);
console.log(isCheckedcreatePatientPortal,'isCheckedcreatePatientPortal')
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      setsendWelcomeEmail(true)
    } else {
      setsendWelcomeEmail(false)
    }
  };
  const handlecreatePatientPortal = () => {
    setIsCheckedcreatePatientPortal(!isCheckedcreatePatientPortal);
    if (!isCheckedcreatePatientPortal) {
      setsendWelcomeEmail(true)
    } else {
      setsendWelcomeEmail(false)
    }
  };
  const loading = open && insuranceNames.length === 0
  const handleChangeInsurances = (event, newValue) => {
    settabValueIns(newValue)
  }
  const dropdownRef = useRef(null); // Step  1: Create a ref for the dropdown container

  useEffect(() => {
    // Step  2: Add a global click event listener
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenInsurances(false); // Close the dropdown if the click is outside
      }
    };

    // Attach the listener to the document
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const onChangeHandle = async (value) => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    // console.log(value)

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Settings/Searchcitystate?zipcode=${value}`,
        { headers }
      )
      .then((response) => {
        setzipDownloaded(false)
        setZipCodeList(response.data)
        setIsZipCode(true)
        setinsuranceNames(response.data)
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }
  useEffect(() => {
    if (_patientId !== undefined && _patientId!=null) {
      // POST request using axios inside useEffect React hook
      let isComponentMounted = true
      const fetchData = () =>
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/Patients/getPatientById?id=${
              _patientId !== undefined ? _patientId : 0
            }`,
            {
              headers,
            }
          )
          .then((response) => {
            // console.log('response getPatID: ', response.data)
            console.log('response.data: ', response.data)
            var ins = []
            resetAfterAPICall(response.data)
            if (
              isComponentMounted &&
              response.data != null &&
              response.data.id > 0
            ) {
              //   setSmokingStatusRes(response.data.smokingStatus)
            }
            setzipDownloaded(true)
            setPhone(response.data.homePhone)
            setcellPhone(response.data.cellPhone)
            setworkPhone(response.data.workPhone)
            setssn(response.data.ssn)
            // setdob(response.data.dob)
            var dateYMD = Moment(response.data.dob).format('YYYY-MM-DD')
            setdob(dateYMD)
            // console.log('dateYMD', dateYMD)
            if (response.data.photoUrl) {
              // console.log('URL: ',`${process.env.REACT_APP_DOCUMENT_URL}`)
              var Url = `${process.env.REACT_APP_DOCUMENT_URL}/${response.data.photoUrl}`
              // console.log('URL: ',Url)
              setImages(Url)
            }
          })
          .catch((error) => {
            console.error('There was an error!', error)
          })
      fetchData()
      return () => {
        isComponentMounted = false
      }
    }
    else
    {
      setValue('status','' ) 
      setValue('providerId', '')
      setValue('firstName',props.firstName )
      setValue('lastName',props.lastName)
      setValue('dob','03/02/2023')
      setDobDate(Moment(props.DOB).format('YYYY-MM-DD'));
    }
  }, [])
  const facilitiesRedx = useSelector(
    (state) => (state.facilities.loading ? [] : state.facilities.NAMES),
    shallowEqual
  )
  const [images, setImages] = React.useState(null)
  /// Delete Code
  const deleteimg = () => {
    setImages(null)
    setValue('photoUrl', '')
  }
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    facilitiesRedux = []
    facilitiesRedx.forEach((field) => {
      facilitiesRedux.push({
        label: field.name,
        value: field.id,
      })
    })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [{ facilitiesRedx }])
  function resetAfterAPICall(value) {
    var data = {
      id: value.id,
      accountNumber: value.accountNumber,
      lastName: value.lastName,
      firstName: value.firstName,
      midInitial: value.midInitial,
      title: value.title,
      alias: value.alias,
      ssn: value.ssn,
      dob: value.dob,
      gender: value.gender,
      status: value.status,
      cellNo: value.cellNo,
      language: value.language,
      maritalStatus: value.maritalStatus,
      homePhone: value.homePhone,
      cellPhone: value.cellPhone,
      workPhone: value.workPhone,
      emailAddress: value.emailAddress,
      city: value.city,
      state: value.state,
      zipCode: value.zipCode,
      address: value.address,
      photoUrl: value.photoUrl,
      program: value.program,
      smokingStatus: value.smokingStatus,
      comments: value.comments,
      practiceId: value.practiceId,
      facilityId: value.facilityId,
      careTeam_id: value.careTeam_id,
      providerId: value.providerId,
      isActive: value.isActive,
      updatedBy: value.updatedBy,
      updatedDate: value.updatedDate,
      createdBy: value.createdBy,
      createdDate: value.createdDate,
      base64: value.base64,
      priIns: {},
      secIns: {},
    }
    if (
      value.priIns !== undefined &&
      value.priIns !== null &&
      value.priIns.id > 0
    ) {
      var _priIns = {
        id: value.priIns.id,
        insuranceId: value.priIns.insuranceId,
        planName: value.priIns.planName,
        patientId: value.priIns.patientId,
        groupId: value.priIns.groupId,
        memberId: value.priIns.memberId,
        practiceId: value.priIns.practiceId,
        payerId: value.priIns.payerId,
        type: value.priIns.typerimary,
        copay: value.priIns.copay,
        deductible: value.priIns.deductible,
        subsRelation: value.priIns.subsRelation,
        subsFirtName: value.priIns.subsFirtName,
        subsLastName: value.priIns.subsLastName,
        guarranterName: value.priIns.guarranterName,
        guarantorAddress: value.priIns.guarantorAddress,
        guarantorSuite: value.priIns.guarantorSuite,
        guarantorCity: value.priIns.guarantorCity,
        guarantorState: value.priIns.guarantorState,
        guarantorZip: value.priIns.guarantorZip,
        inactive: value.priIns.inactive,
        startDate: Moment(value.priIns.startDate).format('YYYY-MM-DD'),
        endDate: Moment(value.priIns.endDate).format('YYYY-MM-DD'),
        createdDate: value.priIns.createdDate,
        updatedDate: value.priIns.updatedDate,
        createdBy: value.priIns.createdBy,
        updatedBy: value.priIns.updatedBy,
        clientCreatedDate: value.priIns.clientCreatedDate,
        clientUpdatedDate: value.priIns.clientUpdatedDate,
      }
      data.priIns = _priIns
    } else {
      data.priIns = defaultValues.priIns
    }
    if (
      value.secIns != undefined &&
      value.secIns != null &&
      value.secIns.id > 0
    ) {
      var _secIns = {
        id: value.secIns.id,
        insuranceId: value.secIns.insuranceId,
        planName: value.secIns.planName,
        patientId: value.secIns.patientId,
        groupId: value.secIns.groupId,
        memberId: value.secIns.memberId,
        practiceId: value.secIns.practiceId,
        payerId: value.secIns.payerId,
        type: value.secIns.type,
        copay: value.secIns.copay,
        deductible: value.secIns.deductible,
        subsRelation: value.secIns.subsRelation,
        subsFirtName: value.secIns.subsFirtName,
        subsLastName: value.secIns.subsLastName,
        guarranterName: value.secIns.guarranterName,
        guarantorAddress: value.secIns.guarantorAddress,
        guarantorSuite: value.secIns.guarantorSuite,
        guarantorCity: value.secIns.guarantorCity,
        guarantorState: value.secIns.guarantorState,
        guarantorZip: value.secIns.guarantorZip,
        inactive: value.secIns.inactive,
        startDate: Moment(value.secIns.startDate).format('YYYY-MM-DD'),
        endDate: Moment(value.secIns.endDate).format('YYYY-MM-DD'),
        createdDate: value.secIns.createdDate,
        updatedDate: value.secIns.updatedDate,
        createdBy: value.secIns.createdBy,
        updatedBy: value.secIns.updatedBy,
        clientCreatedDate: value.secIns.clientCreatedDate,
        clientUpdatedDate: value.secIns.clientUpdatedDate,
      }
      data.secIns = _secIns
    } else {
      data.secIns = defaultValues.secIns
    }
    const deepCopy = JSON.parse(JSON.stringify(data))
    reset(deepCopy)
  }
  // 1111
  const onSubmit = (data) => {   
    // console.log('submit',data.dob)
    // const ddob=(data.dob).toISOString();
    //  console.log('afterc', data.dob)
    if (data.dob != undefined && data.dob != null)
      data.dob = data.dob.toLocaleString()
    //  console.log('submit',data)
    setApiError('')
    setSubmitting(true)
    const postData = data
    postData.sendWelcomeEmail=isChecked
    postData.createPatientPortal=isCheckedcreatePatientPortal

    //postData.dob = data.dob ? data.dob.value : ''
    if(_patientId !== undefined && _patientId != null) postData.id = _patientId
    else postData.id = 0

    axios
      .post(`${process.env.REACT_APP_API_URL}/Patients/addPatient`, postData, {
        headers,
      })
      .then((response) => {
        if (response.data.id) { 
        //   setValue('city', ''); 
        //    setValue('facilityId', '')
        // setValue('title','')
        // setValue('program', '')
        // setValue('maritalStatus','')
        // setValue('providerId','')
        // setValue('smokingStatus','')
        // setValue('title', '')
        // setValue('emailAddress','')
        // setValue('lastName', '')
        // setValue('firstName', '')
        // setValue('ssn', '')
        // setValue('dob', '')
        // setdob('');
        // setValue('gender', '')
        // setValue('status', '')
        // setValue('homePhone','')
        // setValue('cellPhone','')
        // setcellPhone('')
        // setValue('homePhone', '')
        // setPhone('');
        // setValue('workPhone','')
        // setworkPhone('')
        // setValue('address','')
        // setValue('zipCode','')
        // setValue('city', ''); 
        // setValue('state', ''); 
          alert.success('Record saved successfully.', {
            type: 'success',
            onClose: () => {
              setSubmitting(false)
               if(props.handleclose){
                console.log('handle cancel working' , props.handleclose)
                props.handleclose()
              }

              else  if(props.handleClose1){
                console.log('handle cancel working' , props.handleClose1)
                props.handleClose1()
              }
            else  if (props.cancelmodel) {
                props.cancelmodel(false)
              } else  {
                localStorage.setItem('tabvalue',3)
                if(props.handleClose1){
                  props.handleClose1
                }
                if(typeOf==='function'){
                props.callbackDemo(response.data.id )}
                // history.push({
                //   pathname: `/patientsregistration`,
                //   state: { patientId: response.data.id },
                // })
              }
              // if (props.cancelmodel) {
              //   props.cancelmodel(false)
              // } else {
              //   history.push('/PatientSearch')
              // }
              
            },
          })
        } else {
          setSubmitting(false)
          // setApiError('This email is already registered with another patient.')
          setApiError(response.data)
        }
      })
      .catch((error) => {
        setSubmitting(false)
        alert.success(`Error ${error.message}`, {
          type: 'error',
        })
      })
  }
  //
  const onchangeinsurce = async (value) => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    console.log('getInsuranceByName: ', value)

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Insurances/getInsuranceByName?Name=${value}`,
        { headers }
      )
      .then((response) => {
        setOpenInsurances(true)
        setinsuranceNames(response.data)
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }

  const [patientinfo, setpatientinfo] = useState([])
  const [dob, setdob] = React.useState('')
  const [phone, setPhone] = React.useState()
  const [cellPhone, setcellPhone] = React.useState()
  const [workPhone, setworkPhone] = React.useState()
  const [ssn, setssn] = React.useState()
  const [apiError, setApiError] = useState('')
  const [isReadMore, setIsReadMore] = useState(props.cancelmodel ? true : false)
  const [DobDate, setDobDate] = React.useState()
console.log(DobDate,'DobDate')
  const ReadMore = () => {
    // const text = children
    if (props.cancelmodel) {
      return (
        <p>
          {/* {isReadMore ? text : text} */}
          {/* {text.length > 150 ? ( */}
          <span
            onClick={() => {
              setIsReadMore(!isReadMore)
            }}
            className={classes.readhide}
          >
            {isReadMore ? (
              <Button
                sx={{ mb: 2 }}
                variant="gradient"
                size="small"
                style={{
                  padding: '4px',
                  // float: 'left',
                  height: '33px',
                  marginTop: '10px',
                  // backgroundColor: '#FCBE13',
                  color: 'black',
                  fontSize: '14px',
                }}
                // color="warning"
                // size="small"

                // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
              >
                <ExpandMoreIcon
                  fontSize="medium"
                  style={{
                    color: '#1a82ad',
                    // paddingRight: '4px',
                    paddingBottom: '2px',
                  }}
                ></ExpandMoreIcon>
                Show More
              </Button>
            ) : (
              <Button
                sx={{ mb: 2 }}
                variant="gradient"
                size="small"
                style={{
                  padding: '4px',
                  // float: 'left',
                  height: '33px',
                  marginTop: '10px',
                  // backgroundColor: '#FCBE13',
                  color: 'black',
                  fontSize: '14px',
                }}
                // color="warning"
                // size="small"

                // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
              >
                <ExpandLessIcon
                  fontSize="medium"
                  style={{
                    color: '#1a82ad',
                    // paddingRight: '4px',
                    paddingBottom: '2px',
                  }}
                ></ExpandLessIcon>
                Show Less
              </Button>
            )}
          </span>
          {/* ) : null} */}
        </p>
      )
    }
  }
  const today = new Date()
  var startDate = new Date()
  startDate.setFullYear(startDate.getFullYear() - 100)
  const validationSchema = Yup.object({
    lastName: Yup.string().required('Last Name is required'),
    firstName: Yup.string().required('First Name is required'),
    providerId: Yup.string().required('Please Select Provider'),
    status: Yup.string().required('Please Select Status'),

    program: Yup.string().required('Please Select Program'),

    // ssn: Yup.string().required('Social Security number is required'),
    // accountNumber: Yup.string().required('Account # is required'),
    dob: Yup.date()
      .required('DoB is required')
      .nullable()
      .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
      .max(today, "You can't be born in the future!")
      .min(startDate, "You can't be that much old!"),
    // dob: Yup.string().dob('dob is required'),
    // emailAddress: Yup.string()
    //   .required('Email is invalid')
    //   .email('Email is invalid'),
    //  insuranceId: Yup.string().required('Insurance is required'),
  })
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
  })
  var defaultValues = {
    priIns: {
      id: 0,
      insuranceId: '',
      patientId: '',
      groupId: '',
      memberId: '',
      practiceId: '',
      payerId: '',
      type: 'primary',
      copay: '',
      deductible: '',
      subsRelation: '',
      subsFirtName: '',
      subsLastName: '',
      guarranterName: '',
      guarantorAddress: '',
      guarantorSuite: '',
      guarantorCity: '',
      guarantorState: '',
      guarantorZip: '',
      inactive: '',
      startDate: '',
      endDate: '',
      createdDate: '',
      updatedDate: '',
      createdBy: '',
      updatedBy: '',
      clientCreatedDate: '',
      clientUpdatedDate: '',
    },
    id: '',
    accountNumber: '',
    lastName: '',
    firstName: '',
    midInitial: '',
    title: '',
    alias: '',
    ssn: '',
    dob: '',
    gender: '',
    status: '',
    cellNo: '',
    language: '',
    maritalStatus: '',
    homePhone: '',
    cellPhone: '',
    workPhone: '',
    emailAddress: '',
    city: '',
    state: '',
    zipCode: '',
    address: '',
    photoUrl: '',
    program: '',
    smokingStatus: '',
    comments: '',
    practiceId: '',
    facilityId: '',
    providerId: '',
    isActive: '',
    updatedBy: '',
    updatedDate: '',
    createdBy: '',
    createdDate: '',
    base64: '',
    secIns: {
      id: 0,
      insuranceId: '',
      patientId: '',
      groupId: '',
      memberId: '',
      practiceId: '',
      payerId: '',
      type: 'secondary',
      copay: '',
      deductible: '',
      subsRelation: '',
      subsFirtName: '',
      subsLastName: '',
      guarranterName: '',
      guarantorAddress: '',
      guarantorSuite: '',
      guarantorCity: '',
      guarantorState: '',
      guarantorZip: '',
      inactive: '',
      startDate: '',
      endDate: '',
      createdDate: '',
      updatedDate: '',
      createdBy: '',
      updatedBy: '',
      clientCreatedDate: '',
      clientUpdatedDate: '',
    },
  }
  const providersRedx = useSelector(
    (state) => (state.providers.loading ? [] : state.providers.NAMES),
    shallowEqual
  )

  /// Add Image
  const onImageChange = (e) => {
    setImages(URL.createObjectURL(e.target.files[0]))
    const file = e.target.files[0]
    convertBase64(file)
    // console.log("64 image",base64)
    // console.log('Kdr Sy aya ha Console', URL.createObjectURL(e.target.files[0]))
  }
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    }).then((users) => {
      setValue('base64', users)
    })
  }
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    providersRedux = []
    // console.log('reduxxx', providersRedx)
    providersRedx.forEach((field) => {
      providersRedux.push({
        label: field.name,
        value: field.id,
      })
    })
    // if (_patientId == undefined || _patientId==null || _patientId==0) {
    //   setValue('providerId','' ) 
    // }
  })
  const handleInputChange = (e) => {
    // Remove non-numeric characters from the input value
    const inputValue = e.target.value.replace(/\D/g, '');

    // Update the value of the input field
    e.target.value = inputValue;
  };

  return (
    <>
      {submitting ? (
        <CircularProgress
          style={{
            width: '50px',
            height: '50px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginLeft: '-25px',
            marginTop: '-25px',
            zIndex: 1000,
            color: '#FCBE13',
          }}
        />
      ) : null}
      <Grid
        container
        columnSpacing={1}
        alignContent="center"
        justifyContent="center"
      >
        <Grid container>
          <Grid item xs={3}>
            <Grid container style={{ marginLeft: '30px' }}>
              <Grid item xs={12}>
                <label htmlFor="icon-button-file">
                  <Input
                    id="icon-button-file"
                    type="file"
                    onChange={onImageChange}
                  />
                  <Avatar
                    sx={{ bgcolor: '#FCBE13' }}
                    src={images}
                    style={{
                      height: 120,
                      width: 120,
                      borderRadius: '100px',
                    }}
                  >
                    <AddAPhotoIcon
                      fontSize="large"
                      style={{ color: 'black' }}
                    ></AddAPhotoIcon>
                  </Avatar>
                </label>
              </Grid>
              {images && images.length > 0 ? (
                <Button
                  onClick={deleteimg}
                  variant="gradient"
                  // disabled={submitting}
                  style={{
                    marginLeft: '20px',
                    width: '70px',
                    height: '35px',
                    color: 'black',
                    fontSize: '14px',
                  }}
                  // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                >
                  <DeleteOutlinedIcon
                    fontSize="medium"
                    style={{ color: 'red', marginBottom: '5px' }}
                  ></DeleteOutlinedIcon>
                  Remove
                </Button>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <Grid>
              <p style={{ color: 'red', fontSize: '14px' }}>{apiError}</p>
            </Grid>
            <Grid>
          
            </Grid>
            <Grid container xs={12}>
            <Grid item xs={4}>

            <p style={{ color: 'black', fontSize: '14px' }}> <div>
      <label>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
<span style={{marginLeft:'0.5rem'}}>Send WelCome Email to Patient </span>     </label>
    </div></p>
    </Grid>   
    <Grid item xs={4}>

<p style={{ color: 'black', fontSize: '14px' }}> <div>
<label>
<input
type="checkbox"
checked={isCheckedcreatePatientPortal}
onChange={handlecreatePatientPortal}
/>
<span style={{marginLeft:'0.3rem'}}> Create Patient Portal Account</span>     </label>
</div></p>
</Grid>
    
     </Grid>


            <Grid container columnSpacing={1}>
              <Grid item xs={4}>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontSize="15px"
                >
                  Email address
                </SuiTypography>
              </Grid>
              <Grid item xs={4}>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontSize="15px"
                >
                  Account Number
                </SuiTypography>
              </Grid>
              <Grid item xs={4}>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontSize="15px"
                >
                  Title
                </SuiTypography>
              </Grid>
              <Grid item xs={4} style={{ width: '300px' }}>
                <SuiInput
                  type="email"
                  placeholder="Enter Email address"
                  {...register('emailAddress')}
                  defaultValue={props.emailAddress}

                  error={errors.emailAddress}
                />
                <p style={{ color: 'red' }}>{errors.emailAddress?.message}</p>
              </Grid>
              <Grid item xs={4} style={{ width: '300px' }}>
              <SuiInput                   {...register('accountNumber')}

      inputProps={{ maxLength: 12 }}
      type="input"
      placeholder="Account Number"
      onChange={handleInputChange}
      // Add any other props you need, like registering with Formik or displaying errors
    />
                <p style={{ color: 'red' }}>{errors.accountNumber?.message}</p>
              </Grid>
              <Grid item xs={4} style={{ width: '300px' }}>
                <Controller
                  name="gender"
                  {...register('title')}
                  control={control}
                  render={({ field }) => (
                    <SuiSelect
                      value={{ label: getValues('title') }}
                      onChange={(value) => setValue('title', value.label)}
                      options={[
                        { value: '', label: '' },
                        { value: 'Mr', label: 'Mr' },
                        { value: 'Ms ', label: 'Ms' },
                        { value: 'Dr', label: 'Dr' },
                        { value: 'Mrs', label: 'Mrs' },
                      ]}
                    />
                  )}
                />
                {/* <SuiInput
                      type="input"
                      placeholder="Enter title"
                      {...register('title')}
                    /> */}
              </Grid>
              <Grid item xs={4} style={{ width: '300px' }}>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontSize="15px"
                >
                  Last Name
                </SuiTypography>
              </Grid>
              <Grid item xs={4} style={{ width: '300px' }}>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontSize="15px"
                >
                  First Name
                </SuiTypography>
              </Grid>

              <Grid item xs={4} style={{ width: '300px' }}>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontSize="15px"
                >
                  SSN
                </SuiTypography>
              </Grid>

              <Grid item xs={4} style={{ width: '300px' }}>
                <SuiInput
                  type="input"
                  placeholder="Enter Last Name"
                  {...register('lastName')}
                  error={errors.lastName}
                  // onChange={(e) => setFullName(e.target.value)}
                />
                <p style={{ color: 'red', fontSize: '14px' }}>
                  {errors.lastName?.message}
                </p>
              </Grid>
              <Grid item xs={4} style={{ width: '300px' }}>
                <SuiInput
                  type="input"
                  placeholder="Enter First Name"
                  {...register('firstName')}
                  error={errors.firstName}

                  // onChange={(e) => setFullName(e.target.value)}
                />
                <p style={{ color: 'red', fontSize: '14px' }}>
                  {errors.firstName?.message}
                </p>
              </Grid>

              <Grid item xs={4} style={{ width: '300px' }}>
                <InputMask
                  mask="999-99-9999"
                  disabled={false}
                  value={ssn}
                  style={{ height: '30px' }}
                  onChange={(e) => {
                    setValue('ssn', e.target.value)
                    setssn(e.target.value)
                  }}
                >
                  {() => (
                    <SuiInput
                      id="outlined-basic"
                      fullWidth
                      margin="dense"
                      placeholder="Enter SSN"
                    />
                  )}
                </InputMask>
              </Grid>
            </Grid>
          </Grid>



        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            DOB
          </SuiTypography>
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Gender
          </SuiTypography>
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Status
          </SuiTypography>
        </Grid>
       
        <Grid item xs={3} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Language
          </SuiTypography>
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
        <SuiInput
    type="date"
    placeholder="Enter dob"
    value={dob?dob:DobDate}
    onChange={(e) => {
        setValue('dob', e.target.value)
        setdob(e.target.value)
    }}
    error={errors.dob}
/>

          <p style={{ color: 'red', fontSize: '14px' }}>
            {errors.dob?.message}
          </p>
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <Controller
            name="gender"
            {...register('gender')}
            control={control}
            render={({ field }) => (
              <SuiSelect
                value={{ label: getValues('gender') }}
                onChange={(value) => setValue('gender', value.label)}
                options={[
                  { value: '', label: '' },
                  { value: 'Male', label: 'Male' },
                  { value: 'Female', label: 'Female' },
                  { value: 'Other', label: 'Other' },
                ]}
              />
            )}
          />
        </Grid>
        {/* <Grid item xs={3} style={{ width: '300px' }}>
          <Controller
            name="status"
            {...register('status')}
            control={control}
            render={({ field }) => (
              <SuiSelect
                {...field}
                value={{ label: getValues('status') }}
                onChange={(value) => setValue('status', value.label)}
                options={[
                  { value: 'Active', label: 'Active' },
                  { value: 'InActive', label: 'InActive' },
                  { value: 'Discharge', label: 'Discharge' },
                ]}
                error={errors.status}

              />
            )}
          />
           <p style={{ color: 'red', fontSize: '14px' }}>
              {errors.status?.message}
            </p>
        </Grid> */}
        <Grid item xs={3} style={{ width: '300px' }}>
  <Controller
    name="status"
    control={control}
    defaultValue="Active" // Set default value here
    render={({ field }) => (
      <SuiSelect
        {...field}
        value={{ label: getValues('status') || 'Active' }} // Display default value in the UI
        onChange={(value) => setValue('status', value.label)}
        options={[
          { value: 'Active', label: 'Active' },
          { value: 'InActive', label: 'InActive' },
          { value: 'Discharge', label: 'Discharge' },
        ]}
        error={errors.status}
      />
    )}
  />
  <p style={{ color: 'red', fontSize: '14px' }}>
    {errors.status?.message}
  </p>
</Grid>

        <Grid item xs={3} style={{ width: '300px' }}>
          <SuiInput
            type="input"
            placeholder="Enter language"
            {...register('language')}
          />
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Home Phone
          </SuiTypography>
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Cell Phone
          </SuiTypography>
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Work Phone
          </SuiTypography>
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Address
          </SuiTypography>
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <InputMask
            mask="+1 999-999-9999"
            disabled={false}
            value={phone}
            style={{ height: '30px' }}
            onChange={(e) => {
              setValue('homePhone', e.target.value)
              setPhone(e.target.value)
            }}
          >
            {() => (
              <SuiInput
                id="outlined-basic"
                fullWidth
                margin="dense"
                placeholder="Enter phoneNo"
              />
            )}
          </InputMask>
          {/* <SuiInput
                type="input"
                placeholder="Enter Home Phone"
                {...register('homePhone')}
              /> */}
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <InputMask
            mask="+1 999-999-9999"
            disabled={false}
            value={cellPhone?cellPhone:props.cellPhone}
            style={{ height: '30px' }}
            onChange={(e) => {
              setValue('cellPhone', e.target.value)
              setcellPhone(e.target.value)
            }}
          >
            {() => (
              <SuiInput
                id="outlined-basic"
                fullWidth
                margin="dense"
                placeholder="Enter CellPhone No"
              />
            )}
          </InputMask>
          {/* <SuiInput
                type="input"
                placeholder="Enter cellPhone"
                {...register('cellPhone')}
              /> */}
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <InputMask
            mask="+1 999-999-9999"
            disabled={false}
            value={workPhone}
            style={{ height: '30px' }}
            onChange={(e) => {
              setValue('workPhone', e.target.value)
              setworkPhone(e.target.value)
            }}
          >
            {() => (
              <SuiInput
                id="outlined-basic"
                fullWidth
                margin="dense"
                placeholder="Enter workPhone No"
              />
            )}
          </InputMask>
          {/* <SuiInput
                type="input"
                placeholder="Enter Work Phone"
                {...register('workPhone')}
              /> */}
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <SuiInput
            type="input"
            placeholder="Enter address"
            {...register('address')}
          />
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Zip Code
          </SuiTypography>
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            City
          </SuiTypography>
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            State
          </SuiTypography>
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Marital Status
          </SuiTypography>
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
        <SuiInput       inputProps={{ maxLength: 5 }}

                  type="zipCode"
                  placeholder="Enter ZipCode"
                  {...register('zipCode')}
            
                />
          {/* <SuiInput
            type="input"
            placeholder="ZipCode"
            {...register('zipCode')}
            onKeyUp={(ev) => {
              // dont fire API if the user delete or not entered anything
              if (ev.target.value !== '' || ev.target.value !== null) {
                onChangeHandle(ev.target.value)
              }
            }}
          /> */}
          {/* {isZipCode ? (
            <div
              className="col-xs-3"
              style={{
                padding: '14px 16px',
                fontSize: '0.875rem',
                color: '#67748e',
                borderRadius: '5px',
                background: 'white',
                textAlign: 'justify',
                textJustify: 'inter-word',
                position: 'absolute',
                zIndex: '99',
                width: '25%',
                border: '1px solid #6cb5f9',
              }}
            >
              {zipCodeList.map((s, index) => (
                <p
                  // attribZip={s.zipCode}
                  // attribState={s.state}
                  // attribCity={s.city}
                  // attribIndex={index}

                  style={zipCodeListStyle}
                  onClick={(ev) => {
                    console.log('evvv', ev)
                    // var sel=ev.target.attributes['attribIndex']
                    console.log('Selected: ', zipCodeList[index])
                    setValue('zipCode', zipCodeList[index].zipCode)
                    setValue('city', zipCodeList[index].city)
                    setValue('state', zipCodeList[index].state)
                    setZipCodeList([])
                    setIsZipCode(false)
                  }}
                  onBlur={(ev) => {
                    setZipCodeList([])
                    setIsZipCode(false)
                  }}
                  onChange={(ev) => {
                    setZipCodeList([])
                    setIsZipCode(false)
                  }}
                >
                  {s.zipCode} <strong>City:</strong> {s.city} <strong>State:</strong> {s.state}
                </p>
              ))}
            </div>
          ) : (
            ''
          )} */}
        </Grid>

        <Grid item xs={3} style={{ width: '300px' }}>
          <SuiInput
            type="input"
            // readOnly
            placeholder="Enter city"
            {...register('city')}
          />
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <SuiInput
            type="input"
            // readOnly
            placeholder="Enter state"
            {...register('state')}
          />
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          {/* <SuiInput
                type="input"
                placeholder="Enter maritalstatus"
                {...register('maritalstatus')}
              /> */}
          <Controller
            name="maritalStatus"
            {...register('maritalStatus')}
            control={control}
            render={({ field }) => (
              <SuiSelect
                {...field}
                value={{ label: getValues('maritalStatus') }}
                onChange={(value) => setValue('maritalStatus', value.label)}
                options={[
                  { value: 'Single', label: 'Single' },
                  { value: 'Married', label: 'Married' },
                  { value: 'Widowed', label: 'Widowed' },
                  { value: 'Separated', label: 'Separated' },
                  { value: 'Divorced', label: 'Divorced' },
                ]}
              />
            )}
          />
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Program
          </SuiTypography>
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Smoking
          </SuiTypography>
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Provider
          </SuiTypography>
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Facilities
          </SuiTypography>
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <Controller
            name="program"
            {...register('program')}
            control={control}
            render={({ field }) => (
              <SuiSelect
                {...field}
                value={{ label: getValues('program') }}
                onChange={(value) => setValue('program', value.label)}
                options={[
                  { value: '', label: '' },
                  { value: 'BHI', label: 'BHI' },
                  { value: 'CoCM', label: 'CoCM' },
                ]}
                error={errors.program}
              />
              
            )}
          />
           <p style={{ color: 'red', fontSize: '14px' }}>
              {errors.program?.message}
            </p>
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <Controller
            name="smokingStatus"
            {...register('smokingStatus')}
            control={control}
            render={({ field }) => (
              <SuiSelect
                {...field}
                value={{ label: getValues('smokingStatus') }}
                onChange={(value) => setValue('smokingStatus', value.label)}
                options={[
                  { value: '', label: '' },
                  { value: 'smokingCessation', label: 'Smoking Cessation' },
                ]}
              />
            )}
          />
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <Controller
            name="providerId"
            {...register('providerId')}
            control={control}
            render={({ field }) => (
              <SuiSelect
                isClearable
                {...field}
                default
                value={providersRedux.filter(function (option) {
                  return option.value === getValues('providerId')
                })}
                onChange={(value) => {
                  setValue('providerId', value.value)
                }}
                options={providersRedux}
                error={errors.providerId}
              />
            )}
          />
           <p style={{ color: 'red', fontSize: '14px' }}>
              {errors.providerId?.message}
            </p>
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          <Controller
            name="facilityId"
            {...register('facilityId')}
            control={control}
            render={({ field }) => (
              <SuiSelect
                placeholder="Select Facilities"
                isClearable
                {...field}
                value={facilitiesRedux.filter(function (option) {
                  return option.value === getValues('facilityId')
                  // return (
                  //   option.value ===
                  //   (fetchedData ? getValues('facilityId') : -1)
                  // )
                })}
                options={facilitiesRedux}
                onChange={(value) => {
                  setValue('facilityId', value.value)
                }}
              />
            )}
          />
        </Grid>
        {/* <Grid item xs={12} style={{ width: '300px' }}>
              <SuiTypography
                component="label"
                variant="caption"
                fontSize="15px"
              >
                CareTeam
              </SuiTypography>
            </Grid>
            <Grid item xs={3} style={{ width: '300px' }}>
              <Controller
                name="CareTeam"
                {...register('careTeam_id')}
                control={control}
                render={({ field }) => (
                  <SuiSelect
                    {...field}
                    isClearable
                    value={CareTeamList.filter(function (option) {
                      return option.value === getValues('careTeam_id')
                      // return (
                      //   option.value ==
                      //   (fetchedData ? getValues('careTeam_id') : -1)
                      // )
                    })}
                    // value={CareTeamList.filter(function (option) {
                    //   return option.value === getValues('careTeam_id')
                    // })}
                    options={CareTeamList}
                    onChange={(value) => {
                      setValue('careTeam_id', value.value)
                    }}

                  />
                  // <ReactSelect
                  //   onChange={(e) => setUserFacility(e.value)}
                  //   options={facilitiesList}
                  // />
                )}
              />
            </Grid> 
            <Grid item xs={3} style={{ width: '300px' }}></Grid>
            <Grid item xs={3} style={{ width: '300px' }}></Grid>
            <Grid item xs={3} style={{ width: '300px' }}></Grid> */}
        {props.cancelmodel ? <ReadMore></ReadMore> : null}

        {isReadMore ? (
          ''
        ) : (
          <>
            <Grid item xs={12} style={{ width: '300px' }}>
              <SuiTypography
                component="label"
                variant="caption"
                fontSize="15px"
              >
                Comments
              </SuiTypography>
            </Grid>
            <Grid item xs={12}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={5}
                placeholder="Enter comments"
                style={{
                  width: '100%',
                  borderRadius: '8px',
                  border: '2px solid #black',
                  padding: '5px',
                }}
                {...register('comments')}
              />
            </Grid>
          </>
        )}
        {isReadMore ? (
          ''
        ) : (
          <Grid
            spacing={1}
            alignContent="left"
            justifyContent="left"
            style={{
              width: '100% !important',
              marginLeft: '0px !important',
            }}
          >
            <Accordion
              style={{
                width: '100%',
                marginBottom: '10px',
                border: '1px solid rgba(5, 152, 236, 0.637)',
                borderRadius: '20px',
                marginTop: '10px',
                marginLeft: '5px',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Insurance</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <TabContext value={tabValueIns}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList
                        onChange={handleChangeInsurances}
                        aria-label="lab API tabs example"
                      >
                        <Tab
                          value={0}
                          icon={<PermIdentity />}
                          label="PRIMARY INSURANCE"
                        />
                        <Tab
                          value={1}
                          icon={<AssignmentTurnedInIcon />}
                          label="SECONDARY INSURANCE"
                        />
                      </TabList>
                    </Box>
                    <TabPanel value={0}>
                      {submitting && <div>Saving Form...</div>}
                      <Grid
                        container
                        spacing={1}
                        alignContent="center"
                        justifyContent="center"
                      >
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiTypography style={{ fontSize: '14px' }}>
                                Insurance
                              </SuiTypography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiTypography style={{ fontSize: '14px' }}>
                                Payer
                              </SuiTypography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Group Id
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Member Id
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                            <SuiInput
  type="input"
  placeholder="Primary Insurance"
  {...register('priIns.planName')}
  onKeyUp={(ev) => {
    // Don't fire API if the user deletes or doesn't enter anything
    if (ev.target.value !== '' || ev.target.value !== null) {
      onchangeinsurce(ev.target.value);
    }
  }}
/>
{openInsurances ? (
  <div  ref={dropdownRef} 
    className="col-xs-3"
    style={{
      padding: '14px  16px',
      fontSize: '0.875rem',
      color: '#67748e',
      borderRadius: '5px',
      background: 'white',
      textAlign: 'justify',
      textJustify: 'inter-word',
      position: 'absolute',
      zIndex: '99',
      width: '25%',
      border: '1px solid #6cb5f9',
    }}
  >
    {!insuranceNames.length ? (
      <p style={zipCodeListStyle} >No Record Found
        </p>
    ) : (
      insuranceNames.map((s, index) => (
        <p 
          style={zipCodeListStyle}
          onClick={(ev) => {
            console.log('evvv', ev);
            console.log('Selected: ', zipCodeList[index]);
            setValue('priIns.insuranceId', insuranceNames[index].id);
            setValue('priIns.payerId', insuranceNames[index].payerid);
            setValue('priIns.planName', insuranceNames[index].name);
            setOpenInsurances(false);
            setinsuranceNames([]);
          }}
          onBlur={(ev) => {
            setinsuranceNames([]);
            setOpenInsurances(false);
          }}
          onChange={(ev) => {
            setinsuranceNames([]);
            setOpenInsurances(false);
          }}
        >
          {s.name}
        </p>
      ))
    )}
  </div>
) : (
  ''
)}

                            </Grid>

                            {false ? (
                              <SuiInput
                                type="hidden"
                                placeholder="Insurance"
                                {...register('priIns.insuranceId')}
                                error={errors.insuranceId}
                                label="Insurance"
                                fullWidth
                                margin="dense"
                              />
                            ) : null}
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiInput
                                type="input"
                                placeholder="payerId"
                                {...register('priIns.payerId')}
                                label="payerId"
                                fullWidth
                                margin="dense"
                              />
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiInput
                                type="input"
                                placeholder="groupId"
                                {...register('priIns.groupId')}
                                label="Group ID"
                                fullWidth
                                margin="dense"
                              />
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiInput
                                type="input"
                                placeholder="Please enter Member Id"
                                {...register('priIns.memberId')}
                                fullWidth
                                margin="dense"
                              />
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Copay
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Deductible
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Start Date
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                End Date
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiInput
                                type="number"
                                placeholder="Please enter a Copay"
                                {...register('priIns.copay')}
                                fullWidth
                                margin="dense"
                              />
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiInput
                                type="number"
                                placeholder="Please enter a Deductible"
                                {...register('priIns.deductible')}
                                fullWidth
                                margin="dense"
                              />
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiInput
                                type="date"
                                placeholder="Start Date"
                                {...register('priIns.startDate')}
                                label="Start Date"
                                fullWidth
                                margin="dense"
                              />
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiInput
                                type="date"
                                placeholder="planName"
                                {...register('priIns.endDate')}
                                label="End Date"
                                fullWidth
                                margin="dense"
                              />
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Relation
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Subscriber First Name
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Subscriber Last Name
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Type
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiInput
                                type="input"
                                placeholder="Relation"
                                {...register('priIns.subsRelation')}
                                label="Relation"
                                fullWidth
                                margin="dense"
                              />
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiInput
                                type="input"
                                placeholder="Please Enter Subscriber First Name"
                                {...register('priIns.subsFirtName')}
                                label="SubscriberFirstName"
                                fullWidth
                                margin="dense"
                              />
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiInput
                                type="input"
                                placeholder="Subscriber Last Name"
                                {...register('priIns.subsLastName')}
                                label="SubscriberLastName"
                                fullWidth
                                margin="dense"
                              />
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiInput
                                type="input"
                                readOnly={true}
                                value="Primary"
                                {...register('priIns.type')}
                                label="Primary"
                                fullWidth
                                margin="dense"
                              />
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              item
                              xs={12}
                              sm={12}
                              xl={12}
                              style={{
                                // width: '100%',
                                marginBottom: '10px',
                                border: '1px solid rgba(5, 152, 236, 0.637)',
                                borderRadius: '20px',
                                marginTop: '10px',
                                paddingBottom: '20px',
                                marginLeft: '5px',
                                paddingRight: '10px',
                              }}
                            >
                              <Grid item xs={12}>
                                <Typography style={{ fontSize: '20px' }}>
                                  Guarantor:
                                </Typography>
                              </Grid>
                              <Grid item xs={12}></Grid>
                              <Grid item xs={4} sm={4} xl={4}>
                                <Typography style={{ fontSize: '14px' }}>
                                  Name
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sm={4} xl={4}>
                                <Typography style={{ fontSize: '14px' }}>
                                  Address
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sm={4} xl={4}>
                                <Typography style={{ fontSize: '14px' }}>
                                  Suite
                                </Typography>
                              </Grid>

                              <Grid item xs={4} sm={4} xl={4}>
                                <SuiInput
                                  type="input"
                                  placeholder="Please Enter Guarantor Name"
                                  {...register('priIns.guarranterName')}
                                  label="GuarantorName"
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                              <Grid item xs={4} sm={4} xl={4}>
                                <SuiInput
                                  type="input"
                                  placeholder="Please Enter Guarantor Address"
                                  {...register('priIns.guarantorAddress')}
                                  label="GuarantorAddress"
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                              <Grid item xs={4} sm={4} xl={4}>
                                <SuiInput
                                  type="input"
                                  placeholder="Please Enter Guarantor Suite"
                                  {...register('priIns.guarantorSuite')}
                                  label="GuarantorSuite"
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                              <Grid item xs={4} sm={4} xl={4}>
                                <Typography style={{ fontSize: '14px' }}>
                                  Zip Code
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sm={4} xl={4}>
                                <Typography style={{ fontSize: '14px' }}>
                                  City
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sm={4} xl={4}>
                                <Typography style={{ fontSize: '14px' }}>
                                  State
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sm={4} xl={4}>
                                <SuiInput
                                  type="input"
                                  placeholder="Please Enter Guarantor Zip"
                                  {...register('priIns.guarantorZip')}
                                  label="GuarantorZip"
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                              <Grid item xs={4} sm={4} xl={4}>
                                <SuiInput
                                  type="input"
                                  placeholder="Please Enter Guarantor City"
                                  {...register('priIns.guarantorCity')}
                                  label="GuarantorCity"
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                              <Grid item xs={4} sm={4} xl={4}>
                                <SuiInput
                                  type="input"
                                  placeholder="Please Enter Guarantor State"
                                  {...register('priIns.guarantorState')}
                                  label="GuarantorState"
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value={1}>
                      {submitting && <div>Saving Form...</div>}
                      <Grid
                        container
                        spacing={1}
                        alignContent="center"
                        justifyContent="center"
                      >
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiTypography style={{ fontSize: '14px' }}>
                                Insurance
                              </SuiTypography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiTypography style={{ fontSize: '14px' }}>
                                Payer
                              </SuiTypography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Group Id
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Member Id
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiInput
                                type="input"
                                placeholder="Secondary Insurance"
                                {...register('secIns.planName')}
                                onKeyUp={(ev) => {
                                  // dont fire API if the user delete or not entered anything
                                  if (
                                    ev.target.value !== '' ||
                                    ev.target.value !== null
                                  ) {
                                    onchangeinsurce(ev.target.value)
                                  }
                                }}
                              />
                              {openInsurances ? (
                                <div
                                  className="col-xs-3"
                                  style={{
                                    padding: '14px 16px',
                                    fontSize: '0.875rem',
                                    color: '#67748e',
                                    borderRadius: '5px',
                                    background: 'white',
                                    textAlign: 'justify',
                                    textJustify: 'inter-word',
                                    position: 'absolute',
                                    zIndex: '99',
                                    width: '25%',
                                    border: '1px solid #6cb5f9',
                                  }}
                                >
                                  {insuranceNames.map((s, index) => (
                                    <p
                                      style={zipCodeListStyle}
                                      onClick={(ev) => {
                                        setValue(
                                          'secIns.insuranceId',
                                          insuranceNames[index].id
                                        )
                                        setValue(
                                          'secIns.payerId',
                                          insuranceNames[index].payerid
                                        )
                                        setValue(
                                          'secIns.planName',
                                          insuranceNames[index].name
                                        )
                                        setOpenInsurances(false)
                                        setinsuranceNames([])
                                      }}
                                      onBlur={(ev) => {
                                        setinsuranceNames([])
                                        setOpenInsurances(false)
                                      }}
                                      onChange={(ev) => {
                                        setinsuranceNames([])
                                        setOpenInsurances(false)
                                      }}
                                    >
                                      {s.name}
                                    </p>
                                  ))}
                                </div>
                              ) : (
                                ''
                              )}
                            </Grid>

                            {false ? (
                              <SuiInput
                                type="hidden"
                                placeholder="Insurance"
                                {...register('secIns.insuranceId')}
                                error={errors.insuranceId}
                                label="Insurance"
                                fullWidth
                                margin="dense"
                              />
                            ) : null}
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiInput
                                type="input"
                                placeholder="payerId"
                                {...register('secIns.payerId')}
                                label="payerId"
                                fullWidth
                                margin="dense"
                              />
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiInput
                                type="input"
                                placeholder="groupId"
                                {...register('secIns.groupId')}
                                label="Group ID"
                                fullWidth
                                margin="dense"
                              />
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiInput
                                type="input"
                                placeholder="Please enter Member Id"
                                {...register('secIns.memberId')}
                                fullWidth
                                margin="dense"
                              />
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Copay
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Deductible
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Start Date
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                End Date
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiInput
                                type="number"
                                placeholder="Please enter a Copay"
                                {...register('secIns.copay')}
                                fullWidth
                                margin="dense"
                              />
                              {/*  <CurrencyInput
                                  className={classes.root}
                                  variant="outlined"
                                  style={{
                                    width: '100%',
                                    borderColor: '#EEF0F1',
                                    borderRadius: '10px',
                                    height: '40px',
                                  }}
                                  type="input"
                                  placeholder="Please enter a Copay"
                                  decimalsLimit={2}
                                  {...register('priIns.copay')}
                                  fullWidth
                                  required
                                  margin="dense"
                                /> */}
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiInput
                                type="number"
                                placeholder="Please enter a Deductible"
                                {...register('secIns.deductible')}
                                fullWidth
                                margin="dense"
                              />
                              {/* <CurrencyInput
                                  className={classes.root}
                                  variant="outlined"
                                  style={{
                                    width: '100%',
                                    borderColor: '#EEF0F1',
                                    borderRadius: '10px',
                                    height: '40px',
                                  }}
                                  type="input"
                                  placeholder="Please enter a Deductible"
                                  decimalsLimit={2}
                                  {...register('priIns.deductible')}
                                  label="Deductible"
                                  fullWidth
                                  required
                                  margin="dense"
                                /> */}
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiInput
                                type="date"
                                placeholder="Start Date"
                                {...register('secIns.startDate')}
                                label="Start Date"
                                fullWidth
                                margin="dense"
                              />
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiInput
                                type="date"
                                placeholder="planName"
                                {...register('secIns.endDate')}
                                label="End Date"
                                fullWidth
                                margin="dense"
                              />
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Relation
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Subscriber First Name
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Subscriber Last Name
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <Typography style={{ fontSize: '14px' }}>
                                Type
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiInput
                                type="input"
                                placeholder="Relation"
                                {...register('secIns.subsRelation')}
                                label="Relation"
                                fullWidth
                                margin="dense"
                              />
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiInput
                                type="input"
                                placeholder="Please Enter Subscriber First Name"
                                {...register('secIns.subsFirtName')}
                                label="SubscriberFirstName"
                                fullWidth
                                margin="dense"
                              />
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiInput
                                type="input"
                                placeholder="Subscriber Last Name"
                                {...register('secIns.subsLastName')}
                                label="SubscriberLastName"
                                fullWidth
                                margin="dense"
                              />
                            </Grid>
                            <Grid item xs={6} sm={3} xl={3}>
                              <SuiInput
                                type="input"
                                readOnly={true}
                                value="Secondary"
                                {...register('secIns.type')}
                                label="Secondary"
                                fullWidth
                                margin="dense"
                              />
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              item
                              xs={12}
                              sm={12}
                              xl={12}
                              style={{
                                // width: '100%',
                                marginBottom: '10px',
                                border: '1px solid rgba(5, 152, 236, 0.637)',
                                borderRadius: '20px',
                                marginTop: '10px',
                                paddingBottom: '20px',
                                marginLeft: '5px',
                                paddingRight: '10px',
                              }}
                            >
                              <Grid item xs={12}>
                                <Typography style={{ fontSize: '20px' }}>
                                  Guarantor:
                                </Typography>
                              </Grid>
                              <Grid item xs={12}></Grid>
                              <Grid item xs={4} sm={4} xl={4}>
                                <Typography style={{ fontSize: '14px' }}>
                                  Name
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sm={4} xl={4}>
                                <Typography style={{ fontSize: '14px' }}>
                                  Address
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sm={4} xl={4}>
                                <Typography style={{ fontSize: '14px' }}>
                                  Suite
                                </Typography>
                              </Grid>

                              <Grid item xs={4} sm={4} xl={4}>
                                <SuiInput
                                  type="input"
                                  placeholder="Please Enter Guarantor Name"
                                  {...register('secIns.guarranterName')}
                                  label="GuarantorName"
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                              <Grid item xs={4} sm={4} xl={4}>
                                <SuiInput
                                  type="input"
                                  placeholder="Please Enter Guarantor Address"
                                  {...register('secIns.guarantorAddress')}
                                  label="GuarantorAddress"
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                              <Grid item xs={4} sm={4} xl={4}>
                                <SuiInput
                                  type="input"
                                  placeholder="Please Enter Guarantor Suite"
                                  {...register('secIns.guarantorSuite')}
                                  label="GuarantorSuite"
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                              <Grid item xs={4} sm={4} xl={4}>
                                <Typography style={{ fontSize: '14px' }}>
                                  Zip
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sm={4} xl={4}>
                                <Typography style={{ fontSize: '14px' }}>
                                  City
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sm={4} xl={4}>
                                <Typography style={{ fontSize: '14px' }}>
                                  State
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sm={4} xl={4}>
                                <SuiInput
                                  type="input"
                                  placeholder="Please Enter Guarantor Zip"
                                  {...register('secIns.guarantorZip')}
                                  label="GuarantorZip"
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                              <Grid item xs={4} sm={4} xl={4}>
                                <SuiInput
                                  type="input"
                                  placeholder="Please Enter Guarantor City"
                                  {...register('secIns.guarantorCity')}
                                  label="GuarantorCity"
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                              <Grid item xs={4} sm={4} xl={4}>
                                <SuiInput
                                  type="input"
                                  placeholder="Please Enter Guarantor State"
                                  {...register('secIns.guarantorState')}
                                  fullWidth
                                  margin="dense"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </TabPanel>
                  </TabContext>
                </div>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} lg={12} sm={12}>
        <Button
          onClick={handleCancel}
          variant="gradient"
          disabled={submitting}
          style={{
            marginTop: '30px',
            marginBottom: '10px',
            float: 'right',
            marginLeft: '20px',
            width: '90px',
            height: '35px',
            backgroundColor: '#FCBE13',
            color: 'black',
            fontSize: '14px',
          }}
          // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
        >
          <CancelOutlinedIcon
            fontSize="medium"
            style={{ color: 'red', paddingRight: '5px' }}
          ></CancelOutlinedIcon>
          Cancel
        </Button>

        <Button
          onClick={handleSubmit(onSubmit)}
          variant="gradient"
          disabled={submitting}
          style={{
            marginTop: '30px',
            marginBottom: '10px',
            float: 'right',
            marginLeft: 'auto',
            width: '80px',
            height: '35px',
            backgroundColor: '#FCBE13',
            color: 'black',
            fontSize: '14px',
          }}
          // color="warning"
          // size="small"

          // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
        >
          <SaveOutlinedIcon
            fontSize="medium"
            style={{
              color: '#1a82ad',
              paddingRight: '5px',
              paddingBottom: '2px',
            }}
          ></SaveOutlinedIcon>
          Save
        </Button>
      </Grid>
    </>
  )
}

export default Demographics
