import axios from "axios";
import { USER_PRACTICES, USERS_ERROR } from "../types";

export const getUserPractices = () => async (dispatch) => {
  const auth = `Bearer  ${localStorage.getItem("dtt_token")}`;
  const headers = {
    Authorization: auth,
  };
  try {
    const res = await axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/UserPractices/getUserPractices?userEmail=${localStorage.getItem("dtt_user")}`,
      { headers }
    );
    dispatch({
      type: USER_PRACTICES,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: USERS_ERROR,
      payload: console.log(e),
    });
  }

  return 1;
};
