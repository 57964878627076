import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import { Container, Grid, Typography, Box } from "@material-ui/core";
import Modal from "@mui/material/Modal";
import { createBrowserHistory } from "history";
import Stepper from "react-stepper-horizontal";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
// import { useAlert } from "react-alert";

export default function PHQ(props) {
  const [date, setDate] = useState(new Date(1598051730000));
  const [mode, setMode] = useState("date");
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [active, setActive] = useState(0);
  const [q1, setQ1] = useState(0);
  const [q2, setQ2] = useState(0);
  const [q3, setQ3] = useState(0);
  const [q4, setQ4] = useState(0);
  const [q5, setQ5] = useState(0);
  const [q6, setQ6] = useState(0);
  const [q7, setQ7] = useState(0);
  const [q8, setQ8] = useState(0);
  const [q9, setQ9] = useState(null);
  const [score, setScore] = useState(0);
  // const alert = useAlert();
  const [patientId, setpatientId] = useState(
    localStorage.getItem("dtt_PatientId")
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [title, setTitle] = useState("");

  const handleClose = () => {
    setModalVisible(false);
    // history.push("/Home");
  };

  // const [phqCompleted, setPhqCompleted] = useState(false);

 



  const history = createBrowserHistory({ forceRefresh: true });

  const auth = `Bearer  ${localStorage.getItem("dtt_token")}`;
  const headers = {
    Authorization: auth,
  };

  // const token = `${localStorage.getItem("dtt_PatientToken")}`;
  const updateSteps = (step, newscore = 0) => {
    onNextStep();

    console.log("step: ", step);

    if (step === 1) setQ1(newscore);
    if (step === 2) setQ2(newscore);
    if (step === 3) setQ3(newscore);
    if (step === 4) setQ4(newscore);
    if (step === 5) setQ5(newscore);
    if (step === 6) setQ6(newscore);
    if (step === 7) setQ7(newscore);
    if (step === 8) setQ8(newscore);
    if (step === 9) {
      setQ9(newscore);
    }
    setScore(score + newscore);
  };
  function previousStep(step, newscore = 0) {
    onPreviousStep();

    if (step === 1) setQ1(newscore);
    if (step === 2) setQ2(newscore);
    if (step === 3) setQ3(newscore);
    if (step === 4) setQ4(newscore);
    if (step === 5) setQ5(newscore);
    if (step === 6) setQ6(newscore);
    if (step === 7) {
      setQ7(newscore);
    }
    setScore(score + newscore);
  }
  useEffect(() => {
    if (q9 != null) saveGAD7Result();
  }, [q9]);
  async function saveGAD7Result() {
    const postData = {
      q1: q1,
      q2: q2,
      q3: q3,
      q4: q4,
      q5: q5,
      q6: q6,
      q7: q7,
      q8: q8,
      q9: q9,
      totalScore: score,
      source: "Provider Portal",
      patientId: props.patientId,
    };

    console.log("PostData: ", postData);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientPortal/addPatientPHQ9`,
        postData,
        {
          headers,
        }
      )
      .then((response) => {
        console.log(score);
        console.log(response.data,"record Saved Succesfully");
props.getRespRefresh(response.data)
        // alert.success("Record saved successfully.", {
        //   type: "success",
        //   onClose: () => {
        //     // setOpenNote(false);
        //     // setSubmitting(false);
        //   },
        // });
      
        props.onComplete();
      });
  }
  const progressStepsStyle = {
    completedStepIconColor: "#686868",
    completedProgressBarColor: "#686868",
    completedCheckColor: "#4bb543",
  };

  const buttonTextStyle = {
    // color: '#FCBE13',
    fontWeight: "bold",
  };
  function onNextStep() {
    setActive(active + 1);
    console.log("called next step:", active);
  }
  function onPreviousStep() {
    setActive(active - 1);
    console.log("called Previous step:", active);
  }
  const onChange = (event, selectedDate) => {
    const currentDate = selectedDate || date;

    setDate(currentDate);
  };

  const showMode = (currentMode) => {
    setShow(true);
    setMode(currentMode);
  };

  const gridStyle = {
    marginBottom: "20px",
  };

  const showDatepicker = () => {
    showMode("date");
  };

  const showTimepicker = () => {
    showMode("time");
  };
  const initialStep = [
    { label: "Little interest or pleasure in doing things" },
    { label: "Feeling down, depressed, or hopeless." },
    { label: "Trouble falling or staying asleep, or sleeping too much." },
    { label: "Feeling tired or having little energy." },
    { label: "Poor appetite or overeating." },
    {
      label:
        "Feeling bad about yourself - or that you are a failure or have let yourself or your family down.",
    },
    {
      label:
        "Trouble concentrating on things, such as reading the newspaper or watching television.",
    },
    {
      label:
        "Moving or speaking so slowly that other people could have noticed. Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual.",
    },
    {
      label:
        "Thoughts that you would be better off dead, or of hurting yourself in some way.",
    },
  ];
  const initialStepLabels = [
    { label: "" },
    { label: "" },
    { label: "" },
    { label: "" },
    { label: "" },
    { label: "" },
    { label: "" },
    { label: "" },
    { label: "" },
  ];
  const styleConfig = { size: "50px" };
  const connectorStyleConfig = { stepSize: "10px" };
  const steps = {
    // max-width: 90%;
    marginRight: "100px",
    marginLeft: "80px",
    padding: "100px",
  };

  // const onComplete = (active) => {
  //   if (active > 9) {
  //     props.onComplete();
  //     // setPhqCompleted(true);
  //   }
  // }

  const MyComponent = (props) => {
    // console.log("Props:", props);
    const { title } = props;

    return !props.title ? null : (
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Typography
            variant="p"
            align=""
            gutterBottom
            style={{ fontSize: 15 }}
          >
            {title.label}
          </Typography>
        </Grid>

        <Grid container style={gridStyle}>
          <Grid xs={4}></Grid>
          <Grid xs={4}>
            <Button
              variant="contained"
              // size="large"
              // width="100px"
              sx={{ bgcolor: "#FCBE13" }}
              onClick={() => updateSteps(active + 1, 0)}
              fullWidth
            >
              Not at all
            </Button>
          </Grid>
          <Grid xs={4}></Grid>
        </Grid>

        <Grid container style={gridStyle}>
          <Grid xs={4}></Grid>
          <Grid xs={4}>
            <Button
              variant="contained"
              size="large"
              sx={{ bgcolor: "#FCBE13" }}
              onClick={() => updateSteps(active + 1, 1)}
              fullWidth
            >
              Several days
            </Button>
          </Grid>
          <Grid xs={4}></Grid>
        </Grid>

        <Grid container style={gridStyle}>
          <Grid xs={4}></Grid>
          <Grid xs={4}>
            <Button
              variant="contained"
              size="large"
              sx={{ bgcolor: "#FCBE13" }}
              onClick={() => updateSteps(active + 1, 2)}
              fullWidth
            >
              More than half the days
            </Button>
          </Grid>
          <Grid xs={4}></Grid>
        </Grid>

        <Grid container style={gridStyle}>
          <Grid xs={4}></Grid>
          <Grid xs={4}>
            <Button
              variant="contained"
              size="large"
              sx={{ bgcolor: "#FCBE13" }}
              onClick={() => updateSteps(active + 1, 3)}
              fullWidth
            >
              Nearly every day
            </Button>
          </Grid>
          <Grid xs={4}></Grid>
        </Grid>

        <Grid container style={gridStyle}>
          <Grid xs={4}>
            {active > 0 ? (
              <Button
                style={{
                  backgroundColor: "skyblue",
                  color: "black",
                  marginLeft: "30px",
                }}
                onClick={() => previousStep(active - 1)}
              >
                <ReplyAllIcon
                  style={{
                    marginBottom: "5px",
                    marginRight: "4px",
                    color: "blue",
                  }}
                />{" "}
                Previous
              </Button>
            ) : (
              ""
            )}
          </Grid>
          <Grid xs={8}></Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid Container style={{ maxWidth: "100%" }}>
      <Grid xs={12} sm={12} md={12} lg={12}>
        {active < 9 ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid>
              <h1 style={{ textAlign: "center" }}>PHQ9 </h1>
            </Grid>
            {active > 10 ? null : (
              <Stepper
                steps={initialStepLabels}
                activeStep={active}
                activeColor={"#CB7723"}
                completeColor={"#2EA4D6"}
              />
            )}
            <MyComponent title={active < 10 ? initialStep[active] : null} />
          </Grid>
        ) : null}

        
      </Grid>
    </Grid>
  );
}
