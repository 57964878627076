
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import {
  Typography,
} from '@mui/material'
import React, { useEffect, useReducer, useState, Fragment, useRef } from 'react'
import SuiTypography from 'components/SuiTypography'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { Grid } from '@mui/material'
import Switch from '@mui/material/Switch'
import AllergiesForm from './AllergiesForm'
import MedicationForm from './MedicationForm'


import FamilyHistory from './FamilyHistory'
import PastPsychiatristHistory from './PastPsychiatristHistory'
import SocialStatus from './SocialStatus'
import PastSurgicalHistory from './PastSurgicalHistory'
import PastMedicalHistory from './PastMedicalHistory'
function MedicalHistory(props) {

  // const pastMedicalHistory = ['Diabetes', 'Hypertension', 'Thyroid Disease', 'Heart Disease', 'Kidney Disease','Lung Disease','Autoimmune Disease','Chronic Pain ','Arthritis','Visual Impairment','Dyslipidemia','Sleep Apnea','Seasonal Allergies','Insomnia','Gastrointestinal Problems']
  const _patientId = props.patientId
  return (
    <>

    
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Allergies</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* <Typography>
          
          </Typography> */}
          <AllergiesForm   patientId={_patientId}></AllergiesForm>
        </AccordionDetails>
      </Accordion>
      
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Medications</Typography>
        </AccordionSummary>
        <AccordionDetails>
       <MedicationForm patientId={_patientId}></MedicationForm>
        </AccordionDetails>
      </Accordion> 
     
     
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Past Psychiatrist History</Typography>
        </AccordionSummary>
        <AccordionDetails>
     <PastPsychiatristHistory patientId={_patientId}></PastPsychiatristHistory>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Past Medical History</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <PastMedicalHistory patientId={_patientId}></PastMedicalHistory>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Past Surgical History</Typography>
        </AccordionSummary>
        <AccordionDetails>
      <PastSurgicalHistory patientId={_patientId}></PastSurgicalHistory>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Socail History</Typography>
        </AccordionSummary>
        <AccordionDetails>
      <SocialStatus patientId={_patientId}></SocialStatus>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Family History</Typography>
        </AccordionSummary>
        <AccordionDetails>
    <FamilyHistory patientId={_patientId}></FamilyHistory>
        </AccordionDetails>
      </Accordion>
   
    </>
  
  )
}

export default MedicalHistory
