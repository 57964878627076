import axios from "axios";
import { PROVIDERS, PROVIDER_ERROS } from "../types";

export const getProvidersNames = () => async (dispatch) => {

  const auth = `Bearer  ${localStorage.getItem("dtt_token")}`;
  const headers = {
    Authorization: auth,
  };
  try {

    const res = await axios.get(`${process.env.REACT_APP_API_URL}/Providers/ProvidersNames`, {
      headers,
    });
    dispatch({
      type: PROVIDERS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: PROVIDER_ERROS,
      payload: console.log(e),
    });
  }

  return 1;
};
