import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import SuiButton from 'components/SuiButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';
import axios from 'axios';
import { Typography } from '@mui/material';

const Timelogeidth1 = ({ Timelogid ,providerFirstName,providerlastName,totalTimeSpent}) => {
  const auth = `Bearer ${localStorage.getItem('dtt_token')}`;
  const headers = {
    Authorization: auth,
  };
  const [open, setOpen] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);
  const [rows, setRows] = useState([]);

  const handleOpen = (cell) => {
    setSelectedCell(cell);
    setOpen(true);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/PatientTimeLog/GetProviderTimeLogByProviderId?id=${Timelogid}`, { headers })
      .then((response) => {
        setRows(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleClose = () => {
    setOpen(false);
    setSelectedCell(null);
  };

  const handleDelete = () => {
    // Your delete logic here
    setOpen(false);
  };
  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 5,
    background: 'white',
    fontSize: '13.6px !important',
    color: '#8392ab !important',
    borderColor: 'rgba(5, 152, 236, 0.637) !important',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#FFFFC2',
      borderRadius: 1.8,
    },
  }
  const timelogColumns = [
    {
      field: 'id',
      headerName: 'ID',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'firstname',
      headerName: 'Patient Name',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
      renderCell: (cellValues) => {
        return (
          <SuiButton
            size="small"
            variant="text"
            color="info"
            // onClick={() => {
            //   setOpenEidthReport(cellValues.row.id);
            //   setopenNote(false);
            // }}
          >{`${cellValues.row.lastname}, ${cellValues.row.firstname}`}</SuiButton>
        );
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    {
      field: 'startTime',
      headerName: 'Start Time',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    {
      field: 'endTime',
      headerName: 'End Time',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.4,
    },
    {
      field: 'timeSpent',
      headerName: 'Total Time',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.2,
    },
    // {
    //   field: 'delete',
    //   headerName: 'Delete',
    //   sortable: true,
    //   headerClassName: 'super-app-theme--header',
    //   flex: 0.3,
    //   renderCell: (cell) => (
    //     <>
    //       <DeleteIcon
    //         style={{
    //           height: '16px',
    //           width: '22px',
    //           marginRight: '5px',
    //           float: 'right',
    //           marginTop: '5px',
    //           color: 'red',
    //         }}
    //         onClick={() => handleOpen(cell)}
    //       />
    //       <Dialog
    //         open={open}
    //         onClose={handleClose}
    //         aria-labelledby="responsive-dialog-title"
    //         BackdropComponent={Backdrop}
    //         BackdropProps={{
    //           invisible: true,
    //         }}
    //         PaperProps={{
    //           style: {
    //             background: '#cef5f0',
    //             boxShadow: 'none',
    //             borderRadius: '8px',
    //           },
    //         }}
    //       >
    //         <div style={{ borderRadius: '8px' }}>
    //           <ErrorOutlineIcon
    //             style={{ marginLeft: '125px', fontSize: '100px', color: 'orange' }}
    //           />
    //           <DialogTitle>{'Do you want to delete the record?'}</DialogTitle>
    //           <h5 style={{ textAlign: 'center' }}>This action cannot be undone.</h5>
    //           <DialogContent>
    //             <DialogContentText>
    //               <Grid style={{ marginLeft: '90px' }}>
    //                 <Button onClick={handleDelete} style={{ backgroundColor: 'red', color: 'white' }}>
    //                   YES
    //                 </Button>
    //                 <Button onClick={handleClose} style={{ backgroundColor: 'blue', color: 'white', marginLeft: '1rem' }}>
    //                   NO
    //                 </Button>
    //               </Grid>
    //             </DialogContentText>
    //           </DialogContent>
    //         </div>
    //       </Dialog>
    //     </>
    //   ),
    // },
  ];

  return (
    <>
    <div style={{ height: 450, width: '100%', marginTop:20  }}>
        <Grid item     xs={12}  sm={12}  md={12}  lg={12}
        style={{display:"flex"}}>
            <Grid item     xs={2}  sm={2} md={2}  lg={2}>
<Typography style={{fontSize:25 ,marginTop:-20,marginBottom:10}}><b>ID   : </b>  {Timelogid}</Typography>
            </Grid>
            <Grid item     xs={4}  sm={4} md={4}  lg={4}>
<Typography style={{fontSize:25 ,marginTop:-20,marginBottom:10}}><b>Provider Name   : </b>  {providerlastName},{providerFirstName}</Typography>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item     xs={4}  sm={4} md={4}  lg={4}>
<Typography style={{fontSize:25 ,marginTop:-20,marginBottom:10}}><b>Total Time Spent   : </b>  {totalTimeSpent}</Typography>
            </Grid>
        </Grid>
        
      <DataGrid style={{height: 400}} rows={rows} 
      columns={timelogColumns} 
      pageSize={10}
      headerHeight={37}
      rowsPerPageOptions={[10]}
      sx={gridRowStyle}
      />
    </div>
    </>
  );
};

export default Timelogeidth1;
