import React, { useEffect, useReducer, useState, Fragment } from 'react'
import SuiInput from 'components/SuiInput'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import { createBrowserHistory } from 'history'
import axios from 'axios'
const history = createBrowserHistory({ forceRefresh: true })
const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
const headers = {
  Authorization: auth,
}
export default function YBOCS(props) {
  const id = props.id

  const [question1, setQuestion1] = useState('')
  const [question2, setQuestion2] = useState('')
  const [question3, setQuestion3] = useState('')
  const [question4, setQuestion4] = useState('')
  const [question5, setQuestion5] = useState('')
  const [question6, setQuestion6] = useState('')
  const [question7, setQuestion7] = useState('')
  const [question8, setQuestion8] = useState('')
  const [question9, setQuestion9] = useState('')
  const [question10, setQuestion10] = useState('')
  const [question11, setQuestion11] = useState('')
  const [question12, setQuestion12] = useState('')
  const [question13, setQuestion13] = useState('')
  const [question14, setQuestion14] = useState('')
  const [question15, setQuestion15] = useState('')
  const [question16, setQuestion16] = useState('')
  const [question17, setQuestion17] = useState('')
  const [question18, setQuestion18] = useState('')
  const [question19, setQuestion19] = useState('')
  const [question20, setQuestion20] = useState('')
  const [question21, setQuestion21] = useState('')
  const [question22, setQuestion22] = useState('')
  const [question23, setQuestion23] = useState('')
  const [question24, setQuestion24] = useState('')
  const [question25, setQuestion25] = useState('')
  const [question26, setQuestion26] = useState('')
  const [question27, setQuestion27] = useState('')
  const [question28, setQuestion28] = useState('')
  const [question29, setQuestion29] = useState('')
  const [question30, setQuestion30] = useState('')
  const [question31, setQuestion31] = useState('')
  const [question32, setQuestion32] = useState('')
  const [question33, setQuestion33] = useState('')
  const [question34, setQuestion34] = useState('')
  const [question35, setQuestion35] = useState('')
  const [question36, setQuestion36] = useState('')
  const [question37, setQuestion37] = useState('')
  const [question38, setQuestion38] = useState('')
  const [question39, setQuestion39] = useState('')
  const [question40, setQuestion40] = useState('')
  const [question41, setQuestion41] = useState('')
  const [question42, setQuestion42] = useState('')
  const [question43, setQuestion43] = useState('')
  const [question44, setQuestion44] = useState('')
  const [question45, setQuestion45] = useState('')
  const [question46, setQuestion46] = useState('')
  const [question47, setQuestion47] = useState('')
  const [question48, setQuestion48] = useState('')
  const [question49, setQuestion49] = useState('')
  const [question50, setQuestion50] = useState('')
  const [question51, setQuestion51] = useState('')
  const [question52, setQuestion52] = useState('')
  const [question53, setQuestion53] = useState('')
  const [question54, setQuestion54] = useState('')
  const [question55, setQuestion55] = useState('')
  const [question56, setQuestion56] = useState('')
  const [question57, setQuestion57] = useState('')
  const [question58, setQuestion58] = useState('')
  const [question59, setQuestion59] = useState('')
  const [question60, setQuestion60] = useState('')
  const [question61, setQuestion61] = useState('')
  const [question62, setQuestion62] = useState('')
  const [question63, setQuestion63] = useState('')
  const [question64, setQuestion64] = useState('')
  const [question65, setQuestion65] = useState('')
  const [question66, setQuestion66] = useState('')
  const [question67, setQuestion67] = useState('')
  const [question68, setQuestion68] = useState('')
  const [question69, setQuestion69] = useState('')
  const [question70, setQuestion70] = useState('')
  const [question71, setQuestion71] = useState('')
  const [question72, setQuestion72] = useState('')
  const [question73, setQuestion73] = useState('')
  const [question74, setQuestion74] = useState('')
  const [question75, setQuestion75] = useState('')
  const [question76, setQuestion76] = useState('')
  const [question77, setQuestion77] = useState('')
  const [question78, setQuestion78] = useState('')
  const [question79, setQuestion79] = useState('')
  const [question80, setQuestion80] = useState('')
  const [question81, setQuestion81] = useState('')
  const [question82, setQuestion82] = useState('')
  const [question83, setQuestion83] = useState('')
  const [question84, setQuestion84] = useState('')
  const [question85, setQuestion85] = useState('')
  const [question86, setQuestion86] = useState('')
  const [question87, setQuestion87] = useState('')
  const [question88, setQuestion88] = useState('')
  const [question89, setQuestion89] = useState('')
  const [question90, setQuestion90] = useState('')

  const [date, setDate] = useState('')

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    let isComponentMounted = true
    const fetchData = async () =>
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/PatientPortal/getYbocs90ById?Id=${
            id !== undefined ? id : 0
          }`,
          { headers }
          // `${process.env.REACT_APP_API_URL}/Facilities/getFacilityById?id=${
          //   location.state.facilityId !== undefined ? location.state.facilityId : 0
          // }`,
          // null,
          // {
          //   headers,
          // }
        )
        .then((response) => {
          //   console.log('response.data: ', response.data)
          setQuestion1(response.data.q1)
          setQuestion2(response.data.q2)
          setQuestion3(response.data.q3)
          setQuestion4(response.data.q4)
          setQuestion5(response.data.q5)
          setQuestion6(response.data.q6)
          setQuestion7(response.data.q7)
          setQuestion8(response.data.q8)
          setQuestion9(response.data.q9)
          setQuestion10(response.data.q10)
          setQuestion11(response.data.q11)
          setQuestion12(response.data.q12)
          setQuestion13(response.data.q13)
          setQuestion14(response.data.q14)
          setQuestion15(response.data.q15)
          setQuestion16(response.data.q16)
          setQuestion17(response.data.q17)
          setQuestion18(response.data.q18)
          setQuestion19(response.data.q19)
          setQuestion20(response.data.q20)
          setQuestion21(response.data.q21)
          setQuestion22(response.data.q22)
          setQuestion23(response.data.q23)
          setQuestion24(response.data.q24)
          setQuestion25(response.data.q25)
          setQuestion26(response.data.q26)
          setQuestion27(response.data.q27)
          setQuestion28(response.data.q28)
          setQuestion29(response.data.q29)
          setQuestion30(response.data.q30)
          setQuestion31(response.data.q31)
          setQuestion32(response.data.q32)
          setQuestion33(response.data.q33)
          setQuestion34(response.data.q34)
          setQuestion35(response.data.q35)
          setQuestion36(response.data.q36)
          setQuestion37(response.data.q37)
          setQuestion38(response.data.q38)
          setQuestion39(response.data.q39)
          setQuestion40(response.data.q40)
          setQuestion41(response.data.q41)
          setQuestion42(response.data.q42)
          setQuestion43(response.data.q43)
          setQuestion44(response.data.q44)
          setQuestion45(response.data.q45)
          setQuestion46(response.data.q46)
          setQuestion47(response.data.q47)
          setQuestion48(response.data.q48)
          setQuestion49(response.data.q49)
          setQuestion50(response.data.q50)
          setQuestion51(response.data.q51)
          setQuestion52(response.data.q52)
          setQuestion53(response.data.q53)
          setQuestion54(response.data.q54)
          setQuestion55(response.data.q55)
          setQuestion56(response.data.q56)
          setQuestion57(response.data.q57)
          setQuestion58(response.data.q58)
          setQuestion59(response.data.q59)
          setQuestion60(response.data.q60)
          setQuestion61(response.data.q61)
          setQuestion62(response.data.q62)
          setQuestion63(response.data.q63)
          setQuestion64(response.data.q64)
          setQuestion65(response.data.q65)
          setQuestion66(response.data.q66)
          setQuestion67(response.data.q67)
          setQuestion68(response.data.q68)
          setQuestion69(response.data.q69)
          setQuestion70(response.data.q70)
          setQuestion71(response.data.q71)
          setQuestion72(response.data.q72)
          setQuestion73(response.data.q73)
          setQuestion74(response.data.q74)
          setQuestion75(response.data.q75)
          setQuestion76(response.data.q76)
          setQuestion77(response.data.q77)
          setQuestion78(response.data.q78)
          setQuestion79(response.data.q79)
          setQuestion80(response.data.q80)
          setQuestion81(response.data.q81)
          setQuestion82(response.data.q82)
          setQuestion83(response.data.q83)
          setQuestion84(response.data.q84)
          setQuestion85(response.data.q85)
          setQuestion86(response.data.q86)
          setQuestion87(response.data.q87)
          setQuestion88(response.data.q88)
          setQuestion89(response.data.q89)
          setQuestion90(response.data.q90)

          setDate(response.data.date)
        })
        .catch((error) => {
          console.error('There was an error!', error)
        })
    fetchData()
    return () => {
      isComponentMounted = false
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [])
  return (
    <Grid container>
      <Typography variant="h2" style={{ textAlign: 'center' }}>
      CRAFFT Screening Tool for Adolescent Substance Abuse
      </Typography>
      <Grid container>
        <Typography
          style={{ marginBottom: '30px', marginTop: '50px', textAlign: 'left' }}
        >
          General Instructions
        </Typography>
        <Typography style={{ marginBottom: '5px', fontSize: '12px' }}>
          This rating scale is designed to rate the severity and type of
          symptoms in patients with obsessive compulsive disorder (OCD). In
          general, the items depend on the patient's report; however, the final
          rating is based on the clinical judgement of the interviewer. Rate the
          characteristics of each item during the prior week up until and
          including the time of the interview. Scores should reflect the average
          (mean) occurrence of each item for the entire week.
        </Typography>
        <Typography style={{ marginBottom: '5px', fontSize: '12px' }}>
          This rating scale is intended for use as a semi-structured interview.
          The interviewer should assess the items in the listed order and use
          the questions provided. However, the interviewer is free to ask
          additional questions for purposes of clarification. If the patient
          volunteers information at any time during the interview, that
          information will be considered. Ratings should be based primarily on
          reports and observations gained during the interview. If you judge
          that the information being provided is grossly inaccurate, then the
          reliability of the patient is in doubt and should be noted accordingly
          at the cad of the interview (item 19).
        </Typography>
        <Typography style={{ marginBottom: '5px', fontSize: '12px' }}>
          Additional information supplied by others (e.g., spouse or parent) may
          be included in a determination of the ratings only if it is judged
          that (1) such information is essential to adequately assessing symptom
          severity and (2) consistent week-to-week reporting can be ensured by
          having the same informant(s) present for each rating session.
        </Typography>
        <Typography style={{ marginBottom: '5px', fontSize: '12px' }}>
          Before proceeding with the questions, define "obsessions" and
          "compulsions" for the patient as follows:
        </Typography>
        <Typography style={{ marginBottom: '5px', fontSize: '12px' }}>
          "OBSESSIONS are unwelcome and distressing ideas, thoughts, images or
          impulses that repeatedly enter your mind. They may seem to occur
          against your will. They may be repugnant to you, you may recognize
          them as senseless, and they may not fit your personality."
        </Typography>
        <Typography style={{ marginBottom: '5px', fontSize: '12px' }}>
          "COMPULSIONS, on the other hand, are behaviors or acts that you feel
          driven to perform although you may recognize them as senseless or
          excessive. At times, you may try to resist doing them but this may
          prove difficult. You may experience anxiety that does not diminish
          until the behavior is completed."
        </Typography>
        <Typography style={{ marginBottom: '5px', fontSize: '12px' }}>
          "An example of an obsession is: the recurrent thought or impulse to do
          serious physical harm to your children even though you never would."
        </Typography>
        <Typography style={{ marginBottom: '5px', fontSize: '12px' }}>
          "An example of a compulsion is: the need to repeatedly check
          appliances, water faucets, and the lock on the front door before you
          can leave the house. While most compulsions are observable behaviors,
          some are unobservable mental acts, such as silent checking or having
          to recite nonsense phrases to yourself each time you have a bad
          thought."
        </Typography>
        <Typography style={{ marginBottom: '5px', fontSize: '12px' }}>
          "Do you have any questions about what these words mean?" [If not,
          proceed.]
        </Typography>
        <Typography style={{ marginBottom: '5px', fontSize: '12px' }}>
          <Typography style={{ marginBottom: '5px', fontSize: '12px' }}>
            On repeated testing it is not always necessary to re-read these
            definitions and examples as long as it can be established that the
            patient understands them. It may be sufficient to remind the patient
            that obsessions are the thoughts or concerns and compulsions are the
            things you feel driven to do, including covert mental acts.
          </Typography>
        </Typography>
        <Typography style={{ marginBottom: '5px', fontSize: '12px' }}>
          Have the patient enumerate current obsessions and compulsions in order
          to generate a list of target symptoms. Use the Y-BOCS Symptom
          Checklist as an aid for identifying current symptoms. It is also
          useful to identify and be aware of past symptoms since they may
          re-appear during subsequent ratings. Once the current types of
          obsessions and compulsions are identified, organize and list them on
          the Target Symptoms form according to clinically convenient
          distinctions (e.g., divide target compulsions into checking and
          washing). Describe salient features of the symptoms so that they can
          be more easily tracked (e.g., in addition to listing checking, specify
          what the patient checks for). Be sure to indicate which are the most
          prominent symptoms; i.e., those that will be the major focus of
          assessment. Note, however, that the final score for each item should
          reflect a composite rating of all of the patient's obsessions or
          compulsions.
        </Typography>
        <Typography style={{ marginBottom: '5px', fontSize: '12px' }}>
          The rater must ascertain whether reported behaviors are bona fide
          symptoms of OCD and not symptoms of another disorder, such as Simple
          Phobia or a Paraphilia. The differential diagnosis between certain
          complex motor tics and certain compulsions (e.g., involving touching)
          may be difficult or impossible. In such cases, it is particularly
          important to provide explicit descriptions of the target symptoms and
          to be consistent in subsequent ratings. Separate assessment of tie
          severity with a tic rating instrument may be necessary in such cases.
          Some of the items listed on the Y-BOCS Symptom Checklist, such as
          trichotillomania, are currently classified in DSM-m-R as symptoms of
          an Impulse Control Disorder. It should be noted that the suitability
          of the Y-BOCS for use in disorders other than DSM-m-R-defined OCD has
          yet to be established. However, when using the Y-BOCS to rate severity
          of symptoms not strictly classified under OCD (e.g., trichotillomania)
          in a patient who otherwise meets criteria for OCD, it has been our
          practice to admuista the Y-BOCS twice: once for conventional
          obsessivecompulsive symptoms, and a second time for putative
          OCD-related phenomena. In this fashion separate Y-BOCS scores are
          generated for severity of OCD and severity of other symptoms in which
          the relationship to OCD is still unsettled.
        </Typography>
        <Typography style={{ marginBottom: '5px', fontSize: '12px' }}>
          On repeated testing, review and, if necessary, revise target
          obsessions prior to rating item I. Do likewise for compulsions prior
          to rating item 6.
        </Typography>
        <Typography style={{ marginBottom: '5px', fontSize: '12px' }}>
          All 19 items are rated, but only items 1-10 (excluding items lb and
          6b) are used to determine the total score. The total Y-BOCS score is
          the sum of items 1-10 (excluding lb and 6b), whereas the obsession and
          compulsion subtotals are the sums of items 1-5 (excluding lb) and 10
          (excluding 6b3; respectively.
        </Typography>{' '}
        <Typography style={{ marginBottom: '5px', fontSize: '12px' }}>
          Because at the time of this writing (9/89) there are limited data
          regarding the psychometric properties of items lb, 6b, and 11-16,
          these items should be considered investigational. Until adequate
          studies of toe reliability, validity, and sensitivity to change of
          those items are conducted, we must caution against placing much weight
          on results derived from these item scores. These important caveats
          aside, we believe that items lb (obsession-free interval), 6b
          (compulsion-free interval), and 12 (avoidance) may provide information
          that has bearing on the severity of obsessive-compulsive symptoms.
          Item 11 (insight) may also furnish useful clinical information. We are
          least secure about the usefulness of items 13-16.
        </Typography>
        <Typography style={{ marginBottom: '5px', fontSize: '12px' }}>
          Items 17 (global severity) and 18 (global improvement) have been
          adapted from the Clinical Global Impression Seale (Guy W, 1976) to
          provide measures of overall functional impairment associated with, but
          not restricted to, the presence of obsessive-compulsive symptoms.
          Disability produced by secondary depressive symptoms would also be
          considered when rating these items. Item 19, which estimates the
          reliability of the information reported by the patient, may assist in
          the interpretation of scores on other Y-BOCS items in some cases of
          OCD.
        </Typography>
      </Grid>
      <Grid container>
        <Typography
          style={{ textAlign: 'center', fontWeight: 'bold', margin: '10px' }}
        >
          Y-BOCS SYMPTOM CHECKLIST (9/89)
        </Typography>
      </Grid>
      <Grid>
        <Typography style={{ fontSize: '14px' }}>
          Check all that apply, but clearly mark the principal symptoms with a
          "P", (Rater must asertain whether reported behviors are bona fide
          symptoms of OCD, and not symptoms of another disorder such as Simple
          Phobia or Hypochondriasis. Items maried "*" may or may not be OCD
          phenomena.)
        </Typography>
      </Grid>
      <Grid container style={{ textAlign: 'center' }}>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
                scope="col"
                colSpan={2}
              >
                AGGRESSIVE OBSESSIONS
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Current
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Past
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Fear might harm self
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question1"
                  checked={question1}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question1"
                  checked={!question1}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Fear might harm others
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question2"
                  checked={question2}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question2"
                  checked={!question2}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Violent or horrific images
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question3"
                  checked={question3}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question3"
                  checked={!question3}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Fear of blurting out obscenities or insults
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question4"
                  checked={question4}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question4"
                  checked={!question4}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Fear of doing something else embarrassing *
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question5"
                  checked={question5}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question5"
                  checked={!question5}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Fear will act on unwanted impulses (e.g. to stab friend)
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question6"
                  checked={question6}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question6"
                  checked={!question6}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Fear will steal things
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question7"
                  checked={question7}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question7"
                  checked={!question7}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Fear will harm others because not careful enough (e.g. hit/run
                MVA)
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question8"
                  checked={question8}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question8"
                  checked={!question8}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Fear will be responsible for something else terrible happening
                (e.g. fire, burglary){' '}
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question9"
                  checked={question9}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question9"
                  checked={!question9}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Other{' '}
              </td>
              <th
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question10"
                  checked={question10}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question10"
                  checked={!question10}
                  value="false"
                ></input>
              </th>
            </tr>
          </tbody>
        </table>
      </Grid>
      <Grid container style={{ textAlign: 'center' }}>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
                scope="col"
                colSpan={2}
              >
                CONTAMINATION OBSESSIONS{' '}
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Current
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Past
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Concerns or disgust with bodily waste or secretions (e.g. urine,
                feces, saliva){' '}
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question11"
                  checked={question11}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question11"
                  checked={!question11}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Concern with dirt or germs
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question12"
                  checked={question12}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question12"
                  checked={!question12}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Excessive concern with environmental contarninants (c.g.
                asbestos, radiation, toxic waste){' '}
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question13"
                  checked={question13}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question13"
                  checked={!question13}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Excessive concern with environmental contarninants (c.g.
                asbestos, radiation, toxic waste){' '}
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question14"
                  checked={question14}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question14"
                  checked={!question14}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Excessive concern with animals (e.g. insects){' '}
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question15"
                  checked={question15}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question15"
                  checked={!question15}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Bothered by sticky substances or residues{' '}
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question16"
                  checked={question16}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question16"
                  checked={!question16}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Bothered by sticky substances or residues
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question17"
                  checked={question17}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question17"
                  checked={!question17}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Concerned will get others ill by spreading contaminant
                (Aggressive)
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question18"
                  checked={question18}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question18"
                  checked={!question18}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                No concern with consequences of contarnination other than how it
                might feel
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question19"
                  checked={question19}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question19"
                  checked={!question19}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Other{' '}
              </td>
              <th
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question20"
                  checked={question20}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question20"
                  checked={!question20}
                  value="false"
                ></input>
              </th>
            </tr>
          </tbody>
        </table>
      </Grid>
      <Grid container style={{ textAlign: 'center' }}>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
                scope="col"
                colSpan={2}
              >
                SEXUAL OBSESSIONS
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Current
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Past
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Forbidden or perverse sexual thoughts, images, or impulses
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question21"
                  checked={question21}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question21"
                  checked={!question21}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Content involves children or incest
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question22"
                  checked={question22}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question22"
                  checked={!question22}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Content involves homosexuality *
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question23"
                  checked={question23}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question23"
                  checked={!question23}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Sexual behavior toward others (Aggressive)*
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question24"
                  checked={question24}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question24"
                  checked={!question24}
                  value="false"
                ></input>
              </th>
            </tr>

            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Other{' '}
              </td>
              <th
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question25"
                  checked={question25}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question25"
                  checked={!question25}
                  value="false"
                ></input>
              </th>
            </tr>
          </tbody>
        </table>
      </Grid>
      <Grid container style={{ textAlign: 'center' }}>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
                scope="col"
                colSpan={2}
              >
                MOARDING/SAVING OBSESSIONS
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Current
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Past
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                [distinguish from hobbies and concern with objects of monetary
                or sentimental value]{' '}
              </td>
              <th
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question26"
                  checked={question26}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question26"
                  checked={!question26}
                  value="false"
                ></input>
              </th>
            </tr>
          </tbody>
        </table>
      </Grid>
      <Grid container style={{ textAlign: 'center' }}>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
                scope="col"
                colSpan={2}
              >
                RELIGIOUS OBSESSIONS
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Current
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Past
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                (Scrupulosity) Concerned with sacrilege and blasphemy
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question27"
                  checked={question27}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question27"
                  checked={!question27}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Excess concern with right/wrong, morality
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question28"
                  checked={question28}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question28"
                  checked={!question28}
                  value="false"
                ></input>
              </th>
            </tr>

            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Other{' '}
              </td>
              <th
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question29"
                  checked={question29}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question29"
                  checked={!question29}
                  value="false"
                ></input>
              </th>
            </tr>
          </tbody>
        </table>
      </Grid>
      <Grid container style={{ textAlign: 'center' }}>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
                scope="col"
                colSpan={2}
              >
                OBSESSION WITH NEED FOR SYMMETRY OR EXACTNESS
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Current
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Past
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                (Accompanied by magical thinking (c.x., concerned the mother
                will have accident unless things and in the right place)
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question30"
                  checked={question30}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question30"
                  checked={!question30}
                  value="false"
                ></input>
              </th>
            </tr>

            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Not accompanied by magical thinking{' '}
              </td>
              <th
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question31"
                  checked={question31}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question31"
                  checked={!question31}
                  value="false"
                ></input>
              </th>
            </tr>
          </tbody>
        </table>
      </Grid>
      <Grid container style={{ textAlign: 'center' }}>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
                scope="col"
                colSpan={2}
              >
                MISCELLANEOUS OBSESSIONS
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Current
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Past
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Need to know or remember
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question32"
                  checked={question32}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question32"
                  checked={!question32}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Fear of saying certain things
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question33"
                  checked={question33}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question33"
                  checked={!question33}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Fear of not saying just the right thing
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question34"
                  checked={question34}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question34"
                  checked={!question34}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Fear of losing things
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question35"
                  checked={question35}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question35"
                  checked={!question35}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Intrusive (non-violent) images
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question36"
                  checked={question36}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question36"
                  checked={!question36}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Intrusive nonsense sounds, words, or music
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question37"
                  checked={question37}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question37"
                  checked={!question37}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Intrusive nonsense sounds, words, or music
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question38"
                  checked={question38}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question38"
                  checked={!question38}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Lucky/unlucky numbers
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question39"
                  checked={question39}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question39"
                  checked={!question39}
                  value="false"
                ></input>
              </th>
            </tr>

            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Colors with special significance Superstitious fears{' '}
              </td>
              <th
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question40"
                  checked={question40}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question40"
                  checked={!question40}
                  value="false"
                ></input>
              </th>
            </tr>
          </tbody>
        </table>
      </Grid>
      <Grid container style={{ textAlign: 'center' }}>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
                scope="col"
                colSpan={2}
              >
                SOMATIC OBSESSIONS
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Current
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Past
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Concern with illness or disease *
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question41"
                  checked={question41}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question41"
                  checked={!question41}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Excessive concern with body part or aspect of appearance (e.g.
                dysmorphophobia) *{' '}
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question42"
                  checked={question42}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question42"
                  checked={!question42}
                  value="false"
                ></input>
              </th>
            </tr>

            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Other{' '}
              </td>
              <th
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question43"
                  checked={question43}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question43"
                  checked={!question43}
                  value="false"
                ></input>
              </th>
            </tr>
          </tbody>
        </table>
      </Grid>
      <Grid container style={{ textAlign: 'center' }}>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
                scope="col"
                colSpan={2}
              >
                CLEANING/WASHING COMPULSIONS Current Past
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Current
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Past
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Excessive or rituailzed handwashing
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question44"
                  checked={question44}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question44"
                  checked={!question44}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Excessive or ritualized showering, bathing, toothbrushing,
                grooming, or toilet routine. Involves cleaning of household
                items or other inanimate objects
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question45"
                  checked={question45}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question45"
                  checked={!question45}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Other measures to prevent or remove contact with contaminants
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question46"
                  checked={question46}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question46"
                  checked={!question46}
                  value="false"
                ></input>
              </th>
            </tr>

            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Other{' '}
              </td>
              <th
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question47"
                  checked={question47}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question47"
                  checked={!question47}
                  value="false"
                ></input>
              </th>
            </tr>
          </tbody>
        </table>
      </Grid>
      <Grid container style={{ textAlign: 'center' }}>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
                scope="col"
                colSpan={2}
              >
                CHECKING COMPULSIONS
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Current
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Past
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Checking locks, stove, appliances, etc.
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question48"
                  checked={question48}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question48"
                  checked={!question48}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Cheeking that did not/will not harm others
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question49"
                  checked={question49}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question49"
                  checked={!question49}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Checking that did not/will not harm self
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question50"
                  checked={question50}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question50"
                  checked={!question50}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Checking that nothing terrible did/will happen
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question51"
                  checked={question51}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question51"
                  checked={!question51}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Checking that did not make mistake
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question52"
                  checked={question52}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question52"
                  checked={!question52}
                  value="false"
                ></input>
              </th>
            </tr>{' '}
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Checking tied to somatic obsessions
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question53"
                  checked={question53}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question53"
                  checked={!question53}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Other{' '}
              </td>
              <th
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question54"
                  checked={question54}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question54"
                  checked={!question54}
                  value="false"
                ></input>
              </th>
            </tr>
          </tbody>
        </table>
      </Grid>
      <Grid container style={{ textAlign: 'center' }}>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
                scope="col"
                colSpan={2}
              >
                REPEATING RITUALS
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Current
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Past
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Re-reading or re-writing
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question55"
                  checked={question55}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question55"
                  checked={!question55}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Need to repeat routine activities(e.g. in/out door, up/down from
                chair)
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question56"
                  checked={question56}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question56"
                  checked={!question56}
                  value="false"
                ></input>
              </th>
            </tr>

            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Other{' '}
              </td>
              <th
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question57"
                  checked={question57}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question57"
                  checked={!question57}
                  value="false"
                ></input>
              </th>
            </tr>
          </tbody>
        </table>
      </Grid>
      <Grid container style={{ textAlign: 'center' }}>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
                scope="col"
                colSpan={2}
              >
                COUNTING COMPULSIONS
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Current
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Past
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              ></td>
              <th
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  // name="question1"
                  // checked={!question1}
                  value="false"
                ></input>
              </th>
              <th
                style={{
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  // name="question1"
                  // checked={question1}
                  value="true"
                ></input>
              </th>
            </tr>
          </tbody>
        </table>
      </Grid>
      <Grid container style={{ textAlign: 'center' }}>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
                scope="col"
                colSpan={2}
              >
                ORDERING/ARRANGING COMPULSIONS
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Current
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Past
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              ></td>
              <th
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  // name="question1"
                  // checked={!question1}
                  value="false"
                ></input>
              </th>
              <th
                style={{
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  // name="question1"
                  // checked={question1}
                  value="true"
                ></input>
              </th>
            </tr>
          </tbody>
        </table>
      </Grid>
      <Grid container style={{ textAlign: 'center' }}>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
                scope="col"
                colSpan={2}
              >
                HOARDING/COLLECTING COMPULSIONS
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Current
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Past
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                [distinguish from hobbies and concern with objects of monetary
                or sentimental value (e.g.,carefulig reads junkmail, piles up
                old newspapers, sorts through garbage, collects useless objects)
              </td>
              <th
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question58"
                  checked={question58}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question58"
                  checked={!question58}
                  value="false"
                ></input>
              </th>
            </tr>
          </tbody>
        </table>
      </Grid>
      <Grid container style={{ textAlign: 'center' }}>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
                scope="col"
                colSpan={2}
              >
                MISCELLANEOUS COMPULSIONS <br />
                Mental rituals (other than checking/counting)
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Current
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  width: '100px',
                }}
              >
                Past
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Excessive listmaking
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question59"
                  checked={question59}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question59"
                  checked={!question59}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Need to tell, ask, or confess
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question60"
                  checked={question60}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question60"
                  checked={!question60}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Need to touch, tap, or rub *
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question61"
                  checked={question61}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question61"
                  checked={!question61}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Rituals involving blinking or staring *
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question62"
                  checked={question62}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question62"
                  checked={!question62}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Measures (not checking) to prevent:
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question63"
                  checked={question63}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question63"
                  checked={!question63}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                harm to self harm to others terrible consequences
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question64"
                  checked={question64}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question64"
                  checked={!question64}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Ritualized eating behaviors *
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question65"
                  checked={question65}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question65"
                  checked={!question65}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Superstitious behaviors
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question66"
                  checked={question66}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question66"
                  checked={!question66}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Trichotillomania *
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question67"
                  checked={question67}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question67"
                  checked={!question67}
                  value="false"
                ></input>
              </th>
            </tr>{' '}
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Other self damaging or self-mutilating behaviors *
              </td>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question68"
                  checked={question68}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question68"
                  checked={!question68}
                  value="false"
                ></input>
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={2}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              >
                Other{' '}
              </td>
              <th
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question69"
                  checked={question69}
                  value="true"
                ></input>
              </th>
              <th
                style={{
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question69"
                  checked={!question69}
                  value="false"
                ></input>
              </th>
            </tr>
          </tbody>
        </table>
      </Grid>
      <Grid container>
        <Typography
          style={{ TextAlign: 'center', margin: '10px', fontWeight: 'bold' }}
        >
          TARGET SYMPTOM LIST
        </Typography>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  width: '100%',
                  backgroundColor: '#F3F3E9',
                }}
                colSpan={10}
              >
                Obsessions:
              </th>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  backgroundColor: '#F3F3E9',
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={10}
                style={{
                  borderRight: '1px solid black',
                  borderBottom: '1px solid black',
                  textAlign: 'left',
                }}
              ></td>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                1.
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={10}
                style={{
                  borderRight: '1px solid black',
                  borderBottom: '1px solid black',
                  textAlign: 'left',
                }}
              ></td>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                2.
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={10}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              ></td>
              <th
                colSpan={2}
                style={{
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                3.
              </th>
            </tr>
          </tbody>
        </table>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  width: '100%',
                  backgroundColor: '#F3F3E9',
                }}
                colSpan={10}
              >
                COMPULSIONS:
              </th>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  backgroundColor: '#F3F3E9',
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={10}
                style={{
                  borderRight: '1px solid black',
                  borderBottom: '1px solid black',
                  textAlign: 'left',
                }}
              ></td>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                1.
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={10}
                style={{
                  borderRight: '1px solid black',
                  borderBottom: '1px solid black',
                  textAlign: 'left',
                }}
              ></td>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                2.
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={10}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              ></td>
              <th
                colSpan={2}
                style={{
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                3.
              </th>
            </tr>
          </tbody>
        </table>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  width: '100%',
                  backgroundColor: '#F3F3E9',
                }}
                colSpan={10}
              >
                AVOIDANCE:
              </th>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  backgroundColor: '#F3F3E9',
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={10}
                style={{
                  borderRight: '1px solid black',
                  borderBottom: '1px solid black',
                  textAlign: 'left',
                }}
              ></td>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                1.
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={10}
                style={{
                  borderRight: '1px solid black',
                  borderBottom: '1px solid black',
                  textAlign: 'left',
                }}
              ></td>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                2.
              </th>
            </tr>
            <tr>
              <td
                scope="col"
                colSpan={10}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
              ></td>
              <th
                colSpan={2}
                style={{
                  textAlign: 'center',
                  backgroundColor: '#F3F3E9',
                }}
              >
                3.
              </th>
            </tr>
          </tbody>
        </table>
      </Grid>
      <Grid container>
        <Typography
          style={{ TextAlign: 'center', margin: '10px', fontWeight: 'bold' }}
        >
          YALE-BROWN OBSESSIVE COMPULSIVE SCALE (Y-BOCS)
        </Typography>
        <Typography>
          "I am now going to ask several questions about your obsessive
          thoughts." [Make specific reference to the patient's target
          obsessions.]
        </Typography>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  width: '100%',
                  backgroundColor: '#F3F3E9',
                  textAlign: 'left',
                }}
                colSpan={10}
              >
                1. TIME OCCUPED BY OBSESSIVE THOUGHTS <br />
                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                  0 = None. <br />
                  1 = Mild, less than 1 hr/day or occasional intrusion. <br />
                  2 = Moderate, 1 to 3 hrs/day or frequent intrusion. <br />
                  3 = Severe, greater than 3 and up to 8 hrs/day or very
                  frequent intrusion. <br />4 = Extreme, greater than 8 hrs/day
                  or near constant intrusion.{' '}
                </span>
              </th>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  backgroundColor: '#F3F3E9',
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={8}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                  width: '90%',
                }}
              >
                <b>Q:</b> How much of your time is occupied by obsessive
                thoughts? [When obsessions occur as brief, intermittent
                intrusions, it may be difficult to assess time occupied by them
                in temns of total hours. In such cases, estirnate time by
                detesmining how frequently they occur. Consider both the number
                of times the intrusions occur and how many hours of the day are
                affected. Ask:1 How frequently do the obsessive thoughts occur?
                [Be sure to exclude ruminations and preoccupations which, unlike
                obsessions, are ego-syntonic and rational (but exaggerated).]
              </td>
              <th
                colSpan={4}
                style={{
                  textAlign: 'left',
                  backgroundColor: '#F3F3E9',
                  padding: '10px',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question70"
                  checked={question70 == 0 ? true : false}
                  value='0'
                  marginRight="5px"
                ></input>{' '}
                0<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question70"
                  checked={question70 == 1 ? true : false}
                  value='1'
                  marginRight="5px"
                ></input>{' '}
                1<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question70"
                  checked={question70 == 2 ? true : false}
                  value='2'
                  marginRight="5px"
                ></input>{' '}
                2<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question70"
                  checked={question70 == 3 ? true : false}
                  value='3'
                  marginRight="5px"
                ></input>{' '}
                3<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question70"
                  checked={question70 == 4 ? true : false}
                  value='4'
                  marginRight="5px"
                ></input>{' '}
                4
              </th>
            </tr>
          </tbody>
        </table>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  width: '100%',
                  backgroundColor: '#F3F3E9',
                  textAlign: 'left',
                }}
                colSpan={10}
              >
                I b. OBSESSION-FREE INTERVAL (not included in total score){' '}
                <br />
                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                  0 = No symptoms. <br />
                  1 = Long symptom-free interval, more than 8 consecutive
                  hours/day symptom-free.
                  <br />
                  2 = Moderately long symptom-free interval, more than 3 and up
                  to 8 consecutive hours/day symptom-free. <br />
                  3 = Short symptom-free interval, from I to 3 consecutive
                  hours/day symptom-free.
                  <br />4 = Extremely short symptom-free interval, less than I
                  consecutive hour/day symptom-free
                </span>
              </th>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  backgroundColor: '#F3F3E9',
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={8}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                  width: '90%',
                }}
              >
                <b>Q:</b> On the average, what is the longest number of
                consecutive waking hours per day that you are completely free of
                obsessive thoughts? [If necessary, ask:1 What is the longest
                block of time in which obsessive thoughts are absent?
              </td>
              <th
                colSpan={4}
                style={{
                  textAlign: 'left',
                  backgroundColor: '#F3F3E9',
                  padding: '10px',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question71"
                  checked={question71 == 0 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                0<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question71"
                  checked={question71 == 1 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                1<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question71"
                  checked={question71 == 2 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                2<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question71"
                  checked={question71 == 3 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                3<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question71"
                  checked={question71 == 4 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                4
              </th>
            </tr>
          </tbody>
        </table>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  width: '100%',
                  backgroundColor: '#F3F3E9',
                  textAlign: 'left',
                }}
                colSpan={10}
              >
                2. INTERFERENCE DUE TO OBSESSIVE THOUGHTS
                <br />
                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                  0 = None.
                  <br />
                  1 = Mild, slight interference with social or occupational
                  activities, but overall performance not impaired. <br />
                  2 = Moderate, definite interference with social or
                  occupational performance, but still manageable.
                  <br />
                  3 = Severe, causes substantial impairment in social or
                  occupational performance. <br />4 - Extreme, incapacitating
                </span>
              </th>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  backgroundColor: '#F3F3E9',
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={8}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                  width: '90%',
                }}
              >
                <b>Q:</b> How much do your obsessive thoughts interfere with
                your social or work (or role) functioning? Is there anything
                that you don't do because of them? [If currently not working
                determine how much performance would be affected if patient were
                employed.]
              </td>
              <th
                colSpan={4}
                style={{
                  textAlign: 'left',
                  backgroundColor: '#F3F3E9',
                  padding: '10px',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question72"
                  checked={question72 == 0 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                0<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question72"
                  checked={question72 == 1 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                1<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question72"
                  checked={question72 == 2 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                2<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question72"
                  checked={question72 == 3 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                3<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question72"
                  checked={question72 == 4 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                4
              </th>
            </tr>
          </tbody>
        </table>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  width: '100%',
                  backgroundColor: '#F3F3E9',
                  textAlign: 'left',
                }}
                colSpan={10}
              >
                3. DISTRESS ASSOCIATED W1TH OBSESSIVE THOUGHTS
                <br />
                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                  0 = None <br />
                  I = Mild, not too disturbing
                  <br />
                  2 = 1doderate, disturbing, but still manageable
                  <br />
                  3 = Severe, very disturbing <br />
                  4 = Extreme, near constant and disabling distress <br />
                </span>
              </th>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  backgroundColor: '#F3F3E9',
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={8}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                  width: '90%',
                }}
              >
                <b>Q:</b> How much distress do your obsessive thoughts cause
                you? [In most eases, distress is equated with anxiety; however,
                patients may report that their obsessions are "disturbing" but
                deny "anxiety." Only rate anxiety that seems triggered by
                obsessions, not generalized anxiety or associated with other
                conditions.]
              </td>
              <th
                colSpan={4}
                style={{
                  textAlign: 'left',
                  backgroundColor: '#F3F3E9',
                  padding: '10px',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question73"
                  checked={question73 == 0 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                0<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question73"
                  checked={question73 == 1 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                1<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question73"
                  checked={question73 == 2 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                2<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question73"
                  checked={question73 == 3 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                3<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question73"
                  checked={question73 == 4 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                4
              </th>
            </tr>
          </tbody>
        </table>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  width: '100%',
                  backgroundColor: '#F3F3E9',
                  textAlign: 'left',
                }}
                colSpan={10}
              >
                4. RESISTANCE AGAINST OBSESSIONS
                <br />
                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                  0 = Makes an effort to always resist, or symptoms so minimal
                  doesn't need to actively resist <br />
                  1 = Tries to resist most of the time <br />
                  2 = Makes some effort to resist <br />
                  3 = Yields to all obsessions without attempting to control
                  them, but does so with some reluctance <br />4 = Completely
                  and willingly yields to all obsessions
                </span>
              </th>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  backgroundColor: '#F3F3E9',
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={8}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                  width: '90%',
                }}
              >
                <b>Q:</b> How much of an effort do you make to resist the
                obsessive thoughts? How often do you try to disregard or turn
                your anention away from these thoughts as they eater your mind?
                [Only rate effort made to resist, not success or failure in
                actually controlling the obsessions. How much the patient
                resists the obsessions may or may not correlate with his/her
                abilig to control them. Note that this item does not direetly
                measure the severig of the intrusive thoughts; rather it rates a
                manifestation of health, i.e., the effort the patient makes to
                counteract the obsessions by means other than avoidance or the
                performance of compulsions. Thus, the more the patient tries to
                resist, the less impaired is this aspect of his/her functioning.
                There are "active" and “passive" forms of resistance. Patients
                in behavioral therapy may be encouraged to counteract their
                obsessive symptoms by not struggling against them (e.g., "just
                let the thoughts come; passive opposition) or by intentionally
                bringing on the disturbing thoughts. For the purposes of this
                item, consider use of these behavioral techniques as forms of
                resistance. If the obsessions are minimal, the patieut may not
                feel the need to resist them. In such cases, a rating of "0"
                should be given.]
              </td>
              <th
                colSpan={4}
                style={{
                  textAlign: 'left',
                  backgroundColor: '#F3F3E9',
                  padding: '10px',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question74"
                  checked={question74 == 0 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                0<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question74"
                  checked={question74 == 1 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                1<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question74"
                  checked={question74 == 2 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                2<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question74"
                  checked={question74 == 3 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                3<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question74"
                  checked={question74 == 4 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                4
              </th>
            </tr>
          </tbody>
        </table>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  width: '100%',
                  backgroundColor: '#F3F3E9',
                  textAlign: 'left',
                }}
                colSpan={10}
              >
                5. DEGREE OP CONTROL OVER OBSESSIVE THOUGHTS
                <br />
                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                  0 = Complete control.
                  <br />
                  1 = Much control, usually able to stop or divert obsessions
                  with some effort and concentration. <br />
                  2 = Moderate control, sometimes able to stop or divert
                  obsessions. <br />
                  3 = Little control, rarely successful in stopping or
                  dismissing obsessions, can only divert attention with
                  difficulty.
                  <br />4 = No control, experienced as completely involuntary,
                  rarely able to even momentarily alter obsessive thinking.
                </span>
              </th>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  backgroundColor: '#F3F3E9',
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={8}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                  width: '90%',
                }}
              >
                <b>Q:</b> How much control do you have over your obsessive
                thoughts? How successful are you in stopping or diverting your
                obsessive thinking? Can you dismiss them? [In contrast to the
                preceding item on resistance, the ability of the patient to
                control his obsessions is more closely related to the severity
                of the intrusive thoughts.]
              </td>
              <th
                colSpan={4}
                style={{
                  textAlign: 'left',
                  backgroundColor: '#F3F3E9',
                  padding: '10px',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question75"
                  checked={question75 == 0 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                0<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question75"
                  checked={question75 == 1 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                1<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question75"
                  checked={question75 == 2 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                2<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question75"
                  checked={question75 == 3 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                3<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question75"
                  checked={question75 == 4 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                4
              </th>
            </tr>
          </tbody>
        </table>
        <Typography variant="h5" style={{ marginTop: '10px' }}>
          "The next several questions are about your compulsive behaviors."
          [Make specific reference to the patient's target compulsions.]{' '}
        </Typography>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  width: '100%',
                  backgroundColor: '#F3F3E9',
                  textAlign: 'left',
                }}
                colSpan={10}
              >
                6. TIME SPENT PERFORM~G COMPULSIVE BEHAVIORS
                <br />
                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                  0 = None <br />
                  1 = Mild (spends less than I hr/day performing compulsions),
                  or occasional performance of compulsive behaviors.
                  <br />
                  2 = Moderate (speeds from I to 3 hrs/day performing
                  compulsions), or frequent performance of compulsive behaviors.{' '}
                  <br />
                  3 = Severe (spends more than 3 and up to 8 hrs/day performing
                  compulsions), or very frequent performance of compulsive
                  behaviors. <br />4 = Extreme (spends more than 8 hrs/day
                  performing compulsions), or near constant performance of
                  compulsive behaviors (too numerous to count).
                </span>
              </th>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  backgroundColor: '#F3F3E9',
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={8}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                  width: '90%',
                }}
              >
                <b>Q:</b> How much time do you spend performing compulsive
                behaviors? [When rituals involving activities of daily living
                are chiefly present, ask:] How much longer than most people does
                it take to complete routine activities because of your rituals?
                [When compulsions occur as brief, intermittent behaviors, it may
                difficult to assess time spent performing them in terms of total
                hours. In such cases, estimate time by determining how
                frequently they are performed. Consider both the number of times
                compulsions are performed and how many hours of the day are
                affected. Count separate occurrences of compulsive behaviors,
                not number of repetitions; e.g., a patient who goes into the
                bathroom 20 different times a day to wash his hands 5 times very
                quickly, performs compulsions 20 times a day, not 5 or 5 x 20 =
                100. Ask:] How frequently do you perform compulsions? 1In most
                cases compulsions are observable behaviors(e.g., land washing),
                but some compulsions are covert (e.g., silent checking).]
              </td>
              <th
                colSpan={4}
                style={{
                  textAlign: 'left',
                  backgroundColor: '#F3F3E9',
                  padding: '10px',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question76"
                  checked={question76 == 0 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                0<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question76"
                  checked={question76 == 1 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                1<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question76"
                  checked={question76 == 2 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                2<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question76"
                  checked={question76 == 3 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                3<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question76"
                  checked={question76 == 4 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                4
              </th>
            </tr>
          </tbody>
        </table>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  width: '100%',
                  backgroundColor: '#F3F3E9',
                  textAlign: 'left',
                }}
                colSpan={10}
              >
                6b. COMPULSION-FREE INTERVAL(not included in total score)
                <br />
                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                  0 = No symptoms. <br />
                  1 = Long symptom-free interval, more than 8 consecutive
                  hours/day symptom-free. 2 = Moderately long symptom-free
                  interval, more than 3 and up to 8 consecutive hours/day
                  symptom-free. <br />
                  3 = Short symptom-free interval, from I to 3 consecutive
                  hours/day symptom-free.
                  <br />4 = Extremely short symptom-free interval, less than I
                  consecutive hour/day symptom-free.
                </span>
              </th>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  backgroundColor: '#F3F3E9',
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={8}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                  width: '90%',
                }}
              >
                <b>Q:</b> On the average, what is the longest number of
                consecutive waking hours per day that you are completely free of
                compulsive behavior? [If necessary, ask:] What is the longest
                block of time in which compulsions are absent?different times a
                day to wash his hands 5 times very quickly, performs compulsions
                20 times a day, not 5 or 5 x 20 = 100. Ask:] How frequently do
                you perform compulsions? 1In most cases compulsions are
                observable behaviors(e.g., land washing), but some compulsions
                are covert (e.g., silent checking).]
              </td>
              <th
                colSpan={4}
                style={{
                  textAlign: 'left',
                  backgroundColor: '#F3F3E9',
                  padding: '10px',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question77"
                  checked={question77 == 0 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                0<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question77"
                  checked={question77 == 1 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                1<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question77"
                  checked={question77 == 2 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                2<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question77"
                  checked={question77 == 3 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                3<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question77"
                  checked={question77 == 4 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                4
              </th>
            </tr>
          </tbody>
        </table>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  width: '100%',
                  backgroundColor: '#F3F3E9',
                  textAlign: 'left',
                }}
                colSpan={10}
              >
                7 INTERFERIINCE DUE TO COMPULSIVE BEHAVIQRS
                <br />
                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                  0 = None <br />
                  1 = Mild, slight interference with social or occupational
                  activities, but overall performance not impaired <br />
                  2 = Moderate, definite interference with social or
                  occupational performance, but still manageable
                  <br />
                  3 = Severe, causes substantial impaiment in social or
                  occupational performance <br />4 = Extreme, incapacitating
                </span>
              </th>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  backgroundColor: '#F3F3E9',
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={8}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                  width: '90%',
                }}
              >
                <b>Q:</b> How much do your compulsive behaviors interfere with
                your social or work (or role) functioning? Is there anything
                that you don't do because of the compulsions? [If currently not
                working determine how much performance would be affected if
                patient were employed.]
              </td>
              <th
                colSpan={4}
                style={{
                  textAlign: 'left',
                  backgroundColor: '#F3F3E9',
                  padding: '10px',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question78"
                  checked={question78 == 0 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                0<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question78"
                  checked={question78 == 1 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                1<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question78"
                  checked={question78 == 2 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                2<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question78"
                  checked={question78 == 3 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                3<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question78"
                  checked={question78 == 4 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                4
              </th>
            </tr>
          </tbody>
        </table>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  width: '100%',
                  backgroundColor: '#F3F3E9',
                  textAlign: 'left',
                }}
                colSpan={10}
              >
                8. DISTRESS ASSOCIATED WITH COMPULSIVE BEHAVIOR
                <br />
                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                  0 = None <br />
                  I = Mild only slightly anxious if compulsions prevented, or
                  only slight anxiety during performance of compulsions <br />
                  2 = Moderate, reports that anxioty would mount but remain
                  manageable if compulsions prevented, or that anxiety increases
                  but remains manageable during performance of compulsions{' '}
                  <br />
                  3 = Severe, prominent and very disturbing increase in anxiety
                  if compulsions interrupted, or prorninent and very disturbing
                  increase in anxiety during performance of compulsions <br />4
                  = Extreme, incapacitating anxiety from any intervention aimed
                  at modifying activity, or incapacitating anxiety develops
                  during performance of compulsions
                </span>
              </th>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  backgroundColor: '#F3F3E9',
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={8}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                  width: '90%',
                }}
              >
                <b>Q:</b> How would you feel if prevented from performing your
                compulsion(s)? [Pause] How anxious would you become? [Rate
                degree of distress patient would experience if performance of
                the compulsion were suddenly interrupted without reassurance
                offered. In most, but not all cases, performing compulsions
                reduces anxiety. If, in the judgement of the interviewer,
                anxiety is actually reduced by preventing compulsions in the
                manner described above, then asked: How anxious do you get while
                performing compulsions until you are satisfied they are
                completed?
              </td>
              <th
                colSpan={4}
                style={{
                  textAlign: 'left',
                  backgroundColor: '#F3F3E9',
                  padding: '10px',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question79"
                  checked={question79 == 0 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                0<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question79"
                  checked={question79 == 1 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                1<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question79"
                  checked={question79 == 2 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                2<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question79"
                  checked={question79 == 3 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                3<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question79"
                  checked={question79 == 4 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                4
              </th>
            </tr>
          </tbody>
        </table>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  width: '100%',
                  backgroundColor: '#F3F3E9',
                  textAlign: 'left',
                }}
                colSpan={10}
              >
                9. RESISTANCE AGAINST COMPULSIONS
                <br />
                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                  0 = Malces an effort to always resist, or symptoms so minimal
                  doesn't need to actively resist <br />
                  I = Tries to resist most of the time <br />
                  2 = Makes some effort to resist <br />
                  3 = Yields to almost all compulsions without attempting to
                  control them, but does so with some reluetance <br />4 =
                  Completely and willingly yields to all compulsions
                </span>
              </th>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  backgroundColor: '#F3F3E9',
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={8}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                  width: '90%',
                }}
              >
                <b>Q:</b> How much of an effort do you make to resist the
                compulsions? I Only rate effort made to resist, not success or
                failure in actually controlling the compulsions. How much the
                patient resists the compulsions may or may not correlate with
                his ability to control them. Note that this item does not
                directly measure the severity of the compulsions; rather it
                rates a manifestation of health, i.e., the effort the patient
                makes to counteract the compulsions. Thus, the more the patient
                tries to resist, the less impaired is this aspect of his
                functioning. If the compulsions are minimal, the patient may not
                feel the need to resist them. In such cases, a rating of "0"
                should be given.] ?
              </td>
              <th
                colSpan={4}
                style={{
                  textAlign: 'left',
                  backgroundColor: '#F3F3E9',
                  padding: '10px',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question80"
                  checked={question80 == 0 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                0<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question80"
                  checked={question80 == 1 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                1<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question80"
                  checked={question80 == 2 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                2<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question80"
                  checked={question80 == 3 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                3<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question80"
                  checked={question80 == 4 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                4
              </th>
            </tr>
          </tbody>
        </table>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  width: '100%',
                  backgroundColor: '#F3F3E9',
                  textAlign: 'left',
                }}
                colSpan={10}
              >
                10. DEGREE OF CONTROL OVER COMULSIVE BEHAVIOR
                <br />
                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                  I = Much control, experiences pressure to perform the behavior
                  but usually able to exercise voluntary control over it. <br />
                  2 = Moderate control, strong pressure to perform behavior, can
                  control it only with difficulty <br />
                  3 = Little control, very strong drive to perform behavior,
                  must be carried to completion, can only delay with difficulty{' '}
                  <br />4 = No control. drive to perform behavior expericoced as
                  completely involuntary and overpowering, rarely able to even
                  momentarily delay activity
                </span>
              </th>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  backgroundColor: '#F3F3E9',
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={8}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                  width: '90%',
                }}
              >
                <b>Q:</b> How strong is the drive to perform the compulsive
                behavior? [Pause] How much control do you have over the
                compulsions? [In contrast to the preceding item on resistance,
                the ability of the patient to control his compulsions is more
                closely related to the severity of the compulsions.]
              </td>
              <th
                colSpan={4}
                style={{
                  textAlign: 'left',
                  backgroundColor: '#F3F3E9',
                  padding: '10px',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question81"
                  checked={question81 == 0 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                0<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question81"
                  checked={question81 == 1 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                1<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question81"
                  checked={question81 == 2 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                2<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question81"
                  checked={question81 == 3 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                3<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question81"
                  checked={question81 == 4 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                4
              </th>
            </tr>
          </tbody>
        </table>
        <Typography style={{ margin: '10px' }}>
          "The remaining questions are about both obsessions and compulsions.
          Some ask about related problems."" These are investigational items not
          included in total Y-BOCS score but may be useful in assessing these
          symptoms.{' '}
        </Typography>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  width: '100%',
                  backgroundColor: '#F3F3E9',
                  textAlign: 'left',
                }}
                colSpan={10}
              >
                11. INSIGHT INTO OBSESSIONS AND COMPULSIONS
                <br />
                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                  0 = Excellent insight, fully rational
                  <br />
                  1 = Good insight. Readily acknowledges absurdity or
                  excessiveness of thoughts or behaviors but does not seem
                  completely convinced that there isn't something besides
                  anxiety to be concerned about (i.e., has lingering doubts).{' '}
                  <br />
                  2 = Fair insight. Reluctantly admits thoughts or behavior seem
                  unreasonable or excessive, but wavers. May have some
                  unrealistic fears, but no fixed convictions. <br />
                  3 = Poor insight. Maintains that thoughts or behaviors are not
                  unreasonable or excessive, but acknowledges validity of
                  contrary evidence (i.e., overvalued ideas present).
                  <br />4 = Lacks insight, delusional. Definitely convinced that
                  concerns and behavior are reasonable, unresponsive to contrary
                  evidence.
                </span>
              </th>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  backgroundColor: '#F3F3E9',
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={8}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                  width: '90%',
                }}
              >
                <b>Q:</b> Do you think your concerns or behaviors are
                reasonable? [Pause] What do you think would happen if you did
                not perform the compulsion(s)? Are you convinced something would
                really happen? 1Ratc patient's insight into the senselessness or
                excessiveness of his obsession(s) based on beliefs expressed at
                the time of the interview.]
              </td>
              <th
                colSpan={4}
                style={{
                  textAlign: 'left',
                  backgroundColor: '#F3F3E9',
                  padding: '10px',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question82"
                  checked={question82 == 0 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                0<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question82"
                  checked={question82 == 1 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                1<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question82"
                  checked={question82 == 2 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                2<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question82"
                  checked={question82 == 3 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                3<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question82"
                  checked={question82 == 4 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                4
              </th>
            </tr>
          </tbody>
        </table>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  width: '100%',
                  backgroundColor: '#F3F3E9',
                  textAlign: 'left',
                }}
                colSpan={10}
              >
                12. AVOIDANCE
                <br />
                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                  0 = No deliberate avoidance <br />
                  1 = Mild, minimal avoidance
                  <br />
                  2 = Moderate, some avoidance; clearly present <br />
                  3 = Severe, much avoidance; avoidance prominent <br />4 =
                  Extreme, very extensive avoidance; patient does almost
                  everything he/she can to avoid triggering symptoms
                </span>
              </th>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  backgroundColor: '#F3F3E9',
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={8}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                  width: '90%',
                }}
              >
                <b>Q:</b> Have you been avoiding doing anything, going any
                place, or being with anyone because of your obsessional thoughts
                or out of concern you will perform compulsions? [If yes, then
                ask:] Elow much do you avoid? [Rate degree to which patient
                deliberately tries to avoid things. Sometimes compulsions are
                designed to "avoid" contact with something that the patient
                fears. For example, clothes washing rituals would be designated
                as compulsions, not as avoidant behavior. If the patient stopped
                doing the laundry then this would constitute avoidance.]
              </td>
              <th
                colSpan={4}
                style={{
                  textAlign: 'left',
                  backgroundColor: '#F3F3E9',
                  padding: '10px',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question83"
                  checked={question83 == 0 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                0<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question83"
                  checked={question83 == 1 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                1<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question83"
                  checked={question83 == 2 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                2<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question83"
                  checked={question83 == 3 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                3<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question83"
                  checked={question83 == 4 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                4
              </th>
            </tr>
          </tbody>
        </table>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  width: '100%',
                  backgroundColor: '#F3F3E9',
                  textAlign: 'left',
                }}
                colSpan={10}
              >
                13. DEGEE OF INDECISIVENESS
                <br />
                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                  0 = None <br />
                  1 = Mild, some trouble making decisions about minor things{' '}
                  <br />
                  2 = Moderate, freely reports significant trouble making
                  decisions that others would not think twice about <br />
                  3 = Severe, continual weighing of pros and cons about
                  nonessentials. <br />4 = Extreme, unable to make any
                  decisions. Disabling.
                </span>
              </th>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  backgroundColor: '#F3F3E9',
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={8}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                  width: '90%',
                }}
              >
                <b>Q:</b>Do you have trouble making decisions about little
                things that other people might not think twice about (e.g.,
                which clothes to put on in the morning; which brand of cereal to
                buy)? [Exclude difficulty making decisions which reflect
                ruminative thinking. Ambivalence concerning rationally-based
                difficult choices should also be excluded.]
              </td>
              <th
                colSpan={4}
                style={{
                  textAlign: 'left',
                  backgroundColor: '#F3F3E9',
                  padding: '10px',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question84"
                  checked={question84 == 0 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                0<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question84"
                  checked={question84 == 1 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                1<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question84"
                  checked={question84 == 2 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                2<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question84"
                  checked={question84 == 3 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                3<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question84"
                  checked={question84 == 4 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                4
              </th>
            </tr>
          </tbody>
        </table>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  width: '100%',
                  backgroundColor: '#F3F3E9',
                  textAlign: 'left',
                }}
                colSpan={10}
              >
                14. OVERVALUED SENSE OF RESPONSIBILY
                <br />
                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                  0 = None<br></br> I = Mild, only mentioned on questioning,
                  slight sense of over-responsibility <br />
                  2 = Moderate, ideas stated spontaneously, clearly present;
                  patient experiences significant sense of over-responsibility
                  for events outside his/her reasonable control
                  <br />
                  3 = Severe, ideas prominent and pervasive; deeply concerned
                  he/she is responsible for events clearly outside his control.
                  Self-blaming farfetched and nearly irrational
                  <br />4 = Extreme, delusional sense of responsibility (e.g.,
                  if an earthquake occurs 3,000 miles away patient blames
                  herself because she didn't perform her compulsions)
                </span>
              </th>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  backgroundColor: '#F3F3E9',
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={8}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                  width: '90%',
                }}
              >
                <b>Q:</b> Do you feel very responsible for the consequences of
                your actions? Do you blame yourself for the outcome of events
                not completely in your control? [Distinguish from normal
                feelings of responsibility, feelings of worthlessness, and
                pathological guilt. A guilt-ridden person experiences himself or
                his actions as bad or evil.]
              </td>
              <th
                colSpan={4}
                style={{
                  textAlign: 'left',
                  backgroundColor: '#F3F3E9',
                  padding: '10px',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question85"
                  checked={question85 == 0 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                0<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question85"
                  checked={question85 == 1 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                1<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question85"
                  checked={question85 == 2 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                2<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question85"
                  checked={question85 == 3 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                3<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question85"
                  checked={question85 == 4 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                4
              </th>
            </tr>
          </tbody>
        </table>{' '}
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  width: '100%',
                  backgroundColor: '#F3F3E9',
                  textAlign: 'left',
                }}
                colSpan={10}
              >
                15. PERVASIVE SLOWNESS/ DISTURBANCE OF INERTIA
                <br />
                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                  0 = None.
                  <br />
                  I = Mild, occasional delay in starting or finishing.
                  <br />
                  2 = Moderate, frequent prolongation of routine activities but
                  tasks usually completed. Frequently late. <br />
                  3 = Severe, pervasive and marked difficulty initiating and
                  completing routine tasks. Usually late. <br />4 = Extreme,
                  unable to start or complete routine tasks without full
                  assistance.
                </span>
              </th>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  backgroundColor: '#F3F3E9',
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={8}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                  width: '90%',
                }}
              >
                <b>Q:</b> Do you have difficulty starting or finishing tasks? Do
                many routine activities take longer than they should?
                [Distinguish from psychomotor retardation secondary to
                depression. Rate increased time spent performing routine
                activities even when specific obsessions cannot be identified.]
              </td>
              <th
                colSpan={4}
                style={{
                  textAlign: 'left',
                  backgroundColor: '#F3F3E9',
                  padding: '10px',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question86"
                  checked={question86 == 0 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                0<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question86"
                  checked={question86 == 1 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                1<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question86"
                  checked={question86 == 2 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                2<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question86"
                  checked={question86 == 3 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                3<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question86"
                  checked={question86 == 4 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                4
              </th>
            </tr>
          </tbody>
        </table>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  width: '100%',
                  backgroundColor: '#F3F3E9',
                  textAlign: 'left',
                }}
                colSpan={10}
              >
                16. PATHOLOGICAL DOUBTING
                <br />
                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                  0 = None.
                  <br />
                  1 = Mild, only mentioned on questioning, slight pathological
                  doubt. Examples given may be within normal range. <br />
                  2 = Moderate, ideas stated spontaneously, clearly present and
                  apparent in some of patient's behaviors, patient bothered by
                  significant pathological doubt. Some effect on performance but
                  still manageable. <br />
                  3 = Severe, uncertainty about perceptions or ,memory
                  prominent; pathological doubt frequently affects performance.{' '}
                  <br />4 = Extreme uncertainty about perceptions constantly
                  present; pathological doubt substantially affects almost all
                  activities. Incapacitating (e.g., patient states "my mind
                  doesn't trust what my eyes see").
                </span>
              </th>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  backgroundColor: '#F3F3E9',
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={8}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                  width: '90%',
                }}
              >
                <b>Q:</b>After you complete an activity do you doubt whether you
                performed it correctly? Do you doubt whether you did it at all?
                When carrying out routine activities do you find that you don't
                trust your senses (i.e., what you see, hear, or touch)?
              </td>
              <th
                colSpan={4}
                style={{
                  textAlign: 'left',
                  backgroundColor: '#F3F3E9',
                  padding: '10px',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question87"
                  checked={question87 == 0 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                0<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question87"
                  checked={question87 == 1 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                1<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question87"
                  checked={question87 == 2 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                2<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question87"
                  checked={question87 == 3 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                3<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question87"
                  checked={question87 == 4 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                4
              </th>
            </tr>
          </tbody>
        </table>
        <Typography style={{ margin: '10px' }}>
          [Items 17 and 18 refer to global illness severity. The rater is
          required to consider global function, not just the severity of
          obssive-compulsive symptoms.]
        </Typography>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  width: '100%',
                  backgroundColor: '#F3F3E9',
                  textAlign: 'left',
                }}
                colSpan={10}
              >
                17. GLOBAL SEVERITY:
                <br />
                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                  0 = No illness <br />
                  1 = Illness slight, doubtful, transient; no functional
                  impairment <br />
                  2 = Mild symptoms, little functional impairment <br />
                  3 = Moderate symptoms, functions with effort <br />
                  4 = Moderate - Severe symptoms, limited functioning <br />
                  5 = Severe symptoms, functions mainly with assistance <br />6
                  = Extremely Severe symptoms, completely nonfunctional
                </span>
              </th>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  backgroundColor: '#F3F3E9',
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={8}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                  width: '90%',
                }}
              >
                Interviewer's judgement of the overall severity of the patient's
                illness. Rated from O (no illness) to 6-(most severe patient
                seen). [Consider the degree of distress reported by the patient,
                the symptoms observed, and the functional impairment reported.
                Your judgement is required both in averaging this data as well
                as weighing the reliability or accuracy of the data obtained.
                This judgement is based on information obtained during the
                interview.]
              </td>
              <th
                colSpan={4}
                style={{
                  textAlign: 'left',
                  backgroundColor: '#F3F3E9',
                  padding: '10px',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question88"
                  checked={question88 == 0 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                0<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question88"
                  checked={question88 == 1 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                1<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question88"
                  checked={question88 == 2 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                2<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question88"
                  checked={question88 == 3 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                3<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question88"
                  checked={question88 == 4 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                4<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question88"
                  checked={question88 == 5 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                5<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question88"
                  checked={question88 == 6 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                6<br />
              </th>
            </tr>
          </tbody>
        </table>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  width: '100%',
                  backgroundColor: '#F3F3E9',
                  textAlign: 'left',
                }}
                colSpan={10}
              >
                18. GLOBAL IMPROVEMENT:
                <br />
                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                  0 = Very much worse <br />
                  1 = Much worse <br />
                  2 = Minimal worse <br />
                  3 = No change <br />
                  4 = Minimally improved <br />
                  5 = Much improved <br />6 = Very much improved
                </span>
              </th>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  backgroundColor: '#F3F3E9',
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={8}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                  width: '90%',
                }}
              >
                Rate total overall improvement present SINCE THE INITIAL RATING
                whether or not, in your judgement, it is due to drug treatment.
              </td>
              <th
                colSpan={4}
                style={{
                  textAlign: 'left',
                  backgroundColor: '#F3F3E9',
                  padding: '10px',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question89"
                  checked={question89 == 0 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                0<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question89"
                  checked={question89 == 1 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                1<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question89"
                  checked={question89 == 2 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                2<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question89"
                  checked={question89 == 3 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                3<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question89"
                  checked={question89 == 4 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                4<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question89"
                  checked={question89 == 5 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                5<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question89"
                  checked={question89 == 6 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                6<br />
              </th>
            </tr>
          </tbody>
        </table>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{
            border: '2px solid black',
            marginLeft: '50px',
            marginTop: '30px',
            width: '90%',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  width: '100%',
                  backgroundColor: '#F3F3E9',
                  textAlign: 'left',
                }}
                colSpan={10}
              >
                19. RELIABILITY:
                <br />
                <span style={{ fontWeight: 'normal', fontSize: '12px' }}>
                  0 = Excellent, no reason to suspect data unreliable . .<br />
                  1 = Good, factor(s) present that may adversely affect
                  reliability <br />
                  2 = Fair, factorts) present that definitely reduce reliability{' '}
                  <br />3 = Poor, very low reliability
                </span>
              </th>
              <th
                colSpan={2}
                style={{
                  borderBottom: '1px solid black',
                  backgroundColor: '#F3F3E9',
                }}
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                scope="col"
                colSpan={8}
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'left',
                  width: '90%',
                }}
              >
                Rate the overall reliability of the rating scores obtained.
                Factors that may affect reliability include the patient's
                cooperativenes and his/her natural ability to communicate. The
                type and severity of obsessive-compulsive symptoms present may
                interfere with the patient's concentration, attention, or
                freedom to speak spontaneously (e.g., the content of some
                obsessions may cause the patient to choose his words very
                carefully).
              </td>
              <th
                colSpan={4}
                style={{
                  textAlign: 'left',
                  backgroundColor: '#F3F3E9',
                  padding: '10px',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question90"
                  checked={question90 == 0 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                0<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question90"
                  checked={question90 == 1 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                1<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question1"
                  checked={question90 == 2 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                2<br />
                <input
                  class="form-check-input"
                  type="radio"
                  name="question90"
                  checked={question90 == 3 ? true : false}
                  value="true"
                  marginRight="5px"
                ></input>{' '}
                3
              </th>
            </tr>
          </tbody>
        </table>
      </Grid>

      <Typography style={{ marginTop: '20px' }}>
        Items 17 and 18 arc adapted from the Clinical Global Impression Scale
        (Guy W: ECDEU Assessment Manual for Psychopharrnacology: Publication
        76-338. Washington, D.C., U.S. Department of Health, Education, and
        Welfare (1976)).
      </Typography>
      <Typography style={{ marginTop: '20px' }}>
        Additional infomnation regarding the development, use, and psychometric
        properties of the Y-BOCS can be found in Goodman WK, Price LH, Rasmussen
        SA, et al.: The Yale-Brown Obsessive Compulsive Scaie (YBOCS): Part I.
        Development, use, and reliability. Arch Gen Psvchiaty (46:1006~1011,
        1989). and Goodman WK, Price LH, Rasmussen SA, ct al.: The Yale-Brown
        Obsessive Compulsive Scale (YBOCS): Part II. Validity. Arch Gen
        Psvchiatry (46:1012-1016, 1989).
      </Typography>
      <Typography style={{ marginTop: '20px' }}>
        Copies of a version of the Y-BOCS modified for usc in children, the
        Children's Yale-Brown Obsessive Compulsive Scale (CY-BOCS) (Goodman WK,
        Rasmussen SA, Price LH, Mazure C, Rapoport JL, Heninger GR, Charney DS),
        is available from Dr. Goodman on request.{' '}
      </Typography>

      <table
        className="table table-bordered border-dark table-striped table-hover"
        style={{
          border: '2px solid black',
          marginLeft: '50px',
          marginTop: '30px',
          width: '90%',
        }}
      >
        <tbody>
          <tr>
            <th
              colSpan={6}
              style={{
                textAlign: 'left',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '40%',
              }}
            >
              Y-BOCS TOTAL(add items 1-10){' '}
              <input
                class="form-check-input"
                type="radio"
                // name="question1"
                // checked={question1}
                value="true"
                marginRight="5px"
              ></input>
            </th>
            <th
              style={{
                textAlign: 'left',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={4}
            >
              Date:{' '}
            </th>
            <th
              style={{ textAlign: 'left', backgroundColor: '#F3F3E9' }}
              scope="col"
              colSpan={4}
            >
              Rater:{' '}
            </th>
          </tr>
          <tr>
            <th
              colSpan={6}
              style={{
                textAlign: 'left',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '50%',
              }}
            >
              Patient Name
            </th>
            <th
              style={{
                textAlign: 'left',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={6}
            >
              Patient Id:{' '}
            </th>
          </tr>
        </tbody>
      </table>
      <table
        className="table table-bordered border-dark table-striped table-hover"
        style={{
          border: '2px solid black',
          marginLeft: '50px',
          marginTop: '30px',
          width: '90%',
        }}
      >
        {' '}
        <thead>
          <tr>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '16px',
                backgroundColor: '#F3F3E9',
                width: '60%',
              }}
              scope="col"
            >
              <b>Obsessions </b>
            </th>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              None
            </th>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              Mild
            </th>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              Moderate
            </th>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              Severe
            </th>
            <th
              style={{
                textAlign: 'center',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              Extreme
            </th>
          </tr>
          <tr>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '16px',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
            >
              <b> </b>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              0
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              1
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              2
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              3
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              4
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              1. TIME SPENT ON OBSESSIONS
            </td>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input class="form-check-input" type="radio" value="true"></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input class="form-check-input" type="radio" value="true"></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input class="form-check-input" type="radio" value="true"></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input class="form-check-input" type="radio" value="true"></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input class="form-check-input" type="radio" value="true"></input>
            </th>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              1b. Obsession-free interval (do not add to subtotal or total
              score){' '}
            </td>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
               //   name="question2"
//  checked={question2 == 0 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              2. INTERFERERENCE FROM OBSESSIONS
            </td>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              3. DISTRESS OF OBSESSIONS
            </td>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              4. RESISTANCE
            </td>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              5. CONTROL OVER OBSESSIONS
            </td>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
          </tr>
          <tr>
            <td style={{ borderRight: '1px solid black' }}>
              OBSESSION SUBTOTAL(add items 1-5)
            </td>
            <th
              style={{ textAlign: 'center', backgroundColor: '#F3F3E9' }}
              scope="col"
              colSpan={10}
            >
              "score"{' '}
            </th>
          </tr>
        </tbody>
      </table>
      <table
        className="table table-bordered border-dark table-striped table-hover"
        style={{
          border: '2px solid black',
          marginLeft: '50px',
          marginTop: '30px',
          width: '90%',
        }}
      >
        {' '}
        <thead>
          <tr>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '16px',
                backgroundColor: '#F3F3E9',
                width: '60%',
              }}
              scope="col"
            >
              <b>Compulsions </b>
            </th>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              None
            </th>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              Mild
            </th>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              Moderate
            </th>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              Severe
            </th>
            <th
              style={{
                textAlign: 'center',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              Extreme
            </th>
          </tr>
          <tr>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '16px',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
            >
              <b> </b>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              0
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              1
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              2
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              3
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              4
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              6. TIME SPENT ON COMPULSIONS
            </td>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
               //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
               //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
               //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              6b. TIME SPENT ON COMPULSIONS Compulsion-free interval (do not add
              to subtotal or total score){' '}
            </td>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
               //   name="question2"
//  checked={question2 == 0 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              7. INTERFERENCE FROM COMPULSION
            </td>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              8. DISTRESS FROM COMPULSIONS
            </td>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              9. RESISTANCE
            </td>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              10. CONTROL OVER COMPULSIONS
            </td>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
          </tr>
          <tr>
            <td style={{ borderRight: '1px solid black' }}>
              COMPULSION SUBTOTAL(add items 6-10)
            </td>
            <th
              style={{ textAlign: 'center', backgroundColor: '#F3F3E9' }}
              scope="col"
              colSpan={10}
            >
              "score"{' '}
            </th>
          </tr>
        </tbody>
      </table>
      <table
        className="table table-bordered border-dark table-striped table-hover"
        style={{
          border: '2px solid black',
          marginLeft: '50px',
          marginTop: '30px',
          width: '90%',
        }}
      >
        {' '}
        <thead>
          <tr>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '16px',
                backgroundColor: '#F3F3E9',
                width: '60%',
              }}
              scope="col"
            >
              <b> </b>
            </th>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              None
            </th>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              Mild
            </th>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              Moderate
            </th>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              Severe
            </th>
            <th
              style={{
                textAlign: 'center',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              Extreme
            </th>
          </tr>
          <tr>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '16px',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
            >
              <b> </b>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              0
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              1
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              2
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              3
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              4
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              11. INSIGHT INTO O-C SYMPTOMS
            </td>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
               //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
               //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
               //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              12. AVOIDANCE
            </td>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
               //   name="question2"
//  checked={question2 == 0 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              13. INDECISIVENESS
            </td>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              14. PATHOLOGIC RESPONSIBILITY
            </td>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                // name="question4"
                // checked={question4 == 2 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              15. SLOWNESS
            </td>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              16. PATHOLOGIC DOUBTING
            </td>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
          </tr>
          <tr>
            <td style={{ borderRight: '1px solid black' }}>
              17. GLOBAL SEVERITY
            </td>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{ textAlign: 'center', backgroundColor: '#F3F3E9' }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
          </tr>
        </tbody>
      </table>
      <table
        className="table table-bordered border-dark table-striped table-hover"
        style={{
          border: '2px solid black',
          marginLeft: '50px',
          marginTop: '30px',
          width: '90%',
        }}
      >
        {' '}
        <thead>
          <tr>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '16px',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
            >
              <b> </b>
            </th>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              0
            </th>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              1
            </th>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              2
            </th>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              3
            </th>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              4
            </th>
            <th
              style={{
                textAlign: 'center',
                borderRight: '1px solid black',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              5
            </th>

            <th
              style={{
                textAlign: 'center',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: '#F3F3E9',
                width: '70px',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              6
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
                width: '60%',
              }}
            >
              17. GLOBAL SEVERITY
            </td>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
               //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
               //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
               //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
               //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
               //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
                width: '60%',
              }}
            >
              18. GLOBAL IMPROVEMENT
            </td>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
               //   name="question2"
//  checked={question2 == 0 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
               //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                borderRight: '1px solid black',
                backgroundColor: '#F3F3E9',
                width: '70px',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
               //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F3F3E9',
              }}
              scope="col"
              colSpan={2}
            >
              <input
                class="form-check-input"
                type="radio"
                //   name="question2"
//  checked={question2 == 1 ? true : false}
                value="true"
              ></input>
            </th>
          </tr>
          <tr>
            <td style={{ borderRight: '1px solid black' }}>
              19. RELIABILITY:{' '}
            </td>
            <th
              style={{ textAlign: 'center', backgroundColor: '#F3F3E9' }}
              scope="col"
              colSpan={14}
            >
              Excellent=0 Good=1 Fair=2 Poor=3{' '}
            </th>
          </tr>
        </tbody>
      </table>
    </Grid>
  )
}
