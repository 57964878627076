import { yupResolver } from '@hookform/resolvers/yup'

import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import DraftsIcon from '@mui/icons-material/Drafts'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InboxIcon from '@mui/icons-material/Inbox'
import SendIcon from '@mui/icons-material/Send'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { CircularProgress, Grid, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Tab from '@mui/material/Tab'
import axios from 'axios'
import SuiSelect from 'components/SuiSelect'
import SuiButton from 'components/SuiButton'
import SuiTypography from 'components/SuiTypography'
import { Dialog, DialogActions, DialogContent, DialogTitle,  } from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { Backdrop } from '@mui/material';
import SuiBox from 'components/SuiBox'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined'
import SuiInput from 'components/SuiInput'
import { confirmAlert } from 'react-confirm-alert'
import { createBrowserHistory } from 'history'
import React, { useState } from 'react'
import { useAlert } from 'react-alert'
import { Controller, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import { useEffect } from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Moment from 'moment'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import moment from 'moment'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { getAllUsers } from 'redux/Actions/allUsersActions'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import Modal from '@mui/material/Modal'
import { makeStyles } from '@material-ui/core/styles'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import RefreshIcon from '@mui/icons-material/Refresh'
import CachedIcon from '@mui/icons-material/Cached'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import Draggable from 'react-draggable'
import FaxDemographics from './FaxDemographics '

const history = createBrowserHistory({ forceRefresh: true })

const options = ['Delete', 'Reply']
var usersRedux = []
const ITEM_HEIGHT = 48
const useStyles = makeStyles((theme) => ({
  styling: {
    width: '100',
    // height: '100%',
    height: 'fit-content',
    backgroundColor: 'white',
    position: 'absolute',
    display: 'flex',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    // overflow:'auto',
    padding: '10px',
    margin: 'auto',
  },
  fab: {
    position: 'fixed',
    right: 20,
    bottom: 20,
  },
  container: {
    width: 900,
    // height: '100%',
    height: 'fit-content',
    backgroundColor: 'white',
    position: 'absolute',
    display: 'flex',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    // overflow:'auto',
    padding: '10px',
    margin: 'auto',
    // [theme.breakpoints.down('sm')]: {
    //   width: '100vw',
    //   // height: '100vh',
    // },
  },
  form: {
    padding: theme.spacing(1),
  },
  item: {
    marginBottom: theme.spacing(2),
  },
  text: {
    display: 'inline',
    width: '100%',
  },

  readhide: {
    color: 'blue',
    cursor: 'pointer',
  },
}))

const fields = [
  'id',
  'practiceId',
  'parentId',
  'sendBy',
  'sendDate',
  'inbox',
  'sentMessage',
  'isDraft',
  'subject',
  'bodyMessage',
  'isRead',
  'readDate',
  'isActive',
  'createdBy',
  'createdDate',
  'updatedBy',
  'updatedDate',
  'clientCreatedDate',
  'clientUpdatedDate',
  'messagesTos',
]
const defValues = {
  id: 0,
  practiceId: '',
  parentId: '',
  sendBy: '',
  sendDate: '',
  inbox: '',
  sentMessage: '',
  isDraft: 'false',
  subject: '',
  bodyMessage: '',
  isRead: 'false',
  readDate: '',
  isActive: '',
  createdBy: '',
  createdDate: '',
  updatedBy: '',
  updatedDate: '',
  clientCreatedDate: '',
  clientUpdatedDate: '',
  messagesTos: [],
}
const styleDetails = {
  width: 1000,
  height: 'fit-content',
  bgcolor: '#F0FFFF',
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 30,
  right: 0,
  margin: 'auto',
  marginTop: '10px',
  paddingBottom: '10px',
  padding: '20px',
  // overflow: 'auto',
}

function Fax(props) {
  // const [showFaxAppointmentCreate, setShowFaxAppointmentCreate] = useState(
  //   false
  // );
  const [showFaxDemographics, setShowFaxDemographics] = useState(false)
  // const boxMargin = showFaxAppointmentCreate ? 0 : "auto";
  const boxMargin = showFaxDemographics ? 0 : 'auto'
  const styleDetailsPdf = {
    width: 900,
    height: '100%',
    bgcolor: '#F0FFFF',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 30,
    right: 0,
    margin: boxMargin,
    marginTop: '0',
    paddingBottom: '10px',
    padding: '0px',
    overflow: 'auto',
    zIndex: '10',
    // tabIndex: "-1",
  }

  const location = useLocation()
  const alert = useAlert()
  const dispatch = useDispatch()
  // var messagesToss=[]

  useEffect(() => {
    dispatch(getAllUsers())
  }, [dispatch])

  const [isReadMore, setIsReadMore] = useState(true)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [faxFrom, setFaxFrom] = useState('')
  const [faxTo, setFaxTo] = useState('')
  const [receivedDate, setReceivedDate] = useState('')
  const [sendDate, setSendDate] = useState('')
  const [receivedId, setreceiveId] = useState(0)
  const [completed, setcompleted] = useState('')
  const [fileError, setFileError] = useState('')
  const [docName, setDocName] = useState('')
  const ReadMore = ({ children }) => {
    const text = children
    return (


     

      <p className={classes.text}>
        {isReadMore ? text.slice(0, 50) : text}
        {text.length > 50
          ? '.....'
          : // <span
            //   onClick={() => {
            //     setIsReadMore(!isReadMore)
            //   }}
            //   className={classes.readhide}
            // >
            //   {isReadMore ? '...read more' : '    show less'}
            // </span>
            null}
      </p>
    )
  }
  const fields = [
    'id',
    'ringcenteralId',
    'availability',
    'creationTime',
    'direction',
    'faxFrom',
    'faxTo',
    'subject',
    'faxPageCount',
    'messageStatus',
    'readStatus',
    'base64',
    'faxAttachments',
  ]
  const defValues = {
    id: 0,
    ringcenteralId: '',
    availability: '',
    creationTime: '',
    direction: '',
    faxFrom: '',
    faxTo: '',
    subject: '',
    faxPageCount: '',
    messageStatus: '',
    readStatus: '',
    base64: '',
    faxAttachments: '',
  }
  const onImageChange = (e) => {
    const file = e.target.files[0]
    setValue('documentName', file.name)
    // console.log('ff:', file.name)
    setDocName(file.name)
    convertBase64(file)
    // console.log("64 image",base64)
    // console.log('Kdr Sy aya ha Console', URL.createObjectURL(e.target.files[0]))
  }
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    }).then((users) => {
      setValue('base64', users)
    })
  }
  const [submitting, setSubmitting] = useState(false)
  // const [dataReceived, setdataReceived] = useState(false)
  const [openn, setOpenn] = useState(false)
  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }
  const [maill, setMaill] = useState(
    `${localStorage.getItem('dtt_user_email')}`
  )
  const classes = useStyles()
  const [rows, setRows] = useState('')
  const [doc, setDoc] = useState()
  const [sentMessages, setSentMessages] = useState('')
  const [refresh, setRefresh] = useState(false)
  const [isDraft, setIsDraft] = useState(false)
  const [openNewMail, setOpenNewMail] = React.useState(false)
  const [openPdf, setOpenPdf] = React.useState(false)
  const [showbutton, setshowbutton] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDeleteById = async (item) => {
    try {
      const postData = {
        id: item.id,
        isActive: false,
      };
      await axios.post(`${process.env.REACT_APP_API_URL}/Faxes/deleteFax`, postData, { headers });
      setRefresh(!refresh);
      setOpenn(false); // Close the dialog after deletion
      console.log("Record deleted successfully!");
    } catch (error) {
      console.error('There was an error!', error);
    }
  };
  // const handleshow = () => {
  //   setshowbutton(true);
  // }

  const validationSchema = Yup.object({
    faxTo: Yup.string().required('Please Enter Fax Number.'),
    subject: Yup.string().required('Please Enter Subject.'),
  })
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  })
  const setDocument = (ar) => {
    var url = ar //.replaceAll('Pdf', 'pdf')
    console.log('URL: __________________', url)
    setDoc(url)
  }
  const onSubmit = (data) => {
    setFileError('')
    if (docName.length > 0) {
      setSubmitting(true)
      const postData = data
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/Faxes/SendFaxDocuument`,
          postData,
          {
            headers,
          }
        )
        .then((response) => {
          setOpenNewMail(false)
          alert.success('Fax Sent successfully.', {
            type: 'success',
            onClose: () => {
              settabValue(1)
              setRefresh(!refresh)
              clearAll()
              setSubmitting(false)
            },
          })
        })
        .catch((error) => {
          setSubmitting(false)
          alert.success(`Error ${error.message}`, {
            type: 'error',
          })
        })
    } else {
      setFileError(true)
    }
  }
  function handleCancel() {
    history.push('/PatientSearch')
  }
  const handleCloseMail = (reason) => {
    if (reason === 'clickaway') {
      return
    }
    clearAll()
  }
  const [id, setId] = useState('')
  const [inbox, setInbox] = useState('')
  const [refreshAssessment, setrefreshAssessment] = useState(false)
  const [inboxIndex, setInboxIndex] = useState('')
  var date = moment(inbox.sendDate).format('L')
  date = Moment(date).format('MM-DD-YYYY')
  var time = Moment(inbox.sendDate).format('hh:mm:A')
  function handleDate(e) {
    var Mdate = moment(e).format('L')
    Mdate = Moment(Mdate).format('MM-DD-YYYY')
    // console.log('date',Mdate)
    var Mtime = Moment(e).format('hh:mm:A')
    var TTime = `${Mdate}  ${Mtime}`
    return TTime
  }
  const [anchorEl, setAnchorEl] = useState(false)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (e) => {
    setAnchorEl(null)
    // console.log('eventt:',e)
  }
  const onFilterClear = (event) => {
    setFaxFrom('')
    setFaxTo('')
    setReceivedDate('')
    setSendDate('')
    setrefreshAssessment(!refreshAssessment)
  }

  const onFilterSearch = (event) => {
    setrefreshAssessment(!refreshAssessment)
    //         if (`${localStorage.getItem('dtt_userType')}` != 'Admin')
    // setReadOnly(true)
  }
  useEffect(() => {
    // setrefreshAssessment(!refreshAssessment)
    getdata()
  }, [refreshAssessment, refresh])
  // useEffect(() => {
  //   getdata()
  //   // empty dependency array means this effect will only run once (like componentDidMount in classes)
  // }, [])
  


  const MarkCompleted = () =>  {
    setSubmitting(true)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Faxes/MarkFaxes?id=${receivedId}&markcompleted=true`,
        {
          headers,
        }
      )
      .then((response) => {

        alert.success('Mark Completed successfully.', {
          type: 'success',
         
          onClose: () => {
            setRefresh(!refresh)
            clearAll()
            setSubmitting(false)
          },
        })
        
        
        
        // console.log(response.data)
        // console.log('Button Clicked')

        // setshowbutton(true);


      })
      .catch((error) => {
        console.error(error)
      })
  }

  

  const getdata = () => {
    setSubmitting(true)
    setRows(null)
    setOpenn(false)
    const postData = {
      faxFrom: faxFrom,
      receivedDate: receivedDate,
    }
    axios
      .post(`${process.env.REACT_APP_API_URL}/Faxes/ReceivedFax`, postData, {
        headers,
      })
      .then((response) => {
        setRows(response.data)
        setSubmitting(false)

        console.log("Dataaaaaaaaaaaaaaaaa" ,response.data )
        setSubmitting(false)
      })
      .catch((error) => {
        setSubmitting(false)
        console.error('There was an error!', error)
      })
  }

  useEffect(() => {
    setOpenn(false);
    setSubmitting(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/Faxes/SentFaxes`, { headers });
        if (response.data && response.data.length > 0) {
          setSentMessages(response.data);
          setSubmitting(false);
          if (tabValue === 2) {
            setOpenn(true);
          }
        } else {
          setSentMessages([]); // Set to empty array when there is no data
          setSubmitting(false);
        }
      } catch (error) {
        console.error('There was an error!', error);
      }
    };
    fetchData();
  }, [refresh]);
  //   const usersRedx = useSelector((state) =>
  //   (state.allusers.loading ? [] : state.allusers.NAMES), shallowEqual
  // )
  const usersRedx = useSelector(
    (state) => (state.allUsers.loading ? [] : state.allUsers.NAMES),
    shallowEqual
  )
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    usersRedux = []
    usersRedx.forEach((field) => {
      usersRedux.push({
        label: `${field.fullName}<${field.email}>`,
        value: field.email,
      })
    })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [{ usersRedx }])

  const clearAll = () => {
    //  setValue('sendBy', '')
    setValue('subject', '')
    // setValue('bodyMessage', '')
    setValue('faxTo', '')
    setDocName('')
    setDoc('')
    settabValue(1)
    setOpenNewMail(false)
    setRefresh(!refresh)
    setOpenn(false)
  }
  const handleDraft = () => {
    setIsDraft(true)
    handleSubmit(onSubmit)
    clearAll()
  }
  const [tabValue, settabValue] = React.useState(1)
  const [email, setEmail] = React.useState('')
  const [messagesToss, setMessagesToss] = React.useState([])

  const handleChangeTab = (event, newValue) => {
    settabValue(newValue)
    setSelectedIndex('')
    setOpenn(false)
    // if(newValue==1){
    //   if(rows.length>0)
    //   setOpenn(true)
    // setId(rows[0].id)
    // }
    // if(newValue==2){
    //   if(sentMessages.length>0)
    //   setOpenn(true)
    //   setId(sentMessages[0].id)
    // }
  }

  const [startTime, setStartTime] = useState('')
  const [assignId, setAssignId] = useState('')
  const [endTime, setEndTime] = useState('')

  const handleOpenn = () => {
    // console.log('Button clicked');
    // setShowFaxAppointmentCreate(true);
    setShowFaxDemographics(true)
  }
  const handleClosee = () => {
    // console.log("Button clicked");
    // setShowFaxAppointmentCreate(false);
    setShowFaxDemographics(false)
  }
  const [openDialog, setOpenDialog] = useState(false);
  const [items, setItems] = useState(null);

  const handleDelete = (item) => {
    const postData = {
      id: item.id,
      isActive: false,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/Faxes/deleteFax`, postData, {
        headers,
      })
      .then((response) => {
        setRefresh(!refresh);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      })
      .finally(() => {
        setOpenDialog(false);
      });
  };
  //Appointment Creat Function
  const AppointmentCreat = (event) => {
    console.log('events', event)
    setAssignId(parseInt(event.event.id))
    setStartTime(event.event.start)
    setEndTime(event.event.end)
    handleOpenn(true)
  }
  const position = { x: 100, y: 100 }

  return (
    <>
      {submitting ? (
        <CircularProgress
          style={{
            width: '50px',
            height: '50px',
            position: 'absolute',
            left: '50%',
            top: '5%',
            marginLeft: '-25px',
            marginTop: '-25px',
            zIndex: 1000,
            color: '#FCBE13',
          }}
        />
      ) : null}
      <Modal
        open={openNewMail}
        onClose={handleCloseMail}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleDetails}>
          <form className={classes.form} autoComplete="off">
            <CancelOutlinedIcon
              onClick={() => {
                clearAll()
                // setMaill(`${localStorage.getItem('dtt_user_email')}`)
              }}
              variant="contained"
              style={{
                float: 'right',
                color: 'red',
              }}
            ></CancelOutlinedIcon>
            <h3 style={{ backgroundColor: 'Primary' }}>New Fax</h3>
            <Grid
              container
              spacing={1}
              alignContent="center"
              justifyContent="center"
            >
              <Grid item xs={1}>
                <Typography>To:</Typography>
              </Grid>
              <Grid item xs={4}>
                <SuiInput
                  type="input"
                  placeholder="Please Enter Fax To"
                  {...register('faxTo')}
                  fullWidth
                  float="left"
                  margin="dense"
                  error={errors.faxTo}
                />
                <p style={{ color: 'red', fontSize: '13px' }}>
                  {errors.faxTo?.message}
                </p>
              </Grid>
              <Grid item xs={7}></Grid>
              <Grid item xs={1}>
                <Typography>Subject:</Typography>
              </Grid>
              <Grid item xs={11}>
                <SuiInput
                  type="input"
                  placeholder="Subject"
                  {...register('subject')}
                  fullWidth
                  margin="dense"
                  error={errors.subject}
                />
                <p style={{ color: 'red', fontSize: '13px' }}>
                  {errors.subject?.message}
                </p>
              </Grid>

              <Grid xs={12} style={{ textAlign: 'center' }}>
                <SuiButton
                  onClick={() => {
                    document.getElementById('choosefile').click()
                    setFileError('')
                  }}
                  variant="gradient"
                  disabled={submitting}
                  style={{
                    marginTop: '30px',
                    // float: 'center',
                    // marginRight: '13px',
                    width: '300px',
                    height: '35px',
                    marginLeft: '5px',
                    // backgroundColor: '#FCBE13',
                    color: 'black',
                    fontSize: '14px',
                  }}
                  // color="warning"
                  // size="small"

                  // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                >
                  <FileUploadIcon
                    fontSize="medium"
                    style={{
                      color: '#1a82ad',
                      paddingRight: '5px',
                      paddingBottom: '2px',
                    }}
                  ></FileUploadIcon>
                  Upload Doc{' '}
                  <SuiTypography style={{ fontSize: '10px' }}>
                    &nbsp;&nbsp;{docName}
                  </SuiTypography>
                </SuiButton>

                <label htmlFor="icon-button-file">
                  <input
                    id="choosefile"
                    style={{ display: 'none' }}
                    type="file"
                    name="upload"
                    accept="application/pdf"
                    onChange={onImageChange}
                  />
                </label>
                {fileError ? (
                  <p style={{ fontSize: '13px', color: 'red' }}>
                    {'Please Upload File'}
                  </p>
                ) : (
                  ''
                )}
              </Grid>

              <Grid item xs={12}>
                <SuiButton
                  onClick={handleSubmit(onSubmit)}
                  variant="gradient"
                  disabled={submitting} 
                  style={{
                    marginTop: '10px',
                    marginBottom: '3px',
                    float: 'right',
                    marginRight: '5px',
                    marginLeft: 'auto',
                    // width: '80px',
                    height: '35px',
                    backgroundColor: '#FCBE13',
                    color: 'black',
                    fontSize: '14px',
                  }}
                >
                  <SendIcon fontSize="medium" />
                  Send
                </SuiButton>

                <SuiButton
                  onClick={clearAll}
                  variant="gradient"
                  disabled={submitting}
                  style={{
                    marginTop: '10px',
                    marginBottom: '3px',
                    float: 'right',
                    marginRight: '5px',
                    marginLeft: 'auto',
                    // width: '80px',
                    height: '35px',
                    backgroundColor: '#FCBE13',
                    color: 'black',
                    fontSize: '14px',
                  }}
                >
                  <DeleteIcon fontSize="medium" />
                  Discard
                </SuiButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>

      <Grid container>
        <Modal
          open={openn}
          closeOnEscape={false}
          closeOnClickOutside={false}
          onClose={() => {
            setDocName('')
            setDoc('')
            setOpenn(false)
            setFaxFrom('')
            setFaxTo('')
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box>
            <Grid xs={8}>
            <Draggable bounds={{ left: -400, top: 0, right: 400, bottom: 500 }}>
              <Box sx={styleDetailsPdf}>
                <form className={classes.form} autoComplete="off">
                  {faxFrom.length > 0 ? (
                    <>
                      <span>From:</span>{' '}
                      <span style={{ fontSize: '12px' }}>{faxFrom}</span>
                    {completed == true ? (
                      <span style={{ fontSize: '18px', color:'red',marginLeft: '40px', }}>This document is already completed</span>
                      ) : ("")}

                    {completed == true ?  ("") : (
                      <>
                      <Button
                        onClick={MarkCompleted}
                        variant="gradient"
                        disabled={submitting}
                        style={{
                          marginTop: '1px',
                          marginBottom: '15px',
                          marginLeft: '372px',
                          // marginLeft: '260px',
                          width: '180px',
                          padding: '1px',
                          // float:  "left" : "right",
                          height: '35px',
                          backgroundColor: '#FCBE13',
                          color: 'black',
                          fontSize: '14px',
                        }}
                      >
                        MARK AS COMPLETED
                      </Button>
                   
                      <Button
                        onClick={
                          showFaxDemographics ? handleClosee : handleOpenn
                        }
                        variant="gradient"
                        // disabled={submitting}
                        style={{
                          marginTop: '1px',
                          marginBottom: '15px',
                          marginLeft: '5px',
                          width: '150px',
                          padding: '1px',
                          // float:  "left" : "right",
                          height: '35px',
                          backgroundColor: '#FCBE13',
                          color: 'black',
                          fontSize: '14px',
                        }}
                      >
                        Create Patient
                      </Button>
                      </>
                    )} 
                    
                    </>
                  ) : (
                    <>
                      <span>To: </span>
                      <span style={{ fontSize: '12px' }}>{faxTo}</span>
                    </>
                  )}
                  <CancelOutlinedIcon
                    onClick={() => {
                      setOpenn(false)
                      setDoc('')
                      setFaxFrom('')
                      setFaxTo('')
                      // setMaill(`${localStorage.getItem('dtt_user_email')}`)
                    }}
                    variant="contained"
                    style={{
                      float: 'right',
                      color: 'red',
                      marginTop: '7px',
                      marginLeft: '5px',
                    }}
                  ></CancelOutlinedIcon>

                  {openn ? (
                    <Grid
                      container
                      columnSpacing={1}
                      alignContent="center"
                      justifyContent="center"
                    >
                      <div
                        id="iframe_container"
                        style={{
                          position: 'absolute',
                          top: '6%',
                          // top: '15%',
                          bottom: '0%',
                          left: '0%',
                          right: '0%',
                          marginTop: '10px',
                          // marginBottom:"10px",
                        }}
                      >
                        <iframe
                          name="ifr"
                          scrolling="yes"
                          fullWidth
                          style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                          }}
                          src={`${doc}`}
                          frameborder="0"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </Grid>
                  ) : null}
                </form>
              </Box>
            </Draggable>
            </Grid>
         
            <Grid xs={4} sm={4} xl ={4}>
              {showFaxDemographics && (
                <FaxDemographics
                  onClose={handleClosee}
                  receivedId={receivedId}
                />
              )}
            </Grid>
          </Box>
        </Modal>
        
      </Grid>

      <Grid
        spacing={1}
        alignContent="left"
        justifyContent="left"
        style={{
          width: '100% !important',
          marginLeft: '0px !important',
        }}
      >
        <div>
          <SuiButton
            onClick={() => setOpenNewMail(true)}
            variant="gradient"
            disabled={submitting}
            style={{
              // marginTop: '10px',
              marginBottom: '10px',
              // float: 'left',
              // marginRight: '5px',
              marginLeft: 'auto',
              // width: '80px',
              height: '35px',
              backgroundColor: '#FCBE13',
              color: 'black',
              fontSize: '14px',
            }}
          >
            <AddIcon fontSize="medium" />
            New Fax
          </SuiButton>
          <SuiButton
            sx={{ mb: 2, marginRight: '20px' }}
            onClick={() => {
              setRefresh(!refresh)
              setSubmitting(true)
            }}
            style={{
              marginBottom: '10px',
              float: 'right',
              marginLeft: '20px',
              width: '120px',
              height: '35px',
              backgroundColor: '#FCBE13',
              color: 'black',
              fontSize: '14px',
            }}
          >
            <CachedOutlinedIcon
              fontSize="medium"
              style={{
                color: '#1a82ad',
                marginRight: '1px',
                paddingRight: '0px',
                paddingBottom: '2px',
              }}
            ></CachedOutlinedIcon>
            Refresh
          </SuiButton>

          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '30%' }}>
              <TabList
                onChange={handleChangeTab}
                aria-label="lab API tabs example"
              >
                <Tab
                  value={1}
                  icon={<InboxIcon fontSize="medium" />}
                  label="Received"
                />
                <Tab
                  value={2}
                  icon={<SendIcon fontSize="medium" />}
                  label="Sent"
                />
                {/* <Tab
                  value={3}
                  icon={<DraftsIcon fontSize="medium" />}
                  label="Draft"
                /> */}
              </TabList>
            </Box>
            <TabPanel value={1}>
              {/* {submitting && <div>Saving Form...</div>} */}
              <Grid
                container
                spacing={1}
                alignContent="center"
                justifyContent="center"
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <Grid xs={12}>
                  <SuiBox mb={2}>
                    <Grid
                      container
                      spacing={1}
                      item
                      xs={12}
                      sm={12}
                      xl={12}
                      style={{
                        width: '100%',
                        marginBottom: '10px',
                        border: '1px solid rgba(5, 152, 236, 0.637)',
                        borderRadius: '20px',
                        marginTop: '10px',
                        paddingBottom: '20px',
                        marginLeft: '1px',
                        padding: '15px',
                        // paddingLeft: '15px',
                        backgroundColor: 'white',
                      }}
                    >
                      <Grid item xs={6} sm={6} xl={6}>
                        <Typography style={{ fontSize: '14px' }}>
                          Fax From
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={6} sm={4} xl={4}>
            <Typography style={{ fontSize: '14px' }}>Fax To</Typography>
          </Grid> */}
                      <Grid item xs={6} sm={6} xl={6}>
                        <Typography style={{ fontSize: '14px' }}>
                          Received Date
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sm={6} xl={6}>
                        <SuiInput
                          onChange={(e) => setFaxFrom(e.target.value)}
                          value={faxFrom}
                          placeholder="Please Enter Fax From"
                          name="faxFrom"
                          label="faxFrom"
                          fullWidth
                          margin="dense"
                        />
                      </Grid>
                      {/* <Grid item xs={6} sm={4} xl={4}>
            <SuiInput
              onChange={(e) => setFaxTo(e.target.value)}
              value={faxTo}
              placeholder="Please Enter  To"
              name="faxTo"
              label="faxTo"
              fullWidth
              margin="dense"
            />
          </Grid> */}
                      <Grid item xs={6} sm={6} xl={6}>
                        <SuiInput
                          onChange={(e) => setReceivedDate(e.target.value)}
                          value={receivedDate}
                          type="date"
                          name="receivedDate"
                          label="Received Date"
                          fullWidth
                          margin="dense"
                        />
                      </Grid>
                      <Grid container>
                        <Grid item xs={9} sm={9} xl={9}></Grid>
                        <Grid item xs={3} sm={3} xl={3}>
                          <SuiButton
                            onClick={onFilterSearch}
                            variant="gradient"
                            style={{
                              marginTop: '10px',
                              float: 'right',
                              marginLeft: '10px',
                              width: '85px',
                              height: '35px',
                              backgroundColor: '#FCBE13',
                              color: 'black',
                              fontSize: '14px',
                            }}
                          >
                            <SearchOutlinedIcon
                              fontSize="medium"
                              style={{ color: '#1a82ad', paddingBottom: '1px' }}
                            ></SearchOutlinedIcon>
                            Search
                          </SuiButton>
                          <SuiButton
                            onClick={onFilterClear}
                            variant="gradient"
                            style={{
                              marginTop: '10px',
                              marginLeft: 'auto',
                              float: 'right',
                              width: '80px',
                              height: '35px',
                              backgroundColor: '#FCBE13',
                              color: 'black',
                              fontSize: '14px',
                            }}
                            styleDetailsPdf
                          >
                            <CleaningServicesOutlinedIcon
                              fontSize="medium"
                              style={{ color: '#0a9ff0', paddingRight: '5px' }}
                            ></CleaningServicesOutlinedIcon>
                            Clear
                          </SuiButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </SuiBox>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{
                    margin: '0 auto',
                    height: '500px',
                    width: '100%',
                    overflow: 'auto',
                  }}
                >
                  {rows != null && rows.length > 0
                    ? rows.map((item, index) => (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            style={{
                              marginBottom: '5px',
                              borderBottom: 'groove',
                              overFlow: 'auto',
                              padding: '5px',
                            }}
                            backgroundColor={
                              selectedIndex == index ? '#B2E4F6' : ''
                            }
                          >
                            <Grid container xs={6} sm={12} md={12} lg={12}>
                              <Grid item xs={4}>
                                <Typography
                                  style={{
                                    paddingBottom: '1px',
                                    fontSize: '17px',
                                    cursor:"pointer",
                                  }}
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                  color="#32A6D6"
                                  onClick={() => {
                                    setSelectedIndex(index),
                                      setFaxFrom(item.faxFrom),
                                      setreceiveId(item.id)
                                      setcompleted(item.markComplete)

                                      console.log("MarkCompleted value is " , item.markComplete)
                                      console.log("Received Fax id Is " ,item.id )


                                    item.faxAttachments
                                      ? (setDocument(
                                          `${process.env.REACT_APP_DOCUMENT_URL}/${item.faxAttachments}`
                                        ),
                                        setOpenn(true))
                                      : confirmAlert({
                                          message:
                                            'Please Contact Administrator! Document Not Found.',
                                          closeOnEscape: true,
                                          closeOnClickOutside: true,
                                          // overlayClassName: 'overlay-custom-class-name'
                                          buttons: [
                                            {
                                              label: 'Ok',
                                            },
                                          ],
                                        })
                                  }}
                                >
                                  Subject: <span>{item.subject}</span>
                                </Typography>
                              </Grid>

                              <Grid item xs={4}>
                                <Typography
                                  style={{
                                    paddingBottom: '1px',
                                    fontSize: '14px',
                                    cursor:"pointer",
                                  }}
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                  color="#333333"
                                  onClick={() => {
                                    setSelectedIndex(index),
                                      setFaxFrom(item.faxFrom)
                                    item.faxAttachments
                                      ? (setDocument(
                                          `${process.env.REACT_APP_DOCUMENT_URL}/${item.faxAttachments}`
                                        ),
                                        // item.faxAttachments
                                        //   ? (setDocument(
                                        //       `${
                                        //         process.env.REACT_APP_DOCUMENT_URL
                                        //       }/${encodeURI(item.faxAttachments)}`
                                        //     ),
                                        setOpenn(true))
                                      : confirmAlert({
                                          message:
                                            'Please Contact Administrator! Document Not Found.',
                                          closeOnEscape: true,
                                          closeOnClickOutside: true,
                                          // overlayClassName: 'overlay-custom-class-name'
                                          buttons: [
                                            {
                                              label: 'Ok',
                                            },
                                          ],
                                        })
                                  }}
                                >
                                  From: {item.faxFrom} (
                                  <span style={{ fontSize: '10px' }}>
                                    Pages:{item.faxPageCount}
                                  </span>
                                  )
                                </Typography>
                              </Grid>

                              <Grid item xs={3}>
                                <Typography
                                  style={{
                                    padding: '4px',
                                    marginTop: '8px',
                                    fontSize: '10px',
                                    textAlign: 'right',
                                    float: 'right',
                                  }}
                                  component="label"
                                  variant="caption"
                                  // color="#32A6D6"
                                >
                                  {handleDate(item.creationTime)}
                                </Typography>
                              </Grid>

                              <Grid xs={1}>
                              <>
      <DeleteIcon
        cursor="pointer"
        style={{
          marginRight: '5px',
          float: 'right',
          marginTop: '5px',
          color: 'red',
        }}
        onClick={() => setOpenDialog(true)}
      ></DeleteIcon>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}
       aria-labelledby="responsive-dialog-title"
       PaperProps={{
        style: {background:'Amber',
        boxShadow:'none',
        borderRadius:'8px'
        },
     }}                  
       BackdropComponent={Backdrop}
       BackdropProps={{
         invisible: true,
       }}
      >

<div style={{borderRadius:'8px',width:'22rem'}}>
   
            <ErrorOutlineIcon style={{marginLeft:'125px',fontSize:'100px',color:'orange'}}></ErrorOutlineIcon>
                <DialogTitle style={{marginLeft:'1rem',fontWeight:'bold'}}>{"Do you want to delete the record?"}</DialogTitle>
                <h5 style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'normal',marginLeft:'-2rem' }}>
  This action cannot be undone.
</h5>
                <DialogContent>
                    <DialogContentText>
                    <Grid style={{marginLeft:'90px'}}>
                    <Button  onClick={()=>{handleDelete(item)}} style={{backgroundColor:'red',color:'white'}}>YES</Button>
                    <Button  onClick={() => setOpenDialog(false)}  style={{backgroundColor:'blue',color:'white',marginLeft:'1rem'}}>NO</Button>
                    </Grid>
                    </DialogContentText>
                </DialogContent>
               
                
      
    
    
    </div>


   
      </Dialog>
    </>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      ))
                    : 'No Record Found'}
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={2}>
              {/* {submitting && <div>Saving Form...</div>} */}
              <Grid
                container
                spacing={1}
                alignContent="center"
                justifyContent="center"
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <Grid xs={12}>
                  <SuiBox mb={2}>
                    <Grid
                      container
                      spacing={1}
                      item
                      xs={12}
                      sm={12}
                      xl={12}
                      style={{
                        width: '100%',
                        marginBottom: '10px',
                        border: '1px solid rgba(5, 152, 236, 0.637)',
                        borderRadius: '20px',
                        marginTop: '10px',
                        paddingBottom: '20px',
                        marginLeft: '1px',
                        padding: '15px',
                        // paddingLeft: '15px',
                        backgroundColor: 'white',
                      }}
                    >
                      <Grid item xs={6} sm={4} xl={4}>
                        <Typography style={{ fontSize: '14px' }}>
                          Fax From
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4} xl={4}>
                        <Typography style={{ fontSize: '14px' }}>
                          Fax To
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4} xl={4}>
                        <Typography style={{ fontSize: '14px' }}>
                          Send Date
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sm={4} xl={4}>
                        <SuiInput
                          onChange={(e) => setFaxFrom(e.target.value)}
                          value={faxFrom}
                          placeholder="Please enter Fax From"
                          name="faxFrom"
                          label="faxFrom"
                          fullWidth
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={6} sm={4} xl={4}>
                        <SuiInput
                          onChange={(e) => setFaxTo(e.target.value)}
                          value={faxTo}
                          placeholder="Please enter Fax To"
                          name="faxTo"
                          label="faxTo"
                          fullWidth
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={6} sm={4} xl={4}>
                        <SuiInput
                          onChange={(e) => setSendDate(e.target.value)}
                          value={sendDate}
                          type="date"
                          name="sendDate"
                          label="Send Date"
                          fullWidth
                          margin="dense"
                        />
                      </Grid>

                      <Grid container>
                        <Grid item xs={9} sm={9} xl={9}></Grid>
                        <Grid item xs={3} sm={3} xl={3}>
                          <SuiButton
                            onClick={onFilterSearch}
                            variant="gradient"
                            style={{
                              marginTop: '10px',
                              float: 'right',
                              marginLeft: '10px',
                              width: '85px',
                              height: '35px',
                              backgroundColor: '#FCBE13',
                              color: 'black',
                              fontSize: '14px',
                            }}
                          >
                            <SearchOutlinedIcon
                              fontSize="medium"
                              style={{ color: '#1a82ad', paddingBottom: '1px' }}
                            ></SearchOutlinedIcon>
                            Search
                          </SuiButton>
                          <SuiButton
                            onClick={onFilterClear}
                            variant="gradient"
                            style={{
                              marginTop: '10px',
                              marginLeft: 'auto',
                              float: 'right',
                              width: '80px',
                              height: '35px',
                              backgroundColor: '#FCBE13',
                              color: 'black',
                              fontSize: '14px',
                            }}
                          >
                            <CleaningServicesOutlinedIcon
                              fontSize="medium"
                              style={{ color: '#0a9ff0', paddingRight: '5px' }}
                            ></CleaningServicesOutlinedIcon>
                            Clear
                          </SuiButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </SuiBox>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{
                    margin: '0 auto',
                    height: '500px',
                    width: '100%',
                    overflow: 'auto',
                  }}
                >
                  {sentMessages != null && sentMessages.length > 0
                    ? sentMessages.map((item, index) => (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{
                            marginBottom: '5px',
                            borderBottom: 'groove',
                            padding: '5px',
                          }}
                          backgroundColor={
                            selectedIndex == index ? '#B2E4F6' : ''
                          }

                          // onClick={() => {
                          //   setOpenn()
                          //   setFaxTo(item.faxTo)
                          //   setSelectedIndex(index),
                          //     // setId(item.id)
                          //     item.faxAttachments
                          //       ? (setDocument(
                          //           `${
                          //             process.env.REACT_APP_DOCUMENT_URL
                          //           }/${encodeURI(item.faxAttachments)}`
                          //         ),
                          //         setOpenn(true))
                          //       : confirmAlert({
                          //           message:
                          //             'Please Contact Administrator! Document Not Found.',
                          //           closeOnEscape: true,
                          //           closeOnClickOutside: true,
                          //           // overlayClassName: 'overlay-custom-class-name'
                          //           buttons: [
                          //             {
                          //               label: 'Ok',
                          //             },
                          //           ],
                          //         })
                          // }}
                        >
                          <Grid container xs={6} sm={12} md={12} lg={12}>
                            <Grid xs={4}>
                              <Typography
                                style={{
                                  paddingBottom: '1px',
                                  fontSize: '17px',
                                  cursor:"pointer",
                                }}
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                color="#32A6D6"
                                onClick={() => {
                                  setOpenn(true)
                                  setFaxTo(item.faxTo)
                                  setSelectedIndex(index),
                                    // setId(item.id)
                                    item.faxAttachments
                                      ? (setDocument(
                                          `${process.env.REACT_APP_DOCUMENT_URL}/${item.faxAttachments}`
                                        ),
                                        setOpenn(true))
                                      : confirmAlert({
                                          message:
                                            'Please Contact Administrator! Document Not Found.',
                                          closeOnEscape: true,
                                          closeOnClickOutside: true,
                                          // overlayClassName: 'overlay-custom-class-name'
                                          buttons: [
                                            {
                                              label: 'Ok',
                                            },
                                          ],
                                        })
                                }}
                              >
                                Subject: <span>{item.subject}</span>
                              </Typography>
                            </Grid>

                            <Grid xs={3}>
                              <Typography
                                style={{
                                  paddingBottom: '1px',
                                  fontSize: '14px',
                                  cursor:"pointer",
                                }}
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                color="#333333"
                                onClick={() => {
                                  setOpenn(true)
                                  setFaxTo(item.faxTo)
                                  setSelectedIndex(index),
                                    // setId(item.id)
                                    item.faxAttachments
                                      ? (setDocument(
                                          `${
                                            process.env.REACT_APP_DOCUMENT_URL
                                          }/${encodeURI(item.faxAttachments)}`
                                        ),
                                        setOpenn(true))
                                      : confirmAlert({
                                          message:
                                            'Please Contact Administrator! Document Not Found.',
                                          closeOnEscape: true,
                                          closeOnClickOutside: true,
                                          // overlayClassName: 'overlay-custom-class-name'
                                          buttons: [
                                            {
                                              label: 'Ok',
                                            },
                                          ],
                                        })
                                }}
                              >
                                Fax To: {item.faxTo} (
                                <span style={{ fontSize: '10px' }}>
                                  Pages:{item.faxPageCount}
                                </span>
                                )
                              </Typography>
                            </Grid>
                            <Grid xs={2}>
                              <Typography
                                style={{
                                  paddingBottom: '1px',
                                  fontSize: '14px',
                                  cursor:"pointer",
                                }}
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                color="#333333"
                                onClick={() => {
                                  setOpenn(true)
                                  setFaxTo(item.faxTo)
                                  setSelectedIndex(index),
                                    // setId(item.id)
                                    item.faxAttachments
                                      ? (setDocument(
                                          `${
                                            process.env.REACT_APP_DOCUMENT_URL
                                          }/${encodeURI(item.faxAttachments)}`
                                        ),
                                        setOpenn(true))
                                      : confirmAlert({
                                          message:
                                            'Please Contact Administrator! Document Not Found.',
                                          closeOnEscape: true,
                                          closeOnClickOutside: true,
                                          // overlayClassName: 'overlay-custom-class-name'
                                          buttons: [
                                            {
                                              label: 'Ok',
                                            },
                                          ],
                                        })
                                }}
                              >
                                Status: <span>{item.messageStatus}</span>
                              </Typography>
                            </Grid>
                            <Grid xs={2}>
                              <Typography
                                style={{
                                  padding: '4px',
                                  marginTop: '8px',
                                  fontSize: '10px',
                                  textAlign: 'right',
                                  float: 'right',
                                }}
                                component="label"
                                variant="caption"
                                // color="#2188FE"sentBy
                                onClick={() => {
                                  setOpenn(true)
                                  setFaxTo(item.faxTo)
                                  setSelectedIndex(index),
                                    // setId(item.id)
                                    item.faxAttachments
                                      ? (setDocument(
                                          `${
                                            process.env.REACT_APP_DOCUMENT_URL
                                          }/${encodeURI(item.faxAttachments)}`
                                        ),
                                        setOpenn(true))
                                      : confirmAlert({
                                          message:
                                            'Please Contact Administrator! Document Not Found.',
                                          closeOnEscape: true,
                                          closeOnClickOutside: true,
                                          // overlayClassName: 'overlay-custom-class-name'
                                          buttons: [
                                            {
                                              label: 'Ok',
                                            },
                                          ],
                                        })
                                }}
                              >
                                {handleDate(item.creationTime)}
                              </Typography>
                            </Grid>
                            <Grid xs={1}>
                            <>
      <DeleteIcon
        cursor="pointer"
        style={{
          marginRight: '5px',
          float: 'right',
          marginTop: '5px',
          color: 'red',
        }}
        onClick={() => setDialogOpen(true)}
      ></DeleteIcon>
      <Dialog

        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropComponent={Backdrop}
        BackdropProps={{
          invisible: true,
        }}
        PaperProps={{
          style: {background:'#cef5f0',
          boxShadow:'none',
          borderRadius:'8px'
          },
       }}
      >
      <div style={{borderRadius:'8px',width:'22rem',background:'white'}}>
   
   <ErrorOutlineIcon style={{marginLeft:'125px',fontSize:'100px',color:'orange'}}></ErrorOutlineIcon>
       <DialogTitle style={{marginLeft:'1rem',fontWeight:'bold'}}>{"Do you want to delete the record?"}</DialogTitle>
       <h5 style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'normal',marginLeft:'-2rem' }}>
This action cannot be undone.
</h5>
       <DialogContent>
           <DialogContentText>
           <Grid style={{marginLeft:'90px'}}>
           <Button onClick={()=>{handleDeleteById(item)}} style={{backgroundColor:'red',color:'white'}}>YES</Button>
           <Button  onClick={() => setDialogOpen(false)}   style={{backgroundColor:'blue',color:'white',marginLeft:'1rem'}}>NO</Button>
           </Grid>
           </DialogContentText>
       </DialogContent>
      
       



</div>
      </Dialog>
    </>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))
                    : 'No Record Found'}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={3}></TabPanel>
          </TabContext>
        </div>
        {/* </AccordionDetails> */}
        {/* </Accordion> */}
      </Grid>
    </>
  )
}

export default Fax
