import React, { useEffect, useReducer, useState, Fragment } from 'react'
import SuiInput from 'components/SuiInput'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import { createBrowserHistory } from 'history'
import axios from 'axios'
const history = createBrowserHistory({ forceRefresh: true })
const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
const headers = {
  Authorization: auth,
}
export default function CRAFFT(props) {
  const id = props.id
  const [question1, setQuestion1] = useState('')
  const [question2, setQuestion2] = useState('')
  const [question3, setQuestion3] = useState('')
  const [question4, setQuestion4] = useState('')
  const [question5, setQuestion5] = useState('')
  const [question6, setQuestion6] = useState('')
  const [question7, setQuestion7] = useState('')
  const [question8, setQuestion8] = useState('')
  const [question9, setQuestion9] = useState('')

  const [date, setDate] = useState('')

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    let isComponentMounted = true
    const fetchData = async () =>
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/PatientPortal/getCraft9ById?Id=${id !== undefined ? id : 0
          }`,
          { headers }
          // `${process.env.REACT_APP_API_URL}/Facilities/getFacilityById?id=${
          //   location.state.facilityId !== undefined ? location.state.facilityId : 0
          // }`,
          // null,
          // {
          //   headers,
          // }
        )
        .then((response) => {
          //   console.log('response.data: ', response.data)
          setQuestion1(response.data.q1)
          setQuestion2(response.data.q2)
          setQuestion3(response.data.q3)
          setQuestion4(response.data.q4)
          setQuestion5(response.data.q5)
          setQuestion6(response.data.q6)
          setQuestion7(response.data.q7)
          setQuestion8(response.data.q8)
          setQuestion9(response.data.q9)

          setDate(response.data.date)
        })
        .catch((error) => {
          console.error('There was an error!', error)
        })
    fetchData()
    return () => {
      isComponentMounted = false
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [])
  return (
    <Grid container>
      <Typography variant="h2" style={{ textAlign: 'center' }}>
        CRAFFT Screening Tool for Adolescent Substance Abuse
      </Typography>
      <Typography style={{ marginBottom: '30px', marginTop: '50px' }}>
        The following questions concern information about your potential
        involvement with alcohol and other drugs during the past 12 months.
        Carefully read each question and decide if your answer is “YES” or “NO”.
        Then mark in the appropriate box beside the question. Please answer
        every question. If you cannot decide, then choose the response that is
        mostly right.
      </Typography>
      <Typography style={{ marginBottom: '80px' }}>
        When the word “drug” is used, it refers to the use of prescribed or
        over-the-counter drugs that are used in excess of the directions and any
        non-medical use of drugs. The various classes of drugs may include but
        are not limited to: cannabis (e.g., marijuana, hash), solvents (e.g.,
        gas, paints etc…), tranquilizers (e.g., Valium), barbiturates, cocaine,
        and stimulants (e.g., speed), hallucinogens (e.g., LSD) or narcotics
        (e.g., Heroin, Oxycontin).
      </Typography>
      <Grid container></Grid>
      <table
        className="table table-bordered border-dark table-striped table-hover"
        style={{ border: '2px solid black' }}
      >
        <thead>
          <tr>
            <th
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
              scope="col"
              colSpan={2}
            >
              Part A: During the PAST 12 MONTHS, did you:
            </th>
            <th
              scope="col"
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
                textAlign: 'center',
              }}
            >
              NO
            </th>
            <th
              scope="col"
              style={{ borderBottom: '1px solid black', textAlign: 'center' }}
            >
              YES
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                borderRight: '2px solid black',
                borderBottom: '1px solid black',
              }}
              scope="col"
            >
              1:
            </td>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              Drink any{' '}
              <b>
                <u>alcohol</u>
              </b>{' '}
              (more than a few sips)? <br />
              (Do not count sips of alcohol taken during family or religious
              events.)
            </td>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F1F4FF',
              }}
            >
              <input
                class="form-check-input"
                type="radio"
                name="question1"
                checked={!question1}
                value="false"
              ></input>
            </td>
            <td
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F1F4FF',
              }}
            >
              <input
                class="form-check-input"
                type="radio"
                name="question1"
                checked={question1}
                value="true"
              ></input>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderRight: '2px solid black',
                borderBottom: '1px solid black',
              }}
              scope="col"
            >
              2:
            </td>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              Smoke any{' '}
              <b>
                <u>marijuana</u>
              </b>{' '}
              or{' '}
              <b>
                <u>hashish?</u>
              </b>
            </td>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F1F4FF',
              }}
            >
              <input
                class="form-check-input"
                type="radio"
                name="question2"
                checked={!question2}
                value="false"
              ></input>
            </td>
            <td
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F1F4FF',
              }}
            >
              <input
                class="form-check-input"
                type="radio"
                name="question2"
                checked={question2}
                value="true"
              ></input>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderRight: '2px solid black',
                borderBottom: '1px solid black',
              }}
              scope="col"
            >
              3:
            </td>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              Use anything else to get high? <br />
              (“anything else” includes illegal drugs, over the counter and
              prescription drugs, and things that you sniff or “huff”)
            </td>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F1F4FF',
              }}
            >
              <input
                class="form-check-input"
                type="radio"
                name="question3"
                checked={!question3}
                value="false"
              ></input>
            </td>
            <td
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F1F4FF',
              }}
            >
              <input
                class="form-check-input"
                type="radio"
                name="question3"
                checked={question3}
                value="true"
              ></input>
            </td>
          </tr>
          <tr>
            <th
              scope="col"
              colSpan={2}
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              Part B: CRAFFT{' '}
            </th>
            <th
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
                textAlign: 'center',
              }}
              scope="col"
            >
              NO
            </th>
            <th
              style={{ borderBottom: '1px solid black', textAlign: 'center' }}
              scope="col"
            >
              YES
            </th>
          </tr>
          <tr>
            <td
              style={{
                borderRight: '2px solid black',
                borderBottom: '1px solid black',
              }}
              scope="col"
            >
              1:
            </td>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              Have you ever ridden in a <b>CAR</b> driven by someone (including
              yourself) who was “high” or had been using alcohol or drugs?
            </td>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
                borderRight: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F1F4FF',
              }}
            >
              <input
                class="form-check-input"
                type="radio"
                name="question4"
                checked={!question4}
                value="false"
              ></input>
            </td>
            <td
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F1F4FF',
              }}
            >
              <input
                class="form-check-input"
                type="radio"
                name="question4"
                checked={question4}
                value="true"
              ></input>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderRight: '2px solid black',
                borderBottom: '1px solid black',
              }}
              scope="col"
            >
              2:
            </td>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              Do you ever use alcohol or drugs to <b>RELAX</b>, feel better
              about yourself, or fit in?
            </td>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F1F4FF',
              }}
            >
              <input
                class="form-check-input"
                type="radio"
                name="question5"
                checked={!question5}
                value="false"
              ></input>
            </td>
            <td
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F1F4FF',
              }}
            >
              <input
                class="form-check-input"
                type="radio"
                name="question5"
                checked={question5}
                value="true"
              ></input>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderRight: '2px solid black',
                borderBottom: '1px solid black',
              }}
              scope="col"
            >
              3:
            </td>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              Do you ever use alcohol or drugs while you are by yourself, or{' '}
              <b>ALONE</b>?
            </td>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F1F4FF',
              }}
            >
              <input
                class="form-check-input"
                type="radio"
                name="question6"
                checked={!question6}
                value="false"
              ></input>
            </td>
            <td
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F1F4FF',
              }}
            >
              <input
                class="form-check-input"
                type="radio"
                name="question6"
                checked={question6}
                value="true"
              ></input>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderRight: '2px solid black',
                borderBottom: '1px solid black',
              }}
              scope="col"
            >
              4:
            </td>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              Do you ever <b>FORGET</b> things you did while using alcohol or
              drugs?
            </td>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F1F4FF',
              }}
            >
              <input
                class="form-check-input"
                type="radio"
                name="question7"
                checked={!question7}
                value="false"
              ></input>
            </td>
            <td
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F1F4FF',
              }}
            >
              <input
                class="form-check-input"
                type="radio"
                name="question7"
                checked={question7}
                value="true"
              ></input>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderRight: '2px solid black',
                borderBottom: '1px solid black',
              }}
              scope="col"
            >
              5:
            </td>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              Do your <b>FAMILY or FRIENDS</b> ever tell you that you should cut
              down on your drinking or drug use?
            </td>
            <td
              style={{
                borderBottom: '1px solid black',
                borderRight: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F1F4FF',
              }}
            >
              <input
                class="form-check-input"
                type="radio"
                name="question8"
                checked={!question8}
                value="false"
              ></input>
            </td>
            <td
              style={{
                borderBottom: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F1F4FF',
              }}
            >
              <input
                class="form-check-input"
                type="radio"
                name="question8"
                checked={question8}
                value="true"
              ></input>
            </td>
          </tr>
          <tr>
            <td style={{ borderRight: '2px solid black' }} scope="col">
              6:
            </td>
            <td style={{ borderRight: '1px solid black' }}>
              Have you ever gotten into <b>TROUBLE</b> while you were using
              alcohol or drugs?
            </td>
            <td
              style={{
                borderRight: '1px solid black',
                textAlign: 'center',
                backgroundColor: '#F1F4FF',
              }}
            >
              <input
                class="form-check-input"
                type="radio"
                name="question9"
                checked={!question9}
                value="false"
              ></input>
            </td>
            <td style={{ textAlign: 'center', backgroundColor: '#F1F4FF' }}>
              <input
                class="form-check-input"
                type="radio"
                name="question9"
                checked={question9}
                value="true"
              ></input>
            </td>
          </tr>
        </tbody>
      </table>
      <Typography variant="h2" style={{ marginTop: '150px' }}>
        CRAFFT Screening Tool for Adolescent Substance Abuse
      </Typography>
      <Typography style={{ marginTop: '30px' }}>
        <b>
          <u>Scoring and Interpretation:</u>
        </b>
      </Typography>
      <Typography style={{ marginTop: '20px' }}>
        Part A: If “yes” to any questions in Part A, ask all 6 CRAFFT questions.
        If “no” ask CAR question then stop.
      </Typography>
      <Typography>Part B: Score 1 point for each “YES” answer.</Typography>
      <Grid
        container
        style={{
          margin: '40px',
          padding: '20px',
          innerborder: '2px solid black',
        }}
      >
        <Grid container style={{ border: '2px solid black' }}>
          <Grid
            container
            xs={12}
            lg={12}
            style={{ borderBottom: '1px solid black' }}
          >
            <Grid
              item
              xs={4}
              lg={4}
              style={{ borderRight: '1px solid black', textAlign: 'center' }}
            >
              CRAFFT Score
            </Grid>
            <Grid
              item
              xs={4}
              lg={4}
              style={{ borderRight: '1px solid black', textAlign: 'center' }}
            >
              Degree of problem related to alcohol or other substance abuse
            </Grid>
            <Grid item xs={4} lg={4} style={{ textAlign: 'center' }}>
              Suggested Action
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            lg={12}
            style={{ borderBottom: '1px solid black' }}
          >
            <Grid
              item
              xs={4}
              lg={4}
              style={{ borderRight: '1px solid black', textAlign: 'center' }}
            >
              0-1
            </Grid>
            <Grid
              item
              xs={4}
              lg={4}
              style={{ borderRight: '1px solid black', textAlign: 'center' }}
            >
              No problems reported.
            </Grid>
            <Grid item xs={4} lg={4} style={{ textAlign: 'center' }}>
              None at this time.
            </Grid>
          </Grid>
          <Grid container xs={12} lg={12}>
            <Grid
              item
              xs={4}
              lg={4}
              style={{ borderRight: '1px solid black', textAlign: 'center' }}
            >
              2+
            </Grid>
            <Grid
              item
              xs={4}
              lg={4}
              style={{ borderRight: '1px solid black', textAlign: 'center' }}
            >
              Potential of a significant problem.
            </Grid>
            <Grid item xs={4} lg={4} style={{ textAlign: 'center' }}>
              Assessment required.
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Typography style={{ marginTop: '50px', fontFamily: 'sans-serif' }}>
        <u>References:</u>
        <p>
          • Knight JR, et al. A new brief screen for adolescent substance abuse.
          Arch Pediatr
          <br /> Adolesc Med. 1999 Jun;153(6):591-6. PMID: 10357299 <br />
          • Dhalla S, et al. A review of the psychometric properties of the
          CRAFFT instrument:
          <br /> 1999-2010. Curr Drug Abuse Rev. 2011 Mar 1;4(1):57-64. PMID:
          21466499
        </p>
      </Typography>
    </Grid>
  )
}
