import React, { useEffect, useReducer, useState, Fragment } from 'react'
 import SuiInput from 'components/SuiInput'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import { alignProperty } from '@mui/material/styles/cssUtils'
import { createBrowserHistory } from 'history'
import axios from 'axios'
import pic from './ptsd.png'
 const history = createBrowserHistory({ forceRefresh: true })
const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
const headers = {
  Authorization: auth,
}
export default function PCL(props) {
    const id=props.id
    const [question1, setQuestion1] = useState('')
    const [question2, setQuestion2] = useState('')
    const [question3, setQuestion3] = useState('')
    const [question4, setQuestion4] = useState('')
    const [question5, setQuestion5] = useState('')
    const [question6, setQuestion6] = useState('')
    const [question7, setQuestion7] = useState('')
    const [question8, setQuestion8] = useState('')
    const [question9, setQuestion9] = useState('')
    const [question10, setQuestion10] = useState('')
    const [question11, setQuestion11] = useState('')
    const [question12, setQuestion12] = useState('')
    const [question13, setQuestion13] = useState('')
    const [question14, setQuestion14] = useState('')
    const [question15, setQuestion15] = useState('')
    const [question16, setQuestion16] = useState('')
    const [question17, setQuestion17] = useState('')
    const [question18, setQuestion18] = useState('')
    const [question19, setQuestion19] = useState('')
    const [question20, setQuestion20] = useState('')

    
    useEffect(() => {
        // POST request using axios inside useEffect React hook
        let isComponentMounted = true
        const fetchData = async () =>
          axios
            .get(
              `${
                process.env.REACT_APP_API_URL
              }/PatientPortal/getPcl20ById?Id=${
                id !== undefined ? id : 0
              }`,
              { headers }
              // `${process.env.REACT_APP_API_URL}/Facilities/getFacilityById?id=${
              //   location.state.facilityId !== undefined ? location.state.facilityId : 0
              // }`,
              // null,
              // {
              //   headers,
              // }
            )
            .then((response) => {
            //   console.log('response.data: ', response.data)
             setQuestion1(response.data.q1)
             setQuestion2(response.data.q2)
             setQuestion3(response.data.q3)
             setQuestion4(response.data.q4)
             setQuestion5(response.data.q5)
             setQuestion6(response.data.q6)
             setQuestion7(response.data.q7)
             setQuestion8(response.data.q8)
             setQuestion9(response.data.q9)
             setQuestion10(response.data.q10)
             setQuestion11(response.data.q11)
             setQuestion12(response.data.q12)
             setQuestion13(response.data.q13)
             setQuestion14(response.data.q14)
             setQuestion15(response.data.q15)
             setQuestion16(response.data.q16)
             setQuestion17(response.data.q17)
             setQuestion18(response.data.q18)
             setQuestion19(response.data.q19)
             setQuestion20(response.data.q20)
             })
            .catch((error) => {
              console.error('There was an error!', error)
            })
        fetchData()
        return () => {
          isComponentMounted = false
        }
    
        // empty dependency array means this effect will only run once (like componentDidMount in classes)
      }, [])
  return (
    <Grid className='container'>
     
      <Grid item xs={12} sm={12} lg={12} align="center" marginTop="10px">
              <img src={pic} width="100%"></img>
<Typography style={{fontSize:'50px',fontWeight:'bold',marginTop:'70px,',fontFamily:'algebra'}}>PTSD Checklist for<br/>
DSM-5 (PCL-5)</Typography>
      </Grid>
      <br/> <br/> <br/> <br/> <br/> <br/> <br/>
      <Grid item xs={12} marginTop="50px">
<Typography fontSize={'14px'}><b>Version date:</b> 11 April 2018 
</Typography>

      </Grid>
      
      <Grid item xs={12} marginTop="10px">
<Typography fontSize={'14px'}><b>Reference:</b> Weathers, F. W., Litz, B. T., Keane, T. M., <br/>
Palmieri, P. A., Marx, B. P.,  Schnurr, P. P. (2013).  <br/>
The PTSD Checklist for DSM-5 (PCL-5) – Standard  11 April 2018  <br/>
[Measurement instrument]. Available from <br/>
<a href="https://www.ptsd.va.gov/" color='black'>https://www.ptsd.va.gov/</a>
</Typography>
<Typography fontSize={'14px'}  marginTop="10px"><b>URL:</b> 
<a href="https://www.ptsd.va.gov/professional/" color='black'>https://www.ptsd.va.gov/professional/</a>
</Typography>
<Typography fontSize={'14px'} marginTop="10px"><b>Note:</b>  This is a fillable form. You may complete it electronically.
</Typography>
      </Grid>
      <Grid item xs={12} marginTop="50px">
<Typography style={{borderBottom:'5px solid #023B5A'}}>
</Typography>

      </Grid>
       <Typography style={{ marginBottom: '30px', marginTop: '50px' ,fontSize:'12px'}}>
        <b>Instructions:</b> Below is a list of problems that people sometimes have in response to a very stressful experience. Please 
read each problem carefully and then circle one of the numbers to the right to indicate how much you have been 
bothered by that problem in the past month.
      </Typography>
     
      <table className='table table-bordered border-dark table-striped table-hover' style={{border:'2px solid black'}}>
        <thead>
          <tr>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black',fontSize:'16px'}} scope="col" ><b>In the past month, how much were you bothered by: </b></th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black',fontSize:'12px',padding:'5px'}} scope="col" colSpan={2}>Not at all</th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black',fontSize:'12px',padding:'5px'}} scope="col" colSpan={2}>A little bit</th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black',fontSize:'12px',padding:'5px'}} scope="col" colSpan={2}>Moderately</th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black',fontSize:'12px',padding:'5px'}} scope="col" colSpan={2}>Quite a bit </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',fontSize:'12px',padding:'5px'}} scope="col" colSpan={2}>Extremely</th>
          </tr>
          <tr>
          </tr>
        </thead>
        
        <tbody>
            
          <tr style={{backgroundColor:'#E6E7E9'}}>
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>1. Repeated, disturbing, and unwanted memories of the 
stressful experience?
             </td>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question1"   checked={question1==0?true:false} value="true"></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question1"  checked={question1==1?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question1"  checked={question1==2?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question1"  checked={question1==3?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question1"  checked={question1==4?true:false} value='true'></input>
            </th>
          </tr >
          <tr >
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>
              
              2. Repeated, disturbing dreams of the stressful experience?             </td>
              <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question2"   checked={question2==0?true:false} value="true"></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question2"  checked={question2==1?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question2"  checked={question2==2?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question2"  checked={question2==3?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question2"  checked={question2==4?true:false} value='true'></input>
            </th>
          </tr>
          <tr style={{backgroundColor:'#E6E7E9'}}>
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>
            3. Suddenly feeling or acting as if the stressful experience were 
actually happening again (as if you were actually back there 
reliving it)? 
            </td>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question3"   checked={question3==0?true:false} value="true"></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question3"  checked={question3==1?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question3"  checked={question3==2?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question3"  checked={question3==3?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question3"  checked={question3==4?true:false} value='true'></input>
            </th>
          </tr>
          <tr>
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>
            4. Feeling very upset when something reminded you of the 
stressful experience? 
            </td>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question4"   checked={question4==0?true:false} value="true"></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question4"  checked={question4==1?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question4"  checked={question4==2?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question4"  checked={question4==3?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question4"  checked={question4==4?true:false} value='true'></input>
            </th>
          </tr>
          <tr style={{backgroundColor:'#E6E7E9'}}>
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>
            5. Having strong physical reactions when something reminded 
you of the stressful experience (for example, heart 
pounding, trouble breathing, sweating)?             </td>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question5"   checked={question5==0?true:false} value="true"></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question5"  checked={question5==1?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question5"  checked={question5==2?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question5"  checked={question5==3?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question5"  checked={question5==4?true:false} value='true'></input>
            </th>
          </tr>
          <tr>
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>
            6. Avoiding memories, thoughts, or feelings related to the 
stressful experience? 
            </td>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question6"   checked={question6==0?true:false} value="true"></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question6"  checked={question6==1?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question6"  checked={question6==2?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question6"  checked={question6==3?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question6"  checked={question6==4?true:false} value='true'></input>
            </th>
          </tr>
          <tr style={{backgroundColor:'#E6E7E9'}}>
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>
            7. Avoiding external reminders of the stressful experience (for 
example, people, places, conversations, activities, objects, or 
situations)?            </td>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question7"   checked={question7==0?true:false} value="true"></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question7"  checked={question7==1?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question7"  checked={question7==2?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question7"  checked={question7==3?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question7"  checked={question7==4?true:false} value='true'></input>
            </th>
          </tr>
          <tr>
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>
            8. Trouble remembering important parts of the stressful 
experience?            </td>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question8"   checked={question8==0?true:false} value="true"></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question8"  checked={question8==1?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question8"  checked={question8==2?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question8"  checked={question8==3?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question8"  checked={question8==4?true:false} value='true'></input>
            </th>
          </tr>
          <tr style={{backgroundColor:'#E6E7E9'}}>  
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>
            9. Having strong negative beliefs about yourself, other people, 
or the world (for example, having thoughts such as: I am 
bad, there is something seriously wrong with me, 
no one can be trusted, the world is completely dangerous)?            </td>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question9"   checked={question9==0?true:false} value="true"></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question9"  checked={question9==1?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question9"  checked={question9==2?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question9"  checked={question9==3?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question9"  checked={question9==4?true:false} value='true'></input>
            </th>
          </tr>
          <tr>
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>
            10. Blaming yourself or someone else for the stressful 
experience or what happened after it?                  </td>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question10"   checked={question10==0?true:false} value="true"></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question10"  checked={question10==1?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question10"  checked={question10==2?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question10"  checked={question10==3?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question10"  checked={question10==4?true:false} value='true'></input>
            </th>
          </tr>
          <tr style={{backgroundColor:'#E6E7E9'}}>
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>
            11. Having strong negative feelings such as fear, horror, anger, 
guilt, or shame?            </td>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question11"   checked={question11==0?true:false} value="true"></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question11"  checked={question11==1?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question11"  checked={question11==2?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question11"  checked={question11==3?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question11"  checked={question11==4?true:false} value='true'></input>
            </th>
          </tr>
          <tr>
            <td style={{borderRight:'1px solid black'}}>
12. Loss of interest in activities that you used to enjoy?      
      </td>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question12"   checked={question12==0?true:false} value="true"></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question12"  checked={question12==1?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question12"  checked={question12==2?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question12"  checked={question12==3?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question12"  checked={question12==4?true:false} value='true'></input>
            </th>
          </tr>
          <tr style={{backgroundColor:'#E6E7E9'}}>
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>
            13. Feeling distant or cut off from other people? 
        </td>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question13"   checked={question13==0?true:false} value="true"></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question13"  checked={question13==1?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question13"  checked={question13==2?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question13"  checked={question13==3?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question13"  checked={question13==4?true:false} value='true'></input>
            </th>
          </tr>
          <tr>
            <td style={{borderRight:'1px solid black'}}>
            14. Trouble experiencing positive feelings (for example, being 
unable to feel happiness or have loving feelings for people 
close to you)?      </td>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question14"   checked={question14==0?true:false} value="true"></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question14"  checked={question14==1?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question14"  checked={question14==2?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question14"  checked={question14==3?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question14"  checked={question14==4?true:false} value='true'></input>
            </th>
          </tr>
          <tr style={{backgroundColor:'#E6E7E9'}}>
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>
            15. Irritable behavior, angry outbursts, or acting aggressively?    
       </td>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question15"   checked={question15==0?true:false} value="true"></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question15"  checked={question15==1?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question15"  checked={question15==2?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question15"  checked={question15==3?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question15"  checked={question15==4?true:false} value='true'></input>
            </th>
          </tr>
          <tr>
            <td style={{borderRight:'1px solid black'}}>
            16. Taking too many risks or doing things that could cause you 
harm?      </td>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question16"   checked={question16==0?true:false} value="true"></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question16"  checked={question16==1?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question16"  checked={question16==2?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question16"  checked={question16==3?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question16"  checked={question16==4?true:false} value='true'></input>
            </th>
          </tr>
          <tr style={{backgroundColor:'#E6E7E9'}}>
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>
            17. Being “superalert” or watchful or on guard?      </td>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question17"   checked={question17==0?true:false} value="true"></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question17"  checked={question17==1?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question17"  checked={question17==2?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question17"  checked={question17==3?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question17"  checked={question17==4?true:false} value='true'></input>
            </th>
          </tr>
          <tr>
            <td style={{borderRight:'1px solid black'}}>
            18. Feeling jumpy or easily startled?      </td>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question18"   checked={question18==0?true:false} value="true"></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question18"  checked={question18==1?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question18"  checked={question18==2?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question18"  checked={question18==3?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question18"  checked={question18==4?true:false} value='true'></input>
            </th>
          </tr>
          <tr style={{backgroundColor:'#E6E7E9'}}>
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>
            19. Having difficulty concentrating?    </td>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question19"   checked={question19==0?true:false} value="true"></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question19"  checked={question19==1?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question19"  checked={question19==2?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question19"  checked={question19==3?true:false} value='true'></input>
            </th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question19"  checked={question19==4?true:false} value='true'></input>
            </th>
          </tr>
          <tr>
            <td style={{borderRight:'1px solid black'}}>
            20. Trouble falling or staying asleep?      </td>
            <th style={{  textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question20"   checked={question20==0?true:false} value="true"></input>
            </th>
            <th style={{  textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question20"  checked={question20==1?true:false} value='true'></input>
            </th>
            <th style={{  textAlign:'center',borderRight:'1px solid black'}}  scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question20"  checked={question20==2?true:false} value='true'></input>
            </th>
            <th style={{  textAlign:'center',borderRight:'1px solid black'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question20"  checked={question20==3?true:false} value='true'></input>
            </th>
            <th style={{  textAlign:'center'}} scope="col" colSpan={2}>
              <input class="form-check-input" type="radio" name="question20"  checked={question20==4?true:false} value='true'></input>
            </th>
          </tr>
        </tbody>
      </table>
      
      <Grid>
        <Typography style={{fontFamily:'serif',fontSize:'12px'}}>
           PCL-5 (11 April 2018)           <span style={{textAlign:'center',marginLeft:'150px'}}>National Center for PTSD   </span>       <br />
         
         
        </Typography>
      </Grid>
       
    </Grid>
  )
}
