import React,{useEffect,useState} from 'react'
import { DataGrid, GridColDef, GridValueGetterParams  } from "@mui/x-data-grid";
import { Button, Grid, Modal } from "@mui/material";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DialogTitle from "@material-ui/core/DialogTitle";
import SuiTypography from "components/SuiTypography";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import InviteIcon from 'assets/invite.png'
import EditIcon from 'assets/edit.png'
import { useAlert } from 'react-alert'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Backdrop } from '@mui/material';

import Alert from '@mui/material/Alert';
import axios from 'axios'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { useLocation, Link } from 'react-router-dom';
import AppointmentCreate from "../AppointmentCreate";
import NewAppointments from './NewAppointments';
import { confirmAlert } from 'react-confirm-alert'
import DeleteIcon from '@mui/icons-material/Delete'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import SuiButton from 'components/SuiButton';
const Appointments = (props) => {
  console.log(props,'props main kia h Appointments')
  const NO_ROWS_LABEL = "No Record found";
  const [NoRowsLabel, setNoRowsLabel] = useState(NO_ROWS_LABEL);
    const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
    const location = useLocation()
    const _patientId = props.patientId || (location.state ? location.state.patientId : null)
    // props.patientId || (location.state ? location.state.patientId : null);
  localStorage.setItem(_patientId, 'patientId');
  const patientName = props.patientName || (location.state ? location.state.patientName : null)
  const patientLastName = props.patientLastName || (location.state ? location.state.patientLastName : null)
  const patientLastName1 = props.currentName || (location.state ? location.state.currentName : null)

console.log(patientName,'patientLastName1,,')
  const alert = useAlert()

    const readOnly=props.readOnly
    const headers = {
      Authorization: auth,
    }
    // const _patientId = props.patientId
    // const _patientId = localStorage.getItem('_patientId')
    const [rows, setRows] = React.useState(null)

  
    const [timeFrom, setTimeFrom] = useState("09:00");
    const [timeTo, settimeTo] = useState("17:00");
    const [duration, setDuration] = useState("");
   
    const [endTime, setEndTime] = useState("");
    const [startTime, setStartTime] = useState("");
    const [callingFrom , setcallingFrom] = useState("patientAppointment")
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pId, setidpatientId] = useState(false);

    const [openAlert, setOpenAlert] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);

  const handleClickOpen = (cell) => {
    setSelectedCell(cell);
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setSelectedCell(null);
    setOpenAlert(false);
  };

  const handleDeleteById = () => {
    if (!selectedCell) {
      // Handle error or show a message as needed
      console.error('No cell selected for deletion.');
      return;
    }

    const postData = {
      id: selectedCell.row.id,
      deleted: true,
      firstName: selectedCell.row.firstName,
      lastName: selectedCell.row.lastName,
      // userId: selectedCell.row.userId,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/PatientAppointment/AddPatientAppointment`, postData, {
        headers,
      })
      .then((response) => { alert.success('Record Deleted successfully.')
        // Handle success, e.g., show a success message
        console.log('Record Deleted successfully.');
        setRefresh(!refresh);
        setLoading(false);
      })
      .catch((error) => {
        // Handle error, e.g., show an error message
        console.error('Error:', error);
        setLoading(false);
      });

    handleCloseAlert();
  };
  const handleDeleteConfirm = (cell) => {
    confirmAlert({
      message: 'Do you want to delete the record.',
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: 'overlay-custom-class-name',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const postData = {
              id: cell.row.id,
              deleted: true,
              firstName: cell.row.firstName,
              lastName: cell.row.lastName,
              // userId: cell.row.userId,
            };
  
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/PatientAppointment/AddPatientAppointment`,
                postData,
                {
                  headers,
                }
              )
              .then((response) => { alert.success('Record Deleted successfully.')
                setRefresh(!refresh);
                // setOpenNote(false)
                // console.log('response.data: ', response.data)
              })
              .catch((error) => {
                console.error('There was an error!', error);
              });
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };
  {props.datamining === 'dataMinings' ? (
    ''
  ):''}

    const columns = [
      {
        field: "patientId",
        headerName: "ID",
        width: 80,
        headerClassName: "super-app-theme--header",
      },
      {
        field: "appointmentDate",
        headerClassName: "super-app-theme--header",
        headerName: "Appointment Date",
        sortable: true,
        width: 155,
    
      },
      {
        field: "startTime",
        headerClassName: "super-app-theme--header",
        headerName: "Start Time",
        sortable: true,
        width: 110,
     
      },
      {
        field: "endTime",
        headerClassName: "super-app-theme--header",
        headerName: "End Time",
        sortable: true,
        width: 110,
      },
      {
        field: "duration",
        headerClassName: "super-app-theme--header",
        headerName: "Duration",
        sortable: true,
        width: 102,
      },
      {
        field: "providerName",
        headerClassName: "super-app-theme--header",
        headerName: "Provider Name",
        sortable: true,
        width: 200,
      },
    
      {
        field: "appointmentStatus",
        headerClassName: "super-app-theme--header",
        headerName: "Status",
        sortable: true,
        width: 120,
      },
     
      {
        field: 'Edit',
        headerClassName: 'super-app-theme--header',
        headerName: 'Edit',
        sortable: false,
        width: 120,
        renderCell: (cellValues) => (
          <SuiButton
            size="small"
            variant="gradient"
            color="warning"
   
            onClick={(event) => {
          
              setOpen(true)
              setidpatientId(cellValues.row.id);
            }}
            style={{ width: '70px' }}
            startIcon={
              <img
                src={InviteIcon}
                style={{ marginTop: '-3px', marginRight: '-6px' }}
                alt="Invite Icon"
              />
            }
          >
            Edit
          </SuiButton>
        ),
      },
      {
        field: 'delete',
        headerClassName: 'super-app-theme--header',
        headerName: 'Action',
        sortable: true,
        width: 100,
        renderCell: (cell) => (
          <>
 <>
                <Button onClick={() => handleClickOpen(cell)}>
                  <DeleteIcon
                    color="red"
                    style={{
                      marginRight: '5px',
                      float: 'right',
                      marginTop: '5px',
                      color:'red'
                    }}
                  />
                </Button>
                <Dialog
                  open={openAlert}
                  onClose={handleCloseAlert}
                  aria-labelledby="responsive-dialog-title"
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    invisible: true,
                  }}
                  PaperProps={{
                    style: {background:'#cef5f0',
                    boxShadow:'none',
                    borderRadius:'8px'
                    },
                 }}                >





<div style={{borderRadius:'8px'}}>
   
            <ErrorOutlineIcon style={{marginLeft:'125px',fontSize:'100px',color:'orange'}}></ErrorOutlineIcon>
                <DialogTitle>{"Do you want to delete the record?"}</DialogTitle>
                <h5 style={{textAlign:'center'}}>This action cannot be undone.</h5>
                <DialogContent>
                    <DialogContentText>
                    <Grid style={{marginLeft:'90px'}}>
                    <Button  onClick={handleDeleteById} style={{backgroundColor:'red',color:'white'}}>YES</Button>
                    <Button  onClick={handleCloseAlert} style={{backgroundColor:'blue',color:'white',marginLeft:'1rem'}}>NO</Button>
                    </Grid>
                    </DialogContentText>
                </DialogContent>
               
                
      
    
    
    </div>


                
                </Dialog>
              </> 
              {/* oooooo */}
                {/* <Button onClick={() => handleClickOpen(cell)}>
                  <DeleteIcon
                    color="red"
                    style={{
                      marginRight: '5px',
                      float: 'right',
                      marginTop: '5px',
                    }}
                  />
                </Button>
                <Dialog
                  open={openAlert}
                  onClose={handleCloseAlert}
                  aria-labelledby="responsive-dialog-title"
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    invisible: true,
                  }}
                  PaperProps={{
                    style: {background:'#cef5f0',
                    boxShadow:'none',
                    borderRadius:'8px'
                    },
                 }}                >





<div style={{borderRadius:'8px'}}>
   
            <ErrorOutlineIcon style={{marginLeft:'125px',fontSize:'100px',color:'orange'}}></ErrorOutlineIcon>
                <DialogTitle>{"Do you want to delete the record?"}</DialogTitle>
                <h5 style={{textAlign:'center'}}>This action cannot be undone.</h5>
                <DialogContent>
                    <DialogContentText>
                    <Grid style={{marginLeft:'90px'}}>
                    <Button  onClick={handleDeleteById} style={{backgroundColor:'red',color:'white'}}>YES</Button>
                    <Button  onClick={handleCloseAlert} style={{backgroundColor:'blue',color:'white',marginLeft:'1rem'}}>NO</Button>
                    </Grid>
                    </DialogContentText>
                </DialogContent>
               
                
      
    
    
    </div>


                
                </Dialog> */}
             

          {/* 1111 */}
            {/* {props.datamining === 'dataMinings' ? (
              <>
                <Button onClick={() => handleClickOpen(cell)}>
                  <DeleteIcon
                    color="black"
                    style={{
                      marginRight: '5px',
                      float: 'right',
                      marginTop: '5px',
                    }}
                  />
                </Button>
                <Dialog
                  open={openAlert}
                  onClose={handleCloseAlert}
                  aria-labelledby="responsive-dialog-title"
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    invisible: true,
                  }}
                  PaperProps={{
                    style: {background:'#cef5f0',
                    boxShadow:'none',
                    borderRadius:'8px'
                    },
                 }}                >





<div style={{borderRadius:'8px'}}>
   
            <ErrorOutlineIcon style={{marginLeft:'125px',fontSize:'100px',color:'orange'}}></ErrorOutlineIcon>
                <DialogTitle>{"Do you want to delete the record?"}</DialogTitle>
                <h5 style={{textAlign:'center'}}>This action cannot be undone.ss</h5>
                <DialogContent>
                    <DialogContentText>
                    <Grid style={{marginLeft:'90px'}}>
                    <Button  onClick={handleDeleteById} style={{backgroundColor:'red',color:'white'}}>YES</Button>
                    <Button  onClick={handleCloseAlert} style={{backgroundColor:'blue',color:'white',marginLeft:'1rem'}}>NO</Button>
                    </Grid>
                    </DialogContentText>
                </DialogContent>
               
                
      
    
    
    </div>


                
                </Dialog>
              </>
            ) : (
              <DeleteIcon
                style={{
                  marginRight: '5px',
                  float: 'right',
                  marginTop: '5px',
                }}
                color="black"
                onClick={() => handleDeleteConfirm(cell)}
              />
            )} */}
          </>
        ),
      }
      ,
    ];
    
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/PatientAppointment/PatientAppointmentList?patientId=${_patientId}`,
            { headers}
          );
          setRows(response.data);
        
          setDuration(response.data[0].duration);
        } catch (error) {
          console.error('There was an error!', error);
        }
      };
  
      fetchData();
    }, [_patientId , refresh]); 
    const style = {
      position: 'absolute' ,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      borderRadius:'8px'
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  return (
    <div>
      <Grid container xs={12}>
      <Grid item xs={2} lg={2}>
      <Typography component="label" style={{ fontWeight: 500 }}             sx={{ mb: 5, width: '100%' }}
> 
      Appointments
                  </Typography>
      </Grid>

      <Grid item xs={8} lg={8}></Grid>
      <Grid item xs={2} lg={2} >
    
      {/* <Button
            sx={{ mb: 5, width: '100%' }}
            // onClick={() => {
            //   handleOpen();
            //   setidpatientId('');
            // }}
            
            variant="gradient"
            // disabled={readOnly==true?true:false}
            style={{
              backgroundColor: '#FCBE13',
              color: 'black',
              fontSize: '14px',
            }}
          >
            <AddOutlinedIcon
              fontSize="small"
              style={{
                color: '#1a82ad',
                paddingRight: '4px',
                paddingBottom: '2px',
              }}
            ></AddOutlinedIcon>
            Add New
          </Button> */}
      </Grid>
       
      <Modal
        open={open} 
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} menuPortalTarget={document.body} >
   <AppointmentCreate menuPortalTarget={document.body}
   patientName={patientName}
   patientLastName={patientLastName}
   getRefresh = {setRefresh} 
   refresh = {setRefresh}
   canclemodel={setOpen}
   timeFrom={timeFrom}
   datamining={props.datamining}
   duration ={duration}
   timeTo={timeTo}
   pId={pId}
   patientIds={_patientId}
   sTime={startTime}
   eTime={endTime}
   callingFrom = {callingFrom}
/> 
        </Box>
      </Modal>
      <Grid item xs={12} lg={12} style={{marginTop:'-1rem'}}>
            <div style={{ height: 400, width: "100%" }}>
            { loading?'Loading...':
             rows === null || rows.length === 0 ? (
                <Typography>No Record Found</Typography>
              ) : (
                <Box sx={{ height: 400, width: '100%',background:'white'  ,border:'2px solid #04C4F4',borderRadius:'8px'}}>
               <DataGrid
  rows={rows}
  columns={columns}
  pageSize={5}  // Use pageSize instead of paginationModel.pageSize
  // checkboxSelection
  disableRowSelectionOnClick
/>

              </Box>
              )}
            </div>
          </Grid>
          </Grid>
    </div>
  )
}

export default Appointments