/* eslint-disable object-shorthand */
// @mui material components
import Card from '@mui/material/Card'

// Soft UI Dashboard React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiInput from 'components/SuiInput'
import SuiButton from 'components/SuiButton'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import { useAlert } from 'react-alert'
import Autocomplete from '@mui/material/Autocomplete'
import SaveIcon from 'assets/save.png'
import CancelIcon from 'assets/cancel.png'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import input from '@mui/material/Input'
import { Grid, Snackbar, TextField, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useReducer, useState, Fragment } from 'react'
import { createBrowserHistory } from 'history'
import Checkbox from '@mui/material/Checkbox'
import { useLocation } from 'react-router-dom'
import ReactSelect from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InputMask from 'react-input-mask'
import Button from '@mui/material/Button'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import CircularProgress from '@mui/material/CircularProgress'

const history = createBrowserHistory({ forceRefresh: true })

const fields = [
  'city',
  'createdBy',
  'createdDate',
  'id',
  'deleted',
  'state',
  'updatedBy',
  'updatedDate',
  'zipCode',
  'taxId',
  'phoneNo',
  'fax',
  'website',
  'emailAddress',
  'practiceName',
  'orgBusiness',
  'address',
  'billingEmailAddress',
]
const defValues = {
  practiceName: '',
  orgBusiness: '',
  taxId: '',
  address: '',
  phoneNo: '',
  fax: '',
  emailAddress: '',
  city: '',
  website: '',
  createdBy: '',
  createdDate: '',
  id: 0,
  deleted: '',
  state: '',
  updatedBy: '',
  updatedDate: '',
  zipCode: '',
  billingEmailAddress: '',
}
function handleCancel() {
  history.push('/practices')
}
function PracticesRegistration() {
  const location = useLocation()
  const alert = useAlert()
  const [submitting, setSubmitting] = useState(false)
  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }
  const zipCodeListStyle = {
    cursor: 'pointer',
    '&:hover': {
      background: '#3b3b3b !important',
      color: 'rgba(5, 152, 236, 0.637) !important',
    },
  }
  const validationSchema = Yup.object({
    practiceName: Yup.string().required('Name is required'),
  })
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  })
  const onSubmit = (data) => {
    setSubmitting(true)
    const postData = data
    console.warn('Data: ', postData)
    if (location.state.userId !== undefined && location.state.userId != null)
      postData.Id = location.state.userId
    else postData.Id = 0
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Practices/addPractice`,
        postData,
        { headers }
      )
      .then((response) => {
        setSubmitting(false)
        alert.success('Record saved successfully.', {
          type: 'success',
          onClose: () => {
            history.push('/practices')
          },
        })
      })
      .catch((error) => {
        setSubmitting(false)
        alert.success(`Error ${error.message}`, {
          type: 'error',
        })
      })
  }
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    let isComponentMounted = true
    const fetchData = async () =>
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/Practices/getPracticeById?id=${
            location.state.userId !== undefined ? location.state.userId : 0
          }`,
          { headers }
        )
        .then((response) => {
          console.log('response.data: ', response.data)
          if (isComponentMounted) {
            // setUsersData(response.data);
            fields.forEach((field) => setValue(field, response.data[field]))
            /*  const petList = Object.entries(response.data).map(([key, value]) => {
              console.log("Key: ", key, value);
              register(`${key}`, `${value}`);
              return [key, value];
            }); */
          }
          setPhone(response.data.phoneNo)
          setFax(response.data.fax)
        })
        .catch((error) => {
          console.error('There was an error!', error)
        })
    fetchData()
    return () => {
      isComponentMounted = false
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [])
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const loading = open && options.length === 0
  const [zipCodeList, setZipCodeList] = React.useState([])
  const [isZipCode, setIsZipCode] = React.useState(false)

  const onChangeHandle = async (value) => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    // console.log(value)

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Settings/Searchcitystate?zipcode=${value}`,
        { headers }
      )
      .then((response) => {
        setZipCodeList(response.data)
        setIsZipCode(true)
        setinsuranceNames(response.data)
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  const styles = (theme) => ({
    multilineColor: {
      color: 'red',
    },
  })
  const [phone, setPhone] = React.useState()
  const [Fax, setFax] = React.useState()
  return (
    <>
      {submitting ? (
        <CircularProgress
          style={{
            width: '50px',
            height: '50px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginLeft: '-25px',
            marginTop: '-25px',
            zIndex: 1000,
            color: '#FCBE13',
          }}
        />
      ) : null}
      <Grid
        container
        spacing={1}
        alignContent="center"
        justifyContent="center"
        style={{
          paddingTop: '10px',
          paddingLeft: '150px',
          paddingRight: '150px',
        }}
      >
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Practice Name
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Org Business
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Tax Id
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter Full Name"
            name="fullName"
            label="fullName"
            fullWidth
            margin="dense"
            {...register('practiceName')}
            error={errors.practiceName}
          />
           <p style={{ color: "red", fontSize:"14px" }}>{errors.practiceName?.message}</p>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter orgBusiness"
            name="orgBusiness"
            label="orgBusiness"
            fullWidth
            margin="dense"
            {...register('orgBusiness')}
            error={errors.orgBusiness}
          />
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter TaxId"
            name="taxId"
            label="taxId"
            fullWidth
            margin="dense"
            {...register('taxId')}
            error={errors.taxId}
          />
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Address
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Phone No
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Fax
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter Address"
            name="address"
            label="address"
            fullWidth
            margin="dense"
            {...register('address')}
            error={errors.address}
          />
        </Grid>

        <Grid item xs={4} style={{ width: '300px' }}>
          <InputMask
            mask="+1 999-999-9999"
            disabled={false}
            value={phone}
            style={{ height: '30px' }}
            onChange={(e) => {
              setValue('phoneNo', e.target.value)
              setPhone(e.target.value)
            }}
          >
            {() => (
              <SuiInput
                id="outlined-basic"
                fullWidth
                margin="dense"
                placeholder="Enter phoneNo"
              />
            )}
          </InputMask>
          {/* <SuiInput
            placeholder="Please enter PhoneNo"
            name="phoneNo"
            label="phoneNo"
            fullWidth
            margin="dense"
            {...register('phoneNo')}
            error={errors.phoneNo}
          /> */}
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <InputMask
            mask=" 999-9999999"
            disabled={false}
            value={Fax}
            style={{ height: '30px' }}
            onChange={(e) => {
              setValue('fax', e.target.value)
              setFax(e.target.value)
            }}
          >
            {() => (
              <SuiInput
                id="outlined-basic"
                fullWidth
                margin="dense"
                placeholder="Enter Fax"
              />
            )}
          </InputMask>
          {/* <SuiInput
            placeholder="Please enter Fax"
            name="fax"
            label="fax"
            fullWidth
            margin="dense"
            {...register('fax')}
            error={errors.fax}
          /> */}
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Zip Code
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            City
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            State
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            type="input"
            placeholder="ZipCode"
            {...register('zipCode')}
            onKeyUp={(ev) => {
              // dont fire API if the user delete or not entered anything
              if (ev.target.value !== '' || ev.target.value !== null) {
                onChangeHandle(ev.target.value)
              }
            }}
          />
          {isZipCode ? (
            <div
              className="col-xs-3"
              style={{
                padding: '14px 16px',
                fontSize: '0.875rem',
                color: '#67748e',
                borderRadius: '5px',
                background: 'white',
                textAlign: 'justify',
                textJustify: 'inter-word',
                position: 'absolute',
                zIndex: '99',
                width: '25%',
                border: '1px solid #6cb5f9',
              }}
            >
              {zipCodeList.map((s, index) => (
                <p
                  // attribZip={s.zipCode}
                  // attribState={s.state}
                  // attribCity={s.city}
                  // attribIndex={index}

                  style={zipCodeListStyle}
                  onClick={(ev) => {
                    console.log('evvv', ev)
                    // var sel=ev.target.attributes['attribIndex']
                    console.log('Selected: ', zipCodeList[index])
                    setValue('zipCode', zipCodeList[index].zipCode)
                    setValue('city', zipCodeList[index].city)
                    setValue('state', zipCodeList[index].state)
                    setZipCodeList([])
                    setIsZipCode(false)
                  }}
                  onBlur={(ev) => {
                    setZipCodeList([])
                    setIsZipCode(false)
                  }}
                  onChange={(ev) => {
                    setZipCodeList([])
                    setIsZipCode(false)
                  }}
                >
                  {s.zipCode} City: {s.city} State: {s.state}
                </p>
              ))}
            </div>
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            type="input"
            placeholder="City"
            name="city"
            {...register('city')}
          />
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter State"
            name="state"
            label="state"
            fullWidth
            margin="dense"
            {...register('state')}
            error={errors.state}
          />
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Email Address
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Billing Email Address
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Website
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter EmailAddress"
            name="emailAddress"
            label="emailAddress"
            fullWidth
            margin="dense"
            {...register('emailAddress')}
            error={errors.emailAddress}
          />
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter BillingEmailAddress"
            name="BillingEmailAddress"
            label="BillingEmailAddress"
            fullWidth
            margin="dense"
            {...register('billingEmailAddress')}
            error={errors.billingEmailAddress}
          />
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            style={{ width: '300px' }}
            type="input"
            placeholder="Enter Website"
            name="website"
            {...register('website')}
          />
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}>
          {/* <SuiTypography component="label" variant="caption"fontSize="15px">
            Active
          </SuiTypography> */}
        </Grid>
        <Grid item xs={1} style={{ width: '300px' }}>
          {/* <SuiInput
            type="checkbox"
            margin="dense"
            {...register('deleted')}
            error={errors.deleted}
          /> */}
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}></Grid>
        <Grid item xs={4} style={{ width: '300px' }}></Grid>
        <Grid item xs={12} style={{ width: '300px' }}>
          <Accordion
            style={{
              marginBottom: '10px',
              border: '1px solid rgba(5, 152, 236, 0.637)',
              borderRadius: '20px',
              marginTop: '10px',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Audit Log</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={4} xl={4}>
                    <Typography
                     fontSize="15px"
                      style={{ fontSize: '14px', width: '300px' }}
                    >
                      Updated By
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4} xl={4}>
                    <Typography
                     fontSize="15px"
                      style={{ fontSize: '14px', width: '300px' }}
                    >
                      Updated Date
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4} xl={4}>
                    <Typography
                     fontSize="15px"
                      style={{ fontSize: '14px', width: '300px' }}
                    >
                      CreatedBy
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4} xl={4} style={{ width: '300px' }}>
                    <SuiInput
                      disabled
                      name="updatedBy"
                      readOnly="true"
                      fullWidth
                      margin="dense"
                      {...register('updatedBy')}
                      error={errors.updatedBy}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4} xl={4} style={{ width: '300px' }}>
                    <SuiInput
                      disabled
                      type="input"
                      readOnly="true"
                      fullWidth
                      margin="dense"
                      {...register('updatedDate')}
                      error={errors.updatedDate}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4} xl={4} style={{ width: '300px' }}>
                    <SuiInput
                      disabled
                      name="createdBy"
                      readOnly="true"
                      fullWidth
                      margin="dense"
                      {...register('createdBy')}
                      error={errors.createdBy}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} xl={12}>
                    <Typography
                     fontSize="15px"
                      style={{ fontSize: '14px', width: '300px' }}
                    >
                      Created Date
                    </Typography>
                  </Grid>

                  <Grid item xs={6} sm={3} xl={3} style={{ width: '300px' }}>
                    <SuiInput
                      disabled
                      type="input"
                      readOnly="true"
                      fullWidth
                      margin="dense"
                      {...register('createdDate')}
                      error={errors.createdDate}
                    />
                  </Grid>
                </Grid>
              </div>
            </AccordionDetails>
          </Accordion>
          <Grid item xs={12} lg={12} sm={12}>
          <Button
              onClick={handleCancel}
              variant="gradient"
              disabled={submitting}
              style={{
                marginTop: '30px',
                float: 'right',
                marginLeft: '10px',
                width: '90px',
                height: '35px',
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
              }}

            >
              <CancelOutlinedIcon
                fontSize="medium"
                style={{ color: 'red', paddingRight: '5px' }}
              ></CancelOutlinedIcon>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              variant="gradient"
              disabled={submitting}
              style={{
                marginTop: '30px',
                float: 'right',
                marginLeft: '10px',
                width: '80px',
                height: '35px',
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
              }}

            >
              <SaveOutlinedIcon
                fontSize="medium"
                style={{
                  color: '#1a82ad',
                  paddingRight: '5px',
                  paddingBottom: '2px',
                }}
              ></SaveOutlinedIcon>
              Save
            </Button>

          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default PracticesRegistration
