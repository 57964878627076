import {
    CircularProgress,
    Grid,
    InputAdornment,
    Snackbar,
    TextField,
    Typography,
} from '@mui/material'
import axios from 'axios'
import SuiSelect from 'components/SuiSelect'

import React, { useEffect, useReducer, useState, Fragment, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { TabPanel, TabContext, TabList } from '@mui/lab'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import { useAlert } from 'react-alert'
import { DataGrid } from '@mui/x-data-grid'
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import input from '@mui/material/Input'
import Moment from 'moment'
import Avatar from '@mui/material/Avatar'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'

const SocialStatus = (props) => {

    const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
    const headers = {
      Authorization: auth,
    }
    const alert = useAlert()
    const patientId=props.patientId
    const validationSchema = Yup.object({
        occupation: Yup.string().required("occupation is required"),
        tobaccoUse: Yup.string().required("tobaccoUse is required"),
        exerciseFrequency: Yup.string().required("exerciseFrequency is required"),


      });
const fields =[
    "id",
    "patientId",
    "practiceId",
    "isActive",
    "occupation",
    "maritalStatus",
    "livingSituation",
    "exerciseFrequency",
    "dietHabits",
    "tobaccoUse",
    "alcoholUse",
    "drugUse",
    "createdDate",
    "updatedDate",
    "createdBy",
    "updatedBy"
]
    const defValues = {
        id: 0,
  patientId: 0,
  practiceId: 0,
  isActive: '',
  occupation:'',
  maritalStatus:'',
  livingSituation:'',
  exerciseFrequency:'',
  dietHabits:'',
  tobaccoUse:'',
  alcoholUse:'',
  drugUse:'',
  createdDate:'',
  updatedDate:'',
  createdBy:'',
  updatedBy:''
    }
    const {
        register,
        control,
        handleSubmit,
        setValue,
        reset,
        getValues,
        formState: { errors },
      } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: false,
        shouldUseNativeValidation: false,
        delayError: undefined,
        resolver: yupResolver(validationSchema),
        defaultValues: defValues,
      });
      const [refresh, setRefresh] = React.useState(false)
      const [submitting, setSubmitting] = useState(false)

      const onSubmit = (data) => {   
        setSubmitting(true)
    
        const postData = {
          ...data,
          patientId:props.patientId
        }
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/PatientMedicalNotes/AddSocialstatus`,
            postData,
            { headers }
          )
          .then((response) => {
            setRefresh(!refresh)
            setSubmitting(false)
    
             alert.success('Record saved successfully.', {
              type: 'success',
              // onClose: () => {
              //   setOpenNote(false)
              //   setSubmitting(false)
              // },
            })
          })
          .catch((error) => {
            setSubmitting(false)
            alert.success(`Error ${error.message}`, {
              type: 'error',
            })
          })
      }

      useEffect(() => {
        // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    
        axios
          .get
          (`${process.env.REACT_APP_API_URL}/PatientMedicalNotes/getSocialstatusByPatientId?patientId=${props.patientId}`, { headers })
          .then(response => { 
            fields.forEach((field) => setValue(field, response.data[field]));
          })
          .catch(error => {
            console.error('There was an error!', error)
          })
      }, [])
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                        Occupation
                    </Typography>
                    <Controller
                        name="occupation"
                        {...register("occupation")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // isClearable
                                size="small"
                                placeholder="enter occupation"
                                {...field}
                                fullWidth
                                error={errors.occupation}
                                />
                              )}
                            />
                            <p style={{ color: 'red', fontSize: '14px' }}>{errors.occupation?.message}</p>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                        Martial Status
                    </Typography>
                   
                     <Controller
            name="maritalStatus"
            {...register('maritalStatus')}
            control={control}
            render={({ field }) => (
              <SuiSelect
                {...field}
                value={{ label: getValues('maritalStatus') }}
                onChange={(value) => setValue('maritalStatus', value.label)}
                options={[
                  { value: 'Single', label: 'Single' },
                  { value: 'Married', label: 'Married' },
                  { value: 'Widowed', label: 'Widowed' },
                  { value: 'Separated', label: 'Separated' },
                  { value: 'Divorced', label: 'Divorced' },
                ]}
              />
            )}
          />
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                        Living Situation
                    </Typography>
                    <Controller
                        name="livingSituation"
                        {...register("livingSituation")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // isClearable
                                size="small"
                                placeholder="enter living Situation"
                                {...field}
                                fullWidth

                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                        Exercise Frequency
                    </Typography>
                    <Controller
                        name="exerciseFrequency"
                        {...register("exerciseFrequency")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // isClearable
                                size="small"
                                placeholder="enter exercise frequency"
                                {...field}
                                fullWidth
                                error={errors.exerciseFrequency}
                                />
                              )}
                            />
                            <p style={{ color: 'red', fontSize: '14px' }}>{errors.exerciseFrequency?.message}</p>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                        Diet Habits
                    </Typography>
                    <Controller
                        name="dietHabits"
                        {...register("dietHabits")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // isClearable
                                size="small"
                                placeholder="enter diet habits"
                                {...field}
                                fullWidth

                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                        Tobacco Use
                    </Typography>
                    <Controller
                        name="tobaccoUse"
                        {...register("tobaccoUse")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // isClearable
                                size="small"
                                placeholder="enter tobacco use"
                                {...field}
                                fullWidth

                                error={errors.tobaccoUse}
                                />
                              )}
                            />
                            <p style={{ color: 'red', fontSize: '14px' }}>{errors.tobaccoUse?.message}</p>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                        Alcohol Use
                    </Typography>
                    <Controller
                        name="alcoholUse"
                        {...register("alcoholUse")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // isClearable
                                size="small"
                                placeholder="enter alcohol use"
                                {...field}
                                fullWidth

                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                        Druag Use
                    </Typography>
                    <Controller
                        name="drugUse"
                        {...register("drugUse")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // isClearable
                                size="small"
                                placeholder="enter drug use"
                                {...field}
                                fullWidth

                            />
                        )}
                    />
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button
                                      onClick={handleSubmit(onSubmit)}
                            variant="gradient"
                            style={{
                                marginTop: '10px',
                                marginBottom: '10px',
                                float: 'right',
                                marginRight: '5px',
                                marginLeft: 'auto',
                                width: '80px',
                                height: '35px',
                                backgroundColor: '#FCBE13',
                                color: 'black',
                                fontSize: '14px',
                            }}
                        >
                            {/* <SaveIcon
                        fontSize="medium"
                        style={{ color: '#0a9ff0', paddingRight: '5px' }}
                      ></SaveIcon> */}
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}

export default SocialStatus
