// @mui material components
import Card from '@mui/material/Card'
import CircularProgress from "@mui/material/CircularProgress";
// Soft UI Dashboard React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiInput from 'components/SuiInput'
import SuiButton from 'components/SuiButton'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import { useAlert } from 'react-alert'
import Timelogeidth from './Timelogeidth'
import Timelogeidth1 from './Timelogeidth1'

import SearchIcon from 'assets/search.png'
import ClearIcon from 'assets/clear.png'
import CloseIcon from '@mui/icons-material/Close';

import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import input from '@mui/material/Input'
import { Grid, Snackbar, TextField } from '@mui/material'
import ModeEditIcon from '@mui/icons-material/Edit'
import axios from 'axios'
import React, { useEffect, useReducer, useState, Fragment } from 'react'
import { createBrowserHistory } from 'history'
import Checkbox from '@mui/material/Checkbox'
import { useLocation } from 'react-router-dom'
import ReactSelect from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { TabPanel, TabContext, TabList } from '@mui/lab'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import PermIdentity from '@mui/icons-material/PermIdentity'
import WorkOutline from '@mui/icons-material/WorkOutline'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import { DataGrid } from '@mui/x-data-grid'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import customstyle from 'assets/customStyle.css'
import { Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { confirmAlert } from 'react-confirm-alert'
import Button from '@mui/material/Button'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
// import PatientRegistration from './PatientRegistration'
import Demographics from './Demographics'
import Modal from '@mui/material/Modal'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DialogTitle from "@material-ui/core/DialogTitle";
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import SuiSelect from 'components/SuiSelect'

import { Backdrop } from '@mui/material';

var providersRedux = []

const history = createBrowserHistory({ forceRefresh: true })
const defValues = {
  Id: '',
  startTime: '',
  endTime: '',
  userName: '',
}
function Reports(props) {
  const location = useLocation()
  const [tabValue, settabValue] = React.useState(0)
  const alert = useAlert()
  const [submitting, setSubmitting] = useState(false)
  const [patientFilter, setPatientFilter] = useState(null)
  const [userFilter, setUserFilter] = useState(null)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [timeLogRows, setTimeLogRows] = useState(null)
  const [timeLogRows1, setTimeLogRows1] = useState(null)
  const [Report1, setReport1] = useState(false)
  const[ProviderLastName,setProviderLastName] =useState('')
  const[ProviderFirstName,setProviderFirstName] =useState('')
  const[totalTimeSpent,settotalTimeSpent] =useState('')

  const [refresh, setRefresh] = useState(false)
  const [fpatientId, setFpatientId] = useState('')
  const [flastName, setFlastName] = useState('')
  const [ffirstName, setFfirstName] = useState('')
  const [fpatientName, setFpatientName] = useState('')
  const [userName, setFuserName] = useState('')
  
  const [fuserId, setFuserId] = useState('')
  const [fstartTime, setFstartTime] = useState('')
  const [fdate, setFdate] = useState('')

  const [fendTime, setFendTime] = useState('')
  const [rows1, setRows1] = useState('')
  const [openNote, setopenNote] = useState(true)
  const [openNote1, setopenNote1] = useState(true)

  const [openEidthReport, setOpenEidthReport] = useState(false)
  const [openEidthReport1, setOpenEidthReport1] = useState(false)

  const [PatientTimeLogSummaryReport, setPatientTimeLogSummaryReport] = useState(null)
  const [ProviderReport, setGetProviderReport] = useState(null)
  const [Activepage, setActivepage] = useState(1)
  const [Activepage1, setActivepage1] = useState(1)
  const [Size, setSize] = useState(5)

  const [year, setyear] = useState('')
  const [month, setmonth] = useState('')
  const [error, seterror] = useState('')
  const [error1, seterror1] = useState('')
  const [NoRecord, setNoRecord] = useState('Please Apply Filter')
  const [_providerId, setProviderId] = React.useState('');
  const [msggroupBy , setMSggroupBy] = React.useState('');
  const [groupBy , setGroupValue] = React.useState('');
  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }
  function applyRefresh(ref) {
    console.error('There was an error!')
    //  setRefresh(!refresh)
  }
  const providersRedx = useSelector(
    (state) => (state.providers.loading ? [] : state.providers.NAMES),
    shallowEqual
  )
  
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    providersRedux = []
    providersRedux.push({
      label:'All',
      value: '-1',
    })
    providersRedx.forEach((field) => {
      providersRedux.push({
        label: field.name,
        value: field.id,
      })
    })
  }, [providersRedx])
  const handleChange = (event, newValue) => {
    settabValue(newValue)
  }
  const onFilterClear = (event) => {
 
setSubmitting(true)

    setTimeLogRows(null)
    setTimeLogRows1(null)
    setFlastName('')
    setFfirstName('')
    setFuserName('')
    // setproviderId('')

    setFdate('')
    setFstartTime('')
    setFendTime('')

    const postData = {
      // lastname: flastName,
      // firstname: ffirstName,
      // startTime: fstartTime,
      // endTime: fendTime,
      // userName:userName
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientTimeLog/Report`,
        postData,
        { headers }
      )
      .then((response) => {setSubmitting(false)
        console.log(response.data, 'responsedataflter')
        setTimeLogRows(response.data)
        setTimeLogRows1(response.data)

      })
      .catch((error) => {setSubmitting(false)
        console.error('There was an error!', error)
      })
  }
//   const searchPatients = () => {
//     props.getgroupBy(groupBy)
// if(!groupBy){
// setMSggroupBy('Group By is required')
//   return
// }
// setMSggroupBy('')


//     setsubmitting(true);
//     var filterArr = [];
//     filter.forEach(f => {
//       if (f.columns && f.operator && (f.value || f.value2)) {
//         var obj = {
//           field: f.columns,
//           operatorName: f.operator,
//           operatorType: f.type,
//           value: f.value,
//           value2: f.value2
//         };
//         filterArr.push(obj);
//       }
//     });
  
//     axios.post(`${process.env.REACT_APP_API_URL}/Reports/GetPatientPHQ9Report?fromDate=${startDate}&toDate=${endDate}&diagnosis=${code}&providerid=${_providerId}&groupBy=${groupBy}&consistentPatients=${isChecked}`, filterArr, { headers })
//       .then(response => {
//         console.error("There is a response1:", response.data);
//         props.getData(response.data);
//       if(response.data.length===0 || response.data===undefined){
//         props.getErrorMsgPhq9('No Record Found')

//         setsubmitting(false);

//       }else{
//       props.getData(response.data);

//         setsubmitting(false);}
//       })
//       .catch(error => {
//         console.error('There was an error!', error);
//         setsubmitting(false);
//       });
//       axios.post(`${process.env.REACT_APP_API_URL}/Reports/GetPatientGAD7Report?fromDate=${startDate}&toDate=${endDate}&diagnosis=${code}&providerid=${_providerId}&groupBy=${groupBy}&consistentPatients=${isChecked}`, filterArr, { headers })
//       .then(response => {
//         console.error("There is a response1:", response.data);
//         props.getDataGAD7(response.data);
//         if(response.data.length===0 || response.data===undefined){
//           props.getErrorMsgPhq9('No Record Found')
  
//           setsubmitting(false);
  
//         }else{
//           props.getDataGAD7(response.data);
  
//           setsubmitting(false);}
  
//         setsubmitting(false);
//       })
//       .catch(error => {
//         console.error('There was an error!', error);
//         setsubmitting(false);
//       });
//   }
const handlePageClick = event => {
  const nextPage = event + 1
  setActivepage(nextPage)
  console.log('Active page:', nextPage) // Display the active page number in the console
}
const handlePageClick1 = event => {
  const nextPage = event + 1
  setActivepage1(nextPage)
  console.log('Active page 1:', nextPage) // Display the active page number in the console
}
  const timelogColumnsSummaryReport = [
    {
      field: 'id',
      headerName: 'ID',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      width: 40
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      width: 100,

      renderCell: cellValues => {
        return (
          <Grid xs={12}>
            <Button
              size='small'
              variant='text'
              color='info'
              display='flex'
              // style={{ textAlign: 'left' }}
              style={{ justifyContent: 'flex-start', color: 'black' }}
              justifyContent='space-between'
              onClick={event => {
                setOpenEidthReport(cellValues.row.id)
                setopenNote(false)
              }}
            >{`${cellValues.row.firstName}`}</Button>
          </Grid>
        )
      }
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      width: 100
    },
    {
      field: 'dOB',
      headerName: 'DOB',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      width: 100
    },
    {
      field: 'status',
      headerName: 'Status',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      width: 90
    },
    {
      field: 'timeTracked',
      headerName: 'Time Tracked',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      width: 100
    },
    {
      field: 'medicareStatus',
      headerName: 'Medicare Status',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      width: 120
    },
    {
      field: 'intakeStatus',
      headerName: 'intake Status',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      width: 100
    },
    {
      field: 'gender',
      headerName: 'Gender',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      width: 70
    },
    {
      field: 'frequencyofcontact',
      headerName: 'frequency Of Contact',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      width: 150
    },
    {
      field: 'estimatedMonthlyTime',
      headerName: 'estimatedMonthlyTime',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      width: 160
    }

  ]

  const onFilterSearchreport = (event) => {
    setSubmitting(true)
    setTimeLogRows(null)
    setTimeLogRows1(null)

    const postData = {
      lastname: flastName,
      firstname: ffirstName,
      date:fdate,
      startTime: fstartTime,
      endTime: fendTime,
      userName:userName,
      providerId:_providerId,
      // groupBy:groupBy,
      // PageNumber:Activepage,
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientTimeLog/Report?groupBy=${groupBy}&PageNumber=${Activepage}`,
        postData,
        { headers }
      )
      // .get(`${process.env.REACT_APP_API_URL}/PatientTimeLog/Report`, {
      //   headers,
      // })
      .then((response) => {
        // setTimeLogRows(response.data)
        // setTimeLogRows1(response.data)
        if(groupBy==='Provider'){
          setTimeLogRows1(response.data)

        }else{
          setTimeLogRows(response.data)

        }

        setSubmitting(false)
      })
      .catch((error) => {
        setSubmitting(false)
        console.error('There was an error!', error)
      })
  }
  // 1111
  const onFilterSearch = async (event) => {
    setSubmitting(true);
    
    if (month === '') {
      seterror("Month is required");
      setSubmitting(false);
      return;
    }
    
    seterror('');
    
    if (year === '') {
      seterror1("Year is required");
      setSubmitting(false);
      return;
    }
    
    seterror1('');
    
    const postData = {
      month,
      year,
      groupBy: getValues('GroupBy') // Get the group by value
    };
    
    try {
      const response1 = await axios.post(
        `${process.env.REACT_APP_API_URL}/PatientTimeLog/PatientTimeLogSummaryReport?month=${month}&year=${year}`,
        postData, { headers }
      );
      
      if (response1.data === undefined || response1.data.length === 0) {
        setNoRecord('No Record Found');
      } else {
        setPatientTimeLogSummaryReport(response1.data);
      }
      
      const response2 = await axios.post(
        `${process.env.REACT_APP_API_URL}/PatientTimeLog/GetProviderReport?month=${month}&year=${year}`,
        postData, { headers }
      );
      
      if (response2.data === undefined || response2.data.length === 0) {
        setNoRecord('No Record Found');
      } else {
        setGetProviderReport(response2.data);
      }
    } catch (error) {
      console.error('There was an error!', error);
    } finally {
      setSubmitting(false);
    }
  };
  
 
  
  const onFilterClearsummmary = (event) => {setPatientTimeLogSummaryReport(null)
    setValue('month', '');
    setValue('year', '');
    setSubmitting(true)
    if (month == '') {
      seterror("month is required")
      setSubmitting(false)
      return;
    }
    seterror('')

    if (year == '') {
      seterror1("year is required")
      setSubmitting(false)
      return;
    }
    seterror1('')

    const postData = {
      month: month,
      year: year
    }
    axios
      .post(`${process.env.REACT_APP_API_URL}/PatientTimeLog/PatientTimeLogSummaryReport`,
        postData, { headers })
      .then(response => {
        // setorignalRows2(response.data)
        setPatientTimeLogSummaryReport(response.data)
        setSubmitting(false)
      })
      .catch(error => {
        console.error('There was an error!', error)
        setSubmitting(false)
      })
      axios
      .post(`${process.env.REACT_APP_API_URL}/PatientTimeLog/GetProviderReport`,
        postData, { headers })
      .then(response => {
        // setorignalRows2(response.data)
        setGetProviderReport(response.data)
        setSubmitting(false)
      })
      .catch(error => {
        console.error('There was an error!', error)
        setSubmitting(false)
      })
  }
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px',
    },
  }
  const [patientId, setpatientId] = useState('')
  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 5,
    background: 'white',
    fontSize: '13.6px !important',
    color: '#8392ab !important',
    borderColor: 'rgba(5, 152, 236, 0.637) !important',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#FFFFC2',
      borderRadius: 1.8,
    },
  }

  const timelogColumns = [
    {
      field: 'id',
      headerName: 'ID',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'firstname',
      headerName: 'Patient Name',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
      renderCell: (cellValues) => {
        return (
          <SuiButton
            size="small"
            variant="text"
            color="info"
            onClick={(event) => {
              setOpenEidthReport(cellValues.row.id)
              setopenNote(false)
            }}
          >{`${cellValues.row.lastname}, ${cellValues.row.firstname}`}</SuiButton>
        )
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    {
      field: 'startTime',
      headerName: 'Start Time',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    {
      field: 'endTime',
      headerName: 'End Time',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.4,
    },
    {
      field: 'timeSpent',
      headerName: 'Total Time',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.2,
    },
    // {
    //   field: 'patientId',
    //   headerName: 'Add Patient',
    //   sortable: true,
    //   headerClassName: 'super-app-theme--header',
    //   flex: 0.3,
    //   renderCell: (cellValues) => (
    //     <SuiButton
    //       onClick={(event) => {
    //         // console.log('Cell: ', cell)
    //         setpatientId(cellValues.row.patientId)

    //       }}
    //     >
    //       <AddOutlinedIcon
    //         style={{
    //           marginRight: '5px',
    //           float: 'right',
    //           marginTop: '5px',
    //           color: 'red',
    //         }}

    //       ></AddOutlinedIcon>
    //       Add
    //       {/* {`${cellValues.row.patientId}`} */}
    //     </SuiButton>
    //     // <AddOutlinedIcon
    //     //   style={{
    //     //     marginRight: '5px',
    //     //     float: 'right',
    //     //     marginTop: '5px',
    //     //     color:'red',
    //     //   }}

    //     // ></AddOutlinedIcon>
    //   ),
    // },
    {
      field: 'delete',
      headerName: 'Delete',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      renderCell: (cell) => (
        <>
      <DeleteIcon
        style={{
          height: '16px',
          width: '22px',
          marginRight: '5px',
          float: 'right',
          marginTop: '5px',
          color: 'red',
        }}
        onClick={() => handleOpen(cell)}
      ></DeleteIcon>

      <Dialog open={open} onClose={handleClose}
       aria-labelledby="responsive-dialog-title"
                                           
       BackdropComponent={Backdrop}
       BackdropProps={{
         invisible: true,
       }}
       PaperProps={{
        style: {background:'#cef5f0',
        boxShadow:'none',
        borderRadius:'8px'
        },
     }} 
      >

      <div style={{borderRadius:'8px'}}>
   
   <ErrorOutlineIcon style={{marginLeft:'125px',fontSize:'100px',color:'orange'}}></ErrorOutlineIcon>
       <DialogTitle>{"Do you want to delete the record?"}</DialogTitle>
       <h5 style={{textAlign:'center'}}>This action cannot be undone.</h5>
       <DialogContent>
           <DialogContentText>
           <Grid style={{marginLeft:'90px'}}>
           <Button  onClick={handleDelete} style={{backgroundColor:'red',color:'white'}}>YES</Button>
           <Button  onClick={handleClose} style={{backgroundColor:'blue',color:'white',marginLeft:'1rem'}}>NO</Button>
           </Grid>
           </DialogContentText>
       </DialogContent>
      
       



</div>


       
      </Dialog>
    </>
      ),
    },
  ]
  const timelogColumns1 = [
    {
      field: 'id',
      headerName: 'ID',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'firstname',
      headerName: 'Provider Name',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
      renderCell: (cellValues) => {
        return (
          <SuiButton
            size="small"
            variant="text"
            color="info"
            onClick={(event) => {
           

              setOpenEidthReport1(cellValues.row.id)
              setProviderLastName(cellValues.row.providerlastName);
              setProviderFirstName(cellValues.row.providerfirstName);
              settotalTimeSpent(cellValues.row.totalTimeSpent);
              
              setopenNote1(false)
            }}
          >{`${cellValues.row.providerlastName}, ${cellValues.row.providerfirstName}`}</SuiButton>
        )
      },
    },
    // {
    //   field: 'date',
    //   headerName: 'Date',
    //   cellClassName: 'gridCell',
    //   sortable: false,
    //   headerClassName: 'super-app-theme--header',
    //   flex: 0.5,
    // },
    // {
    //   field: 'startTime',
    //   headerName: 'Start Time',
    //   cellClassName: 'gridCell',
    //   sortable: false,
    //   headerClassName: 'super-app-theme--header',
    //   flex: 0.5,
    // },
    // {
    //   field: 'endTime',
    //   headerName: 'End Time',
    //   cellClassName: 'gridCell',
    //   sortable: false,
    //   headerClassName: 'super-app-theme--header',
    //   flex: 0.4,
    // },
    {
      field: 'totalTimeSpent',
      headerName: 'Total Time',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.2,
    },
    // {
    //   field: 'patientId',
    //   headerName: 'Add Patient',
    //   sortable: true,
    //   headerClassName: 'super-app-theme--header',
    //   flex: 0.3,
    //   renderCell: (cellValues) => (
    //     <SuiButton
    //       onClick={(event) => {
    //         // console.log('Cell: ', cell)
    //         setpatientId(cellValues.row.patientId)

    //       }}
    //     >
    //       <AddOutlinedIcon
    //         style={{
    //           marginRight: '5px',
    //           float: 'right',
    //           marginTop: '5px',
    //           color: 'red',
    //         }}

    //       ></AddOutlinedIcon>
    //       Add
    //       {/* {`${cellValues.row.patientId}`} */}
    //     </SuiButton>
    //     // <AddOutlinedIcon
    //     //   style={{
    //     //     marginRight: '5px',
    //     //     float: 'right',
    //     //     marginTop: '5px',
    //     //     color:'red',
    //     //   }}

    //     // ></AddOutlinedIcon>
    //   ),
    // },
//     {
//       field: 'delete',
//       headerName: 'Delete',
//       sortable: true,
//       headerClassName: 'super-app-theme--header',
//       flex: 0.3,
//       renderCell: (cell) => (
//         <>
//       <DeleteIcon
//         style={{
//           height: '16px',
//           width: '22px',
//           marginRight: '5px',
//           float: 'right',
//           marginTop: '5px',
//           color: 'red',
//         }}
//         onClick={() => handleOpen(cell)}
//       ></DeleteIcon>

//       <Dialog open={open} onClose={handleClose}
//        aria-labelledby="responsive-dialog-title"
                                           
//        BackdropComponent={Backdrop}
//        BackdropProps={{
//          invisible: true,
//        }}
//        PaperProps={{
//         style: {background:'#cef5f0',
//         boxShadow:'none',
//         borderRadius:'8px'
//         },
//      }} 
//       >

//       <div style={{borderRadius:'8px'}}>
   
//    <ErrorOutlineIcon style={{marginLeft:'125px',fontSize:'100px',color:'orange'}}></ErrorOutlineIcon>
//        <DialogTitle>{"Do you want to delete the record?"}</DialogTitle>
//        <h5 style={{textAlign:'center'}}>This action cannot be undone.</h5>
//        <DialogContent>
//            <DialogContentText>
//            <Grid style={{marginLeft:'90px'}}>
//            <Button  onClick={handleDelete} style={{backgroundColor:'red',color:'white'}}>YES</Button>
//            <Button  onClick={handleClose} style={{backgroundColor:'blue',color:'white',marginLeft:'1rem'}}>NO</Button>
//            </Grid>
//            </DialogContentText>
//        </DialogContent>
      
       



// </div>


       
//       </Dialog>
//     </>
//       ),
//     },
  ]
  const timelogColumnsSummary = [
    {
      field: 'id',
      headerName: 'ID',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'fullName',
      headerName: 'Patient Name',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
      renderCell: (cellValues) => {
        return (
          <SuiButton
            size="small"
            variant="text"
            color="info"
            onClick={(event) => {
              setOpenEidthReport(cellValues.row.id)
              setopenNote(false)
            }}
          >{`${cellValues.row.fullName}`}</SuiButton>
        )
      },
    },
    {
      field: 'date',
      headerName: 'Datedsgrg',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    {
      field: 'startTime',
      headerName: 'Start Time',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    {
      field: 'endTime',
      headerName: 'End Time',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    {
      field: 'timeSpent',
      headerName: 'Total Time',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    // {
    //   field: 'patientId',
    //   headerName: 'Add Patient',
    //   sortable: true,
    //   headerClassName: 'super-app-theme--header',
    //   flex: 0.3,
    //   renderCell: (cellValues) => (
    //     <SuiButton
    //       onClick={(event) => {
    //         // console.log('Cell: ', cell)
    //         setpatientId(cellValues.row.firstName)

    //       }}
    //     >
    //       <AddOutlinedIcon
    //         style={{
    //           marginRight: '5px',
    //           float: 'right',
    //           marginTop: '5px',
    //           color: 'red',
    //         }}

    //       ></AddOutlinedIcon>
    //       Add
    //       {/* {`${cellValues.row.patientId}`} */}
    //     </SuiButton>
    //     // <AddOutlinedIcon
    //     //   style={{
    //     //     marginRight: '5px',
    //     //     float: 'right',
    //     //     marginTop: '5px',
    //     //     color:'red',
    //     //   }}

    //     // ></AddOutlinedIcon>
    //   ),
    // },
    {
      field: 'delete',
      headerName: 'Delete',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      renderCell: (cell) => (
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
            color: 'red',
          }}
          onClick={(cellValues) => {
            // console.log('Cell: ', cell)
            confirmAlert({
              message: 'Do you want to delete the record.',
              closeOnEscape: true,
              closeOnClickOutside: true,
              overlayClassName: 'overlay-custom-class-name',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    const postData = {
                      name: cell.row.name,
                      lastname: cell.row.lastname,
                      firstname: cell.row.firstname,
                      id: cell.row.id,
                      inactive: true,
                      patientId: cell.row.patientId,
                      practiceId: cell.row.practiceId,
                    }

                    axios
                      .post(
                        `${process.env.REACT_APP_API_URL}/PatientTimeLog/addPatientTimeLog`,
                        postData,
                        {
                          headers,
                        }
                      )
                      .then((response) => {
                        setRefresh(!refresh)
                        // setOpenNote(false)
                        // console.log('response.data: ', response.data)
                      })
                      .catch((error) => {
                        console.error('There was an error!', error)
                      })
                  },
                },
                {
                  label: 'No',
                },
              ],
            })
          }}
        ></DeleteIcon>
      ),
    },

  ]
  const validationSchema = Yup.object({})
  const {
    register,
    control,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  })
  const styleDetails = {
    width: '90%',
    height: '95%',
    bgcolor: '#F0FFFF',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 30,
    right: 0,
    margin: 'auto',
    marginTop: '10px',
    paddingBottom: '10px',
    padding: '20px',
    overflow: 'auto'
  }
  const [open1, setOpen1] = React.useState(false)
  const handleOpen1 = () => setOpen1(true)
  const handleClose1 = () => {
    setpatientId(0)
    setOpen1(false)
  }


  const [open, setOpen] = useState(false);
  const [cell, setCell] = useState(null);

  const handleOpen = (cell) => {
    setOpen(true);
    setCell(cell);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    const postData = {
      name: cell.row.name,
      lastname: cell.row.lastname,
      firstname: cell.row.firstname,
      id: cell.row.id,
      inactive: true,
      patientId: cell.row.patientId,
      practiceId: cell.row.practiceId,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientTimeLog/addPatientTimeLog`,
        postData,
        {
          headers:headers
        }
      )
      .then((response) => {
        setRefresh(!refresh);
        handleClose();
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
  };


  useEffect(() => {
    handleOpen1()
 
  }, [patientId])
  useEffect(() => {setSubmitting(true)
  let isComponentMounted = true
    const postData = {
      // "patientId":1
    }
    let pId = 1
    //  let uName = ''
    const fetchData = async () =>
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/PatientTimeLog/Report`,
          postData,
          { headers }
        )
        // .get(`${process.env.REACT_APP_API_URL}/PatientTimeLog/Report?PatientId=${pId}`, {
        //   headers,
        // })
        .then((response) => {setSubmitting(false)
         

        })
        .catch((error) => {
          console.error('There was an error!', error)
          setSubmitting(false)
        })
    fetchData()
    return () => {
      isComponentMounted = false
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])


  return (
    <>
      {/* <Modal
              open={open1}
              onClose={handleClose1}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'
            >
              <Box sx={styleDetails}>
                <Grid container columnSpacing={1}>
                  <Grid item xs={12} style={{ fontWeight: 500 }}>
                    <CancelOutlinedIcon
                      onClick={handleClose1}
                      fontSize='medium'
                      style={{ color: 'red', marginBottom: '10px', float: 'right' }}
                    ></CancelOutlinedIcon>
                  </Grid>
                
                  <Demographics patientId={patientId}></Demographics>
                </Grid>
              </Box>
            </Modal> */}


      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab value={0} icon={<PermIdentity />} label="PATIENT TIME LOG" />
            <Tab
              value={1}
              icon={<AssignmentTurnedInIcon />}
              label="PATIENT TIME LOG SUMMARY"
            />
          </TabList>
        </Box>
        <TabPanel value={0}>
          <Grid
            spacing={1}
            alignContent="left"
            justifyContent="left"
            style={{ width: '100% !important', marginLeft: '0px !important' }}
          >
            <Accordion
              style={{
                marginBottom: '10px',
                border: '1px solid rgba(5, 152, 236, 0.637)',
                borderRadius: '20px',
                marginTop: '10px',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Advanced Search</Typography>

              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={3} xl={4}>
                      <Typography style={{ fontSize: '14px' }}>
                        Last Name
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} xl={4}>
                      <Typography style={{ fontSize: '14px' }}>
                        First Name
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} xl={4}>
                      <Typography style={{ fontSize: '14px' }}>
                        User Name
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} xl={4}>
                      <SuiInput
                        onChange={(e) => setFlastName(e.target.value)}
                        value={flastName}
                        placeholder="Please enter Last Name"
                        name="Patientname"
                        label="Patient Name"
                        fullWidth
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={6} sm={3} xl={4}>
                      <SuiInput
                        onChange={(e) => setFfirstName(e.target.value)}
                        value={ffirstName}
                        placeholder="Please enter First name"
                        name="Patientname"
                        label="Patient Name"
                        fullWidth
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={6} sm={3} xl={4}>
                      <SuiInput
                        onChange={(e) => setFuserName(e.target.value)}
                        value={userName}
                        placeholder="Please enter User Name"
                        name="username"
                        label="User Name"
                        fullWidth
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={6} sm={3} xl={4}>
                      <Typography style={{ fontSize: '14px' }}>
                        From Date
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} xl={4}>
                      <Typography style={{ fontSize: '14px' }}>
                        To Date
                      </Typography>
                    </Grid>   
                    <Grid item xs={6} sm={3} xl={4}>
                      <Typography style={{ fontSize: '14px' }}>
                      Providers
                      </Typography>
                    </Grid>
                 
                    <Grid item xs={6} sm={3} xl={4}>
                      <SuiInput
                        onChange={(e) => setFstartTime(e.target.value)}
                        value={fstartTime}
                        placeholder="Please enter Start Time"
                        type="date"
                        name="startTime"
                        label="Start Time"
                        fullWidth
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={6} sm={3} xl={4}>
                      <SuiInput
                        onChange={(e) => setFendTime(e.target.value)}
                        value={fendTime}
                        placeholder="Please enter End Time"
                        type="date"
                        name="endTime"
                        label="End Time"
                        fullWidth
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={6} sm={3} xl={4} style={{ width: '300px', zIndex: 1000 }}>
  <Controller
    name="providerId"
    control={control}
    render={({ field }) => (
      <SuiSelect
        isClearable
        {...field}
        value={providersRedux.find((option) => option.value === getValues('providerId'))}
        onChange={(value) => {
          // Handle clearing by setting the value to undefined or null
          setValue('providerId', value ? value.value : null);
          setProviderId(value ? value.value : null);
        }}
        options={providersRedux}
      />
    )}
  />

  <p style={{ color: 'red', fontSize: '14px' }}>
    {errors.providerId?.message}
  </p>
</Grid>

                    <Grid item xs={6} sm={3} xl={4}>
                      <Typography style={{ fontSize: '14px' ,marginTop:-15}}>
                        Group By
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} xl={4}>
                      <Typography style={{ fontSize: '14px' }}>
                   
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} xl={4}>
                      <Typography style={{ fontSize: '14px' }}>
                   
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} xl={4}>
  <Controller
    name="GroupBy"
    {...register('GroupBy')}
    control={control}
    render={({ field }) => {
      const options = [
        { label: 'Patient', value: 'Patient' },
        { label: 'Provider', value: 'Provider' },
      ];

      return (
        <>
          <SuiSelect
            placeholder="Group By"
            isClearable
            {...field}
            value={options.find(option => option.value === getValues('GroupBy'))}
           
            onChange={(value) => {
              setValue('GroupBy',  value ? value.value : null); // Ensure the value is set correctly
              setGroupValue(value ? value.value : null);
              console.log("Selected Value: ", value); // Logs the selected value to the console
            }}
            options={options}
          />
        </>
      );
    }}
  />
  {<span style={{ color: 'red' }}>{msggroupBy}</span>}
  {submitting ? (
    // <CircularProgress
    //   style={{
    //     width: "50px",
    //     marginTop: '1rem',
    //     position: "absolute",
    //     left: "50%",
    //     marginLeft: "-25px",
    //     color: "#FCBE13",
    //   }}
    // />
    ''
  ) : (<> </>)}
</Grid>




                  </Grid>

                  <div>
                    <Button
                      onClick={onFilterSearchreport}
                      variant="gradient"
                      style={{
                        marginTop: '10px',
                        marginBottom: '10px',
                        float: 'right',
                        width: '80px',
                        height: '35px',
                        backgroundColor: '#FCBE13',
                        color: 'black',
                        fontSize: '14px',
                      }}
                    >
                      <SearchOutlinedIcon
                        fontSize="medium"
                        style={{ color: '#1a82ad', paddingBottom: '1px' }}
                      ></SearchOutlinedIcon>
                      Search
                    </Button>
                    <Button
                      onClick={onFilterClear}
                      variant="gradient"
                      style={{
                        marginTop: '10px',
                        marginBottom: '10px',
                        float: 'right',
                        marginRight: '13px',
                        width: '80px',
                        height: '33px',
                        backgroundColor: '#FCBE13',
                        color: 'black',
                        fontSize: '14px',
                      }}
                    // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                    >
                      <CleaningServicesOutlinedIcon
                        fontSize="medium"
                        style={{ color: '#0a9ff0', paddingRight: '5px' }}
                      ></CleaningServicesOutlinedIcon>
                      Clear
                    </Button>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

          </Grid>
          <Grid item spacing={1} xs={12} sm={12} md={12} lg={12}>
            {/* <Button
          sx={{ mb: 2, marginRight: '20px' }}
          onClick={(event) => {
            setRefresh(!refresh)
          }}
          style={{
            marginBottom: '10px',
            float: 'right',
            marginLeft: '20px',
            // width: '120px',
            height: '35px',
            backgroundColor: '#FCBE13',
            color: 'black',
            fontSize: '14px',
          }}
        >
          <CachedOutlinedIcon
            fontSize="medium"
            style={{
              color: '#1a82ad',
              marginRight: '1px',
              paddingRight: '0px',
              paddingBottom: '2px',
            }}
          ></CachedOutlinedIcon>
          Refresh
         
        </Button>  */}
          </Grid>
{
  groupBy==='Provider'? (
    <Grid
    container
    spacing={1}
    alignContent="center"
    justifyContent="center"
  >
    {openNote1 ? (
      <Grid item xs={12}>
        <SuiBox mb={3}>
          {' '}

          <div style={{ height: 400, width: '100%' }}>
            {submitting ?
              <CircularProgress
                style={{
                  width: "50px",
                  marginTop: '7rem',

                  position: "absolute",
                  left: "50%",

                  marginLeft: "-25px",

                  // zIndex: 100000,
                  color: "#FCBE13",
                }}
              /> 
              
              : (
                <>
                  {timeLogRows1 === null ||
                    timeLogRows1.length === 0 ? 'No Record Found'

                    : (
                      <DataGrid
                        rows={timeLogRows1}
                        columns={timelogColumns1}
                        onPageChange={handlePageClick}
                        // {...(Activepage == 0 ? {} : { rowCount: TotalRowsall })}
                        headerHeight={37}
                        pageSize={10}
                        sx={gridRowStyle}
                        rowsPerPageOptions={[5]}

                      />
                    )}</>)}
          </div>

                          
        </SuiBox>
      </Grid>
    ) : (
      <Grid
        container
        xs={12}
        spacing={1}
        style={{
          marginTop: '20px',
          paddingTop: '10px',
          borderRadius: '20px',
          border: '1px solid rgba(5, 152, 236, 0.637)',
        }}
      >
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            width: '100%',
            marginTop: '5px',
            borderRadius: '20px',
          }}
        // bgcolor={'rgb(161,230,246)'}
        >
          {/* <Button
            variant="gradient"
            onClick={(event) => {
              setopenNote1(true)
            }}
            style={{
              marginLeft: '16px',
              paddingRight: '1px',
              paddingLeft: '0px',
              width: '80px',
              height: '35px',
              backgroundColor: '#FCBE13',
              color: 'black',
              fontSize: '14px',
            }}
          >
            <ArrowBackIosNewOutlinedIcon
              fontSize="medium"
              style={{
                color: '#1a82ad',
                paddingRight: '5px',
                paddingBottom: '2px',
              }}
            ></ArrowBackIosNewOutlinedIcon>
            Back
          </Button> */}
        </Grid>
        <Modal
            open={openEidthReport1}
            onClose={handleClose}
            aria-labelledby="timelogeidth-modal-title"
            aria-describedby="timelogeidth-modal-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: '20px',
              }}
            >
                {/* <Button
              variant="gradient"
              onClick={() => setopenNote(true)}
              style={{
                marginLeft: '16px',
                paddingRight: '1px',
                paddingLeft: '0px',
                width: '80px',
                height: '35px',
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
              }}
            >
              <ArrowBackIosNewOutlinedIcon
                fontSize="medium"
                style={{
                  color: '#1a82ad',
                  paddingRight: '5px',
                  paddingBottom: '2px',
                }}
              />
              Back
            </Button> */}
<div
  style={{
    position: 'relative',
    width: '100%',
    height: '100%',
  }}
>
  <div
    onClick={() => setopenNote1(true)}
    style={{
      cursor: 'pointer',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '2px solid black',
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      position: 'absolute',
      top: '1px', // Adjust as needed
      right: '1px', // Adjust as needed
      // backgroundColor: '#FCBE13',
    }}
  >
    <CloseIcon
      fontSize="large"
      style={{
        color: 'black',
      }}
    />
  </div>
</div>

<Timelogeidth1
            Timelogid={openEidthReport1}
            providerlastName={ProviderLastName}
            providerFirstName={ProviderFirstName}
            totalTimeSpent={totalTimeSpent}
            Backfunction={setopenNote1}
            refresh={!refresh}
          ></Timelogeidth1>
            </Box>
          </Modal>
    
      </Grid>
      
    )}

  </Grid>

 
  ) : (
      <Grid container spacing={1} alignContent="center" justifyContent="center">
      {openNote ? (
        <Grid item xs={12}>
          <SuiBox mb={3}>
            <div style={{ height: 400, width: '100%' }}>
              {submitting ? (
                <CircularProgress
                  style={{
                    width: '50px',
                    marginTop: '7rem',
                    position: 'absolute',
                    left: '50%',
                    marginLeft: '-25px',
                    color: '#FCBE13',
                  }}
                />
                
              ) : (
                <>
                  {timeLogRows === null || timeLogRows.length === 0 ? (
                    'No Record Found'
                  ) : (
                    <DataGrid
                      rows={timeLogRows}
                      columns={timelogColumns}
                      onPageChange={handlePageClick}
                      // {...(Activepage == 0 ? {} : { rowCount: TotalRowsall })}
                      headerHeight={37}
                      pageSize={10}
                      rowsPerPageOptions={[5]}
                      sx={gridRowStyle}
                    />
                  )}
                </>
              )}
            </div>
          </SuiBox>
        </Grid>
      ) : (
        <Grid
          container
          xs={12}
          spacing={1}
          style={{
            marginTop: '20px',
            paddingTop: '10px',
            borderRadius: '20px',
            border: '1px solid rgba(5, 152, 236, 0.637)',
          }}
        >
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              width: '100%',
              marginTop: '5px',
              borderRadius: '20px',
            }}
          >
          
          </Grid>
          <Modal
            open={openEidthReport}
            onClose={handleClose}
            aria-labelledby="timelogeidth-modal-title"
            aria-describedby="timelogeidth-modal-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: '20px',
              }}
            >
                {/* <Button
              variant="gradient"
              onClick={() => setopenNote(true)}
              style={{
                marginLeft: '16px',
                paddingRight: '1px',
                paddingLeft: '0px',
                width: '80px',
                height: '35px',
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
              }}
            >
              <ArrowBackIosNewOutlinedIcon
                fontSize="medium"
                style={{
                  color: '#1a82ad',
                  paddingRight: '5px',
                  paddingBottom: '2px',
                }}
              />
              Back
            </Button> */}
<div
  style={{
    position: 'relative',
    width: '100%',
    height: '100%',
  }}
>
  <div
    onClick={() => setopenNote(true)}
    style={{
      cursor: 'pointer',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '2px solid black',
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      position: 'absolute',
      top: '1px', // Adjust as needed
      right: '1px', // Adjust as needed
      // backgroundColor: '#FCBE13',
    }}
  >
    <CloseIcon
      fontSize="large"
      style={{
        color: 'black',
      }}
    />
  </div>
</div>

              <Timelogeidth
                Timelogid={openEidthReport}
                Backfunction={setopenNote}
                refresh={!refresh}
              />
            </Box>
          </Modal>
        </Grid>
      )}
    </Grid>
)
}
         
         
        </TabPanel>
        <TabPanel value={1}>
          <Grid
            spacing={1}
            alignContent="left"
            justifyContent="left"
            style={{ width: '100% !important', marginLeft: '0px !important' }}
          >
            <Accordion
              style={{
                marginBottom: '10px',
                border: '1px solid rgba(5, 152, 236, 0.637)',
                borderRadius: '20px',
                marginTop: '10px',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Advanced Search</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <Grid container xs={12} spacing={1}>
                    <Grid item xs={6}>
                      <Typography component='label' color='black' fontWeight='bold' fontSize='14px'>
                        Month
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography component='label' fontWeight='bold' color='black' fontSize='14px'>
                        Year
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller

                        name='month'
                        margin='dense'
                        {...register('month')}
                        control={control}
                        render={({ field }) => (
                          <ReactSelect
                            style={{ marginTop: 0, marginBottom: 0 }}
                            size='small'
                            // isClearable
                            {...field}
                            options={[
                              { value: '1', label: 'January' },
                              { value: '2', label: 'February' },
                              { value: '3', label: 'March' },
                              { value: '4', label: 'April' },
                              { value: '5', label: 'May' },
                              { value: '6', label: 'June' },
                              { value: '7', label: 'July' },
                              { value: '8', label: 'August' },
                              { value: '9', label: 'September' },
                              { value: '10', label: 'October' },
                              { value: '11', label: 'November' },
                              { value: '12', label: 'December' }
                            ]}
                            value={{ label: getValues('month') }}
                            onChange={value => {
                              setValue('month', value.label)
                              setmonth(value.value)
                            }}
                            error={errors.year}
                            menuPortalTarget={document.body}
                            menuPosition="fixed"
                            menuMaxHeight={200}
                          />
                        )}
                      />
                      <p style={{ color: 'red', fontSize: '14px' }}>{error}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name='year'
                        margin='dense'
                        {...register('year')}
                        control={control}
                        render={({ field }) => (
                          <ReactSelect
                            style={{ marginTop: 0, marginBottom: 0, zIndex: '1000' }}
                            {...field}
                            size='small'
                            value={{ label: getValues('year') }}
                            onChange={value => {
                              setValue('year', value.label);
                              setyear(value.value);
                            }}
                            options={[
                              { value: '2022', label: '2022' },
                              { value: '2023', label: '2023' },
                              { value: '2024', label: '2024' },
                              { value: '2025', label: '2025' },
                              { value: '2026', label: '2026' },
                              { value: '2027', label: '2027' },
                              { value: '2028', label: '2028' },
                              { value: '2029', label: '2029' },
                              { value: '2030', label: '2030' },
                              { value: '2031', label: '2031' },
                              { value: '2032', label: '2032' },
                              { value: '2033', label: '2033' }
                            ]}
                            error={errors.year}
                            menuPortalTarget={document.body}
                            menuPosition="fixed"
                            menuMaxHeight={200} // Adjust the height as needed
                          />
                        )}
                      />

                      <p style={{ color: 'red', fontSize: '14px' }}>{error1}</p>
                    </Grid>
                  </Grid>
                  <div>
                    <Button
                      onClick={onFilterSearch}
                      disabled={submitting}
                      variant="gradient"
                      style={{
                        marginTop: '10px',
                        marginBottom: '10px',
                        float: 'right',
                        width: '80px',
                        height: '35px',
                        backgroundColor: '#FCBE13',
                        color: 'black',
                        fontSize: '14px',
                      }}
                    >
                      <SearchOutlinedIcon
                        fontSize="medium"
                        style={{ color: '#1a82ad', paddingBottom: '1px' }}
                      ></SearchOutlinedIcon>
                      Search
                    </Button>
                    <Button
                      onClick={onFilterClearsummmary}
                      variant="gradient"
                      style={{
                        marginTop: '10px',
                        marginBottom: '10px',
                        float: 'right',
                        marginRight: '13px',
                        width: '80px',
                        height: '33px',
                        backgroundColor: '#FCBE13',
                        color: 'black',
                        fontSize: '14px',
                      }}
                    // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                    >
                      <CleaningServicesOutlinedIcon
                        fontSize="medium"
                        style={{ color: '#0a9ff0', paddingRight: '5px' }}
                      ></CleaningServicesOutlinedIcon>
                      Clear
                    </Button>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item spacing={1} xs={12} sm={12} md={12} lg={12}>
            {/* <Button
          sx={{ mb: 2, marginRight: '20px' }}
          onClick={(event) => {
            setRefresh(!refresh)
          }}
          style={{
            marginBottom: '10px',
            float: 'right',
            marginLeft: '20px',
            // width: '120px',
            height: '35px',
            backgroundColor: '#FCBE13',
            color: 'black',
            fontSize: '14px',
          }}
        >
          <CachedOutlinedIcon
            fontSize="medium"
            style={{
              color: '#1a82ad',
              marginRight: '1px',
              paddingRight: '0px',
              paddingBottom: '2px',
            }}
          ></CachedOutlinedIcon>
          Refresh
         
        </Button>  */}
          </Grid>

          <Grid
            container
            spacing={1}
            alignContent="center"
            justifyContent="center"
          >
            {openNote ? (
              <Grid item xs={12}>
                <SuiBox mb={3}>
                  <div style={{ height: 400, width: '100%' }}>

                    {submitting ? <CircularProgress
                      style={{
                        width: "50px",
                        marginTop: '7rem',

                        position: "absolute",
                        left: "50%",

                        marginLeft: "-25px",

                        // zIndex: 100000,
                        color: "#FCBE13",
                      }}
                    /> : (
                      <>
                        {PatientTimeLogSummaryReport === null || PatientTimeLogSummaryReport.length === 0 ? (

                          `${NoRecord}`

                        ) : (
                          <DataGrid
                            rows={PatientTimeLogSummaryReport}
                            columns={timelogColumnsSummaryReport}
                            pageSize={10}
                            headerHeight={37}
                            rowsPerPageOptions={[10]}
                            sx={gridRowStyle}
                          />
                        )}
                      </>
                    )}
                  </div>
                </SuiBox>
              </Grid>
            ) : (
              <Grid
                container
                xs={12}
                spacing={1}
                style={{
                  marginTop: '20px',
                  paddingTop: '10px',
                  borderRadius: '20px',
                  border: '1px solid rgba(5, 152, 236, 0.637)',
                }}
              >
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{
                    width: '100%',
                    marginTop: '5px',
                    borderRadius: '20px',
                  }}
                // bgcolor={'rgb(161,230,246)'}
                >
                  <Button
                    variant="gradient"
                    color="info"
                    onClick={(event) => {
                      setopenNote(true)
                    }}
                    style={{
                      marginLeft: '16px',
                      paddingRight: '1px',
                      paddingLeft: '0px',
                      width: '80px',
                      height: '35px',
                      backgroundColor: '#FCBE13',
                      color: 'black',
                      fontSize: '14px',
                    }}
                  >
                    <ArrowBackIosNewOutlinedIcon
                      fontSize="medium"
                      style={{
                        color: '#1a82ad',
                        paddingRight: '5px',
                        paddingBottom: '2px',
                      }}
                    ></ArrowBackIosNewOutlinedIcon>
                    Back
                  </Button>
                </Grid>
                {openEidthReport ? (
                  <Timelogeidth
                    Timelogid={openEidthReport}
                    Backfunction={setopenNote}
                    refresh={applyRefresh}
                  //  refresh={!refresh}
                  ></Timelogeidth>
                ) : null}
              </Grid>
            )}
          </Grid>
       
        </TabPanel>
      </TabContext>

    </>
  )
}

export default Reports
