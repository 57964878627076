import { USER_PRACTICES } from "../types";

const initialState = {
  USER_PRACTICES: [],
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_PRACTICES:
      return {
        ...state,
        USER_PRACTICES: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
