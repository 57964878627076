import { yupResolver } from '@hookform/resolvers/yup'
import {TextareaAutosize} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import DraftsIcon from '@mui/icons-material/Drafts'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InboxIcon from '@mui/icons-material/Inbox'
import SendIcon from '@mui/icons-material/Send'
import { confirmAlert } from 'react-confirm-alert'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { CircularProgress, Grid, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import axios from 'axios'
import SuiSelect from 'components/SuiSelect'
import SuiButton from 'components/SuiButton'
import SuiInput from 'components/SuiInput'
import { createBrowserHistory } from 'history'
import React, { useState } from 'react'
import { useAlert } from 'react-alert'
import { Controller, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import { useEffect } from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Moment from 'moment'
import moment from 'moment'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { getAllUsers } from 'redux/Actions/allUsersActions'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import Modal from '@mui/material/Modal'
import { makeStyles } from '@material-ui/core/styles'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import RefreshIcon from '@mui/icons-material/Refresh'
import CachedIcon from '@mui/icons-material/Cached'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { Backdrop } from '@mui/material';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

// import Button from '@mui/material/Button'
const history = createBrowserHistory({ forceRefresh: true })
const options = ['Delete', 'Reply']
var usersRedux = []
const ITEM_HEIGHT = 48
const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    right: 20,
    bottom: 20,
  },
  container: {
    width: 900,
    // height: '100%',
    height: 'fit-content',
    backgroundColor: 'white',
    position: 'absolute',
    display: 'flex',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    // overflow:'auto',
    padding: '10px',
    margin: 'auto',
    // [theme.breakpoints.down('sm')]: {
    //   width: '100vw',
    //   // height: '100vh',
    // },
  },
  form: {
    padding: theme.spacing(1),
  },
  item: {
    marginBottom: theme.spacing(2),
  },
  text: {
    display: 'inline',
    width: '100%',
  },

  readhide: {
    color: 'blue',
    cursor: 'pointer',
  },
}))
const fields = [
  'id',
  'practiceId',
  'parentId',
  'sendBy',
  'sendDate',
  'inbox',
  'sentMessage',
  'isDraft',
  'subject',
  'bodyMessage',
  'isRead',
  'readDate',
  'isActive',
  'createdBy',
  'createdDate',
  'updatedBy',
  'updatedDate',
  'clientCreatedDate',
  'clientUpdatedDate',
  'messagesTos',
]
const defValues = {
  id: 0,
  practiceId: '',
  parentId: '',
  sendBy: '',
  sendDate: '',
  inbox: '',
  sentMessage: '',
  isDraft: 'false',
  subject: '',
  bodyMessage: '',
  isRead: 'false',
  readDate: '',
  isActive: 'false',
  createdBy: '',
  createdDate: '',
  updatedBy: '',
  updatedDate: '',
  clientCreatedDate: '',
  clientUpdatedDate: '',
  messagesTos: [],
}
const styleDetails = {
  width: 1000,
  height: 'fit-content',
  bgcolor: '#F0FFFF',
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 30,
  right: 0,
  margin: 'auto',
  marginTop: '10px',
  paddingBottom: '10px',
  padding: '20px',
  // overflow: 'auto',
}
function Email(props) {
  const location = useLocation()
  const alert = useAlert()
  const dispatch = useDispatch()
  // var messagesToss=[]
  useEffect(() => {
    dispatch(getAllUsers())
  }, [dispatch])
  const [isReadMore, setIsReadMore] = useState(true)
  const ReadMore = ({ children }) => {
    const text = children
  
    return (
      <p className={classes.text}>
        {isReadMore ? text.slice(0, 10) : text}
        {text.length > 10
          ? '.....'
          : // <span
            //   onClick={() => {
            //     setIsReadMore(!isReadMore)
            //   }}
            //   className={classes.readhide}
            // >
            //   {isReadMore ? '...read more' : '    show less'}
            // </span>
            null}
      </p>
    )
  }
  const [openDialg, setOpenDialog] = useState(false);
  const [ItemID, setItemsId] = useState(false);

  const handleDeleteD   = () => {
    console.log(id,'item.id')
    // Handle your delete logic here
    const postData = {
      id:ItemID,
      isActive: true,
    };

    axios.post(`${process.env.REACT_APP_API_URL}/Messages/addMessage`, postData, { headers })
      .then((response) => {
        setRefresh(!refresh);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });

      setOpenDialog(false); // Close the dialog after performing the action
  };
  const [submitting, setSubmitting] = useState(true)
  // const [dataReceived, setdataReceived] = useState(false)
  const [openn, setOpenn] = useState(false)
  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }
  const maill = localStorage.getItem('dtt_user_email')
  const classes = useStyles()
  const [rows, setRows] = useState('')
  const [selectedIndex, setSelectedIndex] = useState('')
  const [sentMessages, setSentMessages] = useState('')
  const [refresh, setRefresh] = useState(false)
  const [isDraft, setIsDraft] = useState(false)
  const [openNewMail, setOpenNewMail] = React.useState(false)
  const validationSchema = Yup.object({
     // messagesTos: Yup.string().required('Please Select User Email.'),
    subject: Yup.string().required('Please Enter Subject.'),
    messagesTos: Yup.string().required('Please Enter Email'),

  })
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  })
  const [buttonClicked, setButtonClicked] = useState(false);
  const onSubmit = (data) => {
    setSubmitting(true)
    setButtonClicked(true);
    const postData = data
    // postData.messagesTos = messagesToss;
    if (messagesToss.length > 0) {
      postData.messagesTos = messagesToss
    }
    if (isDraft) {
      postData.isDraft = true
    }
    // postData.isNoteReview = false
    // postData.patientId = location.state.patientId
    // postData.patientNoteId = props.patientnoteId
    // console.log('Data: ', postData)
    // console.log(JSON.stringify(postData, null, 2))
    axios
      .post(`${process.env.REACT_APP_API_URL}/Messages/addMessage`, postData, {
        headers,
      })
      .then((response) => {
        setSubmitting(false)
        if (isDraft == false) {
          alert.success('Email Sent successfully.', {
            type: 'success',
            onClose: () => {
              settabValue(1)
              setRefresh(!refresh)
              clearAll()
              setButtonClicked(false);
            },
          })
        } else {
          alert.success('Saved as Draft.', {
            type: 'success',
            onClose: () => {
              settabValue(1)
              setOpenNewMail(false)
              clearAll()
            },
          })
        }
      })
      .catch((error) => {
        setSubmitting(false)
        alert.success(`Error ${error.message}`, {
          type: 'error',
        })
      })
  }
  function handleCancel() {
    history.push('/PatientSearch')
  }
  const handleCloseMail = (reason) => {
    if (reason === 'clickaway') {
      return
    }
    clearAll()
  }
  const [id, setId] = useState('')
  const [inbox, setInbox] = useState('')
  const [inboxIndex, setInboxIndex] = useState('')
  var date = moment(inbox.sendDate).format('L')
  date = Moment(date).format('MM-DD-YYYY')
  var time = Moment(inbox.sendDate).format('hh:mm:A')
  function handleDate(e) {
    var Mdate = moment(e).format('L')
    Mdate = Moment(Mdate).format('MM-DD-YYYY')
    // console.log('date',Mdate)
    var Mtime = Moment(e).format('hh:mm:A')
    var TTime = `${Mdate}  ${Mtime}`
    return TTime
  }
  const [anchorEl, setAnchorEl] = useState(false)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (e) => {
    setAnchorEl(null)
    console.log('eventt:', e)
  }
  useEffect(() => {
    setSubmitting(true)
    // POST request using axios inside useEffect React hook
    // console.log('API: ', props)
    let isComponentMounted = true
    const fetchData = async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/Messages/getInbox`, {
          headers,
        })
        .then((response) => {
          if (response.data != '') {
            setRows(response.data)
            setSubmitting(false)
            if (tabValue == 1) {
              setId(response.data[0].id)
            }
          } else {
            setSubmitting(false)
          }
        })
        .catch((error) => {
          setSubmitting(false)
          alert.success(`Error ${error.message}`, {
            type: 'error',
          })
        })
    fetchData()
    return () => {
      isComponentMounted = false
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])
  const [openD, setOpen] = useState(false);

  const handleDelete = () => {
    const postData = {
      id: id,
      isActive: true,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/Messages/addMessage`, postData, {
        headers,
      })
      .then((response) => {
        setRefresh(!refresh);
        setOpen(false);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
  };

  useEffect(() => {
    setOpenn(false)
    setSubmitting(true)
    // POST request using axios inside useEffect React hook
    // console.log('API: ', props)
    let isComponentMounted = true
    const fetchData = async () =>
      axios
        .get(`${process.env.REACT_APP_API_URL}/Messages/getsentMessages`, {
          headers,
        })
        .then((response) => {
          if (response.data != '') {
            setSentMessages(response.data)
            setSubmitting(false)
            if (tabValue == 2) {
              setId(response.data[0].id)
            }
          } else {
            setSubmitting(false)
            setOpenn(false)
          }
        })
        .catch((error) => {
          setSubmitting(false)
          setOpenn(false)
          alert.success(`Error ${error.message}`, {
            type: 'error',
          })
        })
    fetchData()
    return () => {
      isComponentMounted = false
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])
  //   const usersRedx = useSelector((state) =>
  //   (state.allusers.loading ? [] : state.allusers.NAMES), shallowEqual
  // )
  const usersRedx = useSelector(
    (state) => (state.allUsers.loading ? [] : state.allUsers.NAMES),
    shallowEqual
  )
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    usersRedux = []
    usersRedx.forEach((field) => {
      usersRedux.push({
        label: `${field.fullName}<${field.email}>`,
        value: field.email,
      })
    })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [{ usersRedx }])

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    // console.log('API: ', props)
    if (id) {
      setSubmitting(true)
      let isComponentMounted = true
      const fetchData = async () =>
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/Messages/getMeassageById?Id=${id}`,
            {
              headers,
            }
          )
          .then((response) => {
            setInbox(response.data)
            setOpenn(true)
            setSubmitting(false)
            // console.log('row', response.data)
          })
          .catch((error) => {
            setSubmitting(false)
            setOpenn(false)
            alert.success(`Error ${error.message}`, {
              type: 'error',
            })
          })
      fetchData()
      return () => {
        isComponentMounted = false
      }
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [id])
  const clearAll = () => {
    //  setValue('sendBy', '')
    setValue('subject', '')
    setValue('bodyMessage', '')
    setValue('messagesTos', '')
    settabValue(1)
    setEmail('')
    setOpenNewMail(false)
  }
  const handleDraft = () => {
    setIsDraft(true)
    handleSubmit(onSubmit)
    clearAll()
  }
  const [tabValue, settabValue] = React.useState(1)
  const [email, setEmail] = React.useState('')
  const [messagesToss, setMessagesToss] = React.useState([])

  const handleChangeTab = (event, newValue) => {
    settabValue(newValue)
    setSelectedIndex('')
    setOpenn(false)
    if (newValue == 1) {
      if (rows.length > 0) {
        setId(rows[0].id)
      }
    }
    if (newValue == 2) {
      if (sentMessages.length > 0) {
        setId(sentMessages[0].id)
      }
    }
  }
  return (
    <>
      <Modal
        open={openNewMail}
        onClose={handleCloseMail}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleDetails}>
          <form className={classes.form} autoComplete="off">
            <CancelOutlinedIcon
              onClick={() => {
                clearAll()
                // setMaill(`${localStorage.getItem('dtt_user_email')}`)
                // props.refresh()
              }}
              variant="contained"
              style={{
                float: 'right',
                color: 'red',
              }}
            ></CancelOutlinedIcon>
            <h3 style={{ backgroundColor: 'Primary' }}>New mail</h3>
            <Grid
              container
              spacing={1}
              alignContent="center"
              justifyContent="center"
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {/* {
                    <CKEditor
                      editor={ClassicEditor}
                    //   data={getValues('medicalNotes')}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor)
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        // setValue('medicalNotes', data)
                      }}
                      onBlur={(event, editor) => {
                        console.log('Blur.', editor)
                      }}
                      onFocus={(event, editor) => {
                        console.log('Focus.', editor)
                      }}
                    />
                  } */}
              </Grid>
              <Grid item xs={1}>
                <Typography>From:</Typography>
              </Grid>
              <Grid item xs={4}>
                <SuiInput
                  type="input"
                  value={maill}
                  readOnly={true}
                  disabled
                  placeholder="Please enter Email"
                  {...register('sendBy')}
                  fullWidth
                  float="left"
                  margin="dense"
                />
              </Grid>
              <Grid item xs={7}></Grid>
              <Grid item xs={1}>
                <Typography>To:</Typography>
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="messagesTos"
                  // {...register('messagesTos.email')}
                  control={control}
                  render={({ field }) => (
                    <SuiSelect
                      placeholder="Select User Email"
                      {...field}
                      value={usersRedux.filter(function (option) {
                        return option.value === getValues('messagesTos')
                        // return (
                        //   option.value ===
                        //   (fetchedData ? getValues('facilityId') : -1)
                        // )
                      })}
                      options={usersRedux}
                      onChange={(value) => {
                        setValue('messagesTos', value.value)
                        if (email.length > 0) {
                          if(email.indexOf(value.value)==-1)
                            setEmail(email + ',' + value.value)
                        } else {
                          setEmail(value.value)
                        }
                        // setEmailTo(value)
                        const emailar = value
                        var emailObj = {
                          email: emailar.value,
                        }
                        var eList = messagesToss
                        eList.push(emailObj)
                        setMessagesToss(eList)
                      }}
                      error={errors.eList}

                    />
                  )}
                />
                <p style={{ color: 'red', fontSize: '13px' }}>
                  {errors.eList?.message}
                </p>
                {/* <SuiInput
                    type="input"
                    placeholder="Please enter Email"
                    {...register('messagesTos.email')}
                    fullWidth
                    margin="dense"
                    float="left"
                  /> */}
              </Grid>
              <Grid item xs={7}>
                <SuiInput
                  type="value"
                  value={email}
                  // error={errors.messagesTos.email}
                  placeholder="Please Select Email"
                  // {...register('messagesTos.value')}
                  fullWidth
                  margin="dense"
                  float="left"
                  error={errors.messagesTos}
                />
                <p style={{ color: 'red', fontSize: '13px' }}>
                  {errors.messagesTos?.message}
                </p>
              </Grid>
              {/* <Grid item xs={1}>
                    <Typography>Subject:</Typography>
                  </Grid> */}
              <Grid item xs={12}>
                <SuiInput
                  type="input"
                  placeholder="Subject"
                  {...register('subject')}
                  fullWidth
                  margin="dense"
                  error={errors.subject}
                />
                <p style={{ color: 'red', fontSize: '13px' }}>
                  {errors.subject?.message}
                </p>
              </Grid>
              <Grid item xs={12}>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={5}
                  // placeholder="Enter comments"
                  style={{ width: '100%', padding: '5px' }}
                  {...register('bodyMessage')}
                />
              </Grid>
              <Grid item xs={12}>
                <SuiButton
                  onClick={handleSubmit(onSubmit)}
                    disabled={ buttonClicked}
                  variant="gradient"
                  style={{
                    marginTop: '10px',
                    marginBottom: '3px',
                    float: 'right',
                    marginRight: '5px',
                    marginLeft: 'auto',
                    // width: '80px',
                    height: '35px',
                    backgroundColor: '#FCBE13',
                    color: 'black',
                    fontSize: '14px',
                  }}
                >
                  <SendIcon fontSize="medium" />
                  Send
                </SuiButton>

                <SuiButton
                  onClick={clearAll}
                  variant="gradient"
                  style={{
                    marginTop: '10px',
                    marginBottom: '3px',
                    float: 'right',
                    marginRight: '5px',
                    marginLeft: 'auto',
                    // width: '80px',
                    height: '35px',
                    backgroundColor: '#FCBE13',
                    color: 'red',
                    fontSize: '14px',
                  }}
                >
                  <DeleteIcon fontSize="medium" />
                  Discard
                </SuiButton>
                {/* <SuiButton
                    onClick={()=>{  
                      setIsDraft(true)
                      handleSubmit(onSubmit)
                      clearAll()}}
                    variant="gradient"
                    style={{
                      marginTop: '10px',
                      marginBottom: '3px',
                      float: 'right',
                      marginRight: '5px',
                      marginLeft: 'auto',
                      // width: '80px',
                      height: '35px',
                      backgroundColor: '#FCBE13',
                      color: 'black',
                      fontSize: '14px',
                    }}
                  >
                    <DraftsIcon fontSize="medium" />
                    Save as Draft
                  </SuiButton> */}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
      {submitting ? (
        <CircularProgress
          style={{
            width: '50px',
            height: '50px',
            position: 'absolute',
            left: '50%',
            top: '5%',
            marginLeft: '-25px',
            marginTop: '-25px',
            zIndex: 1000,
            color: '#FCBE13',
          }}
        />
      ) : null}

      <Grid
        spacing={1}
        alignContent="left"
        justifyContent="left"
        style={{
          width: '100% !important',
          marginLeft: '0px !important',
        }}
      >
        <div>
          <SuiButton
            onClick={() => setOpenNewMail(true)}
            variant="gradient"
            style={{
              // marginTop: '10px',
              marginBottom: '10px',
              // float: 'left',
              // marginRight: '5px',
              marginLeft: 'auto',
              // width: '80px',
              height: '35px',
              backgroundColor: '#FCBE13',
              color: 'black',
              fontSize: '14px',
            }}
          >
            <AddIcon fontSize="medium" />
            New mail
          </SuiButton>
          
          <SuiButton
              sx={{ mb: 2, marginRight: '20px' }}
              onClick={() => {
                setRefresh(!refresh)
                setSubmitting(true)
              // onClick={() => {
              //   setRefresh(!refresh)
               }}
              style={{
                marginBottom: '10px',
                float: 'right',
                marginLeft: '20px',
                width: '120px',
                height: '35px',
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
              }}
            >
              <CachedOutlinedIcon
                fontSize="medium"
                style={{
                  color: '#1a82ad',
                  marginRight: '1px',
                  paddingRight: '0px',
                  paddingBottom: '2px',
                }}
              ></CachedOutlinedIcon>
              Refresh
            </SuiButton>
          {/* <CachedIcon
            fontSize="medium"
            style={{ float: 'right', cursor: 'pointer' }}
            onClick={() => {
              setRefresh(!refresh)
              setSubmitting(true)
            }}
          /> */}
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '30%' }}>
              <TabList
                onChange={handleChangeTab}
                aria-label="lab API tabs example"
              >
                {/* <Tab
                  value={0}
                  icon={<AddIcon fontSize="medium" />}
                  label="New Email"
                /> */}
                <Tab
                  value={1}
                  icon={<InboxIcon fontSize="medium" />}
                  label="Inbox"
                />
                <Tab
                  value={2}
                  icon={<SendIcon fontSize="medium" />}
                  label="Sent"
                />
                {/* <Tab
                  value={3}
                  icon={<DraftsIcon fontSize="medium" />}
                  label="Draft"
                /> */}
              </TabList>
            </Box>
            <TabPanel value={0}>
              {/* {submitting && <div>Saving Form...</div>} */}
            </TabPanel>
            <TabPanel value={1}>
              {/* {submitting && <div>Saving Form...</div>} */}
              <Grid
                container
                spacing={1}
                alignContent="center"
                justifyContent="center"
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <Grid
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  style={{
                    margin: '0 auto',
                    height: '500px',
                    width: '100%',
                    overflow: 'auto',
                  }}
                >
                  {rows != null && rows.length > 0
                    ? rows.map((item, i) => (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{
                            marginBottom: '5px',
                            borderBottom: 'groove',
                            overFlow: 'auto',
                            padding: '5px',
                          }}
                          backgroundColor={selectedIndex == i ? '#B2E4F6' : ''}
                          onClick={() => {
                            setSelectedIndex(i)
                            setId(item.id)
                          }}
                        >
                          <Grid item xs={6} sm={12} md={12} lg={12}>
                            <Typography
                              style={{
                                paddingBottom: '1px',
                                fontSize: '17px',
                              }}
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              color="#32A6D6"
                            >
                              <ReadMore>{item.subject}</ReadMore>
                            </Typography>
                            <Typography
                              style={{
                                padding: '4px',
                                marginTop: '7px',
                                fontSize: '10px',
                                textAlign: 'right',
                                float: 'right',
                              }}
                              component="label"
                              variant="caption"
                              // color="#32A6D6"
                            >
                              {handleDate(item.sendDate)}
                            </Typography>
                          </Grid>



                          <>
      <DeleteIcon
        style={{
          marginRight: '5px',
          float: 'right',
          marginTop: '5px',
          color: 'red',
        }}
        onClick={() => {setOpen(true)}}
      />

      <Dialog open={openD} onClose={() =>{ setOpen(false)}}
         BackdropComponent={Backdrop}
         BackdropProps={{
           invisible: true,
         }}
         PaperProps={{
           style: {background:'#cef5f0',
           boxShadow:'none',
           borderRadius:'8px'
           },
        }} 
      >
<div style={{borderRadius:'8px',width:'22rem',background:'white'}}>
   
   <ErrorOutlineIcon style={{marginLeft:'125px',fontSize:'100px',color:'orange'}}></ErrorOutlineIcon>
       <DialogTitle style={{marginLeft:'1rem',fontWeight:'bold'}}>{"Do you want to delete the record?"}</DialogTitle>
       <h5 style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'normal',marginLeft:'-2rem' }}>
This action cannot be undone.
</h5>
       <DialogContent>
           <DialogContentText>
           <Grid style={{marginLeft:'90px'}}>
           <Button  onClick={handleDelete} style={{backgroundColor:'red',color:'white'}}>YES</Button>
           <Button  onClick={() => {setOpen(false)}}  style={{backgroundColor:'blue',color:'white',marginLeft:'1rem'}}>NO</Button>
           </Grid>
           </DialogContentText>
       </DialogContent>
      
       



</div>



   
      </Dialog>
    </>



                          <Grid item spacing={1} xs={6} sm={12} md={12} lg={12}>
                            <Typography
                              style={{
                                paddingBottom: '1px',
                                fontSize: '14px',
                              }}
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              color="#333333"
                            >
                              <ReadMore>{item.sentBy}</ReadMore>
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography
                              style={{
                                paddingBottom: '1px',
                                fontSize: '14px',
                              }}
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              color="#333333"
                            >
                              <ReadMore>{item.bodyMessage}</ReadMore>
                            </Typography>
                          </Grid>
                        </Grid>
                      ))
                    : 'No Record Found'}
                </Grid>
                <Grid item xs={9} sm={9} md={9} lg={9}>
                  {openn ? (
                    <Grid
                      container
                      style={{
                        backgroundColor: 'white',
                        // height: '100%',
                        position: 'relative',
                      }}
                    >
                      <Grid container xs={12} sm={12} md={12} lg={12}>
                        <Grid item xs={9} sm={9} md={9} lg={9}>
                          <Typography
                            style={{
                              padding: '4px',
                              fontSize: '20px',
                            }}
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="#32A6D6"
                          >
                            {inbox.subject}
                          </Typography>
                          <Typography
                            style={{
                              padding: '4px',
                              fontSize: '12px',
                            }}
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            // color="#2188FE"sentBy
                          >
                            {inbox.sentBy}
                          </Typography>
                        </Grid>
                        {/* <Grid item xs={2} sm={2} md={2} lg={2}></Grid> */}
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          lg={3}
                          style={{ textAlign: 'right', float: 'right' }}
                        >
                          <Typography
                            style={{
                              padding: '4px',
                              fontSize: '10px',
                            }}
                            component="label"
                            variant="caption"
                            // color="#2188FE"sentBy
                          >
                            {date}
                          </Typography>
                          <Typography
                            style={{
                              padding: '4px',
                              fontSize: '10px',
                            }}
                            component="label"
                            variant="caption"
                            // color="#2188FE"sentBy
                          >
                            {time}
                          </Typography>

                          {/* <Typography component="label" variant="caption">
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? 'long-menu' : undefined}
                              aria-expanded={open ? 'true' : undefined}
                              aria-haspopup="true"
                              onClick={handleClick}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              MenuListProps={{
                                'aria-labelledby': 'long-button',
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              // onClose={handleClose}
                              PaperProps={{
                                style: {
                                  maxHeight: ITEM_HEIGHT * 4.5,
                                  width: '20ch',
                                },
                              }}
                            >
                              {options.map((option) => (
                                <MenuItem
                                  key={option}
                                  selected={option === 'Pyxis'}
                                  onClick={handleClose}
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </Menu>
                          </Typography> */}
                        </Grid>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            padding: '4px',
                            paddingLeft: '5px',
                            fontSize: '17px',
                          }}
                          // component="label"
                          // variant="caption"
                          fontWeight="300"
                          // color="#2188FE"sentBy
                        >
                          {inbox.bodyMessage}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={2}>
              {/* {submitting && <div>Saving Form...</div>} */}
              <Grid
                container
                spacing={1}
                alignContent="center"
                justifyContent="center"
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <Grid
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  style={{
                    margin: '0 auto',
                    height: '500px',
                    width: '100%',
                    overflow: 'auto',
                  }}
                >
                  {sentMessages != null && sentMessages.length > 0
                    ? sentMessages.map((item, i) => (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{
                            marginBottom: '5px',
                            borderBottom: 'groove',
                            padding: '5px',
                          }}
                          backgroundColor={selectedIndex == i ? '#B2E4F6' : ''}
                          onClick={() => {
                            setSelectedIndex(i), setId(item.id)
                          }}
                        >
                          <Grid item spacing={1} xs={6} sm={12} md={12} lg={12}>
                            {/* <Typography
                              style={{
                                paddingBottom: '1px',
                                fontSize: '14px',
                              }}
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              color="#333333"
                            >
                              {item.sentBy}
                            </Typography> */}
                          </Grid>
                          <Grid item xs={6} sm={12} md={12} lg={12}>
                            <Typography
                              style={{
                                paddingBottom: '1px',
                                fontSize: '17px',
                              }}
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              color="#32A6D6"
                            >
                              <ReadMore>{item.subject}</ReadMore>
                            </Typography>
                            <Typography
                              style={{
                                padding: '4px',
                                marginTop: '7px',
                                fontSize: '10px',
                                textAlign: 'right',
                                float: 'right',
                              }}
                              component="label"
                              variant="caption"
                              // color="#2188FE"sentBy
                            >
                              {handleDate(item.sendDate)}
                            </Typography>

                           

                           

                          </Grid>
                          <Grid item spacing={1} xs={6} sm={12} md={12} lg={12}>
                            <Typography
                              style={{
                                paddingBottom: '1px',
                                fontSize: '14px',
                              }}
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              color="#333333"
                            >
                              <ReadMore>{item.emailTo}</ReadMore>
                            </Typography>


                            <>
      <DeleteIcon
        style={{
          marginRight: '5px',
          float: 'right',
          marginTop: '5px',
          color: 'red',
        }}
        onClick={() => {setOpenDialog(true)
       setItemsId(item.id)
        }}
      />

      <Dialog open={openDialg} onClose={() => setOpenDialog(false)}
         BackdropComponent={Backdrop}
         BackdropProps={{
           invisible: true,
         }}
         PaperProps={{
           style: {background:'#cef5f0',
           boxShadow:'none',
           borderRadius:'8px'
           },
        }} 
      >
       <div style={{borderRadius:'8px',width:'22rem',background:'white'}}>
   
   <ErrorOutlineIcon style={{marginLeft:'125px',fontSize:'100px',color:'orange'}}></ErrorOutlineIcon>
       <DialogTitle style={{marginLeft:'1rem',fontWeight:'bold'}}>{"Do you want to delete the record?"}</DialogTitle>
       <h5 style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'normal',marginLeft:'-2rem' }}>
This action cannot be undone.
</h5>
       <DialogContent>
           <DialogContentText>
           <Grid style={{marginLeft:'90px'}}>
           <Button  onClick={()=>{handleDeleteD()}} style={{backgroundColor:'red',color:'white'}}>YES</Button>
           <Button  onClick={() => setOpenDialog(false)}  style={{backgroundColor:'blue',color:'white',marginLeft:'1rem'}}>NO</Button>
           </Grid>
           </DialogContentText>
       </DialogContent>
      
       



</div>
      </Dialog>
    </>

                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography
                              style={{
                                paddingBottom: '1px',
                                fontSize: '14px',
                              }}
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              color="#333333"
                            >
                              <ReadMore>{item.bodyMessage}</ReadMore>
                            </Typography>
                          </Grid>
                        </Grid>
                      ))
                    : 'No Record Found'}
                </Grid>
                <Grid item xs={9} sm={9} md={9} lg={9}>
                  {openn ? (
                    <Grid
                      container
                      style={{
                        backgroundColor: 'white',
                        // height: '100%',
                        position: 'relative',
                      }}
                    >
                      <Grid container xs={12} sm={12} md={12} lg={12}>
                        <Grid item xs={9} sm={9} md={9} lg={9}>
                          <Typography
                            style={{
                              padding: '4px',
                              fontSize: '20px',
                            }}
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="#32A6D6"
                          >
                            {inbox.subject}
                          </Typography>
                          <Typography
                            style={{
                              padding: '4px',
                              fontSize: '12px',
                            }}
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            // color="#2188FE"sentBy
                          >
                            {inbox.sentTo}
                          </Typography>
                        </Grid>
                        {/* <Grid item xs={2} sm={2} md={2} lg={2}></Grid> */}
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          md={3}
                          lg={3}
                          textAlign="right"
                        >
                          <Typography
                            style={{
                              padding: '4px',
                              fontSize: '10px',
                            }}
                            component="label"
                            variant="caption"
                            // color="#2188FE"sentBy
                          >
                            {date}
                          </Typography>
                          <Typography
                            style={{
                              padding: '4px',
                              fontSize: '10px',
                            }}
                            component="label"
                            variant="caption"
                            // color="#2188FE"sentBy
                          >
                            {time}
                          </Typography>

                          {/* <Typography component="label" variant="caption">
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? 'long-menu' : undefined}
                              aria-expanded={open ? 'true' : undefined}
                              aria-haspopup="true"
                              onClick={handleClick}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              MenuListProps={{
                                'aria-labelledby': 'long-button',
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              PaperProps={{
                                style: {
                                  maxHeight: ITEM_HEIGHT * 4.5,
                                  width: '20ch',
                                },
                              }}
                            >
                              {options.map((option) => (
                                <MenuItem
                                  key={option}
                                  selected={option === 'Pyxis'}
                                  onClick={handleClose}
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </Menu>
                          </Typography> */}
                        </Grid>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            padding: '4px',
                            paddingLeft: '5px',
                            fontSize: '17px',
                          }}
                          // component="label"
                          // variant="caption"
                          fontWeight="300"
                          // color="#2188FE"sentBy
                        >
                          {inbox.bodyMessage}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={3}></TabPanel>
          </TabContext>
        </div>
        {/* </AccordionDetails> */}
        {/* </Accordion> */}
      </Grid>
    </>
  )
}

export default Email
