import * as React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { Grid, Typography, Button, Box } from '@mui/material'
import { createBrowserHistory } from 'history'
import Modal from '@mui/material/Modal'
import CancelOutlined from '@mui/icons-material/CancelOutlined'

const history = createBrowserHistory({ forceRefresh: true })

function PatientReffralReport() {
  const NO_ROWS_LABEL = 'No Record found'
  const GETTING_ROWS_LABEL = 'Please wait!! Your data is being loaded..'
  const [NoRowsLabel, setNoRowsLabel] = useState(false)
  const [openPatient, setopenPatient] = useState(false)
  const [openAppointment, setopenAppointment] = useState(false)
  const [rows, setRows] = useState(null)
  const [Patientrows, setPatientRows] = useState(null)
  const [Appointmentrows, setAppointmentrows] = useState(null)
  const [loading, setLoading] = useState(false)
  const [creationTime, setcreationTime] = useState(0)
  const [Err, setErrr] = useState(false)
  const [Err1, setErrr1] = useState(false)

  var time;
  const handleOpen = (value, creationTime) => {
    setopenPatient(true)
    creationTime=creationTime,
    console.log("creationTime is", creationTime);
  }
  const handleOpenn = (value, creationTime) => {
    setopenAppointment(true)
    creationTime=creationTime,
    console.log("creationTime is", creationTime);
  }

  const handleClose = () => {
    setopenPatient(false)
    setopenAppointment(false)
    console.log('clicked')
  }

  const headers = {
    Authorization: `Bearer  ${localStorage.getItem('dtt_token')}`,
  }
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/Faxes/ProgressReport`, {
        headers,
      })
      .then((response) => {
        if (response.data.length == 0) {
          setNoRowsLabel('No Record found');
        } else {
          setRows(response.data);
          setNoRowsLabel(false);
        }
        // ... the rest of your code
      })
      .catch((error) => {
        setNoRowsLabel('No Record found');
        console.error('There was an error!', error);
      });
  }, [refresh]);
  

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      flex: 0.2,
    },
    {
      field: 'faxesCreatedTotal',
      headerName: 'Faxes Created Total',
      // type: 'text',
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'patientCreated',
      headerName: 'Patient Created',
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      renderCell: (cellValues) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%', // Adjust the width as needed
          }}
        >
          <div
            style={{
              color: 'black',
              width: '100%', // Adjust the width as needed
              cursor: cellValues.row.patientCreated === 0 ? 'not-allowed' : 'pointer',
            }}
            onClick={() => {setRefresh(!refresh)
              if (cellValues.row.patientCreated === 0) {
                setErrr1("No Data Found");
                return;
              }
              handleOpen(cellValues.value, cellValues.row.creationTime);
              var creationTime = cellValues.row.creationTime;
        setLoading(true)
              axios
                .get(
                  `${process.env.REACT_APP_API_URL}/Faxes/ProgressReportDetailsbyPatientCreated?patientcreated=true&creationTime=${creationTime}`,
                  {
                    headers,
                  }
                )
                .then((response) => {    setLoading(false)
                  setPatientRows(response.data)
                  // if(response.data.length===0){
                  //   setErrr1('No Record Found')
                  // }else{
                  // setPatientRows(response.data);}
                })
                .catch((error) => {    setLoading(false)
                  console.error('There was an error!', error);
                });
            }}
          >
            {cellValues.value}
          </div>
        </div>
      ),
    }
    
    
    ,
    // {
    //   field: 'appointmentCreated',
    //   headerName: 'Appointment Created',
    //   headerClassName: 'super-app-theme--header',
    //   flex: 0.3,
    //   renderCell: (cellValues) => (
    //     <div
    //       onClick={() => {
    //         if (cellValues.row.appointmentCreated === 0) {
    //           console.log("No Data Found")
    //           return; // exit the function without making the API call
    //         }
    //         handleOpenn(cellValues.value, cellValues.row.creationTime)
    //         var creationTime= cellValues.row.creationTime;
            
    //         axios
    //           .get(
    //             `${process.env.REACT_APP_API_URL}/Faxes/ProgressReportDetailsByAppointmentCreated?AppointmentCreated=true&creationTime=${creationTime}`,
    //             {
    //               headers,
    //             }
    //           )
    //           .then((response) => {
    //             setAppointmentrows(response.data)
    //           })
    //           .catch((error) => {
    //             console.error('There was an error!', error)
    //           })
    //       }}
    //     >
    //       {cellValues.value}

    //     </div>
    //   ),
    // },
    {
      field: 'appointmentCreated',
      headerName: 'Appointment Created',
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      renderCell: (cellValues) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%', // Adjust the width as needed
          }}
        >
          <div
            style={{
              color: 'black',
              width: '100%', // Adjust the width as needed
              cursor: cellValues.row.appointmentCreated === 0 ? 'not-allowed' : 'pointer',
            }}
            onClick={() => {
              if (cellValues.row.appointmentCreated === 0) {
                console.log("No Data Found");
                return;
              }
              handleOpenn(cellValues.value, cellValues.row.creationTime);
              var creationTime = cellValues.row.creationTime;
            setLoading(true)
              axios
                .get(
                  `${process.env.REACT_APP_API_URL}/Faxes/ProgressReportDetailsByAppointmentCreated?AppointmentCreated=true&creationTime=${creationTime}`,
                  {
                    headers,
                  }
                )
                .then((response) => {    setLoading(false)
                  setAppointmentrows(response.data);
                })
                .catch((error) => {    setLoading(false)
                  console.error('There was an error!', error);
                });
            }}
          >
            {cellValues.value}
          </div>
        </div>
      ),
    }
    
    ,
    {
      field: 'creationTime',
      headerName: 'Fax Date',
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
  ]

  const Patientcolumns = [
    {
      field: 'id',
      headerName: 'Patient ID',
      headerClassName: 'super-app-theme--header',
      flex: 0.2,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      // type: 'text',
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },

    {
      field: 'dOB',
      headerName: 'Date Of Birth',
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'program',
      headerName: 'Program',
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
  ]

  const Appointmentcolumns = [
    {
      field: 'id',
      headerName: 'Appointment ID',
      headerClassName: 'super-app-theme--header',
      flex: 0.2,
    },
    {
      field: 'patientId',
      headerName: 'Paitient ID',
      // type: 'text',
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      // type: 'text',
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'providerName',
      headerName: 'Provider Name',
      // type: 'text',
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },

    {
      field: 'providerId',
      headerName: 'Provider ID',
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },

    {
      field: 'appointmentdate',
      headerName: 'Appointment Date',
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
  ]

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 1.8,
    background: 'white',
    fontSize: '13.6px !important',
    color: '#8392ab !important',
    borderColor: 'rgba(5, 152, 236, 0.637) !important',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#FFFFC2',
      borderRadius: 1.8,
      // marginTop:"3px",
    },
  }

  const styleBox = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: 400,
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: '#F0FFFF',
    border: '2px solid #black',
    borderRadius: '20px',
    boxShadow: 24,
    p: 2,
  }

  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}/Faxes/ProgressReport`, {
  //       headers,
  //     })
  //     .then((response) => {
  //       setRows(response.data)
  //       const creationTime = response.data.map((obj) => obj.creationTime);
  //       console.log(creationTime, 'Hello For Appointment');

  //     })
  //     .catch((error) => {
  //       console.error('There was an error!', error)
  //     })
  // }, [refresh])

  return (
    <>
      <Grid
        container
        spacing={1}
        style={{
          paddingTop: '10px',
          paddingLeft: '10px',
          paddingRight: '50px',
        }}
      >
        {/* 00000 */}
        <div style={{ height: 353.5, width: '100%' }}>
          {rows === null || rows.length === 0 ? (
            <Typography>  {NoRowsLabel || GETTING_ROWS_LABEL }</Typography>
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              headerHeight={37}
              pageSize={5}
              rowsPerPageOptions={[5]}
              sx={gridRowStyle}
            />
          )}
        </div>
        
      </Grid>

      <div>
        <Modal
          keepMounted
          open={openPatient}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
         
          <Box sx={styleBox}>
          <Grid xs={12}>
            <Button
              // variant="text"
              style={{
                float: 'right',
                position: 'absolute',
                top: '0px',
                right: '-7px',
                color: 'red',
                // marginBottom:"20px",
                // height:"200px",
              }}
              onClick={handleClose}
            >
              <CancelOutlined />
            </Button>
            </Grid>
            {loading?<Typography>Loading...</Typography>:
            Patientrows === null || Patientrows.length === 0 ? (
              <Typography>No Record Found</Typography>
            ) : (
              <DataGrid style={{marginTop:"7px"}}
                rows={Patientrows}
                columns={Patientcolumns}
                headerHeight={37}
                pageSize={5}
                rowsPerPageOptions={[5]}
                sx={gridRowStyle}
              />
            )}
          </Box>
        </Modal>

        <Modal
          open={openAppointment}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={styleBox}>
            <Grid xs={12}>
              <Button
                variant="text"
                style={{
                  float: 'right',
                  position: 'absolute',
                  top: '0px',
                  right: '-7px',
                  color: 'red',
                }}
                onClick={handleClose}
              >
                <CancelOutlined />
              </Button>
            </Grid>
{loading?<Typography>Loading...</Typography>:
Appointmentrows === null || Appointmentrows.length === 0 ? (
              <Typography>No Record Found</Typography>
            ) : (
              <DataGrid style={{marginTop:"7px"}}
                rows={Appointmentrows}
                columns={Appointmentcolumns}
                headerHeight={37}
                pageSize={5}
                rowsPerPageOptions={[5]}
                sx={gridRowStyle}
              />
            )}
          </Box>
        </Modal>
      </div>
    </>
  )
}

export default PatientReffralReport
