import { React, useState } from 'react'
// import { useNavigate } from "react-router-dom";
// import Button from "react-bootstrap/Button";
// import logo from "../assets/logoMobilecopy.png";
import pic from '../assets/blue.png'
import { Grid, Snackbar, TextField, Typography } from '@mui/material'
import SuiTypography from 'components/SuiTypography'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import axios from 'axios'
import { createBrowserHistory } from 'history'
import { makeStyles } from '@material-ui/core/styles'
import { Controller, useForm } from 'react-hook-form'
import EditIcon from '@mui/icons-material/Edit'
export default function CareTeamCard(props) {
  const history = createBrowserHistory({ forceRefresh: true })
  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const [images, setImages] = useState(null)
  const [isReadMore, setIsReadMore] = useState(true)
  const ReadMore = ({ children }) => {
    const text = children

    return (
      <p className={classes.text}>
        {isReadMore ? text.slice(0, 150) : text}
        {text.length > 150 ? (
          <span
            onClick={() => {
              setIsReadMore(!isReadMore)
            }}
            className={classes.readhide}
          >
            {isReadMore ? '...read more' : '    show less'}
          </span>
        ) : null}
      </p>
    )
  }
  const useStyles = makeStyles((theme) => ({
    text: {
      display: 'inline',
      width: '100%',
    },

    readhide: {
      color: 'blue',
      cursor: 'pointer',
    },
  }))
  //// Delete Pic Code
  //   const {
  //     register,
  //     control,
  //     handleSubmit,
  //     setValue,
  //     getValues,
  //     formState: { errors },
  //   } = useForm({
  //     mode: 'onBlur',
  //     reValidateMode: 'onChange',
  //     context: undefined,
  //     criteriaMode: 'firstError',
  //     shouldFocusError: true,
  //     shouldUnregister: false,
  //     shouldUseNativeValidation: false,
  //     delayError: undefined,

  //   })
  /// Add Image Code
  //   const onImageChange = (e) => {
  //     setImages(URL.createObjectURL(e.target.files[0]))
  //     const file = e.target.files[0]
  //     convertBase64(file)
  //     // console.log("64 image",base64)
  //     // console.log('Kdr Sy aya ha Console', URL.createObjectURL(e.target.files[0]))
  //   }
  //   const convertBase64 = (file) => {
  //     return new Promise((resolve, reject) => {
  //       const fileReader = new FileReader()
  //       fileReader.readAsDataURL(file)
  //       fileReader.onload = () => {
  //         resolve(fileReader.result)
  //       }
  //       fileReader.onerror = (error) => {
  //         reject(error)
  //       }
  //     }).then((users) => {
  //     //   setValue('base64', users)
  //     })
  //   }
  const headers = {
    Authorization: auth,
  }
  const postData = props.postData
  const classes = useStyles()
  return (
    // <Grid
    //   container
    //   columnSpacing={1}
    //   alignContent="center"
    //   justifyContent="left"
    //   alignSelf="left"
    //   style={{ margin: '10px' ,}}
    // >
    <Grid
      container
      lg={5}
      xs={12}
      style={{
        backgroundColor: postData.patientId ? '#EBEAE7' : '#F4F5F3',
        margin: '7px',
      }}
    >
      <Grid
        item
        lg={8}
        xs={6}
        style={{ padding: '20px', paddingBottom: '5px' }}
      >
        <Grid container>
          <Grid item xs={7} sm={7} xl={7}>
            <Typography
              style={{
                color: '#1C8FD6',
                fontSize: 20,
                fontWeight: 'bold',
                marginBottom: 5,
                cursor: postData.patientId ? 'pointer' : '',
              }}
              onClick={
                postData.patientId
                  ? () => {
                      props.openModal(postData.id)

                      // props.careTeamMemberId=postData.id
                    }
                  : null
              }
            >
              {props.lastName}
              {props.firstName}
            </Typography>
          </Grid>
          {postData.patientId ? (
            <Grid item xs={1} sm={1} xl={1}>
              <EditIcon
                style={{
                  color: '#32A6D6',
                  cursor: 'pointer',
                  marginTop: '5px',
                }}
                onClick={() => {
                  props.openModal(postData.id)
                }}
              ></EditIcon>
            </Grid>
          ) : null}
        </Grid>

        {props.role ? (
          <Grid
            item
            xs={12}
            sm={12}
            xl={12}
            lg={12}
            style={{ marginTop: '7px' }}
          >
            <Grid container>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography
                  style={{ color: 'black', fontSize: 15, fontWeight: 'bold' }}
                >
                  Role:
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ color: 'black', fontSize: '15px' }}>
                &nbsp;{props.role}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        {props.phoneNo ? (
          <Grid
            item
            xs={12}
            sm={12}
            xl={12}
            lg={12}
            style={{ marginTop: '7px' }}
          >
            <Grid container>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography
                  style={{ color: 'black', fontSize: 15, fontWeight: 'bold' }}
                >
                  Phone No:
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ color: 'black', fontSize: '15px' }}>
                  &nbsp;{props.phoneNo}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
      <Grid
        item
        lg={4}
        xs={6}
        style={{ padding: '10px', paddingBottom: '5px' }}
      >
        {props.photoUrl !== undefined &&
        props.photoUrl !== null &&
        props.photoUrl.length > 0 ? (
          <img
            //   //   resizeMode="stretch"
            //   //   src={`${process.env.REACT_APP_API_URL}/${encodeURI(
            //   //     props.photoUrl
            //   //   )}`}
            src={`${process.env.REACT_APP_DOCUMENT_URL}/${encodeURI(
              props.photoUrl
            )}`}
            style={{
              width: '100px',
              height: '100px',
              margin: '5px',
              // marginBottom:,
              borderRadius: '100px',
              //     objectFit: 'stretch',
            }}
          />
        ) : (
          // />
          // <Avatar
          //   sx={{ bgcolor: '#FCBE13' }}
          //   src={`${process.env.REACT_APP_API_URL}/${encodeURI(
          //      props.photoUrl
          //      )}`}
          //                  style={{
          //     height: 120,
          //     width: 120,
          //     borderRadius: '100px',
          //   }}
          // />
          // <img
          //   //   resizeMode="stretch"
          //   //   src={`${process.env.REACT_APP_API_URL}/${encodeURI(
          //   //     props.photoUrl
          //   //   )}`}
          //   src={pic}
          //   style={{
          //     width: '120px',
          //     height: '120px',
          //     margin: '5px',
          //     // marginBottom:,
          //     borderRadius: '100px',
          //     objectFit: 'stretch',
          //   }}
          // />
          <Avatar
            sx={{ bgcolor: '#FCBE13' }}
            src={pic}
            style={{
              height: 100,
              width: 100,
              borderRadius: '100px',
            }}
          />
        )}
      </Grid>
      {props.responsiblities ? (
        <Grid
          item
          xs={12}
          sm={12}
          xl={12}
          lg={12}
          style={{ padding: '20px', paddingBottom: '5px', paddingTop: '5px' }}
        >
          <Grid container>
            <Grid item xs={4} sm={4} xl={4}>
              <Typography
                style={{ color: 'black', fontSize: 15, fontWeight: 'bold' }}
              >
                Responsibilities:
              </Typography>
            </Grid>
            <Grid item xs={8} sm={8} xl={8}>
              <Typography style={{ color: 'black', fontSize: '15px' }}>
              {props.responsiblities}
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} sm={12} xl={12}>
            <Typography
              style={{
                color: 'black',
                fontSize: '15px',
                width: '100%',
                overflow: 'hidden',
                flexWrap: 'wrap',
                wordWrap: 'break-word',
              }}
            >
             <ReadMore>{props.responsiblities}</ReadMore> 
            </Typography>
          </Grid> */}
        </Grid>
      ) : (
        //   <>
        // <Grid>
        //   <SuiTypography style={{ color: 'black', fontSize: 15 ,fontWeight:'bold' }}>
        //   Responsibilities:
        //   </SuiTypography>
        // </Grid>
        // <Grid>{props.responsiblities}</Grid></>
        ''
      )}
    </Grid>
    // </Grid>
  )
}
