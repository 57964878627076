import {
    CircularProgress,
    Grid,
    InputAdornment,
    Snackbar,
    TextField,
    Typography,
} from '@mui/material'
import axios from 'axios'
import React, { useEffect, useReducer, useState, Fragment, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { TabPanel, TabContext, TabList } from '@mui/lab'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import { useAlert } from 'react-alert'
import { DataGrid } from '@mui/x-data-grid'
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import input from '@mui/material/Input'
import Moment from 'moment'
import Avatar from '@mui/material/Avatar'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import SuiInput from "components/SuiInput";

const PastSurgicalHistory = (props) => {

    const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
    const headers = {
      Authorization: auth,
    }
    // const validationSchema = Yup.object({
    //     surgoenName: Yup.string().required("surgoenName is required"),
    //     surgeryType: Yup.string().required("surgeryType is required"),
    
    //   });
    const alert = useAlert()
    const patientId=props.patientId

const fields =[
    "id",
    "patientId",
    "practiceId",
    "isActive",
    "surgoenName",
    "surgeryType",
    "hospitalName",
    "complications",
    "recoveryStatus",
    "postMedications",
    "surgeryDate",
    "createdDate",
    "updatedDate",
    "createdBy",
    "updatedBy"
]
    const defValues = {
        id: 0,
        patientId: 0,
        practiceId: 0,
        isActive:'',
        surgoenName:'',
        surgeryType:'',
        hospitalName:'',
        complications:'',
        recoveryStatus:'',
        postMedications:'',
        surgeryDate:'',
        createdDate:'',
        updatedDate:'',
        createdBy:'',
        updatedBy:''
    }
    const {
        register,
        control,
        handleSubmit,
        setValue,
        reset,
        getValues,
        formState: { errors },
      } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: false,
        shouldUseNativeValidation: false,
        delayError: undefined,
        // resolver: yupResolver(validationSchema),
        defaultValues: defValues,
      });
      const [refresh, setRefresh] = React.useState(false)
      const [submitting, setSubmitting] = useState(false)
      const onSubmit = (data) => {   
        setSubmitting(true)
    
        const postData = {
          ...data,
          patientId:props.patientId

        }
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/PatientMedicalNotes/AddPastSurgicalHistory`,
            postData,
            { headers }
          )
          .then((response) => {
            setRefresh(!refresh)
            setSubmitting(false)
    
             alert.success('Record saved successfully.', {
              type: 'success',
              // onClose: () => {
              //   setOpenNote(false)
              //   setSubmitting(false)
              // },
            })
          })
          .catch((error) => {
            setSubmitting(false)
            alert.success(`Error ${error.message}`, {
              type: 'error',
            })
          })
      }
      useEffect(() => {
        // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    
        axios
          .get
          (`${process.env.REACT_APP_API_URL}/PatientMedicalNotes/getPastSurgicalHistoryByPatientId?patientId=${props.patientId}`, { headers })
          .then(response => { 
            fields.forEach((field) => setValue(field, response.data[field]));
            var surgeryDate = Moment(response.data.surgeryDate).format("YYYY-MM-DD");
            console.log("mk____",surgeryDate)
            setValue('surgeryDate', surgeryDate); 
          })
          .catch(error => {
            console.error('There was an error!', error)
          })
      }, [])
  return (
 <>
     <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                        Surgoen Name
                    </Typography>
                    <Controller
                        name="surgoenName"
                        {...register("surgoenName")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // isClearable
                                size="small"
                                placeholder="enter surgoen name"
                                {...field}
                                fullWidth

                                // error={errors.surgoenName}
                                />
                              )}
                            />
                            {/* <p style={{ color: 'red', fontSize: '14px' }}>{errors.status?.surgoenName}</p> */}
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                        Surgery Type
                    </Typography>
                    <Controller
                        name="surgeryType"
                        {...register("surgeryType")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // isClearable
                                size="small"
                                placeholder="enter surgery type"
                                {...field}
                                fullWidth
                                // error={errors.surgeryType}
                                />
                              )}
                            />
                            {/* <p style={{ color: 'red', fontSize: '14px' }}>{errors.status?.surgeryType}</p> */}
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                        Hospital Name
                    </Typography>
                    <Controller
                        name="hospitalName"
                        {...register("hospitalName")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // isClearable
                                size="small"
                                placeholder="enter hospital name"
                                {...field}
                                fullWidth

                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                        Complications
                    </Typography>
                    <Controller
                        name="complications"
                        {...register("complications")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // isClearable
                                size="small"
                                placeholder="enter Complications"
                                {...field}
                                fullWidth

                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                       Recovery Status
                    </Typography>
                    <Controller
                        name="recoveryStatus"
                        {...register("recoveryStatus")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // isClearable
                                size="small"
                                placeholder="enter recovery status"
                                {...field}
                                fullWidth

                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                       Post Medications
                    </Typography>
                    <Controller
                        name="postMedications"
                        {...register("postMedications")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // isClearable
                                size="small"
                                placeholder="enter post medication"
                                {...field}
                                fullWidth

                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                       Surgery Date
                    </Typography>
                    <SuiInput
                    type="date"
                    name="surgeryDate"
                    fullWidth
                    margin="dense"
                    {...register("surgeryDate")}
                  />    
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button
                         onClick={handleSubmit(onSubmit)}
                            variant="gradient"
                            style={{
                                marginTop: '10px',
                                marginBottom: '10px',
                                float: 'right',
                                marginRight: '5px',
                                marginLeft: 'auto',
                                width: '80px',
                                height: '35px',
                                backgroundColor: '#FCBE13',
                                color: 'black',
                                fontSize: '14px',
                            }}
                        >
                            {/* <SaveIcon
                        fontSize="medium"
                        style={{ color: '#0a9ff0', paddingRight: '5px' }}
                      ></SaveIcon> */}
                            Save
                        </Button>
                    </Grid>
                </Grid>
                </Grid>
 
 
 
 </>
  )
}

export default PastSurgicalHistory
