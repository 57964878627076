export const USER_PRACTICES = "USER_PRACTICES";
export const ALLUSERS= "ALLUSERS";
export const PROVIDERS = "PROVIDERS";
export const FACILITIES = "FACILITIES";
export const USERS_ERROR = "USERS_ERROR";
export const PROVIDER_ERROS = "PROVIDER_ERROS";
export const FACILITIES_ERROS = "FACILITIES_ERROS";
export const ALLPROVIDERS = "ALLPROVIDERS";
export const ALLPROVIDERS_ERROS = "ALLPROVIDERS_ERROS";
export const ALLUSERS_ERROR = "ALLUSERS_ERROR";
const types = {
  USER_PRACTICES,
  PROVIDERS,
  USERS_ERROR,
  PROVIDER_ERROS,
  FACILITIES,
  FACILITIES_ERROS,
  ALLPROVIDERS,
  ALLUSERS,
  ALLUSERS_ERROR,
  ALLPROVIDERS_ERROS
};

export default types;
