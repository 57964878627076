import axios from "axios";
import { ALLUSERS, ALLUSERS_ERROR } from "../types";

export const getAllUsers = () => async (dispatch) => {
  const auth = `Bearer  ${localStorage.getItem("dtt_token")}`;
  const headers = {
    Authorization: auth,
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/Users/getUsers`,
      {
        headers,
      }
    );
 
    dispatch({
      type: ALLUSERS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: ALLUSERS_ERROR,
      payload: console.log(e),
    });
  }

  return 1;
};
