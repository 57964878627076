/* eslint-disable object-shorthand */
// @mui material components
import Card from '@mui/material/Card'

// Soft UI Dashboard React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiInput from 'components/SuiInput'
import SuiButton from 'components/SuiButton'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import { useAlert } from 'react-alert'
import SaveIcon from 'assets/save.png'
import CancelIcon from 'assets/cancel.png'
// Soft UI Dashboard React example components
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import input from '@mui/material/Input'
import { Grid, Snackbar, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useReducer, useState, Fragment } from 'react'
import { createBrowserHistory } from 'history'
import Checkbox from '@mui/material/Checkbox'
import { useLocation } from 'react-router-dom'
import ReactSelect from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { height, style } from '@mui/system'
import Autocomplete from '@mui/material/Autocomplete'
import SuiSelect from 'components/SuiSelect'
import InputMask from 'react-input-mask'
import Moment from 'moment'
import IconButton from '@mui/material/IconButton'
import PhotoCamera from '@mui/icons-material/PhotoCamera'
// import MultipleImageUpload from "./MultipleImageUpload";
import { styled } from '@mui/material/styles'
import PersonIcon from '@mui/icons-material/Person'
import Button from '@mui/material/Button'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import Avatar from '@mui/material/Avatar'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import CircularProgress from '@mui/material/CircularProgress'

const history = createBrowserHistory({ forceRefresh: true })
const Input = styled('input')({
  display: 'none',
})
const fields = [
 
  'city',
  'contactNo',
  'createdBy',
  'createdDate',
  'dOB',
  'email',
  'fullName',
  'gender',
  'id',
  'inactive',
  'active',
  'attendingPhysician',
  'isbillingphysician',
  'isStaff',
  'isVerified',
  'lastLogin',
  'password',
  'profilePhoto',
  'state',
  'updatedBy',
  'updatedDate',
  'userType',
  'zipCode',
  'shortName',
  'phoneNo',
  'fax',
  'emailAddress',
  'clientCreatedDate',
  'clientModifiedDate',
  'defaultFacility',
  'defaultPOS',
  'nPI',
  'address',
  'practiceId',
  'lname',
  'fname',
  'mi',
  'title',
  'sSN',
  'homephone',
  'Cellphone',
  'phoneno',
  'faxno',
  'uPin',
  'firstName',
  'lastName',
  'psychiatristPhysician',
  'base64',
  'photoUrl',
]

const defValues = {
   
  lastName: '',
  firstName: '',
  mi: '',
  title: '',
  active: '',
  sSN: '',
  address: '',
  phoneNo: '',
  fax: '',
  emailAddress: '',
  city: '',
  homephone: '',
  Cellphone: '',
  faxno: '',
  clientcreateddate: '',
  contactNo: '',
  createdBy: '',
  createdDate: '',
  clientCreatedDate: '',
  clientModifiedDate: '',
  practiceId: 0,
  dOB: '',
  email: '',
  uPin: '',
  fullName: '',
  gender: '',
  id: 0,
  inactive: '',
  lastLogin: '',
  password: '',
  profilePhoto: '',
  state: '',
  street: '',
  token: '',
  updatedBy: '',
  updatedDate: '',
  zipCode: '',
  nPI: '',
  psychiatristPhysician: '',
  base64: '',
  photoUrl: '',
  attendingPhysician:'',
}
function handleCancel() {
  history.push('/Providers')
}
function providerRegistration() {
  const location = useLocation()
  const alert = useAlert()
  const [submitting, setSubmitting] = useState(false)
  const [apiError, setApiError] = useState('')
  const zipCodeListStyle = {
    cursor: 'pointer',
    '&:hover': {
      background: '#3b3b3b !important',
      color: 'rgba(5, 152, 236, 0.637) !important',
    },
  }
  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }
  const today = new Date()
   var startDate = new Date();
   startDate.setFullYear(startDate.getFullYear() - 100);
  const validationSchema = Yup.object({
    lastName: Yup.string().required('Please Enter Last Name.'),
    title: Yup.string().required('Please Select Title.'),
    emailAddress: Yup.string().email('Email is invalid.'),
    firstName: Yup.string().required('Please Enter First Name.'),
    dOB: Yup.date()
       .required('Please Select DOB.')
       .nullable()
        .transform(v => (v instanceof Date && !isNaN(v) ? v : null))
       .max(today, "You can't be born in the future!")
       .min(startDate, "You can't be that much old!")
    //nPI: Yup.string().required('NPI is required'),
    // password: Yup.string()
    //   .required('Password is required')
    //   .min(3, 'Password must be at least 3 characters')
    //   .max(15, 'Password must not exceed 15 characters'),
  })
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  })
  const onSubmit = (data) => {
    setApiError('')
    setSubmitting(true)
    const postData = data
    // postData.userType = data.userType ? data.userType.value : ''
    // postData.gender = data.gender ? data.gender.value : ''
    console.log('Data: ', postData)
    console.log(JSON.stringify(postData, null, 2))
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Providers/addProvider`,
        postData,
        { headers }
      )
      .then((response) => {
        setSubmitting(false)
        if (response.data.id) {
          alert.success('Record saved successfully.', {
            type: 'success',
            onClose: () => {
              history.push('/Providers')
            },
          })
        } else {
          setApiError(response.data)
        }
      })
      .catch((error) => {
        setSubmitting(false)
        alert.success(`Error ${error.message}`, {
          type: 'error',
        })
      })
  }

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    let isComponentMounted = true
    const fetchData = async () =>
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/Providers/getProviderById?id=${
            location.state.userId !== undefined ? location.state.userId : 0
          }`,
          { headers }
        )
        .then((response) => {
          if(response.data){
          console.log('response.data: ', response.data)
          if (isComponentMounted) {
            fields.forEach((field) => setValue(field, response.data[field]))
          }
          if (response.data.photoUrl) {
            // console.log('URL: ',`${process.env.REACT_APP_DOCUMENT_URL}`)
            var Url = `${process.env.REACT_APP_DOCUMENT_URL}/${response.data.photoUrl}`
            // console.log('URL: ',Url)
            setImages(Url)
          }

          setPhone(response.data.phoneNo)
          setssn(response.data.sSN)
          setFax(response.data.fax)
          var dateYMD = Moment(response.data.dOB).format('YYYY-MM-DD')
          setdob(dateYMD)
   } }) 
        .catch((error) => {
          console.error('There was an error!', error)
        })
    fetchData()
    return () => {
      isComponentMounted = false
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [])
  ///Auto Compelet
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const loading = open && options.length === 0
  const [zipCodeList, setZipCodeList] = React.useState([])
  const [isZipCode, setIsZipCode] = React.useState(false)
  ////Upload Image code
  const [images, setImages] = React.useState(null)
  //// Delete Pic Code
  const deleteimg = () => {
    setImages(null)
    setValue('photoUrl', '')
  }
  /// Add Image Code
  const onImageChange = (e) => {
    setImages(URL.createObjectURL(e.target.files[0]))
    const file = e.target.files[0]
    convertBase64(file)
    // console.log("64 image",base64)
    console.log('Kdr Sy aya ha Console', URL.createObjectURL(e.target.files[0]))
  }
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    }).then((users) => {
      setValue('base64', users)
    })
  }
  const onChangeHandle = async (value) => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    // console.log(value)

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Settings/Searchcitystate?zipcode=${value}`,
        { headers }
      )
      .then((response) => {
        setZipCodeList(response.data)
        setIsZipCode(true)
        setinsuranceNames(response.data)
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])
  const [dOB, setdob] = React.useState(null)
  console.log('date',dOB)

  const [phone, setPhone] = React.useState()
  const [ssn, setssn] = React.useState()
  const [Fax, setFax] = React.useState()
  return (
    <>
      {submitting ? (
        <CircularProgress
          style={{
            width: '50px',
            height: '50px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginLeft: '-25px',
            marginTop: '-25px',
            zIndex: 1000,
            color: '#FCBE13',
          }}
        />
      ) : null}

      <Grid
        container
        columnSpacing={1}
        alignContent="center"
        justifyContent="center"
      >
        <Grid container>
          <Grid item xs={3}>
            <Grid container style={{ marginLeft: '58px' }}>
              <Grid item xs={12}>
                <label htmlFor="icon-button-file">
                  <Input
                    id="icon-button-file"
                    type="file"
                    onChange={onImageChange}
                  />
                  <Avatar
                    sx={{ bgcolor: '#FCBE13' }}
                    src={images}
                    style={{
                      height: 120,
                      width: 120,
                      borderRadius: '100px',
                    }}
                  >
                    <AddAPhotoIcon
                      fontSize="large"
                      style={{ color: 'black', marginBottom: '5px' }}
                    ></AddAPhotoIcon>
                  </Avatar>
                </label>
              </Grid>
              {images && images.length > 0 ? (
                <Button
                  onClick={deleteimg}
                  variant="gradient"
                  // disabled={submitting}
                  style={{
                    marginLeft: '20px',
                    width: '70px',
                    height: '33px',
                    color: 'black',
                    fontSize: '14px',
                  }}
                  // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                >
                  <DeleteOutlinedIcon
                    fontSize="medium"
                    style={{ color: 'red', marginBottom: '5px' }}
                  ></DeleteOutlinedIcon>
                  Remove
                </Button>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={9}>
          <Grid>
              <p style={{ color: 'red', fontSize: '14px' }}>{apiError}</p>
            </Grid>
            <Grid container columnSpacing={1}>
            
              <Grid item xs={4}>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontSize="15px"
                >
                  Last Name
                </SuiTypography>
              </Grid>
              <Grid item xs={4}>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontSize="15px"
                >
                  First Name
                </SuiTypography>
              </Grid>
              <Grid item xs={4}>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontSize="15px"
                >
                  MI
                </SuiTypography>
              </Grid>
              <Grid item xs={4} style={{ width: '300px' }}>
                <SuiInput
                  placeholder="Please enter last name"
                  name="lname"
                  label="lname"
                  fullWidth
                  margin="dense"
                  {...register('lastName')}
                  error={errors.lastName}
                />
                <p style={{ color: "red", fontSize:"14px" }}>{errors.lastName?.message}</p>
              </Grid>
              <Grid item xs={4} style={{ width: '300px' }}>
                <SuiInput
                  placeholder="Please enter First Name"
                  name="fullName"
                  label="fullName"
                  fullWidth
                  margin="dense"
                  {...register('firstName')}
                  error={errors.firstName}
                />
               <p style={{ color: "red", fontSize:"14px" }}>{errors.firstName?.message}</p>
              </Grid>
              <Grid item xs={4} style={{ width: '300px' }}>
                <SuiInput
                  placeholder="Please enter MI"
                  name="mi"
                  label="mi"
                  fullWidth
                  margin="dense"
                  {...register('mi')}
                  error={errors.mi}
                />
              </Grid>
              <Grid item xs={4} style={{ width: '300px' }}>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontSize="15px"
                >
                  Title
                </SuiTypography>
              </Grid>
              <Grid item xs={4} style={{ width: '300px' }}>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontSize="15px"
                >
                  DOB
                </SuiTypography>
              </Grid>

              <Grid item xs={4} style={{ width: '300px' }}>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontSize="15px"
                >
                  SSN
                </SuiTypography>
              </Grid>

              <Grid item xs={4} style={{ width: '300px' }}>
                <Controller
                  name="title"
                  {...register('title')}
                  control={control}
                  render={({ field }) => (
                    <SuiSelect
                      {...field}
                      value={{ label: getValues('title') }}
                      error={errors.title}
                      onChange={(value) => setValue('title', value.label)}
                      options={[
                        {
                          value: 'Behavioral Health Care Manager',
                          label: 'Behavioral Health Care Manager',
                        },
                        {
                          value: 'Billing Physician',
                          label: 'Billing Physician',
                        },
                        {
                          value: 'Psychiatry Consultant',
                          label: 'Psychiatry Consultant',
                        },
                        {
                          value: 'Billing Advanced Practice Provider',
                          label: 'Billing Advanced Practice Provider',
                        },
                        { value: 'Counselor', label: 'Counselor' },
                        { value: 'Nurse', label: 'Nurse' },
                        {
                          value: 'Medical Assistant',
                          label: 'Medical Assistant',
                        },
                      ]}
                    />
                  )}
                />
                                <p style={{ color: "red", fontSize:"14px" }}>{errors.title?.message}</p>

              </Grid>
              <Grid item xs={4} style={{ width: '300px' }}>
                <SuiInput
                  type="date"
                  placeholder="Enter DOB"
                  value={dOB?dOB:''}
                  onChange={(e) => {
                    setValue('dOB', e.target.value)
                    setdob(e.target.value)
                  }}
                  error={errors.dOB}
                />
                 <p style={{ color: "red", fontSize:"14px" }}>{errors.dOB?.message}</p>
              </Grid>

              <Grid item xs={4} style={{ width: '300px' }}>
                <InputMask
                  mask="999-99-9999"
                  disabled={false}
                  value={ssn}
                  style={{ height: '30px' }}
                  onChange={(e) => {
                    setValue('sSN', e.target.value)
                    setssn(e.target.value)
                  }}
                >
                  {() => (
                    <SuiInput
                      id="outlined-basic"
                      fullWidth
                      margin="dense"
                      placeholder="Enter SSN"
                    />
                  )}
                </InputMask>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Address
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Email
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Phone#
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter Address"
            name="address"
            label="address"
            fullWidth
            margin="dense"
            {...register('address')}
            error={errors.address}
          />
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter EmailAddress"
            name="emailAddress"
            label="emailAddress"
            fullWidth
            margin="dense"
            {...register('emailAddress')}
            error={errors.emailAddress}
          />
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <InputMask
            mask="+1 999-999-9999"
            disabled={false}
            value={phone}
            style={{ height: '30px' }}
            onChange={(e) => {
              setValue('phoneNo', e.target.value)
              setPhone(e.target.value)
            }}
          >
            {() => (
              <SuiInput
                id="outlined-basic"
                fullWidth
                margin="dense"
                placeholder="Enter phoneNo"
              />
            )}
          </InputMask>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Zip Code
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            City
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            State
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
        <SuiInput
                  type="zipCode"
                  placeholder="Enter ZipCode"
                  {...register('zipCode')}
            
                />
          {/* <SuiInput
            type="input"
            placeholder="ZipCode"
            {...register('zipCode')}
            onKeyUp={(ev) => {
              // dont fire API if the user delete or not entered anything
              if (ev.target.value !== '' || ev.target.value !== null) {
                onChangeHandle(ev.target.value)
              }
            }}
          /> */}
          {/* {isZipCode ? (
            <div
              className="col-xs-3"
              style={{
                padding: '14px 16px',
                fontSize: '0.875rem',
                color: '#67748e',
                borderRadius: '5px',
                background: 'white',
                textAlign: 'justify',
                textJustify: 'inter-word',
                position: 'absolute',
                zIndex: '99',
                width: '25%',
                border: '1px solid #6cb5f9',
              }}
            >
              {zipCodeList.map((s, index) => (
                <p
                  // attribZip={s.zipCode}
                  // attribState={s.state}
                  // attribCity={s.city}
                  // attribIndex={index}

                  style={zipCodeListStyle}
                  onClick={(ev) => {
                    console.log('evvv', ev)
                    // var sel=ev.target.attributes['attribIndex']
                    console.log('Selected: ', zipCodeList[index])
                    setValue('zipCode', zipCodeList[index].zipCode)
                    setValue('city', zipCodeList[index].city)
                    setValue('state', zipCodeList[index].state)
                    setZipCodeList([])
                    setIsZipCode(false)
                  }}
                  onBlur={(ev) => {
                    setZipCodeList([])
                    setIsZipCode(false)
                  }}
                  onChange={(ev) => {
                    setZipCodeList([])
                    setIsZipCode(false)
                  }}
                >
                  {s.zipCode} <strong>City:</strong> {s.city} <strong>State:</strong> {s.state}
                </p>
              ))}
            </div>
          ) : (
            ''
          )} */}
        </Grid>

        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            type="input"
            // readOnly
            placeholder="Enter city"
            {...register('city')}
          />
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            type="input"
            // readOnly
            placeholder="Enter state"
            {...register('state')}
          />
        </Grid>

        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Fax
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            NPI
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            UPIN
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <InputMask
            mask=" 999-9999999"
            disabled={false}
            value={Fax}
            style={{ height: '30px' }}
            onChange={(e) => {
              setValue('fax', e.target.value)
              setFax(e.target.value)
            }}
          >
            {() => (
              <SuiInput
                id="outlined-basic"
                fullWidth
                margin="dense"
                placeholder="Enter Fax"
              />
            )}
          </InputMask>
          {/* <SuiInput
            placeholder="Please enter Fax"
            name="fax"
            label="fax"
            fullWidth
            margin="dense"
            {...register('fax')}
            error={errors.fax}
          /> */}
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter NPI"
            name="npi"
            label="npi"
            fullWidth
            margin="dense"
            {...register('nPI')}
            error={errors.nPI}
          />
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter UPIN"
            fullWidth
            margin="dense"
            {...register('uPin')}
            error={errors.uPin}
          />
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Active
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Attending Physician
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Psychotherapy Privilege
          </SuiTypography>
        </Grid>
        <Grid item xs={1} style={{ width: '300px' }}>
          <SuiInput
            type="checkbox"
            margin="dense"
            {...register('active')}
            error={errors.active}
          />
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}></Grid>
        <Grid item xs={1} style={{ width: '300px' }}>
          <SuiInput
            type="checkbox"
            margin="dense"
            {...register('attendingPhysician')}
            error={errors.attendingPhysician}
          />
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}></Grid>
        <Grid item xs={1} style={{ width: '300px' }}>
          <SuiInput
            type="checkbox"
            margin="dense"
            {...register('psychiatristPhysician')}
          />
        </Grid>
        <Grid item xs={3} style={{ width: '300px' }}></Grid>
        <Grid item xs={12} lg={12} sm={12}>
          <Button
            onClick={handleCancel}
            variant="gradient"
            disabled={submitting}
            color="warning"
            size="small"
            style={{
              marginTop: '10px',
              float: 'right',
              width: '90px',
              height: '35px',
              backgroundColor: '#FCBE13',
              color: 'black',
              fontSize: '14px',
              marginRight: '10px',
            }}
          >
            <CancelOutlinedIcon
              fontSize="medium"
              style={{ color: 'red', paddingRight: '5px' }}
            ></CancelOutlinedIcon>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            variant="gradient"
            disabled={submitting}
            style={{
              marginTop: '10px',
              float: 'right',
              marginRight: '20px',
              width: '80px',
              height: '35px',
              backgroundColor: '#FCBE13',
              color: 'black',
              fontSize: '14px',
            }}
          >
            <SaveOutlinedIcon
              fontSize="medium"
              style={{
                color: '#1a82ad',
                paddingRight: '5px',
                paddingBottom: '2px',
              }}
            ></SaveOutlinedIcon>
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default providerRegistration
