/**
=========================================================
* Soft UI Dashboard React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react'
import Chip from '@mui/material/Chip';
import LetteredAvatar from 'react-lettered-avatar';
// react-router components
import { useLocation, Link } from 'react-router-dom'

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// @material-ui core components
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import Icon from '@mui/material/Icon'
import Avatar from '@mui/material/Avatar'

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiInput from 'components/SuiInput'

// Soft UI Dashboard PRO React example components
import Breadcrumbs from 'examples/Breadcrumbs'
import MenuItem from 'examples/Items/MenuItem'
import PracticeMenuItem from 'examples/Items/PracticeMenuItem'
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from 'examples/Navbars/DashboardNavbar/styles'

// Soft UI Dashboard PRO React context
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from 'context'

// Images
// import team2 from 'assets/images/team-2.jpg'
import logoSpotify from 'assets/images/small-logos/logo-spotify.svg'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { createBrowserHistory } from 'history'
import { Typography } from '@mui/material'
import axios from 'axios'

const history = createBrowserHistory({ forceRefresh: true })
function DashboardNavbar({ absolute, light, isMini, routes }) {
  var img = localStorage.getItem('dtt_user_img')
  var Url = `${process.env.REACT_APP_DOCUMENT_URL}/${(img)}`
  const [navbarType, setNavbarType] = useState()
  const [controller, dispatch] = useSoftUIController()
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } =
    controller
  const [openMenu, setOpenMenu] = useState(false)
  const [openPracMenu, setOpenPracMenu] = useState(false)
  const dispatchRedux = useDispatch()
  const location = useLocation().pathname.split('/').slice(1)
  const userPractices = useSelector(
    (state) => (state.users.loading ? [] : state.users.USER_PRACTICES),
    shallowEqual
  )
  const [logedIn, setLogedIn] = useState()
  useEffect(() => {}, [logedIn])
  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky')
    } else {
      setNavbarType('static')
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      )
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener('scroll', handleTransparentNavbar)

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar()

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleTransparentNavbar)
  }, [dispatch, fixedNavbar])

  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav)
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator)
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget)
  const handleCloseMenu = () => setOpenMenu(false)
  const handleOpenPracMenu = (event) => setOpenPracMenu(event.currentTarget)
  function handleClosePracMenu(practiceId) {
    // console.warn('clickeeedd', practiceId)
    setOpenPracMenu(false)
    if (practiceId !== 0) {
      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/Practices/switchPractice?emailAddress=${localStorage.getItem(
            'dtt_user_email'
          )}&practiceId=${practiceId}`,
          { headers }
        )
        .then((response) => {
          console.warn(response.data)
          localStorage.setItem('dtt_token', response.data.token)
          localStorage.setItem('dtt_user_email', response.data.email)
          localStorage.setItem('dtt_user', response.data.email)
          localStorage.setItem(
            'dtt_user_default_facility',
            response.data.defaultFacilityId
          )
          localStorage.setItem(
            'dtt_user_default_practice',
            response.data.defaultPracticeId
          )

          history.push('/PatientSearch')
        })
        .catch((error) => {
          console.error('There was an error!', error)
        })
    }
  }

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <MenuItem
        title={['Logout']}
        color="primary"
        onClick={() => logOutClickHandler()}
      />
    </Menu>
  )
  const renderPractices = () => (
    <Menu
      anchorEl={openPracMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={Boolean(openPracMenu)}
      onClose={() => handleClosePracMenu(0)}
      sx={{ mt: 2 }}
    >
      {userPractices.map((ele, i) =>
        parseInt(ele.practiceId, 10) ===
        parseInt(localStorage.getItem('dtt_user_default_practice'), 10) ? (
          <PracticeMenuItem
            title={[ele.practiceName]}
            color="primary"
            onClick={() => handleClosePracMenu(ele.practiceId)}
          />
        ) : (
          <PracticeMenuItem
            title={[ele.practiceName]}
            color="secondary"
            onClick={() => handleClosePracMenu(ele.practiceId)}
          />
        )
      )}
    </Menu>
  )

  const logOutClickHandler = () => {
    localStorage.removeItem('dtt_token')
    localStorage.removeItem('dtt_user_email')
    localStorage.removeItem('dtt_user_name')
    localStorage.removeItem('dtt_user')
    localStorage.removeItem('dtt_isPsychotherapyPriviledges')
    localStorage.removeItem('dtt_user_default_facility')
    localStorage.removeItem('dtt_user_default_practice')
    history.push('/login')
    window.location.reload(false)
  }
  const route = routes.filter((newVal) => {
    return newVal.route === `/${location}`
    // comparing category for displaying data
  })
  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color="primary"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SuiBox
          color="primary"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <Breadcrumbs
            icon="home"
            title={route[route.length - 1]}
            route={route}
            // routes={routes}
            // light={light}
          />
        </SuiBox>
        {isMini ? null : (
          <SuiBox sx={(theme) => navbarRow(theme, { isMini })}>
            {/* <SuiBox pr={1}>
              <SuiInput
                placeholder="Type here..."
                icon={{ component: 'search', direction: 'left' }}
              />
            </SuiBox> */}
            <SuiBox color={light ? 'white' : 'inherit'}>
              <IconButton
                sx={navbarIconButton}
                size="small"
                onClick={handleOpenPracMenu}
              >
                {userPractices.map((ele, i) =>
                  parseInt(ele.practiceId, 10) ===
                  parseInt(
                    localStorage.getItem('dtt_user_default_practice'),
                    10
                  ) ? (
                    <>
                    <SuiTypography
                      variant="button"
                      fontWeight="medium"
                      marginRight="2px"
                      color={light ? 'white' : 'dark'}
                    >
                <Chip style={{height:'30px',fontWeight:'bold',cursor:'pointer',}}
                variant="outlined"
                      avatar={<Avatar><Icon
                    sx={({ palette: { dark, white } }) => ({
                      color: light ? white.main : dark.main,
                    })}
                  >
                    table_rows
                  </Icon></Avatar>} label={ele.practiceName}>
                        
                        
                  </Chip>

                    </SuiTypography>
                    
                  </>
                  ) : (
                    ''
                  )
                )}
                

                
              </IconButton>
              {renderPractices()}
              <IconButton
                size="small"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={light ? 'text-white' : 'text-dark'}>
                  {miniSidenav ? 'menu_open' : 'menu'}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <SuiTypography
                  variant="button"
                  fontWeight="bold"
                  color={light ? 'white' : 'dark'}
                >
                                       <Chip style={{height:'30px',cursor:'pointer',}}
                                       label= {localStorage.getItem('dtt_user_name')}
                                       variant="outlined"
                                         avatar={img != 'null' && img != '' ?
                                         <img 
                                            src={Url}
                                            style={{
                                              width: '30px',
                                               height: '30px',
                                               cursor:'pointer',
                                               borderRadius:'100px',padding:'2px'
                                              }}
                                              />:<Avatar><Icon className={light ? 'text-white' : 'text-dark'}>
                                               account_circle
                                            </Icon></Avatar>
                                         }
                                         />

                </SuiTypography>
                
              </IconButton>

              {renderMenu()}
            </SuiBox>
          </SuiBox>
        )}
      </Toolbar>
    </AppBar>
  )
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
}

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
}

export default DashboardNavbar
