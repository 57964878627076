import * as React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import SuiBox from 'components/SuiBox'
import SuiButton from 'components/SuiButton'
import SuiTypography from 'components/SuiTypography'
import { Typography } from '@mui/material'
import SuiInput from 'components/SuiInput'
// import { getAllProviders } from 'redux/Actions/allProvidersActions'
import { useDispatch } from 'react-redux'
import FormControl from '@mui/material/FormControl'

import { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import axios from 'axios'
import AddIcon from 'assets/add.png'
import { Grid, Snackbar, TextField } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert'
import { createBrowserHistory } from 'history'
import customStyle from '../assets/customStyle.css'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Button from '@mui/material/Button';
import Demographics from './Demographics'
import CircularProgress from "@mui/material/CircularProgress";
// import MUIDataTable from "mui-datatables";
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import SuiSelect from 'components/SuiSelect'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined'
import { shallowEqual, useSelector } from "react-redux";
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import InputMask from 'react-input-mask'


const history = createBrowserHistory({ forceRefresh: true })
function InActive(props) {
  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  
  const headers = {
    Authorization: auth,
  }
const validationSchema = Yup.object({})
const defValues = {
  Id: '',
  startTime: '',
  endTime: '',
  userName: '',
}
const {
  register,
  control,
  handleSubmit,
  setValue,
  getValues,
  formState: { errors },
} = useForm({
  mode: 'onBlur',
  reValidateMode: 'onChange',
  context: undefined,
  criteriaMode: 'firstError',
  shouldFocusError: true,
  shouldUnregister: false,
  shouldUseNativeValidation: false,
  delayError: undefined,
  resolver: yupResolver(validationSchema),
  defaultValues: defValues,
})
const openReferralRegistration = (event, id) => {
  history.push({
    pathname: `/ReferralRegistration`,
    state: { referral: id, },
  })
  window.location.reload(false)
}
const [open1, setOpen1] = React.useState(false)
const [submitting, setsubmitting] = React.useState(false)
const handleOpen1 = () => setOpen1(true)
const handleClose1 = () => {
  // setpatientId(0)
  setOpen1(false)
}
const [refresh, setRefresh] = useState(false)
const [firstName, setfirstName] = useState('')
const [lastName, setlastName] = useState('')
const [cellPhone, setcellPhone] = useState('')
const [facilityId, setfacilityId] = useState('')
const [providerId, setproviderId] = useState('')
const [patientId, setPatientId] = useState('')
const [patientID, setPatientID] = React.useState("");
const [fphone, setFphone] = useState('')
const [smoking, setSmoking] = useState('')
const handlesmokingChange = (event) => {
  setSmoking(event.value)
}  
const [CareTeam, setCareTeam] = useState([])

const [careTeam_id, setCareTeam_id] = useState('')
  const handlecareteam_id = (event) => {
    setCareTeam_id(event.value)
  }

const [appointStatus, setAppointStatus] = useState('');
const [providerRedux, setprovidersRedux] = useState([]);
const [fstartTime, setFstartTime] = useState('')
const [fendTime, setFendTime] = useState('')
const [femail, setFemail] = useState('')
const dispatch = useDispatch()
const [callRedux, setCallRedux] = useState(true)
const [fssn, setFssn] = useState('')
const [Program1, setProgram1] = useState({ value: '', label: 'All' });


const [dOB, setdOB] = useState('')
const [reduxLoaded, setreduxLoaded] = useState(false);
const [timeLogRows, setTimeLogRows] = useState(null)
const [noRecordsFound, setNoRecordsFound] = useState('Please Apply Filter')

const [openNote, setopenNote] = useState(true)
const [patientData, setPatientData] = React.useState([]);
const [patientSelected, setPatientSelected] = React.useState(false);
const [patientNamee, setPatientNamee] = useState("");

const patientName = props.patientName || (location.state ? location.state.patientName : '');
const appointmentId = props.sendingId || props.pId;
const _patientId = props.patientId ||(location.state ? location.state.patientId  : null) || props.patientIds ||(location.state ? location.state.patientIds  : null);
console.log(_patientId,'_patientId,,')
const patientLastName=props.patientLastName
const handleDetails = () => {
  setOpenDetails(true);
  setPatientID("");
};
const [patient, setPatient] = React.useState(false);
const [searchText, setSearchText] = useState("");

const [program, setprogram] = useState('')
const providersRedx = useSelector(
  (state) => state.providers.NAMES,
  shallowEqual
);
const allProvidersRedx = useSelector(
  (state) => (state.allProviders.loading ? [] : state.allProviders.NAMES),
  shallowEqual
)
const [allProviders, setAllProviders] = useState([])
useEffect(() => {
  // POST request using axios inside useEffect React hook

  var allProvidersRedux = []
  // console.log('reduxxx', providersRedx)
  allProvidersRedx.forEach((field) => {
    allProvidersRedux.push({
      label: field.lastName + ', ' + field.firstName,
      value: field.id,
    })
  })

  setAllProviders(allProvidersRedux)
  //     if (dtt_userType != 'Admin')
  //     {
  //       setValue('providerId', providersRedux[0].value)
  //     }
  // console.log('provideredux',providersRedux[0].value)

  // empty dependency array means this effect will only run once (like componentDidMount in classes)
}, [])
const keyDown = (e) => {
  if (e.key === 'Enter') {
    getdata(e)
  }
}
// useEffect(() => {
//   if (callRedux) dispatch(getAllProviders())
// }, [dispatch, callRedux])
const zipCodeListStyle = {
  cursor: "pointer",
  hover: {
    background: "#3b3b3b !important",
    color: "rgba(5, 152, 236, 0.637) !important",
  },
  color: "green",
  selection: {
    background: "#D7624E",
    color: "white",
  },
};
// useEffect(() => {
//   if (callRedux) dispatch(getAllProviders())
// }, [dispatch, callRedux])
const [isShown, setIsShown] = useState(false)
const handleClick = (event) => {
  // 👇️ toggle shown state
  setIsShown((current) => !current)
  // 👇️ or simply set it to true
  // setIsShown(true);
}
const AppointmentStatus = [
  { value: 'All', label: 'All' },
  { value: 'Checkedin', label: 'Checkedin' },
  { value: 'CheckedOut', label: 'CheckedOut' },
  { value: 'Confirmed', label: 'Confirmed' },
  { value: 'Late', label: 'Late' },
  { value: 'No-Show', label: 'No-Show' },
  { value: 'ReSheduled', label: 'ReSheduled' },
  { value: 'Scheduled', label: 'Scheduled' },
  { value: 'Canceled', label: 'Canceled' },
]
const onFilterClear = (event) => {
  setCareTeam_id('');
  setTimeLogRows(null);
  setAppointStatus('');
  setFstartTime('');
  setFendTime('');
  setproviderId('');
  setPatientNamee('');
  setFemail('');
  setFssn('');
  setProgram1('');
  setFphone('');
  setSmoking('');
  setCareTeam('');
  setPatientData([]);
  setValue("patientName", ""); 
  setSearchText(""); 
  setPatient(false); 
  setPatientSelected(false); 
  setPatientID("");
};

const onChangeHandle = async (value) => {
  // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
  console.log(value);
  const sendData = {
    id: 0,
    lastName: value,
    firstName: "",
    ssn: "",
    status: "",
    cellPhone: "",
    address: "",
    dob: "",
  };
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/Patients/searchPatient`,
      sendData,
      { headers },
      setPatientSelected(false)
    )
    .then((response) => {
      // console.log('responses: ', response.data)

      // const patientName = response.data;
      setPatientData(response.data);
      setPatient(true);

      // console.log('patient: ', response.data)

      // setOptions(Object.keys(countries).map(key => key.zipCode))
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });

  // const response = await fetch(
  //   "https://country.register.gov.uk/records.json?page-size=5000"
  // );

  // const countries = await response.json();
  // setOptions(Object.keys(countries).map(key => countries[key].item[0]));
};
const onFilterSearch = (event) => {
  setTimeLogRows(null)
 
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/Patients/SearchInActivePatient?patientId=${patientID?patientID:patientId}&FromDate=${fstartTime}&ToDate=${fendTime}&ssn=${fssn}&providerId=${careTeam_id}&cellPhone=${fphone}&emailAddress=${femail}&program=${Program1.value}&careTeam_id=${careTeam_id}&smokingStatus=${smoking}`,
      { headers }
    )
    .then((response) => {
      setRows(response.data);
      if (response.data === null || response.data.length === 0) {
        setNoRecordsFound(true);
        setNoRecordsFound('No Record Found')
      } else {
        setNoRecordsFound(false);
      }
    })
    .catch((error) => {
      console.error('There was an error!', error);
    });
};

useEffect(() => {
  var providerRedux = [];
  providersRedx.forEach((field) => {
    providerRedux.push({
      label: field.name,
      value: field.id,
    });
  });
  setprovidersRedux(providerRedux);
  console.log(providerRedux);
  setreduxLoaded(true);
}, []);
const columns = [
    {
        field: 'id',
        headerClassName: 'super-app-theme--header',
        headerName: 'ID',
        width: 80,
        // flex: 1,
      },
      {
        field: 'firstName',
        headerName: 'First Name',
        headerClassName: 'super-app-theme--header',
        cellClassName: 'gridCell',
        sortable: false,
        flex: 1,
        renderCell: (cellValues) => (
          <SuiButton
            size="small"
            variant="text"
            color="dark"
            onClick={(event) => {setfirstName(cellValues.row.firstName)
              setlastName(cellValues.row.lastName)
              setcellPhone(cellValues.row.cellPhone)
              setdOB(cellValues.row.dOB)
              setprogram(cellValues.row.program)
              setfacilityId(cellValues.row.facilityId)
              setproviderId(cellValues.row.providerId)
              setPatientId(cellValues.row.id)
              handleOpen1()
              // 1111
              // openUserRegistration(event, cellValues.row.id)
            }}
            style={{ color: '#20c5ec' }}
          >{`${cellValues.row.firstName}`}</SuiButton>
        ),
      },
    
      {
        field: 'lastName',
        headerClassName: 'super-app-theme--header',
        headerName: 'Last Name',
        // width: 205,
        flex: 1,
      },
      // {
      //   field: "ProviderName",
      //   headerClassName: "super-app-theme--header",
      //   headerName: "Provider",
      //   width: 160,
      //   flex: 1,

      // },
      {
        field: 'dob',
        headerClassName: 'super-app-theme--header',
        headerName: 'DOB',
        type: 'date',
        // width: 160,
        flex: 1,
      },
      {
        field: 'program',
        headerClassName: 'super-app-theme--header',
        headerName: 'Program',
        sortable: true,
        // width: 160,
        flex: 1,
      },
      {
        field: 'status',
        headerClassName: 'super-app-theme--header',
        headerName: 'Status',
        sortable: true,
        // width: 100,
        flex: 1,
      },
      // {
      //   // field: '',
      //   // headerName: 'Delete',
      //   sortable: true,
      //   headerClassName: 'super-app-theme--header',
      //   // flex: 0.5,
      //   width: 40,
      //   renderCell: (cell) => (
      //     <DeleteIcon
      //       style={{
      //         marginRight: '5px',
      //         float: 'right',
      //         marginTop: '5px',
      //       }}
      //       color="black"
            
      //       onClick={(cellValues) =>
      //        {
      //         // console.log('Cell: ', cell)
      //         confirmAlert({
      //           message: 'Do you want to delete the record.',
      //           closeOnEscape: true,
      //           closeOnClickOutside: true,
      //           overlayClassName: 'overlay-custom-class-name',
      //           buttons: [
      //             {
      //               label: 'Yes',
      //               onClick: () => {
      //                 const postData = {
                    
      //                   id: cell.row.id,
      //                   inactive: true,
            
      //                 }
    
      //                 axios
      //                   .post(
      //                     `${process.env.REACT_APP_API_URL}/Patients/addPatient`,
      //                     postData,
      //                     {
      //                       headers,
      //                     }
      //                   )
      //                   .then((response) => {
      //                     setRefresh(!refresh)
                   
      //                     console.log('response.data: ', response.data)
      //                   })
      //                   .catch((error) => {
      //                     console.error('There was an error!', error)
      //                   })
      //               },
      //             },
      //             {
      //               label: 'No',
      //             },
      //           ],
      //         }
      //         )
      //       }}
      //     ></DeleteIcon>
      //   ),
      // },
 

]


  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px',
    },
  }
  const styleDetails = {
    width: '90%',
    height: '95%',
    bgcolor: '#F0FFFF',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 30,
    right: 0,
    margin: 'auto',
    marginTop: '10px',
    paddingBottom: '10px',
    padding: '20px',
    overflow: 'auto'
  }
  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 5,
    background: 'white',
    fontSize: '13.6px !important',
    color: '#8392ab !important',
    borderColor: 'rgba(5, 152, 236, 0.637) !important',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#FFFFC2',
      borderRadius: 1.8,
    },
  }
  const [rows, setRows] = useState(null)

  useEffect(() => {setsubmitting(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/Patients/InActivePatients`, {
        headers,
      })
      .then((response) => {
        const getData = []
        response.data.forEach((element) => {
          if (element.id !== 0) getData.push(element)
        })
        console.warn(getData)
        setsubmitting(false)
        // setRows(getData)

      })
      .catch((error) => {
        console.error('There was an error!', error)
      })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])
  return (
    <>
    
    <Modal
    open={open1}
    onClose={handleClose1}
    aria-labelledby='modal-modal-title'
    aria-describedby='modal-modal-description'
  >
    <Box sx={styleDetails}>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} style={{ fontWeight: 500 }}>
          <CancelOutlinedIcon
            onClick={handleClose1}
            fontSize='medium'
            style={{ color: 'red', marginBottom: '10px', float: 'right' }}
          ></CancelOutlinedIcon>
        </Grid>
        <Demographics firstName={firstName}  lastName={lastName} program={program}
        DOB={dOB} providerId={providerId} handleClose1={handleClose1} patientId={patientId} facilityId={facilityId} cellPhone={cellPhone}
        ></Demographics>
      </Grid>
    </Box>
  </Modal>
  <Grid
            spacing={1}
            alignContent="left"
            justifyContent="left"
            style={{ width: '100% !important', marginLeft: '0px !important' }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>InActive Patients</Typography>

            </AccordionSummary>
            <AccordionDetails>
              <div>
                <Grid container spacing={1}>
                <Grid item xs={3} sm={3} xl={2.2}>
  <Typography style={{ fontSize: '14px' }}>
    Patient
  </Typography>
  <SuiInput
    type="input"
    placeholder={patientLastName || patientName ? `${patientLastName == null ? '' : patientLastName} ${patientName}` : 'Patient'}
    {...register("patientName")}
    error={errors.patientName}
    disabled={!!appointmentId || patientName || patientLastName}  // Disable if appointmentId is present or patientName/patientLastName is not empty
    onKeyUp={(ev) => {
      console.log("ee:__", ev);
      setSearchText(ev.target.value ? ev.target.value : `${patientLastName} ${patientName}`);
      setPatientData([]);
      // Don't fire API if the user deletes or doesn't enter anything
      if (ev.target.value !== null && ev.target.value !== "" && ev.target.value.length > 0) {
        onChangeHandle(ev.target.value);
        setPatient(ev.target.name)
        console.log("ee:__1", ev);

      } else {
        console.log("ee:__2", ev);

        setPatientSelected(false);
        setPatient(false);
        // setSearchText('')
      }
    }}
  />

  <p style={{ color: "red", fontSize: "13px" }}>
    {errors.patientName?.message}
  </p>
  {patient ? (
    <div
      className="col-xs-6"
      style={{
        padding: "14px 16px",
        fontSize: "0.875rem",
        color: "#67748e",
        borderRadius: "5px",
        background: "white",
        textAlign: "justify",
        textJustify: "inter-word",
        position: "absolute",
        zIndex: "99",
        width: "39%",
        border: "1px solid #6cb5f9",
        maxHeight: "250px",  // Add a fixed height
        overflowY: "auto"  // Enable vertical scroll bar
      }}
    >
      {patient
        ? patientData.map((s, index) => {
          const pat = `${s.lastName}, ${s.firstName}`;
          const patLower = pat.toLowerCase();
          const i = patLower.indexOf(searchText);
          console.log("searchText:", searchText);
          const p1 = pat.substring(0, i);
          const p = pat.substring(i, searchText.length + i);
          const p2 = pat.substring(i + p.length, patLower.length);
          return (
            <p
              style={zipCodeListStyle}
              onClick={(ev) => {
                setValue(
                  "patientName",
                  `${patientData[index].lastName}, ${patientData[index].firstName}`
                );
                console.log(s,'_____')
                setPatientID(s.id)
                setValue("patientId", patientData[index].id);
                // setPatientID(patientData[index].id);
                setPatientData([]);
                setPatientSelected(true);
                setPatient(false);
                setPatientNamee(
                  `${patientData[index].lastName}, ${patientData[index].firstName}`
                );
              }}
              onBlur={(ev) => {
                setPatientData([]);
                setPatient(false);
              }}
              onChange={(ev) => {
                setPatientData([]);
                setPatientSelected(false);
                setPatient(false);
                setPatientNamee("");
              }}
            >
              <span>{p1}</span>
              <span style={{ backgroundColor: "yellow" }}>
                {p}
              </span>
              <span>{p2}</span>
            </p>
          );
        })
        : null}
    </div>
  ) : (
    ""
  )}
</Grid>
<Grid item xs={3} sm={3} xl={2.2}>
<Typography style={{ fontSize: '14px' }}>Provider</Typography>
            <SuiSelect
              // isClearable
              // {...field}
              // error={errors.providerId}
              // default
              value={allProviders.filter(function (option) {
                return option.value === careTeam_id
              })}
              // onChange={(value) => {
              //   setValue('providerId', value.value)
              // }}

              onChange={handlecareteam_id}
              options={allProviders}
              onKeyDown={keyDown}
            />
            {/* <InputMask
            mask="999-99-9999"
            disabled={false}
            value={fssn}
            onKeyDown={keyDown}
            style={{ height: '30px' }}
            onChange={(e) => {
              // setValue('ssn', e.target.value)
              setFssn(e.target.value)
            }}
          >
            {() => (
              <SuiInput
                id="outlined-basic"
                fullWidth
                margin="dense"
                placeholder="Enter SSN"
              />
            )}
          </InputMask> */}
          </Grid>
                
                            
                  <Grid item xs={3} sm={3} xl={2.2}>
                    <Typography style={{ fontSize: '14px' }}>
                      From Date
                    </Typography>
                    <SuiInput
                      onChange={(e) => setFstartTime(e.target.value)}
                      value={fstartTime}
                      placeholder="Please enter Start Time"
                      type="date"
                      name="startTime"
                      label="Start Time"
                      fullWidth
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={3} sm={3} xl={2.2}>
                    <Typography style={{ fontSize: '14px' }}>
                      To Date
                    </Typography>
                    <SuiInput
                      onChange={(e) => setFendTime(e.target.value)}
                      value={fendTime}
                      placeholder="Please enter End Time"
                      type="date"
                      name="endTime"
                      label="End Time"
                      fullWidth
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={3} sm={3} xl={2.2}>
            <Typography style={{ fontSize: '14px' }}>SSN</Typography>
            <InputMask
              mask="999-99-9999"
              disabled={false}
              value={fssn}
              onKeyDown={keyDown}
              style={{ height: '30px' }}
              onChange={(e) => {
                // setValue('ssn', e.target.value)
                setFssn(e.target.value)
              }}
            >
              {() => (
                <SuiInput
                  id="outlined-basic"
                  fullWidth
                  margin="dense"
                  placeholder="Enter SSN"
                />
              )}
            </InputMask>
          </Grid>
                  <Grid item xs={1} sm={1} xl={1}>
            <Button
              sx={{ mb: 2 }}
              onClick={handleClick}
              variant="gradient"
              size="small"
              style={{
                width: '50px',
                float: 'left',
                height: '33px',
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
                marginTop: "25px",
              }}
            // color="warning"
            // size="small"

            // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
            >
              {isShown ? (
                <ExpandLessIcon
                  fontSize="medium"
                  style={{
                    color: '#1a82ad',
                    paddingRight: '4px',
                    paddingBottom: '2px',
                  }}
                ></ExpandLessIcon>
              ) : (
                <ExpandMoreIcon
                  fontSize="medium"
                  style={{
                    color: '#1a82ad',
                    paddingRight: '4px',
                    paddingBottom: '2px',
                  }}
                ></ExpandMoreIcon>
              )}
            </Button>
          </Grid>
       
          {isShown && (
            <>
              <Grid
                container
                spacing={1}
                style={{
                  marginLeft: '2px',
                }}
              >
                <Grid item xs={3} sm={3} xl={2.2}>
                  <Typography style={{ fontSize: '14px' }}>
                    Email Address
                  </Typography>
                  <SuiInput
                    onChange={(e) => setFemail(e.target.value)}
                    value={femail}
                    placeholder="Please enter email address"
                    name="emailAddress"
                    onKeyDown={keyDown}
                    label="emailAddress"
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={3} sm={3} xl={2.2} style={{ fontSize: '14px' }}>
                  <Typography style={{ fontSize: '14px' }}>Phone No</Typography>
                  <InputMask
                    mask="+1 999-99-9999"
                    disabled={false}
                    value={fphone}
                    onKeyDown={keyDown}
                    style={{ height: '30px' }}
                    onChange={(e) => {
                      // setValue('ssn', e.target.value)
                      setFphone(e.target.value)
                    }}
                  >
                    {() => (
                      <SuiInput
                        id="outlined-basic"
                        fullWidth
                        margin="dense"
                        placeholder="Enter Phone NO"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={3} sm={3} xl={2.2}>
                  <Typography style={{ fontSize: '14px' }}>
                    Smoking Status
                  </Typography>
                  <SuiSelect
                    defaultValue={{ value: '', label: 'All' }}
                    onChange={handlesmokingChange}
                    options={[
                      { value: '', label: 'All' },
                      {
                        value: 'smoking Cessation',
                        label: 'Smoking Cessation',
                      },
                    ]}
                    onKeyDown={keyDown}
                  />
                </Grid>
                
          <Grid item xs={3} sm={3} xl={2.2}>
            <Typography style={{ fontSize: '14px' }}>Program</Typography>
          <FormControl>
            <SuiSelect
              value={Program1}
              onKeyDown={keyDown}
              style={{ height: '30px' }}
              onChange={(e) => {

                // setValue('ssn', e.target.value)
                setProgram1(e)
                console.log("setProgram1",e)
              }}
              
              options={[
                { value: '', label: 'All' },
                { value: 'BHI', label: 'BHI' },
                { value: 'CoCM', label: 'CoCM' },
              ]}
            />
          </FormControl>
               
              </Grid>
              </Grid>
            
            </>
          )}

                </Grid>
                <div>
                  <Button
                    onClick={onFilterSearch}
                    variant="gradient"
                    style={{
                      marginTop: '10px',
                      marginBottom: '10px',
                      float: 'right',
                      width: '80px',
                      height: '35px',
                      backgroundColor: '#FCBE13',
                      color: 'black',
                      fontSize: '14px',
                    }}
                  >
                    <SearchOutlinedIcon
                      fontSize="medium"
                      style={{ color: '#1a82ad', paddingBottom: '1px' }}
                    ></SearchOutlinedIcon>
                    Search
                  </Button>
                  <Button
                    onClick={onFilterClear}
                    variant="gradient"
                    style={{
                      marginTop: '10px',
                      marginBottom: '10px',
                      float: 'right',
                      marginRight: '13px',
                      width: '80px',
                      height: '33px',
                      backgroundColor: '#FCBE13',
                      color: 'black',
                      fontSize: '14px',
                    }}
                  >
                    <CleaningServicesOutlinedIcon
                      fontSize="medium"
                      style={{ color: '#0a9ff0', paddingRight: '5px' }}
                    ></CleaningServicesOutlinedIcon>
                    Clear
                  </Button>
                </div>
              </div>
            </AccordionDetails>

          </Grid>
          <Grid item spacing={1} xs={12} sm={12} md={12} lg={12}>
          </Grid>
          <Grid
            container
            spacing={1}
            alignContent="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
             <div style={{ height: 400, width: '100%' }}>
            {submitting ? (
          <CircularProgress
            style={{
              width: "50px",
              height: "50px",
              position: "absolute",
              left: "50%",
              top: "50%",
              marginLeft: "-25px",
              marginTop: "-25px",
              zIndex: 1000,
              color: "#FCBE13",
            }}
          />
        ) : rows === null || rows.length === 0 ? noRecordsFound : (
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  headerHeight={37}
                  rowsPerPageOptions={[5]}
                  sx={gridRowStyle}
                />
              )}
            </div>
            </Grid>
          </Grid>
      
    </>
  )
}

export default InActive
