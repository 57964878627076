
import SuiBox from 'components/SuiBox'
import SuiInput from 'components/SuiInput'
import SuiButton from 'components/SuiButton'
import MenuItem from '@mui/material/MenuItem'
import { useAlert } from 'react-alert'
import Timelogeidth from './Timelogeidth'
import Table from 'examples/Tables/Table'
import input from '@mui/material/Input'
import { Grid, Snackbar, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useReducer, useState, Fragment } from 'react'
import { createBrowserHistory } from 'history'
import { useLocation } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { TabPanel, TabContext, TabList } from '@mui/lab'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import PermIdentity from '@mui/icons-material/PermIdentity'
import 'react-datepicker/dist/react-datepicker.css'
import { DataGrid } from '@mui/x-data-grid'
import { Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { confirmAlert } from 'react-confirm-alert'
import Button from '@mui/material/Button'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import Select from "@mui/material/Select";
import { useRef } from "react";
import FormControl from "@mui/material/FormControl";
// import PatientRegistration from './PatientRegistration'
import Demographics from './Demographics'
import Modal from '@mui/material/Modal'
import SuiSelect from 'components/SuiSelect'
import { shallowEqual, useSelector } from "react-redux";


const auth = `Bearer  ${localStorage.getItem("dtt_token")}`;
const headers = {
  Authorization: auth,
};

const history = createBrowserHistory({ forceRefresh: true })
const defValues = {
  Id: '',
  startTime: '',
  endTime: '',
  userName: '',
}
function Reports(props) {
  const formControlRef = useRef(null);
  const location = useLocation()
  const [tabValue, settabValue] = React.useState(0)
  const alert = useAlert()
  const [submitting, setSubmitting] = useState(false)
  const [patientFilter, setPatientFilter] = useState(null)
  const [userFilter, setUserFilter] = useState(null)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [timeLogRows, setTimeLogRows] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [fpatientId, setFpatientId] = useState('')
  const [flastName, setFlastName] = useState('')
  const [ffirstName, setFfirstName] = useState('')
  const [fpatientName, setFpatientName] = useState('')
  const [fuserName, setFuserName] = useState('')
  const [fuserId, setFuserId] = useState('')
  const [fstartTime, setFstartTime] = useState('')
  const [fendTime, setFendTime] = useState('')
  const [openNote, setopenNote] = useState(true)
  const [fmonth, setFmonth] = useState('')
  const [reportType, setReportType] = useState('');
  const [appointStatus, setAppointStatus] = useState('');
  const [providerId, setProviderId] = useState("");
  const [openEidthReport, setOpenEidthReport] = useState(false)
  const [providerRedux, setprovidersRedux] = useState([]);
  const [noRecordsFound, setNoRecordsFound] = useState(false);
  const [clearData, setclearData] = useState(false);
  const [reduxLoaded, setreduxLoaded] = useState(false);
  

  const providersRedx = useSelector(
    (state) => state.providers.NAMES,
    shallowEqual
  );
  const AppointmentStatus = [
    { value: 'All', label: 'All' },
    { value: 'Checkedin', label: 'Checkedin' },
    { value: 'CheckedOut', label: 'CheckedOut' },
    { value: 'Confirmed', label: 'Confirmed' },
    { value: 'Late', label: 'Late' },
    { value: 'No-Show', label: 'No-Show' },
    { value: 'ReSheduled', label: 'ReSheduled' },
    { value: 'Scheduled', label: 'Scheduled' },
    { value: 'Canceled', label: 'Canceled' },

  ]

  useEffect(() => {
    var providerRedux = [];
    providersRedx.forEach((field) => {
      providerRedux.push({
        label: field.name,
        value: field.id,
      });
    });
    setprovidersRedux(providerRedux);
    console.log(providerRedux);
    setreduxLoaded(true);
  }, []);

  // useEffect(() => {
  //   setSubmitting(true);
  //   const postData = {
  //     providerId: providerId != null ? providerId : "",
  //   };

  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_URL}/ProviderScheduler/GetProviderScheduler`,
  //       postData,
  //       { headers }
  //     )
  //     .then((response) => {
  //       if (
  //         (response.data.startTime == undefined || response.data.startTime == 0) &&
  //         (response.data.endTime == undefined || response.data.endTime == 0)
  //       ) {
  //         setTimeFrom('09:00')
  //         settimeTo('17:00')
  //       } else {
  //         setTimeFrom(response.data.startTime)
  //         settimeTo(response.data.endTime)
  //       }
  //       setSubmitting(false);
  //     })


  //     .catch((error) => {
  //       console.error(error);
  //       setSubmitting(false);
  //     });
  // }, [providerId]);

  const handleChange = (event, newValue) => {
    settabValue(newValue)
  }
  const onFilterClear = (event) => {
    setTimeLogRows(null)
    setAppointStatus('')
    setFstartTime('')
    setFendTime('')
    setProviderId('')
    
  }

  // const postData = {
  //   AppointmentStatus: appointStatus,
  //   ReportType: reportType,
  //   startTime: fstartTime,
  //   endTime: fendTime,
  // }
  // axios
  //   .post(
  //     `${process.env.REACT_APP_API_URL}/PatientTimeLog/Report`,
  //     postData,
  //     { headers }
  //   )
  //   .then((response) => {
  //     setTimeLogRows(response.data)
  //   })
  //   .catch((error) => {
  //     console.error('There was an error!', error)
  //   })

  const onFilterSearch = (event) => {
    setTimeLogRows(null)
    const postData = {
      providerId: providerId,
      AppointmentStatus: appointStatus == 'All' ? -1 : appointStatus,
      FromDate: fstartTime,
      ToDate: fendTime,
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientAppointment/AppointmentStatusByUser`,
        postData,
        { headers }
      )
      .then((response) => {
        setTimeLogRows(response.data);
        if (response.data === null || response.data.length === 0) {
          setNoRecordsFound(true);
        } else {
          setNoRecordsFound(false);
        }
      })
      .catch((error) => {
        console.error('There was an error!', error);
        setNoRecordsFound(true);
      });
  };

  const [patientId, setpatientId] = useState('')
  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 5,
    background: 'white',
    fontSize: '13.6px !important',
    color: '#8392ab !important',
    borderColor: 'rgba(5, 152, 236, 0.637) !important',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '16px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#FFFFC2',
      borderRadius: 1.8,
    },
  }

  const timelogColumns = [
    // {
    //   field: 'ProviderId',
    //   headerName: 'Provider ID',
    //   cellClassName: 'gridCell',
    //   sortable: false,
    //   headerClassName: 'super-app-theme--header',
    //   flex: 0.3,
    // },
    {
      field: 'PatientName',
      headerName: 'Patient Name',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    {
      field: 'AppointmentDate',
      headerName: 'Appointment Date',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    {
      field: 'AppointmentStatus',
      headerName: 'Appointment Status',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    {
      field: 'ProviderName',
      headerName: 'Provider Name',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,

    },

    {
      field: 'StartTime',
      headerName: 'Appointment Time',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    {
      field: 'Duration',
      headerName: 'Duration',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.5,
    },
    // {
    //   field: 'AppointmentCount',
    //   headerName: 'Appointment Count',
    //   cellClassName: 'gridCell',
    //   sortable: false,
    //   headerClassName: 'super-app-theme--header',
    //   flex: 0.5,
    // },

  ]
  
  const validationSchema = Yup.object({})
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  })
  const [open1, setOpen1] = React.useState(false)
  const handleOpen1 = () => setOpen1(true)
  const handleClose1 = () => {
    setpatientId(0)
    setOpen1(false)
  }
  useEffect(() => {
    handleOpen1()
  
  }, [patientId])



  return (
    <>
      <TabContext value={tabValue}>
        <TabPanel value={0}>
          <Grid
            spacing={1}
            alignContent="left"
            justifyContent="left"
            style={{ width: '100% !important', marginLeft: '0px !important' }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Advanced Search</Typography>

            </AccordionSummary>
            <AccordionDetails>
              <div>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={3} xl={6}>
                    <Typography style={{ fontSize: '14px' }}>
                      Appointment Status
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={3} xl={6}>
                    <Typography style={{ fontSize: '14px' }}>
                      Provider
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={3} xl={6}>
                    <SuiSelect
                      value={{ label: appointStatus }}
                      onChange={(selectedOption) => {
                        setAppointStatus(selectedOption.value);
                      }}
                      options={AppointmentStatus}
                      placeholder="Select Appointment Status"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} xl={6}>
                    <SuiSelect
                      value={providerRedux.find(option => option.value === getValues('providerId')) || null}
                      onChange={value => {
                        setValue('providerId', value != null ? value.value : '');
                        setProviderId(value?.value || ''); 
                      }}
                      options={
                        providerRedux && providerRedux.length > 0
                          ? providerRedux.map(item => ({
                            value: item.value,
                            label: item.label,
                          }))
                          : []
                      }
                      placeholder="Select Provider"
                      className="cal-header-picker"
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          cursor: 'pointer',
                        }),
                        menu: (provided, state) => ({
                          ...provided,
                        }),
                      }}
                    />
                  </Grid>



                  <Grid item xs={6} sm={3} xl={6}>
                    <Typography style={{ fontSize: '14px' }}>
                      From Date
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={3} xl={6}>
                    <Typography style={{ fontSize: '14px' }}>
                      To Date
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={3} xl={6}>
                    <SuiInput
                      onChange={(e) => setFstartTime(e.target.value)}
                      value={fstartTime}
                      placeholder="Please enter Start Time"
                      type="date"
                      name="startTime"
                      label="Start Time"
                      fullWidth
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} xl={6}>
                    <SuiInput
                      onChange={(e) => setFendTime(e.target.value)}
                      value={fendTime}
                      placeholder="Please enter End Time"
                      type="date"
                      name="endTime"
                      label="End Time"
                      fullWidth
                      margin="dense"
                    />
                  </Grid>
                </Grid>
                <div>
                  <Button
                    onClick={onFilterSearch}
                    variant="gradient"
                    style={{
                      marginTop: '10px',
                      marginBottom: '10px',
                      float: 'right',
                      width: '80px',
                      height: '35px',
                      backgroundColor: '#FCBE13',
                      color: 'black',
                      fontSize: '14px',
                    }}
                  >
                    <SearchOutlinedIcon
                      fontSize="medium"
                      style={{ color: '#1a82ad', paddingBottom: '1px' }}
                    ></SearchOutlinedIcon>
                    Search
                  </Button>
                  <Button
                    onClick={onFilterClear}
                    variant="gradient"
                    style={{
                      marginTop: '10px',
                      marginBottom: '10px',
                      float: 'right',
                      marginRight: '13px',
                      width: '80px',
                      height: '33px',
                      backgroundColor: '#FCBE13',
                      color: 'black',
                      fontSize: '14px',
                    }}
                  >
                    <CleaningServicesOutlinedIcon
                      fontSize="medium"
                      style={{ color: '#0a9ff0', paddingRight: '5px' }}
                    ></CleaningServicesOutlinedIcon>
                    Clear
                  </Button>
                </div>
              </div>
            </AccordionDetails>

          </Grid>
          <Grid item spacing={1} xs={12} sm={12} md={12} lg={12}>
          </Grid>

          <Grid
            container
            spacing={1}
            alignContent="center"
            justifyContent="center"
          >
            {openNote ? (
              <Grid item xs={12}>
                {noRecordsFound ? (
                  <Typography variant="h6" style={{ marginTop: '20px' }}>
                    No records found.
                  </Typography>
                ) : (
                  <SuiBox mb={3}>
                    {' '}
                    <div style={{ height: 353, width: '100%' }}>
                      {timeLogRows === null ||
                        timeLogRows.length === 0 ? null : (
                        <DataGrid
                          rows={timeLogRows}
                          columns={timelogColumns}
                          headerHeight={37}
                          pageSize={5}
                          rowsPerPageOptions={[5]}
                          sx={gridRowStyle}
                        />
                      )}
                    </div>
                  </SuiBox>
                )}
              </Grid>
            ) : (
              <Grid
                container
                xs={12}
                spacing={1}
                style={{
                  marginTop: '20px',
                  paddingTop: '10px',
                  borderRadius: '20px',
                  border: '1px solid rgba(5, 152, 236, 0.637)',
                }}
              >
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{
                    width: '100%',
                    marginTop: '5px',
                    borderRadius: '20px',
                  }}
                >
                  <Button
                    variant="gradient"
                    onClick={(event) => {
                      setopenNote(true)
                    }}
                    style={{
                      marginLeft: '16px',
                      paddingRight: '1px',
                      paddingLeft: '0px',
                      width: '80px',
                      height: '35px',
                      backgroundColor: '#FCBE13',
                      color: 'black',
                      fontSize: '14px',
                    }}
                  >
                    <ArrowBackIosNewOutlinedIcon
                      fontSize="medium"
                      style={{
                        color: '#1a82ad',
                        paddingRight: '5px',
                        paddingBottom: '2px',
                      }}
                    ></ArrowBackIosNewOutlinedIcon>
                    Back
                  </Button>
                </Grid>
                {openEidthReport ? (
                  <Timelogeidth
                    Timelogid={openEidthReport}
                    Backfunction={setopenNote}
                    refresh={!refresh}
                  ></Timelogeidth>
                ) : null}
              </Grid>
            )}

          </Grid>
        </TabPanel>
      </TabContext>

    </>
  )
}

export default Reports
