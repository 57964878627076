/**
=========================================================
* Soft UI Dashboard React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard React layouts

import Home from 'Modules/Home'
import SignIn from 'Modules/logIn'
import SignUp from 'layouts/authentication/sign-up'
import Users from 'Modules/Users'
import NfcIcon from '@mui/icons-material/Nfc';
// import PatientSearch from 'Modules/PatientSearch'
import PatientSearchNew from 'Modules/PatientSearchNew'
import PatientRegistration from 'Modules/PatientRegistration'
import InsuranceRegistration from 'Modules/InsuranceRegistration'
import Timelog from 'Modules/Timelog'
import UserRegistration from 'Modules/UserRegistration'
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import Facilities from 'Modules/Facilities'
import ReferralRegistration from 'Modules/ReferralRegistration'
import FacilityRegistration from 'Modules/FacilityRegistration'
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import ProviderRegistration from 'Modules/ProviderRegistration'
import Phq9gad7Registration from 'Modules/Phq9gad7Registration'
import InvitePatient from 'Modules/invitePatient'
import Practices from 'Modules/Practices'
import Insuranceplan from 'Modules/Insuranceplan'
import PracticeRegistration from 'Modules/PracticeRegistration'
import Timelogeidth from 'Modules/Timelogeidth'
import Providers from 'Modules/Providers'
import CallToActionIcon from '@mui/icons-material/CallToAction';
import CareTeam from 'Modules/CareTeam'
import Addteam from 'Modules/Addteam'
import AddNotes from 'Modules/AddNotes'
import DataMining from 'Modules/DataMining'
import PatientReffralReport from 'Modules/PatientReffralReport'
import SpeedIcon from '@mui/icons-material/Speed';
import Addmember from 'Modules/Addmember'
import Reports from 'Modules/Reports'
import BillingReports from 'Modules/BillingReport'
import EmergencyContact from 'Modules/EmergencyContact'
import PatientNotes from 'Modules/PatientNotes'
// Soft UI Dashboard React icons
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import Shop from 'examples/Icons/Shop'
import Document from 'examples/Icons/Document'
import SpaceShip from 'examples/Icons/SpaceShip'
import CustomerSupport from 'examples/Icons/CustomerSupport'
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety'
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import PostAddIcon from '@mui/icons-material/PostAdd';
import GroupsIcon from '@mui/icons-material/Groups';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import Scheduler from 'Modules/Scheduler';
import Email from 'Modules/Email';
import Fax from 'Modules/Fax';
import Referral from 'Modules/Referral'
import InActive from 'Modules/InActive'
import Templates from 'Modules/Templates'
import EmailIcon from '@mui/icons-material/Email';
// import FaxIcon from '@mui/icons-material/Fax';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ProviderResources from 'Modules/ProviderResources';
import PrintIcon from '@mui/icons-material/Print';
import PdfViewer from 'Modules/PdfViewer';
import PHQ from 'Modules/PHQ'
import GAD from 'Modules/GAD'
import FaxAppointmentCreate from 'Modules/FaxAppointmentCreate ';
import FaxDemographics from 'Modules/FaxDemographics ';
import Screening from 'Modules/Screening';
import EventNoteIcon from '@mui/icons-material/EventNote'
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import Provider_scheduler from 'Modules/Provider_scheduler';
import AppointmentStatus from 'Modules/AppointmentStatus';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import Phq9gad7 from 'Modules/Phq9gad7'
import Smsemail from 'Modules/Smsemail'
import Modules from 'Modules/Modules';
import RuleFolderIcon from '@mui/icons-material/RuleFolder';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import MailIcon from '@mui/icons-material/Mail';
const routes = [
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'home',
    route: '/home',
    icon: <DashboardCustomizeIcon size="12px" />,
    component: Home,
    noCollapse: true,
    
  },
  // {
  //   type: 'collapse',
  //   name: 'PatientSearch',
  //   key: 'PatientSearch',
  //   route: '/PatientSearch',
  //   component: PatientSearch,
  //   icon: <HealthAndSafetyIcon size="12px" />,
  //   noCollapse: true,
  // },
  {
    type: 'collapse',
    name: 'Scheduler',
    key: 'Scheduler',
    route: '/Scheduler',
    component: Scheduler,
    icon: <EventNoteIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Provider Resources',
    key: 'ProviderResources',
    route: '/ProviderResources',
    component: ProviderResources,
    icon: <LibraryAddCheckIcon size="12px" />,
    noCollapse: true,
  },
  {
    // type: 'collapse',
    name: 'Screening',
    key: 'Screening',
    route: '/Screening',
    component: Screening,
    icon: <EventAvailableIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Fax',
    key: 'Fax',
    route: '/Fax',
    component: Fax,
    icon: <PrintIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Email',
    key: 'Email',
    route: '/Email',
    component: Email,
    icon: <EmailIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Patients',
    key: 'PatientSearch',
    route: '/PatientSearch',
    component: PatientSearchNew,
    icon: <HealthAndSafetyIcon size="12px" />,
    noCollapse: true,
  },
  {
    // type: 'collapse',
    name: 'Document',
    key: 'PdfViewer',
    route: '/Document',
    component: PdfViewer,
    icon: <HealthAndSafetyIcon size="12px" />,
    noCollapse: true,
  },
 
  {
    // type: 'collapse',
    name: 'PatientFilters',
    key: 'PatientFilters',
    route: '/PatientFilters',
    icon: <Shop size="12px" />,
    component: PatientRegistration,
    noCollapse: true,
  },
  
  {
    name: 'Patient Registration',
    key: 'patientsregistration',
    route: '/patientsregistration',
    icon: <Shop size="12px" />,
    component: PatientRegistration,
    noCollapse: true,
  },
  {
    name: 'Insurance Registration',
    key: 'Insurance Registration',
    route: '/InsuranceRegistration',
    icon: <Shop size="12px" />,
    component: InsuranceRegistration,
    noCollapse: true,
  },
  {
    name: 'Time Log',
    key: 'Time Log',
    route: '/Timelog',
    icon: <Shop size="12px" />,
    component: Timelog,
    noCollapse: true,
  },
  {
    name: 'Appointment Status Report',
    key: 'Appointment Status',
    route: '/AppointmentStatus',
    icon: <Shop size="12px" />,
    component: AppointmentStatus,
    noCollapse: true,
  },
  {
    name: 'PHQ',
    key: 'PHQ',
    route: '/PHQ',
    icon: <Shop size="12px" />,
    component: PHQ,
    noCollapse: true,
  },
  {
    name: 'GAD',
    key: 'GAD',
    route: '/GAD',
    icon: <Shop size="12px" />,
    component: GAD,
    noCollapse: true,
  },
  {
    name: 'FaxAppointmentCreate',
    key: 'FaxAppointmentCreate',
    route: '/FaxAppointmentCreate',
    icon: <Shop size="12px" />,
    component: FaxAppointmentCreate,
    noCollapse: true,
  },
  {
    name: 'FaxDemographics',
    key: 'FaxDemographics',
    route: '/FaxDemographics',
    icon: <Shop size="12px" />,
    component: FaxDemographics,
    noCollapse: true,
  },
  {
    type: 'title',
    title: 'Admin Settings',
    key: 'account-pages',
    userType: 'Admin',
  },
  {
    type: 'collapse',
    name: 'Users' /* #todo add users list here */,
    key: 'Users',
    route: '/Users',
    icon: <CustomerSupport size="12px" />,
    component: Users,
    noCollapse: true,
    userType: 'Admin',
  },
  {
    name: 'User Registration' /* #todo add users list here */,
    key: 'UserRegistration',
    route: '/UserRegistration',
    icon: <CustomerSupport size="12px" />,
    component: UserRegistration,
    noCollapse: true,
  },
  {
    name: 'Patient Notes' /* #todo add users list here */,
    key: 'PatientNotes',
    route: '/PatientNotes',
    component: PatientNotes,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Providers' /* #todo add providers list here */,
    key: 'Providers',
    route: '/Providers',
    icon: <GroupWorkIcon size="12px" />,
    component: Providers,
    noCollapse: true,
    userType: 'Admin',
  },
  {
    type: 'collapse',
    name: 'Provider Scheduler' /* #todo add providers list here */,
    key: 'Provider_scheduler',
    route: '/Provider_scheduler',
    icon: <Document size="12px" />,
    component: Provider_scheduler,
    noCollapse: true,
    userType: 'Admin',
  },
  {
    type: 'collapse',
    name: 'Facilities',
    key: 'facilities',
    route: '/facilities',
    icon: <LocalHospitalIcon size="12px" />,
    component: Facilities,
    userType: 'Admin',
  },
  {
    type: 'collapse',
    name: 'Practices',
    key: 'Practices',
    route: '/Practices',
    icon: <HomeWorkIcon size="12px" />,
    component: Practices,
    noCollapse: true,
    userType: 'Admin',
  },
  {
    type: 'collapse',
    name: 'Insurance Plan',
    key: 'Insuranceplan',
    // title: 'Insurance Plan',
    route: '/Insuranceplan',
    icon: <Shop size="12px" />,
    component: Insuranceplan,
    noCollapse: true,
    userType: 'Admin',
  },
  {
    type: 'collapse',
    name: 'Templates' /* #todo add users list here */,
    key: 'Templates',
    route: '/Templates',
    icon: <CustomerSupport size="12px" />,
    component: Templates,
    noCollapse: true,
    userType: 'Admin',
  },
  {
    type: 'collapse',
    name: 'Referral' /* #todo add users list here */,
    key: 'Referral',
    route: '/Referral',
    icon: <RoomPreferencesIcon size="12px" />,
    component: Referral,
    noCollapse: true,
    userType: 'Admin',
  },
  {
    type: 'collapse',
    name: 'InActive' /* #todo add users list here */,
    key: 'InActive',
    route: '/InActive',
    icon: <CallToActionIcon size="12px" />,
    component: InActive,
    noCollapse: true,
    userType: 'Admin',
  },
  
  
  {
    name: 'Practices Registration',
    key: 'PracticeRegistration',
    route: '/PracticeRegistration',
    icon: <SpaceShip size="12px" />,
    component: PracticeRegistration,
    noCollapse: true,
  },
  {
    name: 'Time Log Edit',
    key: 'Timelogeidth',
    route: '/Timelogeidth',
    icon: <SpaceShip size="12px" />,
    component: Timelogeidth,
    noCollapse: true,
  },

  {
    name: 'Facility Registration',
    key: 'facilityRegistration',
    route: '/facilityRegistration',
    icon: <Shop size="12px" />,
    component: FacilityRegistration,
    noCollapse: true,
  },
  {
    name: 'Referral Registration',
    key: 'ReferralRegistration',
    route: '/ReferralRegistration',
    icon: <Shop size="12px" />,
    component: ReferralRegistration,
    noCollapse: true,
  },
  {
    name: 'Provider Registration',
    key: 'providerRegistration',
    route: '/providerRegistration',
    icon: <Shop size="12px" />,
    component: ProviderRegistration,
    noCollapse: true,
  },
  {
    name: 'Phq9gad7 Registration',
    key: 'Phq9gad7Registration',
    route: '/Phq9gad7Registration',
    icon: <Shop size="12px" />,
    component: Phq9gad7Registration,
    noCollapse: true,
  },
  // {
  //   name: 'Patient Reffral Report',
  //   key: 'PatientReffralReport',
  //   route: '/PatientReffralReport',
  //   icon: <Shop size="12px" />,
  //   component: PatientReffralReport,
  //   noCollapse: true,
  //   userType: 'Admin',
  //   noCollapse: true,
  // },
  
  {
    name: 'Invite Patient',
    key: 'Invitepatient',
    route: '/InvitePatient',
    icon: <Shop size="12px" />,
    component: InvitePatient,
    noCollapse: true,
  },
  {
    name: 'Sign In',
    key: 'login',
    route: '/login',
    icon: <SpaceShip size="12px" />,
    component: SignIn,
    noCollapse: true,
  },
  // {
  //   type: 'collapse',
  //   name: 'Care Teams' /* #todo add CareTeam list here */,
  //   key: 'CareTeam',
  //   route: '/CareTeam',
  //   icon: <GroupsIcon size="12px" />,
  //   component: CareTeam,
  //   noCollapse: true,
  //   userType: 'Admin',
  // },
  { type: 'title', title: 'Reports', key: 'report-pages', userType: 'Admin' },
  {
    type: 'collapse',
    name: 'Time Log' /* #todo add CareTeam list here */,
    key: 'Reports',
    route: '/Reports',
    icon: <HistoryToggleOffIcon size="12px" />,
    component: Reports,
    noCollapse: true,
    userType: 'Admin',
  },
  {
    type: 'collapse',
    name: 'Appointment Report',
    key: 'AppointmentReport',
    route: '/AppointmentReport',
    icon: <BookOnlineIcon  size="12px" />,
    component:AppointmentStatus,
    userType: 'Admin',
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Super Bill' ,
    key: 'SuperBill',
    route: '/SuperBill',
    icon: <PriceChangeIcon size="12px" />,
    component: BillingReports,
    userType: 'Admin',
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Data Mining' /* #todo add CareTeam list here */,
    key: 'DataMining',
    route: '/DataMining',
    icon: <NfcIcon size="12px" />,
    component: DataMining,
    userType: 'Admin',
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'PHQ 9||Gad 7' /* #todo add CareTeam list here */,
    key: 'Phq9gad7',
    route: '/Phq9gad7',
    icon: <SpeedIcon  size="12px" />,
    component: Phq9gad7,
    userType: 'Admin',
    noCollapse: false,
  },
  {
    type: 'collapse',
    name: 'EMAIL || SMS ' /* #todo add CareTeam list here */,
    key: 'Smsemail',
    route: '/Smsemail',
    icon: <MailIcon  size="12px" />,
    component: Smsemail,
    userType: 'Admin',
    noCollapse: false,
  },
 
  {
    type: 'collapse',
    name: 'Modules' /* #todo add CareTeam list here */,
    key: 'Modules',
    route: '/Modules',
    icon: <RuleFolderIcon size="12px" />,
    component: Modules,
    userType: 'Admin',
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Patient Referral Report',
    key: 'PatientReffralReport',
    route: '/PatientReffralReport',
    icon: <Shop size="12px" />,
    component: PatientReffralReport,
    noCollapse: true,
    userType: 'Admin',
    
  },
  {
    name: 'Add Notes',
    key: 'AddNotes',
    route: '/AddNotes',
    icon: <SpaceShip size="12px" />,
    component: AddNotes,
    noCollapse: true,
  },
  {
    name: 'Add Team',
    key: 'Addteam',
    route: '/Addteam',
    icon: <SpaceShip size="12px" />,
    component: Addteam,
    noCollapse: true,
  },
  {
    name: 'Add Team Member',
    key: 'Addmember',
    route: '/Addmember',
    icon: <SpaceShip size="12px" />,
    component: Addmember,
    noCollapse: true,
  },
  {
    name: 'Emergency Contact',
    key: 'EmergencyContact',
    route: '/EmergencyContact',
    icon: <SpaceShip size="12px" />,
    component: EmergencyContact,
    noCollapse: true,
  },
  // {
  //   type: 'collapse',
  //   name: 'PatientReferalReport' /* #todo add CareTeam list here */,
  //   key: 'BillingReport',
  //   route: '/SuperBill',
  //   icon: <PriceCheckIcon size="12px" />,
  //   component: BillingReports,
  //   userType: 'Admin',
  //   noCollapse: true,
  // },
]

export default routes
