
import Card from '@mui/material/Card'
import axios from 'axios'
import { confirmAlert } from 'react-confirm-alert'
// Soft UI Dashboard React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import { useLocation, Link } from 'react-router-dom'
import SuiButton from 'components/SuiButton'
import SuiAvatar from 'components/SuiAvatar'
import { TabPanel, TabContext, TabList } from '@mui/lab'
// import Table from 'examples/Tables/Table'
import React, { useEffect, useState, } from 'react'
import { createBrowserHistory } from 'history'
import { DataGrid } from '@mui/x-data-grid'
import CloseIcon from '@mui/icons-material/Close';

import { Controller, useForm } from 'react-hook-form'
import { Grid, Snackbar, TextField, Typography } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { shallowEqual, useSelector } from 'react-redux'
import PHQ9GAD7Filters from './DataMiningFilter'
import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Demographics from './Demographics'
import Modal from '@mui/material/Modal'
import Moment from 'moment'
import 'bootstrap/dist/css/bootstrap.css';
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from '@mui/styles';
import { Favorite, Sick, Warning } from "@mui/icons-material";
import { Chart } from 'react-google-charts';
import ScheduleIcon from '@mui/icons-material/Schedule';
import UpdateIcon from '@mui/icons-material/Update'; 
import SpeedIcon from '@mui/icons-material/Speed';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    width: '100%',
    margin: '0 auto', // This will center the container
  },
}));

import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import PatientRegistration from './PatientRegistration'
import MiniStatisticsCard from 'examples/Cards/StatisticsCards/MiniStatisticsCard'
import { Col } from 'react-grid-system'
function createData(lastname, firstname, fat, carbs, protein) {
  return { lastname, firstname, fat, carbs, protein };
}

const history = createBrowserHistory({ forceRefresh: true })
var providersRedux = []
var facilitiesRedux = []

var AvgLabel = [{ "value": 3, "label": "Quarterly Average" }, { "value": 6, "label": "Half Yearly Average" }, { "value": 12, "label": "Yearly Average" }]
const LineChartOptionsPHQ1 = {
  hAxis: {
    title: "Time",
  },
  lineWidth: 4,
  backgroundColor: "#F0FFFF", //"#73C2E3",
  legend: "none",
  annotations: { stemColor: "white", textStyle: { fontSize: 16 } },
  vAxis: {
    title: "Score",
    ticks: [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28],
  },
  pointSize: 10,
  curveType: "function",
};
function DataMining(props) {
  const classes = useStyles();
  const [refresh, setRefresh] = useState(false)
  const [pageSize, setPageSize] = React.useState(25);
  const [PHQ9rows, setPHQ9rows] = useState('')
  const [tabValue, settabValue] = React.useState(0)
  const [rows, setrows] = useState('')
  const [phq9, setPhq9] = React.useState('')
  const [CheckboxGraphs, setCheckboxGraphs] = React.useState('')
  const [CheckboxGraph, setCheckboxGraph] = React.useState('false')

  const [ErrorMsgPhq9, setErrorMsgPhq9] = React.useState('Please Apply Filter')
  const [Gad7Data, setGAD7] = React.useState('')
  const [Gad7rows, setGad7rows] = useState('')
  const [submitting, setsubmitting] = useState(false)
  const [firstName, setfirstName] = useState('')
  const [lastName, setlastName] = useState('')
  const [dob, setdob] = React.useState('')
  const [PatinetCheck, setPatinetCheck] = React.useState(true)
  const [DatagroupBy, setgetDatagroupBy] = React.useState('')
  const [resp, setResp] = React.useState([])

  const [avgIndex, setAVGIndex] = useState(12)

// Assuming phq9 is an array of objects with phq9Date, phQ9StartScore, and phQ9LastScore properties
// const chartData = [
//   ['Date', 'Start Score', 'Last Score'],
// ];

// if (Array.isArray(Gad7Data)) {
//   chartData.push(
//     ...Gad7Data.map(entry => {
//       // Convert the date to a string in the format "YYYY-MM-DD"
//       const dateString = new Date(entry.Gad7Data).toISOString().split('T')[0];
//       return [dateString, entry.phQ9StartScore, entry.phQ9LastScore];
//     })
//   );
// } else {
//   console.error('phq9 is not an array or is undefined');
// }

const values = ['abcd', 'bbbb', 'cdcdcd'];

 const  dataMinings='dataMinings'
  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 5,
    background: 'white',
    textTransform: 'none !important',
    fontSize: '13.6px !important',
    color: '#8392ab !important',
    borderColor: 'rgba(5, 152, 236, 0.637) !important',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#FFFFC2',
      borderRadius: 1.8,
    },
    '& .SUBTOTAL': {
      backgroundColor: 'rgba(200, 150, 255, 0.49)',
      color: 'black !important',
      border: '1px solid black !important'
    },
  }
  const [value, setValues] = React.useState('1');

  const handleChanges = (event, newValue) => {
    setValues(newValue);
  };
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      cellClassName: (params) => params.id,
      hide: false,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.2,
      colSpan: ({ row }) => {
        if (row.id == "SUBTOTAL") {
          return 8;
        }
        return undefined;
      },
      valueGetter: ({ value, row }) => {
        if (row.id == "SUBTOTAL") {
          return row.label;
        }
        return value;
      },
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.4,
      renderCell: (cellValues) => (
        <SuiButton
          size="small"
          variant="text"
          color="dark"
          onClick={(event) => {
            openUserRegistration(event, cellValues.row.id)
          }}
        >{`${cellValues.row.firstName}`}</SuiButton>
      ),
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'totalPhq9',
      headerName: 'Last Score',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },

    {
      field: 'lastPhq9Date',
      headerName: 'Last Phq9 Date',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.4,
    },
    {
      field: 'miN_SCORE',
      headerName: 'Min Score',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'maX_SCORE',
      headerName: 'Max Score',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'patientId',
      headerName: 'Patient',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      renderCell: (cellValues) => (
        <SuiButton
          onClick={(event) => {
            setfirstName(cellValues.row.firstName)
            setlastName(cellValues.row.lastName)
            setdob(cellValues.row.dob)
            handleOpen1()
          }}
        >
          <AddOutlinedIcon
            style={{
              marginRight: '5px',
              float: 'right',
              marginTop: '5px',
              color: 'red',
            }}

          ></AddOutlinedIcon>
          Add
          {/* {`${cellValues.row.patientId}`} */}
        </SuiButton>
        // <AddOutlinedIcon
        //   style={{
        //     marginRight: '5px',
        //     float: 'right',
        //     marginTop: '5px',
        //     color:'red',
        //   }}

        // ></AddOutlinedIcon>
      ),
    },
    {
      field: 'totaL_PHQ9_ATTEMPTS',
      headerName: 'Total Attempts',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      // sx={{ marginLeft: '5px'}},
    },

  ]

  const GAD7columns = [
    {
      field: 'id',
      headerName: 'ID',
      cellClassName: (params) => params.id,
      hide: false,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.2,
      colSpan: ({ row }) => {
        if (row.id == "SUBTOTAL") {
          return 8;
        }
        return undefined;
      },
      valueGetter: ({ value, row }) => {
        if (row.id == "SUBTOTAL") {
          return row.label;
        }
        return value;
      },
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.4,
      renderCell: (cellValues) => (
        <SuiButton
          size="small"
          variant="text"
          color="dark"
          onClick={(event) => {
            openUserRegistration(event, cellValues.row.id)
          }}
        >{`${cellValues.row.firstName}`}</SuiButton>
      ),
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'totalGad7',
      headerName: 'Last Score',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },

    {
      field: 'lastGad7Date',
      headerName: 'Last GAD7 Date',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.4,
    },
    {
      field: 'miN_SCORE',
      headerName: 'Min Score',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'maX_SCORE',
      headerName: 'Max Score',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'patientId',
      headerName: 'Patient',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      renderCell: (cellValues) => (
        <SuiButton
          onClick={(event) => {
            // console.log('Cell: ', cell)
            setfirstName(cellValues.row.firstName)
            setlastName(cellValues.row.lastName)
            setdob(cellValues.row.dob)
            handleOpen1()
          }}
        >
          <AddOutlinedIcon
            style={{
              marginRight: '5px',
              float: 'right',
              marginTop: '5px',
              color: 'red',
            }}

          ></AddOutlinedIcon>
          Add
          {/* {`${cellValues.row.patientId}`} */}
        </SuiButton>
        // <AddOutlinedIcon
        //   style={{
        //     marginRight: '5px',
        //     float: 'right',
        //     marginTop: '5px',
        //     color:'red',
        //   }}

        // ></AddOutlinedIcon>
      ),
    },
    {
      field: 'totaL_GAD7_ATTEMPTS',
      headerName: 'Total Attempts',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      // sx={{ marginLeft: '5px'}},
    },

  ]

  const GetAVGLabel = (index) => {
    let resp = "";
    AvgLabel.forEach((e) => {
      if (e.value == index)
        resp = e.label;
    });
    return resp;
  }
  // const location = useLocation()
  // const _patientId = location.state.patientId
  const auth = `Bearer  ${localStorage.getItem("dtt_token")}`;
  const headers = {
    Authorization: auth,
  }
  const styleDetails = {
    width: '90%',
    height: '95%',
    bgcolor: '#F0FFFF',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 30,
    right: 0,
    margin: 'auto',
    marginTop: '10px',
    paddingBottom: '10px',
    padding: '20px',
    overflow: 'auto',
    borderRadius:'8px'
  }
  const handleChange = (event, newValue) => {
    settabValue(newValue)
  }

  const facilitiesRedx = useSelector(
    (state) => (state.facilities.loading ? [] : state.facilities.NAMES),
    shallowEqual
  )
  const handleChange1 = (event, newValue) => {
    settabValue(newValue)
  }
  const [GadDateData, setGraphData] = React.useState([])
  const [GadScore, setGadScore] = React.useState('')

  const updateData = (totalScore, gad7Date) => {


    // setGadDate(newDate);
    // setGadScore(newScore);
 };



  const [open1, setOpen1] = React.useState(false)
  const handleOpen1 = () => setOpen1(true)
  const handleClose1 = () => {
    // setpatientId(0)
    setOpen1(false)
  }

  const [open2, setOpen2] = useState(false);
  const tets = 'bbbb'

  const handleOpen2 = () => setOpen2(true)
  const handleClose2 = () => {
    setOpen2(false);
  };
  const [open3, setOpen3] = useState(false);
  const [openFullGraphModel, setopenFullGraphModel] = useState(false);
  const handleOpen4 = () => setOpen3(true)
  const handleClose4 = () => {
    setopenFullGraphModel(false);
  };
  const [open5, setOpen5] = useState(false);
  const handleOpen5 = () => setOpen3(true)
  const handleClose5 = () => {
    setOpen5(false);
  };
 
  const handleOpen3 = () => setOpen3(true)
  const handleClose3 = () => {
    setOpen3(false);
  };


  let phq9sum = 0;
  let gad7sum = 0;

  // useEffect(() => {
  //   // POST request using axios inside useEffect React hook
  //   axios
  //     .post(

  //       `${process.env.REACT_APP_API_URL}/Reports/GetPatientPHQ9Report?fromDate=${'2023-06-22'}&toDate=${'2023-08-28'}`,
  //       {},
  //       { headers }
  //     )
  //     .then((response) => {
  //       console.error("There is a response:", response.data);
  //       setPhq9(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("There was an error!", error);
  //     });

  // }, []);

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Reports/GetPatientsDetailedReport`,
        {},
        { headers }
      )
      .then((response) => {

        setResp(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [])
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    facilitiesRedux = []
    facilitiesRedx.forEach((field) => {
      facilitiesRedux.push({
        label: field.name,
        value: field.id,
      })
    })
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [{ facilitiesRedx }])

  const providersRedx = useSelector(
    (state) => (state.providers.loading ? [] : state.providers.NAMES),
    shallowEqual
  )

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    providersRedux = []
    providersRedx.forEach((field) => {
      providersRedux.push({
        label: field.name,
        value: field.id,
      })
    })

    // console.log(providersRedux);

  }, [{ providersRedux }])
  var providersRedux = []
  const userProviderId = `${localStorage.getItem('dtt_user_provider_id')}`
  const location = useLocation();
  const _patientId = location.state?.patientId;

  const openUserRegistration = (event, id) => {
    history.push({
      pathname: `/Phq9gad7Registration`,
      state: { userId: id },
    })
  }
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  })

  const totalScoreSumphq9 = Array.isArray(phq9)
    ? phq9.reduce((sum, row) => sum + row.totalScore, 0)
    : 0;
  const averageTotalScorephq9 = Array.isArray(phq9) && phq9.length > 0
    ? totalScoreSumphq9 / phq9.length
    : 0;
  const totalScoreSum = Array.isArray(Gad7Data)
    ? Gad7Data.reduce((sum, row) => sum + row.totalScore, 0)
    : 0;
  const averageTotalScore = Array.isArray(Gad7Data) && Gad7Data.length > 0
    ? totalScoreSum / Gad7Data.length
    : 0;
  let prevName = ''; // Variable to store the previous name
  let totalScoreSumGroup = 0; // Variable to store the total score sum for each group
  let totalScoreCountGroup = 0;
  let nameGroupData = {};
  let prevNameGad7 = ''; // Variable to store the previous name
  let totalScoreSumGroupGad7 = 0; // Variable to store the total score sum for each group
  let totalScoreCountGroupGad7 = 0;
  let nameGroupDataGad7 = {};
  const [modalState, setModalState] = useState(null);

  const [patientId, setpatientId] = useState(null);

  const [patientName, setPatientName] = useState(null);
  const [fullGraphData, setfullGraphData] = useState(null);
  const [fullGraphDataPhq9, setfullGraphDataPhq9] = useState(null);
  const [Phq9NameGraph, setPhq9NameGraph] = useState(null);
  const [Gad7NameGraph, setGad7NameGraph] = useState(null);

  const [showAllDatainFullGraph, setshowAllDatainFullGraph] = useState(true);

const handleFullGraphViewClick = (_fullGraphData, currentName) => {
  const LineDataPHQ9Temp = [
    [
      { type: "string", label: "Date" },
      { type: "number", label: "PHQ9 Score" },
      { type: "string", role: "style" },
    ],
  ];

  _fullGraphData.forEach((item) => {
    // Ensure item is an array and has at least two elements
    if (Array.isArray(item) && item.length >= 2) {
      const dateStr = item[0]; // Assuming the date is the first element
      const score = parseInt(item[1]); // Assuming the score is the second element

      if (!isNaN(score)) { // Check if score is a valid number
        let style = null; // Default style
        // Determine the style based on the score
        if (score < 4) {
          style = "point { size: 7; shape-type: circle; fill-color: #389837; }";
        } else if (score < 10) {
          style = "point { size: 7; shape-type: circle; fill-color: #fbcf33; }";
        } else if (score < 15) {
          style = "point { size: 7; shape-type: circle; fill-color: #FFA07A; }";
        } else if (score < 20) {
          style = "point { size: 7; shape-type: circle; fill-color: #FF4500; }";
        } else if (score < 28) {
          style = "point { size: 7; shape-type: circle; fill-color: #DC143C; }";
        }

        // Push the data with proper formatting
        LineDataPHQ9Temp.push([dateStr, score, style]);
      }
    }
  });

  setfullGraphData(LineDataPHQ9Temp);
  setGad7NameGraph(currentName);
  setopenFullGraphModel(true);
};


 
const handleFullGraphViewClickPhq9 = (_fullGraphDataPhq9, currentName) => {
  const LineDataPHQ9Temp = [
    [
      { type: "string", label: "Date" },
      { type: "number", label: "PHQ9 Score" },
      { type: "string", role: "style" },
    ],
  ];

  _fullGraphDataPhq9.forEach((item) => {
    var dateStr = item[0]; // Accessing the date from the item
    if (dateStr != null && dateStr.length > 0) {
      var dataP = [];
      dataP.push(dateStr);
      dataP.push(parseInt(item[1]));

      // Determine the style based on the score
      let style = null; // Default style
      if (item[1] < 4) {
        style = "point { size: 7; shape-type: circle; fill-color: #389837; }";
      } else if (item[1] < 10) {
        style = "point { size: 7; shape-type: circle; fill-color: #fbcf33; }";
      } else if (item[1] < 15) {
        style = "point { size: 7; shape-type: circle; fill-color: #FFA07A; }";
      } else if (item[1] < 20) {
        style = "point { size: 7; shape-type: circle; fill-color: #FF4500; }";
      } else if (item[1] < 28) {
        style = "point { size: 7; shape-type: circle; fill-color: #DC143C; }";
      }

      dataP.push(style);
      LineDataPHQ9Temp.push(dataP);
    }
  });

  setfullGraphDataPhq9(LineDataPHQ9Temp);
  setPhq9NameGraph(currentName);
  setOpen5(true);
};

const handleCheckboxChange = (e) => {
  setCheckboxGraphs(e.target.checked);
  setshowAllDatainFullGraph(e.target.checked); // Toggle showAllDatainFullGraph
};
const handleCheckboxChange1 = (e) => {
  setCheckboxGraphs(e.target.checked);
  setshowAllDatainFullGraph(e.target.checked); // Toggle showAllDatainFullGraph
};
  const handleTableCellClick1 = (patientId, id, userType, userProviderId, providerId, status, smokingStatus, facilityId, zipCode, cellPhone, dob, firstName, lastName) => {
    // Log patientId to the console
    setOpen3(true)

    // Define the state object
    const state = {
      patientId: patientId,
      readOnly: userType !== 'Admin' && userProviderId !== providerId,
    };
    localStorage.setItem('id', id);
    // Update state
    setpatientId(patientId);


    // Navigate to the new page with the state information
   
  };

  const handleTableCellClick = (lastName,firstName,patientId, id, userType, userProviderId, providerId, status, smokingStatus, facilityId, zipCode, cellPhone, dob, patientName) => {



    const data = {
      readOnly: userType !== 'Admin' && userProviderId !== providerId,
      patientId: patientId,
      firstName:firstName,
      lastName:lastName,

      // currentName: currentName,

    };

    // Set patientId in localStorage
    localStorage.setItem('id', id);
    // Update state
    setpatientId(patientId);
    setOpen2(true);
  };

  const StyledTooltip = withStyles({
    tooltip: {
      backgroundColor: "#FCBE13",
      // backgroundColor: '#41CBEB',
      color: "black",
      // backgroundColor: '#333',
      // color: '#fff',
      fontSize: "12px",
      fontWeight: 500,
      borderRadius: "10px",
      padding: "8px",
    },
  })(Tooltip);


  const groupByMonthYear = (data) => {
    return data.reduce((acc, row) => {
      const monthYear = row.monthYear;
      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }
      acc[monthYear].push(row);
      return acc;
    }, {});
  };
  const safePhq9 = Array.isArray(phq9) ? phq9 : [];
  // const july2023Data = safePhq9.filter(row => row.monthYear.includes("Jul 2023"));

const groupedData = Array.isArray(phq9) ? phq9.reduce((acc, row) => {
  if (!acc[row.monthYear]) {
     acc[row.monthYear] = [];
  }
  acc[row.monthYear].push(row);
  return acc;
 }, {}) : {};
 const groupedDataGad7 = Array.isArray(Gad7Data) ? Gad7Data.reduce((acc, row) => {
  if (!acc[row.monthYear]) {
     acc[row.monthYear] = [];
  }
  acc[row.monthYear].push(row);
  return acc;
 }, {}) : {};
 // Rest of your component...
 const LineChartOptionsPHQ = {
  hAxis: {
    title: "Date",
  },
  lineWidth: 4,
  backgroundColor: "#F0FFFF", //"#73C2E3",
  legend: "none",
  annotations: { stemColor: "white", textStyle: { fontSize: 16 } },
  vAxis: {
    title: "Score",
    ticks: [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28],
  },
  pointSize: 10,
  curveType: "function",
};
// Now for Phq9 test of Month Graph code

// const [chartData, setChartData] = useState([]);

// useEffect(() => {
//   mergeData();
// }, [phq9]);

// const mergeData = () => {
//   if (phq9.length === 0) {
//     console.log("No data available");
//     return;
//   }

//   setChartData(phq9.map(({ monthYear, average }) => [monthYear, average]));
// };





const [Phq9chartData, setPhq9chartData] = useState([]);

useEffect(() => {
  handleFullGraphViewPhq9Fun();
}, [phq9]);

const handleFullGraphViewPhq9Fun = () => {
  const LineDataPHQ9Temp = [
    [
      { type: "string", label: "Month" },
      { type: "number", label: "PHQ9 Average" },
      { type: "string", role: "style" },
    ],
  ];
 if( phq9.length===0){
  return
 }
  phq9.forEach((item) => {
    const monthYear = item.monthYear; // Accessing the month and year from the item
    const average = item.average;

    // Determine the style based on the average score
    let style = null; // Default style
    if (average < 4) {
      style = "point { size: 7; shape-type: circle; fill-color: #389837; }";
    } else if (average < 10) {
      style = "point { size: 7; shape-type: circle; fill-color: #fbcf33; }";
    } else if (average < 15) {
      style = "point { size: 7; shape-type: circle; fill-color: #FFA07A; }";
    } else if (average < 20) {
      style = "point { size: 7; shape-type: circle; fill-color: #FF4500; }";
    } else if (average < 28) {
      style = "point { size: 7; shape-type: circle; fill-color: #DC143C; }";
    }

    LineDataPHQ9Temp.push([monthYear, average, style]);
  });

  setPhq9chartData(LineDataPHQ9Temp);
};















// Now for gad 7 test of Month Graph code

const [gad7CartMonth, setgad7CartMonth] = useState([]);

useEffect(() => {
  handleFullGraphView();
}, [Gad7Data]);

const handleFullGraphView = () => {
  const LineDataPHQ9Temp = [
    [
      { type: "string", label: "Month" },
      { type: "number", label: "Gad7 Average" },
      { type: "string", role: "style" },
    ],
  ];
  if( Gad7Data.length===0){
    return
   }
  Gad7Data.forEach((item) => {
    const monthYear = item.monthYear; // Accessing the month and year from the item
    const average = item.average;

    // Determine the style based on the average score
    let style = null; // Default style
    if (average < 4) {
      style = "point { size: 7; shape-type: circle; fill-color: #389837; }";
    } else if (average < 10) {
      style = "point { size: 7; shape-type: circle; fill-color: #fbcf33; }";
    } else if (average < 15) {
      style = "point { size: 7; shape-type: circle; fill-color: #FFA07A; }";
    } else if (average < 20) {
      style = "point { size: 7; shape-type: circle; fill-color: #FF4500; }";
    } else if (average < 28) {
      style = "point { size: 7; shape-type: circle; fill-color: #DC143C; }";
    }

    LineDataPHQ9Temp.push([monthYear, average, style]);
  });

  setgad7CartMonth(LineDataPHQ9Temp);
};
  return (

    <>
      <PHQ9GAD7Filters
        getRecords={setrows}
        getRecordsGAD7={setGad7rows}
        getData={setPhq9}
        getGraphCheckbox={setCheckboxGraph}
        getErrorMsgPhq9={setErrorMsgPhq9}
        getDataGAD7={setGAD7}
        getgroupBy={setgetDatagroupBy}
        tabValue={tabValue}
        submitting={submitting}
      />
     { DatagroupBy==='Patient'?(<>
  
          <div className={classes.tabContainer}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange1} aria-label="lab API tabs example">
                <Tab value={0} label="PHQ9" />
                <Tab value={1} label="GAD7" />
              </TabList>
            </Box>
            
            <TabPanel value={0}>
            <Modal
  open={open5}
  onClose={handleClose5}
  aria-labelledby='modal-modal-title'
  aria-describedby='modal-modal-description'
>
  <Box sx={styleDetails}>
  <Grid container xs={12}>

<Grid item xs={11.6}>
</Grid>
<Grid item xs={0.4}>

  <p style={{float:'right',color:'red'}} onClick={()=>{setOpen5(false)}}><CloseIcon/></p>
</Grid>
</Grid>
    <Grid container columnSpacing={1}>
      {phq9 === '' || phq9.length === 0 ? (
        <Typography style={{ marginTop: '2rem', marginLeft: '90%', textAlign: 'center', width: '100%' }}>{ErrorMsgPhq9}</Typography>
      ) : (
        <div style={{ width: '100%' }}>
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
        <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={2}>

        </Grid>
        <Grid item xs={2}>{fullGraphDataPhq9?.length > 6 ? (
    <Typography style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '1rem' }}>
        {/* bbbbb */}
        <input type="checkbox" id="as" onChange={handleCheckboxChange1} name="vehicle" value="Bi" style={{ fontSize: '16px' }} />
        <label htmlFor="vehicle1" style={{ fontSize: '16px', marginLeft: '0.4rem', color: '#9155FD' }}>View Full Data</label>
    </Typography>
) : ''}

                         </Grid>
        <Grid item xs={4}>
        {<div style={{ color: '#70D226', fontSize: '19px' }}>{Phq9NameGraph}'s PHQ9 Scores Over Time</div>}
       </Grid>
        <Grid item xs={2}>
</Grid>
      </Grid>
    </Box>
        </h2>
        {/* aaaaaa */}
        <Chart
          width={'100%'}
          height={'400px'}
          chartType="LineChart"
          loader={<div>Loading Chart</div>}
          // data={fullGraphDataPhq9}
          data=   {showAllDatainFullGraph ? fullGraphDataPhq9 : (fullGraphDataPhq9 || []).slice(0, 6)}

          options={LineChartOptionsPHQ}

        />
      </div>
      )}
    </Grid>
  </Box>
</Modal>

              <Grid
                container
                spacing={1}
                style={{
                  width: '100%',
                  marginBottom: '10px',
                  border: '1px solid rgba(5, 152, 236, 0.637)',
                  borderRadius: '20px',
                  marginTop: '10px',
                  paddingBottom: '20px',
                  marginLeft: '1px',
                  padding: '15px',
                  backgroundColor: 'white',
                }}
              >
                {/* 111 */}
  
                <Modal
                  open={open2}
                  onClose={handleClose2}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
                >
                  <Box sx={styleDetails}>
                    <Grid container columnSpacing={1}>
  
                    <PatientRegistration patientName={patientName} patientId={patientId} datamining ={dataMinings} handleClose2={handleClose2} />
  
  
                    </Grid>
                  </Box>
                </Modal>
  
                <div className='col-md-12'>
                  <TableContainer component={Paper}>
                    <Table style={{ width: '100%', tableLayout: 'fixed' }} aria-label="caption table">
                      <TableRow style={{ width: '100%', background: '#70D226', color: 'white' }}>
                        <TableCell sx={{ width: '15%', whiteSpace: 'nowrap', overflow: 'hidden' }}>Name</TableCell>
                        <TableCell sx={{ width: '14%', whiteSpace: 'nowrap', overflow: 'hidden' }}>Date</TableCell>
                        <TableCell sx={{ width: '10%', whiteSpace: 'nowrap', overflow: 'hidden' }}>Provider Name</TableCell>
                        <TableCell sx={{ width: '10%', whiteSpace: 'nowrap', overflow: 'hidden' }}>PHQ9</TableCell>
                      </TableRow>
  
                      <TableBody>
                      {phq9 === '' || phq9.length===0 ? <Typography style={{marginTop:'2rem',marginLeft:'90%', textAlign: 'center',width:'100%'}}>{ErrorMsgPhq9}</Typography> : (   Array.isArray(phq9) && phq9.map((row, index) => {
                          const currentName = `${row.firstName} ${row.lastName}`;
                          const currentIdPhq9Id=row.id
                          const isNewName = currentName !== prevName;
                          prevName = currentName;
                          const isLastRow = index === phq9.length - 1;
                          const hasNextName = phq9[index + 1] && `${phq9[index + 1].firstName} ${phq9[index + 1].lastName}` === currentName;
                          if (!nameGroupData[currentName]) {
                            nameGroupData[currentName] = {
                              totalScoreSum: 0,
                              totalScoreCount: 0,
                            };
                          }
  
                          nameGroupData[currentName].totalScoreSum += row.totalScore;
                          nameGroupData[currentName].totalScoreCount++;
                          const personChartData = [
                            [
                              { type: "string", label: "Date" },
                              { type: "number", label: "PHQ9 Score" },
                              { type: "string", role: "style" },
                            ],
                            ...phq9
                              .filter(entry => `${entry.firstName} ${entry.lastName}` === currentName)
                              .map(entry => {
                                const dateStr = new Date(entry.phq9Date).toLocaleDateString();
                                let style = '';
                                if (entry.totalScore < 4) {
                                  style = "point { size: 7; shape-type: circle; fill-color: #389837; }";
                                } else if (entry.totalScore < 10) {
                                  style = "point { size: 7; shape-type: circle; fill-color: #fbcf33; }";
                                } else if (entry.totalScore < 15) {
                                  style = "point { size: 7; shape-type: circle; fill-color: #FFA07A; }";
                                } else if (entry.totalScore < 20) {
                                  style = "point { size: 7; shape-type: circle; fill-color: #FF4500; }";
                                } else if (entry.totalScore < 28) {
                                  style = "point { size: 7; shape-type: circle; fill-color: #DC143C; }";
                                } else {
                                  style = null;
                                }
                                return [dateStr, entry.totalScore, style];
                              }),
                          ];
                        
                          return (
                            <React.Fragment key={row.name}>
  
  
                              <TableRow>
                                <TableCell
                                  onClick={() => {
                                    setPatientName(currentName)

                                    handleTableCellClick(    row.lastName,
                                      row.firstName,
                                    row.patientId,
                                    row.userType,
                                    row.userProviderId,
                                    row.providerId,
                                    row.id,
                                    row.status,
                                    // pass lastName as a prop
                                

                                  )}}
                                  sx={{ width: '10%', whiteSpace: 'nowrap', overflow: 'hidden', zIndex: '-1', cursor: 'pointer',color:"#20c5ec" ,fontWeight:'bold'}}>
                                  {currentName}
                                </TableCell>
  
                                <TableCell align="left" sx={{ width: '10%', whiteSpace: 'nowrap', overflow: 'hidden' }}>{row.phq9Date}</TableCell>
                                <TableCell align="left" sx={{ width: '10%', whiteSpace: 'nowrap', overflow: 'hidden' }}>{row.ProviderName}</TableCell>
                                <TableCell align="left" sx={{ width: '10%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                  {row.TotalScore}
                                </TableCell>
                              </TableRow>
                              {!hasNextName && (
                                <TableRow>
                                    <TableCell align="left" sx={{ width: '10%', whiteSpace: 'nowrap', overflow: 'hidden', color: '#70D226' }}>
                                    <span style={{ marginLeft: "6rem" }}>Total Score Sum : {nameGroupData[currentName].totalScoreSum}</span>
                                  </TableCell>
                           
                                  <TableCell align="left" sx={{ width: '10%', whiteSpace: 'nowrap', overflow: 'hidden', color: '#70D226', }}>
                                  <span
                                    >Total Count : {nameGroupData[currentName].totalScoreCount}</span>
                                    <span  style={{marginLeft:'2rem'}} >  Average : {nameGroupData[currentName].totalScoreCount > 0 ? (nameGroupData[currentName].totalScoreSum / nameGroupData[currentName].totalScoreCount).toFixed(2) : 0}</span>
                                  </TableCell>
                                  <TableCell align="left" sx={{ width: '10%', whiteSpace: 'nowrap', overflow: 'hidden', color: '#70D226',marginRight:'3rem' }}>
  
                                  </TableCell>
                                
                                  <TableCell component="th" scope="row" sx={{ width: '10.2%', color: '#70D226', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                   
  
                                  </TableCell>
                                </TableRow>
                              )}
                              
                           {!hasNextName && (<>
                            <TableRow style={{ width: '100%' }}>
                            <TableCell align="left" sx={{ width: '10%', height: '4.5rem', whiteSpace: 'nowrap', overflow: 'hidden', color: '#70D226', }}>
  <Box
    sx={{
      width:  '100%', 
      padding:   0, 
      borderRadius:   2, 
      backgroundColor: 'white', 
      color: 'black',
      border: '2px solid', 
      borderColor: 'primary.main', 
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'center', 
      alignItems: 'center', 
      boxShadow:   3, 
      gap:   0, // Add some space between the items
      overflow: 'auto', 
    }}
  >
    <Box sx={{ display: 'flex', justifyContent: 'center', gap:   2 }}>
      <Typography variant="body2">
        First PHQ9 Date   
      </Typography>
    </Box>
    <Typography variant="body2" sx={{ color: 'primary.main' }}>
      {row.phq9FirstDate}
    </Typography>
  </Box>
</TableCell>

                                  <TableCell align="left" sx={{ width: '10%', height: '4rem', whiteSpace: 'nowrap', overflow: 'hidden', color: '#70D226', }}>
  <Box
    sx={{
      maxHeight: '4.5rem', 

      width:  '100%', 
      padding:   0, 
      borderRadius:   2, 
      backgroundColor: 'white', 
      color: 'black',
      border: '2px solid', 
      borderColor: 'primary.main', 
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'center', 
      alignItems: 'center', 
      boxShadow:   3, 
      gap:   0, // Add some space between the items
    }}
  >
    <Box sx={{ display: 'flex', justifyContent: 'center', gap:   2 }}>
      <Typography variant="body2">
        Last PHQ9 Date   
      </Typography>
    </Box>
    <Typography variant="body2" sx={{ color: 'primary.main' }}>
      {row.phq9LastDate}
    </Typography>
  </Box>
</TableCell>

                                  <TableCell align="left" sx={{ width: '10%', whiteSpace: 'nowrap', overflow: 'hidden', color: '#70D226', }}>
                     

                                  <Box
  sx={{
    width:  '100%', 
    padding:   0, 
    borderRadius:   2, 
    backgroundColor: 'white', 
    color: 'black',
    border: '2px solid', 
    borderColor: 'primary.main', 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center', 
    boxShadow:   3, 
    gap:   0, // Add some space between the items
    maxHeight: '4.5rem', 
    overflow: 'auto', 
  }}
>
  <Box sx={{ display: 'flex', justifyContent: 'center', gap:   2 }}>
    <Typography variant="body2">
      First PHQ9 Score   
    </Typography>
  </Box>
  <Typography variant="body2" sx={{ color: 'primary.main' }}>
    {row.PHQ9StartScore}
  </Typography>
</Box>




                                  </TableCell>

                                  <TableCell align="left" sx={{ width: '10%', whiteSpace: 'nowrap', overflow: 'hidden', color: '#70D226', }}>
                        
<Box
      sx={{
        width:  '100%', 
        padding:   0, 
        borderRadius:   2,
        backgroundColor: 'white', 
        color: 'black',
        border: '2px solid', 
        borderColor: 'primary.main', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        boxShadow:   3, 
        maxHeight: '4.5rem', 
        overflow: 'auto', 
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', gap:   2 }}>
        <Typography variant="body2">
        Last PHQ9 Score
        </Typography>
        

      </Box>
      <Typography variant="body2" sx={{ color: 'primary.main' }}>
      {row.PHQ9LastScore}
    </Typography>
    </Box>




                                  </TableCell>
                             
</TableRow>



                         </>   
                         
                         
                         )}

{CheckboxGraph === 'true' && !hasNextName && (
  <>
    {personChartData?.length <= 2 ? (
      <div style={{ color: 'red', marginLeft: '1rem' }}>There should be at least 2 records for the graph.</div>
    ) : (
      <TableRow style={{ width: '100%' }}>
        <TableCell colSpan={4}>
          <div style={{ maxWidth: '100%', margin: '0 auto', border: '1px solid blue', borderRadius: '10px', padding: '20px' }}>
            <h2 style={{ textAlign: 'center', marginBottom: '20px', color: '#70D226', fontSize: '19px' }}>
              {currentName}'s PHQ9 Scores Over Time
            </h2>
            <div style={{ color: '#9155FD', fontSize: '19px', marginLeft: '8%' }} onClick={() => {
              handleFullGraphViewClickPhq9(personChartData, currentName);
              setGadScore(row.totalScore);
            }}>
              <VisibilityIcon /> View Full
            </div>

            <Chart
              width={'100%'}
              height={'400px'}
              chartType="LineChart"
              loader={<div>Loading Chart</div>}
              data={personChartData}
              options={LineChartOptionsPHQ}
            />
          </div>
        </TableCell>
      </TableRow>
    )}
  </>
)}



                            </React.Fragment>
                          );
                        }))}
                     
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
  
  
  
              </Grid>
            </TabPanel>
            <TabPanel value={1}>
            <Modal
                  open={open3}
                  onClose={handleClose3}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
                >
                  <Box sx={styleDetails}>
                    <Grid container columnSpacing={1}>
  
                      <PatientRegistration patientName={patientName} patientId={patientId} datamining ={dataMinings} handleClose3={handleClose3} />
  
  
                    </Grid>
                  </Box>
                </Modal>
                <Modal
  open={openFullGraphModel}
  onClose={handleClose4}
  aria-labelledby='modal-modal-title'
  aria-describedby='modal-modal-description'
>
  <Box sx={styleDetails}>
  <Grid container xs={12}>

<Grid item xs={11.6}>
</Grid>
<Grid item xs={0.4}>

  <p style={{float:'right',color:'red'}} onClick={()=>{setopenFullGraphModel(false)}}><CloseIcon/></p>
</Grid>
</Grid>
    <Grid container columnSpacing={1}>
      {Gad7Data === '' || Gad7Data.length === 0 ? (
        <Typography style={{ marginTop: '2rem', marginLeft: '90%', textAlign: 'center', width: '100%' }}>{ErrorMsgPhq9}</Typography>
      ) : (


          <React.Fragment > 
                <div style={{ width: '100%' }}>
                <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
      <Grid item xs={1}>
</Grid>
        <Grid item xs={4}>
        <Col
                sm={10}
                xs={12}
                lg={8}
                md={10}
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                <label style={{ fontWeight: "bold" }}>PHQ9</label>
                <label
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    backgroundColor: "#389837",
                    width: 45,
                    borderRadius: 20,
                    padding: 5,
                    marginLeft: "2px",
                    marginBottom: 10,
                  }}
                >
                  0-4
                </label>
                <label
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    backgroundColor: "#fbcf33",
                    width: 45,
                    borderRadius: 20,
                    padding: 5,
                    marginLeft: "2px",
                    marginBottom: 10,
                  }}
                >
                  5-9
                </label>
                <label
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    backgroundColor: "#FFA07A",
                    width: 45,
                    borderRadius: 20,
                    padding: 5,
                    marginLeft: "2px",
                    marginBottom: 10,
                  }}
                >
                  10-14
                </label>
                <label
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    backgroundColor: "#FF4500",
                    width: 45,
                    borderRadius: 20,
                    padding: 5,
                    marginLeft: "2px",
                    marginBottom: 10,
                  }}
                >
                  15-19
                </label>
                <label
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    backgroundColor: "#DC143C",
                    width: 45,
                    borderRadius: 20,
                    padding: 5,
                    marginLeft: "2px",
                    marginBottom: 10,
                  }}
                >
                  20-27
                </label>
              </Col>
        </Grid>
        <Grid item xs={1.5}>
    {fullGraphData?.length>6?(    <Typography style={{ fontSize: '20px', fontWeight: 'bold',marginTop:'1rem' }}>
              {/* bbbbb */}
              <input type="checkbox" id="as" onChange={handleCheckboxChange} name="vehicle" value="Bi" style={{ fontSize: '16px' }} />

              <label htmlFor="vehicle1" style={{ fontSize: '16px',marginLeft:'0.4rem' ,color:'#9155FD'}}>View Full Data</label>
            
                        </Typography>     ):''}   </Grid>
        <Grid item xs={4}>
        {<><div style={{ color: '#70D226', fontSize: '19px' }}> {Gad7NameGraph}'s GAD7 Scores Over Time</div></>}
       </Grid>
    
      </Grid>
    </Box>
                 
                  <Chart
                                      style={{ backgroundColor: "#73C2E3" }}

                    width={'100%'}
                    height={'400px'}
                    chartType="LineChart"
                    loader={<div>Loading Chart</div>}
                    // data={fullGraphData}
                    
                 data=   {showAllDatainFullGraph ? fullGraphData : (fullGraphData || []).slice(0, 6)}
                  
                    options={LineChartOptionsPHQ}

                  />
                </div> 
            </React.Fragment>
      )
        }
     
      
    </Grid>
  </Box>
</Modal>

              <Grid
                container
                spacing={1}
                style={{
                  width: '100%',
                  marginBottom: '10px',
                  border: '1px solid rgba(5, 152, 236, 0.637)',
                  borderRadius: '20px',
                  marginTop: '10px',
                  paddingBottom: '20px',
                  marginLeft: '1px',
                  padding: '15px',
                  backgroundColor: 'white',
                }}
              >
  
  
  
                <div className='col-md-12'>
                  <TableContainer component={Paper}>
                    <Table sx={{ width: '100%' }} aria-label="caption table">
                      <TableRow style={{ width: '100%', background: '#70D226', color: 'white' }} >
                        <TableCell sx={{ width: '20%', whiteSpace: 'nowrap', overflow: 'hidden' }}>Name</TableCell>
                        <TableCell sx={{ width: '10%', whiteSpace: 'nowrap', overflow: 'hidden' }}>Date</TableCell>
                        <TableCell sx={{ width: '20%', whiteSpace: 'nowrap', overflow: 'hidden' }}>Provider Name</TableCell>
                        <TableCell sx={{ width: '10%', whiteSpace: 'nowrap', overflow: 'hidden' }}>GAD7&nbsp;</TableCell>
                      </TableRow>
  
                      <TableBody>
                   {Gad7Data === '' || Gad7Data.length===0 ? <Typography style={{marginTop:'2rem',marginLeft:'90%', textAlign: 'center',width:'100%'}}>{ErrorMsgPhq9}</Typography> : (  Array.isArray(Gad7Data) && Gad7Data.map((row, index) => {
                          const currentName = `${row.firstName} ${row.lastName}`;
                          const isNewName = currentName !== prevNameGad7;
                          prevNameGad7 = currentName;
                          const currentId = row.id;
                          const totalScoreSumGad7 = Gad7Data
                            .filter(groupRow => `${groupRow.firstName} ${groupRow.lastName}` === currentName)
                            .reduce((sum, groupRow) => {
                              return sum + groupRow.totalScore;
                            }, 0);
                          if (!nameGroupDataGad7[currentName]) {
                            nameGroupDataGad7[currentName] = {
                              totalScoreSum: 0,
                              totalScoreCount: 0,
                            };
                          }
  
                          nameGroupDataGad7[currentName].totalScoreSum += row.totalScore;
                          nameGroupDataGad7[currentName].totalScoreCount++;
                          const isLastRow = index === Gad7Data.length - 1;
                          const hasNextName = Gad7Data[index + 1] && `${Gad7Data[index + 1].firstName} ${Gad7Data[index + 1].lastName}` === currentName;
                          
                      
                          
                          const personChartDataGad7 = [
                            [
                              { type: "string", label: "Date" },
                              { type: "number", label: "PHQ9 Score" },
                              { type: "string", role: "style" },
                            ],
                            ...Gad7Data
                              .filter(entry => `${entry.firstName} ${entry.lastName}` === currentName)
                              .map(entry => {
                                const dateStr = new Date(entry.gad7Date).toLocaleDateString();
                                let style = '';
                                if (entry.totalScore < 4) {
                                  style = "point { size: 7; shape-type: circle; fill-color: #389837; }";
                                } else if (entry.totalScore < 10) {
                                  style = "point { size: 7; shape-type: circle; fill-color: #fbcf33; }";
                                } else if (entry.totalScore < 15) {
                                  style = "point { size: 7; shape-type: circle; fill-color: #FFA07A; }";
                                } else if (entry.totalScore < 20) {
                                  style = "point { size: 7; shape-type: circle; fill-color: #FF4500; }";
                                } else if (entry.totalScore < 28) {
                                  style = "point { size: 7; shape-type: circle; fill-color: #DC143C; }";
                                } else {
                                  style = null;
                                }
                                return [dateStr, entry.totalScore, style];
                              }),
                          ];
                        // setGraphData(personChartDataGad7)
                          return (
                            <React.Fragment key={row.name}>
                              <TableRow>
                                <TableCell   onClick={() => handleTableCellClick1(
                                    row.patientId,
                                    row.userType,
                                    row.userProviderId,
                                    row.providerId,
                                    row.id,
                                    row.status,
                                    // pass lastName as a prop
                                    row.lastName
                                  )} component="th" scope="row" sx={{ width: '20%', whiteSpace: 'nowrap', overflow: 'hidden', cursor: 'pointer' ,color: '#20c5ec',fontWeight:'bold'}}>
                                  {currentName}
                                </TableCell>
                                <TableCell align="left" sx={{ width: '25%', whiteSpace: 'nowrap', overflow: 'hidden' }}>{row.gad7Date}</TableCell>
                                <TableCell align="left" sx={{ width: '20%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                  {row.providerName}
                                </TableCell>
                                <TableCell align="left" sx={{ width: '10%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                  {row.totalScore}
                                </TableCell>
                              </TableRow>
                              {!hasNextName && (
                                <TableRow>
                                  <TableCell component="th" scope="row" sx={{ width: '30%', color: '#70D226', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                    <span style={{ marginLeft: "6rem" }}>Total Score Sum : {nameGroupDataGad7[currentName].totalScoreSum}</span>
  
                                  </TableCell>
                                  <TableCell align="left" sx={{ width: '20%', whiteSpace: 'nowrap', overflow: 'hidden', color: '#70D226' }}>
                                    <span style={{ marginLeft: '1rem' }}>Average : {nameGroupDataGad7[currentName].totalScoreCount > 0 ? (nameGroupDataGad7[currentName].totalScoreSum / nameGroupDataGad7[currentName].totalScoreCount).toFixed(2) : 0}</span>
                                    <span style={{marginLeft:'2rem'}}>Total Count : {nameGroupDataGad7[currentName].totalScoreCount}</span>
                                  </TableCell>
                                  <TableCell align="left" sx={{ width: '20%', whiteSpace: 'nowrap', overflow: 'hidden', color: '#70D226' }}>
                                   
                                  </TableCell>
                                </TableRow>
                              )}
                              {!hasNextName && (<>
                            <TableRow>
                            <TableCell align="left" sx={{ width: '10%', height: '4.5rem', whiteSpace: 'nowrap', overflow: 'hidden', color: '#70D226', }}>
  <Box
    sx={{
      width:  '100%', 
      padding:   0, 
      borderRadius:   2, 
      backgroundColor: 'white', 
      color: 'black',
      border: '2px solid', 
      borderColor: 'primary.main', 
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'center', 
      alignItems: 'center', 
      boxShadow:   3, 
      gap:   0, // Add some space between the items
      overflow: 'auto', 
    }}
  >
    <Box sx={{ display: 'flex', justifyContent: 'center', gap:   2 }}>
      <Typography variant="body2">
        First GAD7 Date   
      </Typography>
    </Box>
    <Typography variant="body2" sx={{ color: 'primary.main' }}>
      {row.gad7FirstDate}
    </Typography>
  </Box>
</TableCell>

                                  <TableCell align="left" sx={{ width: '10%', height: '4rem', whiteSpace: 'nowrap', overflow: 'hidden', color: '#70D226', }}>
  <Box
    sx={{
      maxHeight: '4.5rem', 

      width:  '100%', 
      padding:   0, 
      borderRadius:   2, 
      backgroundColor: 'white', 
      color: 'black',
      border: '2px solid', 
      borderColor: 'primary.main', 
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'center', 
      alignItems: 'center', 
      boxShadow:   3, 
      gap:   0, // Add some space between the items
    }}
  >
    <Box sx={{ display: 'flex', justifyContent: 'center', gap:   2 }}>
      <Typography variant="body2">
        Last GAD7 Date   
      </Typography>
    </Box>
    <Typography variant="body2" sx={{ color: 'primary.main' }}>
      {row.gad7LastDate}
    </Typography>
  </Box>
</TableCell>

                                  <TableCell align="left" sx={{ width: '10%', whiteSpace: 'nowrap', overflow: 'hidden', color: '#70D226', }}>
                     

                                  <Box
  sx={{
    width:  '100%', 
    padding:   0, 
    borderRadius:   2, 
    backgroundColor: 'white', 
    color: 'black',
    border: '2px solid', 
    borderColor: 'primary.main', 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center', 
    boxShadow:   3, 
    gap:   0, // Add some space between the items
    maxHeight: '4.5rem', 
    overflow: 'auto', 
  }}
>
  <Box sx={{ display: 'flex', justifyContent: 'center', gap:   2 }}>
    <Typography variant="body2">
      First GAD7 Score   
    </Typography>
  </Box>
  <Typography variant="body2" sx={{ color: 'primary.main' }}>
    {row.gaD7StartScore}
  </Typography>
</Box>




                                  </TableCell>

                                  <TableCell align="left" sx={{ width: '10%', whiteSpace: 'nowrap', overflow: 'hidden', color: '#70D226', }}>
                        
<Box
      sx={{
        width:  '100%', 
        padding:   0, 
        borderRadius:   2,
        backgroundColor: 'white', 
        color: 'black',
        border: '2px solid', 
        borderColor: 'primary.main', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        boxShadow:   3, 
        maxHeight: '4.5rem', 
        overflow: 'auto', 
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', gap:   2 }}>
        <Typography variant="body2">
        Last GAD7 Score
        </Typography>
        

      </Box>
      <Typography variant="body2" sx={{ color: 'primary.main' }}>
      {row.gaD7LastScore}
    </Typography>
    </Box>




                                  </TableCell>
                             
</TableRow>
            {CheckboxGraph === 'true' && !hasNextName && (<>
              {personChartDataGad7.length <= 2 ?(<div style={{color:'red'}}>There should be atleast 2 records for graph.</div>) :( <>    <TableRow style={{ width: '100%'     }}>
                    <TableCell colSpan={4}>
                    <div style={{ maxWidth: '100%', margin: '0 auto', border: '1px solid ',color:'#20c5ec', borderRadius: '10px', padding: '20px' }}>
                      
            <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>{<div style={{color:'#70D226',fontSize:'19px'}}>{currentName}'s GAD7 Scores Over Time</div>}</h2>
             {<div style={{color:'primary.main',fontSize:'19px',marginLeft:'8%'}} onClick={()=>{handleFullGraphViewClick(personChartDataGad7,currentName),
         
            updateData(row.totalScore, row.gad7Date); // Pass totalScore and gad7Date to updateData
            }}><VisibilityIcon/> View Full</div>}
            <Chart
                width={'100%'}
                height={'400px'}
                chartType="LineChart"
                loader={<div>Loading Chart</div>}
                data={personChartDataGad7}
                options={LineChartOptionsPHQ}

            />
        </div>
                    </TableCell>
                </TableRow></>)}

            </>
            )}


                         </>    )}
                            </React.Fragment>
                          );
                        }))}
  
                      
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                
  
              </Grid>
            </TabPanel>
          </TabContext>
        </div></>
     ):
    //  DataMiii
(
  <div className={classes.tabContainer}>
          <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange1} aria-label="lab API tabs example">
                <Tab value={0} label="PHQ9" />
                <Tab value={1} label="GAD7" />
              </TabList>
            </Box>
            <TabPanel value={0}>
              <Grid
                container
                spacing={1}
                style={{
                  width: '100%',
                  marginBottom: '10px',
                  border: '1px solid rgba(5, 152, 236, 0.637)',
                  borderRadius: '20px',
                  marginTop: '10px',
                  paddingBottom: '20px',
                  marginLeft: '1px',
                  padding: '15px',
                  backgroundColor: 'white',
                }}
              >

<TableContainer >
<Box sx={{ flexGrow: 1 }} >
      <Grid container spacing={1} style={{ background: '#FFFFC2', color: 'black',height:'50px'}}>
      <Grid item xs={0.1}>
        
        </Grid>
        <Grid item xs={1.2} style={{ width: '12.5%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
        Average
        </Grid>
        <Grid item xs={1.5} style={{ width: '12.5%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
        First Score
        </Grid>
        <Grid item xs={1.5} style={{ width: '12.5%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
        Last Score        </Grid>
        <Grid item xs={4} style={{ width: '12.5%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
        {phq9[0]?.weekStartDate ? 'Week Start Date' : 'Month/Year'}{' '}
     <span style={{marginLeft:'1rem'}}>   {phq9[0]?.weekStartDate ? 'Week End Date' : ''}{' '}</span>

        </Grid>
    
        <Grid item xs={1.5} style={{ width: '12.5%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
        Total PHQ9        </Grid>
        <Grid item xs={1.5} style={{ width: '12.5%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
        Total Score{' '}
        </Grid>
      </Grid>
    </Box>
      <Table style={{ width: '100%', tableLayout: 'fixed' }} aria-label="caption table">
   
<TableBody>
  {phq9.length === 0 ? (
    <TableRow>
      <TableCell colSpan={8} style={{ textAlign: 'center' }}>
        <Typography variant="body1">{ErrorMsgPhq9}</Typography>
      </TableCell>
    </TableRow>
  ) : (
    Object.keys(groupedData).map((monthYear) => {
      if (monthYear === null && monthYear === undefined){''} else{
        return [
          <TableRow key={monthYear}>
            <TableCell
              colSpan={8}
              style={{
                fontWeight: 'bold',
                backgroundColor: '#f5f5f5',
                borderBottom: '1px solid #ddd',
              }}
            >
              {monthYear==='null'?'':monthYear}
            </TableCell>
          </TableRow>,
          ...groupedData[monthYear].map((row) => (<>
            <TableRow key={row.name}>
            <TableCell style={{ width: '10.5%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
               {row.average}
            </TableCell>
            <TableCell style={{ width: '12.5%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
               {row.firstScore}
            </TableCell>
            <TableCell style={{ width: '12.5%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
               {row.lastScore}
            </TableCell>
            <TableCell style={{ width: '16.5%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
              <span style={{marginLeft:'-1rem'}}>{row.weekStartDate ?? row.monthYear} </span> 
            </TableCell>
            <TableCell style={{ width: '19.5%', whiteSpace: 'nowrap', overflow: 'hidden', }}>
             <span >  {row.weekEndDate ?? ''}</span>
            </TableCell>
            <TableCell style={{ width: '15.5%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
  <span style={{ marginLeft: '5rem' }}>{row.totalPHQ9}</span>
</TableCell>

            <TableCell style={{ width: '14.5%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            <span style={{marginLeft:'5rem'}}>       {row.totalScore}</span>
            </TableCell>
           </TableRow>
           <TableRow>
        
</TableRow>
           </>
          )),
        ];
      }
      return null; // Exclude null or undefined monthYear
    }).filter(Boolean) // Filter out null or undefined values
  )}


</TableBody>


      </Table>

      {Phq9chartData.length === 0 || CheckboxGraph==='false' ? (
'') : (
  <Chart
  width={'100%'}
  height={'400px'}
  chartType="LineChart"
  loader={<div>Loading Chart</div>}
  data={Phq9chartData}
  options={LineChartOptionsPHQ1}
/>
)}
    </TableContainer>

                </Grid>
              </TabPanel>
              <TabPanel value={1}>
              <Grid
                container
                spacing={1}
                style={{
                  width: '100%',
                  marginBottom: '10px',
                  border: '1px solid rgba(5, 152, 236, 0.637)',
                  borderRadius: '20px',
                  marginTop: '10px',
                  paddingBottom: '20px',
                  marginLeft: '1px',
                  padding: '15px',
                  backgroundColor: 'white',
                }}
              >


<TableContainer >
<Box sx={{ flexGrow: 1 }} >
      <Grid container spacing={1} style={{ background: '#FFFFC2', color: 'black',height:'50px'}}>
      <Grid item xs={0.1}>
        
        </Grid>
        <Grid item xs={1.2} style={{ width: '12.5%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
        Average
        </Grid>
        <Grid item xs={1.5} style={{ width: '12.5%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
        First Score
        </Grid>
        <Grid item xs={1.5} style={{ width: '12.5%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
        Last Score        </Grid>
        <Grid item xs={4} style={{ width: '12.5%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
        {Gad7Data[0]?.weekStartDate ? 'Week Start Date' : 'Month/Year'}{' '}
     <span style={{marginLeft:'1rem'}}>   {Gad7Data[0]?.weekStartDate ? 'Week End Date' : ''}{' '}</span>

        </Grid>
    
        <Grid item xs={1.5} style={{ width: '12.5%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
        Total GAD7        </Grid>
        <Grid item xs={1.5} style={{ width: '12.5%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
        Total Score{' '}
        </Grid>
      </Grid>
    </Box>
      <Table style={{ width: '100%', tableLayout: 'fixed' }} aria-label="caption table">
        
       
<TableBody>
  {Gad7Data.length === 0 ? (
    <TableRow>
      <TableCell colSpan={8} style={{ textAlign: 'center' }}>
        <Typography variant="body1">{ErrorMsgPhq9}</Typography>
      </TableCell>
    </TableRow>
  ) : (
    Object.keys(groupedDataGad7).map((monthYear) => {
      if (monthYear === null && monthYear === undefined){''} else{
        return [
          <TableRow key={monthYear}>
            <TableCell
              colSpan={8}
              style={{
                fontWeight: 'bold',
                backgroundColor: '#f5f5f5',
                borderBottom: '1px solid #ddd',
              }}
            >
              {monthYear==='null'?'':monthYear}
            </TableCell>
          </TableRow>,
          ...groupedDataGad7[monthYear].map((row) => (
            <TableRow key={row.name}>
            <TableCell style={{ width: '10.5%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
               {row.average}
            </TableCell>
            <TableCell style={{ width: '12.5%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
               {row.firstScore}
            </TableCell>
            <TableCell style={{ width: '12.5%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
               {row.lastScore}
            </TableCell>
            <TableCell style={{ width: '16.5%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
              <span style={{marginLeft:'-1rem'}}>{row.weekStartDate ?? row.monthYear} </span> 
            </TableCell>
            <TableCell style={{ width: '19.5%', whiteSpace: 'nowrap', overflow: 'hidden', }}>
             <span >  {row.weekEndDate ?? ''}</span>
            </TableCell>
            <TableCell style={{ width: '15.5%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
  <span style={{ marginLeft: '5rem' }}>{row.totalGAD7}</span>
</TableCell>

            <TableCell style={{ width: '14.5%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            <span style={{marginLeft:'5rem'}}>       {row.totalScore}</span>
            </TableCell>
           </TableRow>
          )),
        ];
      }
      return null; // Exclude null or undefined monthYear
    }).filter(Boolean) // Filter out null or undefined values
  )}
</TableBody>


      </Table>
    </TableContainer>























    {gad7CartMonth.length === 0 || CheckboxGraph==='false' ? (
'') : (
  <Chart
  width={'100%'}
  height={'400px'}
  chartType="LineChart"
  loader={<div>Loading Chart</div>}
  data={gad7CartMonth}
  options={LineChartOptionsPHQ1}
/>
)}
                </Grid>
</TabPanel>
</TabContext>

  </div>

)     }
  

    </>
  )
}

export default DataMining
