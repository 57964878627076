/* eslint-disable object-shorthand */
// @mui material components
import Card from '@mui/material/Card'

// Soft UI Dashboard React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiInput from 'components/SuiInput'
import SuiButton from 'components/SuiButton'
import { useAlert } from 'react-alert'
import Autocomplete from '@mui/material/Autocomplete'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import SaveIcon from 'assets/save.png'
import CancelIcon from 'assets/cancel.png'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import input from '@mui/material/Input'
import { Grid, Snackbar, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useReducer, useState, Fragment } from 'react'
import { createBrowserHistory } from 'history'
import Checkbox from '@mui/material/Checkbox'
import { useLocation } from 'react-router-dom'
import ReactSelect from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Typography } from '@mui/material'
import SuiSelect from 'components/SuiSelect'
import Button from '@mui/material/Button'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import CircularProgress from '@mui/material/CircularProgress'
import InputMask from 'react-input-mask'

const history = createBrowserHistory({ forceRefresh: true })

const fields = [
  'id',
  'name',
  'payerId',
  'phone',
  'fax',
  'email',
  'website',
  'address',
  'city',
  'state',
  'zip',
  'phq9Frequency',
  'phq9Number',
  'gad7Frequency',
  'gad7Number',
  'smokingCessationFrequency',
  'smokingCessationNumber',
  'inActive',
  'copay',
  'source',
]
const defValues = {
  id:'',
  name: '',
  payerId: '',
  phone: '',
  fax: '',
  email: '',
  website: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  phq9Frequency:'',
  phq9Number:'',
  gad7Frequency: '',
  gad7Number: '',
  smokingCessationFrequency: '',
  smokingCessationNumber: '',
  inActive: false,
  copay:'',
  source:'',
}
function handleCancel() {
  history.push('/Insuranceplan')
}
function InsuranceRegistration() {
  const location = useLocation()
  const alert = useAlert()
  const [submitting, setSubmitting] = useState(false)
  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string()
      .email('Email is invalid'),
  })

  const [frequency, setfrequency] = React.useState('Annually')

  const handleChange = (event) => {
    setfrequency(event.target.value)
  }
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  })
  const onSubmit = (data) => {
    setSubmitting(true)
    const postData = data
    console.warn('Data: ', postData)
    if (location.state.insuranceid !== undefined && location.state.insuranceid != null)
      postData.Id = location.state.insuranceid
    else postData.Id = 0
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Insurances/AddInsurances`,
        postData,
        { headers }
      )
      .then((response) => {
        setSubmitting(false)
        alert.success('Record saved successfully.', {
          type: 'success',
          onClose: () => {
            history.push('/Insuranceplan')
          },
        })
      })
      .catch((error) => {
        setSubmitting(false)
        alert.success(`Error ${error.message}`, {
          type: 'error',
        })
      })
  }
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    let isComponentMounted = true
    const fetchData = async () =>
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/Insurances/getInsurancesById?Id=${
            location.state.insuranceid !== undefined ? location.state.insuranceid : 0
          }`,
          { headers }
        )
        // .post(
        //   `${process.env.REACT_APP_API_URL}/Users/getUserById?id=${
        //     location.state.userId !== undefined ? location.state.userId : 0
        //   }`,
        //   null,
        //   {
        //     headers,
        //   }
        // )
        .then((response) => {
          console.log('response.data: ', response.data)
          if (isComponentMounted) {
            // setUsersData(response.data);
            fields.forEach((field) => setValue(field, response.data[field]))
            /*  const petList = Object.entries(response.data).map(([key, value]) => {
              console.log("Key: ", key, value);
              register(`${key}`, `${value}`);
              return [key, value];
            }); */
          }
          setFax(response.data.fax)
          setPhone(response.data.phone)
        })
        .catch((error) => {
          console.error('There was an error!', error)
        })
    fetchData()
    return () => {
      isComponentMounted = false
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [])
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const loading = open && options.length === 0
  const zipCodeListStyle = {
    cursor: 'pointer',
    '&:hover': {
      background: '#3b3b3b !important',
      color: 'rgba(5, 152, 236, 0.637) !important',
    },
  }
 const [zipCodeList, setZipCodeList] = React.useState([])
  const [isZipCode, setIsZipCode] = React.useState(false)
  const onChangeHandle = async (value) => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    // console.log(value)

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Settings/Searchcitystate?zipcode=${value}`,
        { headers }
      )
      .then((response) => {
        setZipCodeList(response.data)
        setIsZipCode(true)
        setOptions(response.data)
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])
  const [Fax, setFax] = React.useState()
  const [phone, setPhone] = React.useState()
  return (
    <>
       {submitting ? (
        <CircularProgress
          style={{
            width: '50px',
            height: '50px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginLeft: '-25px',
            marginTop: '-25px',
            zIndex: 1000,
            color: '#FCBE13',
          }}
        />
      ) : null}
      <Grid
        container
        spacing={1}
        alignContent="center"
        justifyContent="center"
        style={{ paddingTop: '10px', }}
      >
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
          Insurance Name
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Payer ID
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Phone No
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter Full Name"
            name="fullName"
            label="fullName"
            fullWidth
            margin="dense"
            {...register('name')}
            error={errors.name}
          />
          <p style={{ color: "red", fontSize:"14px" }}>{errors.name?.message}</p>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter payerID"
            name="payerID"
            label="payerID"
            fullWidth
            margin="dense"
            {...register('payerId')}

          />
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
        <InputMask
            mask="+1 999-999-9999"
            disabled={false}
            value={phone}
            style={{ height: '30px' }}
            onChange={(e) => {
              setValue('phone', e.target.value)
              setPhone(e.target.value)
            }}
          >
            {() => (
              <SuiInput
                id="outlined-basic"
                fullWidth
                margin="dense"
                placeholder="Enter phoneNo"
              />
            )}
          </InputMask>
          {/* <SuiInput
            placeholder="Please enter phone"
            name="phone"
            label="phone"
            fullWidth
            margin="dense"
            {...register('phone')}

          /> */}
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Fax No
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Email
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            WebSite
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
        <InputMask
            mask=" 999-9999999"
            disabled={false}
            value={Fax}
            style={{ height: '30px' }}
            onChange={(e) => {
              setValue('fax', e.target.value)
              setFax(e.target.value)
            }}
          >
            {() => (
              <SuiInput
                id="outlined-basic"
                fullWidth
                margin="dense"
                placeholder="Enter Fax"
              />
            )}
          </InputMask>
          {/* <SuiInput
            placeholder="Please enter Fax"
            name="address"
            label="address"
            fullWidth
            margin="dense"
            {...register('fax')}
          /> */}
        </Grid>

        <Grid item xs={4} style={{ width: '300px' }}>

          <SuiInput
            placeholder="Please enter email"
            name="phoneNo"
            label="phoneNo"
            fullWidth
            margin="dense"
            {...register('email')}
            error={errors.email}
          />
           <p style={{ color: "red", fontSize:"14px" }}>{errors.email?.message}</p>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter website"
            name="fax"
            label="fax"
            fullWidth
            margin="dense"
            {...register('website')}

          />
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Address
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Zip Code
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            City
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter Address"
            name="address"
            label="address"
            fullWidth
            margin="dense"
            {...register('address')}

          />
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
        <SuiInput
            type="zip"
            placeholder="Enter ZipCode"
            name="zip"
            {...register('zip')}
          />
             

        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            type="input"
            placeholder="City"
            name="city"
            {...register('city')}
          />
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            State
          </SuiTypography>
        </Grid>
        <Grid item xs={8} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
           Copay
          </SuiTypography>
        </Grid>

        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter State"
            name="state"
            label="state"
            fullWidth
            margin="dense"
            {...register('state')}

          />
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
          type='number'
            placeholder="Please enter copay"
            name="copay"
            label="copay"
            fullWidth
            margin="dense"
            {...register('copay')}
            error={errors.copay}
          />
        </Grid>
        <Grid item xs={1} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Active
          </SuiTypography>
        </Grid>
        <Grid item xs={1} style={{ width: '300px' }}>
          {/* <SuiInput
            type="checkbox"
            margin="dense"
            {...register('inActive')}
          /> */}
          <SuiInput
  type="checkbox"
  margin="dense"
  {...register('inActive')}
  disabled
/>
          </Grid>
          {/* <Grid item xs={1} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption"fontSize="15px">
            Active
          </SuiTypography>
        </Grid>
        <Grid item xs={1} style={{ width: '300px' }}>
         <Controller
          name="inActive"
          control={control}
          render={({ field }) => (
            <SuiInput
              type="checkbox"
              margin="dense"
              checked={field.value === 1}
              onClick={() => setValue('inActive', field.value === 1 ? 0 : 1)}
            />
          )}
        />
           </Grid> */}
          <Grid item xs={2} style={{ width: '300px' }}>
     
        </Grid>
        <Grid xs={6}
          sm={6}
          xl={6}>
             <Grid
          container
          spacing={1}
          item
          xs={12}
          sm={12}
          xl={12}
          style={{
            // width: '100%',
           //marginBottom: '10px',
            border: '1px solid rgba(5, 152, 236, 0.637)',
            borderRadius: '20px',
            marginTop: '10px',
            paddingBottom: '20px',
            borderColor:"black",
            //paddingRight: '10px',
          }}
        >

          <Grid item xs={12}>
            <SuiTypography style={{ fontSize: '20px' }}>
             PHQ9:
            </SuiTypography>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={4} sm={4} xl={4}>
            <SuiTypography style={{ fontSize: '14px' }}>
              Frequency
            </SuiTypography>
          </Grid>
          <Grid item xs={8} sm={8} xl={8}>
            <SuiTypography style={{ fontSize: '14px' }}>Number</SuiTypography>
          </Grid>

          <Grid item xs={4} style={{ width: '300px' }}>
            <Controller
                name="phq9Frequency"
                {...register('phq9Frequency')}
                control={control}
                render={({ field }) => (
                  <SuiSelect
                    {...field}
                    value={{ label: getValues('phq9Frequency') }}
                    onChange={(value) => setValue('phq9Frequency', value.label)}
                    options={[
                      { value: 'Annually', label: 'Annually' },
                      { value: 'BiAnnually', label: 'BiAnnually' },
                      { value: 'Quarterly', label: 'Quarterly' },
                    ]}
                  />
                )}
              />

          </Grid>
          <Grid item xs={4} style={{ width: '300px' }}>
            <SuiInput
              style={{ width: '300px' }}
              type="input"
              placeholder="Enter Number"
              name="phq9Number"
              {...register('phq9Number')}
            />
          </Grid>
        </Grid>
          </Grid>
          <Grid xs={6}
          sm={6}
          xl={6}>
             <Grid
          container
          spacing={1}
          item
          xs={12}
          sm={12}
          xl={12}
          style={{
            // width: '100%',
           //marginBottom: '10px',
            border: '1px solid rgba(5, 152, 236, 0.637)',
            borderRadius: '20px',
            borderColor:"black",
            marginTop: '10px',
            paddingBottom: '20px',
            marginLeft: '5px',
            //paddingRight: '10px',
          }}
        >

          <Grid item xs={12}>
            <SuiTypography style={{ fontSize: '20px' }}>
              GAD7:
            </SuiTypography>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={4} sm={4} xl={4}>
            <SuiTypography style={{ fontSize: '14px' }}>
              Frequency
            </SuiTypography>
          </Grid>
          <Grid item xs={8} sm={8} xl={8}>
            <SuiTypography style={{ fontSize: '14px' }}>Number</SuiTypography>
          </Grid>
          <Grid item xs={4} style={{ width: '300px' }}>
            <Controller
                name="gad7Frequency"
                {...register('gad7Frequency')}
                control={control}
                render={({ field }) => (
                  <SuiSelect
                    {...field}
                    value={{ label: getValues('gad7Frequency') }}
                    onChange={(value) => setValue('gad7Frequency', value.label)}
                    options={[
                      { value: 'Annually', label: 'Annually' },
                      { value: 'BiAnnually', label: 'BiAnnually' },
                      { value: 'Quarterly', label: 'Quarterly' },
                    ]}
                  />
                )}
              />

          </Grid>
          <Grid item xs={4} style={{ width: '300px' }}>
            <SuiInput
              style={{ width: '300px' }}
              type="input"
              placeholder="Enter Number"
              name="gad7Number"
              {...register('gad7Number')}
            />
          </Grid>
        </Grid>
          </Grid>
        <Grid
          container
          spacing={1}
          item
          xs={12}
          sm={12}
          xl={12}
          style={{
            // width: '100%',
            marginBottom: '10px',
            border: '1px solid rgba(5, 152, 236, 0.637)',
            borderRadius: '20px',
            marginTop: '10px',
            paddingBottom: '20px',
            paddingRight: '10px',
            borderColor:"black",
          }}
        >
          <Grid item xs={12}>
            <SuiTypography style={{ fontSize: '20px' }}>
            Smoking Cessation:
            </SuiTypography>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={4} sm={4} xl={4}>
            <SuiTypography style={{ fontSize: '14px' }}>
              Frequency
            </SuiTypography>
          </Grid>
          <Grid item xs={8} sm={8} xl={8}>
            <SuiTypography style={{ fontSize: '14px' }}>Number</SuiTypography>
          </Grid>
          <Grid item xs={4} style={{ width: '300px' }}>
          <Controller
                name="smokingCessationFrequency"
                {...register('smokingCessationFrequency')}
                control={control}
                render={({ field }) => (
                  <SuiSelect
                    {...field}
                    value={{ label: getValues('smokingCessationFrequency') }}
                    onChange={(value) => setValue('smokingCessationFrequency', value.label)}
                    options={[
                      { value: 'Annually', label: 'Annually' },
                      { value: 'BiAnnually', label: 'BiAnnually' },
                      { value: 'Quarterly', label: 'Quarterly' },
                    ]}
                  />
                )}
              />
            {/* <Select
            variant='filled'
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={frequency}
              onChange={handleChange}
            >
              <MenuItem value={'Annually'}>Annually</MenuItem>
              <MenuItem value={'BiAnnually'}>BiAnnually</MenuItem>
              <MenuItem value={'Quarterly'}>Quarterly</MenuItem>

            </Select> */}
          </Grid>
          <Grid item xs={4} style={{ width: '300px' }}>
            <SuiInput
              style={{ width: '300px' }}
              type="input"
              placeholder="Enter Number"
              name="smokingCessationNumber"
              {...register('smokingCessationNumber')}
            />
          </Grid>
        </Grid>

        <Button
          onClick={handleSubmit(onSubmit)}
          variant="gradient"
          disabled={submitting}
          style={{
            marginTop: '50px',
            float: 'right',
            marginLeft: 'auto',
            width:'80px',
            height:'35px',
            backgroundColor: "#FCBE13",
            color:"black",
            fontSize:'14px'
          }}
        >
          <SaveOutlinedIcon
          fontSize='medium'
          style={{ color:'#1a82ad', paddingRight:'5px', paddingBottom:'2px'}}
          >
          </SaveOutlinedIcon>
          Save
        </Button>
        <Button
          onClick={handleCancel}
          variant="gradient"
          disabled={submitting}
          style={{
            marginTop: '50px',
            float: 'right',
            marginLeft: '20px',
            width:'90px',
            height:"35px",
            backgroundColor: "#FCBE13",
            color:"black",
            fontSize:'14px'

          }}


        >
          <CancelOutlinedIcon
          fontSize='medium'
         style={{ color:"red",paddingRight:'5px',}}
          ></CancelOutlinedIcon>
          Cancel
        </Button>
      </Grid>
    </>
  )
}

export default InsuranceRegistration
