import {
  Eventcalendar,
  getJson,
  setOptions,
  CalendarNav,
  SegmentedGroup,
  SegmentedItem,
  CalendarPrev,
  CalendarToday,
  CalendarNext,
  Button,
  Popup,
  formatDate,
  toast,
} from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PatientNotes from "./PatientNotes";
import Box from "@mui/material/Box";
import SuiTypography from "components/SuiTypography";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import Modal from "@mui/material/Modal";
import React, {
  useEffect,
  useReducer,
  useState,
  Fragment,
  useRef,
} from "react";
import AppointmentCreate from "./AppointmentCreate";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { Grid, IconButton, Snackbar, TextField, Typography } from "@mui/material";
import { date } from "yup";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Moment from "moment";
import moment from "moment";
import { useAlert } from "react-alert";
import MuiAlert from "@material-ui/lab/Alert";
import PatientRegistration from "./PatientRegistration";
import { createBrowserHistory } from "history";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SuiInput from "components/SuiInput";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import SuiSelect from "components/SuiSelect";
import { shallowEqual, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ReactSelect from "react-select";

const history = createBrowserHistory({ forceRefresh: true });
const UserData = [
  { label: 'Abbas,Hassnain', value: 3151 },
  { label: 'Ch,Ahtisham', value: 3152 },
  { label: 'Test,Patient', value: 3153 },
  { label: 'Muhammad Awais,siddique', value: 3154 },
  { label: 'Ali,Ehtesham', value: 3155 }
];
function Scheduler() {



  const [userMapping, setUserMapping] = useState({});

  useEffect(() => {
    // Create a dictionary for easy lookup
    const mapping = {};
    UserData.forEach(user => {
      mapping[user.value] = user.label;
    });

    setUserMapping(mapping);
  }, []);

  // Retrieve UserProvideID from localStorage
  const userProvidedID = parseInt(localStorage.getItem('dtt_user_provider_id'), 10);

  // Check if the provided ID exists in the mapping
  const userName = userMapping[userProvidedID];



  const UserProvideID = localStorage.getItem('dtt_user_provider_id')
  const formControlRef = useRef(null);
  const [providerRedux, setprovidersRedux] = useState([]);
  const [reduxLoaded, setreduxLoaded] = useState(false);
  // React.useEffect(() => {
  //     getJson('https://trial.mobiscroll.com/events/?vers=5', (events) => {
  //         setEvents(events);
  //     }, 'jsonp');
  // }, []);

  const providersRedx = useSelector(
    (state) => state.providers.NAMES,
    shallowEqual
  );

  const applyRefresh = () => {
    setRefresh(!refresh);
    console.log("refresh:", refresh);
  };
  const [id, setId] = useState("");
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [newfromDate, setnewFromDate] = useState(new Date().toDateString());
  const [dayOfWeekNumber, setdayOfWeekNumber] = useState(new Date().getDay());

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      fontSize: "12px",
      border: "1px solid #ccc",
      boxShadow: state.isFocused ? "0 0 0 2px #007bff" : null,
      // '&:hover': { borderColor: 'red' },
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "11px",
      cursor: "pointer",
      border: "1px solid #ccc",
      // boxShadow: state.isFocused ? '0 0 0 2px #007bff' : null,
      "&:hover": { borderColor: "red" },
    }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
  };

  const defValues = {
    Id: "",
    startTime: "",
    endTime: "",
    userName: "",
  };

  //mouse cliked end
  // console.log('new from date where it is define : ', newfromDate)
  const auth = `Bearer  ${localStorage.getItem("dtt_token")}`;
  const headers = {
    Authorization: auth,
  };
  const AppointmentCreat = (event) => {
    console.log("events", event);
    setAssignId(parseInt(event.event.id));
    setStartTime(event.event.start);
    setEndTime(event.event.end);
    setOpen(true);
  };

  const AppointmentCreateClick = (event) => {
    setAssignId(0);
    setStartTime(event.date);
    const eTimee = moment(event.date).add(10, "m");
    setEndTime(eTimee);
    setOpen(true);
  };

  const [myEvents, setEvents] = React.useState([]);
  const [background, setBackground] = React.useState("");
  const [ClrblockAppointment, setClrblockAppointment] = useState("");
  const backgroundColor = ClrblockAppointment === 'ClrblockAppointment' ? 'gray' : 'rgba(255, 255, 255, 0.5)';

  const useStyles = makeStyles((theme) => ({
    fab: {
      right: 20,
      bottom: 20,
    },
    container: {
      width: '600',
      height: 450,
      backgroundColor: "white",
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      margin: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "100vw",
        height: "100vh",
      },
    },
    form: {
      padding: theme.spacing(2),
    },
    item: {
      marginBottom: theme.spacing(3),
    },
    mdcustomeventcont: {
      fontSize: "12px",
      fontWeight: "600",
      height: "100%",
      background: "rgb(90, 200, 250, .5)",
      position: "relative",
      boxSizing: "border-box",
      boxShadow: "0 0 5px rgba(0, 0, 0, .3)",
      borderRadius: "0 10px 10px 0",
      overflow: "hidden",
      margin: "2px",
      "&:hover": {
        background: "rgb(90, 200, 250,1)",
      },
    },
    mdcustomeventwrapper: {
      position: "relative",
      
      background: ClrblockAppointment === 'ClrblockAppointment' ? 'rgba(128, 128, 128, .7)' : "rgba(255, 255, 255, .5)",

      // background: "rgba(255, 255, 255, .5)",
      height: "100%",
      minHeight: "135px",
      boxSizing: "border-box",
      borderRadius: "0 10px 10px 0",
      transition: "background .15s ease-in-out",
    },
    mdcustomeventtime: {
      fontSize: "10px",
      color: ClrblockAppointment==='ClrblockAppointment'?'white':"#666",
      
      // paddingTop: '3px',
      padding: "3px",
    },
    mdcustomeventtitle: {
      display: "inline-block",
      maxWidth: "100%",
      transition: "background.15s ease-in-out",
      fontSize: "15px",
      color: "black",
      borderRadius: "10px",
      margin: "5px",
      padding: "2px 3px",
      marinLeft: "5px",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    mdcustomeventfield: {
      color: ClrblockAppointment==='ClrblockAppointment'?'white':"black",
      paddingLeft: "3px",
    },
  }));
  const classes = useStyles();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#F0FFFF",
    border: "2px solid #black",
    borderRadius: "20px",
    boxShadow: 24,
    p: 2,
  };
  const [fromDate, setFromDate] = useState(new Date().setHours(9, 0, 0, 0));
  const [view, setView] = React.useState("day");
  const [toDate, setToDate] = useState(null);
  const [lastDate, setlastDate] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [assignId, setAssignId] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [endTime, setEndTime] = useState("");
  const [checkeddIn, setCheckeddIn] = useState(0);
  const [checkeddOut, setCheckeddOut] = useState(0);
  const [scheduledd, setScheduledd] = useState(0);
  const [totalAppointment, setTotalAppointment] = useState(0);
  const [pData, setPData] = useState("");

  const [providerId, setproviderId] = useState(UserProvideID);
  const [dayOFWeek, setdayOFWeek] = useState(moment(new Date()).format("dddd"));
  const [timeFrom, setTimeFrom] = useState("09:00");
  const [timeTo, settimeTo] = useState("17:00");


  useEffect(() => {
    var providerRedux = [];
    providerRedux.push({
      label:'All',
      value: '-1',
    })
    providersRedx.forEach((field) => {
      providerRedux.push({
        label: field.name,
        value: field.id,
      });
    });
    setprovidersRedux(providerRedux);
    setreduxLoaded(true);
    setValue('providerId',UserProvideID)
  }, []);

  useEffect(() => {
    setSubmitting(true);
    const postData = {
      providerId: providerId != null ? providerId : "",
      dayOFWeek,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/ProviderScheduler/GetProviderScheduler`,
        postData,
        {
          headers,
        }
      )
      .then((response) => {
        if (
          (response.data.startTime == undefined ||
            response.data.startTime == 0) &&
          (response.data.endTime == undefined || response.data.endTime == 0)
        ) {
          setTimeFrom("09:00");
          settimeTo("17:00");
        } else {
          setTimeFrom(response.data.startTime);
          settimeTo(response.data.endTime);
        }
        setSubmitting(false);
      })

      .catch((error) => {
        console.error(error);
        setSubmitting(false);
      });
  }, [providerId, dayOFWeek]);

  useEffect(() => {
    if (timeFrom && timeTo) {
      setCalView({
        schedule: {
          type: "day",
          days: false,
          size: 1,
          // startDay: dayOfWeekNumber,
          // endDay: dayOfWeekNumber,
          startTime: timeFrom,
          endTime: timeTo,
          timeCellStep: 10,
          timeLabelStep: 10,
          allDay: false,
        },

        // schedule: {
        //   type: 'day',
        //   // startDay: 0,
        //   // endDay: 6,
        //   startDay: dayOfWeekNumber,
        //   endDay: dayOfWeekNumber,
        //   startTime: timeFrom,
        //   endTime: timeTo,
        //   timeCellStep: stepDuration,
        //   timeLabelStep: stepDuration,
        //   allDay: false
        // }
      });
    }
  }, [timeFrom, timeTo]);

  const handleChange = (event) => {
    console.log("handleChange Event.dateee value is :", event.date);
    setFromDate(event.date);
    // setToDate('')
  };

  const ViewChange = (event) => {
    if (view == "day") {
      // setFromDate(event.firstDay)
      // setToDate(event.firstDay)
      console.log("view === day First Value", event.firstDay);
      console.log("view === day Last Value", event.firstDay);
    } else {
      // setToDate(event.lastDay)
      // setFromDate(event.firstDay)
      setlastDate(event.lastDay);
      console.log("view First Value", event.firstDay);
      console.log("view Last Value", event.lastDay);
    }
  };

  var fdate;
  var tdate;
  useEffect(() => {
    // window.alert('api called')
    // apischeduler
    setEvents([]);
    setSubmitting(true);
    var fdate = moment(fromDate).format("L");
    fdate = Moment(fdate).format("YYYY-MM-DD");
    // console.log('Testing UseEffect 1', moment(fromDate).format('L'))
    // console.log(
    //   'Testing UseEffect 2 After format change',
    //   Moment(fdate).format('YYYY-MM-DD')
    // )

    if (lastDate != null && view != "day") {
      // var tdate = moment(toDate).format('L')
      console.log("lastDate My jo value ha Testing 1 : ", lastDate);
      tdate = Moment(lastDate).format("YYYY-MM-DD");

      console.log(
        "Testing UseEffect 2 After format change lastDate :",
        Moment(lastDate).format("YYYY-MM-DD")
      );
    } else {
      tdate = fdate;
    }

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/PatientAppointment/GetPatientAppointment?fromDate=${fdate}&toDate=${tdate}&providerId=${providerId}`,
        {
          // &toDate=${fdate}
          headers,
        }
      )
      .then((response) => {
        response.data.forEach(item => {
          // Check if blockAppointment is true
          if (item.blockAppointment===true) {
              // If true, show a window alert
              setClrblockAppointment('ClrblockAppointment')
          }else{
            setClrblockAppointment('')

          }
      });
     
        var data = response.data;
        var arr = [];
        data.forEach((item) => {
          var assignData = {
            id: item.id,
            title: item.patientName,
            start: item.startTime,
            end: item.endTime,
            appointmentStatus: item.appointmentStatus,
            appointmentReason: item.appointmentReason,
            roomId: item.roomId,
            patientId: item.patientId,
            providerName: item.providerName,
            providerId: item.providerId,
          };
          arr.push(assignData);
        });
        setEvents(arr), setTotalAppointment(arr.length);
        var chIn = 0;
        var chOut = 0;
        var chScheduled = 0;
        data.forEach((item) => {
          if (item.appointmentStatus == "CheckedIn") {
            chIn = chIn + 1;
          } else if (item.appointmentStatus == "CheckedOut") {
            chOut = chOut + 1;
          } else {
            chScheduled = chScheduled + 1;
          }
        });
        setCheckeddIn(chIn);
        setCheckeddOut(chOut);
        setScheduledd(chScheduled);
        setSubmitting(false);
    
        // console.log('data::', response.data)
      })
      .catch((error) => {
        setSubmitting(false);
        console.error("There was an error!", error);
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [fromDate, lastDate, refresh , providerId]);

  const onEventClick = React.useCallback((event) => {
    // toast({
    //     message: event.event.title
    // });
    setOpen(true);
  }, []);

  const [calView, setCalView] = React.useState({
    schedule: {
      type: "day",
      days: false,
      size: 1,
      // startDay: dayOfWeekNumber,
      // endDay: dayOfWeekNumber,
      startTime: "09:00",
      endTime: "17:00",
      timeCellStep: 10,
      timeLabelStep: 10,
      allDay: false,
    },
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      applyRefresh();
      return;
    }

    setOpenAlert(false);
  };
  const handleOpen = (event) => {
    setOpenAlert(true);
    setIsOpen(false);
  };

  const [appointments, setAppointments] = React.useState(myEvents);
  const [isOpen, setIsOpen] = React.useState(false);
  const [anchor, setAnchor] = React.useState(null);
  const [currentEvent, setCurrentEvent] = React.useState(null);
  const [info, setInfo] = React.useState("");
  const [time, setTime] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [reason, setReason] = React.useState("");
  const [room, setRoom] = React.useState("");
  const [providerName, setproviderName] = React.useState("");
  const [buttonText, setButtonText] = React.useState("");
  const [buttonType, setButtonType] = React.useState("");
  const [bgColor, setBgColor] = React.useState("");
  const timerRef = React.useRef(null);
  const onEventHoverIn = React.useCallback((args) => {
    const event = args.event;
    setPData(event);
    // const resource = doctors.find(dr => dr.id === event.resource);
    const time =
      formatDate("hh:mm A", new Date(event.start)) +
      " - " +
      formatDate("hh:mm A", new Date(event.end));

    setCurrentEvent(event);

    if (event.appointmentStatus == "CheckedIn") {
      setStatus(event.appointmentStatus);
      setButtonText("Checked Out");
      setButtonType("warning");
    } else {
      setStatus(event.appointmentStatus);
      setButtonText("Checked In");
      setButtonType("success");
    }

    setBgColor("#309346");
    setInfo(event.title);
    setTime(time);
    setReason(event.appointmentReason);
    setRoom(event.roomId);
    setproviderName(event.providerName);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    setAnchor(args.domEvent.target);
    setIsOpen(true);
  }, []);
  const getCategory = (appointmentStatus) => {
    switch (appointmentStatus) {
      case "CheckedIn":
        return {
          color: "#64cad4",
        };
      case "CheckedOut":
        return {
          color: "#ff825d",
        };
      // case 3:
      //   return {
      //     color: '#7f9230',
      //   }
      // case 4:
      //   return {
      //     color: '#f14590',
      //   }
      // case 5:
      //   return {
      //     color: '#64cad4',
      //   }
      default:
        return {
          color: "#5ac8fa",
        };
    }
  };
  const alert = useAlert();
  const onEventHoverOut = React.useCallback(() => {
    timerRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 200);
  }, []);

  // const onEventClick = React.useCallback(() => {
  //   setIsOpen(true);
  // }, []);

  const onMouseEnter = React.useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, []);

  const onMouseLeave = React.useCallback(() => {
    timerRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 700);
  }, []);

  const validationSchema = Yup.object({});
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  });

  const setStatusButton = React.useCallback(() => {
    setIsOpen(false);
    const index = myEvents.findIndex((item) => item.id === currentEvent.id);
    const newApp = [...myEvents];
    // newApp[index].confirmed = !appointments[index].appointmentStatus
    // setAppointments(newApp)

    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/PatientAppointment/PatientAppointmentStatus?appoointmentId=${
          myEvents[index].id
        }&status=${
          myEvents[index].appointmentStatus == "CheckedIn"
            ? "CheckedOut"
            : "CheckedIn"
        }`,

        { headers }
      )
      .then((response) => {
        applyRefresh();
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });

    toast({
      message:
        myEvents[index].appointmentStatus == "CheckedIn"
          ? "CheckedOut"
          : "CheckedIn",
    });
  }, [myEvents, currentEvent]);
  const viewFile = React.useCallback((patientId) => {
    // console.log('id:', patientId)
    setIsOpen(false);
    history.push({
      pathname: `/patientsregistration`,
      state: {
        patientId: patientId,
        selectedTab: "selectedTab",
        readOnly: false,
      },
    });
    window.location.reload(false);
    // toast({
    //   message: 'View file',
    // })
  }, []);

  // const deleteApp = React.useCallback(() => {
  //   setAppointments(appointments.filter((item) => item.id !== currentEvent.id))
  //   setIsOpen(false)
  //   toast({
  //     message: 'Appointment deleted',
  //   })
  // }, [myEvents, currentEvent])
  const changeView = (event) => {
    let calView;
    console.log("dayOfWeekNumber is in changeView :", dayOfWeekNumber);
    switch (event.target.value) {
      case "month":
        calView = {
          calendar: { labels: true },
        };
        break;
      case "week":
        calView = {
          schedule: {
            type: "week",
            startDay: 0,
            endDay: 6,
            startTime: timeFrom,
            endTime: timeTo,
            timeCellStep: 10,
            timeLabelStep: 10,
            allDay: false,
          },
        };
        break;
      case "day":
        calView = {
          schedule: {
            type: "day",
            days: false,
            size: 1,
            // startDay: 1,
            // endDay: 6,
            startTime: timeFrom,
            endTime: timeTo,
            timeCellStep: 10,
            timeLabelStep: 10,
            allDay: false,
          },
        };
        break;
      case "agenda":
        calView = {
          calendar: { type: "week" },
          agenda: { type: "week" },
        };
        break;
    }
    if (event != null) {
      setView(event.target.value);
      setCalView(calView);
      console.log("call", event.target.value);
    }
    // setView(event.target.value)
    // setCalView(calView)
    // console.log('call', event.target.value)
  };

  const renderEventContent = React.useCallback((data) => {
    // console.log('dataaaa:', data.original.appointmentStatus)
    const cat = getCategory(data.original.appointmentStatus);
    //  setBackground(cat.color)
    return (
      <>
        <div
          className={classes.mdcustomeventcont}
          style={{ borderLeft: "5px solid blue" + cat.color ,width:ClrblockAppointment==='ClrblockAppointment'?'101%':'100%'}}
        >
          <div className={classes.mdcustomeventwrapper}>
            <div
              className={classes.mdcustomeventtitle}
              // style={{ background: cat.color }}
              style={{ background: ClrblockAppointment === 'ClrblockAppointment' ? 'yellow' : cat.color ,}}
            >
              {data.title}
            </div>
            <div className={classes.mdcustomeventfield}>
              {data.original.appointmentReason}
            </div>
            <div className={classes.mdcustomeventfield}>
              {data.original.appointmentStatus}
            </div>
            <div className={classes.mdcustomeventfield}>
              {data.original.providerName}
            </div>
            <div className={classes.mdcustomeventtime}>
              {data.start} - {data.end}
            </div>
          </div>
        </div>
      </>
    );
  });
  function datePickerhandlechange(newValue) {
    const today = new Date(newValue);
    today.setHours(9, 0, 0, 0);
    setFromDate(today);
 
  }

  const handleNextDayClick = () => {
    const nextDay = dayjs(fromDate).add(1, 'day').toDate();
    datePickerhandlechange(nextDay);
  };
 const handlePrevDayClick = () => {
    const prevDay = dayjs(fromDate).add(-1, 'day').toDate();
    datePickerhandlechange(prevDay);
  };
  const customWithNavButtons = () => {
    return (
      <React.Fragment>
        <CalendarPrev className="cal-header-prev" />
        <CalendarToday className="cal-header-today" />
        <CalendarNext className="cal-header-next" />
      </React.Fragment>
    );
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ marginTop: "10px" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputFormat="MM/DD/YYYY"
              value={dayjs(fromDate)}
              onChange={(e) => {
                console.log("Selected Date onChange :", e.$d);
                datePickerhandlechange(e.$d);
                // setnewFromDate(e.$d)
                setdayOfWeekNumber(e.day());
                const dayOfWeekNames = [
                  "Sunday",
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                ];
                setdayOFWeek(dayOfWeekNames[e.day()]);
                console.log("Day Number is :", e.day());

                setCalView({
                  schedule: {
                    type: "day",
                    days: false,
                    size: 1,
                    // startDay: e.day(),
                    // endDay: e.day(),
                    startTime: timeFrom,
                    endTime: timeTo,
                    timeCellStep: 10,
                    timeLabelStep: 10,
                    allDay: false,
                  },
                });
                // }}
              }}
              renderInput={(params) => (
                <TextField size="small" sx={{ width: 170 }} {...params} />
              )}
              sx={{ width: 160 }}
            />
          </LocalizationProvider>
        </div>
        {/* 000 */}
{/* <IconButton onClick={handlePrevDayClick}  aria-label="fingerprint" color="success">
  <ChevronLeftIcon />
</IconButton>
<IconButton  onClick={handleNextDayClick} aria-label="fingerprint" color="success">
  <ChevronRightIcon />
</IconButton> */}
        <div style={{ marginTop: "5px" }}>
          <FormControl sx={{ m: 1, minWidth: 20 }} size="small">
            {/* <InputLabel id="demo-select-small">Select</InputLabel> */}
            <Select
              value={view}
              onChange={changeView}
              placeholder="Day"
              label="Day"
              className="cal-header-picker"
            >
              <MenuItem value="day">Day</MenuItem>
              <MenuItem value="week">Week</MenuItem>
              <MenuItem value="month">Month</MenuItem>
              <MenuItem value="agenda">Agenda</MenuItem>
            </Select>
          </FormControl>
        </div>
        
        <div style={{ display: "flex", marginTop: "8px" }}>
          <label style={{ marginRight: "10px", marginTop: "10px" }}>
            Provider
          </label>

          <Grid style={{ width: "200px" }}>
            <Controller
              name="providerId"
              {...register("providerId")}
              control={control}
              render={({ field }) => (
                <ReactSelect
                  size="small"
                  {...field}
                  default
                  value={providerRedux.filter(function (option) {
                    return option.value == getValues("providerId");
                  })}
                  onChange={(value) => {
                    setValue("providerId", value != null ? value.value : "");
                    setproviderId(value.value);
                  }}
                  options={providerRedux}
                  styles={customStyles}
                />
              )}
              // render={({ field }) => (
              //   <ReactSelect
              //     size="small"
              //     {...field}
              //     value={providerRedux.find(option => option.value === providerId)}
              //     onChange={(value) => {
              //       setproviderId(value ? value.value : ""); 
              //       setValue("providerId", value ? value.value : "");
              //     }}
              //     options={providerRedux}
              //     styles={customStyles}
              //   />
              // )}
            />
          </Grid>
        </div>

        <div
          style={{
            background: "#FEE195",
            marginTop: "17px",
            paddingBottom: "7px",
            borderRadius: "20px",
            marginLeft: "2px",
            height: "20px",
            textAlign: "center",
            width: "120px",
            fontSize: "15px",
          }}
        >
          Total: {totalAppointment}
        </div>
        <div
          style={{
            background: "#64cad4",
            marginTop: "17px",
            paddingBottom: "7px",
            borderRadius: "20px",
            marginLeft: "2px",
            height: "20px",
            textAlign: "center",
            width: "120px",
            fontSize: "15px",
          }}
        >
          CheckedIn: {checkeddIn}
        </div>
        <div
          style={{
            background: "#ff825d",
            marginTop: "17px",
            paddingBottom: "7px",
            borderRadius: "20px",
            marginLeft: "2px",
            height: "20px",
            textAlign: "center",
            width: "120px",
            fontSize: "15px",
          }}
        >
          CheckedOut: {checkeddOut}
        </div>
        <div
          style={{
            background: "#5ac8fa",
            marginTop: "17px",
            paddingBottom: "7px",
            borderRadius: "20px",
            marginLeft: "2px",
            height: "20px",
            textAlign: "center",
            width: "120px",
            fontSize: "15px",
          }}
        >
          Scheduled: {scheduledd}
        </div>
      </div>
   
      <Modal
        open={openAlert}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <SuiTypography
            component="label"
            variant="caption"
            fontSize="20px"
            style={{ fontWeight: "bold" }}
          >
            Doing This Togehter
          </SuiTypography>
          <Grid container columnSpacing={1}>
            <Grid item xs={12}>
              <SuiTypography
                component="label"
                variant="caption"
                fontSize="15px"
                style={{ fontWeight: "normal" }}
              >
                Do you want to delete this appointment?
              </SuiTypography>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={12} sm={12}>
            <Button
              onClick={handleClose}
              variant="gradient"
              disabled={submitting}
              style={{
                marginTop: "30px",
                marginBottom: "10px",
                float: "right",
                marginLeft: "20px",
                width: "90px",
                height: "35px",
                backgroundColor: "#FCBE13",
                color: "black",
                fontSize: "14px",
              }}
              // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
            >
              <CancelOutlinedIcon
                fontSize="medium"
                style={{ color: "red", paddingRight: "5px" }}
              ></CancelOutlinedIcon>
              Cancel
            </Button>
            <Button
              onClick={() => {
                setSubmitting(true);
                axios
                  .post(
                    `${process.env.REACT_APP_API_URL}/PatientAppointment/AddPatientAppointment`,
                    {
                      id: pData.id,
                      patientId: pData.patientId,
                      patientName: pData.patientName,
                      appointmentDate: pData.start,
                      insuranceId: pData.insuranceId,
                      appointmentStatus: pData.appointmentStatus,
                      appointmentReason: pData.appointmentReason,
                      roomId: pData.roomId,
                      startTime: pData.startTime,
                      endTime: pData.endTime,
                      duration: pData.duration,
                      Deleted: "true",
                      practiceId: pData.practiceId,
                      createdDate: pData.createdDate,
                      updatedDate: pData.updatedDate,
                      createdBy: pData.createdBy,
                      updatedBy: pData.updatedBy,
                      clientCreatedDate: pData.clientCreatedDate,
                      clientUpdatedDate: pData.clientUpdatedDate,
                    },
                    {
                      headers,
                    }
                  )
                  .then((response) => {
                    setOpenAlert(false);
                    setSubmitting(false);
                    alert.success("Record Deleted successfully.", {
                      type: "success",
                      onClose: () => {
                        applyRefresh();
                      },
                    });
                  });
              }}
              variant="gradient"
              disabled={submitting}
              style={{
                marginTop: "30px",
                marginBottom: "10px",
                float: "right",
                marginLeft: "auto",
                width: "80px",
                height: "35px",
                backgroundColor: "#FCBE13",
                color: "black",
                fontSize: "14px",
              }}
              // color="warning"
              // size="small"

              // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
            >
              <DeleteOutlinedIcon
                fontSize="medium"
                style={{
                  color: "red",
                  paddingRight: "5px",
                  paddingBottom: "2px",
                }}
              ></DeleteOutlinedIcon>
              Yes
            </Button>
          </Grid>
        </Box>
      </Modal>
      <Modal open={open}>
        <Grid >
          <AppointmentCreate
            canclemodel={setOpen}
            timeFrom={timeFrom}
            timeTo={timeTo}
            // id={props.id}
            sTime={startTime}
            sendingId={assignId}
            eTime={endTime}
            refresh={applyRefresh}
            providerId={providerId}
            // patientnoteId={props.patientnoteid}
          ></AppointmentCreate>
        </Grid>
      </Modal>
      {submitting ? (
        <CircularProgress
          style={{
            width: "50px",
      marginTop:'7rem',

            position: "absolute",
            left: "50%",
       
            marginLeft: "-25px",
      
            // zIndex: 100000,
            color: "#FCBE13",
          }}
        />
      ) : (<>  
      <Eventcalendar
        selectedDate={fromDate}
        // selectedDate={newfromDate}
        renderHeader={customWithNavButtons}
        height={750}
        view={calView}
        // cssClass="md-switching-view-cont"
        theme="ios"
        themeVariant="light"
        clickToCreate={false}
        dragToCreate={true}
        dragToMove={true}
        dragToResize={true}
        eventDelete={true}
        data={myEvents}
        showEventTooltip={false}
        dragTimeStep={10}
        onEventHoverIn={onEventHoverIn}
        onEventHoverOut={onEventHoverOut}
        onPageChange={ViewChange}
        // onPageChange={() => {
        //   console.log('page______________________')
        // }}
        // onPageChange={
        //   view == 'week' || view == 'month' || view == 'day' ? ViewChange : ''
        // }
        // onCellRightClick={}
        renderScheduleEvent={renderEventContent}
        // view={view}
        // onPageLoaded={view=='week' || view=='month'? (PageLoad):('')}
        onCellDoubleClick={AppointmentCreateClick}
        onEventDoubleClick={AppointmentCreat}
        onEventClick={() => {
          setIsOpen(false);
        }}
        onEventDragEnd={AppointmentCreat}
        onSelectedDateChange={handleChange}
      />
               {ClrblockAppointment==='ClrblockAppointment'?'':(
      <Popup
        display="anchored"
        isOpen={isOpen}
        anchor={anchor}
        touchUi={false}
        showOverlay={false}
        contentPadding={false}
        closeOnOverlayClick={false}
        width={450}
        // style={{ fontSize: '15px', fontWeight: '600' }}
      >
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <div
            style={{
              backgroundColor: bgColor,
              padding: "12px  16px",
              color: "#eee",
              borderRadius: "3px",
            }}
          >
            <span>{info}</span>
            <span style={{ float: "right" }}>{time}</span>
          </div>
          <div
            style={{
              padding: "16px 16px 60px 16px",
              position: "relative",
              lineHeight: "32px",
            }}
          >
            <div style={{ marginBottom: "15px" }}>
              Status: <span style={{ fontWeight: "300" }}>{status}</span>
              <Button
                color={buttonType}
                variant="outline"
                style={{ float: "right" }}
                onClick={setStatusButton}
                disabled={status == "CheckedOut" ? true : false}
              >
                {buttonText}
              </Button>
            </div>
            <div style={{ marginBottom: "15px" }}>
              Reason for visit:{" "}
              <span style={{ fontWeight: "300" }}>{reason}</span>
            </div>
            <div style={{ marginBottom: "15px" }}>
              Provider:{" "}
              <span style={{ fontWeight: "300" }}>{providerName}</span>
              &nbsp;&nbsp;Room:{" "}
              <span style={{ fontWeight: "300" }}>{room}</span>
            </div>
            {/* <div style={{ marginBottom: '15px' }}>
              Provider: <span style={{ fontWeight: '300' }}>{providerName}</span>
            </div> */}
            <Button
              color="warning"
              style={{ position: "absolute", bottom: "16px", left: "16px" }}
              onClick={() => {
                viewFile(pData.patientId);
              }}
            >
              View Patient file
            </Button>
            <Button
              color="danger"
              variant="outline"
              style={{ position: "absolute", bottom: "16px", right: "16px" }}
              onClick={handleOpen}
              disabled={status == "CheckedOut" ? true : false}
            >
              Delete appointment
            </Button>
          </div>
        </div>
      </Popup>)}
      </>)}
    
    </>
  );
}

export default Scheduler;
