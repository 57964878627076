
import {
  CircularProgress,
  Grid,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import axios from 'axios'
import React, { useEffect, useReducer, useState, Fragment, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { TabPanel, TabContext, TabList } from '@mui/lab'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import { useAlert } from 'react-alert'
import { DataGrid } from '@mui/x-data-grid'
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import input from '@mui/material/Input'
import Moment from 'moment'
import Avatar from '@mui/material/Avatar'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'

const FamilyHistory = (props) => {
  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }

  const patientId=props.patientId
  const alert = useAlert()
  const validationSchema = Yup.object({
    icd: Yup.string().required("icd is required"),
      relationship: Yup.string().required("relationship is required"),

    });
  const fields = [
    "id",
    "patientId",
    "practiceId",
    "isActive",
    "icd",
    "relationship",
    "createdDate",
    "updatedDate",
    "createdBy",
    "updatedBy"
  ]
  const defValues = {
    id: 0,
    patientId: 0,
    practiceId: 0,
    isActive: '',
    icd:'',
    relationship:'',
    createdDate:'',
    updatedDate:'',
    createdBy:'',
    updatedBy:''
  }
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  });
  const [refresh, setRefresh] = React.useState(false)
  const [submitting, setSubmitting] = useState(false)

  const onSubmit = (data) => {   
    setSubmitting(true)

    const postData = {
      ...data,
      patientId:props.patientId
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientMedicalNotes/AddFamilystatus`,
        postData,
        { headers }
      )
      .then((response) => {
        setRefresh(!refresh)
        setSubmitting(false)

         alert.success('Record saved successfully.', {
          type: 'success',
          // onClose: () => {
          //   setOpenNote(false)
          //   setSubmitting(false)
          // },
        })
      })
      .catch((error) => {
        setSubmitting(false)
        alert.success(`Error ${error.message}`, {
          type: 'error',
        })
      })
  }
  useEffect(() => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions

    axios
      .get
      (`${process.env.REACT_APP_API_URL}/PatientMedicalNotes/getFamilystatusByPatientId?patientId=${props.patientId}`, { headers })
      .then(response => { 
        fields.forEach((field) => setValue(field, response.data[field]));
      })
      .catch(error => {
        console.error('There was an error!', error)
      })
  }, [])
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography style={{ fontSize: '15px' }}>
            ICD
          </Typography>
          <Controller
            name="icd"
            {...register("icd")}
            control={control}
            render={({ field }) => (
              <TextField
                // isClearable
                size="small"
                placeholder="enter icd "
                {...field}
                fullWidth
                error={errors.icd}
                />
              )}
            />
            <p style={{ color: 'red', fontSize: '14px' }}>{errors.icd?.message}</p>
        </Grid>
        <Grid item xs={6}>
          <Typography style={{ fontSize: '15px' }}>
            Relationship
          </Typography>
          <Controller
            name="relationship"
            {...register("relationship")}
            control={control}
            render={({ field }) => (
              <TextField
                // isClearable
                size="small"
                placeholder="enter relationship "
                {...field}
                fullWidth
                error={errors.relationship}
                />
              )}
            />
            <p style={{ color: 'red', fontSize: '14px' }}>{errors.relationship?.message}</p>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              onClick={handleSubmit(onSubmit)}
              variant="gradient"
              style={{
                marginTop: '10px',
                marginBottom: '10px',
                float: 'right',
                marginRight: '5px',
                marginLeft: 'auto',
                width: '80px',
                height: '35px',
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
              }}
            >
              {/* <SaveIcon
                        fontSize="medium"
                        style={{ color: '#0a9ff0', paddingRight: '5px' }}
                      ></SaveIcon> */}
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default FamilyHistory
