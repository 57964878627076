import axios from "axios";
import { FACILITIES, FACILITIES_ERROS } from "../types";

export const getFacilities = () => async (dispatch) => {
  const auth = `Bearer  ${localStorage.getItem("dtt_token")}`;
  const headers = {
    Authorization: auth,
  };
  try {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/Facilities/getFacilities`, {
      headers,
    });
    dispatch({
      type: FACILITIES,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: FACILITIES_ERROS,
      payload: console.log(e),
    });
  }

  return 1;
};
