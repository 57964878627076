import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import { Container, Grid, Typography, Box } from "@material-ui/core";
import Modal from "@mui/material/Modal";
import { createBrowserHistory } from "history";
import Stepper from "react-stepper-horizontal";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";

export default function GAD(props) {
  const [date, setDate] = useState(new Date(1598051730000));
  const [mode, setMode] = useState("date");
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [active, setActive] = useState(0);
  const [q1, setQ1] = useState(0);
  const [q2, setQ2] = useState(0);
  const [q3, setQ3] = useState(0);
  const [q4, setQ4] = useState(0);
  const [q5, setQ5] = useState(0);
  const [q6, setQ6] = useState(0);
  const [q7, setQ7] = useState(null);
  const [score, setScore] = useState(0);
  const [patientId, setpatientId] = useState(
    localStorage.getItem("dtt_PatientId")
  );

  const history = createBrowserHistory({ forceRefresh: true });
  // const navigation = useNavigate();
  const auth = `Bearer  ${localStorage.getItem("dtt_token")}`;
  const headers = {
    Authorization: auth,
  };
  function updateStep(step, newscore = 0) {
    // this.myStepper.setActive(5)
    onNextStep();
    // setActive(step)
    console.log("step: ", step);
    // onNextStep(step)
    if (step === 1) setQ1(newscore);
    if (step === 2) setQ2(newscore);
    if (step === 3) setQ3(newscore);
    if (step === 4) setQ4(newscore);
    if (step === 5) setQ5(newscore);
    if (step === 6) setQ6(newscore);
    if (step === 7) {
      setQ7(newscore);
    }
    setScore(score + newscore);
  }
  function previousStep(step, newscore = 0) {
    // this.myStepper.setActive(5)
    onPreviousStep();
    // setActive(step)
    if (step === 1) setQ1(newscore);
    if (step === 2) setQ2(newscore);
    if (step === 3) setQ3(newscore);
    if (step === 4) setQ4(newscore);
    if (step === 5) setQ5(newscore);
    if (step === 6) setQ6(newscore);
    if (step === 7) {
      setQ7(newscore);
    }
    setScore(score + newscore);
  }
  useEffect(() => {
    if (q7 != null)
      // console.log("useEffect ID: ", q9);
      saveGAD7Result();
  }, [q7]);
  async function saveGAD7Result() {
    // setActive(0)
    // const patientId = await AsyncStorage.getItem('dtt_user_id')
    // console.log('Patient ID: ', patientId)
    const postData = {
      q1: q1,
      q2: q2,
      q3: q3,
      q4: q4,
      q5: q5,
      q6: q6,
      q7: q7,
      totalScore: score,
      source: "Provider Portal",
      patientId: props.patientId,
    };

    console.log("PostData: ", postData);
    // console.log('axiosConfig: ', axiosConfig)

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientPortal/addPatientGAD7`,
        postData,
        {
          headers,
          },
      )
      .then((response) => {
        // console.log(score);
        // console.log("Response: ", response.data);
        // setActive(0)
        // setScore(0);
        // if (response.data) {
          // history.push("/Home");
          console.log("Score Saved Successfullly")
        
  props.getRespRefresh(response.data)
          props.onComplete();
        // }
      });
  }
  const progressStepsStyle = {
    completedStepIconColor: "#686868",
    completedProgressBarColor: "#686868",
    completedCheckColor: "#4bb543",
  };
  // defaultScrollViewProps = {
  //   keyboardShouldPersistTaps: 'handled',
  //   contentContainerStyle: {
  //     flex: 1,
  //     justifyContent: 'center',
  //   },
  // }
  const buttonTextStyle = {
    // color: '#FCBE13',
    fontWeight: "bold",
  };
  function onNextStep() {
    setActive(active + 1);
    console.log("called next step:", active);
  }
  function onPreviousStep() {
    setActive(active - 1);
    console.log("called Previous step:", active);
  }
  const onChange = (event, selectedDate) => {
    const currentDate = selectedDate || date;

    setDate(currentDate);
  };

  const showMode = (currentMode) => {
    setShow(true);
    setMode(currentMode);
  };

  const gridStyle = {
    marginBottom: "20px",
  };


  const showDatepicker = () => {
    showMode("date");
  };

  const showTimepicker = () => {
    showMode("time");
  };
  const initialStep = [
    { label: "Feeling nervous, anxious, or on edge." },
    { label: "Not being able to stop or control worrying." },
    { label: "Worrying too much about different things." },
    { label: "Trouble relaxing." },
    { label: "Being so restless that it is hard to sit still." },
    {
      label: "Becoming easily annoyed or irritable.",
    },
    {
      label: "Feeling afraid as if something awful might happen.",
    },
  ];
  const initialStepLabels = [
    { label: "" },
    { label: "" },
    { label: "" },
    { label: "" },
    { label: "" },
    { label: "" },
    { label: "" },
  ];


  const MyComponent = (props) => {
    // console.log("Props:", props);
    const { title} = props;

    return !props.title ? null : (
      <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="p" align="" gutterBottom style={{fontSize:15}}>
          {title.label}
        </Typography>
      </Grid>

      <Grid container style={gridStyle}>
        <Grid xs={4}></Grid>
        <Grid xs={4}>
          <Button
            variant="contained"
            // size="large"
            // width="100px"
            sx={{ bgcolor: "#FCBE13" }}
            onClick={() => updateStep(active + 1, 0)}
            fullWidth
          >
            Not at all
          </Button>
        </Grid>
        <Grid xs={4}></Grid>
      </Grid>

      <Grid container style={gridStyle}>
        <Grid xs={4}></Grid>
        <Grid xs={4}>
          <Button
            variant="contained"
            size="large"
            sx={{ bgcolor: "#FCBE13" }}
            onClick={() => updateStep(active + 1, 1)}
            fullWidth
          >
            Several days
          </Button>
        </Grid>
        <Grid xs={4}></Grid>
      </Grid>

      <Grid container style={gridStyle}>
        <Grid xs={4}></Grid>
        <Grid xs={4}>
          <Button
            variant="contained"
            size="large"
            sx={{ bgcolor: "#FCBE13" }}
            onClick={() => updateStep(active + 1, 2)}
            fullWidth
          >
            More than half the days
          </Button>
        </Grid>
        <Grid xs={4}></Grid>
      </Grid>

      <Grid container style={gridStyle}>
        <Grid xs={4}></Grid>
        <Grid xs={4}>
          <Button
            variant="contained"
            size="large"
            sx={{ bgcolor: "#FCBE13" }}
            onClick={() => updateStep(active + 1, 3)}
            fullWidth
          >
            Nearly every day
          </Button>
        </Grid>
        <Grid xs={4}></Grid>
      </Grid>

      <Grid container style={gridStyle}>
        <Grid xs={4}>
        {active > 0 ? (
          <Button 
          style={{backgroundColor:"skyblue", color:"black", marginLeft:"30px"}}
          onClick={() => previousStep(active - 1)}>
            <ReplyAllIcon style={{ marginBottom: "5px" , marginRight:"4px", color:'blue' }} /> Previous
          </Button>
        ) : (
          ""
         )} 
        </Grid>
        <Grid xs={8}></Grid>
      </Grid>
    </Grid>
    );
  };

  return (
    <Grid Container style={{ maxWidth: "100%" }}>
      <Grid xs={12} sm={12} md={12} lg={12}>
      {active < 7 ? (
        <Grid xs={12} sm={12} md={12} lg={12}>
          <Grid>
            <h1 style={{ textAlign: "center" }}>GAD7 </h1>
          </Grid>
          <Stepper
            steps={initialStepLabels}
            activeStep={active}
            activeColor={"#CB7723"}
            completeColor={"#2EA4D6"}
          />
          <MyComponent title={active < 8 ? initialStep[active] : null} />
        </Grid>
        ) : null }
      </Grid>
    </Grid>
  );
}
