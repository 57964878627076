/* eslint-disable object-shorthand */
// @mui material components
import { Grid } from '@mui/material'
import { createBrowserHistory } from 'history'
import React, { useState } from 'react'
import MedicalNotes from './MedicalNotes'
import PatientDiagnosis from './PatientDiagnosis'

// import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'

const history = createBrowserHistory({ forceRefresh: true })


function AddNotes(props) {
  const readOnly= props.readOnly
  // console.log("AddNote:",props)
  const [submitting, setSubmitting] = useState(false)

  return (
    <>
      {submitting && <div>Saving Form...</div>}
      <Grid
        container
        spacing={1}
        style={{
          paddingTop: '10px',
          paddingBottom: '30px',
          // margin:"dense",
         
        }}
      >
        <PatientDiagnosis patientId={props.patientId}
        patientnoteId={props.patientnoteid}
        readOnly={readOnly}
        />
        <MedicalNotes patientId={props.patientId}
        patientnoteId={props.patientnoteid}
        readOnly={readOnly}
        />

      
        
      </Grid>
    </>
  )
}

export default AddNotes
