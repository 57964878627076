import { combineReducers } from "redux";
import userReducer from "redux/reducer/userReducers";
import providerReducer from "redux/reducer/providerReducers";
import facilityReducer from "redux/reducer/facilityReducers";
import allProviderReducer from "redux/reducer/allProviderReducers";
import allUserReducer from "redux/reducer/allUserReducers";

export default combineReducers({
  users: userReducer,
  providers: providerReducer,
  facilities: facilityReducer,
  allProviders: allProviderReducer,
  allUsers: allUserReducer,
});
