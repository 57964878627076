import React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Controller, useForm } from 'react-hook-form'
import { Grid, Snackbar, TextField, Typography } from '@mui/material'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import  { useEffect, useState, } from 'react'
import { TabPanel, TabContext, TabList } from '@mui/lab'
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiButton from 'components/SuiButton'
import SuiAvatar from 'components/SuiAvatar'
import Button from '@mui/material/Button'
import axios from 'axios'
import moment from 'moment'
import SuiInput from 'components/SuiInput'
import ClearIcon from '@mui/icons-material/Clear'
const Smsemail = () => {

  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }
  const [value, updateValue] = React.useState(0)
  const [tabValue, settabValue] = React.useState(0)
  const [Erows, setErows] = useState('')
  const [Smsrows, setSmsrows] = useState('')
  const [refresh, setRefresh] = useState(false)

  const fields =[
    'id',
'fromEmail ',
'toEmail ',
'emailTime ',
'emailText ',
'inactive ',
'PracticeId',
'CreatedDate ',
'UpdatedDate ',
'CreatedBy ',
'UpdatedBy ',
'ClientCreatedDate',
'ClientUpdatedDate ',
'endDate',
'startDate',

  ]
  const defValues ={
id:0,
fromEmail:"" ,
toEmail:"" ,
emailTime:"" ,
emailText:"" ,
inactive:"" ,
PracticeId:"",
CreatedDate:"" ,
UpdatedDate:"" ,
CreatedBy:"" ,
UpdatedBy:"" ,
ClientCreatedDate:"",
ClientUpdatedDate:"", 
startDate:"",
endDate:"",

  }

  const Ecolumns = [
    {
      field: 'Id',
      headerName: 'ID',
      cellClassName: (params) => params.id,
      hide: false,
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.1,
      // colSpan: ({ row }) => {
      //   if (row.id == "SUBTOTAL") {
      //     return 8;
      //   }
      //   return undefined;
      // },
      // valueGetter: ({ value, row }) => {
      //   if (row.id == "SUBTOTAL") {
      //     return row.label;
      //   }
      //   return value;
      // },
    },
    {
      field: 'emailText',
      headerName: 'Email Text',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.4,
      renderCell: (params) => {
        const htmlContent = params.value;
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const content = doc.querySelector('.content p').outerHTML;
  
        return (
          <div
            dangerouslySetInnerHTML={{ __html: content }}
          />
        );
      }
    },
    {
      field: 'emailTime',
      headerName: 'Time',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'fromEmail',
      headerName: 'From Email',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },

    {
      field: 'toEmail',
      headerName: 'To Email',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.4,
    },
    {
      field: 'AppointmentDate',
      headerName: 'Appointment Date',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.4,
    },
    {
      field: 'CreatedBy',
      headerName: 'Created By',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.4,
    },
  ]

  const Scolumns = [
    {
      field: 'id',
      headerName: 'ID',
      cellClassName: (params) => params.id,
      hide: false,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.1,
      // colSpan: ({ row }) => {
      //   if (row.id == "SUBTOTAL") {
      //     return 8;
      //   }
      //   return undefined;
      // },
      // valueGetter: ({ value, row }) => {
      //   if (row.id == "SUBTOTAL") {
      //     return row.label;
      //   }
      //   return value;
      // },
    },
    {
      field: 'message',
      headerName: 'SMS Text',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.4,

        
    },
    {
      field: 'providerName',
      headerName: 'Provider',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },
    {
      field: 'messageFrom',
      headerName: 'From SMS',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
    },

    {
      field: 'messageTo',
      headerName: 'To SMS',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.4,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 0.4,
    },
  ]

  const handleChange = (event, newValue) => {
    settabValue(newValue)
    console.log(newValue, "Tab Value ")
  }

  useEffect(() => {

    axios
      .get(`${process.env.REACT_APP_API_URL}/patients/getEmailLog`, { headers })
      .then((response) => {
 console.log('11111111111',response.data)
        setErows(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [refresh]);

  useEffect(() => {

    axios
      .get(`${process.env.REACT_APP_API_URL}/patients/GetMessageLog`, { headers })
      .then((response) => {
 
        setSmsrows(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [refresh]);
  const [openNote, setopenNote] = useState(true)
  const [isLoading, setLoading] = useState('')
  const [StartDate, setStartDate] = useState('')
  const [EndDate, setEndDate] = useState('')
  const [efilter, setefilter] = useState('')
  const [noRecordsFound, setNoRecordsFound] = useState(false)
  const SDate = StartDate ? moment(StartDate).format('MM-DD-YYYY') : ''
  const EDate = EndDate ? moment(EndDate).format('MM-DD-YYYY') : ''




  const searchEmail = () => {
    axios
     .get(
       `${process.env.REACT_APP_API_URL}/Patients/filterEmailMessage?IsMsg=false&startDate=${SDate}&endDate=${EDate}`,
       { headers }
     )
     .then(response => {
       console.log('search ky response ma keya ata hy ', response.data)
       setErows(response.data)
       setLoading(false)
       if (response.data === null || response.data.length === 0) {
        setNoRecordsFound(true);
      } else {
        setNoRecordsFound(false);
      }
    })
    .catch((error) => {
      console.error('There was an error!', error);
      setNoRecordsFound(true);
    });
    axios
    .get(
      `${process.env.REACT_APP_API_URL}/Patients/filterEmailMessage?IsMsg=true&startDate=${SDate}&endDate=${EDate}`,
      { headers }
    )
    .then(response => {
      console.log('search ky response ma keya ata hy ', response.data)
      setSmsrows(response.data)
      setLoading(false)
      if (response.data === null || response.data.length === 0) {
       setNoRecordsFound(true);
     } else {
       setNoRecordsFound(false);
     }
   })
   .catch((error) => {
     console.error('There was an error!', error);
     setNoRecordsFound(true);
   });
    
 }

//  const searchMsg = () => {
//   axios
//    .get(
//      `${process.env.REACT_APP_API_URL}/Patients/filterEmailMessage?IsMsg=true&startDate=${SDate}&endDate=${EDate}`,
//      { headers }
//    )
//    .then(response => {
//      console.log('search ky response ma keya ata hy ', response.data)
//      setSmsrows(response.data)
//      setLoading(false)
//      if (response.data === null || response.data.length === 0) {
//       setNoRecordsFound(true);
//     } else {
//       setNoRecordsFound(false);
//     }
//   })
//   .catch((error) => {
//     console.error('There was an error!', error);
//     setNoRecordsFound(true);
//   });
    
  
// }

  

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    // borderRadius: 5,
    background: 'white',
    textTransform: 'none !important',
    fontSize: '13.6px !important',
    color: '#8392ab !important',
    borderColor: 'rgba(5, 152, 236, 0.637) !important',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#FFFFC2',
      borderRadius: 1.8,
    },
    '& .SUBTOTAL': {
      backgroundColor: 'rgba(200, 150, 255, 0.49)',
      color: 'black !important',
      border: '1px solid black !important'
    },
  }

  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
  })
  return (
    <>
  <Box mb={2}>
      <Grid
        container
        spacing={1}
        item
        xs={12}
        sm={12}
        xl={12}
        style={{
          width: '100%',
          marginBottom: '10px',
          border: '1px solid rgba(5, 152, 236, 0.637)',
          borderRadius: '20px',
          marginTop: '10px',
          paddingBottom: '20px',
          marginLeft: '1px',
          padding: '15px',
          // paddingLeft: '15px',
          backgroundColor: 'white'
        }}
      >

       
        <Grid item xs={6} sm={6} xl={6}>
          <Typography style={{ fontSize: '14px' }}>Start Date</Typography>
        </Grid>
        <Grid item xs={6} sm={6} xl={6}>
          <Typography style={{ fontSize: '14px' }}>End Date</Typography>
        </Grid>

        <Grid item xs={6} sm={6} xl={6}>
          <SuiInput
            type="date"
            placeholder="Enter startDate"
            name="startDate"
            value={StartDate}
            onChange={(e) => {
              setValue('startDate', e.target.value)
              setStartDate(e.target.value)
            }}
            error={errors.startDate}
          />
          {/* <p style={{ color: 'red', fontSize: '14px' }}>
            {StartDateErr}
          </p> */}
        </Grid>

        <Grid item xs={6} sm={6} xl={6}>
          <SuiInput
            type="date"
            placeholder="Enter startDate"
            name="endDate"
            value={EndDate}
            onChange={(e) => {
              setValue('endDate', e.target.value)
              setEndDate(e.target.value)
            }}
            error={errors.endDate}
          />
           {/* <p style={{ color: 'red', fontSize: '14px' }}>
            {EndDateErr}
          </p> */}
        </Grid>
        <Grid item xs={9}></Grid>
        <Grid item xs={1.5}>
            <Button
              sx={{ mb: 2 }}
              onClick={() => {
            
                setStartDate('')
                setEndDate('')
                setRefresh(!refresh)
              }}
              variant='gradient'
              size='small'
              style={{
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
                // float: 'right',
                // marginRight: '20px',
                marginTop: '20px'
              }}
            >
              Clear
            </Button>
          </Grid>
        <Grid item xs={1.5}>
            <Button
              sx={{ mb: 2 }}
              onClick={searchEmail}
              // disabled={submitting}
              variant='gradient'
              size='small'
              style={{
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
                // float: 'right',
                // marginRight: '20px',
                marginTop: '20px'
              }}
            >
              Search
            </Button>
          </Grid>
          

    


        </Grid>

       

        </Box>  


    <Grid
        container
        spacing={1}
        style={{
          paddingTop: '10px',
          paddingLeft: '10px',
          paddingRight: '50px',
        }}
      >
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab value={0} label="E-MAIL" />
              <Tab value={1} label="SMS" />
            </TabList>

            <TabPanel value={0}>

              <Grid xs={12} xl={12} spacing={1} alignContent="left" justifyContent="left">
              </Grid>


              <Grid item xs={12} >
                {/* {submitting ? ("Please Wait! Your Data is Being Loaded...") : ( */}
                <SuiBox mb={3}>
                  <div style={{ height: 353, width: '100%' }}>
                    {/* {Erows === null || Erows.length === 0 ?  ("Please Apply Filter") : ( */}
                      <DataGrid
                        rows={Erows}
                        // rows={efilter}
                        columns={Ecolumns}
                        getRowId={(Erows) => (Erows != null ? Erows.Id : 0)}
                        headerHeight={37}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        sx={gridRowStyle}
                      />
                    {/* )} */}
                  </div>
                </SuiBox>
                {/* )} */}

              </Grid>


            </TabPanel>

            <TabPanel value={1}>
              <Grid spacing={1} alignContent="left" justifyContent="left" style={{
                width: '100% !important',
                marginLeft: '0px !important'
              }}>
              </Grid>
              <Grid item xs={12} >
                <SuiBox mb={3}>
                  <div style={{ height: 353, width: '100%' }}>
                    {/* {Smsrows === null || Smsrows.length === 0 ? ("Please Apply Filter") : ( */}
                      <DataGrid
                        rows={Smsrows}
                        columns={Scolumns}
                        getRowId={(Smsrows) => (Smsrows != null ? Smsrows.Id : 0)}
                        headerHeight={37}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        sx={gridRowStyle}
                      />
                    {/* )} */}
                  </div>
                </SuiBox>
              </Grid>
            </TabPanel>
          </Box>
        </TabContext>
      </Grid>
    </>
  )
}

export default Smsemail
