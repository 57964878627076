// ** React Imports
import React from 'react'
import axios from 'axios'
import { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { Controller, useForm } from 'react-hook-form'
import ReactSelect from 'react-select'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SuiSelect from 'components/SuiSelect'
import SuiInput from 'components/SuiInput'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Chart } from 'react-google-charts';

import icdsList from 'Data/icdsList'
import CircularProgress from "@mui/material/CircularProgress";



var providersRedux = []
var facilitiesRedux = []
var IntervalRedux = []
const PatientFilters = (props) => {
  const token = localStorage.getItem('dtt_token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }

  const check = createFilterOptions()
  const [filter, setfilter] = useState([])
  const [filterProviderId, setFilterProviderId] = useState('')
  const [filterFacilityId, setFilterFacilityId] = useState("")
  const [startDate, setStartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [TabValue, settabValue] = useState(props.tabValue)
  const [submitting, setsubmitting] = useState(false)
  const [icdfilteredList, seticdfilteredList] = React.useState([])
  const [isZipCode, setIsZipCode] = React.useState(false)
  const [code, setCode] = React.useState('');
  const [_providerId, setProviderId] = React.useState('');
  const [groupBy , setGroupValue] = React.useState('');
  const [Intervalmsgvale , setIntervalmsgvale] = React.useState('');
  const [Intervalmsg , setIntervalmsg] = React.useState('');
  const [sendWelcomeEmail, setsendWelcomeEmail] = React.useState(null)
  const [isChecked, setIsChecked] = useState(false);
  console.log(isChecked ,'isChecked,,, ')

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      setsendWelcomeEmail(true)
    } else {
      setsendWelcomeEmail(false)
    }
  };
  const [msggroupBy , setMSggroupBy] = React.useState('');

console.log(Intervalmsgvale ,'groupBy,,, ')
  const zipCodeListStyle = {
    cursor: 'pointer',
    '&:hover': {
      background: '#3b3b3b !important',
      color: 'rgba(5, 152, 236, 0.637) !important',
    },
  }
  const onChangeHandle = async (value) => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    // console.log(value)
    const arr = icdsList.filter((item) => {
      return item.value.toLowerCase().includes(value)
    })
   
    seticdfilteredList(arr)
    setIsZipCode(true)

  }
  
  const handleClick = event => {
    // 👇️ toggle shown state
    var newfilter = filter
    var id = filter.length + 1
    var boj = {
      id: id,
      columns: '',
      operator: '',
      value: '',
      value2: '',
      type: '',
      dualField: '',
      filter: []
    }
    newfilter.push(boj)
    setfilter([...newfilter])
    // setUpdated(id)
    // console.log('Testfilter', newfilter)
  }

  const searchPatients = () => {
    props.getgroupBy(groupBy)
if(!groupBy){
setMSggroupBy('Group By is required')
  return
}
setMSggroupBy('')

if(!Intervalmsgvale){
  setIntervalmsg('Interval is required')
    return
  }
  setIntervalmsg('')

    setsubmitting(true);
    var filterArr = [];
    filter.forEach(f => {
      if (f.columns && f.operator && (f.value || f.value2)) {
        var obj = {
          field: f.columns,
          operatorName: f.operator,
          operatorType: f.type,
          value: f.value,
          value2: f.value2
        };
        filterArr.push(obj);
      }
    });
  
    axios.post(`${process.env.REACT_APP_API_URL}/Reports/GetPatientPHQ9Report?fromDate=${startDate}&toDate=${endDate}&diagnosis=${code}&providerid=${_providerId}&groupBy=${groupBy}&consistentPatients=${isChecked}`, filterArr, { headers })
      .then(response => {
        console.error("There is a response1:", response.data);
        props.getData(response.data);
      if(response.data.length===0 || response.data===undefined){
        props.getErrorMsgPhq9('No Record Found')

        setsubmitting(false);

      }else{
      props.getData(response.data);

        setsubmitting(false);}
      })
      .catch(error => {
        console.error('There was an error!', error);
        setsubmitting(false);
      });
      axios.post(`${process.env.REACT_APP_API_URL}/Reports/GetPatientGAD7Report?fromDate=${startDate}&toDate=${endDate}&diagnosis=${code}&providerid=${_providerId}&groupBy=${groupBy}&consistentPatients=${isChecked}`, filterArr, { headers })
      .then(response => {
        console.error("There is a response1:", response.data);
        props.getDataGAD7(response.data);
        if(response.data.length===0 || response.data===undefined){
          props.getErrorMsgPhq9('No Record Found')
  
          setsubmitting(false);
  
        }else{
          props.getDataGAD7(response.data);
  
          setsubmitting(false);}
  
        setsubmitting(false);
      })
      .catch(error => {
        console.error('There was an error!', error);
        setsubmitting(false);
      });
  }
  
  


  const whereArray = [

    {
      title: 'First Name', value: 'firstName', type: 'string', filter: [
        { value: 'equal', label: 'Equal' },
        { value: 'notEqual', label: 'Not Equal' },
        { value: 'contains', label: 'Contains' },
        { value: 'notContains', label: 'Not Contains' },
      ]
    },
    {
      title: 'Last Name', value: 'lastName', type: 'string', filter: [
        { value: 'equal', label: 'Equal' },
        { value: 'notEqual', label: 'Not Equal' },
        { value: 'contains', label: 'Contains' },
        { value: 'notContains', label: 'Not Contains' },
      ]
    },
    
    {
      title: 'DOB', value: 'dOB', type: 'date', filter: [
        { value: 'equal', label: 'Equal' },
        { value: 'notEqual', label: 'Not Equal' },
        { value: 'lessThan', label: 'Less Than' },
        { value: 'lessThanEqualTo', label: 'Less Than and Equal to' },
        { value: 'greaterThan', label: 'Greater Than' },
     
        { value: 'greaterThanEqualTo', label: 'Greater Than and Equal to' },
        { value: 'dateBetween', label: 'Between' },
      ]
    },
    {
      title: 'Age',
      value: 'age',
      type: 'string',
      filter: [
        { value: 'equal', label: 'Equal' },
        { value: 'notEqual', label: 'Not Equal' },
        { value: 'lessThan', label: 'Less Than' },
        { value: 'lessThanEqualTo', label: 'Less Than and Equal to' },
        { value: 'greaterThan', label: 'Greater Than' },
    
        { value: 'greaterThanEqualTo', label: 'Greater Than and Equal to' },
        { value: 'between', label: 'Between' },
      ]
    }
    ,
    {
      title: 'Gender', value: 'gender', type: 'string', filter: [
        { value: 'equal', label: 'Equal' },
        { value: 'notEqual', label: 'Not Equal' }
      ]
    },
    {
      title: 'Patient Registration', value: 'createdDate', type: 'date', filter: [
        { value: 'equal', label: 'Equal' },
        { value: 'notEqual', label: 'Not Equal' },
        { value: 'lessThan', label: 'Less Than' },
        { value: 'lessThanEqualTo', label: 'Less Than and Equal to' },
        { value: 'greaterThan', label: 'Greater Than' },
    
        { value: 'greaterThanEqualTo', label: 'Greater Than and Equal to' },
        { value: 'dateBetween', label: 'Between' },
      ]
    },
    // {
    //   title: 'Disease', value: 'd.code', type: 'string', filter: [
    //     { value: 'equal', label: 'Equal' },
    //     { value: 'notEqual', label: 'Not Equal' },
    //     { value: 'contains', label: 'Contains' },
    //     { value: 'notContains', label: 'Not Contains' },
    //   ]
    // },
    {
      title: 'Avg Score', value: 'Avg Score', type: 'string', filter: [
        { value: 'equal', label: 'Equal' },
        { value: 'notEqual', label: 'Not Equal' },
         { value: 'lessThan', label: 'Less Than' },
        { value: 'lessThanEqualTo', label: 'Less Than and Equal to' },
        { value: 'greaterThan', label: 'Greater Than' },
        { value: 'greaterThanEqualTo', label: 'Greater Than and Equal to' },
        { value: 'between', label: 'Between' },
      ]
    },
    {
      title: 'PHQ9 Start Score', value: 'PHQ9StartScore', type: 'string', filter: [
        { value: 'equal', label: 'Equal' },
        { value: 'notEqual', label: 'Not Equal' },
         { value: 'lessThan', label: 'Less Than' },
        { value: 'lessThanEqualTo', label: 'Less Than and Equal to' },
        { value: 'greaterThan', label: 'Greater Than' },
        { value: 'greaterThanEqualTo', label: 'Greater Than and Equal to' },
        { value: 'between', label: 'Between' },
      ]
    },
    {
      title: 'Min Score', value: 'Min Score', type: 'string', filter: [
        { value: 'equal', label: 'Equal' },
        { value: 'notEqual', label: 'Not Equal' },
        { value: 'lessThan', label: 'Less Than' },
        { value: 'lessThanEqualTo', label: 'Less Than and Equal to' },
        { value: 'greaterThan', label: 'Greater Than' },

        { value: 'greaterThanEqualTo', label: 'Greater Than and Equal to' },
        { value: 'between', label: 'Between' },
      ]
    },
    {
      title: 'Max Score', value: 'Max Score', type: 'string', filter: [
        { value: 'equal', label: 'Equal' },
        { value: 'notEqual', label: 'Not Equal' },
        { value: 'lessThan', label: 'Less Than' },
        { value: 'greaterThan', label: 'Greater Than' },
        { value: 'lessThanEqualTo', label: 'Less Than and Equal to' },
        { value: 'greaterThanEqualTo', label: 'Greater Than and Equal to' },
        { value: 'between', label: 'Between' },
      ]
    },
    {
      title: 'Program', value: 'program', type: 'string', filter: [
        { value: 'equal', label: 'Equal' },
        { value: 'notEqual', label: 'Not Equal' },
        { value: 'contains', label: 'Contains' },
        { value: 'notContains', label: 'Not Contains' },
      ]
    }

  ]

  const validationSchema = Yup.object({

    startDate: Yup.date()
      .required('startDate is required')
      .nullable(),
    endDate: Yup.date()
      .required('endDate is required')
      .nullable()
  })
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    // delayError: undefined,
    resolver: yupResolver(validationSchema)
  })

  const facilitiesRedx = useSelector(
    (state) => (state.facilities.loading ? [] : state.facilities.NAMES),
    shallowEqual
  )
  const providersRedx = useSelector(
    (state) => (state.providers.loading ? [] : state.providers.NAMES),
    shallowEqual
  )

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    facilitiesRedux = []
    facilitiesRedx.forEach((field) => {
      facilitiesRedux.push({
        label: field.name,
        value: field.id,
      })
    })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [facilitiesRedx])

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    providersRedux = []
    providersRedx.forEach((field) => {
      providersRedux.push({
        label: field.name,
        value: field.id,
      })
    })
  }, [providersRedx])
  const CheckGraph = (isChecked) => {
    if (isChecked) {
      props.getGraphCheckbox('true');
    } else {
      props.getGraphCheckbox('false');
    }
  };

  return (
    <Box mb={2}>
      <Grid
        container
        spacing={1}
        item
        xs={12}
        sm={12}
        xl={12}
        style={{
          width: '100%',
          marginBottom: '10px',
          border: '1px solid rgba(5, 152, 236, 0.637)',
          borderRadius: '20px',
          marginTop: '10px',
          paddingBottom: '20px',
          marginLeft: '1px',
          padding: '15px',
          // paddingLeft: '15px',
          backgroundColor: 'white'
        }}
      >

        <Grid item xs={6} sm={3} xl={3}>
          <Typography style={{ fontSize: '14px' }}>Providers</Typography>
        </Grid>
        {/* <Grid item xs={6} sm={2} xl={2}>
          <Typography style={{ fontSize: '14px' }}>Facility</Typography>
        </Grid> */}
        <Grid item xs={6} sm={3} xl={3}>
          <Typography style={{ fontSize: '14px' }}>Interval</Typography>
        </Grid>
        <Grid item xs={6} sm={3} xl={3}>
          <Typography style={{ fontSize: '14px' }}>Start Date</Typography>
        </Grid>
        <Grid item xs={6} sm={3} xl={3}>
          <Typography style={{ fontSize: '14px' }}>End Date</Typography>
        </Grid>
   
        

        {/* <Grid item xs={6} sm={3} xl={3}>
          <SuiSelect
            isClearable
            default
            value={filterProviderId}
            onChange={(value) => {
              setFilterProviderId(value.label)
              console.log("setFilterProviderId", value)
            }}
            options={providersRedux}
          />
        </Grid> */}
{/* 111 */}
        <Grid item xs={3} style={{ width: '300px' ,zIndex:1000}}>
        <Controller
  name="providerId"
  {...register('providerId')}
  control={control}
  render={({ field }) => (
    <SuiSelect
      isClearable
      {...field}
      value={providersRedux.find((option) => option.value === getValues('providerId'))}
      onChange={(value) => {
        setValue('providerId', value.value);
        setProviderId(value.value);
      }}
      options={providersRedux}
    />
  )}
/>

          <p style={{ color: 'red', fontSize: '14px' }}>
            {errors.providerId?.message}
          </p>
        </Grid>

        {/* <Grid item xs={2} style={{ width: '300px' }}>
          <Controller
            name="facilityId"
            {...register('facilityId')}
            control={control}
            render={({ field }) => (
              <SuiSelect
                placeholder="Select Facilities"
                isClearable
                {...field}
                value={facilitiesRedux.filter(function (option) {
                  return option.value === getValues('facilityId')

                })}
                options={facilitiesRedux}
                onChange={(value) => {
                  setValue('facilityId', value.value)
                }}
              />
            )}
          />
        </Grid> */}
        <Grid item xs={3} style={{ width: '300px' }}>
  
          <Controller
  name="IntervalId"
  {...register('IntervalId')}
  control={control}
  render={({ field }) => {
    const options = [
      { label: '1 month', value: 1 },
      { label: '3 months', value: 3 },
      { label: '6 months', value: 6 },
      { label: '12 months', value: 12 },
    ];

    return (
      <>
        <SuiSelect
          placeholder="Select Month"
          isClearable
          {...field}
          value={options.find(option => option.value === getValues('IntervalId'))}
          options={options}
          onChange={(value) => {
            setValue('Month', value?.value);
            setIntervalmsgvale(value?.value)

            const selectedMonth = value?.value;

            // Calculate new start date based on selected month
            if (selectedMonth) {
              const currentDate = new Date();
              const newStartDate = new Date(currentDate);
              newStartDate.setMonth(currentDate.getMonth() - selectedMonth);
              const formattedStartDate = newStartDate.toISOString().split('T')[0];
              setValue('startDate', formattedStartDate);
              setStartDate(formattedStartDate);

              // Calculate new end date based on selected month
              const formattedEndDate = currentDate.toISOString().split('T')[0];
              setValue('endDate', formattedEndDate);
              setendDate(formattedEndDate);
            }
          }}
        />
       
      </>
    );
  }}
/>
{<span style={{ color: 'red' }}>{Intervalmsg}</span>}

        </Grid>
        {/* <Grid item xs={6} sm={3} xl={3}>
          <SuiSelect
            isClearable
            default
            value={filterFacilityId}
            onChange={(value) => {
              setFilterFacilityId(value.value)
            }}
            options={facilitiesRedux}
          />
        </Grid> */}

        <Grid item xs={6} sm={3} xl={3}>
        <SuiInput
          type="date"
          placeholder="Enter startDate"
          value={startDate}
          onChange={(e) => {
            setValue('startDate', e.target.value);
            setStartDate(e.target.value);
          }}
   
        />
          <p style={{ color: 'red', fontSize: '14px' }}>
            {errors.startDate?.message}
          </p>
        </Grid>

        <Grid item xs={6} sm={3} xl={3}>
        <SuiInput
          onChange={(e) => setendDate(e.target.value)}
          placeholder="Please enter End Date"
          type="date"
          name="endDate"
          label="End Date"
          fullWidth
          margin="dense"
          value={endDate}
        />
        </Grid>

      
<Grid container spacing={2}>
<Grid item xs={6} >   <Grid item xs={4} sm={4} xl={4}>
        <Typography style={{ fontSize: '14px' }}>Disease</Typography>
      </Grid>
</Grid>
<Grid item xs={6} >   <Grid item xs={4} sm={4} xl={4}>
        <Typography style={{ fontSize: '14px' }}>Group By</Typography>
      </Grid>
</Grid>
<Grid item xs={6} style={{marginTop:'-1rem'}} >    <Autocomplete
  {...register('code')}
  options={icdsList}
  getOptionLabel={(option) => `${option.value} - ${option.label}`}
  onChange={(event, value) => {
    if (value) {
      setValue('code', value.value);
      setValue('description', value.label);
      setCode(value.value);
    }
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="Please enter ICD"
      name="code"
      isClearable
      fullWidth
      margin="dense"
      error={errors.code}
    />
  )}
  onKeyUp={(ev) => {
    if (ev.target.value !== '' && ev.target.value !== null) {
      onChangeHandle(ev.target.value);
    }
  }}
/>
</Grid>
<Grid item xs={6} style={{marginTop:'-0.6rem'}}>  <Controller
 name="GroupBy"
 {...register('GroupBy')}
 control={control}
 render={({ field }) => {
    const options = [
      { label: 'Patient', value: 'Patient' },
      { label: 'Month', value: 'Month' },
      { label: 'Week', value: 'Week' },

    ];

    return (
      <>
        <SuiSelect
          placeholder="Group By"
          isClearable
          {...field}
          value={options.find(option => option.value === getValues('GroupBy'))}
          options={options}
          onChange={(value) => {
            setValue('Month', value?.value);
            setGroupValue(value?.value)
          
            console.log("Selected Value: ", value?.value); // Logs the selected value to the console
          }}
        />
      </>
    );
 }}
/>
{<span style={{ color: 'red' }}>{msggroupBy}</span>}

{submitting ? (
      <CircularProgress
        style={{
          width: "50px",
    marginTop:'1rem',

          position: "absolute",
          left: "50%",
     
          marginLeft: "-25px",
    
          // zIndex: 100000,
          color: "#FCBE13",
        }}
      />
    ) : (<>  </>)}
</Grid>
  {/* <Grid item xs={12} sm={12} md={12} lg={12}>
    <Box display="flex" flexDirection="column" width="100%">
      <Grid item xs={4} sm={4} xl={4}>
        <Typography style={{ fontSize: '14px' }}>Disease</Typography>
      </Grid>

      <Grid item xs={4} sm={4} md={4} lg={4}>
      <Autocomplete
  {...register('code')}
  options={icdsList}
  getOptionLabel={(option) => `${option.value} - ${option.label}`}
  onChange={(event, value) => {
    if (value) {
      setValue('code', value.value);
      setValue('description', value.label);
      setCode(value.value);
    }
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="Please enter ICD"
      name="code"
      isClearable
      fullWidth
      margin="dense"
      error={errors.code}
    />
  )}
  onKeyUp={(ev) => {
    if (ev.target.value !== '' && ev.target.value !== null) {
      onChangeHandle(ev.target.value);
    }
  }}
/>



      </Grid>
      <Grid item xs={4} sm={4} xl={4}>
        <Typography style={{ fontSize: '14px' }}>Group By</Typography>
      </Grid>

      <Grid item xs={4} sm={4} md={4} lg={4}>
    



      </Grid>
    </Box>{submitting ? (
      <CircularProgress
        style={{
          width: "50px",
    marginTop:'1rem',

          position: "absolute",
          left: "50%",
     
          marginLeft: "-25px",
    
          // zIndex: 100000,
          color: "#FCBE13",
        }}
      />
    ) : (<>  </>)}
  </Grid> */}

 {/* 1111 */}
  {/* <Grid item xs={12} sm={6} md={8} lg={8}>
    <Box display="flex" flexDirection="column" width="100%">
      <Grid item xs={12} sm={6} xl={6}>
        <Typography style={{ fontSize: '14px' }}>Description</Typography>
      </Grid>
       
      <Grid item xs={12} sm={6} md={6} lg={6}>
      <SuiInput
  type="textarea"
  {...register('description')}
  placeholder="Please enter Description"
  name="description"
  label="description"
  fullWidth
  margin="dense"
/>
      </Grid>
    </Box>
  </Grid> */}
</Grid>






        <Grid item xs={12} sm={12} xl={12}>
          <Grid item xs={12} sm={12} xl={12}>
            <Typography style={{ fontSize: '20px', fontWeight: 'bold' }}>Filters</Typography>
          </Grid>

          {filter.map((row, index) => {
            console.log('mapping filters', row)
            return (
              <Grid container spacing={2} item xs={12} sm={12} xl={12}>
                <Grid item xs={1} sm={1} xl={1}>
                  <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginTop: '8px' }}>Where</Typography>
                </Grid>
                <Grid item xs={3} sm={3} xl={3}>
                  <Autocomplete
                    onChange={(event, newValue) => {
                      row.columns = newValue.value
                      row.filter = newValue.filter
                      row.type = newValue.type
                      filter[index] = row;
                      setfilter([...filter])
                    }}
                    filterOptions={(options, params) => {
                      const filtered = check(options, params)
                      const { inputValue } = params
                      return filtered
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id='free-solo-with-text-demo'
                    options={whereArray}
                    getOptionLabel={option => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue
                      }
                      // Regular option
                      return option.title
                    }}
                    renderOption={(props, option) => <li {...props}>{option.title}</li>}
                    freeSolo
                    renderInput={params => <TextField {...params} size='medium' />}
                  />

                </Grid>
                {/* 00000 */}
                {row.dualField ? (
  <Grid item xs={2} sm={2} xl={2}>
    <SuiSelect menuPortalTarget={document.body}
    style={{ zIndex: '-1'}}
      onChange={(e, v) => {
        row.dualField = e.value === 'between'; // Simplified condition
        row.operator = e.value;
        filter[index] = row;
        setfilter([...filter]);
      }}
      options={row.filter}
    />
  </Grid>
) : (
  <Grid item xs={3} sm={3} xl={3}>
 <SuiSelect
  menuPortalTarget={document.body}
  style={{ zIndex: '-1'}}
  onChange={(e, v) => {
    // Assuming e.value and v are the selected values you want to store
    row.dualField = e.value === 'between'; // Simplified condition
    row.operator = e.value;
    row.selectedData = v; // Store the selected data in the row object
    filter[index] = row;
    setfilter([...filter]);
  }}
  options={row.filter}
/>

  </Grid>
)}
  
                {row.dualField ? (
                  <Grid item xs={2} sm={2} xl={2}>
                    <Controller
                      name='searchValue'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          size='medium'
                          placeholder='Enter Value'
                          {...field}
                          type={row.type == 'date' ? 'date' : 'input'}
                          onChange={(e) => {
                            row.value = e.target.value
                            filter[index] = row;
                            setfilter([...filter])
                          }}
                          // onChange={(e) => setFfirstName(e.target.value)}
                          // value={ffirstName}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={3} sm={3} xl={3}>
                    <Controller
                      name='searchValue'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          size='medium'
                          placeholder='Enter Value'
                          {...field}
                          type={row.type == 'date' ? 'date' : 'input'}
                          onChange={(e) => {
                            row.value = e.target.value
                            filter[index] = row;
                            setfilter([...filter])
                          }}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                )}
                {row.dualField ? (
                  <Grid item xs={3} sm={3} xl={3}>
                    <Controller
                      name='searchValue'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          size='medium'
                          placeholder='Enter Value 2'
                          {...field}
                          type={row.type == 'date' ? 'date' : 'input'}
                          onChange={(e) => {
                            row.value2 = e.target.value
                            filter[index] = row;
                            setfilter([...filter])
                          }}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                ) :
                  (null)}
                <Grid item xs={1} sm={1} xl={1}>
                  <Button
                    onClick={() => {
                      var newfilter = filter
                      newfilter.splice(index, 1)
                      console.log('Del index', index)
                      setfilter([...newfilter])
                    }}
                    variant='gradient'
                  >
                    <CancelOutlinedIcon
                      fontSize='medium'
                      style={{ color: 'red', paddingRight: '5px' }}
                    ></CancelOutlinedIcon>
                  </Button>
                </Grid>
              </Grid>
            )
          })}
          <Grid container xs={12} sm={12} xl={12}>
          <Grid item xs={4} sm={4} xl={4}> 
          </Grid> 

          <Grid item xs={3} sm={3} xl={3}>
          <div>
      <label>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
<span style={{marginLeft:'0.5rem',fontSize: '16px',color:'black'}}>Consistent Patients Only </span>     </label>
    </div>
          </Grid>
      
          <Grid item xs={3} sm={3} xl={3}>
          <input type="checkbox" id="vehicle1"         onChange={(e) => CheckGraph(e.target.checked)}
 name="vehicle1" value="Bike" style={{ fontSize: '16px' }} />
  <label htmlFor="vehicle1" style={{ fontSize: '16px',marginLeft:'0.4rem' ,color:'black'}}>View Graph</label>
            </Grid>
          <Grid item xs={2} sm={2} xl={2}>
     

            <Button
              // sx={{ mb: 2 }}
              onClick={searchPatients}
              disabled={submitting}
              variant='gradient'
              size='small'
              style={{
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
                float: 'right',
                marginRight: '20px',
                // marginTop: '20px'
              }}
            >
              Search
            </Button>
          </Grid>
          </Grid>

          <Grid item xs={2} sm={2} xl={2}>
            <Button
              sx={{ mb: 2 }}
              onClick={handleClick}
              variant='gradient'
              size='small'
              style={{
                backgroundColor: '#41CBEB',
                color: 'black',
                fontSize: '14px'
              }}
            >
              Add Filter
            </Button>
          </Grid>

        </Grid>
      </Grid>
    </Box>
  )
}


export default PatientFilters
