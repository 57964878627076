import {
    CircularProgress,
    Grid,
    InputAdornment,
    Snackbar,
    TextField,
    Typography,
} from '@mui/material'
import axios from 'axios'
import SuiInput from 'components/SuiInput'
import React, { useEffect, useReducer, useState, Fragment, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { TabPanel, TabContext, TabList } from '@mui/lab'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import { useAlert } from 'react-alert'
import { DataGrid } from '@mui/x-data-grid'
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import input from '@mui/material/Input'
import Moment from 'moment'
import Avatar from '@mui/material/Avatar'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import handleClick from 'material-paper/build/lib/classes/handleclick'

const PastPsychiatristHistory = (props) => {
    const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }
  const validationSchema = Yup.object({
    psychiatristName: Yup.string().required("psychiatristName is required"),
  });
  const alert = useAlert()
  const patientId=props.patientId

    const fields =[
        "id",
        "patientId",
        "practiceId",
        "isActive",
        "psychiatristName",
        "notes",
        "diagnosis",
        "treatmentPlan",
        "visitDate",
        "createdDate",
        "updatedDate",
        "createdBy",
        "updatedBy"
    ]
    const defValues = {
        id: 0,
        patientId: 0,
        practiceId: 0,
        isActive: '',
        psychiatristName:'',
        notes:'',
        diagnosis:'',
        treatmentPlan:'',
        visitDate:'',
        createdDate:'',
        updatedDate:'',
        createdBy:'',
        updatedBy:''
    }
    const {
        register,
        control,
        handleSubmit,
        setValue,
        reset,
        getValues,
        formState: { errors },
      } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: false,
        shouldUseNativeValidation: false,
        delayError: undefined,
        resolver: yupResolver(validationSchema),
        defaultValues: defValues,
      });
      const [refresh, setRefresh] = React.useState(false)
      const [submitting, setSubmitting] = useState(false)

      const onSubmit = (data) => {   
        setSubmitting(true)
    
        const postData = {
          ...data,
          patientId:props.patientId
        }
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/PatientMedicalNotes/AddPastPsychiatristHistory`,
            postData,
            { headers }
          )
          .then((response) => {
            setRefresh(!refresh)
            setSubmitting(false)
    
             alert.success('Record saved successfully.', {
              type: 'success',
              // onClose: () => {
              //   setOpenNote(false)
              //   setSubmitting(false)
              // },
            })
          })
          .catch((error) => {
            setSubmitting(false)
            alert.success(`Error ${error.message}`, {
              type: 'error',
            })
          })
      }
      useEffect(() => {
        // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    
        axios
          .get
          (`${process.env.REACT_APP_API_URL}/PatientMedicalNotes/getPastPsychiatristHistoryByPatientId?patientId=${props.patientId}`, { headers })
          .then(response => { 
            fields.forEach((field) => setValue(field, response.data[field]));
            const visitDate = Moment(response.data.visitDate).format("YYYY-MM-DD");
            setValue('visitDate', visitDate);
          })
          .catch(error => {
            console.error('There was an error!', error)
          })
      }, [])
    
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                        Psychiatrist Name
                    </Typography>
                    <Controller
                        name="psychiatristName"
                        {...register("psychiatristName")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // isClearable
                                size="small"
                                placeholder="enter psychiatrist name "
                                {...field}
                                fullWidth

                                error={errors.psychiatristName}
                                />
                              )}
                            />
                            <p style={{ color: 'red', fontSize: '14px' }}>{errors.psychiatristName?.message}</p>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                        Notes
                    </Typography>
                    <Controller
                        name="notes"
                        {...register("notes")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // isClearable
                                size="small"
                                placeholder="enter notes"
                                {...field}
                                fullWidth

                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                        Diagnosis
                    </Typography>
                    <Controller
                        name="diagnosis"
                        {...register("diagnosis")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // isClearable
                                size="small"
                                placeholder="enter diagnosis"
                                {...field}
                                fullWidth

                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                        Treatment Plan
                    </Typography>
                    <Controller
                        name="treatmentPlan"
                        {...register("treatmentPlan")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // isClearable
                                size="small"
                                placeholder="enter treatment plan"
                                {...field}
                                fullWidth

                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                    Visit Date

                    </Typography>
                 
                            <SuiInput
                            type="date"
                            name="visitDate"
                            fullWidth
                            margin="dense"
                            {...register("visitDate")}
                          />    
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button
                        onClick={handleSubmit(onSubmit)}
                            variant="gradient"
                            style={{
                                marginTop: '10px',
                                marginBottom: '10px',
                                float: 'right',
                                marginRight: '5px',
                                marginLeft: 'auto',
                                width: '80px',
                                height: '35px',
                                backgroundColor: '#FCBE13',
                                color: 'black',
                                fontSize: '14px',
                            }}
                        >
                            {/* <SaveIcon
                        fontSize="medium"
                        style={{ color: '#0a9ff0', paddingRight: '5px' }}
                      ></SaveIcon> */}
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}

export default PastPsychiatristHistory
