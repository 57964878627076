import React, { useEffect, useReducer, useState, Fragment } from 'react'
import SuiInput from 'components/SuiInput'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import { alignProperty } from '@mui/material/styles/cssUtils'
import { createBrowserHistory } from 'history'
import axios from 'axios'
const history = createBrowserHistory({ forceRefresh: true })
const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
const headers = {
  Authorization: auth,
}
export default function DAST(props) {
  const id = props.id
  const [question1, setQuestion1] = useState('')
  const [question2, setQuestion2] = useState('')
  const [question3, setQuestion3] = useState('')
  const [question4, setQuestion4] = useState('')
  const [question5, setQuestion5] = useState('')
  const [question6, setQuestion6] = useState('')
  const [question7, setQuestion7] = useState('')
  const [question8, setQuestion8] = useState('')
  const [question9, setQuestion9] = useState('')
  const [question10, setQuestion10] = useState('')
  const [totalScore, setTotalScore] = useState('')

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    let isComponentMounted = true
    const fetchData = async () =>
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/PatientPortal/getDast10ById?Id=${
            id !== undefined ? id : 0
          }`,
          { headers }
          // `${process.env.REACT_APP_API_URL}/Facilities/getFacilityById?id=${
          //   location.state.facilityId !== undefined ? location.state.facilityId : 0
          // }`,
          // null,
          // {
          //   headers,
          // }
        )
        .then((response) => {
          //   console.log('response.data: ', response.data)
          setQuestion1(response.data.q1)
          setQuestion2(response.data.q2)
          setQuestion3(response.data.q3)
          setQuestion4(response.data.q4)
          setQuestion5(response.data.q5)
          setQuestion6(response.data.q6)
          setQuestion7(response.data.q7)
          setQuestion8(response.data.q8)
          setQuestion9(response.data.q9)
          setQuestion10(response.data.q10)
          setTotalScore(response.data.totalScore)
        })
        .catch((error) => {
          console.error('There was an error!', error)
        })
    fetchData()
    return () => {
      isComponentMounted = false
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [])
  return (
    <Grid className="container">
      <Typography variant="h1" align="center">
        {' '}
        Drug Abuse Screening Test, DAST-10
      </Typography>
      <Typography style={{ marginTop: '40px' }}>
        The following questions concern information about your possible
        involvement with drugs not including alcoholic beverages during the past
        12 months.
      </Typography>
      <Typography style={{ marginTop: '20px' }}>
        "Drug abuse" refers to (1) the use of prescribed or over‐the‐counter
        drugs in excess of the directions, and (2) any nonmedical use of drugs.
      </Typography>
      <Typography style={{ marginTop: '20px' }}>
        The various classes of drugs may include cannabis (marijuana, hashish),
        solvents (e.g., paint thinner), tranquilizers (e.g., Valium),
        barbiturates, cocaine, stimulants (e.g., speed), hallucinogens (e.g.,
        LSD) or narcotics (e.g., heroin). Remember that the questions do not
        include alcoholic beverages.
      </Typography>
      <Typography style={{ marginTop: '20px' }}>
        Please answer every question. If you have difficulty with a statement,
        then choose the response that is mostly right.
      </Typography>
      <Grid>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{ border: '2px solid black' }}
        >
          <thead>
            <tr>
              <th
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                  padding: '5px',
                }}
                scope="col"
                colSpan={2}
              >
                In the past 12 months…
              </th>
              <th
                scope="col"
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                NO
              </th>
              <th
                scope="col"
                style={{ borderBottom: '1px solid black', textAlign: 'center' }}
              >
                YES
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  borderRight: '2px solid black',
                  borderBottom: '1px solid black',
                  padding: '5px',
                }}
                scope="col"
              >
                1:
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  padding: '5px',
                  borderRight: '1px solid black',
                }}
              >
                Have you used drugs other than those required for medical
                reasons?
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question1"
                  checked={!question1}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question1"
                  checked={question1}
                  value="true"
                ></input>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: '2px solid black',
                  padding: '5px',
                  borderBottom: '1px solid black',
                }}
                scope="col"
              >
                2:
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
              >
                Have you used drugs other than those required for medical
                reasons?
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question2"
                  checked={!question2}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question2"
                  checked={question2}
                  value="true"
                ></input>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: '2px solid black',
                  padding: '5px',
                  borderBottom: '1px solid black',
                }}
                scope="col"
              >
                3:
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
              >
                {' '}
                Are you unable to stop abusing drugs when you want to?
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question3"
                  checked={!question3}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question3"
                  checked={question3}
                  value="true"
                ></input>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: '2px solid black',
                  padding: '5px',
                  borderBottom: '1px solid black',
                }}
                scope="col"
              >
                4:
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  borderRight: '1px solid black',
                }}
              >
                {' '}
                Have you ever had blackouts or flashbacks as a result of drug
                use?
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question4"
                  checked={!question4}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question4"
                  checked={question4}
                  value="true"
                ></input>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: '2px solid black',
                  padding: '5px',
                  borderBottom: '1px solid black',
                }}
                scope="col"
              >
                5:
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
              >
                Do you ever feel bad or guilty about your drug use?{' '}
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question5"
                  checked={!question5}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question5"
                  checked={question5}
                  value="true"
                ></input>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: '2px solid black',
                  padding: '5px',
                  borderBottom: '1px solid black',
                }}
                scope="col"
              >
                6:
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
              >
                Does your spouse (or parents) ever complain about your
                involvement with drugs?
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question6"
                  checked={!question6}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question6"
                  checked={question6}
                  value="true"
                ></input>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: '2px solid black',
                  padding: '5px',
                  borderBottom: '1px solid black',
                }}
                scope="col"
              >
                7:
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
              >
                Have you neglected your family because of your use of drugs?{' '}
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question7"
                  checked={!question7}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question7"
                  checked={question7}
                  value="true"
                ></input>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: '2px solid black',
                  padding: '5px',
                  borderBottom: '1px solid black',
                }}
                scope="col"
              >
                8:
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
              >
                Have you neglected your family because of your use of drugs?{' '}
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question8"
                  checked={!question8}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question8"
                  checked={question8}
                  value="true"
                ></input>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: '2px solid black',
                  padding: '5px',
                  borderBottom: '1px solid black',
                }}
                scope="col"
              >
                9:
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
              >
                Have you ever experienced withdrawal symptoms (felt sick) when
                you stopped taking drugs?{' '}
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question9"
                  checked={!question9}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question9"
                  checked={question9}
                  value="true"
                ></input>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: '2px solid black',
                  padding: '5px',
                  borderBottom: '1px solid black',
                }}
                scope="col"
              >
                10:
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
              >
                Have you had medical problems as a result of your drug use (e.g.
                memory loss, hepatitis, convulsions, bleeding)?{' '}
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question10"
                  checked={!question10}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  padding: '5px',
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question10"
                  checked={question10}
                  value="true"
                ></input>
              </td>
            </tr>

            <tr>
              <td scope="col"></td>
              <td style={{ borderRight: '1px solid black' }}>
                Scoring: Score 1 point for each question answered “Yes,” except
                for question 3 for which a “No” receives 1 point.
              </td>
              <td
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                Score:{' '}
              </td>
              <td style={{ textAlign: 'center' }}>
                <Typography name="totalScore">{totalScore}</Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>
      <Grid
        container
        style={{
          marginTop: '50px',
          innerborder: '2px solid black',
        }}
      >
        <Grid container style={{ border: '2px solid black' }}>
          <Grid
            container
            xs={12}
            lg={12}
            style={{ borderBottom: '1px solid black' }}
          >
            <Grid
              item
              xs={12}
              lg={12}
              style={{
                textAlign: 'left',
                padding: '5px',
              }}
            >
              <b>Interpretation of Score</b>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            lg={12}
            style={{ borderBottom: '1px solid black' }}
          >
            <Grid
              item
              xs={1}
              lg={1}
              style={{
                borderRight: '1px solid black',
                textAlign: 'left',
                padding: '5px',
              }}
            >
              <b>Score</b>
            </Grid>
            <Grid
              item
              xs={6}
              lg={6}
              style={{
                borderRight: '1px solid black',
                textAlign: 'left',
                padding: '5px',
              }}
            >
              <b>Degree of Problems Related to Drug Abuse</b>
            </Grid>
            <Grid
              item
              xs={5}
              lg={5}
              style={{ textAlign: 'left', padding: '5px' }}
            >
              <b>Suggested Action</b>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            lg={12}
            style={{ borderBottom: '1px solid black' }}
          >
            <Grid
              item
              xs={1}
              lg={1}
              style={{
                borderRight: '1px solid black',
                textAlign: 'left',
                padding: '5px',
              }}
            >
              0
            </Grid>
            <Grid
              item
              xs={6}
              lg={6}
              style={{
                borderRight: '1px solid black',
                textAlign: 'left',
                padding: '5px',
              }}
            >
              No problems reported.
            </Grid>
            <Grid
              item
              xs={5}
              lg={5}
              style={{ textAlign: 'left', padding: '5px' }}
            >
              None at this time.
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            lg={12}
            style={{ borderBottom: '1px solid black' }}
          >
            <Grid
              item
              xs={1}
              lg={1}
              style={{
                borderRight: '1px solid black',
                textAlign: 'left',
                padding: '5px',
              }}
            >
              1-2
            </Grid>
            <Grid
              item
              xs={6}
              lg={6}
              style={{
                borderRight: '1px solid black',
                textAlign: 'left',
                padding: '5px',
              }}
            >
              Low level{' '}
            </Grid>
            <Grid
              item
              xs={5}
              lg={5}
              style={{ textAlign: 'left', padding: '5px' }}
            >
              Monitor, re‐assess at a later date
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            lg={12}
            style={{ borderBottom: '1px solid black' }}
          >
            <Grid
              item
              xs={1}
              lg={1}
              style={{
                borderRight: '1px solid black',
                textAlign: 'left',
                padding: '5px',
              }}
            >
              3‐5
            </Grid>
            <Grid
              item
              xs={6}
              lg={6}
              style={{
                borderRight: '1px solid black',
                textAlign: 'left',
                padding: '5px',
              }}
            >
              Moderate level{' '}
            </Grid>
            <Grid
              item
              xs={5}
              lg={5}
              style={{ textAlign: 'left', padding: '5px' }}
            >
              Further investigation{' '}
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            lg={12}
            style={{ borderBottom: '1px solid black' }}
          >
            <Grid
              item
              xs={1}
              lg={1}
              style={{
                borderRight: '1px solid black',
                textAlign: 'left',
                padding: '5px',
              }}
            >
              6‐8
            </Grid>
            <Grid
              item
              xs={6}
              lg={6}
              style={{
                borderRight: '1px solid black',
                textAlign: 'left',
                padding: '5px',
              }}
            >
              Substantial level{' '}
            </Grid>
            <Grid
              item
              xs={5}
              lg={5}
              style={{ textAlign: 'left', padding: '5px' }}
            >
              Intensive assessment{' '}
            </Grid>
          </Grid>
          <Grid container xs={12} lg={12}>
            <Grid
              item
              xs={1}
              lg={1}
              style={{
                borderRight: '1px solid black',
                textAlign: 'left',
                padding: '5px',
              }}
            >
              9‐10{' '}
            </Grid>
            <Grid
              item
              xs={6}
              lg={6}
              style={{
                borderRight: '1px solid black',
                textAlign: 'left',
                padding: '5px',
              }}
            >
              Severe level{' '}
            </Grid>
            <Grid
              item
              xs={5}
              lg={5}
              style={{ textAlign: 'left', padding: '5px', padding: '5px' }}
            >
              Intensive assessment{' '}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          padding: '20px',
        }}
      ></Grid>
      <Typography style={{ fontSize: '15px', marginLeft: '1em',textAlign:'right' }}>
        <b>
          Drug Abuse Screening Test (DAST‐10). (Copyright 1982 by the Addiction
          Research Foundation.)
        </b>
      </Typography>
    </Grid>
  )
}
