// @mui material components
import Card from '@mui/material/Card'

// Soft UI Dashboard React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiInput from 'components/SuiInput'
import SuiButton from 'components/SuiButton'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import { useAlert } from 'react-alert'
import SuiSelect from 'components/SuiSelect'
import SaveIcon from 'assets/save.png'
import moment from 'moment'
import Moment from 'moment'
import CloseIcon from '@mui/icons-material/Close';

import CancelIcon from 'assets/cancel.png'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import input from '@mui/material/Input'
import { Grid, Snackbar, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useReducer, useState, Fragment } from 'react'
import { createBrowserHistory } from 'history'
import Checkbox from '@mui/material/Checkbox'
import { useLocation } from 'react-router-dom'
import ReactSelect from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const history = createBrowserHistory({ forceRefresh: true })
const fields = [
  'id',
  'userName',
  'startTime',
  'endTime',
  'inactive',
  'patientId',
  'type',
  'inactive',
   'practiceId',
  'createdDate',
  'updatedDate',
  'createdBy',
  'updatedBy',
  'clientCreatedDate',
  'clientUpdatedDate',
  'patientProgram'
]
const defValues = {
  id: '',
  userName: '',
  startTime: '',
  endTime: '',
  inactive: '',
  patientId: '',
  type: '',
  practiceId:'',
   createdDate:'',
  updatedDate:'',
  createdBy:'',
  updatedBy:'',
  clientCreatedDate:'',
  clientUpdatedDate:'',
  patientProgram:'',

}
// function handleCancel() {
//   history.push('/Reports')
// }

function Timelogeidth(props) {
  const location = useLocation()
  const readOnly=props.readOnly
  const alert = useAlert()
  const _id =
    props != null && props.Timelogid > 0
      ? props.Timelogid
      : location.state.Timelogid
  const [submitting, setSubmitting] = useState(false)
  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }
  const [startTime, setStartTime] = useState('')
   const [endTime, setEndTime] = useState('')
   const [totalTime, setTotalTime] = useState('')
  const validationSchema = Yup.object({
    userName: Yup.string().required('Name is required'),
  })
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  })
  function handleCancel1() {
    getValues('inactive')
  }
  const onSubmit = (data) => {
    setSubmitting(true)
    const postData = data
    console.warn('Data: ', postData)
    /* if (_id !== undefined && _id != null) postData.Id = _id
    else postData.Id = 0 */
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientTimeLog/addPatientTimeLog`,
        postData,
        { headers }
      )
      .then((response) => {
        setSubmitting(false)
        
        alert.success('Record saved successfully.', {
          type: 'success',
          
          onClose: () => {
            // history.push('/Timelog')
            props.Backfunction(true)
           },
        })
      })
      .catch((error) => {
        setSubmitting(false)
        alert.success(`Error ${error.message}`, {
          type: 'error',
        })
      })
  }
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    let isComponentMounted = true
    const fetchData = async () =>
      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/PatientTimeLog/getPatientTimeLogById?id=${
            _id !== undefined ? _id : 0
          }`,
          { headers }
          // `${process.env.REACT_APP_API_URL}/Facilities/getFacilityById?id=${
          //   location.state.facilityId !== undefined ? location.state.facilityId : 0
          // }`,
          // null,
          // {
          //   headers,
          // }
        )
        .then((response) => {
          console.log('response.data: ', response.data)
          if (isComponentMounted) {
            fields.forEach((field) => setValue(field, response.data[field]))
            setStartTime(response.data.startTime)
            setEndTime(response.data.endTime)
          }
        })
        .catch((error) => {
          console.error('There was an error!', error)
        })
    fetchData()
    return () => {
      isComponentMounted = false
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [])
  // const duration = (event) => {
  //   // console.log('drr', event)
  //   var startTimeDuration = moment(`${startTime}`)
  //    var endTimeDuration = moment(`${endTime}`)
  //    var ms = endTimeDuration.diff(startTimeDuration,'minutes','seconds')
  //    var roundof=(ms).toFixed(2)
  //     setTotalTime(roundof+'  '+'minutes')
      
  // }
  // React.useEffect(() => {
  //   duration()
  // }, [startTime, endTime])
  const duration = (event) => {
    
    console.log('Start Time:', startTime);
    console.log('End Time:', endTime);
  
    var startTimeDuration = moment(startTime); 
    var endTimeDuration = moment(endTime); 
  
    var duration = moment.duration(endTimeDuration.diff(startTimeDuration)); 
  
    var minutes = Math.floor(duration.asMinutes());
    var seconds = duration.seconds(); 
  
  
    var totalTime = `${minutes} minutes ${seconds} seconds`;
  
    setTotalTime(totalTime);
  };
  
  React.useEffect(() => {
    duration();
  }, [startTime, endTime]);

  return (
    <>
       {submitting ? (
        <CircularProgress
          style={{
            width: '50px',
            height: '50px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginLeft: '-25px',
            marginTop: '-25px',
            zIndex: 1000,
            color: '#FCBE13',
          }}
        />
      ) : null}
{/* <div
  style={{
    position: 'relative',
    width: '100%',
    height: '100%',
  }}
>
  <div
    onClick={() => setopenNote(false)}
    style={{
      cursor: 'pointer',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '2px solid black',
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      position: 'absolute',
      top: '10px', // Adjust as needed
      right: '10px', // Adjust as needed
      // backgroundColor: '#FCBE13',
    }}
  >
    <CloseIcon
      fontSize="large"
      style={{
        color: 'black',
      }}
    />
  </div>
</div> */}
      <Grid
        container
        spacing={1}
        alignContent="center"
        justifyContent="center"
        style={{
          paddingTop: '10px',
          paddingLeft: '10px',
          paddingRight: '100px',
        }}
      >

        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            Start Time 
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            End Time
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
          Total Time
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter Start Time"
            type="datetime-local"
            fullWidth
            value={startTime? moment(startTime).format('YYYY-MM-DDTHH:mm:ss') : ''}
            margin="dense"
            onChange={(e) => {
              setValue('startTime', e.currentTarget.value)
              setStartTime(moment(e.currentTarget.value).format('YYYY-MM-DDTHH:mm:ss')); 
              console.log('Start Time: ',moment(e.currentTarget.value).format('YYYY-MM-DDTHH:mm'));
            }}
            // {...register('startTime')}
            error={errors.startTime}
          />
          <p style={{ color: 'red' }}>{errors.name?.message}</p>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter EndTime"
            type="datetime-local"
            fullWidth
            margin="dense"
            value={endTime? moment(endTime).format('YYYY-MM-DDTHH:mm:ss') : ''}
             onChange={(e) => {
              setValue('endTime', e.currentTarget.value)
              setEndTime(moment(e.currentTarget.value).format('YYYY-MM-DDTHH:mm:ss'));
              console.log('End Time: ', moment(e.currentTarget.value).format('yyyy-mm-ddthh:mm'));
            }}
            // {...register('endTime')}
            error={errors.endTime}
          />
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
        <SuiInput
            placeholder="Please enter User Name"
            name="totalTime"
            label="totalTime"
            fullWidth
            value={totalTime}
            disabled
            margin="dense"
            // {...register('totalTime')}
            error={errors.userName}
          />
       
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            Type
          </SuiTypography>
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Program
              </SuiTypography>
            </Grid>
            <Grid item xs={3} style={{ width: '300px' }}>
          <SuiTypography
            component="label"
            variant="caption"
            fontWeight="bold"
           >
            User Name
          </SuiTypography>
        </Grid>
        <Grid item xs={1} style={{ width: '300px' }}>
          <SuiTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            onClick={handleCancel1}
          >
            Deleted
          </SuiTypography>
        </Grid>

        <Grid item xs={4} style={{ width: '300px' }}>
          <Controller
            name="type"
            {...register('type')}
            control={control}
            render={({ field }) => (
              <SuiSelect
                value={{ label: getValues('type') }}
                onChange={(value) => setValue('type', value.label)}
                options={[
                  { value: 'Psychotherapist', label: 'Psychotherapist' },
                  { value: 'Other', label: 'Other' },
                ]}
              />
            )}
          />
        </Grid>
        <Grid item xs={4} style={{ width: '300px' }}>
              <Controller
                name="program"
                {...register('patientProgram')}
                control={control}
                render={({ field }) => (
                  <SuiSelect
                    {...field}
                    value={{ label: getValues('patientProgram') }}
                    onChange={(value) => setValue('patientProgram', value.label)}
                    options={[
                      { value: '', label: '' },
                      { value: 'BHI', label: 'BHI' },
                      { value: 'CoCM', label: 'CoCM' },
                    ]}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} style={{ width: '300px' }}>
            <SuiInput
            placeholder="Please enter User Name"
            name="type"
            label="Type"
            fullWidth
            margin="dense"
            {...register('userName')}
            error={errors.userName}
          />
        </Grid>
        <Grid item xs={1} style={{ width: '300px' }}>
          <SuiInput
            type="checkbox"
            margin="dense"
            {...register('inactive')}
            error={errors.inactive}
          />
        </Grid>

        <Button
          onClick={handleSubmit(onSubmit)}
          variant="gradient"
          disabled={readOnly==true?true:submitting}
          style={{
            marginTop: '50px',
            float: 'right',
            marginLeft: 'auto',
            marginBottom:'10px',
            width:'80px',
            height:'35px',
            backgroundColor: "#FCBE13",
            color:"black",
            fontSize:'14px'
          }}
        >
          <SaveOutlinedIcon
          fontSize='medium'
          style={{ color:'#1a82ad', paddingRight:'5px', paddingBottom:'2px'}}
          >
          </SaveOutlinedIcon>
          Save
        </Button>
      </Grid>
    </>
  )
}

export default Timelogeidth
