/* eslint-disable object-shorthand */
// @mui material components
import Card from '@mui/material/Card'

// Soft UI Dashboard React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiInput from 'components/SuiInput'
import SuiButton from 'components/SuiButton'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import { useAlert } from 'react-alert'

import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import input from '@mui/material/Input'
import { Grid, Snackbar, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useReducer, useState, Fragment } from 'react'
import { createBrowserHistory } from 'history'
import Checkbox from '@mui/material/Checkbox'
import { useLocation } from 'react-router-dom'
import ReactSelect from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Button from '@mui/material/Button';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CircularProgress from '@mui/material/CircularProgress'

const history = createBrowserHistory({ forceRefresh: true })
function handleCancel() {
  history.push('/CareTeam')
}
const fields = [
  'id',
  'name',
  'description',
  'alias',
  'speciality',
  'responsibility',
  'practiceId',
  'isActive',
  'createdDate',
  'updatedDate',
  'createdBy',
  'updatedBy',
  'clientCreatedDate',
  'clientUpdatedDate ',
]
const defValues = {
  id: '',
  name: '',
  description: '',
  alias: '',
  speciality: '',
  responsibility: '',
  practiceId: '',
  isActive: '',
  createdDate: '',
  updatedDate: '',
  createdBy: '',
  updatedBy: '',
  clientCreatedDate: '',
  clientUpdatedDate: '',
}
function Addteam() {
  const location = useLocation()
  const alert = useAlert()
  const [submitting, setSubmitting] = useState(false)
  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }
  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
  })
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  })
  // const onSubmit = (data) => {
  //   setSubmitting(true)
  //   const postData = data
  //   postData.userType = data.userType ? data.userType.value : ''
  //   postData.gender = data.gender ? data.gender.value : ''
  //   console.log('Data: ', postData)
  //   console.log(JSON.stringify(postData, null, 2))
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_URL}/CareTeams/addCareTeam`,
  //       postData,
  //       { headers }
  //     )
  //     .then((response) => {
  //       setSubmitting(false)
  //       alert.success('Record saved successfully.', {
  //         type: 'success',
  //         onClose: () => {
  //           history.push('/CareTeam')
  //         },
  //       })
  //     })
  //     .catch((error) => {
  //       setSubmitting(false)
  //       alert.success(`Error ${error.message}`, {
  //         type: 'error',
  //       })
  //     })
  // }
  const onSubmit = (data) => {
    setSubmitting(true)
    const postData = data
    // postData.userType = data.userType ? data.userType.value : ''
    // postData.gender = data.gender ? data.gender.value : ''
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/CareTeams/addCareTeam`,
        postData,
        { headers }
      )
      .then((response) => {
        setSubmitting(false)
        alert.success('Record saved successfully.', {
          type: 'success',
          onClose: () => {
            history.push('/CareTeam')
          },
        })
      })
      .catch((error) => {
        setSubmitting(false)
        alert.success(`Error ${error.message}`, {
          type: 'error',
        })
      })
  }
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    let isComponentMounted = true
    const fetchData = async () =>
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/CareTeams/getCareTeamsById?id=${
            location.state.careTeamId !== undefined
              ? location.state.careTeamId
              : 0
          }`,
          { headers }
        )

        .then((response) => {
          console.log('response.data: ', response.data)
          if (isComponentMounted) {
            // setUsersData(response.data);
            fields.forEach((field) => setValue(field, response.data[field]))
            /*  const petList = Object.entries(response.data).map(([key, value]) => {
              console.log("Key: ", key, value);
              register(`${key}`, `${value}`);
              return [key, value];
            }); */
          }
        })
        .catch((error) => {
          console.error('There was an error!', error)
        })
    fetchData()
    return () => {
      isComponentMounted = false
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [])

  return (
    <>
       {submitting ? (
        <CircularProgress
          style={{
            width: '50px',
            height: '50px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginLeft: '-25px',
            marginTop: '-25px',
            zIndex: 1000,
            color: '#FCBE13',
          }}
        />
      ) : null}
      <Grid
        container
        spacing={1}
        alignContent="center"
        justifyContent="center"
        style={{
          paddingTop: '10px',
          paddingLeft: '150px',
          paddingRight: '150px',
        }}
      >
        <Grid item xs={6} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Name
          </SuiTypography>
        </Grid>
        <Grid item xs={6} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            PracticeId
          </SuiTypography>
        </Grid>
        <Grid item xs={6} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter name"
            name="name"
            label="name"
            fullWidth
            margin="dense"
            {...register('name')}
            error={errors.name}
          />
          <p style={{ color: 'red' }}>{errors.name?.message}</p>
        </Grid>
        <Grid item xs={6} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter practiceId"
            name="practiceId"
            label="practiceId"
            fullWidth
            margin="dense"
            {...register('practiceId')}
            error={errors.practiceId}
          />
          <p style={{ color: 'red' }}>{errors.description?.message}</p>
        </Grid>
        <Grid item xs={6} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Alias
          </SuiTypography>
        </Grid>
        <Grid item xs={6} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Speciality
          </SuiTypography>
        </Grid>
        <Grid item xs={6} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter alias"
            name="alias"
            label="alias"
            fullWidth
            margin="dense"
            {...register('alias')}
            error={errors.alias}
          />
          <p style={{ color: 'red' }}>{errors.alias?.message}</p>
        </Grid>
        <Grid item xs={6} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter speciality"
            name="speciality"
            label="speciality"
            fullWidth
            margin="dense"
            {...register('speciality')}
            error={errors.speciality}
          />
          <p style={{ color: 'red' }}>{errors.speciality?.message}</p>
        </Grid>
        <Grid item xs={6} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Responsibility
          </SuiTypography>
        </Grid>
        <Grid item xs={6} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontSize="15px">
            Description
          </SuiTypography>
        </Grid>
        <Grid item xs={6} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter responsibility"
            name="responsibility"
            label="responsibility"
            fullWidth
            margin="dense"
            {...register('responsibility')}
            error={errors.responsibility}
          />
          <p style={{ color: 'red' }}>{errors.responsibility?.message}</p>
        </Grid>
        <Grid item xs={6} style={{ width: '300px' }}>
          <SuiInput
            placeholder="Please enter description"
            name="description"
            label="description"
            fullWidth
            margin="dense"
            {...register('description')}
            error={errors.description}
          />
          <p style={{ color: 'red' }}>{errors.description?.message}</p>
        </Grid>

        <Grid item xs={12} style={{ width: '300px' }}>
        <Button
           onClick={handleCancel}
            variant="gradient"
            disabled={submitting}
            style={{
              float: 'right',
              marginTop: '10px',
              marginLeft: 'auto',
              float:'right',
              width:'90px',
              height:"35px",
              backgroundColor: "#FCBE13",
              color:"black",
              fontSize:'14px'

            }}
              // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}

          >
            <CancelOutlinedIcon
            fontSize='medium'
           style={{ color:"red",paddingRight:'5px',}}
            ></CancelOutlinedIcon>
            Cancel
          </Button>
        <Button
            onClick={handleSubmit(onSubmit)}
            variant="gradient"
            disabled={submitting}
            style={{
              marginTop: '10px',
              float:'right',
              marginRight: '13px',
              width:'80px',
              height:'35px',
              backgroundColor: "#FCBE13",
              color:"black",
              fontSize:'14px'
            }}
            // color="warning"
            // size="small"

            // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}

          >
            <SaveOutlinedIcon
            fontSize='medium'
            style={{ color:'#1a82ad', paddingRight:'5px', paddingBottom:'2px'}}
            >
            </SaveOutlinedIcon>
            Save
          </Button>

        </Grid>
      </Grid>
    </>
  )
}

export default Addteam
