// ** React Imports
import React from 'react'
import axios from 'axios'
import { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { Controller, useForm } from 'react-hook-form'
import ReactSelect from 'react-select'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SuiSelect from 'components/SuiSelect'
import SuiInput from 'components/SuiInput'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { CircularProgress, } from '@mui/material'




var providersRedux = []
var facilitiesRedux = []
var IntervalRedux = []
const PatientFilters = (props) => {
  const token = localStorage.getItem('dtt_token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }

  const check = createFilterOptions()
  const [filter, setfilter] = useState([])
  const [filterProviderId, setFilterProviderId] = useState('')
  const [filterFacilityId, setFilterFacilityId] = useState("")
  const [startDate, setStartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [TabValue, settabValue] = useState(props.tabValue)
  const [submitting, setsubmitting] = useState(false)
  const [StartDateErr, setStartDateErr] = useState("");
  const [EndDateErr, setEndDateErr] = useState("");
  const [_providerId, setProviderId] = React.useState('');

  const handleClick = event => {
    // 👇️ toggle shown state
    var newfilter = filter
    var id = filter.length + 1
    var boj = {
      id: id,
      columns: '',
      operator: '',
      value: '',
      value2: '',
      type: '',
      dualField: '',
      filter: []
    }
    newfilter.push(boj)
    setfilter([...newfilter])
    // setUpdated(id)
    // console.log('Testfilter', newfilter)
  }

  const searchPatients = () => {
if(!startDate){ 
  setStartDateErr(' Start Date is Required')
  return
}
setStartDateErr('')

if(!endDate){ 
  setEndDateErr(' End Date is Required')
  return
}

setEndDateErr('')
    setsubmitting(true)
    var filterArr = [];
    filter.forEach(f => {
      if (f.columns && f.operator && (f.value || f.value2)) {
        var obj = { 'field': f.columns, 'operatorName': f.operator, 'operatorType': f.type, 'value': f.value, 'value2': f.value2 }
        filterArr.push(obj);
      }
    });

    axios
      .post(`${process.env.REACT_APP_API_URL}/Reports/getPatientPHQ9?startDate=${startDate}&endDate=${endDate}`, filterArr, { headers })
      .then(response => {

        console.log('Response:', response.data);
        if(response.data.length==0){
          props.getRecordsMsg('No Record Found');

        }else{
          props.getRecords(response.data);

        }
        if (TabValue == 0) {
          props.getRecords(response.data);
          setsubmitting(false)

        }
      })
      .catch(error => {
        console.error('There was an error!', error)
        setsubmitting(false)
      })

    axios
      .post(`${process.env.REACT_APP_API_URL}/Reports/getPatientGAD7?startDate=${startDate}&endDate=${endDate}`, filterArr, { headers })
      .then(response => {
        console.log('Response:', response.data);
        if(response.data.length==0){
          props.getRecordsGAD7Msg('No Record Found');

        }else{
          props.getRecordsGAD7(response.data);

        }
        if (TabValue == 0) {
          props.getRecordsGAD7(response.data);
          setsubmitting(false)
          // props.getRecords(response.data);
        }
      })
      .catch(error => {
        console.error('There was an error!', error)
        setsubmitting(false)
      })
    // }

  }


  const whereArray = [

    {
      title: 'First Name', value: 'p.firstName', type: 'string', filter: [
        { value: 'equal', label: 'Equal' },
        { value: 'notEqual', label: 'Not Equal' },
        { value: 'contains', label: 'Contains' },
        { value: 'notContains', label: 'Not Contains' },
      ]
    },
    {
      title: 'Last Name', value: 'p.lastName', type: 'string', filter: [
        { value: 'equal', label: 'Equal' },
        { value: 'notEqual', label: 'Not Equal' },
        { value: 'contains', label: 'Contains' },
        { value: 'notContains', label: 'Not Contains' },
      ]
    },
    {
      title: 'DOB', value: 'p.dOB', type: 'date', filter: [
        { value: 'equal', label: 'Equal' },
        { value: 'notEqual', label: 'Not Equal' },
        { value: 'lessThan', label: 'Less Than' },
        { value: 'greaterThan', label: 'Greater Than' },
        { value: 'lessThanEqualTo', label: 'Less Than and Equal to' },
        { value: 'greaterThanEqualTo', label: 'Greater Than and Equal to' },
        { value: 'dateBetween', label: 'Between' },
      ]
    },
    {
      title: 'Age', value: 'age', type: 'string', filter: [
        { value: 'equal', label: 'Equal' },
        { value: 'notEqual', label: 'Not Equal' },
        { value: 'lessThan', label: 'Less Than' },
        { value: 'greaterThan', label: 'Greater Than' },
        { value: 'lessThanEqualTo', label: 'Less Than and Equal to' },
        { value: 'greaterThanEqualTo', label: 'Greater Than and Equal to' },
        { value: 'between', label: 'Between' },
      ]
    },
    {
      title: 'Gender', value: 'p.gender', type: 'string', filter: [
        { value: 'equal', label: 'Equal' },
        { value: 'notEqual', label: 'Not Equal' }
      ]
    },
    {
      title: 'Patient Registration', value: 'p.createdDate', type: 'date', filter: [
        { value: 'equal', label: 'Equal' },
        { value: 'notEqual', label: 'Not Equal' },
        { value: 'lessThan', label: 'Less Than' },
        { value: 'greaterThan', label: 'Greater Than' },
        { value: 'lessThanEqualTo', label: 'Less Than and Equal to' },
        { value: 'greaterThanEqualTo', label: 'Greater Than and Equal to' },
        { value: 'dateBetween', label: 'Between' },
      ]
    },
    {
      title: 'Disease', value: 'd.code', type: 'string', filter: [
        { value: 'equal', label: 'Equal' },
        { value: 'notEqual', label: 'Not Equal' },
        { value: 'contains', label: 'Contains' },
        { value: 'notContains', label: 'Not Contains' },
      ]
    },
    {
      title: 'Avg Score', value: 'p.totalScore', type: 'string', filter: [
        { value: 'equal', label: 'Equal' },
        { value: 'notEqual', label: 'Not Equal' },
        { value: 'lessThan', label: 'Less Than' },
        { value: 'greaterThan', label: 'Greater Than' },
        { value: 'lessThanEqualTo', label: 'Less Than and Equal to' },
        { value: 'greaterThanEqualTo', label: 'Greater Than and Equal to' },
        { value: 'between', label: 'Between' },
      ]
    },
    {
      title: 'Min Score', value: 'p.totalScore', type: 'string', filter: [
        { value: 'equal', label: 'Equal' },
        { value: 'notEqual', label: 'Not Equal' },
        { value: 'lessThan', label: 'Less Than' },
        { value: 'greaterThan', label: 'Greater Than' },
        { value: 'lessThanEqualTo', label: 'Less Than and Equal to' },
        { value: 'greaterThanEqualTo', label: 'Greater Than and Equal to' },
        { value: 'between', label: 'Between' },
      ]
    },
    {
      title: 'Max Score', value: 'p.totalScore', type: 'string', filter: [
        { value: 'equal', label: 'Equal' },
        { value: 'notEqual', label: 'Not Equal' },
        { value: 'lessThan', label: 'Less Than' },
        { value: 'greaterThan', label: 'Greater Than' },
        { value: 'lessThanEqualTo', label: 'Less Than and Equal to' },
        { value: 'greaterThanEqualTo', label: 'Greater Than and Equal to' },
        { value: 'between', label: 'Between' },
      ]
    },
    {
      title: 'Program', value: 'p.program', type: 'string', filter: [
        { value: 'equal', label: 'Equal' },
        { value: 'notEqual', label: 'Not Equal' },
        { value: 'contains', label: 'Contains' },
        { value: 'notContains', label: 'Not Contains' },
      ]
    }

  ]

  const validationSchema = Yup.object({

    startDate: Yup.date()
      .required('startDate is required')
      .nullable(),
    endDate: Yup.date()
      .required('endDate is required')
      .nullable()
  })
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    // delayError: undefined,
    resolver: yupResolver(validationSchema)
  })

  const facilitiesRedx = useSelector(
    (state) => (state.facilities.loading ? [] : state.facilities.NAMES),
    shallowEqual
  )
  const providersRedx = useSelector(
    (state) => (state.providers.loading ? [] : state.providers.NAMES),
    shallowEqual
  )

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    facilitiesRedux = []
    facilitiesRedx.forEach((field) => {
      facilitiesRedux.push({
        label: field.name,
        value: field.id,
      })
    })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [facilitiesRedx])

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    providersRedux = []
    providersRedx.forEach((field) => {
      providersRedux.push({
        label: field.name,
        value: field.id,
      })
    })
  }, [providersRedx])

  return (
    <Box mb={2}>
      <Grid
        container
        spacing={1}
        item
        xs={12}
        sm={12}
        xl={12}
        style={{
          width: '100%',
          marginBottom: '10px',
          border: '1px solid rgba(5, 152, 236, 0.637)',
          borderRadius: '20px',
          marginTop: '10px',
          paddingBottom: '20px',
          marginLeft: '1px',
          padding: '15px',
          // paddingLeft: '15px',
          backgroundColor: 'white'
        }}
      >

        <Grid item xs={6} sm={3} xl={3}>
          <Typography style={{ fontSize: '14px' }}>Providers</Typography>
        </Grid>
        <Grid item xs={6} sm={3} xl={3}>
          <Typography style={{ fontSize: '14px' }}>Facility</Typography>
        </Grid>
        <Grid item xs={6} sm={3} xl={3}>
          <Typography style={{ fontSize: '14px' }}>Start Date</Typography>
        </Grid>
        <Grid item xs={6} sm={3} xl={3}>
          <Typography style={{ fontSize: '14px' }}>End Date</Typography>
        </Grid>

        {/* <Grid item xs={6} sm={3} xl={3}>
          <SuiSelect
            isClearable
            default
            value={filterProviderId}
            onChange={(value) => {
              setFilterProviderId(value.label)
              console.log("setFilterProviderId", value)
            }}
            options={providersRedux}
          />
        </Grid> */}

        <Grid item xs={3} style={{ width: '300px',zIndex:1000 }}>
        <Controller
  // name="providerId" 
  // {...register('providerId')}
  // control={control}
  // render={({ field }) => (
  //   <SuiSelect
  //     isClearable
  //     {...field}
  //     defaultValue={providersRedux.filter(function (option) {
  //       return option.value === getValues('providerId');
  //     })}
  //     onChange={(value) => {
  //       setValue('providerId', value.value);
  //     }}
  //     options={providersRedux}
     
  //     MenuProps={{
  //       PaperProps: {
  //         style: {
  //           zIndex:  -1, 
  //         },
  //       },
  //     }}
  //   />
  // )}
  name="providerId"
  {...register('providerId')}
  control={control}
  render={({ field }) => (
    <SuiSelect
      isClearable
      {...field}
      value={providersRedux.find((option) => option.value === getValues('providerId'))}
      onChange={(value) => {
        setValue('providerId', value.value);
        setProviderId(value.value);
      }}
      options={providersRedux}
    />
  )}
/>

          <p style={{ color: 'red', fontSize: '14px' }}>
            {errors.providerId?.message}
          </p>
        </Grid>

        <Grid item xs={3} style={{ width: '300px' }}>
          <Controller
            name="facilityId"
            {...register('facilityId')}
            control={control}
            render={({ field }) => (
              <SuiSelect
                placeholder="Select Facilities"
                isClearable
                {...field}
                value={facilitiesRedux.filter(function (option) {
                  return option.value === getValues('facilityId')

                })}
                options={facilitiesRedux}
                onChange={(value) => {
                  setValue('facilityId', value.value)
                }}
              />
            )}
          />
        </Grid>

        {/* <Grid item xs={6} sm={3} xl={3}>
          <SuiSelect
            isClearable
            default
            value={filterFacilityId}
            onChange={(value) => {
              setFilterFacilityId(value.value)
            }}
            options={facilitiesRedux}
          />
        </Grid> */}

        <Grid item xs={6} sm={3} xl={3}>
          <SuiInput
            type="date"
            placeholder="Enter startDate"
            value={startDate}
            onChange={(e) => {
              setValue('startDate', e.target.value)
              setStartDate(e.target.value)
            }}
            error={errors.startDate}
          />
          <p style={{ color: 'red', fontSize: '14px' }}>
            {StartDateErr}
          </p>
        </Grid>

        <Grid item xs={6} sm={3} xl={3}>
          <SuiInput
            onChange={(e) => setendDate(e.target.value)}
            placeholder="Please enter End Date"
            type="date"
            name="endDate"
            label="End Date"
            fullWidth
            margin="dense"
          />
           <p style={{ color: 'red', fontSize: '14px' }}>
            {EndDateErr}
          </p>
        </Grid>

        <Grid item xs={12} sm={12} xl={12}>
          <Grid item xs={12} sm={12} xl={12}>
            <Typography style={{ fontSize: '20px', fontWeight: 'bold' }}>Filters</Typography>
          </Grid>

          {filter.map((row, index) => {
            console.log('mapping filters', row)
            return (
              <Grid container spacing={2} item xs={12} sm={12} xl={12}>
                <Grid item xs={1} sm={1} xl={1}>
                  <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginTop: '8px' }}>Where</Typography>
                </Grid>
                <Grid item xs={3} sm={3} xl={3}>
                  <Autocomplete
                    onChange={(event, newValue) => {
                      row.columns = newValue.value
                      row.filter = newValue.filter
                      row.type = newValue.type
                      filter[index] = row;
                      setfilter([...filter])
                    }}
                    filterOptions={(options, params) => {
                      const filtered = check(options, params)
                      const { inputValue } = params
                      return filtered
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id='free-solo-with-text-demo'
                    options={whereArray}
                    getOptionLabel={option => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue
                      }
                      // Regular option
                      return option.title
                    }}
                    renderOption={(props, option) => <li {...props}>{option.title}</li>}
                    freeSolo
                    renderInput={params => <TextField {...params} size='medium' />}
                  />

                </Grid>
                {row.dualField ? (
                  <Grid item xs={2} sm={2} xl={2}>
                    <SuiSelect
                      onChange={(e, v) => {
                        row.dualField = e.value == 'between' ? true : false
                        row.operator = e.value
                        filter[index] = row;
                        setfilter([...filter])
                      }}
                      options={row.filter}
                    />
                  </Grid>
                ) : (

                  <Grid item xs={3} sm={3} xl={3}>
                    <SuiSelect
                      onChange={(e, v) => {
                        row.dualField = e.value == 'between' ? true : false
                        row.operator = e.value
                        filter[index] = row;
                        setfilter([...filter])
                      }}
                      options={row.filter}
                    />
                  </Grid>
                )}
                {row.dualField ? (
                  <Grid item xs={2} sm={2} xl={2}>
                    <Controller
                      name='searchValue'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          size='medium'
                          placeholder='Search Value'
                          {...field}
                          type={row.type == 'date' ? 'date' : 'input'}
                          onChange={(e) => {
                            row.value = e.target.value
                            filter[index] = row;
                            setfilter([...filter])
                          }}
                          // onChange={(e) => setFfirstName(e.target.value)}
                          // value={ffirstName}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={3} sm={3} xl={3}>
                    <Controller
                      name='searchValue'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          size='medium'
                          placeholder='Search Value'
                          {...field}
                          type={row.type == 'date' ? 'date' : 'input'}
                          onChange={(e) => {
                            row.value = e.target.value
                            filter[index] = row;
                            setfilter([...filter])
                          }}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                )}
                {row.dualField ? (
                  <Grid item xs={3} sm={3} xl={3}>
                    <Controller
                      name='searchValue'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          size='medium'
                          placeholder='Search Value 2'
                          {...field}
                          type={row.type == 'date' ? 'date' : 'input'}
                          onChange={(e) => {
                            row.value2 = e.target.value
                            filter[index] = row;
                            setfilter([...filter])
                          }}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                ) :
                  (null)}
                <Grid item xs={1} sm={1} xl={1}>
                  <Button
                    onClick={() => {
                      var newfilter = filter
                      newfilter.splice(index, 1)
                      console.log('Del index', index)
                      setfilter([...newfilter])
                    }}
                    variant='gradient'
                  >
                    <CancelOutlinedIcon
                      fontSize='medium'
                      style={{ color: 'red', paddingRight: '5px' }}
                    ></CancelOutlinedIcon>
                  </Button>
                </Grid>
              </Grid>
            )
          })}

          <Grid item xs={12} sm={12} xl={12}>
            <Button
              sx={{ mb: 2 }}
              onClick={searchPatients}
              disabled={submitting}
              variant='gradient'
              size='small'
              style={{
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
                float: 'right',
                marginRight: '20px',
                marginTop: '20px'
              }}
            >
              Search
            </Button>
          </Grid>

          <Grid item xs={2} sm={2} xl={2}>
            <Button
              sx={{ mb: 2 }}
              onClick={handleClick}
              variant='gradient'
              size='small'
              style={{
                backgroundColor: '#41CBEB',
                color: 'black',
                fontSize: '14px'
              }}
            >
              Add Filter
            </Button>
          </Grid>
          {submitting? <CircularProgress
          style={{
            width: '50px',
            height: '50px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginLeft: '-25px',
            marginTop: '-25px',
            zIndex: 1000,
            color: '#FCBE13',
          }}
        />:''}
        </Grid>
      </Grid>
    </Box>
  )
}


export default PatientFilters
