/* eslint-disable object-shorthand */
// @mui material components
import Card from '@mui/material/Card'

// Soft UI Dashboard React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiInput from 'components/SuiInput'
import SuiButton from 'components/SuiButton'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import { useAlert } from 'react-alert'
import Autocomplete from '@mui/material/Autocomplete'
import SaveIcon from 'assets/save.png'
import CancelIcon from 'assets/cancel.png'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import input from '@mui/material/Input'
import { Grid, Snackbar, TextField, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useReducer, useState, Fragment } from 'react'
import { createBrowserHistory } from 'history'
import Checkbox from '@mui/material/Checkbox'
import { useLocation } from 'react-router-dom'
import ReactSelect from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import * as Yup from 'yup'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InputMask from 'react-input-mask'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import SuiSelect from 'components/SuiSelect'

const history = createBrowserHistory({ forceRefresh: true })
var providersRedux = []
const fields = [
  'id',
  'patientId',
  'practiceId',
  'name',
  'providerId',
  'fax',
  'completed',
  'patientCreated',
  'inactive',
  'createdDate',
  'updatedDate',
  'createdBy',
  'updatedBy',
  'clientCreatedDate',
  'clientUpdatedDate',
]
const defValues = {
  id: '',
  patientId: 0,
  practiceId: 0,
  name: '',
  providerId: 0,
  fax: '',
  completed: true,
  patientCreated: true,
  appointmentSchedule: true,
  inactive: true,
  createdDate: '',
  updatedDate: '',
  createdBy: '',
  updatedBy: '',
  clientCreatedDate: '',
  clientUpdatedDate: '',
}
function handleCancel() {
  history.push('/Referral')
}
function ReferralRegistration() {
  const location = useLocation()
  const alert = useAlert()
  const [submitting, setSubmitting] = useState(false)
  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
  })
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  })
  const providersRedx = useSelector(
    (state) => (state.providers.loading ? [] : state.providers.NAMES),
    shallowEqual
  )
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    providersRedux = []
    // console.log('reduxxx', providersRedx)
    providersRedx.forEach((field) => {
      providersRedux.push({
        label: field.name,
        value: field.id,
      })
    })
    // if (_patientId == undefined || _patientId==null || _patientId==0) {
    //   setValue('providerId','' ) 
    // }
  })
  const onSubmit = (data) => {
    setSubmitting(true)
    const postData = data
    console.warn('Data: ', postData)
    
    if (location.state.referral !== undefined && location.state.referral != null)
      postData.id = location.state.referral
    else postData.id = 0
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Referrals/AddReferrals`,
        postData,
        { headers }
      )
      .then((response) => {
        setSubmitting(false)
        alert.success('Record saved successfully.', {
          type: 'success',
          onClose: () => {
            history.push('/Referral')
          },
        })
      })
      .catch((error) => {
        setSubmitting(false)
        alert.success(`Error ${error.message}`, {
          type: 'error',
        })
      })
  }
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    let isComponentMounted = true
    const fetchData = async () =>
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/Referrals/ReferralsById?Id=${
            location.state.referral !== undefined ? location.state.referral : 0
          }`,
          { headers }
        )
        .then((response) => {
          console.log('response.data: ', response.data)
          if (isComponentMounted) {
            // setUsersData(response.data);
            fields.forEach((field) => setValue(field, response.data[field]))
            /*  const petList = Object.entries(response.data).map(([key, value]) => {
              console.log("Key: ", key, value);
              register(`${key}`, `${value}`);
              return [key, value];
            }); */
          }
        })
        .catch((error) => {
          console.error('There was an error!', error)
        })
    fetchData()
    return () => {
      isComponentMounted = false
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [])
  return (
    <>
      {submitting ? (
        <CircularProgress
          style={{
            width: '50px',
            height: '50px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginLeft: '-25px',
            marginTop: '-25px',
            zIndex: 1000,
            color: '#FCBE13',
          }}
        />
      ) : null}
      <Grid
        container
        spacing={1}
        // alignContent="center"
        // justifyContent="center"
        // style={{
        //   paddingTop: '10px',
        //   paddingLeft: '150px',
        //   paddingRight: '150px',
        // }}
      >
          <Grid item xs={12} style={{ width: '300px' }}>
            <SuiTypography component="label" variant="caption" fontSize="18px" style={{fontWeight:'bold'}}>
              Source:
            </SuiTypography>
          </Grid>
        <Grid
          container
          spacing={1}
          style={{
            // width: '100%',
            marginBottom: '10px',
            border: '1px solid rgba(5, 152, 236, 0.637)',
            borderRadius: '20px',
            marginTop: '10px',
            paddingBottom: '20px',
            marginLeft: '5px',
            paddingRight: '10px',
          }}
        >
           
          <Grid item xs={2} style={{ width: '300px' }}>
            <SuiTypography component="label" variant="caption" fontSize="15px">
              Name
            </SuiTypography>
          </Grid>
          <Grid item xs={4} style={{ width: '300px' }}>
            <SuiInput
              placeholder="Please enter Full Name"
              name="fullName"
              label="fullName"
              fullWidth
              margin="dense"
              {...register('name')}
              error={errors.name}
            />
            <p style={{ color: 'red', fontSize: '14px' }}>
              {errors.name?.message}
            </p>
          </Grid>
          <Grid item xs={6} style={{ width: '300px' }}>
          </Grid>
          <Grid item xs={2} style={{ width: '300px' }}>
            <SuiTypography component="label" variant="caption" fontSize="15px">
              Fax
            </SuiTypography>
          </Grid>
          <Grid item xs={4} style={{ width: '300px' }}>
            <SuiInput
              placeholder="Please enter Fax Number"
              name="fullName"
              label="fullName"
              fullWidth
              margin="dense"
              {...register('fax')}
             
            />
          </Grid>
          <Grid item xs={6} style={{ width: '300px' }}>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ width: '300px' }}>
            <SuiTypography component="label" variant="caption" fontSize="18px" style={{fontWeight:'bold'}}>
              TransferTo:
            </SuiTypography>
          </Grid>
        <Grid
          container
          spacing={1}
          style={{
            // width: '100%',
            marginBottom: '10px',
            border: '1px solid rgba(5, 152, 236, 0.637)',
            borderRadius: '20px',
            marginTop: '10px',
            paddingBottom: '20px',
            marginLeft: '5px',
            paddingRight: '10px',
          }}
        >
           
          <Grid item xs={2} style={{ width: '300px' }}>
            <SuiTypography component="label" variant="caption" fontSize="15px">
              Provider
            </SuiTypography>
          </Grid>
          <Grid item xs={4} style={{ width: '300px' }}>
          <Controller
            name="providerId"
            {...register('providerId')}
            control={control}
            render={({ field }) => (
              <SuiSelect
                isClearable
                {...field}
                default
                value={providersRedux.filter(function (option) {
                  return option.value === getValues('providerId')
                })}
                onChange={(value) => {
                  setValue('providerId', value.value)
                }}
                options={providersRedux}
              />
            )}
          />
          </Grid>
          <Grid item xs={6} style={{ width: '300px' }}>
          </Grid>
         
        </Grid>
        <Grid item xs={12} lg={12} sm={12}>
        <Button
          onClick={handleCancel}
          variant="gradient"
          disabled={submitting}
          style={{
            marginTop: '30px',
            marginBottom: '10px',
            float: 'right',
            marginLeft: '20px',
            width: '90px',
            height: '35px',
            backgroundColor: '#FCBE13',
            color: 'black',
            fontSize: '14px',
          }}
          // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
        >
          <CancelOutlinedIcon
            fontSize="medium"
            style={{ color: 'red', paddingRight: '5px' }}
          ></CancelOutlinedIcon>
          Cancel
        </Button>

        <Button
          onClick={handleSubmit(onSubmit)}
          variant="gradient"
          disabled={submitting}
          style={{
            marginTop: '30px',
            marginBottom: '10px',
            float: 'right',
            marginLeft: 'auto',
            width: '80px',
            height: '35px',
            backgroundColor: '#FCBE13',
            color: 'black',
            fontSize: '14px',
          }}
          // color="warning"
          // size="small"

          // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
        >
          <SaveOutlinedIcon
            fontSize="medium"
            style={{
              color: '#1a82ad',
              paddingRight: '5px',
              paddingBottom: '2px',
            }}
          ></SaveOutlinedIcon>
          Save
        </Button>
      </Grid>
      </Grid>
    </>
  )
}

export default ReferralRegistration
