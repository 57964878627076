import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import { Grid, TextField, Button, Box } from '@mui/material';
import { useAlert } from 'react-alert'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Backdrop } from '@mui/material';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SuiButton from 'components/SuiButton';
import EditIcon from '@mui/icons-material/Edit'
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
// Define the validation schema with Yup
const validationSchema = Yup.object().shape({
  //   updatedDate: Yup.date().required('Updated Date is required'),
  //   updatedBy: Yup.string().required('Updated By is required'),
  //   createdBy: Yup.string().required('Created By is required'),
  //   isActive: Yup.boolean().required('Is Active is required'),
  //   parentId: Yup.string().required('Parent ID is required'),
  //   practiceId: Yup.string().required(' ID is required'),
  name: Yup.string().required('Name is required'), // New field
  nodesvalues: Yup.string().required('values is required'), // New field

  //   createdDate: Yup.date().required('Created Date is required') // New field
});

// The form component
const MyForm = (props) => {

  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema)
  });
  const alert = useAlert()

  const [refresh, setRefresh] = useState(false);
  const [submitting, setSubmitting] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const applyRefresh = () => {
    setRefresh(!refresh);
  };

  const parentId = props.parentId
  const nodeId = props.nodeId
  const [data, setData] = useState({}); // Initialize with appropriate initial state
  const [UpdateData, setUpdateData] = useState({}); // Initialize with appropriate initial state



  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // console.log(`User entered value for ${name}: ${value}`);
    // Update UpdateData state if needed
    setUpdateData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  const onSubmit = async (data) => {
    try {
      const token = localStorage.getItem('dtt_token');
      const postData = {
        ...data,
        name: data.name || props.name,
        id: nodeId,
        createdBy: props.createdBy, // Including createdDate from the form data
        updatedBy: props.updatedBy, // Including createdDate from the form data
        isActive: props.isActive, // Including createdDate from the form data
        practiceId: props.practiceId, // Including createdDate from the form data
        values: data.nodesvalues !== undefined ? data.nodesvalues : props.nodesvalues,
        parentId: parentId,

      };

      const response = await axios.post(
        'https://dttapis.devforhealth.com/api/Modules/AddModules',
        postData,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      // console.log('Submission successful:', response.data);
      props.applyRefresh()
      // setRefresh(!refresh)
      alert.success(' Record Added successfully.', {
        type: 'success',
        //     onClose: () => {
        //       //  history.push('/PatientSearch')
        //  },
      })
      props.handleClose()
    } catch (error) {
      console.error('Submission failed:', error);
    }
  };

  const onSubmitUpdate = async (data) => {
    try {
      const token = localStorage.getItem('dtt_token');
      const postData = {
        ...data,
        name: data.name || props.name,
        id: nodeId,
        practiceId: localStorage.getItem('dtt_user_default_practice'), // Including createdDate from the form data
        values: data.nodesvalues !== undefined ? data.nodesvalues : props.nodesvalues,
        parentId: parentId,

      };

      const response = await axios.post(
        'https://dttapis.devforhealth.com/api/Modules/AddModules',
        postData,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      // console.log('Submission successful:', response.data);
      props.applyRefresh()
      
      alert.success(' Record Updated successfully.', {
        type: 'success',
        //     onClose: () => {
        //       //  history.push('/PatientSearch')
        //  },
      })
      props.handleClose()
    } catch (error) {
      console.error('Submission failed:', error);
    }
  };


  const onDelete = async () => {
    try {
      const token = localStorage.getItem('dtt_token');
      const postData = {
        ...data,
        name: data.name || props.name,
        id: nodeId,
        isActive: true,

        practiceId: localStorage.getItem('dtt_user_default_practice'), // Including createdDate from the form data
        values: data.nodesvalues !== undefined ? data.nodesvalues : props.nodesvalues,
        parentId: parentId,

      };

      const response = await axios.post(
        'https://dttapis.devforhealth.com/api/Modules/AddModules',
        postData,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      console.log('Submission successful:', response.data);
      setRefresh(!refresh)
      props.applyRefresh()
      alert.success(' Record Deleted successfully.', {
        type: 'success',
        //     onClose: () => {
        //       //  history.push('/PatientSearch')
        //  },
      })
      props.handleClose()
    } catch (error) {
      console.error('Submission failed:', error);
    }
  };



  return (
    <>
      <CancelOutlinedIcon color="primary" onClick={() => props.handleClose()} style={{ float: 'right', marginTop: '-0.8rem', marginRight: '-1.7rem' }} />

      <form onSubmit={props.update == 'update' ? handleSubmit(onSubmitUpdate) : handleSubmit(onSubmit)}>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box mb={2}>
              <label htmlFor="name">Name</label>
              <Controller
                name="name"
                control={control}
                defaultValue={props.name || ''}
                render={({ field }) => (
                  <TextField id="name" fullWidth {...field} error={Boolean(errors.name)} helperText={errors.name ? 'Name is required' : ''} />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box mb={2}>
              <label htmlFor="nodesvalues">Value</label>
              <Controller
                name="nodesvalues"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="nodesvalues"
                    fullWidth
                    {...field}
                    value={field.value}
                    placeholder={props.nodesvalues === 'string' ? 'Enter value' : props.nodesvalues}
                    error={Boolean(errors.nodesvalues)}
                    helperText={errors.nodesvalues ? 'Values is required' : ''}
                  />
                )}
              />

            </Box>
          </Grid>


          <Grid container alignItems="center">
            <Grid item xs={2}>
            </Grid>
            <Grid item xs={2.5}>
              <Box mt={1.5} display="flex" color="primary" alignItems="center" style={{
                color: '#A574FD', marginTop: '0.1rem'
              }}>
                
         
                <Button onClick={() => props.handleClose()}>
                <ClearIcon color="primary" style={{ marginLeft: '0.2rem',
                color: '#A574FD',
                // marginRight: '-4px',
                paddingLeft: '-4px',
                           }} />
                <span style={{marginLeft:'5px'}}> Cancel</span>  
                </Button>
              </Box>
            </Grid>
            {/* <Grid item xs={0.3}></Grid> */}


            {props.update ? (<>    <Grid item xs={3.8}>
              <SuiButton type="submit"
                style={{ width: '5rem', marginLeft: '0.9rem', height: '30px', color: 'white', paddingRight: '31px' }}
                size="small"
                variant="gradient"
                color="warning"

              >  <SaveOutlinedIcon
              // fontSize="medium"
              style={{

                width: '18px',
                height: '14px',
                color: 'white',
                // paddingRight: '-6px',
                // paddingBottom: '2px',
              }}
            />Update
              </SuiButton>

            </Grid>
              <Grid item xs={1} color="red">


              <>









<DeleteIcon
  style={{
    marginRight: '3px',
    float: 'right',
    marginTop: '5px',
    color: 'red',
    marginLeft: '4px',
  }}
  color="red"
  onClick={() => setIsDialogOpen(true)}
></DeleteIcon>

<Dialog
  open={isDialogOpen}
  onClose={() => setIsDialogOpen(false)}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
  BackdropComponent={Backdrop}
  BackdropProps={{
    invisible: true,
  }}
  PaperProps={{
    style: {
      background: '#cef5f0',
      boxShadow: 'none',
      borderRadius: '8px'
    },
  }}
>
  <div style={{ borderRadius: '8px', width: '22rem', }}>

    <ErrorOutlineIcon style={{ marginLeft: '125px', fontSize: '100px', color: 'orange' }}></ErrorOutlineIcon>
    <DialogTitle style={{ marginLeft: '1rem', fontWeight: 'bold' }}>{"Do you want to delete the record?"}</DialogTitle>
    <h5 style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'normal', marginLeft: '-2rem' }}>
      This action cannot be undone.
    </h5>
    <DialogContent>
      <DialogContentText>
        <Grid style={{ marginLeft: '90px' }}>
          <Button onClick={() => onDelete()} style={{ backgroundColor: 'red', color: 'white' }}>YES</Button>
          <Button onClick={() => setIsDialogOpen(false)} style={{ backgroundColor: 'blue', color: 'white', marginLeft: '1rem' }}>NO</Button>
        </Grid>
      </DialogContentText>
    </DialogContent>
  </div>
</Dialog>
</>


              </Grid>  </>

            ) : (<>
              <Grid item xs={3.5}>
                <SuiButton type="submit"
                  style={{ width: '29px', marginLeft: '0.9rem', height: '30px', color: 'white' }}
                  size="medium"
                  variant="gradient"
                  color="warning"

                >
                  
                  <SaveOutlinedIcon
                      fontSize="medium"
                      style={{

                        width: '18px',
                        height: '14px',
                        color: 'white',
                        paddingRight: '-4px',
                        paddingBottom: '2px',
                      }}
                    />Save
                </SuiButton>

              </Grid>
              <Grid item xs={1}>


                <>








{/* 
                  <DeleteIcon
                    style={{
                      marginRight: '3px',
                      float: 'right',
                      marginTop: '5px',
                      color: 'red',
                      marginLeft: '4px',
                    }}
                    color="red"
                    onClick={() => setIsDialogOpen(true)}
                  ></DeleteIcon>

                  <Dialog
                    open={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      invisible: true,
                    }}
                    PaperProps={{
                      style: {
                        background: '#cef5f0',
                        boxShadow: 'none',
                        borderRadius: '8px'
                      },
                    }}
                  >
                    <div style={{ borderRadius: '8px', width: '22rem', }}>

                      <ErrorOutlineIcon style={{ marginLeft: '125px', fontSize: '100px', color: 'orange' }}></ErrorOutlineIcon>
                      <DialogTitle style={{ marginLeft: '1rem', fontWeight: 'bold' }}>{"Do you want to delete the record?"}</DialogTitle>
                      <h5 style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'normal', marginLeft: '-2rem' }}>
                        This action cannot be undone.
                      </h5>
                      <DialogContent>
                        <DialogContentText>
                          <Grid style={{ marginLeft: '90px' }}>
                            <Button onClick={() => onDelete()} style={{ backgroundColor: 'red', color: 'white' }}>YES</Button>
                            <Button onClick={() => setIsDialogOpen(false)} style={{ backgroundColor: 'blue', color: 'white', marginLeft: '1rem' }}>NO</Button>
                          </Grid>
                        </DialogContentText>
                      </DialogContent>
                    </div>
                  </Dialog> */}
                </>

              </Grid>
            </>
            )}





          </Grid>


        </Grid>
      </form>
    </>

  );
};

export default MyForm;
