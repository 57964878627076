// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SaveIcon from "assets/save.png";
import CancelIcon from "assets/cancel.png";
import SuiSelect from "components/SuiSelect";
import Stack from "@mui/material/Stack";
import InputMask from "react-input-mask";
// import Select from '@mui/material/Select'
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { useAlert } from "react-alert";
import { DataGrid } from "@mui/x-data-grid";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import input from "@mui/material/Input";
import Moment from "moment";
import FaxAppointmentCreate from "./FaxAppointmentCreate ";
import Avatar from "@mui/material/Avatar";
import Modal from "@mui/material/Modal";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import PatientCareTeam from "./PatientCareTeam";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  CircularProgress,
  Grid,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, {
  useEffect,
  useReducer,
  useState,
  Fragment,
  useRef,
} from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { createBrowserHistory } from "history";
import Checkbox from "@mui/material/Checkbox";
import { useLocation, Link } from "react-router-dom";
// import ReactSelect from 'react-select'
import { Controller, useForm } from "react-hook-form";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PermIdentity from "@mui/icons-material/PermIdentity";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import WorkOutline from "@mui/icons-material/WorkOutline";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import FormControl from "@mui/material/FormControl";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import PatientNotes from "./PatientNotes";
import CurrencyInput from "react-currency-input-field";
import { makeStyles } from "@material-ui/core/styles";

// import Autocomplete from '@material-ui/lab/Autocomplete'
// import Autocomplete from '@mui/material/Autocomplete'
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Timelog from "./Timelog";
import { flexbox } from "@mui/system";
import { styled } from "@mui/material/styles";
import ReactRoundedImage from "react-rounded-image";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import Button from "@mui/material/Button";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CleaningServicesOutlinedIcon from "@mui/icons-material/CleaningServicesOutlined";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import { parse, isDate } from "date-fns";

const history = createBrowserHistory({ forceRefresh: true });
const Input = styled("input")({
  display: "none",
});
var providersRedux = [];
var facilitiesRedux = [];
function Demographics(props) {   
  const [receivedFaxId, setReceivedFaxId] = useState(props.receivedId != undefined ? props.receivedId:0)
  console.log('receivedFaxId:', receivedFaxId); 
 
  function handleCancel() {
    if (props.cancelmodel) {
      props.cancelmodel(false);
    } else {
      history.push("/PatientSearch");
    }
  }

  const [showFaxAppointmentCreate, setShowFaxAppointmentCreate] = useState(
    false
  );
  const [showButton, setShowButton] = useState(false);
  const [patientId, setpatientId] = useState(0);
  const [patientLName, setpatientLName] = useState(0);
  const [patientFName, setpatientFName] = useState(0);
  const [providerId, setproviderId] = useState(0);
  
  
  const userProviderId = localStorage.getItem("dtt_user_provider_id");
  const focusedColor = "blue";
  const useStyles = makeStyles({
    container: {
      width: 465,
      // height: "100%",
      height: 700,
      backgroundColor: "white",
      position: "absolute",
      // display: "flex",
      top: 0,
      left: 900,
      // left: 300,
      bottom: 0,
      right: 0,
      overflow: "auto",
      padding: "10px",
      marginLeft: 0,
      // zIndex: "1",
      // margin: 'auto',
      // [theme.breakpoints.down('sm')]: {
      //   width: '100vw',
      //   // height: '100vh',
      // },
    },
    root: {
      // input label when focused
      "& label.i-focused": {
        color: focusedColor,
      },
      // focused color for input with variant='standard'
      "& .MuiInput-underline:after": {
        borderBottomColor: focusedColor,
      },
      // focused color for input with variant='filled'
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: focusedColor,
      },
      // focused color for input with variant='outlined'
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: focusedColor,
        },
      },
    },
    readhide: {
      color: "blue",
      cursor: "pointer",
    },
  });
  const gridStyle = {
    color: "#8392ab !important",
    textTransform: "lowercase",
    fontSize: "13.6px",
    fontWeight: "400",
    "&:hover": {
      color: "rgba(5, 152, 236, 0.637) !important",
      fontSize: "14.6px",
    },
  };
  const zipCodeListStyle = {
    cursor: "pointer",
    "&:hover": {
      background: "#3b3b3b !important",
      color: "rgba(5, 152, 236, 0.637) !important",
    },
  };

  // const styleDetailsPdf = {
  //   width: 460,
  //   // height: '1000',
  //   bgcolor: '#F0FFFF',
  //   position: 'absolute',
  //   top: 0,
  //   left: "5px",
  //   bottom: 30,
  //   right: "5px",
  //   marginTop: '0',
  //   paddingBottom: '10px',
  //   padding: '0px',
  //   overflow: 'auto',
  //   zIndex: '10',
  //   // tabIndex: "-1",
  // };
  const classes = useStyles();
  const location = useLocation();
  // const _patientId = props.patientId;
  const readOnly = props.readOnly;
  const dtt_userType = localStorage.getItem("dtt_userType");
  const [loadingTime, setloadingTime] = useState(0);
  const alert = useAlert();
  const [insuranceNames, setinsuranceNames] = React.useState([]);
  const [openInsurances, setOpenInsurances] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [zipCodeList, setZipCodeList] = React.useState([]);
  const [isZipCode, setIsZipCode] = React.useState(false);
  const [zipDownloaded, setzipDownloaded] = useState(false);
  const [tabValueIns, settabValueIns] = React.useState(0);
  const [valueInsName, setValueInsName] = React.useState(null);
  const [valueSecInsName, setValueSecInsName] = React.useState(null);
  const [submitting, setSubmitting] = useState(false);
  const auth = `Bearer  ${localStorage.getItem("dtt_token")}`;
  const psychotherapistPriviledge = `${localStorage.getItem(
    "dtt_isPsychotherapyPriviledges"
  )}`;
  const headers = {
    Authorization: auth,
  };

  const containerStyle = {
    zIndex: 100 // Set the desired z-index value here
  };

  const loading = open && insuranceNames.length === 0;
  const handleChangeInsurances = (event, newValue) => {
    settabValueIns(newValue);
  };
  const onChangeHandle = async (value) => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    // console.log(value)

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Settings/Searchcitystate?zipcode=${value}`,
        { headers }
      )
      .then((response) => {
        setzipDownloaded(false);
        setZipCodeList(response.data);
        setIsZipCode(true);
        setinsuranceNames(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  useEffect(() => {
    if (patientId !== undefined && patientId != null) {
      // POST request using axios inside useEffect React hook
      let isComponentMounted = true;
      const fetchData = () =>
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/Patients/getPatientById?id=${
              patientId !== undefined ? patientId : 0
            }`,
            {
              headers,
            }
          )
          .then((response) => {
            // console.log('response getPatID: ', response.data)
            console.log("response.data: ", response.data);
            var ins = [];
            resetAfterAPICall(response.data);
            if (
              isComponentMounted &&
              response.data != null &&
              response.data.id > 0
            ) {
              //   setSmokingStatusRes(response.data.smokingStatus)
            }
            setzipDownloaded(true);
            setPhone(response.data.homePhone);
            setcellPhone(response.data.cellPhone);
            setworkPhone(response.data.workPhone);
            setssn(response.data.ssn);
            // setdob(response.data.dob)
            var dateYMD = Moment(response.data.dob).format("YYYY-MM-DD");
            setdob(dateYMD);
            // console.log('dateYMD', dateYMD)
            if (response.data.photoUrl) {
              // console.log('URL: ',`${process.env.REACT_APP_DOCUMENT_URL}`)
              var Url = `${process.env.REACT_APP_DOCUMENT_URL}/${response.data.photoUrl}`;
              // console.log('URL: ',Url)
              setImages(Url);
            }
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      fetchData();
      return () => {
        isComponentMounted = false;
      };
    } else {
      setValue("status", "Active");
      setValue("providerId", "");
    }
  }, []);
  const facilitiesRedx = useSelector(
    (state) => (state.facilities.loading ? [] : state.facilities.NAMES),
    shallowEqual
  );
  const [images, setImages] = React.useState(null);
  /// Delete Code
  const deleteimg = () => {
    setImages(null);
    setValue("photoUrl", "");
  };
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    facilitiesRedux = [];
    facilitiesRedx.forEach((field) => {
      facilitiesRedux.push({
        label: field.name,
        value: field.id,
      });
    });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [{ facilitiesRedx }]);
  function resetAfterAPICall(value) {
    var data = {
      id: value.id,
      accountNumber: value.accountNumber,
      lastName: value.lastName,
      firstName: value.firstName,
      midInitial: value.midInitial,
      title: value.title,
      alias: value.alias,
      ssn: value.ssn,
      dob: value.dob,
      gender: value.gender,
      status: value.status,
      cellNo: value.cellNo,
      language: value.language,
      maritalStatus: value.maritalStatus,
      homePhone: value.homePhone,
      cellPhone: value.cellPhone,
      workPhone: value.workPhone,
      emailAddress: value.emailAddress,
      city: value.city,
      state: value.state,
      zipCode: value.zipCode,
      address: value.address,
      photoUrl: value.photoUrl,
      program: value.program,
      smokingStatus: value.smokingStatus,
      comments: value.comments,
      practiceId: value.practiceId,
      facilityId: value.facilityId,
      careTeam_id: value.careTeam_id,
      providerId: value.providerId,
      isActive: value.isActive,
      updatedBy: value.updatedBy,
      updatedDate: value.updatedDate,
      createdBy: value.createdBy,
      createdDate: value.createdDate,
      base64: value.base64,
      priIns: {},
      secIns: {},
    };
    if (
      value.priIns !== undefined &&
      value.priIns !== null &&
      value.priIns.id > 0
    ) {
      var _priIns = {
        id: value.priIns.id,
        insuranceId: value.priIns.insuranceId,
        insuranceName: value.priIns.insuranceName,
        patientId: value.priIns.patientId,
        groupId: value.priIns.groupId,
        memberId: value.priIns.memberId,
        practiceId: value.priIns.practiceId,
        payerId: value.priIns.payerId,
        type: value.priIns.typerimary,
        copay: value.priIns.copay,
        deductible: value.priIns.deductible,
        subsRelation: value.priIns.subsRelation,
        subsFirtName: value.priIns.subsFirtName,
        subsLastName: value.priIns.subsLastName,
        guarranterName: value.priIns.guarranterName,
        guarantorAddress: value.priIns.guarantorAddress,
        guarantorSuite: value.priIns.guarantorSuite,
        guarantorCity: value.priIns.guarantorCity,
        guarantorState: value.priIns.guarantorState,
        guarantorZip: value.priIns.guarantorZip,
        inactive: value.priIns.inactive,
        startDate: Moment(value.priIns.startDate).format("YYYY-MM-DD"),
        endDate: Moment(value.priIns.endDate).format("YYYY-MM-DD"),
        createdDate: value.priIns.createdDate,
        updatedDate: value.priIns.updatedDate,
        createdBy: value.priIns.createdBy,
        updatedBy: value.priIns.updatedBy,
        clientCreatedDate: value.priIns.clientCreatedDate,
        clientUpdatedDate: value.priIns.clientUpdatedDate,
      };
      data.priIns = _priIns;
    } else {
      data.priIns = defaultValues.priIns;
    }
    if (
      value.secIns != undefined &&
      value.secIns != null &&
      value.secIns.id > 0
    ) {
      var _secIns = {
        id: value.secIns.id,
        insuranceId: value.secIns.insuranceId,
        insuranceName: value.secIns.insuranceName,
        patientId: value.secIns.patientId,
        groupId: value.secIns.groupId,
        memberId: value.secIns.memberId,
        practiceId: value.secIns.practiceId,
        payerId: value.secIns.payerId,
        type: value.secIns.type,
        copay: value.secIns.copay,
        deductible: value.secIns.deductible,
        subsRelation: value.secIns.subsRelation,
        subsFirtName: value.secIns.subsFirtName,
        subsLastName: value.secIns.subsLastName,
        guarranterName: value.secIns.guarranterName,
        guarantorAddress: value.secIns.guarantorAddress,
        guarantorSuite: value.secIns.guarantorSuite,
        guarantorCity: value.secIns.guarantorCity,
        guarantorState: value.secIns.guarantorState,
        guarantorZip: value.secIns.guarantorZip,
        inactive: value.secIns.inactive,
        startDate: Moment(value.secIns.startDate).format("YYYY-MM-DD"),
        endDate: Moment(value.secIns.endDate).format("YYYY-MM-DD"),
        createdDate: value.secIns.createdDate,
        updatedDate: value.secIns.updatedDate,
        createdBy: value.secIns.createdBy,
        updatedBy: value.secIns.updatedBy,
        clientCreatedDate: value.secIns.clientCreatedDate,
        clientUpdatedDate: value.secIns.clientUpdatedDate,
      };
      data.secIns = _secIns;
    } else {
      data.secIns = defaultValues.secIns;
    }
    const deepCopy = JSON.parse(JSON.stringify(data));
    reset(deepCopy);
  }
  const onSubmit = (data) => {
    // console.log('submit',data.dob)
    // const ddob=(data.dob).toISOString();
    //  console.log('afterc', data.dob)
    if (data.dob != undefined && data.dob != null)
    // data.dob = new Date(data.dob).toISOString().split('T')[0];
      data.dob = data.dob.toLocaleString();
    //  console.log('submit',data)
    setApiError("");
    setSubmitting(true);
    const postData = data;
    //postData.dob = data.dob ? data.dob.value : ''
    if (patientId !== undefined && patientId != null)
      postData.id = patientId;
    else postData.id = 0;

    axios
      .post(`${process.env.REACT_APP_API_URL}/Patients/addPatient`, postData, {
        headers,
      })
      .then((response) => {
        if (response.data.id) {
          alert.success("Record saved successfully.", {
            type: "success",
            // containerStyle: containerStyle
          });
          setShowButton(true);

          setpatientId(response.data.id); 
          setpatientLName(response.data.lastName)
          setpatientFName(response.data.firstName)
          setproviderId(response.data.providerId)

          console.log('Patient Lname is ', response.data.lastName)
          console.log('Patient Fname is ', response.data.firstName)


          axios
          .get(`${process.env.REACT_APP_API_URL}/Faxes/MarkFaxes?id=${receivedFaxId}&patientCreated=true&patientId=${response.data.id}`,
          {
            headers,
          })
          .then((response) => {
            console.log(response.data); 
          })
          .catch((error) => {
            console.error(error); 
          });
          
        } else {
          setSubmitting(false);
          setApiError(response.data);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        alert.success(`Error ${error.message}`, {
          type: "error",
        });
      });
  };
  //
  const onchangeinsurce = async (value) => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    console.log("getInsuranceByName: ", value);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Insurances/getInsuranceByName?Name=${value}`,
        { headers }
      )
      .then((response) => {
        setOpenInsurances(true);
        setinsuranceNames(response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  const [patientinfo, setpatientinfo] = useState([]);
  const [dob, setdob] = React.useState("");
  const [phone, setPhone] = React.useState();
  const [cellPhone, setcellPhone] = React.useState();
  const [workPhone, setworkPhone] = React.useState();
  const [ssn, setssn] = React.useState();
  const [apiError, setApiError] = useState("");
  const [isReadMore, setIsReadMore] = useState(
    props.cancelmodel ? true : false
  );

  const handleOpenn = () => {
    console.log("Button clicked");
    setShowFaxAppointmentCreate(true);
    // setShowFaxDemographics(true);
  };
  const handleClosee = () => {
    console.log("Button clicked");
    setShowFaxAppointmentCreate(false);
    // setShowFaxDemographics(false);
  };

  const ReadMore = () => {
    // const text = children
    if (props.cancelmodel) {
      return (
        <p>
          {/* {isReadMore ? text : text} */}
          {/* {text.length > 150 ? ( */}
          <span
            onClick={() => {
              setIsReadMore(!isReadMore);
            }}
            className={classes.readhide}
          >
            {isReadMore ? (
              <Button
                sx={{ mb: 2 }}
                variant="gradient"
                size="small"
                style={{
                  padding: "4px",
                  // float: 'left',
                  height: "33px",
                  marginTop: "10px",
                  // backgroundColor: '#FCBE13',
                  color: "black",
                  fontSize: "14px",
                }}
                // color="warning"
                // size="small"

                // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
              >
                <ExpandMoreIcon
                  fontSize="medium"
                  style={{
                    color: "#1a82ad",
                    // paddingRight: '4px',
                    paddingBottom: "2px",
                  }}
                ></ExpandMoreIcon>
                Show More
              </Button>
            ) : (
              <Button
                sx={{ mb: 2 }}
                variant="gradient"
                size="small"
                style={{
                  padding: "4px",
                  // float: 'left',
                  height: "33px",
                  marginTop: "10px",
                  // backgroundColor: '#FCBE13',
                  color: "black",
                  fontSize: "14px",
                }}
                // color="warning"
                // size="small"

                // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
              >
                <ExpandLessIcon
                  fontSize="medium"
                  style={{
                    color: "#1a82ad",
                    // paddingRight: '4px',
                    paddingBottom: "2px",
                  }}
                ></ExpandLessIcon>
                Show Less
              </Button>
            )}
          </span>
          {/* ) : null} */}
        </p>
      );
    }
  };
  const today = new Date();
  var startDate = new Date();
  startDate.setFullYear(startDate.getFullYear() - 100);
  const validationSchema = Yup.object({
    lastName: Yup.string().required("Last Name is required"),
    firstName: Yup.string().required("First Name is required"),
    providerId: Yup.string().required("Please Select Provider"),
    program: Yup.string().required("Please Select Program"),

    // ssn: Yup.string().required('Social Security number is required'),
    // accountNumber: Yup.string().required('Account # is required'),
    dob: Yup.date()
      .required("DoB is required")
      .nullable()
      .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
      .max(today, "You can't be born in the future!")
      .min(startDate, "You can't be that much old!"),
    // dob: Yup.string().dob('dob is required'),
    // cellPhone : Yup.string() .required('Cell phone is required'),
    // emailAddress: Yup.string()
    //   .required('Email is required')
    //   .email('Email is invalid'),
    //  insuranceId: Yup.string().required('Insurance is required'),
  });
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
  });
  var defaultValues = {
    priIns: {
      id: 0,
      insuranceId: "",
      patientId: "",
      groupId: "",
      memberId: "",
      practiceId: "",
      payerId: "",
      type: "primary",
      copay: "",
      deductible: "",
      subsRelation: "",
      subsFirtName: "",
      subsLastName: "",
      guarranterName: "",
      guarantorAddress: "",
      guarantorSuite: "",
      guarantorCity: "",
      guarantorState: "",
      guarantorZip: "",
      inactive: "",
      startDate: "",
      endDate: "",
      createdDate: "",
      updatedDate: "",
      createdBy: "",
      updatedBy: "",
      clientCreatedDate: "",
      clientUpdatedDate: "",
    },
    id: "",
    accountNumber: "",
    lastName: "",
    firstName: "",
    midInitial: "",
    title: "",
    alias: "",
    ssn: "",
    dob: "",
    gender: "",
    status: "",
    cellNo: "",
    language: "",
    maritalStatus: "",
    homePhone: "",
    cellPhone: "",
    workPhone: "",
    emailAddress: "",
    city: "",
    state: "",
    zipCode: "",
    address: "",
    photoUrl: "",
    program: "",
    smokingStatus: "",
    comments: "",
    practiceId: "",
    facilityId: "",
    providerId: "",
    isActive: "",
    updatedBy: "",
    updatedDate: "",
    createdBy: "",
    createdDate: "",
    base64: "",
    secIns: {
      id: 0,
      insuranceId: "",
      patientId: "",
      groupId: "",
      memberId: "",
      practiceId: "",
      payerId: "",
      type: "secondary",
      copay: "",
      deductible: "",
      subsRelation: "",
      subsFirtName: "",
      subsLastName: "",
      guarranterName: "",
      guarantorAddress: "",
      guarantorSuite: "",
      guarantorCity: "",
      guarantorState: "",
      guarantorZip: "",
      inactive: "",
      startDate: "",
      endDate: "",
      createdDate: "",
      updatedDate: "",
      createdBy: "",
      updatedBy: "",
      clientCreatedDate: "",
      clientUpdatedDate: "",
    },
  };
  const providersRedx = useSelector(
    (state) => (state.providers.loading ? [] : state.providers.NAMES),
    shallowEqual
  );

  /// Add Image
  const onImageChange = (e) => {
    setImages(URL.createObjectURL(e.target.files[0]));
    const file = e.target.files[0];
    convertBase64(file);
    // console.log("64 image",base64)
    // console.log('Kdr Sy aya ha Console', URL.createObjectURL(e.target.files[0]))
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    }).then((users) => {
      setValue("base64", users);
    });
  };
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    providersRedux = [];
    // console.log('reduxxx', providersRedx)
    providersRedx.forEach((field) => {
      providersRedux.push({
        label: field.name,
        value: field.id,
      });
    });
   
  });

  return (
    <>
     <Grid className={classes.container}>
      <Box style={{overflow:'auto'}}>
     {/* <Box sx={styleDetailsPdf}> */}
        <Grid
          container
          columnSpacing={1}
          alignContent="center"
          justifyContent="center"
        >
          <Grid xs={12}  
          style={{
                backgroundColor: "aqua",
              }}>
            <h3
             style={{
              backgroundColor: "Primary",
              marginLeft:"20px",
              marginTop: "20px",
              // backgroundColor: "blue",
            }}>
              ADD PATIENT
              <CancelOutlinedIcon
                onClick={props.onClose}
                variant="contained"
                style={{
                  float: "right",
                  color: "red",
                  marginRight:"10px",
                  marginTop:'6px',
                  // backgroundColor:"aqua",
                }}
              ></CancelOutlinedIcon>
            </h3>
          </Grid>
          <Grid xs={12}></Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
            <SuiTypography component="label" variant="caption" fontSize="15px">
              Last Name
            </SuiTypography>
          </Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
            <SuiTypography component="label" variant="caption" fontSize="15px">
              First Name
            </SuiTypography>
          </Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
            <SuiInput
              type="input"
              placeholder="Enter Last Name"
              {...register("lastName")}
              error={errors.lastName}
              // onChange={(e) => setFullName(e.target.value)}
            />
            <p style={{ color: "red", fontSize: "14px" }}>
              {errors.lastName?.message}
            </p>
          </Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
            <SuiInput
              type="input"
              placeholder="Enter First Name"
              {...register("firstName")}
              error={errors.firstName}

              // onChange={(e) => setFullName(e.target.value)}
            />
            <p style={{ color: "red", fontSize: "14px" }}>
              {errors.firstName?.message}
            </p>
          </Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
            <SuiTypography component="label" variant="caption" fontSize="15px">
              DOB
            </SuiTypography>
          </Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
            <SuiTypography component="label" variant="caption" fontSize="15px">
              Gender
            </SuiTypography>
          </Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
            <SuiInput
              type="date"
              placeholder="Enter dob"
              value={dob}
              onChange={(e) => {
                setValue("dob", e.target.value);
                setdob(e.target.value);
              }}
              error={errors.dob}
            />
            <p style={{ color: "red", fontSize: "14px" }}>
              {errors.dob?.message}
            </p>
          </Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
            <Controller
              name="gender"
              {...register("gender")}
              control={control}
              render={({ field }) => (
                <SuiSelect
                  value={{ label: getValues("gender") }}
                  onChange={(value) => setValue("gender", value.label)}
                  options={[
                    { value: "", label: "" },
                    { value: "Male", label: "Male" },
                    { value: "Female", label: "Female" },
                    { value: "Other", label: "Other" },
                  ]}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
            <SuiTypography component="label" variant="caption" fontSize="15px">
              Program
            </SuiTypography>
          </Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
            <SuiTypography component="label" variant="caption" fontSize="15px">
              Home Phone
            </SuiTypography>
          </Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
            <Controller
              name="program"
              {...register("program")}
              control={control}
              render={({ field }) => (
                <SuiSelect
                  {...field}
                  value={{ label: getValues("program") }}
                  onChange={(value) => setValue("program", value.label)}
                  options={[
                    { value: "", label: "" },
                    { value: "BHI", label: "BHI" },
                    { value: "CoCM", label: "CoCM" },
                  ]}
                  error={errors.program}
                />
              )}
            />
            <p style={{ color: "red", fontSize: "14px" }}>
              {errors.program?.message}
            </p>
          </Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
            <InputMask
              mask="+1 999-999-9999"
              disabled={false}
              value={phone}
              style={{ height: "30px" }}
              onChange={(e) => {
                setValue("homePhone", e.target.value);
                setPhone(e.target.value);
              }}
            >
              {() => (
                <SuiInput
                  id="outlined-basic"
                  fullWidth
                  margin="dense"
                  placeholder="Enter phoneNo"
                />
              )}
            </InputMask>
          </Grid>
          
          <Grid item xs={6} style={{ width: "300px" }}>
            <SuiTypography component="label" variant="caption" fontSize="15px">
              Email Address
            </SuiTypography>
          </Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
            <SuiTypography component="label" variant="caption" fontSize="15px">
              Cell Phone
            </SuiTypography>
          </Grid>

          <Grid item xs={6} style={{ width: "300px" }}>
          <SuiInput
                  type="email"
                  placeholder="Enter Email address"
                  {...register('emailAddress')}
                  // error={errors.emailAddress}
                />
                {/* <p style={{ color: 'red' , fontSize: "14px" }}>{errors.emailAddress?.message}</p> */}
          </Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
          <InputMask
            mask="+1 999-999-9999"
            disabled={false}
            value={cellPhone}
            style={{ height: '30px' }}
            onChange={(e) => {
              setValue('cellPhone', e.target.value)
              setcellPhone(e.target.value)
            }}
          >
            {() => (
              <SuiInput
                id="outlined-basic"
                fullWidth
                margin="dense"
                placeholder="Enter CellPhone No"
                // error={errors.cellPhone}
              />
            )}
          </InputMask>
          {/* <p style={{ color: 'red' , fontSize: "14px" }}>{errors.cellPhone?.message}</p> */}
          </Grid>


          <Grid item xs={6} style={{ width: "300px" }}>
            <SuiTypography component="label" variant="caption" fontSize="15px">
              Address
            </SuiTypography>
          </Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
            <SuiTypography component="label" variant="caption" fontSize="15px">
              Zip Code
            </SuiTypography>
          </Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
            <SuiInput
              type="input"
              placeholder="Enter address"
              {...register("address")}
            />
          </Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
            <SuiInput
              type="zipCode"
              placeholder="Enter ZipCode"
              {...register("zipCode")}
            />
          </Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
            <SuiTypography component="label" variant="caption" fontSize="15px">
              City
            </SuiTypography>
          </Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
            <SuiTypography component="label" variant="caption" fontSize="15px">
              State
            </SuiTypography>
          </Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
            <SuiInput
              type="input"
              // readOnly
              placeholder="Enter city"
              {...register("city")}
            />
          </Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
            <SuiInput
              type="input"
              // readOnly
              placeholder="Enter state"
              {...register("state")}
            />
          </Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
            <SuiTypography component="label" variant="caption" fontSize="15px">
              Provider
            </SuiTypography>
          </Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
            <SuiTypography component="label" variant="caption" fontSize="15px">
            Facilities
            </SuiTypography>
          </Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
            <Controller
              name="providerId"
              {...register("providerId")}
              control={control}
              render={({ field }) => (
                <SuiSelect
                  isClearable
                  {...field}
                  default
                  value={providersRedux.filter(function (option) {
                    return option.value === getValues("providerId");
                  })}
                  onChange={(value) => {
                    setValue("providerId", value.value);
                  }}
                  options={providersRedux}
                  error={errors.providerId}
                />
              )}
            />
            <p style={{ color: "red", fontSize: "14px" }}>
              {errors.providerId?.message}
            </p>
          </Grid>
          <Grid item xs={6} style={{ width: "300px" }}>
            <Controller
              name="facilityId"
              {...register("facilityId")}
              control={control}
              render={({ field }) => (
                <SuiSelect
                  placeholder="Select Facilities"
                  isClearable
                  {...field}
                  value={facilitiesRedux.filter(function (option) {
                    return option.value === getValues("facilityId");
                    // return (
                    //   option.value ===
                    //   (fetchedData ? getValues('facilityId') : -1)
                    // )
                  })}
                  options={facilitiesRedux}
                  onChange={(value) => {
                    setValue("facilityId", value.value);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} style={{ width: "300px" }}>
            <SuiTypography component="label" variant="caption" fontSize="15px">
              Comments
            </SuiTypography>
          </Grid>
          <Grid item xs={12}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={5}
              placeholder="Enter comments"
              style={{
                width: "100%",
                borderRadius: "8px",
                border: "2px solid #black",
                padding: "5px",
              }}
              {...register("comments")}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              // onClick={handleCancel}
              onClick={props.onClose}
              variant="gradient"
              disabled={submitting}
              style={{
                marginTop: "30px",
                marginBottom: "10px",
                float: "right",
                marginLeft: "30px",
                width: "90px",
                height: "35px",
                backgroundColor: "#FCBE13",
                color: "black",
                fontSize: "14px",
              }}
             
            >
              <CancelOutlinedIcon
                fontSize="medium"
                style={{ color: "red", paddingRight: "5px" }}
              ></CancelOutlinedIcon>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              onClick={handleSubmit(onSubmit)}
              variant="gradient"
              disabled={readOnly == true ? true : submitting}
              style={{
                marginTop: "30px",
                marginBottom: "10px",
                float: "left",
                marginLeft: "8px",
                width: "80px",
                height: "35px",
                backgroundColor: "#FCBE13",
                color: "black",
                fontSize: "14px",
              }}
              
            >
              <SaveOutlinedIcon
                fontSize="medium"
                style={{
                  color: "#1a82ad",
                  paddingRight: "5px",
                  paddingBottom: "2px",
                }}
              ></SaveOutlinedIcon>
              Save
            </Button>
          </Grid>
          <Grid item xs={7}>
          {showButton && (
            <Button
               onClick={showFaxAppointmentCreate ? handleClosee : handleOpenn}
              variant="gradient"
              // disabled={readOnly == true ? true : submitting}
              style={{
                marginTop: "30px",
                marginBottom: "10px",
                // float: "left",
                marginLeft: "25px",
                width: "230px",
                height: "35px",
                backgroundColor: "#FCBE13",
                color: "black",
                fontSize: "14px",
              }}
              
            >
               SCHEDULE APPOINTMENT
            </Button>
             )}
          </Grid>
        </Grid>
        </Box>
      </Grid>
          

       <Grid xs={4}>
             {showFaxAppointmentCreate && (
            <FaxAppointmentCreate onClose={handleClosee} 
            patientId={patientId} 
            receivedFaxId={receivedFaxId}
            patientFName={patientFName}
            patientLName={patientLName}
            providerId={providerId}
            showButton= {showButton}
            />
          )}
        </Grid>
    </>
  );
}

export default Demographics;
