import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, CircularProgress, Modal } from '@mui/material';
import MyFormRoles from './MyFormRoles';
import EditIcon from '@mui/icons-material/Edit';
import SuiButton from 'components/SuiButton';
import AddIcon from 'assets/add.png';

const useStyles = makeStyles({
  addButton: {
    marginLeft: '1rem',
    marginTop: '0.5rem',
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  itemName: {
    marginRight: '1rem',
    fontSize: '1.2rem',
  },
});
const fields = [

    'practiceId',

  ]
  const defValues = {

    practiceId: '',

  }
const Roles = () => {
  const classes = useStyles();

  const [treeData, setTreeData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [parentId, setParentId] = useState('');
  const [parentId1, setParentId1] = useState('');
  const [isActive, setisActive] = useState('');
  const [name, setName] = useState('');
  const [nodesvalues, setvalues] = useState('');
  const [practiceId, setpracticeId] = useState('');
  const [updatedBy, setupdatedBy] = useState('');
  const [createdBy, setcreatedBy] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [nodeId, setNodeId] = useState('');
  const [applyRefresh, setApplyRefresh] = useState(false);

  const handleClose = () => setOpen(false);
  const handleCloseEdit = () => setOpenEdit(false);

  useEffect(() => {
    fetchTreeData();
  }, [applyRefresh, refresh]);

  const fetchTreeData = async () => {
    setSubmitting(true);
    try {
        const token = localStorage.getItem('dtt_token');

      const practiceId = localStorage.getItem('dtt_user_default_practice');
      const response = await fetch(`https://dttapis.devforhealth.com/api/Modules/Roles?practiceId=${practiceId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch tree data');
      }
      const data = await response.json();
      setTreeData(data);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      console.error('Error fetching tree data:', error);
    }
  };

  const applyRefreshh = () => {
    setApplyRefresh(!applyRefresh);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px'
  };
  return (
    <>
      <Modal
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div><MyFormRoles createdBy={createdBy} update={'update'} applyRefresh={applyRefreshh} practiceId={practiceId} name={name} nodesvalues={nodesvalues} updatedBy={updatedBy} handleClose={handleCloseEdit} nodeId={nodeId} parentId={parentId} isActive={isActive} /></div>
        </Box>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div><MyFormRoles practiceId={practiceId} handleClose={handleClose} parentId={parentId1} applyRefresh={applyRefreshh} /></div>
        </Box>
      </Modal>
      {submitting ? (
        <CircularProgress
          style={{
            width: '50px',
            height: '50px',
            position: 'absolute',
            left: '50%',
            top: '70%',
            marginLeft: '-25px',
            marginTop: '25px',
            zIndex: 1000,
            color: '#FCBE13',
          }}
        />
      ) : null}
      <div>

      <SuiButton
  sx={{ mb: 5 }}
  onClick={() => {
    setOpen(true);
    if (Array.isArray(treeData) && treeData.length > 0) {
      const practiceId = treeData[0].practiceId; // Assuming practiceId is available in the first item of treeData
console.log(practiceId,'practiceIdpracticeId')  
setpracticeId(practiceId)  }
  }}
  size="small"
  style={{
    background: 'linear-gradient(45deg, #f88f33, #f88f33 50%, #eaaf6f, #eac86e)',
    float: 'right',
  }}
  startIcon={
    <img
      src={AddIcon}
      style={{ marginTop: '-3px', marginRight: '-6px' }}
    />
  }
>
  Add New
</SuiButton>

        {Array.isArray(treeData) &&
          treeData.map((node) => (
            <div key={node.id} className={classes.itemContainer}>

              <h3 className={classes.itemName}>{node.name}</h3>
              {/* <SuiButton
                style={{ width: '25px', marginLeft: '0.9rem', height: '30px', color: 'blue' }}
                size="small"
                variant="gradient"
                color="warning"
                className={classes.addButton}
                onClick={() => {
                  setOpenEdit(true);
                  setParentId(node.parentId);
                  setupdatedBy(node.updatedBy);
                  setcreatedBy(node.createdBy);
                  setisActive(node.isActive);
                  setNodeId(node.id);
                  setName(node.name);
                  setvalues(node.values);
                  setpracticeId(node.practiceId);
                }}
                startIcon={
                  <EditIcon    onClick={() => {
                    setOpenEdit(true);
                    setParentId(node.parentId);
                    setupdatedBy(node.updatedBy);
                    setcreatedBy(node.createdBy);
                    setisActive(node.isActive);
                    setNodeId(node.id);
                    setName(node.name);
                    setvalues(node.values);
                    setpracticeId(node.practiceId);
                  }}
                    // src={EditIcon}
                    style={{ marginTop: '-3px', marginRight: '-6px', width: '20px', height: '20px' }}
                  />
                }
              >
              </SuiButton> */}

<EditIcon    onClick={() => {
                    setOpenEdit(true);
                    setParentId(node.parentId);
                    setupdatedBy(node.updatedBy);
                    setcreatedBy(node.createdBy);
                    setisActive(node.isActive);
                    setNodeId(node.id);
                    setName(node.name);
                    setvalues(node.description);
                    setpracticeId(node.practiceId);
                  }}
                    // src={EditIcon}
                    style={{ marginTop: '-3px', marginRight: '-6px', width: '20px', height: '20px' , color: '#f87f37'}}
                  />

            </div>

          ))}
      </div>
    </>
  );
};

export default Roles;
