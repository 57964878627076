// @mui material components
import Card from '@mui/material/Card'

// Soft UI Dashboard React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiInput from 'components/SuiInput'
import SuiButton from 'components/SuiButton'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import { useAlert } from 'react-alert'
import InfoIcon from '@mui/icons-material/Info';
import CircularProgress from '@mui/material/CircularProgress'

import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import input from '@mui/material/Input'
import { Grid, Snackbar, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useReducer, useState, Fragment } from 'react'
import { createBrowserHistory } from 'history'
import Checkbox from '@mui/material/Checkbox'
import { Link, useLocation } from 'react-router-dom'
import ReactSelect from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import TextareaAutosize from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { TabPanel, TabContext, TabList } from '@mui/lab'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import PermIdentity from '@mui/icons-material/PermIdentity'
import WorkOutline from '@mui/icons-material/WorkOutline'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import { DataGrid } from '@mui/x-data-grid'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import customstyle from 'assets/customStyle.css'
import { Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { RFC_2822 } from 'moment'
import SuiSelect from 'components/SuiSelect'
import Button from '@mui/material/Button'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined'
import Tooltip from '@mui/material/Tooltip'
import Modal from '@mui/material/Modal'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import PatientRegistration from "./PatientRegistration";


const history = createBrowserHistory({ forceRefresh: true })
const defValues = {
  Id: '',
  startTime: '',
  endTime: '',
  userName: '',
}
function BillingReports() {
  const location = useLocation()
  const [tabValue, settabValue] = React.useState(0)
  const alert = useAlert()
  const [submitting, setSubmitting] = useState(false)
  const [patientFilter, setPatientFilter] = useState(null)
  const [userFilter, setUserFilter] = useState(null)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [timeLogRows, setTimeLogRows] = useState(null)
  const [dateLogRows, setDateLogRows] = useState(null)
  const [fileName, setfileName] = useState(null)
  const [fpatientId, setFpatientId] = useState('')
  const [fpatientName, setFpatientName] = useState('')
  const [fprogram, setFprogram] = useState('')
  const [fmonth, setFmonth] = useState('')
  const [fyear, setFyear] = useState('')
  const [fcode, setFcode] = useState('')
  const [fromdate, setFromDate] = useState('')
  const [NoRecord, setNoRecord] = useState('Please Apply Filter')

  
  const [todate, setToDate] = useState('')
  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }


  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    // resolver: yupResolver(validationSchema)
    // defaultValues: defValues
  })

  const handleChange1 = (event, newValue) => {
    settabValue(newValue)
  }
  const handleChange = (event, newValue) => {
    setFmonth(newValue)
    setFyear(newValue)
  }
  const onFilterClear = (event) => {
    setValue('month', '')


    setTimeLogRows(null)
    setFpatientName('')
    setFprogram('')
    setFmonth('')
    setFyear('')
    setFcode('')
    setfileName('')
    setFromDate('')
    setToDate('')
    setDateLogRows(null)
    setFromDateError(false)
    setToDateError(false)
    setMonthError(false)
    setYearError(false)
    // onFilterSearch(event)
  }

  const onFilterSearchAndDownload = (event) => {
    downloadData('1')
  }
  const onFilterSearch = (event) => {
    downloadData('0')
  }
  const [fromDateError, setFromDateError] = useState(false)
  const [toDateError, setToDateError] = useState(false)
  const onFilterSearch1 = (downloadFile) => {
    if (fromdate == null || fromdate.length <= 0) {
      setFromDateError(true)
      return
    }

    if (todate == null || todate.length <= 0) {
      setToDateError(true)
      return
    }
    setSubmitting(true)

    var date = fromdate.split('-')
    var fdate = `${date[1]}/${date[2]}/${date[0]}`
    date = todate.split('-')
    var tdate = `${date[1]}/${date[2]}/${date[0]}`
    setDateLogRows(null)
    setfileName(null)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Reports/SuperBillLogs?fromDate=${fdate}&todate=${tdate}`,
        {
          headers,
        }
      )
      .then((response) => {    setSubmitting(false)

        console.log('DataA: ', response.data)
        if (response.data === null || response.data.length === 0) {
          setNoRecord('No Record Found');
          console.log("abc,,1");

        } else {
          setDateLogRows(response.data);
          // Add a check to log "abc" to the console when the response data matches the specified condition
          if (response.data.fileName === "" && response.data.data.length === 0) {
            console.log("abc,,");
          }
        }
        
        // if (downloadFile == '1') setfileName(response.data.fileName)
        // // console.log('Data: ', fileName)
        // // setTimeLogRows(response.data)
      })
      .catch((error) => {setSubmitting(false)
        console.error('There was an error!', error)
      })
  }
  const [monthError, setMonthError] = useState(false)
  const [yearError, setYearError] = useState(false)
  const downloadData = (downloadFile) => {
    if (fmonth == null || fmonth.length <= 0) {
      setMonthError(true)
      return
    }
  
    if (fyear == null || fyear.length <= 0) {
      setYearError(true)
      return
    }
  
    setSubmitting(true)
    setTimeLogRows(null)
    setfileName(null)
  
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Reports/ReportsBilling?file=${downloadFile}&month=${fmonth}&year=${fyear}`,
        {
          headers,
        }
      )
      .then((response) => {
        setSubmitting(false)
        if (response.data === null || response.data.data.length === 0 ||response.data.fileName==='') {
          setNoRecord('No Record Found')
          setTimeLogRows([]) // Set an empty array to ensure the DataGrid doesn't render with previous data
        } else {
          setNoRecord(null) // Reset NoRecord state if data is received
          setTimeLogRows(JSON.parse(JSON.stringify(response.data.data)))
        }
        if (downloadFile === '1') setfileName(response.data.fileName)
        console.log('DataA: ', response.data)
      })
      .catch((error) => {
        setSubmitting(false)
        console.error('There was an error!', error)
      })
  }
  
  const onDownload = (event) => {
    const link = document.createElement('a')
    link.target = '_blank'
    link.download = 'Billing Report'
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Reports/downloadBillingReports?path=${fileName}`,
        {
          headers,
          responseType: 'arraybuffer',
        }
      )
      .then((response) => {
        link.href = URL.createObjectURL(
          new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        )
        link.click()
      })
  }

  const onEmailClicked = (event) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Reports/sendEmailBillingReports?path=${fileName}`,
        {
          headers,
          responseType: 'arraybuffer',
        }
      )
      .then((response) => {
        alert.success('Email sent successfully.', {
          type: 'success',
          onClose: () => {
            history.push('/PatientSearch')
          },
        })
      })
  }
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'none !important',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px',
    },
  }
  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 5,
    background: 'white',
    fontSize: '13.6px !important',
    textTransform: 'none !important',
    color: '#8392ab !important',
    borderColor: 'rgba(5, 152, 236, 0.637) !important',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .MuiDataGrid-cellContent': {
      textTransform: 'none',
    },
  }
  const timelogColumns = [
    {
      field: 'patientId',
      headerName: 'Patient ID',
      cellClassName: 'gridCell',
      sortable: false,
      width: 120,
      renderCell: cellValues => (
        <Button
          onClick={ev => {
            setpatientId(cellValues.row.patientId)
            console.log("Patient ID", cellValues.row.patientId)

          }}
        >
          {' '}
          {`${cellValues.row.patientId}`}
        </Button>
      )
      // renderCell: cellValues => (
      //   <Button
      //     onClick={handleOpenpatient}

      //   >
      //     {' '}
      //     {`${cellValues.row.patientId}`}
      //   </Button>
      // )
    },
    {
      field: 'patientName',
      headerName: 'Patient Name',
      cellClassName: 'gridCell',
      sortable: false,
      width: 200,


    },
    {
      field: 'program',
      headerName: 'Program',
      sortable: false,
      width: 180,
    },
    // {
    //   field: 'month',
    //   headerName: 'Month',
    //   cellClassName: 'gridCell',
    //   sortable: false,
    //   width: 150,
    // },
    // {
    //   field: 'year',
    //   headerName: 'Year',
    //   cellClassName: 'gridCell',
    //   sortable: false,
    //   width: 150,
    // },
    {
      field: 'totalTime',
      headerName: 'Total Time',
      cellClassName: 'gridCell',
      sortable: false,
      width: 180,
    },
    {
      field: 'code',
      headerName: 'Code',
      cellClassName: 'gridCell',
      sortable: false,
      width: 180,
    },
    {
      field: 'icd',
      headerName: 'ICD',
      headerClassName: 'super-app-theme--header',
      minWidth: 170,
      renderCell: cell => {
        let error = 0
        let errorMsg = ''
        if (cell.row.icd == '' && cell.row.icd == '') {
          error++
          errorMsg += ' Please Fill the icd \n'
        }
        { cell.value }
        return error > 0 ? (
          <Tooltip title={errorMsg} placement='right-start' arrow>
            <div>
              <InfoIcon fontSize='medium' marginTop='4px' />
            </div>
          </Tooltip>
        ) : null;

      }
    },
  ]
  const datelogColumns = [
    {
      field: 'createdDate',
      headerName: 'Created Date',
      sortable: false,
      width: 250,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      cellClassName: 'gridCell',
      sortable: false,
      width: 250,
    },
    {
      field: 'numberOfCliams',
      headerName: 'Number Of Claims',
      cellClassName: 'gridCell',
      sortable: false,
      width: 250,
    },

    {
      field: 'fileName',
      headerName: 'File Name',
      cellClassName: 'gridCell',
      sortable: false,
      width: 350,
    },
  ]
  const openEidthReport = (event, id) => {
    console.log('Clicked UserEmail: ', id)
    history.push({
      pathname: `/Timelogeidth`,
      state: { Timelogid: id },
    })
  }

  const [patientId, setpatientId] = useState('')
  const [openpatient, setOpenpatient] = React.useState(false)
  const handleOpenpatient = () => setOpenpatient(true)
  const handleCloseepatient = () => {
    setpatientId(0)
    setOpenpatient(false)
  }

  // useEffect(() => {
  //   if (patientId > 0) {
  //     handleOpenpatient()
  //   }
  // }, [patientId])


  const styleDetails = {
    width: '90%',
    height: '95%',
    bgcolor: '#FFFFC2',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 30,
    right: 0,
    margin: 'auto',
    marginTop: '10px',
    paddingBottom: '10px',
    padding: '20px',
    overflow: 'auto'
  }

  // useEffect(() => {
  //   // POST request using axios inside useEffect React hook
  //   let isComponentMounted = true
  //   const fetchData = async () =>
  //     axios
  //       .get(`${process.env.REACT_APP_API_URL}/Patients/getPatients`, {
  //         headers,
  //       })
  //       .then((response) => {
  //         const data = [{ label: '--All--', value: '' }]
  //         response.data.forEach((field) => {
  //           const label = field.firstName + ' ' + field.lastName
  //           data.push({
  //             value: field.id,
  //             label: label,
  //           })
  //         })
  //         setPatientFilter(data)
  //       })
  //       .catch((error) => {
  //         console.error('There was an error!', error)
  //       })
  //   fetchData()
  //   return () => {
  //     isComponentMounted = false
  //   }

  //   // empty dependency array means this effect will only run once (like componentDidMount in classes)
  // }, [])
  // useEffect(() => {
  //   // POST request using axios inside useEffect React hook
  //   let isComponentMounted = true
  //   const fetchData = async () =>
  //     axios
  //       .get(`${process.env.REACT_APP_API_URL}/Users/getUsers`, {
  //         headers,
  //       })
  //       .then((response) => {
  //         const data = [{ label: '--All--', value: '' }]
  //         response.data.forEach((field) => {
  //           const label = field.email
  //           data.push({
  //             value: field.email,
  //             label: label,
  //           })
  //         })
  //         setUserFilter(data)
  //       })
  //       .catch((error) => {
  //         console.error('There was an error!', error)
  //       })
  //   fetchData()
  //   return () => {
  //     isComponentMounted = false
  //   }

  //   // empty dependency array means this effect will only run once (like componentDidMount in classes)
  // }, [])
  return (
    <>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange1} aria-label="lab API tabs example">
            <Tab value={0} label="Report" />
            <Tab value={1} label="Log" />
          </TabList>
        </Box>
        <TabPanel value={0}>
          <Grid
            spacing={1}
            alignContent="left"
            justifyContent="left"
            style={{ width: '100% !important', marginLeft: '0px !important' }}
          >
            <Accordion
              style={{
                marginBottom: '10px',
                border: '1px solid rgba(5, 152, 236, 0.637)',
                borderRadius: '20px',
                marginTop: '10px',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Advanced Search</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={3} xl={6}>
                      <Typography style={{ fontSize: '14px' }}>
                        Month
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} xl={6}>
                      <Typography style={{ fontSize: '14px' }}>Year</Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} xl={6}>
                      <SuiSelect
                        // value={{ label: fmonth }}
                        value={{ label: getValues('month') }}
                        onChange={(value) => {
                          setValue('month', value.label)
                          setFmonth(value.value)
                          setMonthError(false)
                        }}
                        options={[
                          { value: '1', label: 'January' },
                          { value: '2', label: 'February' },
                          { value: '3', label: 'March' },
                          { value: '4', label: 'April' },
                          { value: '5', label: 'May' },
                          { value: '6', label: 'June' },
                          { value: '7', label: 'July' },
                          { value: '8', label: 'August' },
                          { value: '9', label: 'September' },
                          { value: '10', label: 'October' },
                          { value: '11', label: 'November' },
                          { value: '12', label: 'December' },
                        ]}
                      />
                      {monthError ? (
                        <Typography color="error" style={{ fontSize: '14px' }}>
                          Please Select Month!
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} sm={3} xl={6}>
                      <SuiSelect
                        value={{ label: fyear }}
                        onChange={(value) => {
                          setFyear(value.value)
                          setYearError(false)
                        }}
                        options={[
                          { value: '2022', label: '2022' },
                          { value: '2023', label: '2023' },
                          { value: '2024', label: '2024' },
                          { value: '2025', label: '2025' },
                          { value: '2026', label: '2026' },
                          { value: '2027', label: '2027' },
                          { value: '2028', label: '2028' },
                          { value: '2029', label: '2029' },
                          { value: '2030', label: '2030' },
                          { value: '2031', label: '2031' },
                          { value: '2032', label: '2032' },
                          { value: '2033', label: '2033' },
                        ]}
                      />
                      {yearError ? (
                        <Typography color="error" style={{ fontSize: '14px' }}>
                          Please Select Year!
                        </Typography>
                      ) : null}
                    </Grid>
                  </Grid>
                  <div>
                    <Button
                      onClick={onFilterSearch}
                      variant="gradient"
                      color="info"
                      style={{
                        marginTop: '10px',
                        marginBottom: '10px',
                        float: 'right',
                        marginRight: '13px',
                        height: '33px',
                        backgroundColor: '#FCBE13',
                        color: 'black',
                        fontSize: '14px',
                      }}
                    >
                      <SearchOutlinedIcon
                        fontSize="medium"
                        style={{ color: '#0a9ff0', paddingRight: '5px' }}
                      ></SearchOutlinedIcon>
                      Search
                    </Button>
                    <Button
                      onClick={onFilterSearchAndDownload}
                      variant="gradient"
                      color="info"
                      style={{
                        marginTop: '10px',
                        marginBottom: '10px',
                        float: 'right',
                        marginRight: '13px',
                        height: '33px',
                        backgroundColor: '#FCBE13',
                        color: 'black',
                        fontSize: '14px',
                      }}
                    >
                      <SearchOutlinedIcon
                        fontSize="medium"
                        style={{ color: '#0a9ff0', paddingRight: '5px' }}
                      ></SearchOutlinedIcon>
                      Search & Generate File
                    </Button>
                    <Button
                      onClick={onFilterClear}
                      variant="gradient"
                      color="error"
                      style={{
                        marginTop: '10px',
                        marginBottom: '10px',
                        float: 'right',
                        marginRight: '13px',
                        width: '80px',
                        height: '33px',
                        backgroundColor: '#FCBE13',
                        color: 'black',
                        fontSize: '14px',
                      }}
                    // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                    >
                      <CleaningServicesOutlinedIcon
                        fontSize="medium"
                        style={{ color: '#0a9ff0', paddingRight: '5px' }}
                      ></CleaningServicesOutlinedIcon>
                      Clear
                    </Button>
                    {fileName != null && fileName.length > 0 ? (
                      <Link
                        onClick={onDownload}
                        variant="gradient"
                        color="info"
                        style={{
                          marginTop: '12px',
                          marginRight: '10px',
                          float: 'right',
                          display: 'inline-block',
                        }}
                      >
                        Download File
                      </Link>
                    ) : null}
                    {fileName != null && fileName.length > 0 ? (
                      <Link
                        onClick={onEmailClicked}
                        variant="gradient"
                        color="info"
                        style={{
                          marginTop: '12px',
                          marginRight: '10px',
                          float: 'right',
                          display: 'inline-block',
                        }}
                      >
                        Email
                      </Link>
                    ) : null}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid
            container
            spacing={1}
            alignContent="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <SuiBox mb={3}>
                <div style={{ height: 372, width: '100%' }}>
                {submitting ? (
  <CircularProgress
    style={{
      width: '50px',
      height: '50px',
      position: 'absolute',
      left: '50%',
      top: '50%',
      marginLeft: '-25px',
      marginTop: '-25px',
      zIndex: 1000,
      color: '#FCBE13',
    }}
  />
) :''} 
 {timeLogRows === null || timeLogRows.length === 0 ? (
  <span>{NoRecord}</span>
) : (
  <DataGrid
    rows={timeLogRows} // Provide an empty array if timeLogRows is null
    columns={timelogColumns}
    pageSize={5}
    rowsPerPageOptions={[5]}
    sx={gridRowStyle}
  />
)}

                </div>
              </SuiBox>
            </Grid>
          </Grid>


          <Modal
            open={openpatient}
            onClose={handleCloseepatient}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={styleDetails}>
              <Grid container columnSpacing={1}>
                <Grid item xs={12} style={{ fontWeight: 500 }}>
                  <CancelOutlinedIcon
                    onClick={handleCloseepatient}
                    fontSize='medium'
                    style={{ color: 'red', paddingRight: '5px', float: 'right' }}
                  ></CancelOutlinedIcon>
                </Grid>
                {/* <PatientRegistration /> */}
                {/* <PatientRegistration pid={openpatient ? patientId : null}></PatientRegistration> */}
              </Grid>
            </Box>
          </Modal>





        </TabPanel>

        <TabPanel value={1}>
          <Grid
            spacing={1}
            alignContent="left"
            justifyContent="left"
            style={{ width: '100% !important', marginLeft: '0px !important' }}
          >
            <Accordion
              style={{
                marginBottom: '10px',
                border: '1px solid rgba(5, 152, 236, 0.637)',
                borderRadius: '20px',
                marginTop: '10px',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Advanced Search</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={3} xl={6}>
                      <Typography style={{ fontSize: '14px' }}>
                        From Date
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} xl={6}>
                      <Typography style={{ fontSize: '14px' }}>
                        To Date
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} xl={6}>
                      <SuiInput
                        type="date"
                        onChange={(e) => {
                          setFromDate(e.target.value)
                          setFromDateError(false)
                        }}
                        value={fromdate}
                      />

                      {fromDateError ? (
                        <Typography color="error" style={{ fontSize: '14px' }}>
                          Please Select Date!
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6} sm={3} xl={6}>
                      <SuiInput
                        type="date"
                        onChange={(e) => {
                          setToDate(e.target.value), setToDateError(false)
                        }}
                        value={todate}
                      />
                      {toDateError ? (
                        <Typography color="error" style={{ fontSize: '14px' }}>
                          Please Select Date!
                        </Typography>
                      ) : null}
                    </Grid>
                  </Grid>
                  <div>
                    <Button
                      onClick={onFilterSearch1}
                      variant="gradient"
                      color="info"
                      style={{
                        marginTop: '10px',
                        marginBottom: '10px',
                        float: 'right',
                        marginRight: '5px',
                        height: '33px',
                        backgroundColor: '#FCBE13',
                        color: 'black',
                        fontSize: '14px',
                      }}
                    >
                      <SearchOutlinedIcon
                        fontSize="medium"
                        style={{ color: '#0a9ff0', paddingRight: '5px' }}
                      ></SearchOutlinedIcon>
                      Search
                    </Button>

                    <Button
                      onClick={onFilterClear}
                      variant="gradient"
                      color="error"
                      style={{
                        marginTop: '10px',
                        marginBottom: '10px',
                        float: 'right',
                        marginRight: '13px',
                        width: '80px',
                        height: '33px',
                        backgroundColor: '#FCBE13',
                        color: 'black',
                        fontSize: '14px',
                      }}
                    // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                    >
                      <CleaningServicesOutlinedIcon
                        fontSize="medium"
                        style={{ color: '#0a9ff0', paddingRight: '5px' }}
                      ></CleaningServicesOutlinedIcon>
                      Clear
                    </Button>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid
            container
            spacing={1}
            alignContent="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <SuiBox mb={3}>
                <div style={{ height: 372, width: '100%' }}>
                {submitting? <CircularProgress
          style={{
            width: '50px',
            height: '50px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginLeft: '-25px',
            marginTop: '-25px',
            zIndex: 1000,
            color: '#FCBE13',
          }}
        />:
            
                  dateLogRows === null || dateLogRows.length===0 ?NoRecord : (
                    <DataGrid
                      rows={dateLogRows}
                      columns={datelogColumns}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      sx={gridRowStyle}
                    />
                  )}
                </div>
              </SuiBox>
            </Grid>
          </Grid>
        </TabPanel>
      </TabContext>
    </>
  )
}

export default BillingReports
