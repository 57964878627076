import React, { useState, useEffect } from 'react';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
// import EditIcon from 'assets/edit.png'
import { makeStyles } from '@mui/styles';
import { Button, CircularProgress, Grid, Modal } from "@mui/material";
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import SuiButton from 'components/SuiButton';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import MyForm from './MyForm';
const useStyles = makeStyles({
  treeItem: {
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  label: {
    fontSize: '1rem',
    fontWeight: 'normal',
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  addButton: {
    marginLeft: 'auto',
  },
  editButton: {
    marginLeft: '4px',
  },
});
const DragAndDropTree = () => {
  const classes = useStyles();

  const [treeData, setTreeData] = useState([]);
  const [ids, setId] = useState([]);
  const [expandedNodes, setExpandedNodes] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);



  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [parentId, setParentId] = useState('');
  const [parentId1, setParentId1] = useState('');

  const [isActive, setisActive] = useState('');
  const [name, setName] = useState('');
  const [nodesvalues, setvalues] = useState('');
  const [practiceId, setpracticeId] = useState('');

  const [updatedBy, setupdatedBy] = useState('');
  const [createdBy, setcreatedBy] = useState('');
  const [submitting, setSubmitting] = useState(false)
  const [refresh, setRefresh] = useState(false);

  const [nodeId, setNodeId] = useState('');
  const [applyRefresh, setapplyRefresh] = useState(false);


  const applyRefreshh = () =>  {
    setapplyRefresh(!applyRefresh)
  }



  const handleClose = () => setOpen(false);


  const handleCloseEdit = () => setOpenEdit(false);

  const toggleNode = (id) => {
    setExpandedNodes((prevExpandedNodes) =>
      prevExpandedNodes.includes(id)
        ? prevExpandedNodes.filter((nodeId) => nodeId !== id)
        : [...prevExpandedNodes, id]
    );
  };
  const handleNodeSelect = (event, nodeId) => {
    if (event.target.checked) {
      setSelectedNodes((prevSelectedNodes) => [...prevSelectedNodes, nodeId]);
    } else {
      setSelectedNodes((prevSelectedNodes) =>
        prevSelectedNodes.filter((selectedNodeId) => selectedNodeId !== nodeId)
      );
    }
  };


  useEffect(() => {
    // window.alert('fghj')
    fetchTreeData();
  }, [applyRefresh,refresh]);


  const fetchTreeData = async () => {setSubmitting(true)
      try {
        const token = localStorage.getItem('dtt_token')

        const response = await fetch('https://dttapis.devforhealth.com/api/Modules/getModulesList', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch tree data');
        }
        const data = await response.json();
        setTreeData(data);
        setSubmitting(false)
        // setRefresh(!refresh)
      } catch (error) {
        setSubmitting(false)
        console.error('Error fetching tree data:', error);
      }
   };

  

  // Function to find a node by its ID in the tree data
  const findNodeById = (nodes, id) => {
    for (const node of nodes) {
      if (node.id === id) {
        return node;
      }
      if (node.children && node.children.length > 0) {
        const foundNode = findNodeById(node.children, id);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return null;
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px'
  };
  const handleDrop = async (draggedItemId, newParentId) => {
    try {
      const token = localStorage.getItem('dtt_token')
      // Find the dragged item in the tree data
      const draggedItem = findNodeById(treeData, draggedItemId);
      if (!draggedItem) {
        throw new Error('Dragged item not found in tree data');
      }

      // Create the updated data for the API call
      const updatedData = {
        id: draggedItemId,
        parentId: newParentId,
        isActive: false,
        name: draggedItem.name, // Include the name property from the dragged item
      };

      // Perform the API call to update the parent ID
      const response = await fetch('https://dttapis.devforhealth.com/api/Modules/AddModules', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });

      if (!response.ok) {
        throw new Error('Failed to move module');
      } else {
        setRefresh(!refresh)
      }

      // Update the tree data after successful API call
      const updatedNode = await response.json();
      setTreeData(prevTreeData => {
        const updateTree = (nodes) => {
          return nodes.map(node => {
            if (node.id === newParentId) {
              // Append the new node to the children
              return {
                ...node,
                children: node.children ? [...node.children, updatedNode] : [updatedNode],
              };
            }
            if (node.children && node.children.length > 0) {
              // Recursively update the children nodes
              return {
                ...node,
                children: updateTree(node.children),
              };
            }
            return node;
          });
        };
        return updateTree(prevTreeData);
      });
    } catch (error) {
      console.error('Error moving module:', error);
    }
  };

  const renderTree = (node) => (
    <div key={node.id} style={{ margin: '8px' }}>
      <div
        draggable
        onDragStart={(e) => {
          setName(node.name);
          e.dataTransfer.setData('text/plain', node.id);
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={(e) => {
          
          setId(node.id);
          setName(node.name);
          e.preventDefault();
          const draggedItemId = e.dataTransfer.getData('text/plain');
          if (parseInt(draggedItemId) === node.id) {
            return;
          }
          handleDrop(parseInt(draggedItemId), node.id);
        }}
        onClick={() => toggleNode(node.id)}
        style={{ cursor: 'pointer' }}
      >
        <h3 style={{ display: 'flex', alignItems: 'center' }}>
          {node.children && node.children.length > 0 ? (
            expandedNodes.includes(node.id) ? (
              <FolderOpenIcon style={{ verticalAlign: 'middle', color: '#f87f37' }} />
            ) : (
              <FolderIcon style={{ verticalAlign: 'middle', color: '#f87f37' }} />
            )
          ) : null}
          {node.children && node.children.length === 0 ? (
            // <FormControlLabel
            //   control={
            //     <Checkbox
            //       checked={selectedNodes.includes(node.id)}
            //       onChange={(event) => handleNodeSelect(event, node.id)}
            //       value={node.id}
            //     />
            //   }
            //   label={node.name}
            // />
        <span style={{marginTop:'5px',fontSize:'17px'}}> {node.name}</span>   
          ) : (
           <span style={{marginLeft:'0.5rem'}}>{node.name}</span>            

          )}
          <div style={{ marginLeft: '10px' }}>



            {node.children && node.children.length >0 ? (
              <>
               
                    <EditIcon    onClick={(e) => {
                    e.stopPropagation();
                 
                    setParentId(node.parentId)
                    setupdatedBy(node.updatedBy)
                    setcreatedBy(node.createdBy)
                    setisActive(node.isActive)
                    setNodeId(node.id)
                    setName(node.name)
                    setvalues(node.values)
                    setpracticeId(node.practiceId)

                    setOpenEdit(true);
                  }}
                      // src={EditIcon}
                      style={{ marginTop: '-3px', marginRight: '-6px', width: '20px', height: '20px',  color: '#f87f37'}}
                    />
                
                {node.length===0?(  <IconButton
                  edge="end"
                  size="small"
                  className={classes.addButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    setupdatedBy(node.updatedBy)
                    setcreatedBy(node.createdBy)
                    setisActive(node.isActive)
                    setNodeId(node.id)
                    setName(node.name)
                    setvalues(node.values)
                    setpracticeId(node.practiceId)
                    setParentId1(node.id)

                    setOpen(true);
                  }}
                >
                  <AddIcon
                    // onClick={() => {
                    //   setupdatedBy(node.updatedBy)
                    //   setcreatedBy(node.createdBy)
                    //   setisActive(node.isActive)
                    //   setNodeId(node.id)
                    //   setName(node.name)
                    //   setvalues(node.values)
                    //   setpracticeId(node.practiceId)
                    //   setParentId1(node.parentId)

                    //   // setOpen(true);
                    // }}
                    fontSize="large" style={{ color: '#f87f37', background: '' }} />
                </IconButton>):(  <IconButton
                  edge="end"
                  size="small"
                  className={classes.addButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    setupdatedBy(node.updatedBy)
                    setcreatedBy(node.createdBy)
                    setisActive(node.isActive)
                    setNodeId(node.id)
                    setName(node.name)
                    setvalues(node.values)
                    setpracticeId(node.practiceId)
                    setParentId1(node.id)

                    setOpen(true);
                  }}
                >
                  <AddIcon
                    // onClick={() => {
                    //   setupdatedBy(node.updatedBy)
                    //   setcreatedBy(node.createdBy)
                    //   setisActive(node.isActive)
                    //   setNodeId(node.id)
                    //   setName(node.name)
                    //   setvalues(node.values)
                    //   setpracticeId(node.practiceId)
                    //   setParentId1(node.parentId)

                    //   // setOpen(true);
                    // }}
                    fontSize="large" style={{ color: '#f87f37', background: '' }} />
                </IconButton>)}

              
              </>
            ): <>
              
               
            <EditIcon    onClick={(e) => {
            e.stopPropagation();
         
            setParentId(node.parentId)
            setupdatedBy(node.updatedBy)
            setcreatedBy(node.createdBy)
            setisActive(node.isActive)
            setNodeId(node.id)
            setName(node.name)
            setvalues(node.values)
            setpracticeId(node.practiceId)

            setOpenEdit(true);
          }}
              // src={EditIcon}
              style={{ marginTop: '8px',
                marginRight: '-6px',
                width: '15px',
                height: '17px',  color: '#f87f37'}}
            />
        
      
        <IconButton
          edge="end"
          size="small"
          className={classes.addButton}
          onClick={(e) => {
            e.stopPropagation();
            setupdatedBy(node.updatedBy)
            setcreatedBy(node.createdBy)
            setisActive(node.isActive)
            setNodeId(node.id)
            setName(node.name)
            setvalues(node.values)
            setpracticeId(node.practiceId)
            setParentId1(node.id)

            setOpen(true);
          }}
        >
          <AddIcon
            // onClick={() => {
            //   setupdatedBy(node.updatedBy)
            //   setcreatedBy(node.createdBy)
            //   setisActive(node.isActive)
            //   setNodeId(node.id)
            //   setName(node.name)
            //   setvalues(node.values)
            //   setpracticeId(node.practiceId)
            //   setParentId1(node.parentId)

            //   // setOpen(true);
            // }}
            fontSize="large" style={{ color: '#f87f37', marginTop: '8px',
            marginRight: '-6px',
            width: '20px',
            height: '20px',
            marginLeft:'5PX' ,
            fontWeight:'bold'}} />
        </IconButton>
      </>}
          </div>
        </h3>
      </div>
      {expandedNodes.includes(node.id) && node.children && node.children.length > 0 && (
        <div style={{ marginLeft: '16px' }}>
          {node.children.map((child) => (
            <div key={child.id} onClick={() => { setName(child.name); console.log(`Clicked on ${child.name}`); }}>
              {renderTree(child)}
            </div>
          ))}
        </div>
      )}
    </div>
  );







  return (<>
    <Modal
      open={openEdit}
      onClose={handleCloseEdit}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div><MyForm createdBy={createdBy} update={'update'} applyRefresh={applyRefreshh} practiceId={practiceId} name={name} nodesvalues={nodesvalues} updatedBy={updatedBy} handleClose={handleCloseEdit} nodeId={nodeId} parentId={parentId} isActive={isActive} /></div>        </Box>
    </Modal>

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div><MyForm handleClose={handleClose} parentId={parentId1} applyRefresh={applyRefreshh} /></div>
      </Box>
    </Modal>
    {submitting ? (
        <CircularProgress
          style={{
            width: '50px',
            height: '50px',
            position: 'absolute',
            left: '50%',
            top: '70%',
            marginLeft: '-25px',
            marginTop: '25px',
            zIndex: 1000,
            color: '#FCBE13',
          }}
        />
      ) : null}
    <div>
      {Array.isArray(treeData) && treeData.map((node) => renderTree(node))}
    </div></>
  );
};

export default DragAndDropTree;
