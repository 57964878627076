// @mui material components
import Card from '@mui/material/Card'

// Soft UI Dashboard React components
import { useCallback } from 'react'
import PHQ from './PHQ'
import GAD from './GAD'
import SuiInput from 'components/SuiInput'
import SuiButton from 'components/SuiButton'
import SaveIcon from 'assets/save.png'
import CancelIcon from 'assets/cancel.png'
import SuiSelect from 'components/SuiSelect'
import Stack from '@mui/material/Stack'
import InputMask from 'react-input-mask'
// import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import { useAlert } from 'react-alert'
import { DataGrid } from '@mui/x-data-grid'
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import input from '@mui/material/Input'
import Moment from 'moment'
import Avatar from '@mui/material/Avatar'
import Modal from '@mui/material/Modal'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import PatientCareTeam from './PatientCareTeam'
import Demographics from './Demographics'
import MedicalHistory from './MedicalHistory'
import Screening from './Screening'
import TaskIcon from '@mui/icons-material/Task'
import SendIcon from '@mui/icons-material/Send'
import VideoComponent from './VideoComponent';
import CallComponent from './CallComponent'
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import SmokeFreeIcon from '@mui/icons-material/SmokeFree';
// import Claims from './Claim'
import {
  CircularProgress,
  Grid,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import axios from 'axios'
import React, { useEffect, useReducer, useState, Fragment, useRef } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { createBrowserHistory } from 'history'
import Checkbox from '@mui/material/Checkbox'
import { useLocation, Link } from 'react-router-dom'
// import ReactSelect from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { TabPanel, TabContext, TabList } from '@mui/lab'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import PermIdentity from '@mui/icons-material/PermIdentity'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import MoreTimeRoundedIcon from '@mui/icons-material/MoreTimeRounded'
import WorkOutline from '@mui/icons-material/WorkOutline'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import FormControl from '@mui/material/FormControl'
import GradientLineChart from 'examples/Charts/LineCharts/GradientLineChart'
import PatientNotes from './PatientNotes'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
// import PatientDocuments from './PatientDocuments'
import CurrencyInput from 'react-currency-input-field'
import { makeStyles } from '@material-ui/core/styles'
import {
  PlayCircleRounded,
  PauseCircleRounded,
  Email,
  EmailOutlined,
  MarkEmailRead,
} from '@mui/icons-material'

// import Autocomplete from '@material-ui/lab/Autocomplete'
// import Autocomplete from '@mui/material/Autocomplete'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Timelog from './Timelog'
import { flexbox } from '@mui/system'
import { styled } from '@mui/material/styles'
import ReactRoundedImage from 'react-rounded-image'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import Button from '@mui/material/Button'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined'
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined'
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined'
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined'
import { parse, isDate } from 'date-fns'
import { setOptions } from '@mobiscroll/react'
import { Line } from 'react-chartjs-2'
import Appointments from './Screening/Appointments'
import PatientDocuments from './PatientDocuments'
import HistoryIcon from '@mui/icons-material/History';

// const [openn, setOpen] = React.useState(false);
// const handleOpenn = () => setOpen(true);

const history = createBrowserHistory({ forceRefresh: true })
const Input = styled('input')({
  display: 'none',
})
var providersRedux = []
var facilitiesRedux = []

const STATUS = {
  STARTED: 'Started',
  STOPPED: 'Stopped',
}
const PSYCHOTHERAPYSTATUS = {
  STARTED: 'Started',
  STOPPED: 'Stopped',
}
const SMOKINGSTATUS = {
  STARTED: 'Started',
  STOPPED: 'Stopped',
}
const INITIAL_COUNT = 0
const PSYCHOTHERAPY_COUNT = 0
const SMOKING_COUNT = 0
// source: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
function psyUseInterval(callback, delay) {
  const savedCallbackPSY = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallbackPSY.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallbackPSY.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
function smokingUseInterval(callback, delay) {
  const savedCallbackSmk = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallbackSmk.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallbackSmk.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
// https://stackoverflow.com/a/2998874/1673761
const twoDigits = (num) => String(num).padStart(2, '0')

function handleCancel() {
  history.push('/PatientSearch')
}
const focusedColor = 'blue'
const useStyles = makeStyles({
  root: {
    // input label when focused
    '& label.i-focused': {
      color: focusedColor,
    },
    // focused color for input with variant='standard'
    '& .MuiInput-underline:after': {
      borderBottomColor: focusedColor,
    },
    // focused color for input with variant='filled'
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: focusedColor,
    },
    // focused color for input with variant='outlined'
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: focusedColor,
      },
    },
  },
})
function PatientRegistration(props) {
  console.log(props,'PatientRegistration ki props')
  // const [phqCompleted, setPhqCompleted] = useState(false);
  
  const [datamining,setdatamining]=useState(props.datamining)
 

  const handlePhqCompleted = useCallback(() => {
    // console.log("PHQ completed!")
    setShowPHQ(false)
    // console.log("GAD completed!")
    setShowGAD(false)
  }, [])

  const classes = useStyles()
  // const location = useLocation()
  // const _patientId = location.state.patientId
  // console.log(_patientId,'_patientId..')
  // const readOnly = location.state.readOnly

  // In the PatientRegistration component
  const location = useLocation();
  const [patientName, setPatientName] = useState(
    props.patientName || (location.state && location.state.firstName) || ''
  );
    const [patientLastName,setpatientLastName]=useState(location.state?location.state.lastName:'')
  const [PatientName,setPatientNames]=useState(props.patientName )
  console.log(PatientName,'PatientName,,')
  const _patientId = props.patientId || (location.state ? location.state.patientId : null);
  const handleclose = props.handleClose2
console.log(_patientId,'_patientId')
const [RespRefresh,getRespRefresh]=useState('')

  function handleCancel() {
  
      console.log('handle cancel working1' , props.handleClose2)
      props.handleClose2
  
  }


  const readOnly = location.state ? location.state.readOnly : null;

 
  const selectedTab = location.state ? location.state.selectedTab : '';



  // const selectedTab = location.state ? location.state.selectedTab : '';
  const selectedTabAppointment = location.state ? location.state.selectedTabAppointment : 'null'

  const [timerId, setTimerId] = useState('')
  const [psytimerId, setPSYTimerId] = useState('')
  const [smokingtimerId, setSmokingTimerId] = useState('')

  const [isMainTimerRunning, setIsMainTimerRunning] = useState(false)

  const [globalTimerStartAt, setGlobalTimerStartAt] = useState(null)
  const [pauseSeconds, setPauseSeconds] = useState(0)
  const [globalTimerStoppedAt, setGlobalTimerStoppedAt] = useState(null)

  const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT)
  const [psychotherapySecondsRemaining, setPsychotherapySecondsRemaining] =
    useState(PSYCHOTHERAPY_COUNT)
  const [smokingSecondsRemaining, setSmokingSecondsRemaining] =
    useState(SMOKING_COUNT)

  const [status, setStatus] = useState(STATUS.STOPPED)
  const [psychotherapyStatus, setPsychotherapyStatus] = useState(
    PSYCHOTHERAPYSTATUS.STOPPED
  )
  const [smokingStatus, setSmokingStatus] = useState(SMOKINGSTATUS.STOPPED)
  const [isStartButtonDisabled, setIsStartButtonDisabled] = useState(false)
  const [isStartSmokingButtonDisabled, setIsStartSmokingButtonDisabled] =
    useState(false)
  const [isStopButtonDisabled, setIsStopButtonDisabled] = useState(true)
  const [isStopSmokingButtonDisabled, setIsStopSmokingButtonDisabled] =
    useState(true)
  const [isDisplayPsychotherapyTimer, setIsDisplayPsychotherapyTimer] =
    useState(false)
  const [isDisplaySmokingTimer, setIsDisplaySmokingTimer] = useState(false)
  const secondsToDisplay = secondsRemaining % 60
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60
  const minutesToDisplay = minutesRemaining % 60
  const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60

  const psySeconds = psychotherapySecondsRemaining % 60
  const psyMinsRemaining = (psychotherapySecondsRemaining - psySeconds) / 60
  const psyMins = psyMinsRemaining % 60
  const psyHours = (psyMinsRemaining - psyMins) / 60

  const smkSeconds = smokingSecondsRemaining % 60
  const smkMinsRemaining = (smokingSecondsRemaining - smkSeconds) / 60
  const smkMins = smkMinsRemaining % 60
  const smkHours = (smkMinsRemaining - smkMins) / 60
  const [refreshAssessment, setrefreshAssessment] = useState(false)

  const dtt_userType = localStorage.getItem('dtt_userType')
  const [loadingTime, setloadingTime] = useState(0)
  const alert = useAlert()
  const [insuranceNames, setinsuranceNames] = React.useState([])
  const [openInsurances, setOpenInsurances] = useState(0)
  const [dataError, setDataError] = useState('')
  // const [phqcompleted, setPhqCompleted] = useState(false);

  const [showPHQ, setShowPHQ] = useState(false)
  const [showGAD, setShowGAD] = useState(false)

  const [userProgram, setUserProgram] = useState(null)
  // const [showScreen, setShowScreen] = useState(true);

  const handleOpenn = () => {
    // console.log('Button clicked');
    setShowPHQ(true)
    setShowGAD(false)
  }

  const handleClosee = () => {
    console.log('Button clicked')
    setShowPHQ(false)
  }

  // For GAD 7
  const handleOpen1 = () => {
    // console.log('Button clicked');
    setShowGAD(true)
    setShowPHQ(false)
  }

  const handleClose1 = () => {
    console.log('Button clicked')
    setShowGAD(false)
  }

  // works fine
  const [valueInsName, setValueInsName] = React.useState(null)
  const [valueSecInsName, setValueSecInsName] = React.useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [tabValue, settabValue] = React.useState(
    selectedTabAppointment
      ? 8 // assuming the default value for appointments
      : location.state.selectedTab
      ? 1 // assuming the default value for notes
      : _patientId === undefined
      ? 3 
      : 0 
  );
  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const psychotherapistPriviledge = `${localStorage.getItem(
    'dtt_isPsychotherapyPriviledges'
  )}`
  const headers = {
    Authorization: auth,
  }
  const handleChange1 = (event, newValue) => {
    settabValue(newValue)
  }
  const [fileName, setfileName] = useState(null)
  const [search, setsearch] = useState(0)
  const [fax, setfax] = useState('')
  const [res, setres] = useState(null)

  // const handleChange = (event, newValue) => {
  //   setFmonth(newValue)
  //   setFyear(newValue)
  // }
  const onFilterClear = (event) => {
    // setTimeLogRows(null)
    // setFpatientName('')
    // setFprogram('')
    setFmonth('')
    setFyear('')
    // setFcode('')
    setfileName('')
    setDataError('')

    // setFromDate('')
    // setToDate('')
    // setDateLogRows(null)
    // setFromDateError(false)
    // setToDateError(false)
    setMonthError(false)
    setYearError(false)
    onFilterSearch(event)
  }
  const onFilterSearchAndDownload = (event) => {
    const link = document.createElement('a')
    link.target = '_blank'
    console.log('Check Data', fileName)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Reports/downloadBillingReports?path=${fileName}`,

        {
          headers,
          responseType: 'arraybuffer',
        }
      )

      .then((response) => {
        link.download = 'Monthly Summary'
        link.href = URL.createObjectURL(
          new Blob([response.data], {
            type: 'application/pdf',
          })
        )
        link.click()
      })
    // const link = document.createElement('a')
    // link.target = '_blank'
    // link.download = 'Monthly Summary'
    // link.href = URL.createObjectURL(
    //   new Blob([res], {
    //     type: 'application/pdf',
    //   })
    // )
    // link.click()
  }

  const getCloseFunction = () => {
    if (props.handleClose2) {
      return props.handleClose2;
    } else if (props.handleClose1) {
      return props.handleClose1;
    } else if (props.handleClose) {
      return props.handleClose;
    } else if (props.handleClose3) {
      return props.handleClose3;
    }
    // Default to a generic close function or return null if none of the props are provided
    return () => console.log('No valid handleClose function provided');
  };

  const handleClosesss = getCloseFunction();
  const onFilterSearch = (event) => {
    // downloadData('0')
  }
  // const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  // const fax = (event) => {
  //   downloadData('0')
  // }
  const [timerStatus, setTimerStatus] = useState('stop')
  const [fromDateError, setFromDateError] = useState(false)
  const [toDateError, setToDateError] = useState(false)
  const onFilterSearch1 = (downloadFile) => {
    if (fromdate == null || fromdate.length <= 0) {
      setFromDateError(true)
      return
    }

    if (todate == null || todate.length <= 0) {
      setToDateError(true)
      return
    }

    var date = fromdate.split('-')
    var fdate = `${date[1]}/${date[2]}/${date[0]}`
    date = todate.split('-')
    var tdate = `${date[1]}/${date[2]}/${date[0]}`
    setDateLogRows(null)
    setfileName(null)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/SuperBillLogs/SuperBillLog?fromDate=${fdate}&todate=${tdate}`,
        {
          headers,
        }
      )
      .then((response) => {
        console.log('DataA: ', response.data)
        setDateLogRows(response.data)
        // if (downloadFile == '1') setfileName(response.data.fileName)
        // // console.log('Data: ', fileName)
        // // setTimeLogRows(response.data)
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }
  const [monthError, setMonthError] = useState(false)
  const [yearError, setYearError] = useState(false)

  const onDownload = (event) => {
    setDataError('')
    if (fyear.length > 0 && fmonth.length > 0) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL
          }/PatientNote/GeneratPatTimeLogPdfFile?patientid=${_patientId !== undefined ? _patientId : 0
          }&year=${fyear}&month=${fmonth}`,
          {
            headers,
          }
        )
        .then((response) => {
          if (response.data.fileName) {
            // setsearch(1)
            setfileName(response.data.fileName)
          } else {
            setDataError(response.data)
          }
          // link.href = URL.createObjectURL(
          //   new Blob([response.data], {
          //     type: 'application/pdf',
          //   })
          // )
          // link.click()
        })
    } else {
      setYearError(true)
      setMonthError(true)
    }
  }
  const SentFax = (event) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Faxes/SendFax?pdfFileName=${fileName}&sendTo=${fax}`,
        {
          headers,
        }
      )
      .then((response) => {
        // console.log('CheckData', response)
        if (response.data.messageStatus == 'Queued') {
          setOpen(false)
          alert.success('Fax sent successfully.', {
            type: 'success',
            //     onClose: () => {
            //       //  history.push('/PatientSearch')
            //  },
          })
        } else {
          alert.success('Fax is Not sent.')
        }
      })
    // .then((response) => {
    //   if (response.data) {
    //     console.log('heelo', response)
    //     setfax(1)
    //     setres(response.data)
    //   }
    // link.href = URL.createObjectURL(
    //   new Blob([response.data], {
    //     type: 'application/pdf',
    //   })
    // )
    // link.click()
  }

  const [fetchedData, setfetchedData] = useState(false)
  const [zipDownloaded, setzipDownloaded] = useState(false)
  const [tabValueIns, settabValueIns] = React.useState(0)
  const [patientGAD7, setPatientGAD7] = useState('')
  const [Databyyourself, setDatabyyourself] = useState('')
  const [patientPHQ9, setPatientPHQ9] = useState('')

  // const [phqCompleted, setPhqCompleted] = useState(false);
  const [gad7Rows, setGad7Rows] = useState(null)
  const [phq9Rows, setPhq9Rows] = useState(null)
  // const [Phq9Columns, setPhq9Columns] = useState("")
  // const [selectedRow, setSelectedRow] = useState(null);
  const [Unread, setUnread] = useState(null)
  // const [, setUnread] = useState("")
  const [noteRows, setNoteRows] = useState(null)
  const [smokingStatusRes, setSmokingStatusRes] = useState(false)
  const [psychotherapyRows, setPsychotherapyRows] = useState(null)
  const [smokingRows, setSmokingRows] = useState(null)
  const [timeLogRows, setTimeLogRows] = useState(null)
  const [fmonth, setFmonth] = useState('')
  const [fyear, setFyear] = useState('')
  const providersRedx = useSelector(
    (state) => (state.providers.loading ? [] : state.providers.NAMES),
    shallowEqual
  )
  const facilitiesRedx = useSelector(
    (state) => (state.facilities.loading ? [] : state.facilities.NAMES),
    shallowEqual
  )



  const UnReadCell = ({ value, row }) => (
    <span
      style={{
        fontWeight: row.isRead ? 'normal' : 'bold',
        color: row.isRead ? 'inherit' : 'black',
      }}
    >
      {value}
    </span>
  )

  // Row handle Code
  const handleRowClick = (params) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/PatientPortal/MarkPatientPHQ9?id=${params.id}&isRead=true`,
        {
          headers,
        }
      )
      .then((response) => {
        console.log(response.data)
        console.log('Button Clicked')
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const handleRowClickGAD7 = (params) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/PatientPortal/MarkPatientGAD7?id=${params.id}&isRead=true`,
        {
          headers,
        }
      )
      .then((response) => {
        console.log(response.data)
        console.log('Button Clicked')
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const Gad7Columns = [
    { field: 'id', headerName: 'ID', width: 50, renderCell: UnReadCell },
    {
      field: 'date',
      headerName: 'Assessment Date',
      width: 180,
      renderCell: UnReadCell,
    },
    {
      field: 'totalScore',
      headerName: 'Total Score',
      width: 100,
      renderCell: UnReadCell,
    },
    {
      field: 'percentage',
      headerName: 'Percentage',
      width: 120,
      renderCell: UnReadCell,
    },
    {
      field: 'source',
      headerName: 'Source',
      width: 100,
      renderCell: UnReadCell,
    },
    {
      field: 'isRead',
      headerName: 'Action',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      minWidth: 50,
      renderCell: (cellValues) => (
        <Button
          style={{
            width: '100%',
            color: 'red',
            height: '100%',
            // backgroundColor: 'green'
          }}
          onClick={(e) => {
            console.log("----------------", e)
            e.stopPropagation()
            cellValues.row.isRead = false // stop the event from propagating to the cell
            axios
              .get(
                `${process.env.REACT_APP_API_URL}/PatientPortal/MarkPatientGAD7?id=${cellValues.id}&isRead=false`,
                {
                  headers,
                }
              )
              .then((response) => {
                console.log(response.data)
                console.log('Button Clicked')
                // response.row.isRead = true
              })
              .catch((error) => {
                console.error(error)
              })
          }}
        >
          <MarkEmailRead style={{ color: 'red' }} />
        </Button>
      ),
    },
  ]

  const Phq9Columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 50,
      renderCell: UnReadCell,

      // renderCell: Unread ? 'UnReadCell' : 'Read',
      cursor: 'pointer',
    },
    {
      field: 'date',
      headerName: 'Assessment Date',
      width: 180,
      renderCell: UnReadCell,
      cursor: 'pointer',
    },
    {
      field: 'totalScore',
      headerName: 'Total Score',
      width: 100,
      renderCell: UnReadCell,
      cursor: 'pointer',
    },
    {
      field: 'percentage',
      headerName: 'Percentage',
      width: 120,
      renderCell: UnReadCell,
      cursor: 'pointer',
    },
    {
      field: 'source',
      headerName: 'Source',
      width: 120,
      renderCell: UnReadCell,
      cursor: 'pointer',
    },
    {
      field: 'isRead',
      headerName: 'Action',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      minWidth: 50,
      renderCell: (cellValues) => (
        <Button
          style={{
            width: '100%',
            color: 'red',
            height: '100%',
            // backgroundColor: 'green'
          }}
          onClick={(e) => {

            cellValues.row.isRead = false

            console.log("----------------", e)
            e.stopPropagation() // stop the event from propagating to the cell
            axios
              .get(
                `${process.env.REACT_APP_API_URL}/PatientPortal/MarkPatientPHQ9?id=${cellValues.id}&isRead=false`,
                {
                  headers,
                }
              )
              .then((response) => {
                console.log(response.data)
                console.log('Button Clicked')
                // response.row.isRead = true
              })
              .catch((error) => {
                console.error(error)
              })
          }}
        >
          <MarkEmailRead style={{ color: 'red' }} />
        </Button>
      ),
    },
  ]

  const [CareTeamList, setCareTeamList] = useState([])
  const [patientinfo, setpatientinfo] = useState([])
  const [apiError, setApiError] = useState('')
  const [patientid, setpatientid] = useState('')

  const today = new Date()
  var startDate = new Date()
  startDate.setFullYear(startDate.getFullYear() - 100)
  const validationSchema = Yup.object({
    lastName: Yup.string().required('Last Name is required'),
    firstName: Yup.string().required('First Name is required'),
    providerId: Yup.string().required('Please Select Provider'),

    // ssn: Yup.string().required('Social Security number is required'),
    // accountNumber: Yup.string().required('Account # is required'),
    dob: Yup.date()
      .required('dob is required')
      .nullable()
      .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
      .max(today, "You can't be born in the future!")
      .min(startDate, "You can't be that much old!"),
    // dob: Yup.string().dob('dob is required'),
    // emailAddress: Yup.string()
    //   .required('Email is invalid')
    //   .email('Email is invalid'),
    //  insuranceId: Yup.string().required('Insurance is required'),
  })
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
  })
  var defaultValues = {
    priIns: {
      id: '',
      insuranceId: '',
      patientId: '',
      groupId: '',
      memberId: '',
      practiceId: '',
      payerId: '',
      type: 'primary',
      copay: '',
      deductible: '',
      subsRelation: '',
      subsFirtName: '',
      subsLastName: '',
      guarranterName: '',
      guarantorAddress: '',
      guarantorSuite: '',
      guarantorCity: '',
      guarantorState: '',
      guarantorZip: '',
      inactive: '',
      startDate: '',
      endDate: '',
      createdDate: '',
      updatedDate: '',
      createdBy: '',
      updatedBy: '',
      clientCreatedDate: '',
      clientUpdatedDate: '',
    },
    id: '',
    accountNumber: '',
    lastName: '',
    firstName: '',
    midInitial: '',
    title: '',
    alias: '',
    ssn: '',
    dob: '',
    gender: '',
    status: '',
    cellNo: '',
    language: '',
    maritalStatus: '',
    homePhone: '',
    cellPhone: '',
    workPhone: '',
    emailAddress: '',
    city: '',
    state: '',
    zipCode: '',
    address: '',
    photoUrl: '',
    program: '',
    smokingStatus: '',
    comments: '',
    practiceId: '',
    facilityId: '',
    providerId: '',
    isActive: '',
    updatedBy: '',
    updatedDate: '',
    createdBy: '',
    createdDate: '',
    base64: '',
    secIns: {
      id: '',
      insuranceId: '',
      patientId: '',
      groupId: '',
      memberId: '',
      practiceId: '',
      payerId: '',
      type: 'secondary',
      copay: '',
      deductible: '',
      subsRelation: '',
      subsFirtName: '',
      subsLastName: '',
      guarranterName: '',
      guarantorAddress: '',
      guarantorSuite: '',
      guarantorCity: '',
      guarantorState: '',
      guarantorZip: '',
      inactive: '',
      startDate: '',
      endDate: '',
      createdDate: '',
      updatedDate: '',
      createdBy: '',
      updatedBy: '',
      clientCreatedDate: '',
      clientUpdatedDate: '',
    },
  }
  /*   useEffect(() => {
    const deepCopy = JSON.parse(JSON.stringify(defaultValues))
    reset(deepCopy)
  }, [fetchedData]) */
  function resetAfterAPICall(value) {
    var data = {
      id: value.id,
      accountNumber: value.accountNumber,
      lastName: value.lastName,
      firstName: value.firstName,
      midInitial: value.midInitial,
      title: value.title,
      alias: value.alias,
      ssn: value.ssn,
      dob: value.dob,
      gender: value.gender,
      status: value.status,
      cellNo: value.cellNo,
      language: value.language,
      maritalStatus: value.maritalStatus,
      homePhone: value.homePhone,
      cellPhone: value.cellPhone,
      workPhone: value.workPhone,
      emailAddress: value.emailAddress,
      city: value.city,
      state: value.state,
      zipCode: value.zipCode,
      address: value.address,
      photoUrl: value.photoUrl,
      program: value.program,
      smokingStatus: value.smokingStatus,
      comments: value.comments,
      practiceId: value.practiceId,
      facilityId: value.facilityId,
      careTeam_id: value.careTeam_id,
      providerId: value.providerId,
      isActive: value.isActive,
      updatedBy: value.updatedBy,
      updatedDate: value.updatedDate,
      createdBy: value.createdBy,
      createdDate: value.createdDate,
      base64: value.base64,
      priIns: {},
      secIns: {},
    }
    if (
      value.priIns !== undefined &&
      value.priIns !== null &&
      value.priIns.id > 0
    ) {
      var _priIns = {
        id: value.priIns.id,
        insuranceId: value.priIns.insuranceId,
        insuranceName: value.priIns.insuranceName,
        patientId: value.priIns.patientId,
        groupId: value.priIns.groupId,
        memberId: value.priIns.memberId,
        practiceId: value.priIns.practiceId,
        payerId: value.priIns.payerId,
        type: value.priIns.typerimary,
        copay: value.priIns.copay,
        deductible: value.priIns.deductible,
        subsRelation: value.priIns.subsRelation,
        subsFirtName: value.priIns.subsFirtName,
        subsLastName: value.priIns.subsLastName,
        guarranterName: value.priIns.guarranterName,
        guarantorAddress: value.priIns.guarantorAddress,
        guarantorSuite: value.priIns.guarantorSuite,
        guarantorCity: value.priIns.guarantorCity,
        guarantorState: value.priIns.guarantorState,
        guarantorZip: value.priIns.guarantorZip,
        inactive: value.priIns.inactive,
        startDate: value.priIns.startDate,
        endDate: value.priIns.endDate,
        createdDate: value.priIns.createdDate,
        updatedDate: value.priIns.updatedDate,
        createdBy: value.priIns.createdBy,
        updatedBy: value.priIns.updatedBy,
        clientCreatedDate: value.priIns.clientCreatedDate,
        clientUpdatedDate: value.priIns.clientUpdatedDate,
      }
      data.priIns = _priIns
    } else {
      data.priIns = defaultValues.priIns
    }
    if (
      value.secIns != undefined &&
      value.secIns != null &&
      value.secIns.id > 0
    ) {
      var _secIns = {
        id: value.secIns.id,
        insuranceId: value.secIns.insuranceId,
        insuranceName: value.secIns.insuranceName,
        patientId: value.secIns.patientId,
        groupId: value.secIns.groupId,
        memberId: value.secIns.memberId,
        practiceId: value.secIns.practiceId,
        payerId: value.secIns.payerId,
        type: value.secIns.type,
        copay: value.secIns.copay,
        deductible: value.secIns.deductible,
        subsRelation: value.secIns.subsRelation,
        subsFirtName: value.secIns.subsFirtName,
        subsLastName: value.secIns.subsLastName,
        guarranterName: value.secIns.guarranterName,
        guarantorAddress: value.secIns.guarantorAddress,
        guarantorSuite: value.secIns.guarantorSuite,
        guarantorCity: value.secIns.guarantorCity,
        guarantorState: value.secIns.guarantorState,
        guarantorZip: value.secIns.guarantorZip,
        inactive: value.secIns.inactive,
        startDate: value.secIns.startDate,
        endDate: value.secIns.endDate,
        createdDate: value.secIns.createdDate,
        updatedDate: value.secIns.updatedDate,
        createdBy: value.secIns.createdBy,
        updatedBy: value.secIns.updatedBy,
        clientCreatedDate: value.secIns.clientCreatedDate,
        clientUpdatedDate: value.secIns.clientUpdatedDate,
      }
      data.secIns = _secIns
    } else {
      data.secIns = defaultValues.secIns
    }
    const deepCopy = JSON.parse(JSON.stringify(data))
    reset(deepCopy)
  }
  useEffect(() => {
    if (localStorage.getItem('tabvalue') != undefined) {
      settabValue(3)
      setTimeout(function () {
        localStorage.removeItem('tabvalue')
      }, 500)
    }
  }, [])
  const onSubmit = (data) => {
    // console.log('submit',data.dob)
    // const ddob=(data.dob).toISOString();
    // console.log('afterc', ddob)
    console.log('submit', data)
    setApiError('')
    setSubmitting(true)
    const postData = data
    //postData.dob = data.dob ? data.dob.value : ''
    if (_patientId !== undefined && _patientId != null) postData.id = _patientId
    else postData.id = 0
    axios
      .post(`${process.env.REACT_APP_API_URL}/Patients/addPatient`, postData, {
        headers,
      })
      .then((response) => {
        if (response.data.id) {
          setSubmitting(false)
          alert.success('Record saved successfully.', {
            type: 'success',
            onClose: () => {
              history.push('/PatientSearch')
            },
          })
        } else {
          setApiError(response.data)
          setSubmitting(false)
        }
      })
      .catch((error) => {
        setSubmitting(false)
        alert.success(`Error ${error.message}`, {
          type: 'error',
        })
      })
  }
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    // CareTeamList = []
    setpatientid(_patientId)

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Patients/getPatientInfo?patientid=${_patientId !== undefined ? _patientId : 0
        }`,
        {
          headers,
        }
      )
      .then((response) => {
        setUserProgram(response.data.program)
        if (
          response.data.program == 'CoCM' &&
          localStorage.getItem('dtt_user_provider_type') ==
          'Behavioral Health Care Manager'
        ) {
          setStatus(STATUS.STARTED)
        } else if (response.data.program == 'BHI') {
          setStatus(STATUS.STARTED)
        }

        setpatientinfo(response.data)
        if (response.data.smokingStatus == 'Smoking Cessation')
          setSmokingStatusRes(true)
      })
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [])

  useEffect(() => {
    if (_patientId !== undefined) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/PatientPortal/getPatientPHQ9?PatientId=${_patientId}`,
          {
            headers,
          }
        )

        .then((response) => {
          console.log('test data_______________', response.data)
          const label = []
          const dataArr = []
          // const Phq9row = []
          response.data.forEach((element) => {
            label.push(element.date.split('T')[0])
            dataArr.push(element.totalScore)
            // phq9 Rows Data
            // Phq9row.push({
            //   date: element.date.split('T')[0],
            //   totalScore: element.totalScore,
            //   id: element.id,
            //   percentage: ((element.totalScore / 27) * 100).toFixed(2),
            //   source: element.source,
            //   unread: true,
            // })
            // console.log("phq9row", Phq9row )
          })
          // setPhq9Rows(Phq9row)
          // console.log('phq9row', Phq9row)
          // const dataset = {};
          setPatientPHQ9({
            labels: label,
            datasets: [
              {
                label: 'PHQ9',
                color: 'info',
                data: dataArr,
              },
            ],
          })
          console.log('Labels', label)
        })
        .catch((error) => {
          console.error('There was an error!', error)
        })

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/PatientPortal/getPatientGAD7?PatientId=${_patientId}`,
          {
            headers,
          }
        )
        .then((response) => {
          const label = []
          const dataArr = []
          const Gad7row = []
          response.data.forEach((element) => {
            label.push(element.date.split('T')[0])
            dataArr.push(element.totalScore)
            Gad7row.push({
              date: element.date.split('T')[0],
              totalScore: element.totalScore,
              id: element.id,
              percentage: ((element.totalScore / 21) * 100).toFixed(2),
              source: element.source,
            })
          })
          // setGad7Rows(Gad7row)
          const dataset = {}
          setPatientGAD7({
            labels: label,
            datasets: [
              {
                label: 'GAD7',
                color: 'dark',
                data: dataArr,
              },
            ],
          })
        })
        .catch((error) => {
          console.error('There was an error!', error)
        })
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [RespRefresh])

  // For PHQ9 & GAD&7 Row data APIS... Call
  useEffect(() => {
    if (_patientId !== undefined) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/PatientPortal/PatientPHQ9?PatientId=${_patientId}`,
          {
            headers,
          }
        )
        .then((response) => {
          console.log('response.data', response.data)
          setPhq9Rows(response.data)

          // setUnread(response.cellValues.isRead)

        })
        .catch((error) => {
          console.error('There was an error!', error)
        })

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/PatientPortal/PatientGAD7?PatientId=${_patientId}`,
          {
            headers,
          }
        )
        .then((response) => {
          console.log('response.data', response.data)
          setGad7Rows(response.data)

          // setUnread(response.cellValues.isRead)

        })
        .catch((error) => {
          console.error('There was an error!', error)
        })
    }


    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [RespRefresh])

  useEffect(() => {
    if (status == STATUS.STARTED) {
      if (globalTimerStartAt == null) setGlobalTimerStartAt(new Date())
      StartTimerHandler(status);
    } if (status == STATUS.STOPPED) {
      if (timerId > 0) {
        PauseTimerHandler(status);
      }
    }
  }, [status])

  useEffect(() => {
    if (status == STATUS.STARTED) {
      if (globalTimerStartAt == null) setGlobalTimerStartAt(new Date())
    }
  }, [status])

  useEffect(() => {
    if (status == STATUS.STARTED) {
      var endTimer = new Date()
      setSecondsRemaining(
        parseInt((endTimer - globalTimerStartAt) / 1000) -
        parseInt(pauseSeconds)
      )
    }
  }, [secondsRemaining])

  const PauseTimerHandler = (status) => {
    setStatus(status)
    setGlobalTimerStoppedAt(new Date())
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/PatientTimeLog/PatientEndTime?id=${timerId}&timeSpent=${hoursToDisplay}:${minutesToDisplay}:${secondsToDisplay}`,
        {
          headers,
        }
      )
      .then((response) => {
        // setTimerId(0)
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }

  const StartTimerHandler = (status) => {
    if (globalTimerStoppedAt != null) {
      var endTimer = new Date()
      setPauseSeconds(
        parseInt(pauseSeconds) +
        parseInt((endTimer - globalTimerStoppedAt) / 1000)
      )
    } else {
      setPauseSeconds(0)
    }
    axios
      .get(
        `${process.env.REACT_APP_API_URL
        }/PatientTimeLog/PatientTimeStart?patientid=${_patientId !== undefined ? _patientId : 0
        }&type=${userProgram}`,
        {
          headers,
        }
      )
      .then((response) => {
        setTimerId(response.data.id)
        setStatus(status)
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      title: {
        display: true,
        text: 'Line Chart',
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
      annotation: {
        annotations: [
          {
            type: 'box',
            drawTime: 'beforeDatasetsDraw',
            xScaleID: 'x-axis-0',
            yScaleID: 'y-axis-0',
            xMin: 'February',
            xMax: 'May',
            yMin: 0,
            yMax: 50,
            backgroundColor: 'rgba(255, 99, 132, 0.1)',
            borderColor: 'rgba(255, 99, 132, 0.5)',
            borderWidth: 2,
          },
        ],
      },
    },
  }

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    providersRedux = []
    // console.log('reduxxx', providersRedx)
    providersRedx.forEach((field) => {
      providersRedux.push({
        label: field.name,
        value: field.id,
      })
    })

    //     if (dtt_userType != 'Admin')
    //     {
    //       setValue('providerId', providersRedux[0].value)
    //     }
    // console.log('provideredux',providersRedux[0].value)

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [])

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    facilitiesRedux = []
    facilitiesRedx.forEach((field) => {
      facilitiesRedux.push({
        label: field.name,
        value: field.id,
      })
    })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [{ facilitiesRedx }])

  const startPsychotherapySession = (event, id) => {
    if (status == STATUS.STARTED) {
      setIsMainTimerRunning(true)
      PauseTimerHandler(STATUS.STOPPED)
    } else {
      setIsMainTimerRunning(false)
    }

    if (isDisplaySmokingTimer === true) {
      setSmokingStatus(SMOKINGSTATUS.STOPPED)
      setIsStartSmokingButtonDisabled(false)
      setIsStopSmokingButtonDisabled(true)
    }
    setIsDisplayPsychotherapyTimer(true)
    setIsStartButtonDisabled(true)
    setIsStopButtonDisabled(false)
    setPsychotherapyStatus(PSYCHOTHERAPYSTATUS.STARTED)
    axios
      .get(
        `${process.env.REACT_APP_API_URL
        }/PatientTimeLog/PatientTimeStart?patientid=${_patientId !== undefined ? _patientId : 0
        }&type=Psychiatrist`,
        {
          headers,
        }
      )
      .then((response) => {
        // console.log('PSY Time Log Started: ', response.data)
        //localStorage.setItem("dtt_psy_timer_start_id", response.data.id);
        setPSYTimerId(response.data.id)
        localStorage.setItem(
          'dtt_psy_timer_patient_id',
          response.data.patientId
        )
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }
  const stopPsychotherapySession = (event, id) => {
    if (isMainTimerRunning) StartTimerHandler(STATUS.STARTED)

    setIsDisplayPsychotherapyTimer(false)
    setIsStartButtonDisabled(false)
    setIsStopButtonDisabled(true)
    setPsychotherapyStatus(PSYCHOTHERAPYSTATUS.STOPPED)

    setPsychotherapySecondsRemaining(PSYCHOTHERAPY_COUNT)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/PatientTimeLog/PatientEndTime?sendEmail=1&id=${psytimerId}&timeSpent=${psyHours}:${psyMins}:${psySeconds}`,
        {
          headers,
        }
      )
      .then((response) => {
        setPSYTimerId('')
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }
  const startSmokingSession = (event, id) => {
    if (status == STATUS.STARTED) {
      setIsMainTimerRunning(true)
      PauseTimerHandler(STATUS.STOPPED)
    } else {
      setIsMainTimerRunning(false)
    }

    if (isDisplayPsychotherapyTimer === true) {
      setPsychotherapyStatus(PSYCHOTHERAPYSTATUS.STOPPED)
      setIsStartButtonDisabled(false)
      setIsStopButtonDisabled(true)
    }
    setIsDisplaySmokingTimer(true)
    setIsStartSmokingButtonDisabled(true)
    setIsStopSmokingButtonDisabled(false)
    setSmokingStatus(SMOKINGSTATUS.STARTED)
    axios
      .get(
        `${process.env.REACT_APP_API_URL
        }/PatientTimeLog/PatientTimeStart?patientid=${_patientId !== undefined ? _patientId : 0
        }&type=Smoking`,
        {
          headers,
        }
      )
      .then((response) => {
        // console.log('Smoking Time Log Started: ', response.data)
        //localStorage.setItem("dtt_smoking_timer_start_id", );
        setSmokingTimerId(response.data.id)
        localStorage.setItem(
          'dtt_smoking_timer_patient_id',
          response.data.patientId
        )
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }
  const stopSmokingSession = (event, id) => {


    if (isMainTimerRunning) StartTimerHandler(STATUS.STARTED)

    setIsDisplaySmokingTimer(false)
    setIsStartSmokingButtonDisabled(false)
    setIsStopSmokingButtonDisabled(true)
    setSmokingStatus(SMOKINGSTATUS.STOPPED)
    setSmokingSecondsRemaining(SMOKING_COUNT)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/PatientTimeLog/PatientEndTime?id=${smokingtimerId}&timeSpent=${smkHours}:${smkMins}:${smkSeconds}`,
        {
          headers,
        }
      )
      .then((response) => {
        // console.log('Smoking Time Log Ended: ', response.data)
        //localStorage.setItem("dtt_smoking_timer_start_id", "");
        setSmokingTimerId('')
        localStorage.setItem('dtt_smoking_timer_patient_id', '')
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }

  const handleChange = (event, newValue) => {
    settabValue(newValue)
  }

  const handleChangeInsurances = (event, newValue) => {
    settabValueIns(newValue)
  }
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px',
    },
  }
  const zipCodeListStyle = {
    cursor: 'pointer',
    '&:hover': {
      background: '#3b3b3b !important',
      color: 'rgba(5, 152, 236, 0.637) !important',
    },
  }

  const psychotherapyColumns = [
    {
      field: 'id',
      headerName: 'Id',
      cellClassName: 'gridCell',
      sortable: false,
      width: 80,
    },
    {
      field: 'userName',
      headerName: 'User Name',
      cellClassName: 'gridCell',
      sortable: false,
      width: 200,
    },
    {
      field: 'startTime',
      headerName: 'Start Time',
      cellClassName: 'gridCell',
      sortable: false,
      width: 200,
    },
    {
      field: 'endTime',
      headerName: 'End Time',
      cellClassName: 'gridCell',
      sortable: false,
      width: 200,
    },
    {
      field: 'differenceTime',
      headerName: 'Time Spend',
      cellClassName: 'gridCell',
      sortable: false,
      width: 300,
    },
  ]
  const smokingColumns = [
    {
      field: 'id',
      headerName: 'Id',
      cellClassName: 'gridCell',
      sortable: false,
      width: 80,
    },
    {
      field: 'userName',
      headerName: 'User Name',
      cellClassName: 'gridCell',
      sortable: false,
      width: 200,
    },
    {
      field: 'startTime',
      headerName: 'Start Time',
      cellClassName: 'gridCell',
      sortable: false,
      width: 200,
    },
    {
      field: 'endTime',
      headerName: 'End Time',
      cellClassName: 'gridCell',
      sortable: false,
      width: 200,
    },
    {
      field: 'differenceTime',
      headerName: 'Time Spend',
      cellClassName: 'gridCell',
      sortable: false,
      width: 300,
    },
  ]

  const timelogColumns = [
    {
      field: 'id',
      headerName: 'Id',
      cellClassName: 'gridCell',
      sortable: false,
      width: 80,
    },
    {
      field: 'userName',
      headerName: 'User Name',
      cellClassName: 'gridCell',
      sortable: false,
      width: 200,
    },
    {
      field: 'startTime',
      headerName: 'Start Time',
      cellClassName: 'gridCell',
      sortable: false,
      width: 200,
    },
    {
      field: 'endTime',
      headerName: 'End Time',
      cellClassName: 'gridCell',
      sortable: false,
      width: 200,
    },
    {
      field: 'differenceTime',
      headerName: 'Time Spend',
      cellClassName: 'gridCell',
      sortable: false,
      width: 300,
    },
  ]

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,

    borderRadius: 5,
    background: 'white',
    fontSize: '13.6px !important',
    color: '#8392ab !important',
    borderColor: 'rgba(5, 152, 236, 0.637) !important',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
  }

  const handleStart = () => {
    setStatus(STATUS.STARTED)
  }
  const handleStop = () => {
    setStatus(STATUS.STOPPED)
  }
  const handleReset = () => {
    setStatus(STATUS.STOPPED)
    setSecondsRemaining(INITIAL_COUNT)
  }
  useInterval(
    () => {
      if (secondsRemaining > -1) {

        setSecondsRemaining(secondsRemaining + 1)
        if (secondsRemaining % 60 == 50) {
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/PatientTimeLog/PatientEndTime?id=${timerId}&timeSpent=${hoursToDisplay}:${minutesToDisplay}:${secondsToDisplay}`,
              {
                headers,
              }
            )
            .then((response) => { })
            .catch((error) => {
              console.error('There was an error!', error)
            })
        }
      } else {
        setStatus(STATUS.STOPPED)
      }
    },
    status === STATUS.STARTED ? 1000 : null
    // passing null stops the interval
  )
  psyUseInterval(
    () => {
      if (psychotherapySecondsRemaining > -1) {
        setPsychotherapySecondsRemaining(psychotherapySecondsRemaining + 1)
        if (psychotherapySecondsRemaining % 60 == 50) {
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/PatientTimeLog/PatientEndTime?id=${psytimerId}&timeSpent=${psyHours}:${psyMins}:${psySeconds}`,
              {
                headers,
              }
            )
            .then((response) => { })
            .catch((error) => {
              console.error('There was an error!', error)
            })
        }
      } else {
        setPsychotherapyStatus(PSYCHOTHERAPYSTATUS.STOPPED)
      }
    },
    psychotherapyStatus === PSYCHOTHERAPYSTATUS.STARTED ? 1000 : null
    // passing null stops the interval
  )
  smokingUseInterval(
    () => {
      if (smokingSecondsRemaining > -1) {
        setSmokingSecondsRemaining(smokingSecondsRemaining + 1)
        if (smokingSecondsRemaining % 60 == 50) {
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/PatientTimeLog/PatientEndTime?id=${smokingtimerId}&timeSpent=${smkHours}:${smkMins}:${smkSeconds}`,
              {
                headers,
              }
            )
            .then((response) => { })
            .catch((error) => {
              console.error('There was an error!', error)
            })
        }
      } else {
        setSmokingStatus(SMOKINGSTATUS.STOPPED)
      }
    },
    smokingStatus === SMOKINGSTATUS.STARTED ? 1000 : null
    // passing null stops the interval
  )
  //AutoComplete Code
  const [open, setOpen] = React.useState(false)
  const [zipCodeList, setZipCodeList] = React.useState([])
  const [isZipCode, setIsZipCode] = React.useState(false)

  const loading = open && insuranceNames.length === 0

  const onChangeHandle = async (value) => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    // console.log(value)

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Settings/Searchcitystate?zipcode=${value}`,
        { headers }
      )
      .then((response) => {
        setzipDownloaded(false)
        setZipCodeList(response.data)
        setIsZipCode(true)
        setinsuranceNames(response.data)
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }
  const [dob, setdob] = React.useState()
  const [phone, setPhone] = React.useState()
  const [cellPhone, setcellPhone] = React.useState()
  const [workPhone, setworkPhone] = React.useState()
  const [ssn, setssn] = React.useState()
  ///Upload Pic Code
  const [images, setImages] = React.useState(null)
  /// Delete Code
  const deleteimg = () => {
    setImages(null)
    setValue('photoUrl', '')
  }
  /// Add Image
  const onImageChange = (e) => {
    setImages(URL.createObjectURL(e.target.files[0]))
    const file = e.target.files[0]
    convertBase64(file)
    // console.log("64 image",base64)
    console.log('Kdr Sy aya ha Console', URL.createObjectURL(e.target.files[0]))
  }
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    }).then((users) => {
      setValue('base64', users)
    })
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#F0FFFF',
    border: '2px solid #000',
    borderRadius: '20px',
    boxShadow: 24,
    p: 2,
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <SuiTypography
            component="label"
            variant="caption"
            fontSize="15px"
            style={{ fontWeight: 'bold' }}
          >
            Doing This Togehter
          </SuiTypography>
          <Grid container columnSpacing={1}>
            <Grid item xs={3}>
              <SuiTypography
                component="label"
                variant="caption"
                fontSize="15px"
                style={{ fontWeight: 'bold' }}
              >
                Fax No:
              </SuiTypography>
            </Grid>
            <Grid item xs={9}>
              <SuiInput
                type="input"
                onChange={(e) => setfax(e.target.value)}
              // placeholder="Account Number"
              // {...register('accountNumber')}
              // error={errors.accountNumber}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} lg={12} sm={12}>
            <Button
              onClick={handleClose}
              variant="gradient"
              disabled={submitting}
              style={{
                marginTop: '30px',
                marginBottom: '10px',
                float: 'right',
                marginLeft: '20px',
                width: '90px',
                height: '35px',
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
              }}
            // startIcon={<img src={CancelIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
            >
              <CancelOutlinedIcon
                fontSize="medium"
                style={{ color: 'red', paddingRight: '5px' }}
              ></CancelOutlinedIcon>
              Cancel
            </Button>
            <Button
              onClick={SentFax}
              variant="gradient"
              disabled={readOnly == true ? true : submitting}
              style={{
                marginTop: '30px',
                marginBottom: '10px',
                float: 'right',
                marginLeft: 'auto',
                width: '80px',
                height: '35px',
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
              }}
            // color="warning"
            // size="small"

            // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
            >
              <SendIcon
                fontSize="medium"
                style={{
                  color: '#1a82ad',
                  paddingRight: '5px',
                  paddingBottom: '2px',
                }}
              ></SendIcon>
              Send
            </Button>
          </Grid>
        </Box>
      </Modal>
      {submitting ? (
        <CircularProgress
          style={{
            width: '50px',
            height: '50px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginLeft: '-25px',
            marginTop: '-25px',
            zIndex: 1000,
            color: '#FCBE13',
          }}
        />
      ) : null}
      {_patientId !== undefined ? (<>
      {location.state?'':( <Typography onClick={handleClosesss} style={{ fontSize: '24px', fontWeight: 'bold', color: 'black', position: 'absolute', top: 0, right: 0,marginRight:'10px',marginTop:'-5px',color:'red' }}>
<CancelOutlinedIcon/>
</Typography>
)}
       
      
        <Grid
          container
          spacing={1}
          item
          xs={12}
          sm={12}
          xl={12}
          style={{
            // width: '100%',
            marginBottom: '10px',
            border: '1px solid rgba(5, 152, 236, 0.637)',
            borderRadius: '20px',
            marginTop: '10px',
            paddingBottom: '35px',
            marginLeft: '1px',
            paddingRight: '10px',
            paddingLeft: '20px',
            width: '1174px',
            height: '30px',
            backgroundColor: '#FFFFC2',
          }}
        >
        
          <Grid item xs={2} sm={2} xl={2} lg={2}>
            <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
              {patientinfo.patientName}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3} xl={3} lg={3}>
            <Grid container>
              <Grid item xs={3} sm={3} xl={3}>
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    textAlign: 'right',
                  }}
                >
                  Age:&nbsp;
                </Typography>
              </Grid>
              <Grid item xs={9} sm={9} xl={9}>
                <Typography style={{ fontSize: '14px' }}>
                  {patientinfo.age} (
                  {patientinfo.gender == 'Male'
                    ? 'M'
                    : patientinfo.gender == 'Female'
                      ? 'F'
                      : 'Other'}
                  )
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} sm={3} xl={3} lg={3}>
            <Grid container>
              <Grid item xs={4} sm={4} xl={4}>
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    textAlign: 'right',
                  }}
                >
                  Provider:&nbsp;
                </Typography>
              </Grid>
              <Grid item xs={7} sm={7} xl={7}>
                <Typography style={{ fontSize: '14px' }}>
                  {patientinfo.providerName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={2} sm={2} xl={2}> */}
          {/* <Grid container>
            <Grid item xs={5} sm={5} xl={5}>
              <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>
                Care Team:
              </Typography>
            </Grid>
            <Grid item xs={7} sm={7} xl={7}>
              <Typography style={{ fontSize: '14px' }}>
                {patientinfo.careTeamName}
              </Typography>
            </Grid>
          </Grid> */}
          {/* </Grid> */}
          <Grid item xs={2} sm={2} xl={2} lg={2}>
            <Grid container>
              <Grid item xs={5} sm={5} xl={5}>
                <Typography
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    textAlign: 'right',
                  }}
                >
                  Program:&nbsp;
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                  {patientinfo.program}
                </Typography>
              </Grid>
            </Grid>
          </Grid>


          <Grid item xs={4} sm={2} xl={2} lg={2}>
            <CallComponent
              patientId={patientid}
            />
            {/* <VideoComponent
              patientId={patientid}
            /> */}
          </Grid>



          {/* <Grid item xs={2} sm={2} xl={2}>
          <Grid container>
            <Grid item xs={7} sm={7} xl={7}>
              <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>
              Smoking Status:
              </Typography>
            </Grid>
            <Grid item xs={5} sm={5} xl={5}>
              <Typography style={{ fontSize: '14px' }}>
                {patientinfo.smokingStatus}
              </Typography>
            </Grid>
          </Grid> */}
          {/* </Grid> */}
        </Grid></>
      ) : (
        ''
      )}
      <Grid container>
        <Grid item xs={12}>
          <div>
            {/* {_patientId != undefined ? (
          <div style={{ padding: 20, float: 'right', color: 'red' }}>
            {twoDigits(hoursToDisplay)}:{twoDigits(minutesToDisplay)}:
            {twoDigits(secondsToDisplay)}
          </div>
        ) : null} */}
            {_patientId != undefined &&
              _patientId > 0 &&
              status == STATUS.STARTED ? (
              <div style={{ padding: 14, float: 'right', color: 'green' }}>
                {twoDigits(hoursToDisplay)}:{twoDigits(minutesToDisplay)}:
                {twoDigits(secondsToDisplay)}{' '}
                <PauseCircleRounded
                  style={{ position: 'absolute', marginTop: '6px' }}
                  onClick={(e) => PauseTimerHandler(STATUS.STOPPED)}
                />
              </div>
            ) : null}
            {_patientId != undefined &&
              _patientId > 0 &&
              status == STATUS.STOPPED ? (
              <div style={{ padding: 14, float: 'right', color: 'red' }}>
                {twoDigits(hoursToDisplay)}:{twoDigits(minutesToDisplay)}:
                {twoDigits(secondsToDisplay)}{' '}
                <PlayCircleRounded
                  style={{ position: 'absolute', marginTop: '6px' }}
                  onClick={(e) => StartTimerHandler(STATUS.STARTED)}
                />
              </div>
            ) : null}
            {isDisplayPsychotherapyTimer === true ? (
              <div
                style={{
                  marginTop: '15px',
                  float: 'right',
                  color: 'orange',
                  visibility: { isDisplayPsychotherapyTimer },
                }}
              >
                {twoDigits(psyHours)}:{twoDigits(psyMins)}:
                {twoDigits(psySeconds)}
              </div>
            ) : (
              ''
            )}
            {isDisplaySmokingTimer === true ? (
              <div
                style={{
                  marginTop: '15px',
                  float: 'right',
                  color: 'green',
                  visibility: { isDisplaySmokingTimer },
                }}
              >
                {twoDigits(smkHours)}:{twoDigits(smkMins)}:
                {twoDigits(smkSeconds)}
              </div>
            ) : (
              ''
            )}
          </div>
        </Grid>
      </Grid>

      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            variant="scrollable"
          >
           {_patientId !== undefined ? (
              <Tab
                value={0}
                icon={<AssessmentIcon />}
                label="ASSESSMENTS"
              />
            ) : (
              <></>
            )}
           
           {_patientId !== undefined ? (
              <Tab value={1} icon={<TextSnippetIcon />} label="NOTES" />
            ) : (
              <></>
            )}
         
         {_patientId !== undefined && psychotherapistPriviledge == 'true' ? (
              <Tab
                value={2}
                icon={<AssignmentTurnedInIcon />}
                label="PSYCHOTHERAPY SESSION"
              />
            ) : (
              ''
            )}
         
            <Tab
              value={3}
              TimerStatus={setTimerStatus}
              icon={<PermIdentity />}
              label="DEMOGRAPHICS"
            />

{_patientId !== undefined ? (
              <Tab
                value={4}
                icon={<WorkspacesIcon />}
                label="CARE TEAM"
              />
            ) : (
              <></>
            )} 
          
       
          {_patientId !== undefined ? (
              <Tab value={5} icon={<FitScreenIcon />} label="SCREENING" />
            ) : (
              <>
              </>
            )}
          
          {_patientId !== undefined?
              <Tab
                value={6}
                icon={<SmokeFreeIcon />}
                label="SMOKING CESSATION"
              />:''}
            
           
          
            {_patientId !== undefined?
            <Tab value={7} label="PDF" icon={<PictureAsPdfIcon />} />:''}
    {_patientId !== undefined?
              <Tab value={8} icon={<MoreTimeRoundedIcon />} label="Appointment" />:''}
         

    
             
             
         {_patientId !== undefined?
<Tab
  value={9}
  icon={<HistoryIcon />}
  label="MEDICAL HISTORY"

/>:''}
 {_patientId !== undefined && dtt_userType === 'Admin'?
              <Tab
  value={10}
  icon={<AccessTimeFilledIcon />}
  label="TIME LOG"
 
/>:''}

 
             
              {/* {_patientId !== undefined && dtt_userType == 'Admin' ? (
              <Tab value={10} icon={<MoreTimeRoundedIcon />} label="TIME LOG" />
            ) : (
              <></>
            )} */}
         
          </TabList>
        </Box>

   
        {_patientId !== undefined ? (
          <TabPanel value={0}>
            <SuiBox mb={3}>
              <Grid
                spacing={1}
                alignContent="left"
                justifyContent="left"
                style={{
                  width: '100% !important',
                  marginLeft: '0px !important',
                }}
              >
                <Accordion
                  style={{
                    marginBottom: '10px',
                    border: '1px solid rgba(5, 152, 236, 0.637)',
                    borderRadius: '20px',
                    marginTop: '10px',
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Monthly Summary</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <p style={{ fontSize: '12px', color: 'red' }}>
                        {dataError}
                      </p>

                      <Grid container spacing={1}>
                        <Grid item xs={6} sm={3} xl={6}>
                          <Typography style={{ fontSize: '14px' }}>
                            Month
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3} xl={6}>
                          <Typography style={{ fontSize: '14px' }}>
                            Year
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={3} xl={6}>
                          <SuiSelect
                            // value={{ label: fmonth }}
                            onChange={(value) => {
                              setFmonth(value.value)
                              setMonthError(false)
                            }}
                            options={[
                              { value: '1', label: 'January' },
                              { value: '2', label: 'February' },
                              { value: '3', label: 'March' },
                              { value: '4', label: 'April' },
                              { value: '5', label: 'May' },
                              { value: '6', label: 'June' },
                              { value: '7', label: 'July' },
                              { value: '8', label: 'August' },
                              { value: '9', label: 'September' },
                              { value: '10', label: 'October' },
                              { value: '11', label: 'November' },
                              { value: '12', label: 'December' },
                            ]}
                          />
                          {monthError ? (
                            <Typography
                              color="error"
                              style={{ fontSize: '14px' }}
                            >
                              Please Select Month!
                            </Typography>
                          ) : null}
                        </Grid>
                        <Grid item xs={6} sm={3} xl={6}>
                          <SuiSelect
                            value={{ label: fyear }}
                            onChange={(value) => {
                              setFyear(value.value)
                              setYearError(false)
                            }}
                            options={[
                              { value: '2022', label: '2022' },
                              { value: '2023', label: '2023' },
                              { value: '2024', label: '2024' },
                              { value: '2025', label: '2025' },
                            ]}
                          />
                          {yearError ? (
                            <Typography
                              color="error"
                              style={{ fontSize: '14px' }}
                            >
                              Please Select Year!
                            </Typography>
                          ) : null}
                        </Grid>
                      </Grid>
                      <div>
                        <Button
                          onClick={onDownload}
                          variant="gradient"
                          style={{
                            marginTop: '10px',
                            marginBottom: '10px',
                            marginLeft: 'auto',
                            width: '100px',
                            float: 'right',
                            marginRight: '5px',
                            height: '35px',
                            backgroundColor: '#FCBE13',
                            color: 'black',
                            fontSize: '14px',
                          }}
                        >
                          <SearchOutlinedIcon
                            fontSize="medium"
                            style={{ color: '#0a9ff0', paddingRight: '5px' }}
                          ></SearchOutlinedIcon>
                          Search
                        </Button>

                        <Button
                          onClick={onFilterClear}
                          variant="gradient"
                          style={{
                            marginTop: '10px',
                            marginBottom: '10px',
                            float: 'right',
                            marginRight: '5px',
                            marginLeft: 'auto',
                            width: '80px',
                            height: '35px',
                            backgroundColor: '#FCBE13',
                            color: 'black',
                            fontSize: '14px',
                          }}
                        >
                          <CleaningServicesOutlinedIcon
                            fontSize="medium"
                            style={{ color: '#0a9ff0', paddingRight: '5px' }}
                          ></CleaningServicesOutlinedIcon>
                          Clear
                        </Button>
                        {fileName != null && fileName.length > 0 ? (
                          <Button
                            onClick={onFilterSearchAndDownload}
                            variant="gradient"
                            disable={readOnly == true ? true : false}
                            style={{
                              marginTop: '10px',
                              marginBottom: '10px',
                              float: 'right',
                              marginRight: '5px',
                              marginLeft: 'auto',
                              width: '185px',
                              height: '35px',
                              backgroundColor: '#FCBE13',
                              color: 'black',
                              fontSize: '14px',
                            }}
                          >
                            <DownloadForOfflineOutlinedIcon
                              fontSize="medium"
                              style={{ color: '#0a9ff0', paddingRight: '5px' }}
                            ></DownloadForOfflineOutlinedIcon>
                            Download File
                          </Button>
                        ) : null}
                        {fileName != null && fileName.length ? (
                          <Button
                            onClick={handleOpen}
                            variant="gradient"
                            disable={readOnly == true ? true : false}
                            style={{
                              marginTop: '10px',
                              marginBottom: '10px',
                              float: 'right',
                              marginRight: '5px',
                              marginLeft: 'auto',
                              width: '80px',
                              height: '35px',
                              backgroundColor: '#FCBE13',
                              color: 'black',
                              fontSize: '14px',
                            }}
                          >
                            <LocalPrintshopOutlinedIcon
                              fontSize="medium"
                              style={{ color: '#0a9ff0', paddingRight: '5px' }}
                            ></LocalPrintshopOutlinedIcon>
                            Fax
                          </Button>
                        ) : null}
                        {/* {fileName != null && fileName.length > 0 ? (
                        <Link
                          // onClick={onEmailClicked}
                          variant="gradient"
                          color="info"
                          style={{
                            marginTop: '12px',
                            marginRight: '10px',
                            float: 'right',
                            display: 'inline-block',
                          }}
                        >
                          Email
                        </Link>
                      ) : null} */}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  {patientPHQ9 !== null &&
                    patientPHQ9.labels !== undefined &&
                    patientPHQ9.labels.length > 0 ? (
                    <GradientLineChart
                      title="PHQ-9"
                      height="20.25rem"
                      chart={patientPHQ9}
                    />
                  ) : (
                    <Typography>No Record found for PHQ-9</Typography>
                  )}
                </Grid>
                <Grid item xs={12} lg={6}>
                  {patientGAD7 !== null &&
                    patientGAD7.labels !== undefined &&
                    patientGAD7.labels.length > 0 ? (
                    <GradientLineChart
                      title="GAD-7"
                      height="20.25rem"
                      width="100px"
                      chart={patientGAD7}
                    />
                  ) : (
                    <Typography>No Record found for GAD-7</Typography>
                  )}
                </Grid>

                <Grid xs={6}>
                  <Button
                    sx={{ mb: 2, marginRight: '20px', float: 'left' }}
                    // onClick={handleOpenn}
                    // onClose={handleClosee}
                    onClick={showPHQ ? handleClosee : handleOpenn}
                    variant="gradient"
                    style={{
                      marginTop: ' 10px',
                      marginBottom: '10px',
                      paddingBottom: '10px',
                      marginLeft: '30px',
                      width: '60px',
                      // height: '20px',
                      backgroundColor: '#FCBE13',
                      color: 'black',
                      fontSize: '14px',
                    }}
                  >
                    {/* {showPHQ ? "Close" : "New"} */}
                    New
                  </Button>
                </Grid>
                <Grid xs={6}>
                  <Button
                    sx={{ mb: 2, marginRight: '20px' }}
                    onClick={showGAD ? handleClose1 : handleOpen1}
                    // onClick={handleOpen1}
                    // onClose={handleClose1}
                    variant="gradient"
                    style={{
                      marginTop: ' 10px',
                      marginBottom: '10px',
                      paddingBottom: '10px',
                      marginLeft: '30px',
                      width: '60px',
                      // height: '20px',
                      backgroundColor: '#FCBE13',
                      color: 'black',
                      fontSize: '14px',
                    }}
                  >
                    New
                  </Button>
                </Grid>
                {showPHQ && (
                  <PHQ onComplete={handlePhqCompleted} getRespRefresh={getRespRefresh} patientId={_patientId} />
                )}
                {showGAD && (
                  <GAD onComplete={handlePhqCompleted} getRespRefresh={getRespRefresh} patientId={_patientId} />
                )}
              </Grid>
            </SuiBox>

            <Grid
              container
              spacing={1}
              alignContent="center"
              justifyContent="center"
              style={{ paddingTop: '10px' }}
            >
              <Grid item xs={6} style={{ width: '300px' }}>
                <SuiBox mb={3}>
                  {phq9Rows === null || phq9Rows.length === 0 ? null : (
                    <div style={{ height: 400 }}>
                      <Typography>PHQ-9</Typography>
                      <DataGrid
                        rows={phq9Rows}
                        columns={Phq9Columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        onRowClick={(event) => {
                          // console.log('Row____________________', event)
                          event.row.isRead = true
                          // params.isRead=!params.isRead;
                          handleRowClick(event)
                        }}
                        sx={gridRowStyle}
                      />
                    </div>
                  )}
                </SuiBox>
              </Grid>
              <Grid item xs={6} style={{ width: '300px' }}>
                <SuiBox mb={3}>
                  {gad7Rows === null || gad7Rows.length === 0 ? (
                    <></>
                  ) : (
                    <div style={{ height: 400 }}>
                      <Typography>GAD-7</Typography>
                      <DataGrid
                        rows={gad7Rows}
                        columns={Gad7Columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        onRowClick={(event) => {
                          // console.log('Row____________________', event)
                          event.row.isRead = true
                          // params.isRead=!params.isRead;
                          handleRowClickGAD7(event)
                        }}
                        sx={gridRowStyle}


                      />
                    </div>
                  )}
                </SuiBox>
              </Grid>
            </Grid>
          </TabPanel>
        ) : (
          ''
        )}
    

    <TabPanel value={1}>
          <PatientNotes
            patientId={_patientId}
            readOnly={readOnly}
          ></PatientNotes>
        </TabPanel>

        <TabPanel value={2}>
          {submitting && <div>Saving Form...</div>}
          <Grid
            container
            spacing={1}
            alignContent="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <Button
                onClick={startPsychotherapySession}
                disabled={readOnly == true ? true : isStartButtonDisabled}
                variant="gradient"
                style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                  marginRight: '5px',
                  marginLeft: 'auto',
                  width: '185px',
                  height: '35px',
                  backgroundColor: '#FCBE13',
                  color: 'black',
                  fontSize: '14px',
                }}
              >
                <PlayCircleOutlineOutlinedIcon
                  fontSize="medium"
                  style={{ color: '#0a9ff0', paddingRight: '5px' }}
                ></PlayCircleOutlineOutlinedIcon>
                START SESSION
              </Button>
              <Button
                variant="gradient"
                onClick={stopPsychotherapySession}
                disabled={isStopButtonDisabled}
                style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                  marginRight: '5px',
                  marginLeft: 'auto',
                  width: '185px',
                  height: '35px',
                  backgroundColor: '#FCBE13',
                  color: 'black',
                  fontSize: '14px',
                }}
              >
                <StopCircleOutlinedIcon
                  fontSize="medium"
                  style={{ color: '#0a9ff0', paddingRight: '5px' }}
                ></StopCircleOutlinedIcon>
                END SESSION
              </Button>
              <PatientNotes
                patientId={_patientId}
                readOnly={readOnly}
              ></PatientNotes>
              {/* <SuiBox mb={3}>
                <div style={{ height: 400, width: '100%' }}>
                  {psychotherapyRows === null ||
                  psychotherapyRows.length === 0 ? null : (
                    <DataGrid
                      rows={psychotherapyRows}
                      columns={psychotherapyColumns}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      sx={gridRowStyle}
                    />
                  )}
                </div>
              </SuiBox> */}
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={3} style={{ marginTop: '-13px' }}>
          <Demographics patientId={_patientId} handleclose={handleclose} callbackDemo={(id) => {
            console.log('Id____________', id)
            setpatientid(id)

          }} readOnly={readOnly} />
        </TabPanel>

        <TabPanel value={4}>
          <PatientCareTeam
            patientId={_patientId}
            readOnly={readOnly}
          ></PatientCareTeam>
        </TabPanel>

        <TabPanel value={5}>
          <Screening
          datamining={datamining}
            patientId={_patientId}
            readOnly={readOnly}
            patientName={patientinfo.patientName}
            age={patientinfo.age}
            gender={patientinfo.gender}
          ></Screening>
        </TabPanel>

        <TabPanel value={6}>
          {submitting && <div>Saving Form...</div>}
          <Grid
            container
            spacing={1}
            alignContent="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <SuiButton
                variant="gradient"
                onClick={startSmokingSession}
                // disabled={
                //   readOnly == true ? true : isStartSmokingButtonDisabled
                // }
                color="success"
                style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                  marginLeft: 'auto',
                  display: 'inline-block',
                  marginLeft: '20px',
                }}
              >
                START SMOKING SESSION
              </SuiButton>
              <SuiButton
                variant="gradient"
                color="error"
                onClick={stopSmokingSession}
                disabled={isStopSmokingButtonDisabled}
                style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                  marginLeft: '20px',
                  display: 'inline-block',
                }}
              >
                END SMOKING SESSION
              </SuiButton>
              <SuiBox mb={1}>
                <div style={{ height: 400, width: '100%' }}>
                  {smokingRows === null || smokingRows.length === 0 ? null : (
                    <DataGrid
                      rows={smokingRows}
                      columns={smokingColumns}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      sx={gridRowStyle}
                    />
                  )}
                </div>
              </SuiBox>
            </Grid>
          </Grid>
        </TabPanel>

          <TabPanel value={10}>
            <Timelog patientId={_patientId} readOnly={readOnly}></Timelog>

          </TabPanel>
   

    


<TabPanel value={8}>
          <Appointments  patientName={patientName} patientLastName={patientLastName}  patientId={_patientId} datamining={datamining}
            readOnly={readOnly}
          />

        </TabPanel>


        <TabPanel value={9} style={{ marginTop: '-13px' }}>
          <MedicalHistory patientId={_patientId} />
        </TabPanel>
        

      


        <TabPanel value={7}>
          <PatientDocuments
            patientId={_patientId}
            readOnly={readOnly}
          ></PatientDocuments>
        </TabPanel>

      </TabContext>
    </>
  )
}

export default PatientRegistration
