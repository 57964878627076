import React, { useEffect, useReducer, useState, Fragment } from 'react'
import SuiInput from 'components/SuiInput'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import { createBrowserHistory } from 'history'
import axios from 'axios'
const history = createBrowserHistory({ forceRefresh: true })
const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
const headers = {
  Authorization: auth,
}
function Mdq(props) {
  const id = props.id
  const [question1, setQuestion1] = useState('')
  const [question2, setQuestion2] = useState('')
  const [question3, setQuestion3] = useState('')
  const [question4, setQuestion4] = useState('')
  const [question5, setQuestion5] = useState('')
  const [question6, setQuestion6] = useState('')
  const [question7, setQuestion7] = useState('')
  const [question8, setQuestion8] = useState('')
  const [question9, setQuestion9] = useState('')
  const [question10, setQuestion10] = useState('')
  const [question11, setQuestion11] = useState('')
  const [question12, setQuestion12] = useState('')
  const [question13, setQuestion13] = useState('')
  const [question14, setQuestion14] = useState('')
  const [question15, setQuestion15] = useState('')
  const [question16, setQuestion16] = useState('')
  const [question17, setQuestion17] = useState('')

  const [date, setDate] = useState('')

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    let isComponentMounted = true
    const fetchData = async () =>
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/PatientPortal/getMdq17ById?Id=${
            id !== undefined ? id : 0
          }`,
          { headers }
          // `${process.env.REACT_APP_API_URL}/Facilities/getFacilityById?id=${
          //   location.state.facilityId !== undefined ? location.state.facilityId : 0
          // }`,
          // null,
          // {
          //   headers,
          // }
        )
        .then((response) => {
          //   console.log('response.data: ', response.data)
          setQuestion1(response.data.q1)
          setQuestion2(response.data.q2)
          setQuestion3(response.data.q3)
          setQuestion4(response.data.q4)
          setQuestion5(response.data.q5)
          setQuestion6(response.data.q6)
          setQuestion7(response.data.q7)
          setQuestion8(response.data.q8)
          setQuestion9(response.data.q9)
          setQuestion10(response.data.q10)
          setQuestion11(response.data.q11)
          setQuestion12(response.data.q12)
          setQuestion13(response.data.q13)
          setQuestion14(response.data.q14)
          setQuestion15(response.data.q15)
          setQuestion16(response.data.q16)
          setQuestion17(response.data.q17)
        })
        .catch((error) => {
          console.error('There was an error!', error)
        })
    fetchData()
    return () => {
      isComponentMounted = false
    }

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [])
  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            style={{
              fontSize: '50px',
              fontFamily: 'algebra',
              textAlign: 'center',
              backgroundColor: '#6D2985',
              height: '115px',
              justifyContent: 'center',
              alignSelf: 'center',
              padding: '20px',
              borderRadius: '20px',
              color: 'white',
            }}
          >
            THE MOOD DISORDER QUESTIONNAIRE
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              borderBottom: '3px solid #6D2985',
              fontFamily: 'italian',
              margin: '20px',
              textAlign: 'center',
            }}
          >
            Instructions: Please answer each question to the best of your
            ability
          </Typography>
        </Grid>
        <table
          className="table table-bordered border-dark table-striped table-hover"
          style={{ border: '2px solid black' }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'left',
                }}
                scope="col"
                colSpan={2}
              >
                1. Has there ever been a period of time when you were not your
                usual self and...{' '}
              </th>
              <th
                scope="col"
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                NO
              </th>
              <th
                scope="col"
                style={{ borderBottom: '1px solid black', textAlign: 'center' }}
              >
                YES
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  borderBottom: '1px solid black',
                }}
                scope="col"
              ></td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  paddingLeft: '10px',
                }}
              >
                ...you felt so good or so hyper that other people thought you
                were not your normal self or you were so hyper that you got into
                trouble?
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question1"
                  checked={!question1}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question1"
                  checked={question1}
                  value="true"
                ></input>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: '1px solid black',
                }}
                scope="col"
              ></td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  paddingLeft: '10px',
                }}
              >
                ...you were so irritable that you shouted at people or started
                fights or arguments?
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question2"
                  checked={!question2}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question2"
                  checked={question2}
                  value="true"
                ></input>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: '1px solid black',
                }}
                scope="col"
              ></td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  paddingLeft: '10px',
                }}
              >
                ...you felt much more self-confident than usual?
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question3"
                  checked={!question3}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question3"
                  checked={question3}
                  value="true"
                ></input>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  borderBottom: '1px solid black',
                }}
                scope="col"
              ></td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  paddingLeft: '10px',
                }}
              >
                ...you got much less sleep than usual and found you didn’t
                really miss it?
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question4"
                  checked={!question4}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question4"
                  checked={question4}
                  value="true"
                ></input>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: '1px solid black',
                }}
                scope="col"
              ></td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  paddingLeft: '10px',
                }}
              >
                ...you were much more talkative or spoke much faster than usual?{' '}
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question5"
                  checked={!question5}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question5"
                  checked={question5}
                  value="true"
                ></input>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: '1px solid black',
                }}
                scope="col"
              ></td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  paddingLeft: '10px',
                }}
              >
                ..thoughts raced through your head or you couldn’t slow your
                mind down?{' '}
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question6"
                  checked={!question6}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question6"
                  checked={question6}
                  value="true"
                ></input>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: '1px solid black',
                }}
                scope="col"
              ></td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  paddingLeft: '10px',
                }}
              >
                ...you were so easily distracted by things around you that you
                had trouble concentrating or staying on track?{' '}
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question7"
                  checked={!question7}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question7"
                  checked={question7}
                  value="true"
                ></input>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: '1px solid black',
                }}
                scope="col"
              ></td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  paddingLeft: '10px',
                }}
              >
                ...you had much more energy than usual?
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question8"
                  checked={!question8}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question8"
                  checked={question8}
                  value="true"
                ></input>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: '1px solid black',
                }}
                scope="col"
              ></td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  paddingLeft: '10px',
                }}
              >
                ...you were much more active or did many more things than usual?
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question9"
                  checked={!question9}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question9"
                  checked={question9}
                  value="true"
                ></input>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: '1px solid black',
                }}
                scope="col"
              ></td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  paddingLeft: '10px',
                }}
              >
                ...you were much more social or outgoing than usual, for
                example, you telephoned friends in the middle of the night?
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question10"
                  checked={!question10}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question10"
                  checked={question10}
                  value="true"
                ></input>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: '1px solid black',
                }}
                scope="col"
              ></td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  paddingLeft: '10px',
                }}
              >
                ...you were much more interested in sex than usual?
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question11"
                  checked={!question11}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question11"
                  checked={question11}
                  value="true"
                ></input>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: '1px solid black',
                }}
                scope="col"
              ></td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  paddingLeft: '10px',
                }}
              >
                ...you did things that were unusual for you or that other people
                might have thought were excessive, foolish, or risky?
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question12"
                  checked={!question12}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question12"
                  checked={question12}
                  value="true"
                ></input>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: '1px solid black',
                }}
                scope="col"
              ></td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  paddingLeft: '10px',
                }}
              >
                ...spending money got you or your family into trouble?
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question13"
                  checked={!question13}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question13"
                  checked={question13}
                  value="true"
                ></input>
              </td>
            </tr> 
            <tr>
              <td
                style={{
                  borderBottom: '1px solid black',
                }}
                scope="col"
              ></td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
              >
                2. If you checked YES to more than one of the above, have
                several of these ever happened during the same period of time?
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question14"
                  checked={!question14}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question14"
                  checked={question14}
                  value="true"
                ></input>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: '1px solid black',
                }}
                scope="col"
              ></td>
              <td
                scope="col"
                style={{
                  borderBottom: '1px solid black',
                }}
              >
                3. How much of a problem did any of these cause you – like being
                unable to work; having family, money or legal troubles; getting
                into arguments or fights?
                <br />{' '}
                <span style={{ fontFamily: 'sans-serif', fontSize: '12px' }}>
                  {' '}
                  Please circle one response only.
                </span>{' '}
                <br />
                <span
                  style={{
                    fontFamily: 'calibiri',
                    fontSize: '15px',
                    marginRight: '20px',
                  }}
                >
                  {' '}
                  <input
                    class="form-check-input"
                    type="radio"
                    name="question15"
                    checked={question15 == 0}
                    value='0'
                  ></input>{' '}
                  No Problem{' '}
                </span>{' '}
                <span
                  style={{
                    fontFamily: 'calibiri',
                    fontSize: '15px',
                    marginRight: '20px',
                  }}
                >
                  {' '}
                  <input
                    class="form-check-input"
                    type="radio"
                    name="question15"
                    checked={question15 == 1}
                    value='1'
                  ></input>
                  Minor Problem
                </span>{' '}
                <span
                  style={{
                    fontFamily: 'calibiri',
                    fontSize: '15px',
                    marginRight: '20px',
                  }}
                >
                  {' '}
                  <input
                    class="form-check-input"
                    type="radio"
                    name="question15"
                    checked={question15 == 2}
                    value='2'
                  ></input>
                  Moderate Problem{' '}
                </span>{' '}
                <span style={{ fontFamily: 'calibiri', fontSize: '15px' }}>
                  {' '}
                  <input
                    class="form-check-input"
                    type="radio"
                    name="question15"
                    checked={question15 == 3}
                    value='3'
                  ></input>
                  Serious Problem
                </span>
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              ></td>
              <td style={{ borderBottom: '1px solid black' }}></td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: '1px solid black',
                }}
                scope="col"
              ></td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                }}
              >
                4. Have any of your blood relatives (i.e. children, siblings,
                parents, grandparents, aunts, uncles) had manic-depressive
                illness or bipolar disorder?
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question16"
                  checked={!question16}
                  value="false"
                ></input>
              </td>
              <td
                style={{
                  borderBottom: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question16"
                  checked={question16}
                  value="true"
                ></input>
              </td>
            </tr>
            <tr>
              <td style={{ borderBottom: '1px solid black' }} scope="col"></td>
              <td style={{ borderRight: '1px solid black' }}>
                5. Has a health professional ever told you that you have
                manic-depressive illness or bipolar disorder?{' '}
              </td>
              <td
                style={{
                  borderRight: '1px solid black',
                  textAlign: 'center',
                }}
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="question17"
                  checked={!question17}
                  value="false"
                ></input>
              </td>
              <td style={{ textAlign: 'center', backgroundColor: '#F1F4FF' }}>
                <input
                  class="form-check-input"
                  type="radio"
                  name="question17"
                  checked={question17}
                  value="true"
                ></input>
              </td>
            </tr>
          </tbody>
        </table>
        <Grid item xs={12} style={{ marginTop: '30px' }}>
          <Typography
            style={{
              fontSize: '50px',
              fontFamily: 'algebra',
              textAlign: 'center',
              backgroundColor: '#6D2985',
              height: '115px',
              justifyContent: 'center',
              alignSelf: 'center',
              padding: '20px',
              borderRadius: '20px',
              color: 'white',
            }}
          >
            SCORING THE MOOD DISORDER QUESTIONNAIRE (MDQ){' '}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              borderBottom: '3px solid #6D2985',
              fontFamily: 'italian',
              margin: '20px',
              textAlign: 'center',
            }}
          ></Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              fontFamily: 'italian',
              margin: '5x',
              textAlign: 'left',
              fontSize: '15px',

              justifyContent: 'end',
            }}
          >
            The MDQ was developed by a team of psychiatrists, researchers and
            consumer advocates to address a critical need for timely and
            accurate diagnosis of bipolar disorder, which can be fatal if left
            untreated. The questionnaire takes about five minutes to complete,
            and can provide important insights into diagnosis and treatment.
            Clinical trials have indicated that the MDQ has a high rate of
            accuracy; it is able to identify seven out of ten people who have
            bipolar disorder and screen out nine out of ten people who do not.
            <sup>1</sup>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              fontFamily: 'italian',
              margin: '5px',
              textAlign: 'left',
              fontSize: '15px',

              justifyContent: 'end',
            }}
          >
            A recent National DMDA survey revealed that nearly 70% of people
            with bipolar disorder had received at least one misdiagnosis and
            many had waited more than 10 years from the onset of their symptoms
            before receiving a correct diagnosis. National DMDA hopes that the
            MDQ will shorten this delay and help more people to get the
            treatment they need, when they need it.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              fontFamily: 'italian',
              margin: '5px',
              textAlign: 'left',
              fontSize: '15px',

              justifyContent: 'end',
            }}
          >
            The MDQ screens for Bipolar Spectrum Disorder, (which includes
            Bipolar I, Bipolar II and Bipolar NOS).
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              fontFamily: 'italian',
              margin: '10px',
              textAlign: 'left',
              justifyContent: 'end',
              fontWeight: 'bold',
              fontSize: '15px',
            }}
          >
            If the patient answers:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              fontFamily: 'italian',
              margin: '10px',
              textAlign: 'left',
              fontSize: '15px',

              justifyContent: 'end',
            }}
          >
            1. <b>“Yes”</b> to seven or more of the 13 items in question number
            1;
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              fontFamily: 'italian',
              margin: '10px',
              textAlign: 'left',
              justifyContent: 'end',
              fontSize: '15px',
            }}
          >
            AND
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              fontFamily: 'italian',
              margin: '10px',
              textAlign: 'left',
              justifyContent: 'end',
              fontSize: '15px',
            }}
          >
            2. <b>“Yes”</b> to question number 2;
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              fontFamily: 'italian',
              margin: '10px',
              textAlign: 'left',
              justifyContent: 'end',
              fontSize: '15px',
            }}
          >
            AND
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              fontFamily: 'italian',
              margin: '10px',
              textAlign: 'left',
              justifyContent: 'end',
              fontSize: '15px',
            }}
          >
            3. <b>“Moderate”</b> or <b>“Serious”</b> to question number 3;
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              fontFamily: 'timeRoman',
              margin: '10px',
              textAlign: 'left',
              fontSize: '15px',

              justifyContent: 'end',
            }}
          >
            you have a positive screen. All three of the criteria above should
            be met. A positive screen should be followed by a comprehensive
            medical evaluation for Bipolar Spectrum Disorder.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              fontFamily: 'italian',
              margin: '10px',
              fontSize: '15px',
              textAlign: 'left',
              justifyContent: 'end',
            }}
          >
            <b>ACKNOWLEDGEMENT:</b> This instrument was developed by a committee
            composed of the following individuals: Chairman, Robert M.A.
            Hirschfeld, MD – University of Texas Medical Branch; Joseph R.
            Calabrese, MD – Case Western Reserve School of Medicine; Laurie
            Flynn – National Alliance for the Mentally Ill; Paul E. Keck, Jr.,
            MD – University of Cincinnati College of Medicine; Lydia Lewis –
            National Depressive and Manic-Depressive Association; Robert M.
            Post, MD – National Institute of Mental Health; Gary S. Sachs, MD –
            Harvard University School of Medicine; Robert L. Spitzer, MD –
            Columbia University; Janet Williams, DSW – Columbia University and
            John M. Zajecka, MD – Rush Presbyterian-St. Luke’s Medical Center.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              fontFamily: 'serif',
              margin: '30px',
              fontSize: '10px',
              textAlign: 'left',
              justifyContent: 'end',
            }}
          >
            <sup>1</sup>Hirschfeld, Robert M.A., M.D., Janet B.W. Williams,
            D.S.W., Robert L. Spitzer, M.D., Joseph R. Calabrese, M.D., Laurie
            Flynn, Paul E. Keck, Jr., M.D., Lydia Lewis, Susan L. McElroy, M.D.,
            Robert M. Post, M.D., Daniel J. Rapport, M.D., James M. Russell,
            M.D., Gary S. Sachs, M.D., John Zajecka, M.D., “Development and
            Validation of a Screening Instrument for Bipolar Spectrum Disorder:
            The Mood Disorder Questionnaire.” American Journal of Psychiatry
            157:11 (November 2000) 1873-1875.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              borderBottom: '3px solid #6D2985',
              fontFamily: 'italian',
              margin: '20px',
              textAlign: 'center',
            }}
          ></Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export default Mdq
