import React, { useEffect, useReducer, useState, Fragment } from 'react'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import { alignProperty } from '@mui/material/styles/cssUtils'
import { createBrowserHistory } from 'history'
import axios from 'axios'
 const history = createBrowserHistory({ forceRefresh: true })
const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
const headers = {
  Authorization: auth,
}
 export default function AUDIT_C(props) {
console.log(props,'refresh')
     const id=props.id
    const [question1, setQuestion1] = useState('')
    const [question2, setQuestion2] = useState('')
    const [question3, setQuestion3] = useState('')
 
    const [date, setDate] = useState('')
    
    useEffect(() => {
        // POST request using axios inside useEffect React hook
        let isComponentMounted = true
        const fetchData = async () =>
          axios
            .get(
              `${
                process.env.REACT_APP_API_URL
              }/PatientPortal/getAuditc3ById?Id=${
                id !== undefined ? id : 0
              }`,
              { headers }
              // `${process.env.REACT_APP_API_URL}/Facilities/getFacilityById?id=${
              //   location.state.facilityId !== undefined ? location.state.facilityId : 0
              // }`,
              // null,
              // {
              //   headers,
              // }
            )
            .then((response) => {
              console.log('response.data: ', response.data)
              setQuestion1(response.data.q1)
              setQuestion2(response.data.q2)
              setQuestion3(response.data.q3)
              
              setDate(response.data.date)

            })
            .catch((error) => {
              console.error('There was an error!', error)
            })
        fetchData()
        return () => {
          isComponentMounted = false
        }
    
        // empty dependency array means this effect will only run once (like componentDidMount in classes)
      }, [])


  return (
    <Grid className='container'>
      <Typography variant='h1'
        align='center'>AUDIT-C Questionnaire
      </Typography>
      <Grid>
      <label for="text" style={{marginRight:'5px'}}>Patient Name:</label>
        <input type="text" 
        value={props.patientName}
        style={{
          width:'10em',
          border:'none',
          padding:'5px',
          backgroundColor:'#cef5f0',
          borderBottom: '2px solid black'
        }} readOnly />
        <label for="text" style={{marginRight:'5px',marginLeft:'50px'}}>Date of Visit:</label>
        <input type="text" value={date} style={{
          width:'13em',
          border:'none',
          padding:'5px',
          backgroundColor:'#cef5f0',
          borderBottom: '2px solid black'
        }} readOnly />
      </Grid>
      <Typography style={{ marginTop: '40px', marginBottom: '40px' }}>
        1. Within the past year, how often did you have a drink of alcohol?
        <Typography style={{ marginLeft: '100px', marginTop: '20px' }}>
          <input class="form-check-input" type="checkbox" name="question1"  checked={question1 === '0' ? true : false}
  onChange={() => {}} value='A'></input> a. Never
        </Typography>
        <Typography style={{ marginLeft: '100px', marginTop: '20px' }}>
          <input class="form-check-input" type="checkbox" name="question1" checked={question1 === '1' ? true : false}
  onChange={() => {}} value='B'></input> b. Monthly (e.g. Special occasions/Rare)
        </Typography>
        <Typography style={{ marginLeft: '100px', marginTop: '20px' }}>
          <input class="form-check-input" type="checkbox" name="question1"  checked={question1 === '2' ? true : false}
  onChange={() => {}}></input> c.  2-4 times a month (e.g. 1x on weekend - “Fridays only” or “every other Thursday”)
        </Typography>
        <Typography style={{ marginLeft: '100px', marginTop: '20px' }}>
          <input class="form-check-input" type="checkbox" name="question1"  checked={question1 === '3' ? true : false}
  onChange={() => {}} value='D'></input> d. 2-3 times a week (e.g. weekends: Friday-Saturday or Saturday-Sunday)

        </Typography>
        <Typography style={{ marginLeft: '100px', marginTop: '20px' }}>
          <input class="form-check-input" type="checkbox" name="question1"  checked={question1 === '4' ? true : false}
  onChange={() => {}} value='E'></input> e. 4 or more times a week (e.g. daily or most days/week)
        </Typography>
      </Typography>
      <Typography>
        2. Within the past year, how many standard drinks containing alcohol did you have on a typical day?
        <Typography style={{ marginLeft: '100px', marginTop: '20px' }}>
          <input class="form-check-input" type="checkbox" name="question2"  checked={question2=='0'?true:false} value='0'></input> a. 1 or 2
        </Typography>
        <Typography style={{ marginLeft: '100px', marginTop: '20px' }}>
          <input class="form-check-input" type="checkbox" name="question2"  checked={question2=='1'?true:false} value='1'></input> b. 3 or 4
        </Typography>
        <Typography style={{ marginLeft: '100px', marginTop: '20px' }}>
          <input class="form-check-input" type="checkbox" name="question2"    checked={question2=='2'?true:false} value='2'></input> c. 5 or 6
        </Typography>
        <Typography style={{ marginLeft: '100px', marginTop: '20px' }}>
          <input class="form-check-input" type="checkbox" name="question2"    checked={question2=='3'?true:false} value='3'></input> d. 7 to 9
        </Typography>
        <Typography style={{ marginLeft: '100px', marginTop: '20px' }}>
          <input class="form-check-input" type="checkbox" name="question2"  checked={question2=='4'?true:false} value='4'></input> e. 10 or more
        </Typography>
        <Typography style={{ marginTop: '40px', marginBottom: '40px' }}>
          3. Within the past year, how often did you have six or more drinks on one occasion?
        </Typography>
        <Typography style={{ marginLeft: '100px', marginTop: '20px' }}>
          <input class="form-check-input" type="checkbox" name="inlineRadioOptions5" checked={question3=='0'?true:false} value='0'></input>  a. Never
        </Typography>
        <Typography style={{ marginLeft: '100px', marginTop: '20px' }}>
          <input class="form-check-input" type="checkbox" name="inlineRadioOptions5" checked={question3=='1'?true:false} value='1'></input>  b. Less than monthly
        </Typography>
        <Typography style={{ marginLeft: '100px', marginTop: '20px' }}>
          <input class="form-check-input" type="checkbox" name="inlineRadioOptions5" checked={question3=='2'?true:false} value='2'></input>  c. Monthly
        </Typography>
        <Typography style={{ marginLeft: '100px', marginTop: '20px' }}>
          <input class="form-check-input" type="checkbox" name="inlineRadioOptions5" checked={question3=='3'?true:false} value='3'></input>  d. Weekly
        </Typography>
        <Typography style={{ marginLeft: '100px', marginTop: '20px' }}>
          <input class="form-check-input" type="checkbox" name="inlineRadioOptions5" checked={question3=='4'?true:false} value='4'></input>  e. Daily or almost daily
        </Typography>
        <Typography style={{ marginTop: '80px' }}>
          AUDIT-C is available for use in the public domain.
        </Typography>
        <Typography style={{
          color: 'white',
          marginTop: '180px',
          border: '1px solid gradiant',
          backgroundColor: '#0093E9',
          backgroundImage: 'linear-gradient(160deg, #0093E9 0%, #74da94 50%, #16751f 100%)'

        }}>
          STABLE RESOURCE TOOLKIT
        </Typography>
        <Typography variant='h2' style={{ marginTop: '80px', marginLeft: '6em' }}>
          AUDIT-C - Overview
        </Typography>
        <Typography style={{ marginTop: '40px', marginLeft: '10em' }}>
          The AUDIT-C is a 3-item alcohol screen that can help identify persons who are hazardous
          drinkers or have active alcohol use disorders (including alcohol abuse or dependence).
          The AUDIT-C is a modified version of the 10 question AUDIT instrument.
        </Typography>
        <Typography variant='h3' style={{ marginTop: '40px', marginLeft: '7em' }}>
          Clinical Utility
        </Typography>
        <Typography style={{ marginTop: '20px', marginLeft: '10em' }}>
          The AUDIT-C is a brief alcohol screen that reliably identifies patients who are hazardous
          drinkers or have active alcohol use disorders.
        </Typography>
        <Typography variant='h3' style={{ marginTop: '40px', marginLeft: '7em' }}>
          Scoring
        </Typography>
        <Typography style={{ marginTop: '20px', marginLeft: '10em' }}>
          The AUDIT-C is scored on a scale of 0-12. <br />
          <Typography style={{ marginTop: '20px', marginLeft: '1em' }}>
            Each AUDIT-C question has 5 answer choices. Points allotted are: <br />
            a = 0 points, b = 1 point, c = 2 points,  d = 3 points, e = 4 points
          </Typography>
          <Typography>
            <ul type='square' style={{
              marginLeft: '20px',
            }}>
              <li><b>In men</b>, a score of 4 or more is considered positive, optimal for identifying hazardous drinking or active alcohol use disorders.</li>
              <li><b>In women</b>, a score of 3 or more is considered positive (same as above).</li>
              <li> However, when the points are all from Question #1 alone (#2 and #3 are zero), it can be
                assumed that the patient is drinking below recommended limits and it is suggested that the
                provider review the patient’s alcohol intake over the past few months to confirm accuracy.<sup>3</sup></li>
              <li>Generally, the higher the score, the more likely it is that the patient’s drinking is affecting his or her safety.</li>
            </ul>
          </Typography>
          <Typography variant='h3' style={{ marginTop: '40px', marginLeft: '7em' }}>
            Psychometric Properties
          </Typography>
          <Typography style={{ marginTop: '20px' }}>
            For identifying patients with heavy/hazardous drinking and/or Active-DSM alcohol
            abuse or dependence
          </Typography>
          <Grid container spacing={0.5}>
            <Grid item xs={6} md={3}></Grid>
            <Grid item xs={6} md={3} style={{textAlign:'left'}}><b>Men</b><sup>1</sup></Grid>
            <Grid item xs={6} md={5} style={{textAlign:'center'}}><b>Women</b><sup>2</sup></Grid>
            <Grid item xs={6} md={1}></Grid>
            <Grid item xs={6} md={2}> ≥3
            </Grid>
            <Grid item xs={6} md={5}>Sens: 0.95 / Spec. 0.60</Grid>
            <Grid item xs={6} md={5}>Sens: 0.66 / Spec. 0.94</Grid>
            <Grid item xs={6} md={2}> ≥4
            </Grid>
            <Grid item xs={6} md={5}>Sens: 0.86 / Spec. 0.72</Grid>
            <Grid item xs={6} md={5}>Sens: 0.48 / Spec. 0.99</Grid>
          </Grid>
          <Typography style={{ marginTop: '20px', marginBottom:'20px'}}>
            For identifying patients with active alcohol abuse or dependence
          </Typography>
          <Grid container spacing={0.5}>
            <Grid item xs={6} md={2}> ≥3
            </Grid>
            <Grid item xs={6} md={5}>Sens: 0.95 / Spec. 0.60</Grid>
            <Grid item xs={6} md={5}>Sens: 0.66 / Spec. 0.94</Grid>
            <Grid item xs={6} md={2}> ≥4
            </Grid>
            <Grid item xs={6} md={5}>Sens: 0.86 / Spec. 0.72</Grid>
            <Grid item xs={6} md={5}>Sens: 0.48 / Spec. 0.99</Grid>
          </Grid>
          <Typography style={{ marginTop: '20px' }}>
            1. Bush K, Kivlahan DR, McDonell MB, et al. The AUDIT Alcohol Consumption Questions (AUDIT-C): An effective
            brief screening test for problem drinking. Arch Internal Med. 1998 (3): 1789-1795
          </Typography>
          <Typography style={{ marginTop: '20px' }}>
            2. Bradley KA, Bush KR, Epler AJ, et al. Two brief alcohol-screening tests from the Alcohol Use Disorders
            Identification Test (AUDIT): Validation in a female veterans affairs patient population. Arch Internal Med Vol
            163, April 2003: 821-829
          </Typography>
          <Typography style={{ marginTop: '20px' }}>
            3. Frequently Asked Questions guide to using the AUDIT-C can be found via the website: <br />
            <a href="www.oqp.med.va.gov/general/uploads/FAQ%20AUDIT-C">
              www.oqp.med.va.gov/general/uploads/FAQ%20AUDIT-C
            </a>
          </Typography>
        </Typography>
      </Typography>
    </Grid>
  )
}
