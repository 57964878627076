import React, { useEffect, useReducer, useState, Fragment } from 'react'
 import SuiInput from 'components/SuiInput'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'
import { alignProperty } from '@mui/material/styles/cssUtils'
import { createBrowserHistory } from 'history'
import axios from 'axios'
const history = createBrowserHistory({ forceRefresh: true })
const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
const headers = {
  Authorization: auth,
}

export default function CIDI(props) {
  console.log(props,'propsprops')
    const id=props.id
    const [question1, setQuestion1] = useState('')
    const [question2, setQuestion2] = useState('')
    const [question3, setQuestion3] = useState('')
    const [question4, setQuestion4] = useState('')
    const [question5, setQuestion5] = useState('')
    const [question6, setQuestion6] = useState('')
    const [question7, setQuestion7] = useState('')
    const [question8, setQuestion8] = useState('')
    const [question9, setQuestion9] = useState('')
    const [question10, setQuestion10] = useState('')
    const [question11, setQuestion11] = useState('')
    const [question12, setQuestion12] = useState('')
    const [date, setDate] = useState('')
    const age=props.age
    const gender=props.gender
    
    useEffect(() => {
        // POST request using axios inside useEffect React hook
        let isComponentMounted = true
        const fetchData = async () =>
          axios
            .get(
              `${
                process.env.REACT_APP_API_URL
              }/PatientPortal/getCidi12ById?Id=${
                id !== undefined ? id : 0
              }`,
              { headers }
              // `${process.env.REACT_APP_API_URL}/Facilities/getFacilityById?id=${
              //   location.state.facilityId !== undefined ? location.state.facilityId : 0
              // }`,
              // null,
              // {
              //   headers,
              // }
            )
            .then((response) => {
            //   console.log('response.data: ', response.data)
             setQuestion1(response.data.q1)
             setQuestion2(response.data.q2)
             setQuestion3(response.data.q3)
             setQuestion4(response.data.q4)
             setQuestion5(response.data.q5)
             setQuestion6(response.data.q6)
             setQuestion7(response.data.q7)
             setQuestion8(response.data.q8)
             setQuestion9(response.data.q9)
             setQuestion10(response.data.q10)
             setQuestion11(response.data.q11)
             setQuestion12(response.data.q12)
             setDate(response.data.date)

            })
            .catch((error) => {
              console.error('There was an error!', error)
            })
        fetchData()
        return () => {
          isComponentMounted = false
        }
    
        // empty dependency array means this effect will only run once (like componentDidMount in classes)
      }, [])
  return (
    <Grid className='container'>
      <Grid item xs={12} sm={12} lg={12}>
        <Typography variant='h1' align='center'>
          CIDI-based Bipolar Disorder screening scale*
        </Typography>
      </Grid>
      {/* <input type="text" style={{border:none,  borderBottom: 2}}></input>  */}
      <Grid align='center'>
        <label for="text" style={{marginRight:'5px'}}> Name:</label>
        <input type="text" value={props.patientName}style={{
          width:'15em',
          border:'none',
          padding:'5px',
          backgroundColor:'#cef5f0',
          borderBottom: '2px solid black'
        }} readOnly />
        <label for="text" style={{marginRight:'5px'}}> Age:</label>
        <input type="text" style={{
          width:'8em',
          border:'none',
          padding:'5px',
          backgroundColor:'#cef5f0',
          borderBottom: '2px solid black'
        }}
        value={age} readOnly />
     <span>
    <label htmlFor="male" style={{ marginRight: '5px' }}>
        <input
            type="radio"
            id="male"
            name="gender"
            checked={gender === 'Male' ? true : false}
            value="Male"
            style={{ marginTop: '10px', marginRight: '5px' }}
        />
        Male
    </label>
    <label htmlFor="female" style={{ marginRight: '5px' }}>
        <input
            type="radio"
            id="female"
            name="gender"
            checked={gender === 'Female' ? true : false}
            value="Female"
            style={{ marginTop: '10px', marginRight: '5px' }}
        />
        Female
    </label>
</span>

        <label for="text" style={{marginLeft:'20px'}}>Date:</label>
        <input type="text"           value={date}
 style={{
          width:'13em',
          border:'none',
          padding:'5px',
          backgroundColor:'#cef5f0',
          borderBottom: '2px solid black'
        }} readOnly />
      </Grid>
      <Typography style={{ marginBottom: '30px', marginTop: '50px',justifyContent:'right' }}>
        <b>Instructions:</b> The complete set of 12 Questions takes approximately three minutes to complete.<br/>
        2 Stem Questions: Question 1 and 2<br/>
        Respondents who fail to endorse either of these first two questions are skipped out of the remainder of the question series.
      </Typography>
      <Typography style={{ marginBottom: '30px',justifyContent:'right'  }}>
        1 Criterion B Screening Question: Question 3.
        Respondents who fail to endorse this question after endorsing one of the first two stem questions (above) are skipped out of the
        remainder of the question series.
        Respondents who do endorse this question are then administered the 9 additional symptom questions.
      </Typography>
      <Typography style={{ marginBottom: '30px',justifyContent:'right'  }}>
        9 Criterion B Symptom Questions.
        Each of the 9 symptom questions are administered.
      </Typography>
      <table className='table table-bordered border-dark table-striped table-hover' style={{border:'2px solid black'}}>
        <thead>
          <tr>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col"></th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}} scope="col">NO</th>
            <th style={{  borderBottom: '1px solid black',textAlign:'center'}} scope="col">YES</th>
          </tr>
          <tr>
          </tr>
        </thead>
        
        <tbody>
            
          <tr style={{backgroundColor:'#BDD6EE'}}>
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}><b>I. Stem questions <br />
              Euphoria stem question</b>  <br />
              1. Some people have periods lasting several days or longer when they feel much more excited and full or energy than
              usual. Their minds go too fast. They talk a lot. They are very restless or unable to sit still and they sometimes do
              things that are unusual for them, such as driving too fast or spending too much money. Have you ever had a period
              liked this lasting several days or longer? <br />
              <small><b>
                If this question is endorsed, the irritability stem question is skipped and the respondent goes directly to the Criterion B screening question.
              </b></small></td>
            <td style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}>
              <input class="form-check-input" type="radio" name="question1"   checked={!question1} value="false"></input>
            </td>
            <td style={{  borderBottom: '1px solid black',textAlign:'center'}}>
              <input class="form-check-input" type="radio" name="question1"  checked={question1} value='true'></input>
            </td>
          </tr >
          <tr >
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>
              <Typography variant='h5'>
                Irritability stem question
              </Typography>
              2. Have you ever had a period lasting several days or longer when most of the time you were so irritable or grouchy that you either started arguments, shouted at people or hit people?
            </td>
            <td style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}>
              <input class="form-check-input" type="radio" name="question2"  checked={!question2} value="false"></input>
            </td>
            <td style={{  borderBottom: '1px solid black',textAlign:'center'}}>
              <input class="form-check-input" type="radio" name="question2"   checked={question2} value="true"></input>
            </td>
          </tr>
          <tr style={{backgroundColor:'#BDD6EE'}}>
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>
              <Typography variant='h4'>
                II. Criterion B screening question
              </Typography>
              3. People who have episodes like this often have changes in their thinking and behavior at the same time, like being
              more talkative, needing very little sleep, being very restless, going on buying sprees, and behaving in ways they would
              normally think are inappropriate. Did you ever have any of these changes during your episodes of being excited and
              full of energy/very irritable or grouchy?
            </td>
            <td style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}>
              <input class="form-check-input" type="radio" name="question3"   checked={!question3} value="false"></input>
            </td>
            <td style={{  borderBottom: '1px solid black',textAlign:'center'}}>
              <input class="form-check-input" type="radio" name="question3"  checked={question3} value="true"></input>
            </td>
          </tr>
          <tr>
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>
              <Typography variant='h4'>
                III. Criterion B symptom questions
              </Typography>
              Think of an episode when you had the largest number of changes like these at the same time. During that episode,
              which of the following changes did you experience?
              1. Were you so irritable that you either started arguments, shouted at people, or hit people? <br/>
              <small><b>
                This question is asked only if the euphoria stem question is endorsed.
              </b></small>
            </td>
            <td style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}>
              <input class="form-check-input" type="radio" name="question4"   checked={!question4} value="false"></input>
            </td>
            <td style={{  borderBottom: '1px solid black',textAlign:'center'}}>
              <input class="form-check-input" type="radio" name="question4"  checked={question4} value="true"></input>
            </td>
          </tr>
          <tr style={{backgroundColor:'#BDD6EE'}}>
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>
              2. Did you become so restless or fidgety that you paced up and down or couldn’t stand still?
            </td>
            <td style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}>
              <input class="form-check-input" type="radio" name="question5"   checked={!question5} value="false"></input>
            </td>
            <td style={{  borderBottom: '1px solid black',textAlign:'center'}}>
              <input class="form-check-input" type="radio" name="question5"  checked={question5} value="true"></input>
            </td>
          </tr>
          <tr>
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>
              3. Did you do anything else that wasn’t usual for you – like talking about things you would normally keep private, or
              acting in ways that you’d usually find embarrassing?
            </td>
            <td style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}>
              <input class="form-check-input" type="radio" name="question6"  checked={!question6} value="false"></input>
            </td>
            <td style={{  borderBottom: '1px solid black',textAlign:'center'}}>
              <input class="form-check-input" type="radio" name="question6"   checked={question6} value="true"></input>
            </td>
          </tr>
          <tr style={{backgroundColor:'#BDD6EE'}}>
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>
            4. Did you try to do thing that were impossible to do, like taking on large amounts of work?
            </td>
            <td style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}>
              <input class="form-check-input" type="radio" name="question7" checked={!question7} value="false"></input>
            </td>
            <td style={{  borderBottom: '1px solid black',textAlign:'center'}}>
              <input class="form-check-input" type="radio" name="question7"  checked={question7} value="true"></input>
            </td>
          </tr>
          <tr>
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>
            5. Did you constantly keep changing your plans or activities? 
            </td>
            <td style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}>
              <input class="form-check-input" type="radio" name="question8"   checked={!question8} value="false"></input>
            </td>
            <td style={{  borderBottom: '1px solid black',textAlign:'center'}}>
              <input class="form-check-input" type="radio" name="question8"   checked={question8} value="true"></input>
            </td>
          </tr>
          <tr style={{backgroundColor:'#BDD6EE'}}>  
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>
            6. Did you find it hard to keep your mind on what you were doing?
            </td>
            <td style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}>
              <input class="form-check-input" type="radio" name="question9"   checked={!question9} value="false"></input>
            </td>
            <td style={{  borderBottom: '1px solid black',textAlign:'center'}}>
              <input class="form-check-input" type="radio" name="question9"   checked={question9} value="true"></input>
            </td>
          </tr>
          <tr>
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>
            7. Did your thoughts seem to jump from one thing to another or race through your head so fast you couldn’t keep track of them?             </td>
            <td style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}>
              <input class="form-check-input" type="radio" name="question10"   checked={!question10} value="false"></input>
            </td>
            <td style={{  borderBottom: '1px solid black',textAlign:'center'}}>
              <input class="form-check-input" type="radio" name="question10"   checked={question10} value="true"></input>
            </td>
          </tr>
          <tr style={{backgroundColor:'#BDD6EE'}}>
            <td style={{  borderBottom: '1px solid black',borderRight:'1px solid black'}}>
            8. Did you sleep far less than usual and still not get tired or sleepy? 
            </td>
            <td style={{  borderBottom: '1px solid black',textAlign:'center',borderRight:'1px solid black'}}>
              <input class="form-check-input" type="radio" name="question11"   checked={!question11} value="false"></input>
            </td>
            <td style={{  borderBottom: '1px solid black',textAlign:'center'}}>
              <input class="form-check-input" type="radio" name="question11"   checked={question11} value="true"></input>
            </td>
          </tr>
          <tr>
            <td style={{borderRight:'1px solid black'}}>
            9. Did you spend so much more money than usual that it caused you to have financial trouble?
            </td>
            <td style={{textAlign:'center',borderRight:'1px solid black'}}>
              <input class="form-check-input" type="radio" name="question12"  checked={!question12} value="false"></input>
            </td>
            <td style={{textAlign:'center'}}>
              <input class="form-check-input" type="radio" name="question12"   checked={question12} value="true"></input>
            </td>
          </tr>
        </tbody>
      </table> 
      <Grid>
        <Typography style={{fontFamily:'serif'}}>
          <b>Interpretation of scores:</b>
          <br />
          Scores may be collapsed for reference purposes, if desired, as follows:
          <br />
          <tr>
            <th style={{  textAlign:'left'}} scope="col">Very high risk (80% or more)<br/>
            High risk (50-79%) <br/>Moderate risk (25-49%) <br/>Low risk (5-24%)<br/>Very low risk (less than 5%)<br/>
            </th>
            <th style={{  width:'200px'}} scope="col"></th>
            <th style={{  textAlign:'left'}} scope="col"> 9 questions with positive endorsement<br/>
            7-8 questions with positive endorsement<br/>6 questions with positive endorsement <br/> 5 questions with positive endorsement<br/>0-4 questions with positive endorsement<br/>
            </th>           
            </tr>
         
        </Typography>
      </Grid>
      <Grid>
        <Typography variant='body2' style={{ marginTop: '80px' }}>
          *Adapted for evaluation purposes from the full WHO Composite International Diagnostic Interview (CIDI 3.0)
        </Typography>
      </Grid>
    </Grid>
  )
}
