/**
=========================================================
* Soft UI Dashboard React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card'
import axios from 'axios'
import AddIcon from 'assets/add.png'
import Modal from '@mui/material/Modal'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import React, { useEffect, useReducer, useState, Fragment, useRef } from 'react'

// Soft UI Dashboard React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiButton from 'components/SuiButton'
import SuiAvatar from 'components/SuiAvatar'
import Box from '@mui/material/Box'
import Addmember from './Addmember'
import Table from 'examples/Tables/Table'
 import { Grid, Snackbar, TextField } from '@mui/material'
import { createBrowserHistory } from 'history'
import { DataGrid } from '@mui/x-data-grid'
import { styled } from '@mui/material/styles'
import { customStyle } from '../assets/customStyle.css'
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import Button from '@mui/material/Button'
const history = createBrowserHistory({ forceRefresh: true })

function Users() {
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px',
    },
  }

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 5,
    background: 'white',
    fontSize: '13.6px !important',
    color: '#8392ab !important',
    borderColor: 'rgba(5, 152, 236, 0.637) !important',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
  }
  const [refresh, setRefresh] = useState(false)

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      cellClassName: 'gridCell',
      sortable: false,
      width: 130,
      renderCell: (cellValues) => (
        <SuiButton
          ize="small"
          variant="text"
          color="dark"
          onClick={(event) => {
            openCareTeam(event, cellValues.row.id)
          }}
        >{`${cellValues.row.name}`}</SuiButton>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      cellClassName: 'gridCell',
      sortable: false,
      width: 120,
    },
    {
      field: 'alias',
      headerName: 'Alias',
      cellClassName: 'gridCell',
      sortable: false,
      width: 100,
    },{
      field: 'delete',
      headerName: 'Delete',
      sortable: true,
      width: 50,
      renderCell: (cell) => (
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
          }}
          color="black"
          onClick={(cellValues) => {
            // console.log('Cell: ', cell)
            confirmAlert({
              message: 'Do you want to delete the record.',
              closeOnEscape: true,
              closeOnClickOutside: true,
              overlayClassName: 'overlay-custom-class-name',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    const postData = {
                      name: cell.row.name,
                      description: cell.row.description,
                      alias: cell.row.alias,
                       id: cell.row.id,
                       isActive: false,
                      firstName: cell.row.firstName,
                      practiceId: cell.row.practiceId,
                    }

                    axios
                      .post(
                        `${process.env.REACT_APP_API_URL}/CareTeams/addCareTeam`,
                        postData,
                        {
                          headers,
                        }
                      )
                      .then((response) => {
                        setRefresh(!refresh)
                        // setOpenNote(false)
                        // console.log('response.data: ', response.data)
                      })
                      .catch((error) => {
                        console.error('There was an error!', error)
                      })
                  },
                },
                {
                  label: 'No',
                },
              ],
            })
          }}
        ></DeleteIcon>
      ),
    },
  ]
  const columns1 = [
    {
      field: 'firstName',
      headerName: 'Name',
      cellClassName: 'gridCell',
      sortable: true,
      width: 130,
      renderCell: (cellValues) => (
        <SuiButton
          size="small"
          variant="text"
          color="dark"
          onClick={(event) => {
            setId(cellValues.row.id)
setAddMember(true) 
      }}
        >{`${cellValues.row.lastName}, ${cellValues.row.firstName} `}</SuiButton>
      ),
    },

    {
      field: 'role',
      headerName: 'role',
      cellClassName: 'gridCell',
      sortable: false,
      width: 80,
    },
    {
      field: 'responsiblities',
      headerName: 'Responsiblities',
      cellClassName: 'gridCell',
      sortable: false,
      width: 130,
    },
    {
      field: 'phoneNo',
      headerName: 'PhoneNo',
      cellClassName: 'gridCell',
      sortable: false,
      width: 80,
    },
    {
      field: 'delete',
      headerName: 'Delete',
      sortable: true,
      width: 50,
      renderCell: (cell) => (
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
          }}
          color="black"
          onClick={(cellValues) => {
            // console.log('Cell: ', cell)
            confirmAlert({
              message: 'Do you want to delete the record.',
              closeOnEscape: true,
              closeOnClickOutside: true,
              overlayClassName: 'overlay-custom-class-name',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    const postData = {
                      lastName: cell.row.lastName,
                      responsiblities: cell.row.responsiblities,
                      phoneNo: cell.row.phoneNo,
                      role: cell.row.role,
                      id: cell.row.id,
                      inactive: false,
                      firstName: cell.row.firstName,
                      practiceId: cell.row.practiceId,
                    }

                    axios
                      .post(
                        `${process.env.REACT_APP_API_URL}/CareTeamMembers/addCareTeamMember`,
                        postData,
                        {
                          headers,
                        }
                      )
                      .then((response) => {
                        setRefresh(!refresh)
                        // setOpenNote(false)
                        // console.log('response.data: ', response.data)
                      })
                      .catch((error) => {
                        console.error('There was an error!', error)
                      })
                  },
                },
                {
                  label: 'No',
                },
              ],
            })
          }}
        ></DeleteIcon>
      ),
    },
  ]
  const [rows, setRows] = useState([])
  const [rows1, setRows1] = useState([])
  const [careTeamId, setCareTeamId] = useState('')
  const [id, setId]=useState('')
  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios

      .get(`${process.env.REACT_APP_API_URL}/CareTeams/getCareTeams`, {
        headers,
      })
      .then((response) => setRows(response.data))
      .catch((error) => {
        console.error('There was an error!', error)
      })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    if (careTeamId != null && careTeamId != undefined && careTeamId > 0) {
      axios

        .get(
          `${process.env.REACT_APP_API_URL}/CareTeamMembers/getCareTeamMembers?careTeamId=${careTeamId}`,
          {
            headers,
          }
        )
        .then((response) => setRows1(response.data))
        .catch((error) => {
          console.error('There was an error!', error)
        })
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])
  const openCareTeam = (event, id) => {
    // console.log('Clicked UserEmail: ', id)
    history.push({
      pathname: `/Addteam`,
      state: { careTeamId: id },
    })
  }
  // const openCareTeamMember = (event, id) => {
  //    console.log('careTeamId: ', careTeamId)
  //   history.push({
  //     pathname: `/Addmember`,
  //     state: { careTeamId: careTeamId,careTeamMemberId:id },
  //   })
  

  // }
  const handleOpenAddMember = () => setAddMember(true)
  const handleCloseAddMember = () => setAddMember(false)
  const [addMember, setAddMember] =  useState(false)
  const styleAddMember = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: '#F0FFFF',
    border: '2px solid black',
    boxShadow: 24,
    p: 2,
  }
 
  return (
    <>
     <Modal
        open={addMember}
        onClose={handleCloseAddMember}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleAddMember}>
        {/* <CancelOutlinedIcon
                fontSize="medium"
                style={{  paddingRight: '5px' }}
              ></CancelOutlinedIcon> */}
                  <SuiTypography
            component="label"
            variant="caption"
            fontSize="20px"
            style={{ fontWeight: 'bold',  }}
          >
            Add Member
          </SuiTypography>
        <CancelOutlinedIcon
              onClick={() => setAddMember(false)}
              variant="contained"
            
              style={{
                float: "right",color: 'red',
              }}
            ></CancelOutlinedIcon>
           
          <Grid container columnSpacing={1}>
          <Addmember careTeamId={careTeamId}   
          
        careTeamMemberId={id}
         cancelModal={(props)=>{setAddMember(props)
         history.push({
      pathname: `/CareTeam`})
         }}></Addmember>
         </Grid>
        </Box>
      </Modal>
      <Grid
        container
        spacing={1}
        alignContent="center"
        justifyContent="center"
        style={{
          paddingTop: '10px',
          paddingLeft: '10px',
          paddingRight: '50px',
        }}
      >
        <Grid item xs={6} style={{ width: '300px' }}>
          <SuiBox mb={3}>
            <Button
              sx={{ mb: 5, width: 180 }}
              onClick={openCareTeam}
              variant="gradient"
              style={{
                width:"150px",
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
              }}
            >
              <AddOutlinedIcon
                fontSize="medium"
                style={{
                  color: '#1a82ad',
                  paddingRight: '4px',
                  paddingBottom: '2px',
                }}
              ></AddOutlinedIcon>
              Add Team
            </Button>
          </SuiBox>
        </Grid>
        <Grid item xs={6} style={{ width: '300px' }}>
          <SuiBox mb={3}>
            <Button
              sx={{ mb: 5, width: 180 }}
              onClick={handleOpenAddMember}
              variant="gradient"
              style={{
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
              }}

            >
              <AddOutlinedIcon
                fontSize="small"
                style={{
                  color: '#1a82ad',
                  paddingRight: '4px',
                  paddingBottom: '2px',
                }}
              ></AddOutlinedIcon>
              Add Members
            </Button>
          </SuiBox>
        </Grid>
        <Grid item xs={6} style={{ width: '300px' }}>
          <SuiBox mb={3}>
            <div style={{ height: 400, width: '100%' }}>
              {rows === null || rows.length === 0 ? null : (
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  sx={gridRowStyle}
                  onSelectionModelChange={(ids) => {
                    setCareTeamId(ids[0])
                    axios
                      .get(
                        `${process.env.REACT_APP_API_URL}/CareTeamMembers/getCareTeamMembers?careTeamId=${ids[0]}`,
                        {
                          headers,
                        }
                      )
                      .then((response) => {
                        setRows1(response.data)
                      })
                      .catch((error) => {
                        console.error('There was an error!', error)
                      })
                  }}
                />
              )}
            </div>
          </SuiBox>
        </Grid>
        <Grid item xs={6} style={{ width: '300px' }}>
          <SuiBox mb={3}>
            <div style={{ height: 400, width: '100%' }}>
              {rows === null || rows.length === 0 ? null : (
                <DataGrid
                  rows={rows1}
                  columns={columns1}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  sx={gridRowStyle}
                />
              )}
            </div>
          </SuiBox>
        </Grid>
      </Grid>
    </>
  )
}

export default Users
