export default [
  { value: 'F40.01', label: 'Agoraphobia with panic disorder' },
  { value: 'F40', label: 'Social phobia, unspecified' },
  { value: 'F40.11', label: 'Social phobia, generalized' },
  { value: 'F40.9', label: 'Phobic anxiety disorder, unspecified' },
  {
    value: 'F41.0',
    label: 'Panic disorder [episodic paroxysmal anxiety] without agoraphobia',
  },
  { value: 'F41.1', label: 'Generalized anxiety disorder' },
  { value: 'F41.8', label: 'Other specified anxiety disorders' },
  { value: 'F41.9', label: 'Anxiety disorder, unspecified' },
  { value: 'F42', label: 'Obsessive-compulsive disorder' },
  { value: 'F43', label: 'Post-traumatic stress disorder, unspecified' },
  { value: 'F43.11', label: 'Post-traumatic stress disorder, acute' },
  { value: 'F43.12', label: 'Post-traumatic stress disorder, chronic' },
  { value: 'F43.20', label: 'Adjustment disorder, unspecified' },
  { value: 'F43.21', label: 'Adjustment disorder with depressed mood' },
  { value: 'F43.22', label: 'Adjustment disorder with anxiety' },
  {
    value: 'F43.23',
    label: 'Adjustment disorder with mixed anxiety and depressed mood',
  },
  { value: 'F43.24', label: 'Adjustment disorder with disturbance of conduct' },
  {
    value: 'F43.25',
    label: 'Adjustment disorder with mixed disturbance of emotions and conduct',
  },
  { value: 'F43.29', label: 'Adjustment disorder with other symptoms' },
  {
    value: 'F44.4',
    label: 'Conversion disorder with motor symptom or deficit',
  },
  { value: 'F44.5', label: 'Conversion disorder with seizures or convulsions' },
  {
    value: 'F44.6',
    label: 'Conversion disorder with sensory symptom or deficit',
  },
  {
    value: 'F44.7',
    label: 'Conversion disorder with mixed symptom presentation',
  },
  { value: 'F44.89', label: 'Other dissociative and conversion disorders' },
  {
    value: 'F44.9',
    label:
      'Dissociative and conversion disorder, unspecified -Section F60-F69-Disorders of adult personality and behavior (F60-F69)',
  },
  { value: 'F60.3', label: 'Borderline personality disorder' },
  {
    value: 'F64.2',
    label:
      'Gender identity disorder of childhood -Section F50-F59-Behavioral syndromes associated with physiological disturbances and  physical factors (F50-F59)',
  },
  { value: 'F51.01', label: 'Primary insomnia' },
  { value: 'F51.02', label: 'Adjustment insomnia' },
  { value: 'F51.03', label: 'Paradoxical insomnia' },
  { value: 'F51.04', label: 'Psychophysiologic insomnia' },
  { value: 'F51.05', label: 'Insomnia due to other mental disorder' },
  {
    value: 'F51.09',
    label:
      'Other insomnia not due to a substance or known physiological condition-Section F80-F89-Pervasive and specific  developmental disorders (F80-F89) Autistic disorder -Section F30-F39-Mood [affective] disorders (F30-F39)',
  },
  { value: 'F84.0', label: 'Bipolar disorder, current episode hypomanic' },
  {
    value: 'F31.0',
    label:
      'Bipolar disorder, current episode manic without psychotic features, unspecified',
  },
  {
    value: 'F31.10',
    label:
      'Bipolar disorder, current episode manic without psychotic features, mild',
  },
  {
    value: 'F31.11',
    label:
      'Bipolar disorder, current episode manic without psychotic features, moderate',
  },
  {
    value: 'F31.12',
    label:
      'Bipolar disorder, current episode manic without psychotic features, severe',
  },
  {
    value: 'F31.13',
    label:
      'Bipolar disorder, current episode depressed, mild or moderate severity, unspecified',
  },
  {
    value: 'F31.30',
    label: 'Bipolar disorder, current episode depressed, mild',
  },
  {
    value: 'F31.31',
    label: 'Bipolar disorder, current episode depressed, moderate',
  },
  {
    value: 'F31.32',
    label:
      'Bipolar disorder, current episode depressed, severe, without psychotic features',
  },
  {
    value: 'F31.4',
    label:
      'Bipolar disorder, current episode depressed, severe, with psychotic features',
  },
  {
    value: 'F31.5',
    label: 'Bipolar disorder, current episode mixed, unspecified',
  },
  { value: 'F31.60', label: 'Bipolar disorder, current episode mixed, mild' },
  {
    value: 'F31.61',
    label: 'Bipolar disorder, current episode mixed, moderate',
  },
  {
    value: 'F31.62',
    label:
      'Bipolar disorder, current episode mixed, severe, without psychotic features',
  },
  {
    value: 'F31.63',
    label:
      'Bipolar disorder, current episode mixed, severe, with psychotic features',
  },
  {
    value: 'F31.64',
    label:
      'Bipolar disorder, currently in remission, most recent episode unspecified',
  },
  {
    value: 'F31.70',
    label:
      'Bipolar disorder, in partial remission, most recent episode hypomanic',
  },
  {
    value: 'F31.71',
    label: 'Bipolar disorder, in full remission, most recent episode hypomanic',
  },
  {
    value: 'F31.72',
    label: 'Bipolar disorder, in partial remission, most recent episode manic',
  },
  {
    value: 'F31.73',
    label: 'Bipolar disorder, in full remission, most recent episode manic',
  },
  {
    value: 'F31.74',
    label:
      'Bipolar disorder, in partial remission, most recent episode depressed',
  },
  {
    value: 'F31.75',
    label: 'Bipolar disorder, in full remission, most recent episode depressed',
  },
  {
    value: 'F31.76',
    label: 'Bipolar disorder, in partial remission, most recent episode mixed',
  },
  {
    value: 'F31.77',
    label: 'Bipolar disorder, in full remission, most recent episode mixed',
  },
  { value: 'F31.78', label: 'Bipolar II disorder' },
  { value: 'F31.81', label: 'Other bipolar disorder' },
  { value: 'F31.89', label: 'Bipolar disorder, unspecified' },
  { value: 'F31.9', label: 'Major depressive disorder, single episode, mild' },
  {
    value: 'F32.0',
    label: 'Major depressive disorder, single episode, moderate',
  },
  {
    value: 'F32.1',
    label:
      'Major depressive disorder, single episode, severe without psychotic features',
  },
  {
    value: 'F32.2',
    label:
      'Major depressive disorder, single episode, severe with psychotic features',
  },
  {
    value: 'F32.3',
    label: 'Major depressive disorder, single episode, in partial remission',
  },
  {
    value: 'F32.4',
    label: 'Major depressive disorder, single episode, in full remission',
  },
  { value: 'F32.5', label: 'Other depressive episodes' },
  {
    value: 'F32.8',
    label: 'Major depressive disorder, single episode, unspecified',
  },
  { value: 'F32.9', label: 'Major depressive disorder, recurrent, mild' },
  { value: 'F33.0', label: 'Major depressive disorder, recurrent, moderate' },
  {
    value: 'F33.1',
    label:
      'Major depressive disorder, recurrent severe without psychotic features',
  },
  {
    value: 'F33.2',
    label:
      'Major depressive disorder, recurrent, severe with psychotic symptoms',
  },
  {
    value: 'F33.3',
    label: 'Major depressive disorder, recurrent, in remission, unspecified',
  },
  {
    value: 'F33.40',
    label: 'Major depressive disorder, recurrent, in partial remission',
  },
  {
    value: 'F33.41',
    label: 'Major depressive disorder, recurrent, in full remission',
  },
  { value: 'F33.42', label: 'Other recurrent depressive disorders' },
  {
    value: 'F33.8',
    label: 'Major depressive disorder, recurrent, unspecified',
  },
  { value: 'F33.9', label: 'Dysthymic disorder' },
  {
    value: 'F34.1',
    label:
      'Other persistent mood [affective] disordersF39 Unspecified mood [affective] disorder -Section F20-F29-Schizophrenia,  schizotypal, delusional, and other non-mood psychotic disorders (F20-F29)',
  },
  { value: 'F34.8', label: 'Undifferentiated schizophrenia' },
  { value: 'F20.3', label: 'Schizophrenia, unspecified' },
  { value: 'F20.9', label: 'Schizoaffective disorder, bipolar type' },
  { value: 'F25.0', label: 'Schizoaffective disorder, depressive type' },
  { value: 'F25.1', label: 'Other schizoaffective disorders' },
  {
    value: 'F25.8',
    label:
      'Schizoaffective disorder, unspecifiedF29 Unspecified psychosis not due to a substance or known physiological condition      Section F90-F98-Behavioral and emotional disorders with onset usually occurring in childhood and adolescence (F90-F98)',
  },
  {
    value: 'F25.9',
    label:
      'Attention-deficit hyperactivity disorder, predominantly inattentive type',
  },
  {
    value: 'F90.0',
    label:
      'Attention-deficit hyperactivity disorder, predominantly hyperactive type',
  },
  {
    value: 'F90.1',
    label: 'Attention-deficit hyperactivity disorder, combined type',
  },
  {
    value: 'F90.2',
    label: 'Attention-deficit hyperactivity disorder, other type',
  },
  {
    value: 'F90.8',
    label:
      'Attention-deficit hyperactivity disorder, unspecified type -Section F-F19-',
  },
  {
    value: 'F90.9',
    label:
      'Mental and behavioral disorders due to psychoactive substance use (F-F19)',
  },
  {
    value: 'F.26',
    label:
      'Alcohol dependence with alcohol-induced persisting amnestic disorder',
  },
  {
    value: 'F.27',
    label: 'Alcohol dependence with alcohol-induced persisting dementia',
  },
  {
    value: 'F.96',
    label:
      'Alcohol use, unspecified with alcohol-induced persisting amnestic disorder',
  },
  {
    value: 'F11.20',
    label:
      'Opioid dependence, uncomplicatedF11.21 Opioid dependence, in remissionF11.220 Opioid dependence with intoxication,  uncomplicated',
  },
  { value: 'F11.221', label: 'Opioid dependence with intoxication delirium' },
  {
    value: 'F11.222',
    label: 'Opioid dependence with intoxication with perceptual disturbance',
  },
  {
    value: 'F11.229',
    label: 'Opioid dependence with intoxication, unspecified',
  },
  { value: 'F11.23', label: 'Opioid dependence with withdrawal' },
  {
    value: 'F11.24',
    label: 'Opioid dependence with opioid-induced mood disorder',
  },
  {
    value: 'F11.250',
    label:
      'Opioid dependence with opioid-induced psychotic disorder with delusions',
  },
  {
    value: 'F11.251',
    label:
      'Opioid dependence with opioid-induced psychotic disorder with hallucinations',
  },
  {
    value: 'F11.259',
    label:
      'Opioid dependence with opioid-induced psychotic disorder, unspecified',
  },
  {
    value: 'F11.281',
    label: 'Opioid dependence with opioid-induced sexual dysfunction',
  },
  {
    value: 'F11.282',
    label: 'Opioid dependence with opioid-induced sleep disorder',
  },
  {
    value: 'F11.288',
    label: 'Opioid dependence with other opioid-induced disorder',
  },
  {
    value: 'F11.29',
    label: 'Opioid dependence with unspecified opioid-induced disorder',
  },
  {
    value: 'F13.26',
    label:
      'Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic-induced persisting amnestic disorder',
  },
  {
    value: 'F13.96',
    label:
      'Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic-induced persisting amnestic  disorder',
  },
  {
    value: 'F19.16',
    label:
      'Other psychoactive substance abuse with psychoactive substance-induced persisting amnestic disorder',
  },
  {
    value: 'F19.26',
    label:
      'Other psychoactive substance dependence with psychoactive substance-induced persisting amnestic disorder',
  },
  {
    value: 'F19.96',
    label:
      'Other psychoactive substance use, unspecified with psychoactive substance-induced persisting amnestic disorder -Section  F01-F09-Mental disorders due to known physiological conditions (F01-F09)',
  },
  {
    value: 'F02.80',
    label:
      'Dementia in other diseases classified elsewhere without behavioral disturbance',
  },
  {
    value: 'F03.90',
    label: 'Unspecified dementia without behavioral disturbance',
  },
  { value: 'F05', label: 'Delirium due to known physiological condition' },
  {
    value: 'F06.30',
    label: 'Mood disorder due to known physiological condition, unspecified',
  },
  {
    value: 'F06.31',
    label:
      'Mood disorder due to known physiological condition with depressive features',
  },
  {
    value: 'F06.32',
    label:
      'Mood disorder due to known physiological condition with major depressive-like episode',
  },
  {
    value: 'F06.33',
    label:
      'Mood disorder due to known physiological condition with manic features',
  },
  {
    value: 'F06.34',
    label:
      'Mood disorder due to known physiological condition with mixed features',
  },
  {
    value: 'F06.8',
    label:
      'Other specified mental disorders due to known physiological condition Chapter 6- Diseases of the nervous system ( G00-G99)-Section G30-G32-Other degenerative diseases of the nervous system (G30-G32)',
  },
  { value: 'G30.0', label: "Alzheimer's disease with early onset" },
  { value: 'G30.1', label: "Alzheimer's disease with late onset" },
  { value: 'G30.8', label: "Other Alzheimer's disease" },
  { value: 'G30.9', label: "Alzheimer's disease, unspecified " },
]
