// @mui material components
import Card from '@mui/material/Card'
import EmailIcon from '@mui/icons-material/Email'

// Soft UI Dashboard React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiInput from 'components/SuiInput'
import SuiButton from 'components/SuiButton'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import { useAlert } from 'react-alert'

import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import input from '@mui/material/Input'
import { Grid, Snackbar, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useReducer, useState, Fragment } from 'react'
import { createBrowserHistory } from 'history'
import Checkbox from '@mui/material/Checkbox'
import { useLocation } from 'react-router-dom'
import ReactSelect from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Button from '@mui/material/Button'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'

const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

const history = createBrowserHistory({ forceRefresh: true })
const fields = ['Id', 'EmailAddress', 'password', 'patientId']
const defValues = {
  patientId: '',
  Id: '',
  EmailAddress: '',
  password: '',
}
function handleCancel() {
  history.push('/PatientSearch')
}
function InvitePatient(props) {
  const location = useLocation()
  const alert = useAlert()
  const [submitting, setSubmitting] = useState(false)
  const [emailerror, setemailerror] = useState('')
  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }
  const validationSchema = Yup.object({
    // password: Yup.string().required('password is required'),
    EmailAddress: Yup.string()
      .required('Email is required')
      .email('UserEmail already Exist'),
  })

  const [isChecked, setIsChecked] = useState('')
  const [passerror, setpasserror] = useState(false)
  

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked)
    console.log('event.target.checked value', event.target.checked)
  }

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  })
  const patientLogin = props.location.state.patientLogin
  // console.log('patientLogin:',patientLogin)
  const [email, setEmail] = useState(undefined)
  const [Id, setId] = useState(undefined)
  useEffect(() => {
    if (patientLogin != null && patientLogin.length > 0) {
      const arr = patientLogin.split(';')
      setEmail(arr[0])
      setId(arr[1])
    }
  }, [])
  const errorMesage = {
    color: 'red',
    fontSize: '13px',
    marign: '0px',
  }
  const onSubmit = (data) => {
    setSubmitting(true)
    const postData = data
    if (Id != null && Id > 0) {
      postData.Id = parseInt(Id)
    } else {
      postData.Id = 0
    }

    postData.patientId = parseInt(props.location.state.patientId)
    postData.sendEmail = true
    // console.log('Data: ', postData)
    // console.log(JSON.stringify(postData, null, 2))
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientPortal/invitePatient`,
        postData,
        { headers }
      )
      .then((response) => {
        setSubmitting(false)
        if (response.data.id == undefined) {
          setemailerror(response.data)
        } else {
          alert.success('Record saved successfully.', {
            type: 'success',
            onClose: () => {
              history.push('/PatientSearch')
            },
          })
        }
      })
      .catch((error) => {
        setSubmitting(false)
        alert.success(`Error ${error.message}`, {
          type: 'error',
        })
      })
  }

  const onSubmitSave = (data) => {
    setSubmitting(true)

    const postData = data
    if (Id != null && Id > 0) {
      postData.Id = parseInt(Id)
    } else {
      postData.Id = 0
    }
    if (isChecked && postData.password.length === 0) {
      setpasserror(true)
      setSubmitting(false)
      return
      // Yup.string().required('Password is required');
    }

    postData.patientId = parseInt(props.location.state.patientId)

    // console.log('Data: ', postData)
    // console.log(JSON.stringify(postData, null, 2))
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/PatientPortal/invitePatient`,
        postData,
        { headers }
      )
      .then((response) => {
        setSubmitting(false)
        if (response.data.id == undefined) {
          setemailerror(response.data)
        } else {
          alert.success('Record saved successfully.', {
            type: 'success',
            onClose: () => {
              history.push('/PatientSearch')
            },
          })
        }
      })
      .catch((error) => {
        setSubmitting(false)
        alert.success(`Error ${error.message}`, {
          type: 'error',
        })
      })
  }
  return (
    <>
      <Grid
        container
        spacing={1}
        alignContent="center"
        justifyContent="center"
        style={{ paddingLeft: '150px', paddingRight: '150px' }}
      >
        <Grid item xs={6} style={{ width: '300px' }}>
          <SuiTypography component="label" variant="caption" fontWeight="bold">
            User Name
          </SuiTypography>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6} style={{ width: '300px' }}>
          <SuiInput
            value={email}
            readOnly={email ? true : false}
            // backgroundColor='#00CC00'
            type="input"
            placeholder="Enter Email Address"
            {...register('EmailAddress')}
            error={errors.EmailAddress}
          />
          {emailerror ? (
            <p style={{ color: 'red', fontSize: '7' }}>{emailerror}</p>
          ) : null}
          {email != null && email != undefined ? (
            <p style={{ color: '#1A4D8C', fontSize: '7' }}>
              {'You cannot modify username!'}
            </p>
          ) : null}
        </Grid>
        <Grid item xs={6}></Grid>
        {/* {email?  ("") : ( */}
        <Grid item xs={12}>
          <Checkbox
            {...label}
            // defaultChecked={""}
            style={{ float: 'left' }}
            onChange={handleCheckboxChange}
            // disabled={submitting || isChecked}
          />
          <SuiTypography>Set Password</SuiTypography>
        </Grid>

        {isChecked ? (
          <>
            <Grid item xs={6} style={{ width: '300px' }}>
              <SuiTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Password
              </SuiTypography>
            </Grid>

            <Grid item xs={6}></Grid>

            <Grid item xs={6} style={{ width: '300px' }}>
              <SuiInput
                type="input"
                placeholder="Enter password"
                {...register('password')}
                error={errors.password}
                onChange={(ev) => {
                  setpasserror(false)
                }}
              />
              {passerror ? <p style={errorMesage}>Enter Passsword</p> : null}
            </Grid>
            <Grid item xs={6}></Grid>
          </>
        ) : (
          ''
        )}

        {!isChecked ? (
          <Grid item xs={4} style={{ width: '300px' }}>
            <Button
              onClick={handleSubmit(onSubmit)}
              variant="gradient"
              disabled={submitting}
              style={{
                marginTop: '20px',
                marginLeft: '0px',
                width: email ? '235px' : '80px',
                padding: '1px',
                float: email ? 'left' : 'right',
                height: '35px',
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
              }}
            >
              <EmailIcon
                fontSize="medium"
                style={{
                  color: '#1a82ad',
                  paddingRight: '5px',
                  paddingBottom: '2px',
                }}
              ></EmailIcon>
              {email != null && email != undefined
                ? 'Send Recovery Email'
                : 'Email'}
            </Button>
          </Grid>
        ) : (
          <Grid item xs={4} style={{ width: '300px' }}>
            <Button
              onClick={handleSubmit(onSubmitSave)}
              variant="gradient"
              disabled={submitting}
              style={{
                marginTop: '20px',
                // float: "right",
                float: !isChecked ? 'left' : 'right',
                width: '80px',
                height: '35px',
                marginright: 0,
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '14px',
              }}
            >
              <SaveOutlinedIcon
                fontSize="medium"
                style={{ color: 'red', paddingRight: '5px' }}
              ></SaveOutlinedIcon>
              {/* {email != null && email != undefined ? 'Save' : 'Save'} */}
              Save
            </Button>
          </Grid>
        )}

        <Grid item xs={2} style={{ width: '300px' }}>
          <Button
            onClick={handleCancel}
            variant="gradient"
            disabled={submitting}
            style={{
              marginTop: '20px',
              float: 'left',
              width: '90px',
              height: '35px',
              marginright: 0,
              backgroundColor: '#FCBE13',
              color: 'black',
              fontSize: '14px',
            }}
          >
            <CancelOutlinedIcon
              fontSize="medium"
              style={{ color: 'red', paddingRight: '5px' }}
            ></CancelOutlinedIcon>
            {email != null && email != undefined ? 'Cancel' : 'cancel'}
          </Button>
        </Grid>

        <Grid item xs={4}></Grid>
      </Grid>
    </>
  )
}

export default InvitePatient
