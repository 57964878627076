import {
    CircularProgress,
    Grid,
    InputAdornment,
    Snackbar,
    TextField,
    Typography,
} from '@mui/material'
import axios from 'axios'
import React, { useEffect, useReducer, useState, Fragment, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { TabPanel, TabContext, TabList } from '@mui/lab'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import { useAlert } from 'react-alert'
import { DataGrid } from '@mui/x-data-grid'
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import Footer from 'examples/Footer'
import Table from 'examples/Tables/Table'
import input from '@mui/material/Input'
import Moment from 'moment'
import Avatar from '@mui/material/Avatar'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import handleClick from 'material-paper/build/lib/classes/handleclick'
import SuiInput from "components/SuiInput";

const PastMedicalHistory = (props) => {
    const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
    const headers = {
      Authorization: auth,
    }
    const alert = useAlert()
    const validationSchema = Yup.object({
        status: Yup.string().required("status is required"),
        condition: Yup.string().required("condition is required"),

      });
const patientId=props.patientId

    const fields =[
    "id",
    "practiceId",
    "patientId",
    "condition",
    "status",
    "isActive",
    "diagnosisDate",
    "resolutionDate",
    "lastupdatedDate",
    "isDiabetes",
    "isHypertension",
    "isThyrioddisease",
    "isHeartdisease",
    "iskidneydisease",
    "islungdisease",
    "isAutoimmunedisease",
    "isChronicpain",
    "isArthritis",
    "isVisualimpairnent",
    "isDyslipidemia",
    "isSleepapnea",
    "isSeasonal",
    "isInsomnia",
    "isGastriontestinal",
    "createdDate",
    "updatedDate",
    "createdBy",
    "updatedBy"
]
    const defValues = {
        id: 0,
        practiceId: 0,
        patientId: 0,
        condition:'',
        status:'',
        isActive:'',
        diagnosisDate:'',
        resolutionDate:'',
        lastupdatedDate:'',
        isDiabetes:'',
        isHypertension:'',
        isThyrioddisease:'',
        isHeartdisease:'',
        iskidneydisease:'',
        islungdisease:'',
        isAutoimmunedisease:'',
        isChronicpain:'',
        isArthritis:'',
        isVisualimpairnent:'',
        isDyslipidemia:'',
        isSleepapnea:'',
        isSeasonal:'',
        isInsomnia:'',
        isGastriontestinal:'',
        createdDate:'',
        updatedDate:'',
        createdBy:'',
        updatedBy:''
    }
    const {
        register,
        control,
        handleSubmit,
        setValue,
        reset,
        getValues,
        formState: { errors },
      } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: false,
        shouldUseNativeValidation: false,
        delayError: undefined,
        resolver: yupResolver(validationSchema),
        defaultValues: defValues,
      });
      const [refresh, setRefresh] = React.useState(false)
      const [submitting, setSubmitting] = useState(false)
      const [isDiabetes, setIsDiabetes] = useState(false);
      const [isHypertension, setisHypertension] = useState(false);
      const [isThyrioddisease, setisThyrioddisease] = useState(false);
      const [isHeartdisease, setisHeartdisease] = useState(false);
      const [iskidneydisease, setiskidneydisease] = useState(false);
      const [islungdisease, setislungdisease] = useState(false);
      const [isAutoimmunedisease, setisAutoimmunedisease] = useState(false);
      const [isChronicpain, setisChronicpain] = useState(false);

      const [isArthritis, setisArthritis] = useState(false);
      const [isVisualimpairnent, setisVisualimpairnent] = useState(false);
      const [isDyslipidemia, setisDyslipidemia] = useState(false);
      const [isSleepapnea, setisSleepapnea] = useState(false);
      const [isSeasonal, setisSeasonal] = useState(false);
      const [isInsomnia, setisInsomnia] = useState(false);
      const [isGastriontestinal, setisGastriontestinal] = useState(false);



      const onSubmit = (data) => {   
        setSubmitting(true)
    
        const postData = {
          ...data,
          patientId:props.patientId
        }
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/PatientMedicalNotes/AddPastMedicalHistory`,
            postData,
            { headers }
          )
          .then((response) => {
            setRefresh(!refresh)
            setSubmitting(false)
    
             alert.success('Record saved successfully.', {
              type: 'success',
              // onClose: () => {
              //   setOpenNote(false)
              //   setSubmitting(false)
              // },
            })
          })
          .catch((error) => {
            setSubmitting(false)
            alert.success(`Error ${error.message}`, {
              type: 'error',
            })
          })
      }
      useEffect(() => {
        // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    
        axios
          .get
          (`${process.env.REACT_APP_API_URL}/PatientMedicalNotes/getPastMedicalHistoryByPatientId?patientId=${props.patientId}`, { headers })
          .then(response => { 
            fields.forEach((field) => setValue(field, response.data[field]));
            const diagnosisDate = Moment(response.data.diagnosisDate).format("YYYY-MM-DD");
                setValue('diagnosisDate', diagnosisDate);
                const resolutionDate = Moment(response.data.resolutionDate).format("YYYY-MM-DD");
                setValue('resolutionDate', resolutionDate);
                const lastupdatedDate = Moment(response.data.lastupdatedDate).format("YYYY-MM-DD");
                setValue('lastupdatedDate', lastupdatedDate);
                
            setIsDiabetes(response.data.isDiabetes)
            setisHypertension(response.data.isHypertension)
            setisThyrioddisease(response.data.isThyrioddisease)
            setisHeartdisease(response.data.isHeartdisease)
            setislungdisease(response.data.islungdisease)
            setiskidneydisease(response.data.iskidneydisease)
            setisAutoimmunedisease(response.data.isAutoimmunedisease)
            setisChronicpain(response.data.isChronicpain)
            setisVisualimpairnent(response.data.isVisualimpairnent)
            setisArthritis(response.data.isArthritis)
            setisDyslipidemia(response.data.isDyslipidemia)
            setisGastriontestinal(response.data.isGastriontestinal)
            setisInsomnia(response.data.isInsomnia)
            setisSeasonal(response.data.isSeasonal)
            setisSleepapnea(response.data.isSleepapnea)



          })
          .catch(error => {
            console.error('There was an error!', error)
          })
      }, [])
      const handleChangeHyper = (event) => {
        setisHypertension(event.target.checked); 
        setValue('isHypertension', event.target.checked);
      };
      const handleChangeThyr = (event) => {
        setisThyrioddisease(event.target.checked); 
        setValue('isThyrioddisease', event.target.checked);
      };
      const handleChangeHeart = (event) => {
        setisHeartdisease(event.target.checked); 
        setValue('isHeartdisease', event.target.checked);
      };
      const handleChangelung = (event) => {
        setislungdisease(event.target.checked); 
        setValue('islungdisease', event.target.checked);
      };
      const handleChangekidney= (event) => {
        setiskidneydisease(event.target.checked); 
        setValue('iskidneydisease', event.target.checked);
      };
      const handleChangeChron= (event) => {
        setisChronicpain(event.target.checked); 
        setValue('isChronicpain', event.target.checked);
      };
      const handleChangeAuto= (event) => {
        setisAutoimmunedisease(event.target.checked); 
        setValue('isAutoimmunedisease', event.target.checked);
      };
      const handleChangeVisual= (event) => {
        setisVisualimpairnent(event.target.checked); 
        setValue('isVisualimpairnent', event.target.checked);
      };
      const handleChangeArth= (event) => {
        setisArthritis(event.target.checked); 
        setValue('isArthritis', event.target.checked);
      };
      const handleChangeDys= (event) => {
        setisDyslipidemia(event.target.checked); 
        setValue('isDyslipidemia', event.target.checked);
      };
      const handleChangeGast= (event) => {
        setisGastriontestinal(event.target.checked); 
        setValue('isGastriontestinal', event.target.checked);
      };
      const handleChangeIns= (event) => {
        setisInsomnia(event.target.checked); 
        setValue('isInsomnia', event.target.checked);
      };
      const handleChangeSeasonal= (event) => {
        setisSeasonal(event.target.checked); 
        setValue('isSeasonal', event.target.checked);
      };
      const handleChangeSleep= (event) => {
        setisSleepapnea(event.target.checked); 
        setValue('isSleepapnea', event.target.checked);
      };
      const handleSwitchChange = (event) => {
        setIsDiabetes(event.target.checked); 
        setValue('isDiabetes', event.target.checked);
      };
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                        Condition
                    </Typography>
                    <Controller
                        name="condition"
                        {...register("condition")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // isClearable
                                size="small"
                                placeholder="enter condition"
                                {...field}
                                fullWidth

                                error={errors.condition}
                                />
                              )}
                            />
                            <p style={{ color: 'red', fontSize: '14px' }}>{errors.condition?.message}</p>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                        Status
                    </Typography>
                    <Controller
                        name="status"
                        {...register("status")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // isClearable
                                size="small"
                                placeholder="enter status"
                                {...field}
                                fullWidth
                                error={errors.status}
                                />
                              )}
                            />
                            <p style={{ color: 'red', fontSize: '14px' }}>{errors.status?.message}</p>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                        Diagnosis Date
                    </Typography>
                    <Controller
                        name="diagnosisDate"
                        {...register("diagnosisDate")}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                // isClearable
                                type="date"
                                size="small"
                                {...field}
                                fullWidth
                                />
                              )}
                            />
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                        Resolution Date
                    </Typography>
                    <SuiInput
                            type="date"
                            name="resolutionDate"
                            fullWidth
                            margin="dense"
                            {...register("resolutionDate")}
                          /> 
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ fontSize: '15px' }}>
                        Last Updated Date
                    </Typography>
                    <SuiInput
                            type="date"
                            name="lastupdatedDate"
                            fullWidth
                            margin="dense"
                            {...register("lastupdatedDate")}
                          /> 
                </Grid>
                <Grid container xs={12} sm={12} md={12} lg={12}>
          {/* {pastMedicalHistory.map((e,i)=>{
            return(
              <>
                <Grid container xs={12} sm={4} md={4} lg={4}>               
                  <Grid
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{
                      border: '2px solid black',
                      margin: '10px',
                      padding: '9px',
                      borderRadius: '40px',
                      backgroundColor: '#BAE1F1',
                    }}
                  >
                   
                    <Grid item md={10}>
                      <SuiTypography>
                        {e}
                      </SuiTypography>
                    </Grid>
                    <Grid item
                      md={2}
                    >
                      <>
                        <Switch
                        //   checked={item.isChecked}
                          color="warning"
                          onChange={(event) => {}}
                        />
                      </>
                    </Grid>                
                  </Grid>
                </Grid>
                </>
              
            )


          })} */}
                     <Grid container xs={12} mt={2} >               
                  <Grid
                    container
                    xs={3.5}
                   
                    style={{
                      border: '2px solid black',
                      margin: '10px',
                      padding: '9px',
                      borderRadius: '40px',
                      backgroundColor: '#BAE1F1',
                    }}
                  >
                  <Grid item xs={10}>
                      <SuiTypography>
                       Diabetes
                      </SuiTypography>
                    </Grid>
                    <Grid item
                      xs={2}
                    >
                      <>
                      <Switch
          name="isDiabetes"
          {...register("isDiabetes")}
          checked={isDiabetes} // Bind checked state to the switch
          color="warning"
          onChange={handleSwitchChange} // Update state on change
        />
                      </>
                    </Grid> 
                  </Grid>
                  <Grid
                    container
                    xs={3.5}

                    
                    style={{
                      border: '2px solid black',
                      margin: '10px',
                      padding: '9px',
                      borderRadius: '40px',
                      backgroundColor: '#BAE1F1',
                    }}
                  >
                  <Grid item xs={10}>
                      <SuiTypography>
                      Hypertension
                      </SuiTypography>
                    </Grid>
                    <Grid item
                      xs={2}
                    >
                      <>
                        <Switch
                            name="isHypertension"
                            {...register("isHypertension")}
                          checked={isHypertension}
                          color="warning"
                          onChange={handleChangeHyper} 
                        />
                      </>
                    </Grid> 
                  </Grid>
                  <Grid
                    container
                    xs={3.5}

                    
                    style={{
                      border: '2px solid black',
                      margin: '10px',
                      padding: '9px',
                      borderRadius: '40px',
                      backgroundColor: '#BAE1F1',
                    }}
                  >
                  <Grid item xs={10}>
                      <SuiTypography>
                      Thyroid Disease
                      </SuiTypography>
                    </Grid>
                    <Grid item
                      xs={2}
                    >
                      <>
                        <Switch
                            name="isThyrioddisease"
                            {...register("isThyrioddisease")}
                          checked={isThyrioddisease}
                          color="warning"
                          onChange={handleChangeThyr} 

                        />
                      </>
                    </Grid> 
                  </Grid>
                  <Grid
                    container
                    xs={3.5}

                    
                    style={{
                      border: '2px solid black',
                      margin: '10px',
                      padding: '9px',
                      borderRadius: '40px',
                      backgroundColor: '#BAE1F1',
                    }}
                  >
                  <Grid item xs={10}>
                      <SuiTypography>
                      Heart Disease
                      </SuiTypography>
                    </Grid>
                    <Grid item
                      xs={2}
                    >
                      <>
                        <Switch
                            name="isHeartdisease"
                            {...register("isHeartdisease")}
                          checked={isHeartdisease}
                          color="warning"
                          onChange={handleChangeHeart} 

                        />
                      </>
                    </Grid> 
                  </Grid>
                  <Grid
                    container
                    xs={3.5}

                    style={{
                      border: '2px solid black',
                      margin: '10px',
                      padding: '9px',
                      borderRadius: '40px',
                      backgroundColor: '#BAE1F1',
                    }}
                  >
                  <Grid item xs={10}>
                      <SuiTypography>
                      Kidney Disease
                      </SuiTypography>
                    </Grid>
                    <Grid item
                      xs={2}
                    >
                      <>
                        <Switch
                            name="iskidneydisease"
                            {...register("iskidneydisease")}
                          checked={iskidneydisease}
                          color="warning"
                          onChange={handleChangekidney} 

                        />
                      </>
                    </Grid> 
                  </Grid>
                  <Grid
                    container
                    xs={3.5}

                    
                    style={{
                      border: '2px solid black',
                      margin: '10px',
                      padding: '9px',
                      borderRadius: '40px',
                      backgroundColor: '#BAE1F1',
                    }}
                  >
                  <Grid item xs={10}>
                      <SuiTypography>
                      Lung Disease
                      </SuiTypography>
                    </Grid>
                    <Grid item
                      xs={2}
                    >
                      <>
                        <Switch
                            name="islungdisease"
                            {...register("islungdisease")}
                          checked={islungdisease}
                          color="warning"
                          onChange={handleChangelung} 

                        />
                      </>
                    </Grid> 
                  </Grid>
                  <Grid
                    container
                    xs={3.5}

                    
                    style={{
                      border: '2px solid black',
                      margin: '10px',
                      padding: '9px',
                      borderRadius: '40px',
                      backgroundColor: '#BAE1F1',
                    }}
                  >
                  <Grid item xs={10}>
                      <SuiTypography>
                      Autoimmune Disease
                      </SuiTypography>
                    </Grid>
                    <Grid item
                      xs={2}
                    >
                      <>
                        <Switch
                            name="isAutoimmunedisease"
                            {...register("isAutoimmunedisease")}
                          checked={isAutoimmunedisease}
                          color="warning"
                          onChange={handleChangeAuto} 

                        />
                      </>
                    </Grid> 
                  </Grid>
                  <Grid
                    container
                    xs={3.5}

                    
                    style={{
                      border: '2px solid black',
                      margin: '10px',
                      padding: '9px',
                      borderRadius: '40px',
                      backgroundColor: '#BAE1F1',
                    }}
                  >
                  <Grid item xs={10}>
                      <SuiTypography>
                      Chronic Pain
                      </SuiTypography>
                    </Grid>
                    <Grid item
                      xs={2}
                    >
                      <>
                        <Switch
                            name="isChronicpain"
                            {...register("isChronicpain")}
                          checked={isChronicpain}
                          color="warning"
                          onChange={handleChangeChron} 

                        />
                      </>
                    </Grid> 
                  </Grid>
                  <Grid
                    container
                    xs={3.5}

                    
                    style={{
                      border: '2px solid black',
                      margin: '10px',
                      padding: '9px',
                      borderRadius: '40px',
                      backgroundColor: '#BAE1F1',
                    }}
                  >
                  <Grid item xs={10}>
                      <SuiTypography>
                      Arthritis
                      </SuiTypography>
                    </Grid>
                    <Grid item
                      xs={2}
                    >
                      <>
                        <Switch
                            name="isArthritis"
                            {...register("isArthritis")}
                          checked={isArthritis}
                          color="warning"
                          onChange={handleChangeArth} 

                        />
                      </>
                    </Grid> 
                  </Grid>
                  <Grid
                    container
                    xs={3.5}

                    
                    style={{
                      border: '2px solid black',
                      margin: '10px',
                      padding: '9px',
                      borderRadius: '40px',
                      backgroundColor: '#BAE1F1',
                    }}
                  >
                  <Grid item xs={10}>
                      <SuiTypography>
                      Visual Impairment
                      </SuiTypography>
                    </Grid>
                    <Grid item
                      xs={2}
                    >
                      <>
                        <Switch
                            name="isVisualimpairnent"
                            {...register("isVisualimpairnent")}
                          checked={isVisualimpairnent}
                          color="warning"
                          onChange={handleChangeVisual} 

                        />
                      </>
                    </Grid> 
                  </Grid>
                  <Grid
                    container
                    xs={3.5}

                    
                    style={{
                      border: '2px solid black',
                      margin: '10px',
                      padding: '9px',
                      borderRadius: '40px',
                      backgroundColor: '#BAE1F1',
                    }}
                  >
                  <Grid item xs={10}>
                      <SuiTypography>
                      Dyslipidemia
                      </SuiTypography>
                    </Grid>
                    <Grid item
                      xs={2}
                    >
                      <>
                        <Switch
                            name="isDyslipidemia"
                            {...register("isDyslipidemia")}
                          checked={isDyslipidemia}
                          color="warning"
                          onChange={handleChangeDys} 

                        />
                      </>
                    </Grid> 
                  </Grid>
                  <Grid
                    container
                    xs={3.5}

                    
                    style={{
                      border: '2px solid black',
                      margin: '10px',
                      padding: '9px',
                      borderRadius: '40px',
                      backgroundColor: '#BAE1F1',
                    }}
                  >
                  <Grid item xs={10}>
                      <SuiTypography>
                      Sleep Apnea
                      </SuiTypography>
                    </Grid>
                    <Grid item
                      xs={2}
                    >
                      <>
                        <Switch
                            name="isSleepapnea"
                            {...register("isSleepapnea")}
                          checked={isSleepapnea}
                          color="warning"
                          onChange={handleChangeSleep} 

                        />
                      </>
                    </Grid> 
                  </Grid>
                  <Grid
                    container
                    xs={3.5}

                    
                    style={{
                      border: '2px solid black',
                      margin: '10px',
                      padding: '9px',
                      borderRadius: '40px',
                      backgroundColor: '#BAE1F1',
                    }}
                  >
                  <Grid item xs={10}>
                      <SuiTypography>
                      Seasonal Allergies
                      </SuiTypography>
                    </Grid>
                    <Grid item
                      xs={2}
                    >
                      <>
                        <Switch
                            name="isSeasonal"
                            {...register("isSeasonal")}
                          checked={isSeasonal}
                          color="warning"
                          onChange={handleChangeSeasonal} 

                        />
                      </>
                    </Grid> 
                  </Grid>
                  <Grid
                    container
                    xs={3.5}

                    
                    style={{
                      border: '2px solid black',
                      margin: '10px',
                      padding: '9px',
                      borderRadius: '40px',
                      backgroundColor: '#BAE1F1',
                    }}
                  >
                  <Grid item xs={10}>
                      <SuiTypography>
                      Insomnia
                      </SuiTypography>
                    </Grid>
                    <Grid item
                      xs={2}
                    >
                      <>
                        <Switch
                            name="isInsomnia"
                            {...register("isInsomnia")}
                          checked={isInsomnia}
                          color="warning"
                          onChange={handleChangeIns} 

                        />
                      </>
                    </Grid> 
                  </Grid>
                  <Grid
                    container
                    xs={3.5}

                    
                    style={{
                      border: '2px solid black',
                      margin: '10px',
                      padding: '9px',
                      borderRadius: '40px',
                      backgroundColor: '#BAE1F1',
                    }}
                  >
                  <Grid item xs={10}>
                      <SuiTypography>
                      Gastrointestinal
                      </SuiTypography>
                    </Grid>
                    <Grid item
                      xs={2}
                    >
                      <>
                        <Switch
                            name="isGastriontestinal"
                            {...register("isGastriontestinal")}
                          checked={isGastriontestinal}
                          color="warning"
                          onChange={handleChangeGast} 

                        />
                      </>
                    </Grid> 
                  </Grid>



                  </Grid>  
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button
                        onClick={handleSubmit(onSubmit)}
                            variant="gradient"
                            style={{
                                marginTop: '10px',
                                marginBottom: '10px',
                                float: 'right',
                                marginRight: '5px',
                                marginLeft: 'auto',
                                width: '80px',
                                height: '35px',
                                backgroundColor: '#FCBE13',
                                color: 'black',
                                fontSize: '14px',
                            }}
                        >
                            {/* <SaveIcon
                        fontSize="medium"
                        style={{ color: '#0a9ff0', paddingRight: '5px' }}
                      ></SaveIcon> */}
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>


        </>
    )
}

export default PastMedicalHistory
