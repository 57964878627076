import React from 'react'
import AddNotes from './AddNotes'

function Templates() {
  return (
    <div>
                <AddNotes    patientnoteid={0}
              patientId={0}
              readOnly={false}></AddNotes>

    </div>
  )
}

export default Templates
