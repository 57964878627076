/**
=========================================================
* Soft UI Dashboard React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card'
import axios from 'axios'
import { Grid, Snackbar, TextField } from '@mui/material'
// Soft UI Dashboard React components
import SuiBox from 'components/SuiBox'
import SuiTypography from 'components/SuiTypography'
import SuiButton from 'components/SuiButton'
import SuiAvatar from 'components/SuiAvatar'
import Table from 'examples/Tables/Table'
import { useEffect, useState } from 'react'
import AddIcon from 'assets/add.png'

import { createBrowserHistory } from 'history'
import { DataGrid } from '@mui/x-data-grid'
import { styled } from '@mui/material/styles'
import customStyle from '../assets/customStyle.css'
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Button from '@mui/material/Button';
import {  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Backdrop } from '@mui/material';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CircularProgress from "@mui/material/CircularProgress";

const history = createBrowserHistory({ forceRefresh: true })

function Users() {
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px',
    },
  }

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 5,
    background: 'white',
    fontSize: '13.6px !important',
    color: '#8392ab !important',
    borderColor: 'rgba(5, 152, 236, 0.637) !important',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#FFFFC2',
       borderRadius: 1.8,
    },
  }
  const [refresh, setRefresh] = useState(false)
  const [openDialog, setOpenDialog] = useState(false);

  const handleDelete = (cell) => {
    // Your delete logic here
    const postData = {
      email: cell.row.email,
      fullName: cell.row.fullName,
      userType: cell.row.userType,
      facilityName: cell.row.facilityName,
      id: cell.row.id,
      deleted: true,
      userId: cell.row.userId,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/Users/userRegistration`, postData, { headers })
      .then((response) => {
        setRefresh(!refresh);
        // setOpenNote(false)
        // console.log('response.data: ', response.data)
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });

    // Close the dialog after handling the delete
    setOpenDialog(false);
  };
  const columns = [
    {
      field: 'email',headerClassName: 'super-app-theme--header',
      headerName: 'Email Address',
      cellClassName: 'gridCell',
      flex: 0.5,
      renderCell: (cellValues) => (
        <SuiButton
          size="small"
          variant="text"
          color="dark"
          onClick={(event) => {
            openUserRegistration(event, cellValues.row.id)
          }}
        >{`${cellValues.row.email}`}</SuiButton>
      ),
    },
    {
      field: 'fullName',headerClassName: 'super-app-theme--header',
      headerName: 'Name',
      flex: 0.3,
    },
    {
      field: 'userType',headerClassName: 'super-app-theme--header',
      headerName: 'User Type',
      flex: 0.2,
    },
    {
      field: 'practices',headerClassName: 'super-app-theme--header',
      headerName: 'Practices',
      cellClassName: 'gridCell',
      // sortable: false,
      // type: 'boolean',
      flex: 0.4,
    },
    {
      field: 'delete',headerClassName: 'super-app-theme--header',
      headerName: 'Delete',
      sortable: true,
      flex: 0.1,
      renderCell: (cell) => (
        <>
      {/* Your other code */}
      <DeleteIcon
        style={{
          marginRight: '5px',
          float: 'right',
          marginTop: '5px',
        }}
        color="red"
        onClick={() => setOpenDialog(true)}
      ></DeleteIcon>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}
      
      BackdropComponent={Backdrop}
      BackdropProps={{
        invisible: true,
      }}
      PaperProps={{
        style: {background:'#cef5f0',
        boxShadow:'none',
        borderRadius:'8px'
        },
     }} >
   <div style={{borderRadius:'8px',width:'22rem',background:'white'}}>
   
   <ErrorOutlineIcon style={{marginLeft:'125px',fontSize:'100px',color:'orange'}}></ErrorOutlineIcon>
       <DialogTitle style={{marginLeft:'1rem',fontWeight:'bold'}}>{"Do you want to delete the record?"}</DialogTitle>
       <h5 style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'normal',marginLeft:'-2rem' }}>
This action cannot be undo.
</h5>
       <DialogContent>
           <DialogContentText>
           <Grid style={{marginLeft:'90px'}}>
           <Button  onClick={()=>{handleDelete(cell)}} style={{backgroundColor:'red',color:'white'}}>YES</Button>
           <Button  onClick={() => setOpenDialog(false)}   style={{backgroundColor:'blue',color:'white',marginLeft:'1rem'}}>NO</Button>
           </Grid>
           </DialogContentText>
       </DialogContent>
      
       



</div>

      </Dialog>
    </>
      ),
    },
  ]

  const [rows, setRows] = useState(null)
  const [submitting, setsubmitting] = useState(false)

  const auth = `Bearer  ${localStorage.getItem('dtt_token')}`
  const headers = {
    Authorization: auth,
  }
  useEffect(() => {setsubmitting(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Users/getUsers`, { headers })
      .then((response) =>{ setRows(response.data)
        setsubmitting(false)})
      .catch((error) => {setsubmitting(false)})

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])

  const openUserRegistration = (event, id) => {
    history.push({
      pathname: `/UserRegistration`,
      state: { userId: id },
    })
    window.location.reload(false)
  }
  return (
    <>
      <Grid
        container
        spacing={1}
        style={{
          paddingTop: '10px',
          paddingLeft: '10px',
          paddingRight: '50px',
        }}
      >
        <Grid item xs={6} style={{ width: '300px' }}>
          <SuiBox mb={3}>
            <Button
              sx={{ mb: 5 }}
              onClick={openUserRegistration}
              variant="gradient"
              style={{
                width: '70px',
                height:"33px",
              backgroundColor: "#FCBE13",
            color:"black",
            fontSize:'14px',

          }}
          // color="warning"
          // size="small"

          // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}

        >
          <AddOutlinedIcon
          fontSize='medium'
          style={{ color:'#1a82ad', paddingRight:'4px', paddingBottom:'2px'}}
          >
          </AddOutlinedIcon>
              New
            </Button>
          </SuiBox>
        </Grid>
        <Grid item xs={12} style={{ width: '300px' }}>
          <SuiBox mb={3}>
        
            <div style={{ height: 353, width: '100%' }}>
            {submitting ? (
          <CircularProgress
            style={{
              width: "50px",
              height: "50px",
              position: "absolute",
              left: "50%",
              top: "50%",
              marginLeft: "-25px",
              marginTop: "-25px",
              zIndex: 1000,
              color: "#FCBE13",
            }}
          />
        ) : rows === null || rows.length === 0 ? "No Record Found" : (
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  headerHeight={37}
                  rowsPerPageOptions={[5]}
                  sx={gridRowStyle}
                />
              )}
            </div>
          </SuiBox>
        </Grid>
      </Grid>
    </>
  )
}

export default Users
