import { Grid, CircularProgress } from '@mui/material'
import Icon from '@mui/material/Icon'
import { DataGrid } from '@mui/x-data-grid'
import Box from '@mui/material/Box'
// import CachedIcon from '@mui/icons-material/Cached';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles'
import { withStyles } from '@material-ui/core/styles'
import { Tooltip } from '@material-ui/core'
import FormControl from '@mui/material/FormControl'

// Soft UI Dashboard React components
import SuiBox from 'components/SuiBox'
import SuiBadge from 'components/SuiBadge'
import SuiTypography from 'components/SuiTypography'
import ReactSelect from 'react-select'
// Soft UI Dashboard React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Footer from 'examples/Footer'
import MiniStatisticsCard from 'examples/Cards/StatisticsCards/MiniStatisticsCard'
import ReportsBarChart from 'examples/Charts/BarCharts/ReportsBarChart'
import GradientLineChart from 'examples/Charts/LineCharts/GradientLineChart'
import SuiButton from 'components/SuiButton'
import SuiInput from 'components/SuiInput'
import { createBrowserHistory } from 'history'
import { Controller, useForm } from 'react-hook-form'
import MenuItem from '@mui/material/MenuItem'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
// import PatientFilters from './PatientFilters'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'

import Select from '@mui/material/Select'
import InputMask from 'react-input-mask'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
// Soft UI Dashboard React base styles
import typography from 'assets/theme/base/typography'
import { useEffect, useState } from 'react'
import axios from 'axios'
import TextField from '@mui/material/TextField'

import { Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SuiSelect from 'components/SuiSelect'
import * as React from 'react'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import Button from '@mui/material/Button'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { Favorite, Sick, Warning } from '@mui/icons-material'
// import VideoComponent from './VideoComponent';
// import Video from './Video';
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

// const [refresh, setRefresh] = useState(false)

const history = createBrowserHistory({ forceRefresh: true })
const headers = {
  Authorization: `Bearer  ${localStorage.getItem('dtt_token')}`,
}

function Dashboard() {
  const [filter, setfilter] = useState([])
  const [focus, setFocus] = useState(false)
  const NO_ROWS_LABEL = 'No Record found'
  const GETTING_ROWS_LABEL = 'Please wait!! Your data is being loaded..'
  const [status, setStatus] = useState({ value: 'Active', label: 'Active' })

  const [NoRowsLabel, setNoRowsLabel] = useState(NO_ROWS_LABEL)
  const handleClick = (event) => {
    // 👇️ toggle shown state
    var newfilter = filter
    var id = filter.length + 1
    var boj = {
      id: id,
      columns: '',
      operator: '',
      value: '',
      value2: '',
      type: '',
      dualField: '',
      filter: [],
    }
    newfilter.push(boj)
    setfilter([...newfilter])
    // setUpdated(id)
    // console.log('Testfilter', newfilter)
    setFocus(false)
  }

  // Filter Applied From There

  function PatientFilters() {
    const check = createFilterOptions()
    const handleCancel = (event) => {
      // setfilter(false);
      var newfilter = filter
      newfilter.splice(index, 1)
      setfilter([...newfilter])
    }

    const {
      register,
      control,
      handleSubmit,
      setValue,
      getValues,
      formState: { errors },
    } = useForm({
      mode: 'onBlur',
      reValidateMode: 'onChange',
      context: undefined,
      criteriaMode: 'firstError',
      shouldFocusError: true,
      shouldUnregister: false,
      shouldUseNativeValidation: false,
      // delayError: undefined,
      // resolver: yupResolver(validationSchema)
    })

    const [value, setvalue] = React.useState([0])
    const whereArray = [
      {
        title: 'PHQ9 Score',
        value: 'phq9Score',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'lessThan', label: 'Less Than' },
          { value: 'greaterThan', label: 'Greater Than' },
          { value: 'between', label: 'Between' },
        ],
      },
      {
        title: 'PHQ9 Average',
        value: 'phq9Average',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'lessThan', label: 'Less Than' },
          { value: 'greaterThan', label: 'Greater Than' },
          { value: 'between', label: 'Between' },
        ],
      },
      {
        title: 'Last PHQ9',
        value: 'lastPhq9',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'lessThan', label: 'Less Than' },
          { value: 'greaterThan', label: 'Greater Than' },
          { value: 'between', label: 'Between' },
        ],
      },
      {
        title: 'GAD7 Score',
        value: 'Gad7Score',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'lessThan', label: 'Less Than' },
          { value: 'greaterThan', label: 'Greater Than' },
          { value: 'between', label: 'Between' },
        ],
      },
      {
        title: 'GAD7 Average',
        value: 'gad7Average',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'lessThan', label: 'Less Than' },
          { value: 'greaterThan', label: 'Greater Than' },
          { value: 'between', label: 'Between' },
        ],
      },
      {
        title: 'Last GAD7',
        value: 'lastGad7',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'lessThan', label: 'Less Than' },
          { value: 'greaterThan', label: 'Greater Than' },
          { value: 'between', label: 'Between' },
        ],
      },
      {
        title: 'First Name',
        value: 'firstName',
        type: 'string',
        filter: [{ value: 'contains', label: 'Contains' }],
      },
      {
        title: 'Last Name',
        value: 'lastName',
        type: 'string',
        filter: [{ value: 'contains', label: 'Contains' }],
      },
      {
        title: 'Email',
        value: 'email',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'notEqual', label: 'Not Equal' },
          { value: 'contains', label: 'Contains' },
          { value: 'notContains', label: 'Not Contains' },
        ],
      },
      {
        title: 'DOB',
        value: 'dob',
        type: 'date',
        filter: [{ value: 'contains', label: 'Contains' }],
      },
      {
        title: 'Gender',
        value: 'gender',
        type: 'string',
        filter: [{ value: 'equal', label: 'Equal' }],
      },
      // { title: 'Status', value: 'status' , type: 'string', filter:[
      //   { value: 'equal', label: 'Equal' },
      //   { value: 'notEqual', label: 'Not Equal' },
      //   { value: 'contains', label: 'Contains' },
      //   { value: 'notContains', label: 'Not Contains' }
      //  ] },

      {
        title: 'Created By',
        value: 'createdBy',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'notEqual', label: 'Not Equal' },
          { value: 'contains', label: 'Contains' },
          { value: 'notContains', label: 'Not Contains' },
        ],
      },
      {
        title: 'Updated By',
        value: 'updatedBy',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'notEqual', label: 'Not Equal' },
          { value: 'contains', label: 'Contains' },
          { value: 'notContains', label: 'Not Contains' },
        ],
      },
      {
        title: 'Created Date',
        value: 'createdDate',
        type: 'date',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'between', label: 'Between' },
        ],
      },
      {
        title: 'Updated Date',
        value: 'updatedDate',
        type: 'date',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'notEqual', label: 'Not Equal' },

          { value: 'between', label: 'Between' },
        ],
      },
      {
        title: 'City',
        value: 'city',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'notEqual', label: 'Not Equal' },
          { value: 'contains', label: 'Contains' },
          { value: 'notContains', label: 'Not Contains' },
        ],
      },
      {
        title: 'State',
        value: 'state',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'notEqual', label: 'Not Equal' },
          { value: 'contains', label: 'Contains' },
          { value: 'notContains', label: 'Not Contains' },
        ],
      },
      {
        title: 'Address',
        value: 'address',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'notEqual', label: 'Not Equal' },
          { value: 'contains', label: 'Contains' },
          { value: 'notContains', label: 'Not Contains' },
        ],
      },

      {
        title: 'ZIP Code',
        value: 'zipCode',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'notEqual', label: 'Not Equal' },
          { value: 'contains', label: 'Contains' },
          { value: 'notContains', label: 'Not Contains' },
        ],
      },

      {
        title: 'SSN',
        value: 'ssn',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'notEqual', label: 'Not Equal' },
          { value: 'contains', label: 'Contains' },
          { value: 'notContains', label: 'Not Contains' },
        ],
      },
      {
        title: 'Phone Number',
        value: 'phone',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'notEqual', label: 'Not Equal' },
          { value: 'contains', label: 'Contains' },
          { value: 'notContains', label: 'Not Contains' },
        ],
      },
      {
        title: 'Home Phone',
        value: 'homePhone',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'notEqual', label: 'Not Equal' },
          { value: 'contains', label: 'Contains' },
          { value: 'notContains', label: 'Not Contains' },
        ],
      },
      {
        title: 'Cell Phone',
        value: 'cellPhone',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'notEqual', label: 'Not Equal' },
          { value: 'contains', label: 'Contains' },
          { value: 'notContains', label: 'Not Contains' },
        ],
      },
      {
        title: 'Work Phone',
        value: 'workPhone',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'notEqual', label: 'Not Equal' },
          { value: 'contains', label: 'Contains' },
          { value: 'notContains', label: 'Not Contains' },
        ],
      },
      {
        title: 'Diagnosis',
        value: 'diagnosis',
        type: 'string',
        filter: [
          { value: 'contains', label: 'Contains' },
          { value: 'notContains', label: 'Not Contains' },
        ],
      },
      {
        title: 'Medical Note',
        value: 'medicalNote',
        type: 'string',
        filter: [
          { value: 'contains', label: 'Contains' },
          { value: 'notContains', label: 'Not Contains' },
        ],
      },
      {
        title: 'Smoking Status',
        value: 'smokingStatus',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'notEqual', label: 'Not Equal' },
          { value: 'contains', label: 'Contains' },
          { value: 'notContains', label: 'Not Contains' },
        ],
      },
      {
        title: 'Program',
        value: 'program',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'notEqual', label: 'Not Equal' },
          { value: 'contains', label: 'Contains' },
          { value: 'notContains', label: 'Not Contains' },
        ],
      },

      {
        title: 'Age',
        value: 'age',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'lessThan', label: 'Less Than' },
          { value: 'greaterThan', label: 'Greater Than' },
          { value: 'between', label: 'Between' },
        ],
      },

      {
        title: 'Primary Insurance',
        value: 'primaryInsurance',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'notEqual', label: 'Not Equal' },
          { value: 'contains', label: 'Contains' },
          { value: 'notContains', label: 'Not Contains' },
        ],
      },
      {
        title: 'Secondary Insurance',
        value: 'secondaryInsurance',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'notEqual', label: 'Not Equal' },
          { value: 'contains', label: 'Contains' },
          { value: 'notContains', label: 'Not Contains' },
        ],
      },
      {
        title: 'Procedures',
        value: 'procedures',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'notEqual', label: 'Not Equal' },
          { value: 'contains', label: 'Contains' },
          { value: 'notContains', label: 'Not Contains' },
        ],
      },
      {
        title: 'Time Tracker',
        value: 'timeTracker',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'notEqual', label: 'Not Equal' },
          { value: 'contains', label: 'Contains' },
          { value: 'notContains', label: 'Not Contains' },
        ],
      },
      {
        title: 'Last Reviewed By',
        value: 'lastReviewBy',
        type: 'string',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'notEqual', label: 'Not Equal' },
          { value: 'contains', label: 'Contains' },
          { value: 'notContains', label: 'Not Contains' },
        ],
      },
      {
        title: 'Last Reviewed Date',
        value: 'lastReviewDate',
        type: 'date',
        filter: [
          { value: 'equal', label: 'Equal' },
          { value: 'notEqual', label: 'Not Equal' },
          { value: 'contains', label: 'Contains' },
          { value: 'notContains', label: 'Not Contains' },
        ],
      },
    ]
    return (
      <Box mb={2}>
        <Grid
          container
          spacing={1}
          item
          xs={12}
          sm={12}
          xl={12}
          style={{
            width: '100%',
            // marginBottom: '10px',
            // border: '1px solid rgba(5, 152, 236, 0.637)',
            // borderRadius: '20px',
            // marginTop: '10px',
            // paddingBottom: '20px',
            marginLeft: '1px',
            // padding: '15px',
            // paddingLeft: '15px',
            backgroundColor: 'white',
          }}
        >
          <Grid item xs={12} sm={12} xl={12}>
            {/* <Typography style={{ fontSize: '20px', fontWeight: 'bold' }}>Filters</Typography> */}
          </Grid>
          {filter.map((row, index) => {
            // console.log('mapping filters',row)
            return (
              <Grid
                key={index}
                container
                spacing={2}
                item
                xs={12}
                sm={12}
                xl={12}
              >
                <Grid item xs={1} sm={1} xl={1}>
                  <Typography
                    style={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      marginTop: '8px',
                    }}
                  >
                    Where
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={3} xl={3}>
                  <SuiSelect
                    value={{ title: row.title }}
                    onChange={(event) => {
                      row.columns = event.value
                      row.filter = event.filter
                      row.title = event.title
                      row.type = event.type
                      filter[index] = row
                      setfilter([...filter])
                    }}
                    options={whereArray}
                    renderOption={(props, option) => (
                      <li {...props}>{option.title}</li>
                    )}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue
                      }
                      // Regular option
                      return option.title
                    }}
                  />
                </Grid>
                {row.dualField ? (
                  <Grid item xs={3} sm={3} xl={3}>
                    <SuiSelect
                      value={{ label: row.label }}
                      onChange={(e) => {
                        row.dualField = e.value == 'between' ? true : false
                        row.operator = e.value
                        row.label = e.label
                        filter[index] = row
                        setfilter([...filter])
                      }}
                      options={row.filter}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={3} sm={3} xl={3}>
                    <SuiSelect
                      value={{ label: row.label }}
                      onChange={(e, v) => {
                        row.dualField = e.value == 'between' ? true : false
                        row.operator = e.value
                        row.label = e.label
                        filter[index] = row
                        setfilter([...filter])
                      }}
                      options={row.filter}
                    />
                  </Grid>
                )}

                {row.dualField ? (
                  <Grid item xs={2} sm={2} xl={2}>
                    <SuiInput
                      size="medium"
                      value={row.value}
                      placeholder="Search Value"
                      autoFocus
                      //  ref={callbackRef}
                      onKeyDown={keyDown}
                      type={row.type == 'date' ? 'date' : 'input'}
                      onChange={(e) => {
                        setFocus(false)
                        row.value = e.target.value
                        filter[index] = row
                        setfilter([...filter])
                      }}
                      fullWidth
                    />
                  </Grid>
                ) : (
                  <Grid item xs={4} sm={4} xl={4}>
                    <SuiInput
                      size="medium"
                      value={row.value}
                      placeholder="Search Value"
                      autoFocus={true}
                      onKeyDown={keyDown}
                      type={row.type == 'date' ? 'date' : 'input'}
                      onChange={(e) => {
                        row.value = e.target.value
                        filter[index] = row
                        setfilter([...filter])
                      }}
                      fullWidth
                    />
                  </Grid>
                )}

                {row.dualField ? (
                  <Grid
                    item
                    xs={row.type == 'date' ? 2 : 2}
                    sm={row.type == 'date' ? 2 : 2}
                    xl={row.type == 'date' ? 2 : 2}
                  >
                    <SuiInput
                      size="medium"
                      value={row.value2}
                      placeholder="Search Value"
                      autoFocus={focus == true ? true : false}
                      onKeyDown={keyDown}
                      type={row.type == 'date' ? 'date' : 'input'}
                      onChange={(e) => {
                        setFocus(true)
                        row.value2 = e.target.value
                        filter[index] = row
                        setfilter([...filter])
                      }}
                      fullWidth
                    />
                  </Grid>
                ) : null}
                <Grid item xs={1} sm={1} xl={1}>
                  <Button
                    onClick={() => {
                      var newfilter = filter
                      newfilter.splice(index, 1)
                      // console.log('Del index', index)
                      setfilter([...newfilter])
                    }}
                    variant="gradient"
                  >
                    <CancelOutlinedIcon
                      fontSize="medium"
                      style={{ color: 'red', paddingRight: '5px' }}
                    ></CancelOutlinedIcon>
                  </Button>
                </Grid>
              </Grid>
            )
          })}

          <Grid item xs={2} sm={2} xl={2}>
            <Button
              sx={{ mb: 2 }}
              onClick={handleClick}
              variant="gradient"
              size="small"
              style={{
                backgroundColor: '#41CBEB',
                color: 'black',
                fontSize: '14px',
                height: '35px',
              }}
            >
              Add Filter
              {/* {isShown ? (
                <ExpandLessIcon
                  fontSize="medium"
                  style={{
                    color: '#1a82ad',
                    paddingRight: '4px',
                    paddingBottom: '2px',
                  }}
                ></ExpandLessIcon>
              ) : (
                <ExpandMoreIcon
                  fontSize="medium"
                  style={{
                    color: '#1a82ad',
                    paddingRight: '4px',
                    paddingBottom: '2px',
                  }}
                ></ExpandMoreIcon>
              )} */}
            </Button>
          </Grid>
        </Grid>
      </Box>
    )
  }

  const columns = [
    {
      field: 'patientId',
      headerName: 'ID',
      width: 70,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'PatientName',
      headerClassName: 'super-app-theme--header',
      headerName: 'Patient',
      width: 150,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              width: '150px',
              whiteSpace: 'normal',
              wordWrap: 'break-word',
            }}
          >
            <SuiButton
              size="small"
              variant="text"
              color="info"
              onClick={(event) => {
                setProviderID(cellValues.row.providerId)
                openPatientRegistration(
                  cellValues.row.providerId,
                  cellValues.row.patientId
                )
              }}
            >
              {cellValues.row.PatientName}
            </SuiButton>
          </div>
        )
      },
    },

    {
      field: 'ProviderName',
      headerClassName: 'super-app-theme--header',
      headerName: 'Provider',
      width: 150,
    },
    {
      field: 'dob',
      headerClassName: 'super-app-theme--header',
      headerName: 'DOB',
      sortable: true,
      width: 100,
    },
    {
      field: 'status',
      headerClassName: 'super-app-theme--header',
      headerName: 'Status',
      sortable: true,
      width: 100,
    },
    {
      field: 'lastOfficeVisit',
      type: 'string',
      valueGetter: ({ value }) => value && new Date(value),
      headerClassName: 'super-app-theme--header',
      headerName: 'lastOfficeVisit',
      sortable: true,
      width: 100,
    },
    {
      field: 'phq9Score',
      headerClassName: 'super-app-theme--header',
      headerName: 'PHQ9',
      sortable: true,
      width: 100,
      renderCell: (cellValues) => {
        return (
          <SuiBadge
            badgeContent={`${cellValues.row.phq9Score}`}
            variant="gradient"
            container
            size="small"
          />
        )
      },
    },
    {
      field: 'phq9percentage',
      headerClassName: 'super-app-theme--header',
      headerName: 'PHQ9 %',
      sortable: true,
      width: 100,
      renderCell: (cellValues) => {
        return (
          <SuiBadge
            badgeContent={`${cellValues.row.phq9average}%`}
            variant="gradient"
            container
            size="small"
          />
        )
      },
    },
    {
      field: 'lastPhq9',
      headerClassName: 'super-app-theme--header',
      headerName: 'Last PHQ9',
      sortable: true,
      width: 100,
      renderCell: (cellValues) => {
        return (
          <SuiBadge
            badgeContent={`${
              cellValues.row.lastPhq9 < 0
                ? 'Never'
                : cellValues.row.lastPhq9 == 0
                ? 'Today'
                : cellValues.row.lastPhq9 + ' day(s) Ago'
            } `}
            variant="gradient"
            container
            size="small"
          />
        )
      },
    },
    {
      field: 'Gad7Score',
      headerClassName: 'super-app-theme--header',
      headerName: 'GAD7',
      sortable: true,
      width: 100,
      renderCell: (cellValues) => {
        return (
          <SuiBadge
            badgeContent={`${cellValues.row.Gad7Score}`}
            variant="gradient"
            container
            size="small"
          />
        )
      },
    },

    {
      field: 'gad7percentage',
      headerClassName: 'super-app-theme--header',
      headerName: 'GAD7 %',
      sortable: true,
      width: 100,
      renderCell: (cellValues) => {
        return (
          <SuiBadge
            badgeContent={`${cellValues.row.Gad7average}%`}
            variant="gradient"
            container
            size="small"
          />
        )
      },
    },
    {
      field: 'lastGad7',
      headerClassName: 'super-app-theme--header',
      headerName: 'Last GAD7',
      sortable: true,
      width: 100,
      renderCell: (cellValues) => {
        return (
          <SuiBadge
            badgeContent={`${
              cellValues.row.lastGad7 < 0
                ? 'Never'
                : cellValues.row.lastGad7 == 0
                ? 'Today'
                : cellValues.row.lastGad7 + ' day(s) Ago'
            } `}
            variant="gradient"
            container
            size="small"
          />
        )
      },
    },
    {
      field: 'lastReviewdays',
      headerClassName: 'super-app-theme--header',
      headerName: 'Last Reviewed ',
      sortable: true,
      width: 130,
      renderCell: (cellValues) => {
        return (
          <SuiBadge
            badgeContent={`${
              cellValues.row.lastReviewdays < 0
                ? 'Never'
                : cellValues.row.lastReviewdays == 0
                ? 'Today'
                : cellValues.row.lastReviewdays + ' day(s) Ago'
            } `}
            variant="gradient"
            container
            size="small"
          />
        )
      },
    },
    {
      field: 'timeTracker',
      headerClassName: 'super-app-theme--header',
      headerName: 'Time Tracker',
      sortable: true,
      width: 110,
      renderCell: (cellValues) => {
        return (
          <SuiBadge
            badgeContent={`${
              cellValues.row.timeTracker <= 0
                ? 'Never'
                : cellValues.row.timeTracker + ' mints'
            } `}
            variant="gradient"
            container
            size="small"
          />
        )
      },
    },
    {
      field: 'lastReviewBy',
      headerClassName: 'super-app-theme--header',
      headerName: 'Last Review By',
      sortable: true,
      width: 140,
    },
  ]

  const userProviderId = `${localStorage.getItem('dtt_user_provider_id')}`
  const [readOnly, setReadOnly] = useState(false)
  const [userType, setUserType] = useState(
    `${localStorage.getItem('dtt_userType')}`
  )
  const [providerID, setProviderID] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const openPatientRegistration = (providerId, id) => {
    //   if (userType!= 'Admin' && userProviderId!=providerID){
    //   setReadOnly(true)
    // }
    history.push({
      pathname: `/patientsregistration`,
      state: {
        patientId: id,
        readOnly:
          userType != 'Admin' && userProviderId != providerId ? false : false,
      },
    })
    window.location.reload(false)
  }

  const { size } = typography

  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px',
    },
  }

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'white',
    fontSize: '13.6px !important',
    color: 'dark',
    borderColor: 'rgba(5, 152, 236, 0.637) !important',
    '& .MuiDataGrid-row:hover': {
      color: '#000000 !important',
    },
    '& .MuiDataGrid-cell:hover': {
      color: '#8392ab !important',
      fontSize: '14.6px !important',
    },

    '& .severe .MuiBadge-badge': {
      background: '#DC143C !important',
      width: '90px',
      textTransform: 'none !important',
      color: 'black !important',
      //color: 'white !important',
    },
    '& .moderatesevere .MuiBadge-badge': {
      background: '#FFA500 !important',
      width: '90px',
      textTransform: 'none !important',
      color: 'black !important',
      //color: 'white !important',
    },
    '& .moderatered .MuiBadge-badge': {
      background: 'red !important',
      width: '90px',
      textTransform: 'none !important',
      color: 'black !important',
      //color: 'white !important',
    },
    '& .moderate .MuiBadge-badge': {
      background: '#FFA07A',
      width: '90px',
      textTransform: 'none !important',
      color: 'black !important',
      // color: 'white !important',
    },
    '& .mild .MuiBadge-badge': {
      background: '#fbcf33',
      width: '90px',
      color: 'black !important',
      // color: '#fff !important',
    },
    '& .low .MuiBadge-badge': {
      background: '#389837',
      width: '90px',
      textTransform: 'none !important',
      color: 'black !important',
      // color: 'white !important',
    },
    '& .uppercentage .MuiBadge-badge': {
      background: 'red',
      width: '90px',
      textTransform: 'none !important',
      color: 'black !important',
      // color: 'white !important',
    },
    '& .downpercentage .MuiBadge-badge': {
      background: 'yellow',
      width: '90px',
      color: 'black !important',
    },
    '& .samepercentage .MuiBadge-badge': {
      background: 'green',
      width: '90px',
      color: 'black !important',

      // color: 'white !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#FFFFC2',
      // borderRadius: 1,
    },
  }
  const keyDown = (e) => {
    if (e.key === 'Enter') {
      getdata(e)
    }
  }
  const [rows, setRows] = useState(null)
  const [rows1, setRows1] = useState(null)
  const [users, setUsers] = useState(0)
  const [practices, setPractices] = useState(0)
  const [gad7, setGad7] = useState(0)
  const [phq9, setPhq9] = useState(0)
  const [patients, setPatients] = useState(0)
  const [patientId, setPatientId] = useState(0)
  const [patientGAD7, setPatientGAD7] = useState('')
  const [patientPHQ9, setPatientPHQ9] = useState('')

  const [isCollapse, setIsCollapse] = useState(true)

  const [flastName, setFlastName] = useState('')
  const [ffirstName, setFfirstName] = useState('')
  const [fdob, setFdob] = useState('')
  const [lastOfficeVisit, setlastOfficeVisit] = useState('')
  const [femail, setFemail] = useState('')
  const [fssn, setFssn] = useState('')
  const [fphone, setFphone] = useState('')
  const [fphq9, setFphq9] = useState(0)
  const [fgad7, setFgad7] = useState(0)
  const [date, setDate] = useState(new Date().toISOString().substr(0, 10))
  const [list, setList] = useState(false)
  const [screening, setScreening] = useState('')
  const [refreshAssessment, setrefreshAssessment] = useState(false)
  const [error, setError] = useState('')

  const getCurrentDate = () => {
    const today = new Date()
    return today.toISOString().split('T')[0]
  }

  const handleDateChange = (e) => {
    const selectedDate = e.target.value
    const currentDate = getCurrentDate()
    if (selectedDate > currentDate) {
      setError('You cannot select a future date.')
      setDate('')
    } else {
      setError('')
      setDate(selectedDate)
    }
  }
  //For Click Outside close
  useEffect(() => {
    document.addEventListener('mousedown', () => {
      setList(false)
    })
  })

  const [refreshStats, setrefreshStats] = useState(false)

  const StyledTooltip = withStyles({
    tooltip: {
      backgroundColor: '#FCBE13',
      // backgroundColor: '#41CBEB',
      color: 'black',
      // backgroundColor: '#333',
      // color: '#fff',
      fontSize: '12px',
      fontWeight: 500,
      borderRadius: '10px',
      padding: '8px',
    },
  })(Tooltip)

  const allProvidersRedx = useSelector(
    (state) => (state.allProviders.loading ? [] : state.allProviders.NAMES),
    shallowEqual
  )
  const [allProviders, setAllProviders] = useState([])
  useEffect(() => {
    // POST request using axios inside useEffect React hook

    var allProvidersRedux = []
    // console.log('reduxxx', providersRedx)
    allProvidersRedx.forEach((field) => {
      allProvidersRedux.push({
        label: field.lastName + ', ' + field.firstName,
        value: field.id,
      })
    })

    setAllProviders(allProvidersRedux)
    //     if (dtt_userType != 'Admin')
    //     {
    //       setValue('providerId', providersRedux[0].value)
    //     }
    // console.log('provideredux',providersRedux[0].value)

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [])
  const [careTeam_id, setCareTeam_id] = useState('')
  const handlecareteam_id = (event) => {
    setCareTeam_id(event.value)
  }
  const handlePHQ9Change = (event) => {
    setFphq9(event.target.value)
  }
  const handleGAD7Change = (event) => {
    setFgad7(event.target.value)
  }
  const handleLastVisitedChange = (event) => {
    setFlastVisited(event.target.value)
  }
  const onFilterClear = (event) => {
    setFlastName('')
    setFfirstName('')
    setFdob('')
    setlastOfficeVisit('')
    setFemail('')
    setFssn('')
    setStatus('')
    setFphone('')
    setFphq9('')
    setFgad7('')
    setrefreshAssessment(!refreshAssessment)
    setrefreshStats(!refreshStats)
    setCareTeam_id('')
  }
  useEffect(() => {
    newgetdata()
  }, [refreshStats])
  const newgetdata = (event) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/Dashboard/getDashboardStats?date=${date}`,
        {
          headers,
        }
      )
      .then((response) => {
        //  setUsers(response.data.totalUsers)
        setPatients(response.data.patients)
        setPhq9(response.data.phq9)
        setGad7(response.data.gad7)
        // setPractices(response.data.totalPractices)
        setScreening(response.data)
        setSubmitting(false)
      })

      .catch((error) => {
        setSubmitting(false)
        console.error('There was an error!', error)
      })
  }

  useEffect(() => {
    // setrefreshAssessment(!refreshAssessment)
    getdata()
  }, [refreshAssessment])
  const getdata = (event) => {
    // POST request using axios inside useEffect React hook
    setRows(null)
    var filterArr = []
    filter.forEach((f) => {
      if (f.columns && f.operator && (f.value || f.value2)) {
        var obj = {
          field: f.columns,
          operatorName: f.operator,
          operatorType: f.type,
          value: f.value,
          value2: f.value2,
        }
        filterArr.push(obj)
      }
    })
    const postData = {
      lastName: flastName,
      firstName: ffirstName,
      ssn: fssn,
      dob: fdob,
      lastOfficeVisit: lastOfficeVisit,
      phone: fphone,
      emailAddress: femail,
      phq9: '',
      gad7: '',
      status: status.value,
      careTeam_id: careTeam_id,
      filters: filterArr,
    }

    setNoRowsLabel(GETTING_ROWS_LABEL)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Dashboard/getPatientAssessments`,
        postData,
        { headers }
      )
      .then((response) => {
        setNoRowsLabel(NO_ROWS_LABEL)
        setRows(response.data)
        setssn(response.data.ssn)
        setphone(response.data.phone)
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }
  const onFilterSearch = (event) => {
    setrefreshAssessment(!refreshAssessment)
  }

  // useEffect(() => {
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_URL}/Dashboard/getpatientLastReview`,
  //       {},
  //       {
  //         headers,
  //       }
  //     )
  //     .then((response) => {
  //       const data = []
  //       let idCounter = 1
  //       response.data.forEach((field) => {
  //         data.push({
  //           // id: field.patientId,
  //           lastReviewTime: field.lastReviewTime,
  //           lastReviewBy: field.lastReviewBy,
  //           lastReviewdays: field.lastReviewdays,
  //         })
  //         idCounter++
  //       })
  //       setRows1(data)
  //     })
  //     // .then((response) => setRows1(response.data))
  //     .catch((error) => {
  //       console.error('There was an error!', error)
  //     })

  //   // empty dependency array means this effect will only run once (like componentDidMount in classes)
  // }, [])

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_API_URL}/PatientNote/getpatientLastReview`,
  //       {
  //         headers,
  //       }
  //     )
  //     .then((response) => {
  //       const data = []
  //       let idCounter = 1
  //       response.data.forEach((field) => {
  //         data.push({
  //           id: field.patientId,
  //           providerName: field.firstname + ' ' + field.lastname,
  //           patientName: field.PatientName,
  //           gaD7Score: field.gad7totalscore,
  //           gaD7PercentageChange: field.gad7percentage,
  //           phQ9Score: field.phq9Score,
  //           phQ9PercentageChange: field.phq9percentage,
  //           lastReviewTime: field.lastReviewTime,
  //           lastReviewBy: field.lastReviewBy,
  //           lastReviewdays: field.lastReviewdays,
  //         })
  //         idCounter++
  //       })
  //       setRows1(data)
  //     })
  //     .catch((error) => {
  //       console.error('There was an error!', error)
  //     })

  //   // empty dependency array means this effect will only run once (like componentDidMount in classes)
  // }, [])

  // useEffect(() => {
  //   setSubmitting(true)
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}/Dashboard/getDashboardStats?date=${date}`, {
  //       headers,
  //     })
  //     .then((response) => {
  //       //  setUsers(response.data.totalUsers)
  //       setPatients(response.data.patients)
  //       setPhq9(response.data.phq9)
  //       setGad7(response.data.gad7)
  //       // setPractices(response.data.totalPractices)
  //       setScreening(response.data)
  //       setSubmitting(false)
  //     })

  //     .catch((error) => {
  //       setSubmitting(false)
  //       console.error('There was an error!', error)
  //     })

  //   // empty dependency array means this effect will only run once (like componentDidMount in classes)
  // }, [date])

  // const onSubmit = {
  //   console.log("Hassnain")
  //   connectToRoom: () => {
  //     const token = '$TOKEN';
  //     const roomName = 'my-new-room';

  //     connect(token, { name: roomName })
  //       .then(room => {
  //         console.log(`Successfully joined a Room: ${room}`);
  //         room.on('participantConnected', participant => {
  //           console.log(`A remote Participant connected: ${participant}`);
  //         });
  //       })
  //       .catch(error => {
  //         console.error(`Unable to connect to Room: ${error.message}`);
  //       });
  //   },
  // };

  const [ssn, setssn] = React.useState()
  const [phone, setphone] = React.useState()
  const [isShown, setIsShown] = useState(false)
  // const { connect } = require('twilio-video');
  // const handleClick = event => {
  //   // 👇️ toggle shown state
  //   setIsShown(current => !current);

  //   // 👇️ or simply set it to true
  //   // setIsShown(true);
  // };
  return (
    <>
      {submitting ? (
        <CircularProgress
          style={{
            width: '50px',
            height: '50px',
            position: 'absolute',
            left: '50%',
            top: '60%',
            marginLeft: '-25px',
            marginTop: '-25px',
            zIndex: 1000,
            color: '#FCBE13',
          }}
        />
      ) : null}

      <SuiBox py={3}>
        <SuiBox mb={3}>
          {/* <Grid container xs={12}>
            <Typography>Date:</Typography>

            <Grid
              item
              xs={2}
              style={{ marginBottom: "10px", marginLeft: "25px" }}
            >
              <SuiInput
                onChange={(e) => setDate(e.target.value)}
                value={date}
                type="date"
                placeholder="Please enter dob"
                name="dob"
                onKeyDown={keyDown}
                label="dob"
                fullWidth
                margin="dense"
              />
            </Grid>
            <Button
              sx={{ mb: 2, marginRight: "20px" }}
              onClick={(event) => {
                setrefreshStats(!refreshStats);
                setrefreshAssessment(!refreshAssessment);
              }}
              style={{
                // float: 'left',
                marginBottom: "10px",
                float: "right",
                marginLeft: "20px",
                width: "80px",
                height: "35px",
                backgroundColor: "#FCBE13",
                color: "black",
                fontSize: "12px",
              }}
            >
              <CachedOutlinedIcon
                fontSize="medium"
                style={{
                  color: "#1a82ad",
                  marginRight: "1px",
                  paddingRight: "0px",
                  paddingBottom: "2px",
                }}
              ></CachedOutlinedIcon>
              Refresh
            </Button> */}

          {/* <Video /> */}

          {/* </Grid> */}
          {/* <Grid container xs={12}>
      <Typography>Date:</Typography>
      <Grid item xs={2} style={{ marginBottom: '10px', marginLeft: '25px' }}>
        <SuiInput
          onChange={handleDateChange}
          value={date}
          type="date"
          placeholder="Please enter dob"
          name="dob"
          onKeyDown={keyDown}
          label="dob"
          fullWidth
          margin="dense"
          max={getCurrentDate()}
        />
        {error && <Typography color="error">{error}</Typography>}
      </Grid>
      <Button
        sx={{ mb: 2, marginRight: '20px' }}
        onClick={(event) => {
          if (!error) {
            setrefreshStats(!refreshStats);
            setrefreshAssessment(!refreshAssessment);
          }
        }}
        style={{
          marginBottom: '10px',
          float: 'right',
          marginLeft: '20px',
          width: '80px',
          height: '35px',
          backgroundColor: '#FCBE13',
          color: 'black',
          fontSize: '12px',
        }}
      >
        <CachedOutlinedIcon
          fontSize="medium"
          style={{
            color: '#1a82ad',
            marginRight: '1px',
            paddingRight: '0px',
            paddingBottom: '2px',
          }}
        />
        Refresh
      </Button>
    </Grid> */}
          <Grid container xs={12}>
            <Typography>Date:</Typography>
            <Grid
              item
              xs={2}
              style={{ marginBottom: '10px', marginLeft: '25px' }}
            >
              <SuiInput
                onChange={handleDateChange}
                value={date}
                type="date"
                placeholder="Please enter dob"
                name="dob"
                onKeyDown={keyDown}
                label="dob"
                fullWidth
                margin="dense"
                max={getCurrentDate()}
              />
              {error && (
                <Typography
                  variant="caption"
                  color="error"
                  sx={{ fontSize: '0.75rem', marginTop: '5px' }}
                >
                  {error}
                </Typography>
              )}
            </Grid>
            <Button
              sx={{ mb: 2, marginRight: '20px' }}
              onClick={(event) => {
                if (!error) {
                  setrefreshStats(!refreshStats)
                  setrefreshAssessment(!refreshAssessment)
                }
              }}
              style={{
                marginBottom: '10px',
                float: 'right',
                marginLeft: '20px',
                width: '80px',
                height: '35px',
                backgroundColor: '#FCBE13',
                color: 'black',
                fontSize: '12px',
              }}
            >
              <CachedOutlinedIcon
                fontSize="medium"
                style={{
                  color: '#1a82ad',
                  marginRight: '1px',
                  paddingRight: '0px',
                  paddingBottom: '2px',
                }}
              />
              Refresh
            </Button>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: 'Patients' }}
                count={patients}
                icon={{
                  color: 'warning',
                  component: (
                    <StyledTooltip title="Number of Active Patient" arrow>
                      <Sick />
                    </StyledTooltip>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: 'PHQ9' }}
                count={phq9}
                icon={{
                  color: 'warning',
                  component: (
                    <StyledTooltip title="Number of Patient Who Did PHQ9" arrow>
                      <Favorite />
                    </StyledTooltip>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: 'GAD7' }}
                count={gad7}
                icon={{
                  color: 'warning',
                  component: (
                    <StyledTooltip title="Number of Patient Who Did GAD7" arrow>
                      <Favorite />
                    </StyledTooltip>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              xl={3}
              onClick={() => {
                setList(!list)
              }}
            >
              <MiniStatisticsCard
                title={{ text: 'Screening' }}
                //  count={patients}
                icon={{
                  color: 'warning',
                  component: (
                    <StyledTooltip
                      title="Number Of patient Who Did Screening"
                      arrow
                    >
                      {list ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </StyledTooltip>
                  ),
                }}
              />
              {list ? (
                <Grid
                  xs={3}
                  style={{
                    width: '280px',
                    position: 'absolute',
                    zIndex: '99',
                  }}
                >
                  <ul
                    style={{
                      backgroundColor: 'white',
                      padding: '10px',
                      paddingLeft: '40px',
                      borderRadius: '20px',
                      marginLeft: '10px',
                      marginRight: '10px',
                    }}
                  >
                    <>
                      <li>
                        <p>
                          <Grid container xs={12}>
                            <Grid item xs={5}>
                              CIDI:
                            </Grid>
                            <Grid item xs={7}>
                              {screening.cidi12}
                            </Grid>
                          </Grid>
                        </p>
                      </li>
                      <li>
                        <p>
                          <Grid container xs={12}>
                            <Grid item xs={5}>
                              AUDIT:
                            </Grid>
                            <Grid item xs={7}>
                              {screening.auditC3}
                            </Grid>
                          </Grid>
                        </p>
                      </li>
                      <li>
                        <p>
                          <Grid container xs={12}>
                            <Grid item xs={5}>
                              CRAFT:
                            </Grid>
                            <Grid item xs={7}>
                              {screening.craft9}
                            </Grid>
                          </Grid>
                        </p>
                      </li>
                      <li>
                        <p>
                          <Grid container xs={12}>
                            <Grid item xs={5}>
                              DAST:
                            </Grid>
                            <Grid item xs={7}>
                              {screening.dast10}
                            </Grid>
                          </Grid>
                        </p>
                      </li>
                      <li>
                        <p>
                          <Grid container xs={12}>
                            <Grid item xs={5}>
                              MDQ:
                            </Grid>
                            <Grid item xs={7}>
                              {screening.mdq17}
                            </Grid>
                          </Grid>
                        </p>
                      </li>
                      <li>
                        <p>
                          <Grid container xs={12}>
                            <Grid item xs={5}>
                              PCL:
                            </Grid>
                            <Grid item xs={7}>
                              {screening.pcl120}
                            </Grid>
                          </Grid>
                        </p>
                      </li>
                      <li>
                        <p>
                          <Grid container xs={12}>
                            <Grid item xs={5}>
                              YBOCS:
                            </Grid>
                            <Grid item xs={7}>
                              {screening.ybocs90}
                            </Grid>
                          </Grid>
                        </p>
                      </li>
                    </>
                  </ul>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </SuiBox>
        <Grid
          container
          spacing={1}
          item
          xs={12}
          sm={12}
          xl={12}
          style={{
            // width: '100%',
            marginBottom: '10px',
            border: '1px solid rgba(5, 152, 236, 0.637)',
            borderRadius: '20px',
            marginTop: '10px',
            paddingBottom: '20px',
            marginLeft: '5px',
            paddingRight: '10px',
            backgroundColor: 'white',
          }}
        >
          <Grid item xs={6} sm={2.3} xl={2.3}>
            <Typography style={{ fontSize: '14px' }}>Last Name</Typography>
            <SuiInput
              onChange={(e) => setFlastName(e.target.value)}
              value={flastName}
              placeholder="Please enter last name"
              name="lastName"
              onKeyDown={keyDown}
              label="LastName"
              fullWidth
              margin="dense"
            />
          </Grid>
          <Grid item xs={6} sm={2.3} xl={2.3}>
            <Typography style={{ fontSize: '14px' }}>First Name</Typography>
            <SuiInput
              onChange={(e) => setFfirstName(e.target.value)}
              value={ffirstName}
              placeholder="Please enter first name"
              name="firstName"
              onKeyDown={keyDown}
              label="FirstName"
              fullWidth
              margin="dense"
            />
          </Grid>
          <Grid item xs={6} sm={2.3} xl={2.3}>
            <Typography style={{ fontSize: '14px' }}>DOB</Typography>
            <SuiInput
              onChange={(e) => setFdob(e.target.value)}
              value={fdob}
              type="date"
              placeholder="Please enter dob"
              name="dob"
              onKeyDown={keyDown}
              label="dob"
              fullWidth
              margin="dense"
            />
          </Grid>
          <Grid item xs={6} sm={2.3} xl={2.3}>
            <Typography style={{ fontSize: '14px' }}>Care Team</Typography>
            <SuiSelect
              // isClearable
              // {...field}
              // error={errors.providerId}
              // default
              value={allProviders.filter(function (option) {
                return option.value === careTeam_id
              })}
              // onChange={(value) => {
              //   setValue('providerId', value.value)
              // }}

              onChange={handlecareteam_id}
              options={allProviders}
              onKeyDown={keyDown}
            />
            {/* <InputMask
            mask="999-99-9999"
            disabled={false}
            value={fssn}
            onKeyDown={keyDown}
            style={{ height: '30px' }}
            onChange={(e) => {
              // setValue('ssn', e.target.value)
              setFssn(e.target.value)
            }}
          >
            {() => (
              <SuiInput
                id="outlined-basic"
                fullWidth
                margin="dense"
                placeholder="Enter SSN"
              />
            )}
          </InputMask> */}
          </Grid>
          <Grid item xs={6} sm={2.3} xl={2.3}>
            <Typography style={{ fontSize: '14px' }}>Status</Typography>
            <FormControl>
              <SuiSelect
                value={status}
                onKeyDown={keyDown}
                style={{ height: '30px' }}
                onChange={(e) => {
                  // setValue('ssn', e)
                  setStatus(e)
                  console.log('setStatus', e.value)
                }}
                options={[
                  { value: '', label: 'All' },
                  { value: 'Active', label: 'Active' },
                  { value: 'InActive', label: 'InActive' },
                  { value: 'Discharge', label: 'Discharge' },
                ]}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <PatientFilters />
          </Grid>

          <Grid container>
            <Grid item xs={9} sm={9} xl={9}></Grid>
            <Grid item xs={3} sm={3} xl={3}>
              <Button
                onClick={onFilterSearch}
                variant="gradient"
                style={{
                  marginTop: '10px',
                  float: 'right',
                  marginLeft: '13px',
                  width: '80px',
                  height: '35px',
                  backgroundColor: '#FCBE13',
                  color: 'black',
                  fontSize: '14px',
                }}
              >
                <SearchOutlinedIcon
                  fontSize="medium"
                  style={{ color: '#1a82ad', paddingBottom: '1px' }}
                ></SearchOutlinedIcon>
                Search
              </Button>
              <Button
                onClick={onFilterClear}
                variant="gradient"
                style={{
                  marginTop: '10px',
                  marginLeft: 'auto',
                  float: 'right',
                  width: '80px',
                  height: '35px',
                  backgroundColor: '#FCBE13',
                  color: 'black',
                  fontSize: '14px',
                }}
              >
                <CleaningServicesOutlinedIcon
                  fontSize="medium"
                  style={{ color: '#0a9ff0', paddingRight: '5px' }}
                ></CleaningServicesOutlinedIcon>
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ marginTop: '30px' }}>
          <Typography style={{ display: 'inline-block' }}></Typography>
          <Grid container style={{ marginBottom: '20px' }}>
            <Grid item xs={11} lg={11}>
              <a style={{ marginTop: '20px' }}>
                <span
                  style={{
                    height: '20px',
                    width: '50px',
                    backgroundColor: '#FFA500',
                    float: 'right',
                    fontSize: '12px',
                    marginLeft: '10px',
                    borderRadius: '20px',
                    paddingLeft: '10px',
                    paddingBottom: '10px',
                  }}
                >
                  16-21
                </span>
                <span
                  style={{
                    height: '20px',
                    backgroundColor: '#FFA07A',
                    float: 'right',
                    fontSize: '12px',
                    marginLeft: '10px',
                    width: '50px',
                    borderRadius: '20px',
                    paddingLeft: '10px',
                    paddingBottom: '10px',
                  }}
                >
                  11-15
                </span>
                <span
                  style={{
                    height: '20px',
                    width: '50px',
                    backgroundColor: '#fbcf33',
                    float: 'right',
                    fontSize: '12px',
                    marginLeft: '10px',
                    borderRadius: '20px',
                    paddingLeft: '13px',
                    paddingBottom: '10px',
                  }}
                >
                  6-10
                </span>
                <span
                  style={{
                    height: '20px',
                    backgroundColor: '#389837',
                    float: 'right',
                    fontSize: '12px',
                    marginLeft: '10px',
                    width: '50px',
                    borderRadius: '20px',
                    paddingLeft: '15px',
                    paddingBottom: '10px',
                  }}
                >
                  0-5
                </span>
                <span
                  style={{
                    float: 'right',
                    fontSize: '12px',
                    marginLeft: '62px',
                    fontWeight: 'bold',
                  }}
                >
                  GAD7 LEVELS:
                </span>
                <span
                  style={{
                    height: '20px',
                    width: '50px',
                    backgroundColor: '#DC143C',
                    float: 'right',
                    fontSize: '12px',
                    marginLeft: '10px',
                    borderRadius: '20px',
                    paddingLeft: '10px',
                    paddingBottom: '10px',
                  }}
                >
                  20-27
                </span>{' '}
                <span
                  style={{
                    height: '20px',
                    width: '50px',
                    backgroundColor: '#FFA500',
                    float: 'right',
                    fontSize: '12px',
                    marginLeft: '10px',
                    borderRadius: '20px',
                    paddingLeft: '10px',
                    paddingBottom: '10px',
                  }}
                >
                  15-19
                </span>
                <span
                  style={{
                    height: '20px',
                    width: '50px',
                    backgroundColor: '#FFA07A',
                    float: 'right',
                    fontSize: '12px',
                    marginLeft: '10px',
                    borderRadius: '20px',
                    paddingLeft: '10px',
                    paddingBottom: '10px',
                  }}
                >
                  10-14
                </span>
                <span
                  style={{
                    height: '20px',
                    width: '50px',
                    backgroundColor: '#fbcf33',
                    float: 'right',
                    fontSize: '12px',
                    marginLeft: '10px',
                    borderRadius: '20px',
                    paddingLeft: '15px',
                    paddingBottom: '10px',
                  }}
                >
                  5-9
                </span>
                <span
                  style={{
                    height: '20px',
                    width: '50px',
                    backgroundColor: '#389837',
                    float: 'right',
                    fontSize: '12px',
                    marginLeft: '10px',
                    borderRadius: '20px',
                    paddingLeft: '15px',
                    paddingBottom: '10px',
                  }}
                >
                  0-4
                </span>
                <span
                  style={{
                    float: 'right',
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}
                >
                  PHQ9 LEVELS:
                </span>
              </a>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={12}>
            <div style={{ height: 400, width: '100%' }}>
              {rows === null || rows.length === 0 ? (
                <Typography>{NoRowsLabel}</Typography>
              ) : (
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={100}
                  headerHeight={37}
                  getRowId={(row) => (row != null ? row.patientId : 0)}
                  rowsPerPageOptions={[100]}
                  sx={gridRowStyle}
                  getCellClassName={(params) => {
                    //console.log('score',params)
                    if (params.field == 'lastPhq9') {
                      if (params.row.lastPhq9 < 0) {
                        return 'moderatered'
                      } else if (params.row.lastPhq9 >= 14) {
                        return 'moderatered'
                      } else if (params.row.lastPhq9 >= 0) {
                        return 'low'
                      }
                    }
                    if (params.field == 'lastGad7') {
                      if (params.row.lastGad7 < 0) {
                        return 'moderatered'
                      } else if (params.row.lastGad7 >= 14) {
                        return 'moderatered'
                      } else if (params.row.lastGad7 >= 0) {
                        return 'low'
                      }
                    }
                    if (params.field == 'lastReviewdays') {
                      if (params.row.lastReviewdays > 14) {
                        return 'moderatered'
                      } else if (params.row.lastReviewdays >= 0) {
                        return 'low'
                      } else {
                        return 'moderatered'
                      }
                    }
                    if (params.field == 'timeTracker') {
                      if (params.row.timeTracker <= 0) {
                        return ' moderatered'
                      } else if (params.row.timeTracker > 31) {
                        return 'low'
                      } else {
                        return 'moderatesevere'
                      }
                    }
                    if (params.field == 'Gad7Score') {
                      if (params.row.Gad7Score > 15) {
                        return 'moderatesevere'
                      } else if (params.row.Gad7Score > 10) {
                        return 'moderate'
                      } else if (params.row.Gad7Score > 5) {
                        return 'mild'
                      } else {
                        return 'low'
                      }
                    }
                    if (params.field == 'phq9Score') {
                      if (params.row.phq9Score > 19) {
                        return 'severe'
                      } else if (params.row.phq9Score > 14) {
                        return 'moderatesevere'
                      } else if (params.row.phq9Score > 9) {
                        return 'moderate'
                      } else if (params.row.phq9Score > 4) {
                        return 'mild'
                      } else {
                        return 'low'
                      }
                    }
                    if (params.field == 'gad7percentage') {
                      if (params.row.Gad7average > 0) {
                        return 'uppercentage'
                      } else if (params.row.Gad7average < 0) {
                        return 'downpercentage'
                      } else {
                        return 'low'
                      }
                    }
                    if (params.field == 'phq9percentage') {
                      if (params.row.phq9average > 0) {
                        return 'uppercentage'
                      } else if (params.row.phq9average < 0) {
                        return 'downpercentage'
                      } else {
                        return 'low'
                      }
                    }
                  }}
                  onSelectionModelChange={(id) => {}}
                />
              )}
            </div>
          </Grid>
          <a style={{ paddingTop: '100px' }}>
            <span
              style={{
                height: '20px',
                width: '60px',
                backgroundColor: 'red',
                float: 'right',
                fontSize: '12px',
                marginLeft: '10px',
                borderRadius: '20px',
                paddingLeft: '10px',
                marginTop: '10px',
              }}
            >
              Positive
            </span>{' '}
            <span
              style={{
                height: '20px',
                width: '60px',
                backgroundColor: 'yellow',
                float: 'right',
                fontSize: '12px',
                marginLeft: '10px',
                borderRadius: '20px',
                paddingLeft: '10px',
                marginTop: '10px',
              }}
            >
              Negative
            </span>
            <span
              style={{
                height: '20px',
                width: '80px',
                backgroundColor: 'green',
                float: 'right',
                fontSize: '12px',
                marginLeft: '10px',
                borderRadius: '20px',
                paddingLeft: '10px',
                marginTop: '10px',
                // text: 'white',
                color: 'white',
              }}
            >
              No Change
            </span>
            <span
              style={{
                float: 'right',
                marginTop: '10px',
                fontSize: '12px',
                fontWeight: 'bold',
              }}
            >
              Percent Change:
            </span>
          </a>
        </div>
      </SuiBox>
    </>
  )
}

export default Dashboard
