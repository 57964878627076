import React, { useState, useEffect } from 'react';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
// import EditIcon from 'assets/edit.png'
import { makeStyles } from '@mui/styles';
import { Button, CircularProgress, Grid, Modal } from "@mui/material";
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import SuiButton from 'components/SuiButton';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import MyForm from './MyForm';
import MyFormRights from './MyFormRights';
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useAlert } from 'react-alert'

import axios from 'axios';
const useStyles = makeStyles({
  treeItem: {
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  label: {
    fontSize: '1rem',
    fontWeight: 'normal',
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  addButton: {
    marginLeft: 'auto',
  },
  editButton: {
    marginLeft: '4px',
  },
});
const Rights = () => {
  const classes = useStyles();
  const alert = useAlert()

  const [treeData, setTreeData] = useState([]);
  const [ids, setId] = useState([]);
  const [expandedNodes, setExpandedNodes] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);



  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [parentId, setParentId] = useState('');
  const [parentId1, setParentId1] = useState('');

  const [isActive, setisActive] = useState('');
  const [name, setName] = useState('');
  const [nodesvalues, setvalues] = useState('');
  const [practiceId, setpracticeId] = useState('');

  const [updatedBy, setupdatedBy] = useState('');
  const [createdBy, setcreatedBy] = useState('');
  const [submitting, setSubmitting] = useState(false)
  const [refresh, setRefresh] = useState(false);

  const [nodeId, setNodeId] = useState('');
  const [applyRefresh, setapplyRefresh] = useState(false);


  const applyRefreshh = () => {
    setapplyRefresh(!applyRefresh)
  }



  const handleClose = () => setOpen(false);

  const handleCloseEdit = () => setOpenEdit(false);
  const [treeData1, setTreeData1] = useState([]);
  const [firstSelect, setfirstSelect] = useState('');
  const [selectedOption, setSelectedOption] = useState(firstSelect);
  const [selectedNodeId, setSelectedNodeId] = useState(null); // Initializing with null or appropriate default value
  const [selectedRoleId, setSelectedRoleId] = useState(null); // Initializing with null or appropriate default value
  const [isSubmitting, setIsSubmitting] = useState(false);

console.log(selectedNodeId,'selectedNodeId')
  useEffect(() => {
    setSelectedOption(firstSelect);
  }, [firstSelect]);

 


  useEffect(() => {
    fetchTreeData1();
  }, []);

  const fetchTreeData1 = async () => {
    setSubmitting(true);
    try {
      const token = localStorage.getItem('dtt_token');
      const practiceId = localStorage.getItem('dtt_user_default_practice');

      const response = await axios.get(`https://dttapis.devforhealth.com/api/Modules/Roles?practiceId=${practiceId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status !== 200) {
        throw new Error('Failed to fetch tree data');
      }

      const data = response.data; // Define data here
      if (data.length > 0 && data[0].id !== undefined) {
        setfirstSelect(data[0].id); // Set the first item's ID to the state
      } else {
        console.log('Data is empty or first item does not have an id property');
      }
      setTreeData1(data); // Set the array of ids to the state

      console.log(ids, 'jahhj'); // Log the ids after mapping

      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      console.error('Error fetching tree data:', error);
    }
  };

  const toggleNode = (id) => {
    setExpandedNodes((prevExpandedNodes) =>
      prevExpandedNodes.includes(id)
        ? prevExpandedNodes.filter((nodeId) => nodeId !== id)
        : [...prevExpandedNodes, id]
    );
  };
  const handleNodeSelect = async (event, nodeId, rightsId, roleId, moduleId, isChecked) => {
    // Disable the checkbox during API call
    setIsSubmitting(true);
  
    try {
      // Update the selectedNodes state based on the checkbox state
      if (event.target.checked) {
        setSelectedNodes((prevSelectedNodes) => [...prevSelectedNodes, nodeId]);
      } else {
        setSelectedNodes((prevSelectedNodes) =>
          prevSelectedNodes.filter((selectedNodeId) => selectedNodeId !== nodeId)
        );
      }
  
      // Call onSubmitUpdate to submit changes
      await onSubmitUpdate(moduleId, isChecked, rightsId, roleId);
    } finally {
      // Re-enable the checkbox after API call completes (whether it succeeds or fails)
      setIsSubmitting(false);
    }
  };
  
  


  useEffect(() => {
    console.log(selectedOption, 'selectedOption');
    fetchTreeData(selectedOption); // Pass selectedOption to fetchTreeData directly
  }, [selectedOption, applyRefresh, refresh]);


  const fetchTreeData = async (roleId) => {
    if(!roleId || !selectedOption){
      return
    }
    console.log(selectedOption, 'selectedOptionselectedOption')
    setSubmitting(true);
    try {
      const token = localStorage.getItem('dtt_token');

      const response = await fetch(`https://dttapis.devforhealth.com/api/Rights/getRights?roleId=${roleId ? roleId : selectedOption}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch tree data');
      }
      const data = await response.json();
      setTreeData(data);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      console.error('Error fetching tree data:', error);
    }
  };




  // Function to find a node by its ID in the tree data
  const findNodeById = (nodes, id) => {
    for (const node of nodes) {
      if (node.id === id) {
        return node;
      }
      if (node.children && node.children.length > 0) {
        const foundNode = findNodeById(node.children, id);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return null;
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px'
  };
  // Add this function outside of your component
const getAllNodeIds = (nodes) => {
  let ids = [];
  nodes.forEach(node => {
     ids.push(node.id);
     if (node.children && node.children.length > 0) {
       ids = ids.concat(getAllNodeIds(node.children));
     }
  });
  return ids;
 };
 
 // Inside your component, after fetching treeData
 useEffect(() => {
  fetchTreeData1();
 }, []);
 
 useEffect(() => {
  // Assuming treeData is already populated at this point
  const allNodeIds = getAllNodeIds(treeData);
  setExpandedNodes(allNodeIds);
 }, [treeData]); // Depend on treeData to re-run this effect when treeData changes
 
  const handleDrop = async (draggedItemId, newParentId) => {
    try {
      const token = localStorage.getItem('dtt_token')
      // Find the dragged item in the tree data
      const draggedItem = findNodeById(treeData, draggedItemId);
      if (!draggedItem) {
        throw new Error('Dragged item not found in tree data');
      }

      // Create the updated data for the API call
      const updatedData = {
        id: draggedItemId,
        parentId: newParentId,
        isActive: false,
        name: draggedItem.name, // Include the name property from the dragged item
      };

      // Perform the API call to update the parent ID
      const response = await fetch('https://dttapis.devforhealth.com/api/Modules/AddModules', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });

      if (!response.ok) {
        throw new Error('Failed to move module');
      } else {
        setRefresh(!refresh)
      }

      // Update the tree data after successful API call
      const updatedNode = await response.json();
      setTreeData(prevTreeData => {
        const updateTree = (nodes) => {
          return nodes.map(node => {
            if (node.id === newParentId) {
              // Append the new node to the children
              return {
                ...node,
                children: node.children ? [...node.children, updatedNode] : [updatedNode],
              };
            }
            if (node.children && node.children.length > 0) {
              // Recursively update the children nodes
              return {
                ...node,
                children: updateTree(node.children),
              };
            }
            return node;
          });
        };
        return updateTree(prevTreeData);
      });
    } catch (error) {
      console.error('Error moving module:', error);
    }
  };

  const onSubmitUpdate = async (moduleId, isChecked, rightsId, roleId) => {
    try {
      setIsSubmitting(true);

      // Log moduleId and isChecked to the console
      console.log('Module ID:', moduleId);
      console.log('Is Checked:', isChecked);
      console.log('Is rightsId:', rightsId);
      console.log('Is roleId:', roleId);
      console.log('Is moduleId:', moduleId);

      const token = localStorage.getItem('dtt_token');
  
      const postData = [{
        id: rightsId, // Ensure rightsId is parsed to an integer and wrapped in an array
        roleId: selectedOption,
        moduleId:moduleId,
        practiceId: localStorage.getItem('dtt_user_default_practice'),
        isChecked: isChecked, // Pass isChecked here
        isUpdated: true,
      }];
  
      const response = await axios.post(
        'https://dttapis.devforhealth.com/api/Rights/AddRights',
        postData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      // Handle the response as needed
      console.log('API response:', response.data);
      alert.success('Rights Updated successfully.')
  setRefresh(!refresh)
      // Add any additional logic here
  
    } catch (error) {
      setIsSubmitting(false);

      console.error('Submission failed:', error);
    }
  };
  
  const renderTree = (node) => (
    <div key={node.id} style={{ margin: '8px' }}>
      <div
        draggable
        onDragStart={(e) => {
          setName(node.moduleName);
          e.dataTransfer.setData('text/plain', node.id);
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={(e) => {
          setId(node.id);
          setName(node.moduleName);
          e.preventDefault();
          const draggedItemId = e.dataTransfer.getData('text/plain');
          handleDrop(parseInt(draggedItemId), node.id);
        }}
        onClick={() => toggleNode(node.id)}
        style={{ cursor: 'pointer' }}
      >
        <h3 style={{ display: 'flex', alignItems: 'center' }}>
          {node.children && node.children.length > 0 ? (
            expandedNodes.includes(node.id) ? (
              <FolderOpenIcon style={{ verticalAlign: 'middle', color: '#f87f37' }} />
            ) : (
              <FolderIcon style={{ verticalAlign: 'middle', color: '#f87f37' }} />
            )
          ) : null}
          <FormControlLabel
            control={ 
              <Checkbox style={{marginLeft:'1rem'}}
              disabled={isSubmitting}
              onClick={(e) => {
                e.stopPropagation()}}
              checked={node.isChecked !== 0 ? true : selectedNodes.includes(node.id)}
              onChange={(event) => handleNodeSelect(event, node.id, node.rightsId, node.roleId, node.moduleId, event.target.checked ? 1 : 0)}
              value={node.id}
            />
            
            }
          //  label={node.moduleName}
          />
          <span style={{ marginLeft: '-0.5rem', fontSize: 'medium' }}>{node.moduleName}</span>


          <div style={{ marginLeft: '10px' }}>



            <>


            </>
          </div>
        </h3>
      </div>
      {expandedNodes.includes(node.id) && node.children && node.children.length > 0 && (
        <div style={{ marginLeft: '16px' }}>
          {node.children.map((child) => (
            <div key={child.id} onClick={() => { setName(child.name); console.log(`Clicked on ${child.name}`); }}>
              {renderTree(child)}
            </div>
          ))}
        </div>
      )}
    </div>
  );






  return (<>
<div>
<select
  style={{borderRadius:'7px',
    border: '2px dotted #f98d33',
    width: '9rem',
    textAlign: 'center'
  }}
  value={selectedOption}
  onChange={async (e) => {
    const newSelectedOption = e.target.value;
    setSelectedOption(newSelectedOption);
    // Assuming e.target.value represents nodeId
    setSelectedNodeId(newSelectedOption);
  }}
>
  {treeData1.length===0 || treeData1===null?'No Data':treeData1.map((item, index) => (
    <option key={index} value={item.id}>
      {item.name}
    </option>
  ))}
</select>

    </div>
    {/* <SuiButton 
    // onClick={()=>{onSubmitUpdate()}}
                  style={{ width: '29px', marginLeft: '0.9rem', height: '30px', color: 'white' }}
                  size="medium"
                  variant="gradient"
                  color="warning"

                >
                  
                  <SaveOutlinedIcon
                      fontSize="medium"
                      style={{

                        width: '18px',
                        height: '14px',
                        color: 'white',
                        paddingRight: '-4px',
                        paddingBottom: '2px',
                      }}
                    />Save
                </SuiButton> */}
    <Modal
      open={openEdit}
      onClose={handleCloseEdit}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div><MyFormRights createdBy={createdBy} update={'update'} applyRefresh={applyRefreshh} practiceId={practiceId} name={name} nodesvalues={nodesvalues} updatedBy={updatedBy} handleClose={handleCloseEdit} nodeId={nodeId} parentId={parentId} isActive={isActive} /></div>        </Box>
    </Modal>

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div><MyFormRights handleClose={handleClose} parentId={parentId1} applyRefresh={applyRefreshh} /></div>
      </Box>
    </Modal>
    {submitting ? (
      <CircularProgress
        style={{
          width: '50px',
          height: '50px',
          position: 'absolute',
          left: '50%',
          top: '70%',
          marginLeft: '-25px',
          marginTop: '25px',
          zIndex: 1000,
          color: '#FCBE13',
        }}
      />
    ) : null}
    <div>
      {Array.isArray(treeData) && treeData.map((node) => renderTree(node))}
    </div>

    </>
  );
};

export default Rights;
